import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
  },
  dot: {
    display: 'inline-block',
    height: 16,
    width: 16,
    borderRadius: '50%',
    marginRight: 8,
  },
}));

interface ChartLabelProps {
  className?: string;
  // Color of the dot next to the label
  color: string;
  label: string;
}

export default function ChartLabel({ className = '', color, label }: ChartLabelProps) {
  const classes = useStyles();

  return (
    <span className={`${classes.wrapper} ${className}`}>
      <span
        className={classes.dot}
        style={{
          backgroundColor: color,
        }}
      />
      <Typography component="span" variant="body2">
        {label}
      </Typography>
    </span>
  );
}
