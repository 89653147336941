import { useState } from 'react';
import {
  Button,
  Link,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import SearchBar from '../../../reusables/SearchBar';
import AnalystCard from './AnalystCard';
import ContentScheduler from '../components/ContentScheduler';
import { Student } from '../../../services/interfaces';
import useAnalysts from './useAnalysts';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    height: 40,
    width: '100%',
  },
  headerLeft: {
    display: 'flex',
    width: '50%',
  },
  headerRight: {
    display: 'flex',
    width: '50%',
    justifyContent: 'flex-end',
    '& > *': {
      marginLeft: 20,
    },
  },
  title: {
    marginTop: 4,
    marginRight: 28,
    width: 198,
  },
  analystCardContainer: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
    height: 'calc(100vh - 175px)',
    overflowY: 'auto',
  },
}));

export default function AnalystList() {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const analysts = useAnalysts();
  const [selectedAnalysts, setSelectedAnalysts] = useState<Student[]>([]);
  const [isBatchSelectionActive, setIsBatchSelectionActive] = useState(false);
  const [isContentSchedulerOpen, setIsContentSchedulerOpen] = useState(false);
  const [assignedAnalystId, setAssignedAnalystId] = useState('');

  const handleSelection = (analyst: Student) => {
    const isAnalystSelected = selectedAnalysts.some(selectedAnalyst => selectedAnalyst.id === analyst.id);

    const newSelectedAnalystArray = isAnalystSelected
      ? selectedAnalysts.filter(selectedAnalyst => selectedAnalyst.id !== analyst.id)
      : [...selectedAnalysts, analyst];

    if (newSelectedAnalystArray.length > 0) {
      setIsBatchSelectionActive(true);
    } else {
      setIsBatchSelectionActive(false);
    }

    setSelectedAnalysts(newSelectedAnalystArray);
  };

  const filteredAnalysts = analysts.filter(x => x.name.toLowerCase().includes(searchValue.toLowerCase()));

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Typography className={classes.title} variant="h2">All Analysts </Typography>
          <SearchBar placeholder="Search Analysts" onChange={setSearchValue} />
        </div>
        <div className={classes.headerRight}>
          {isBatchSelectionActive
          && (
            <Button component={Link} onClick={() => { setIsBatchSelectionActive(false); }}>
              End Batch Selection
            </Button>
          )}
        </div>
      </div>
      <div className={classes.analystCardContainer}>
        {filteredAnalysts.map(analyst => (
          <AnalystCard
            analyst={analyst}
            isSelected={selectedAnalysts.some(selectedAnalyst => selectedAnalyst.id === analyst.id)}
            onSelect={handleSelection}
            isBatchSelectionActive={isBatchSelectionActive}
            events={analyst.calendarEvents}
            key={analyst.id}
            assignCurriculum={() => {
              setIsContentSchedulerOpen(true);
              setAssignedAnalystId(analyst.id);
            }}
          />
        ))}
        {isContentSchedulerOpen && (
          <ContentScheduler
            setOpen={setIsContentSchedulerOpen}
            attendee={assignedAnalystId}
          />
        )}
      </div>
    </div>
  );
}
