import { Typography } from '@mui/material';

export default function EventField({
  label, value, className = '', disp = true,
}: EventFieldProps) {
  return (
    <div>
      <Typography variant="h5">{label}</Typography>
      <div className={className} style={{ marginTop: 10 }}>
        <Typography variant="body1">{disp ? value : 'Not Reported' }</Typography>
      </div>
    </div>
  );
}

interface EventFieldProps {
  label: string;
  value?: string | number;
  className?: string;
  disp?: boolean;
}
