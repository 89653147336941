import { makeStyles } from '@mui/styles';
import {
  Typography,
} from '@mui/material';
import EventField from './EventField';
import HighlightedTextField from '../../../../reusables/HighlightedTextField';
import { AnalystInput } from '../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  fields: {
    marginTop: 36,
    display: 'flex',
    gap: 20,
    justifyContent: 'space-between',
    '& > div': {
      flex: 1,
    },
  },
  studentFields: {
    borderRadius: 3,
    backgroundColor: 'rgba(255,255,255,0.11)',
    padding: '11px 13px 11px 14px',
  },
}));

export default function AnalystEventInput({ input }: AnalystHostEventProps) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.fields} style={{ flexDirection: 'column' }}>
        <Typography variant="h5" style={{ marginBottom: 15 }}>Event</Typography>
        <HighlightedTextField data={input.event} />
        <EventField className={classes.studentFields} label="Event Notes" value={input.description} />
      </div>
    </div>
  );
}

interface AnalystHostEventProps {
  input: AnalystInput;
}
