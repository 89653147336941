import React from 'react';
import { Button } from '@mui/material';
import logsService from '../../../services/LogsService';

function DownloadAll() {
  const downloadAll = async () => {
    const file = await logsService.downloadAll();
    const binaryString = window.atob(file);
    const binaryLen = binaryString.length;

    const ab = new ArrayBuffer(binaryLen);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < binaryLen; i += 1) {
      ia[i] = binaryString.charCodeAt(i);
    }

    const data = new Blob([ab], { type: 'zip' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'logs.zip');
    link.click();
  };

  return (
    <Button color="primary" variant="contained" onClick={downloadAll}>Download All</Button>
  );
}

export default React.memo(DownloadAll);
