import { useCallback } from 'react';
import { defaultColors } from '../pages/Settings/MiscSettings';
import { settingsService } from '../services';
import { Setting, ThresholdColors } from '../services/interfaces';
import useSocketListener from './useSocketListener';
import appStore from '../../AppStore';

/**
 * Keeps an updated setting to return
 */
export function useSetting<T>(name: string, defaultValue: T, parser = (v: string) => JSON.parse(v) as T) {
  const callback = useCallback(async (updated?: Setting) => {
    if (!appStore.userId) {
      return;
    }

    if (!updated) {
      return settingsService.find(name, '{ name value }', 'network-only');
    }

    if (updated.name === name) {
      return updated;
    }
  }, [name, appStore.userId]);

  const setting = useSocketListener('settings', 'update', callback);

  if (!setting?.value) {
    return defaultValue;
  }

  return parser(setting.value);
}

export function useThresholdColors() {
  const colors = useSetting<ThresholdColors>('thresholdColors', { ...defaultColors });

  return { ...defaultColors, ...colors };
}