import { FetchPolicy, gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { CreateEvaluationInput, ErrorResponse, Evaluation, PaginationInput } from './interfaces';
import { typeTestResult } from './JobsService';
import ServiceBase from './ServiceBase';
import { typeTest } from './TestsService';

type UploadResponse = ErrorResponse | Evaluation;

const typeStage = `
{
  id
  name
  description
  orderNo
  vendor
  vendorId
  tests ${typeTest}
}
`;

const typeEvaluation = `
{
  id
  name
  description
  isCustom
  tags
  vendor
  vendorId
  stages ${typeStage}
  runCount
  testResults ${typeTestResult}
}
`;

// creates the generic lists query so it can be used repeatedly
function evaluationsQuery(fields = typeEvaluation) {
  return gql`
    query Evaluations($pagination: PaginationInput, $vendor: String) {
      evaluations(pagination: $pagination, vendor: $vendor) ${fields}
    }
  `;
}

export class EvaluationsService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['createdAt']));
  }

  /**
   * Creates a new evaluation
   */
  async createEvaluation(input: CreateEvaluationInput, fields = typeEvaluation): Promise<Evaluation> {
    const query = gql`
      mutation CreateEvaluation($input: CreateEvaluationInput!) {
        createEvaluation(input: $input) ${fields}
      }
    `;

    const { createEvaluation } = await this.graphql(query, { input });
    return createEvaluation;
  }

  /**
   * Deletes an evaluation
   */
  async deleteEvaluation(id: string): Promise<string> {
    const query = gql`
      mutation DeleteEvaluation($id: ID!) {
        deleteEvaluation(id: $id)
      }
    `;

    const { deleteEvaluation } = await this.graphql(query, { id });

    await this.removeGQLCache(evaluationsQuery(), {}, 'Evaluation', (e: Evaluation) => e.id !== id);

    return deleteEvaluation;
  }

  /**
   * Returns a specific evaluation
   */
  async find(vendor: string, vendorId: string, fields = typeEvaluation): Promise<Evaluation> {
    const query = gql`
      query Evaluation($input: VendorInput!) {
        evaluation(input: $input) ${fields}
      }
    `;

    const { evaluation } = await this.graphql(query, { input: { vendor, vendorId } });

    return evaluation;
  }

  /**
   * Returns all evaluations
   */
  async list(
    pagination: PaginationInput = {},
    vendor: string | undefined = undefined,
    fields = typeEvaluation,
    fetchPolicy: FetchPolicy = 'cache-first',
  ): Promise<Evaluation[]> {
    const query = evaluationsQuery(fields);

    const { evaluations } = await this.graphql(query, { pagination, vendor }, fetchPolicy);

    return evaluations;
  }

  /**
   * Refreshes evaluations from vendor
   */
  async refreshEvaluations(): Promise<string> {
    const query = gql`
      mutation RefreshEvaluations {
        refreshEvaluations
      }
    `;
    const { refreshEvaluations } = await this.graphql(query);
    return refreshEvaluations;
  }

  /**
   * Returns evaluations that have stages that have any of the specified test ids
   */
  async byTestIds(ids: string[], fields = typeEvaluation): Promise<Evaluation[]> {
    const query = gql`
      query EvaluationsByTestIds($ids: [String!]!) {
        evaluationsByTestIds(ids: $ids) ${fields}
      }
    `;

    const { evaluationsByTestIds } = await this.graphql(query, { ids });

    return evaluationsByTestIds;
  }

  /**
   * Uploads JSON or Zip files to the server to be processed
   * to create evaluations
   */
  async upload(files: File[], fields = typeEvaluation): Promise<ErrorResponse[]> {
    const query = gql`mutation UploadEvaluations($files: [Upload!]!) {
      uploadEvaluations(files: $files) {
        __typename
          ... on ErrorResponse { message stack }
          ... on Evaluation ${fields}
      }
    }`;

    const { uploadEvaluations } = (await this.graphql(query, { files })) as { uploadEvaluations: UploadResponse[] };

    const [evals, errors] = uploadEvaluations.reduce(
      (a, b) => {
        if (b.__typename === 'Evaluation') {
          a[0].push(b as Evaluation);
        } else {
          a[1].push(b as ErrorResponse);
        }

        return a;
      },
      [[], []] as [Evaluation[], ErrorResponse[]],
    );

    // add new evaluations to the cache
    this.appendGQLCache(evaluationsQuery(), {}, 'Evaluation', evals, (a, b) => (a.name < b.name ? -1 : 1));

    return errors;
  }
}

const evaluationsService = new EvaluationsService();
export default evaluationsService;
