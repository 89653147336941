import { makeStyles } from '@mui/styles';
import {
  ProgressBar,
  ProgressBarSteps,
  ProgressStages,
  PassFailBarChartMetric,
} from '../';

const useStyles = makeStyles({
  chartMetricWrapper: {
    marginBottom: 20,
    marginTop: 20,
    width: 692,
    height: 209,
  },
  progressWrapper: {
    marginBottom: 20,
    marginTop: 20,
  },
});

export default function PreviewProgress() {
  const classes = useStyles();
  return (
    <>
      <h2> Progress Bar </h2>
      <div className={classes.progressWrapper}>
        <ProgressBar size="large" value={33} />
      </div>
      <div className={classes.progressWrapper}>
        <ProgressBar size="medium" value={33} />
      </div>
      <div className={classes.progressWrapper}>
        <ProgressBar size="small" value={33} />
      </div>
      <h2> Progress Bar Metric </h2>
      <div className={classes.progressWrapper}>
        <ProgressBarSteps total={6} current={5} />
      </div>
      <div className={classes.progressWrapper}>
        <ProgressBarSteps total={6} current={1} />
      </div>
      <div className={classes.progressWrapper}>
        <ProgressBarSteps total={6} current={6} />
      </div>
      <h2> Progress Stages </h2>
      <div className={classes.progressWrapper}>
        <ProgressStages stages={['Preparing Tests', 'Running Tests', 'Running Last Test', 'Ready']} current={0} />
      </div>
      <div className={classes.progressWrapper}>
        <ProgressStages stages={['Preparing Tests', 'Running Tests', 'Running Last Test', 'Ready']} current={1} />
      </div>
      <div className={classes.progressWrapper}>
        <ProgressStages stages={['Preparing Tests', 'Running Tests', 'Running Last Test', 'Ready']} current={2} />
      </div>
      <div className={classes.progressWrapper}>
        <ProgressStages stages={['Preparing Tests', 'Running Tests', 'Running Last Test', 'Ready']} current={3} />
      </div>
      <div className={classes.progressWrapper}>
        <ProgressStages stages={['Preparing Tests', 'Running Tests', 'Running Last Test', 'Ready']} current={4} />
      </div>
      <h2> Pass Fail Bar Chart Metric </h2>
      <div className={classes.chartMetricWrapper}>
        <PassFailBarChartMetric
          title="Lorem Ipsum"
          labels={['XX/XX', 'XX/XX', 'XX/XX', 'XX/XX', 'XX/XX', 'XX/XX', 'XX/XX']}
          pass={[88, 46, 93, 65, 63, 29, 76]}
          fail={[37, 46, 16, 0, 70, 29, 0]}
        />
      </div>
    </>
  );
}