import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Typography,
} from '@mui/material';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  headerShort: {
    justifyContent: 'flex-start',
  },
  title: {
    color: '#ffffff',
    marginRight: 20,
    textTransform: 'uppercase',
  },
}));

export default function TitleAndViewAll({
  title, to, onClick, short,
}: TitleAndViewAllProps) {
  const classes = useStyles();

  let component = {};
  if (to) {
    component = { component: Link, to };
  }

  if (onClick) {
    component = { onClick };
  }

  return (
    <div className={clsx(classes.header, { [classes.headerShort]: short })}>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      {(to || onClick) && (
        <Button {...component} color="primary">
          View All
          &nbsp;
          <FontAwesomeIcon icon={['fal', 'arrow-right']} />
        </Button>
      )}
    </div>
  );
}

interface TitleAndViewAllProps {
  short?: boolean;
  title: string;
  to?: string,
  onClick?: () => unknown;
}
