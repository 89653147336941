import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { parseISO, getDayOfYear } from 'date-fns';
import { Calendar } from '../../../../reusables/scl';
import { curriculumService } from '../../../../services';
import AchievementsContainer from '../../components/AchievementsContainer';
import { Card, CardContent, CardDetails, CardLink, CardTag } from '../../components/Card';
import TitleAndViewAll from '../../components/TitleAndViewAll';
import { useAbort } from '../../../../../effects';
import appStore from '../../../../../AppStore';
import Stats from './Stats';
import {
  CalendarEvent,
  ExamEvent,
  RecommendedCourses,
} from '../../../../services/interfaces';
import useRecentAchievements from '../../../../reusables/useRecentAchievements';
import useCalendarEvents from '../../../../reusables/useCalendarEvents';
import useExamEvents from '../../../../reusables/useExamEvents';
import SelectedEvent from './SelectedEvent';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 12,
  },
  recommendedCourses: {
    display: 'flex',
    flexDirection: 'row',
    gap: 25,
    '& > div': {
      flex: 1,
    },
  },
  recommendedCourse: {
    maxWidth: '50%',
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
  },
  recommendedCourseLabel: {
    color: '#a7adb5',
    marginTop: 8,
  },
}));

function Student() {
  const classes = useStyles();
  const [selectedCalendarEvent, setSelectedCalendarEvent] = useState<CalendarEvent | ExamEvent>();

  const [recommendedCourses, setRecommendedCourses] = useState<RecommendedCourses>({});
  useAbort(() => curriculumService.recommendedCourses(appStore.userId), result => {
    setRecommendedCourses(result);
  });

  // watch for updates to recent achievements
  const recentAchievements = useRecentAchievements(appStore.userId);

  // watches the calendar events
  const curriculumEvents = useCalendarEvents(appStore.userId);
  const examEvents = useExamEvents(appStore.userId);

  const events = useMemo(() => [
    ...curriculumEvents.map(event => ({ ...event, start: parseISO(event.day) })),
    ...examEvents
  ].sort((a, b) => a.start.getTime() - b.start.getTime()), [curriculumEvents, examEvents]);

  const onCalendarClick = (date: Date) => {
    const newEvents = events.filter(e => getDayOfYear(e.start) === getDayOfYear(date));
    if (newEvents.length === 1) {
      setSelectedCalendarEvent(newEvents[0]);
    } else {
      setSelectedCalendarEvent(undefined);
    }
  };

  // sets the default event
  useAbort(() => events.find(e => e.start.getTime() > Date.now()),
    result => setSelectedCalendarEvent(result), [events]);

  const calendar = {
    scheduled: events.filter(e => e.status === 'scheduled').flatMap(s => s.start),
    completed: events.filter(e => e.status === 'completed').flatMap(c => c.start),
    scheduledMinutes: 192,
  };

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h2">
          Your Training Overview
        </Typography>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 25, flexWrap: 'wrap' }}>
        <div style={{ flex: 1, minWidth: 400 }}>
          { /* left column */}
          <Stats analyst={appStore.userId} />
          <div style={{ marginTop: 20 }} />
          <AchievementsContainer
            achievements={recentAchievements.map(achievement => achievement.name)}
            title="Recent Achievements"
            viewAllLink="/curriculum/achievements"
          />
          <TitleAndViewAll
            title="Recommended Courses"
            to="/curriculum/learning"
            short
          />
          <div className={classes.recommendedCourses}>
            {recommendedCourses.challenge && (
              <div
                className={classes.recommendedCourse}
              >
                <CardLink to={`/curriculum/learning/${recommendedCourses.challengeParent?.id}/course/${recommendedCourses.challenge?.id}`}>
                  <Card>
                    <CardContent variant="dense" light row>
                      <CardTag>{recommendedCourses.challengeParent?.title}</CardTag>
                    </CardContent>
                    <CardContent variant="dense">
                      <CardDetails
                        level={recommendedCourses.challenge.difficulty}
                        title={recommendedCourses.challenge.title}
                      />
                    </CardContent>
                  </Card>
                </CardLink>
                <Typography variant="body1" className={classes.recommendedCourseLabel}>
                  For a Challenge
                </Typography>
              </div>
            )}
            {recommendedCourses.quickRefresher && (
              <div
                className={classes.recommendedCourse}
              >
                <CardLink to={`/curriculum/learning/${recommendedCourses.quickRefresherParent?.id}/course/${recommendedCourses.quickRefresher?.id}`}>
                  <Card>
                    <CardContent variant="dense" light row>
                      <CardTag>{recommendedCourses.quickRefresherParent?.title}</CardTag>
                    </CardContent>
                    <CardContent variant="dense">
                      <CardDetails
                        level={recommendedCourses.quickRefresher.difficulty}
                        title={recommendedCourses.quickRefresher.title}
                      />
                    </CardContent>
                  </Card>
                </CardLink>
                <Typography variant="body1" className={classes.recommendedCourseLabel}>
                  For a Quick Refresher
                </Typography>
              </div>
            )}
          </div>
        </div>
        <div style={{ flex: 1 }}>
          { /* right column */}
          <TitleAndViewAll title="Curriculum Calendar" to="/curriculum/calendar" />
          <SelectedEvent event={selectedCalendarEvent} />
          <Calendar
            scheduled={calendar.scheduled}
            completed={calendar.completed}
            trainingMins={calendar.scheduledMinutes}
            onClick={v => onCalendarClick(v)}
            small
            autoWidth
            hover
          />
        </div>
      </div>
    </div>
  );
}

export default Student;
