import React from 'react';
import { makeStyles } from '@mui/styles';
import { Divider } from '@mui/material';
import {
  LargeNumericMetric,
  ProgressMonitoringMetric,
} from '../../../reusables/scl';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 6,
    border: 'solid 1px rgba(255, 255, 255, 0.26)',
    padding: 36,
    minWidth: 'calc(50% - 12px)',
  },
}));

function Stats({
  achievements,
  courses,
  curriculums,
  mins,
  minsLastMonth,
}: StatsProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <LargeNumericMetric
        value={achievements ?? 0}
        label="Last 30 Days"
        labelSecondRow="Achievements Earned"
      />
      <Divider orientation="vertical" flexItem />
      <LargeNumericMetric
        value={courses ?? 0}
        label="Last 30 Days"
        labelSecondRow="Total Courses"
      />
      <Divider orientation="vertical" flexItem />
      <LargeNumericMetric
        value={curriculums ?? 0}
        label="Last 30 Days"
        labelSecondRow="Total Curriculums"
      />
      <Divider orientation="vertical" flexItem />
      <ProgressMonitoringMetric
        value={mins ?? 0}
        valuePrevious={minsLastMonth ?? 0}
        unit="minutes"
        unitShort="min"
        duration="month"
      />
    </div>
  );
}

type StatsProps = {
  achievements?: number;
  courses?: number;
  curriculums?: number;
  mins?: number;
  minsLastMonth?: number;
};

export default React.memo(Stats);
