import { makeStyles } from '@mui/styles';
import { ClassNameMap, LinearProgress } from '@mui/material';

const useStyles = makeStyles(() => ({
  progressBar: {
    borderRadius: 24,
    background: 'rgba(255, 255, 255, 0.09)',
  },
  progressBarRainbow: {
    background: 'linear-gradient(to right, #21bee3, #00ffb1)',
  },
  progressBarLivefire: {
    background: '#f67339',
  },
  small: {
    height: 6,
  },
  medium: {
    height: 9,
  },
  large: {
    height: 16,
  },
}));

function barColorPrimary(
  classes: ClassNameMap<'progressBarRainbow' | 'progressBarLivefire'>,
  rainbow?: boolean,
  livefire?: boolean,
) {
  if (rainbow) {
    return classes.progressBarRainbow;
  }

  if (livefire) {
    return classes.progressBarLivefire;
  }

  return {};
}

interface ProgressBarProps {
  value: number;
  size?: 'small' | 'medium' | 'large';
  rainbow?: boolean;
  livefire?: boolean;
}

export default function ProgressBar({ value, size = 'medium', rainbow = false, livefire = false }: ProgressBarProps) {
  const classes = useStyles();

  return (
    <LinearProgress
      variant="determinate"
      className={`${classes.progressBar} ${classes[size]}`}
      classes={barColorPrimary(classes, rainbow, livefire)}
      value={value}
    />
  );
}
