import { useState } from 'react';
import { useAbort } from '../../../effects';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { settingsService } from '../../services';
import ExploitSettings from './ExploitSettings';

const useStyles = makeStyles({
  message: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  }
});

export default function ExploitTesting() {
  const classes = useStyles();
  const [sipSetting, setSipSetting] = useState('');

  useAbort(() => settingsService.find('sip'), result => setSipSetting(result.value));

  if (sipSetting === 'testing') {
    return <ExploitSettings />;
  }

  return (
    <div className={classes.message}>
      <Typography variant="h3">
            To configure Atomic Red Team settings, you must use the Atomic Red Team instrumentation provider
      </Typography>
    </div>
  );
}
