import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { CSSProperties, ReactNode } from 'react';

const useStyles = makeStyles(() => ({
  tag: {
    fontSize: '14px',
    fontWeight: 'bold',
    lineHeight: 1.57,
    backgroundColor: 'rgba(224, 105, 77, 0.49)',
    padding: '2px 9px 3px 7px',
    border: '1px solid rgba(255, 255, 255, 0.3)',
    borderRadius: '3px',
    fontFamily: 'Lexend',
    textAlign: 'center',
  },
  red: {
    backgroundColor: 'rgba(248, 92, 92, 0.49)',
  },
  orange: {
    backgroundColor: 'rgba(255, 165, 6, 0.49)',
  },
  yellow: {
    backgroundColor: 'rgba(255,255,0, 0.8)',
  },
  green: {
    backgroundColor: 'rgba(0, 255, 177, 0.49)',
  },
  blue: {
    backgroundColor: 'rgba(45, 116, 186, 0.49)',
  },
  lg: {
    fontSize: '29px',
  }
}));

function NumberBadge({
  tag = false,
  children,
  className = '',
  color = 'red',
  lg = false,
}: NumberBadgeProps) {
  const classes = useStyles();

  const style: CSSProperties = {};
  let colorClass = '';
  if (color in classes) {
    colorClass = classes[color as 'red' | 'orange' | 'yellow' | 'green' | 'blue'];
  } else {
    style.backgroundColor = color;
  }

  if (tag === true) {
    return (
      <span style={style} className={clsx(classes.tag, className, colorClass,lg && classes.lg)}>
        {children}
      </span>
    );
  }

  return <></>;
}

type NumberBadgeProps = {
  tag?: boolean;
  children: ReactNode;
  className?: string;
  color?: string;
  lg?: boolean
};

export default NumberBadge;
