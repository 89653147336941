import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Button, Chip, Grid, Link, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FrameworkItemWithScores } from '../../../../services/interfaces';
import CapabilityDetails from './details';
import { useAssessmentContext } from '../../components/AssessmentContext';
import CapabilityMaturityTable from './components/table/table';
import { MaturityLevel } from './interface';

const useStyles = makeStyles(() => ({
  container: {
    padding: '10px 20px',
    position: 'relative',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    paddingLeft: 1,
    paddingRight: 1,
    flexDirection: 'row',
    height: 'calc(100vh - 534px)',
    width: '100%',
    overflow: 'scroll',
  },
}));

const CAPABILITY_MATURITY_TABS = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Target',
    value: 'target',
  },
  {
    label: 'Advanced',
    value: 'advanced',
  },
];

interface CapabilityMaturityProps {
  onScroll: (e: any) => void;
}

export default function CapabilityMaturity({ onScroll }: CapabilityMaturityProps) {
  const [currentItem, setCurrentItem] = useState<FrameworkItemWithScores | null>(null);
  const { assessment, assessmentFrameworkId, currentOverallMaturityLevel, setCurrentOverallMaturityLevel } =
    useAssessmentContext();
  const [currentMaturityLevel, setCurrentMaturityLevel] = useState<MaturityLevel>(
    currentOverallMaturityLevel as MaturityLevel,
  );

  const classes = useStyles();

  const currentItemId = currentItem?.identifier;

  useEffect(() => {
    if (currentItemId) {
      const found = assessment.frameworks[0].groups
        .flatMap(g => g.items)
        .find(item => item.identifier === currentItemId);
      if (found) {
        setCurrentItem(found as FrameworkItemWithScores);
      }
    }
  }, [assessment, currentItemId]);

  // uses the first one. Could cause issues if more than 1 framework per assessment
  const assessmentFramework = assessment.frameworks.find(fw => fw.id === assessmentFrameworkId)!;

  // show entire framework if no item is selected
  if (!currentItem) {
    return (
      <>
        <div style={{ float: 'right', padding: 12 }}>
          <Grid container gap={2}>
            <Grid item alignContent="center">
              <Typography variant="textStyle1">Overall Maturity Level:</Typography>
            </Grid>
            {CAPABILITY_MATURITY_TABS.map(tab => {
              return (
                <Grid item key={`capability-maturity-tab-${tab.value}`}>
                  <Chip
                    variant={currentOverallMaturityLevel === tab.value ? 'filled' : 'outlined'}
                    label={tab.label}
                    onClick={() => setCurrentOverallMaturityLevel(tab.value)}
                  />
                </Grid>
              );
            })}
          </Grid>
        </div>
        <div className={classes.wrapper} onScroll={onScroll}>
          <CapabilityMaturityTable framework={assessmentFramework} setCurrentItem={setCurrentItem} />
        </div>
      </>
    );
  }

  // display the capability maturity view for the selcted item
  return (
    <div className={classes.wrapper}>
      <div className={classes.container}>
        <Grid container direction="column" gap={2}>
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Button
                  component={Link}
                  onClick={() => setCurrentItem(null)}
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-left']} />}
                >
                  Back
                </Button>
              </Grid>
              <Grid item alignContent="center">
                <Grid container gap={2}>
                  <Grid item alignContent="center">
                    <Typography variant="textStyle1">Maturity Level:</Typography>
                  </Grid>
                  {CAPABILITY_MATURITY_TABS.map(tab => {
                    return (
                      <Grid item key={`capability-maturity-tab-${tab.value}`}>
                        <Chip
                          variant={currentMaturityLevel === tab.value ? 'filled' : 'outlined'}
                          label={tab.label}
                          onClick={() => setCurrentMaturityLevel(tab.value as MaturityLevel)}
                        />
                      </Grid>
                    );
                  })}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <CapabilityDetails
              framework={assessmentFramework}
              item={currentItem}
              maturityLevel={[currentMaturityLevel, setCurrentMaturityLevel]}
            />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
