import { observer } from 'mobx-react';
import appStore from '../../../../AppStore';
import Student from './student/Student';
import Instructor from './instructor/Instructor';
import { Role } from '../../../services/interfaces';

function Overview() {
  // TODO this should be replaced with the 'selected' role when implemented
  const showInstructor = appStore.activeRole === Role.INSTRUCTOR;
  return (
    <>
      { showInstructor && (<Instructor />) }
      {!showInstructor && (<Student />)}
    </>
  );
}

export default observer(Overview);
