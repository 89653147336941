import React from 'react';
import Modal from '@mui/material/Modal';
import { create } from 'zustand';
import { makeStyles } from '@mui/styles';
import { ReactElement } from 'react-markdown/lib/react-markdown';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10vh',
  },
  wrapper: {
    borderRadius: 6,
    backgroundColor: '#0b0c12',
    padding: '20px',
  },
}));

function BetterModalFactory(modalKey: string) {
  return function BetterModal({ children, ...props }: { children: ReactElement }) {
    const classes = useStyles();
    const modal = useModal(modalKey);

    const close = () => {
      modal.closeModal();
    };

    const elements = React.Children.map(children, child => {
      if (React.isValidElement(child)) {
        return React.cloneElement<any>(child, {
          onClose: close,
          data: modal.data,
        });
      }
      return null;
    });

    if(!modal.isOpen) {
      return null;
    }

    return (
      <Modal open={true} onClose={close} className={classes.modal} {...props}>
        <>
          {elements[0]}
        </>       
      </Modal>
    );
  };
}

export const useModalStore = create<ModalStore>((set, get) => ({
  modals: {},
  createModal: (modalKey: string)  => {
    const existing = get().modals[modalKey];
    if (existing) {
      return existing.component;
    }
    const component = BetterModalFactory(modalKey);
    set(state => {
      return ({
        modals: {
          ...state.modals,
          [modalKey]: {
            component,
            isOpen: false,
            openModal: (data: any) => {
              set(prevState => ({
                ...prevState,
                modals: {
                  ...prevState.modals,
                  [modalKey]: {
                    ...prevState.modals[modalKey],
                    isOpen: true,
                    data,
                  }
                }
              }));
            },
            closeModal: () => {
              set(prevState => ({
                ...prevState,
                modals: {
                  ...prevState.modals,
                  [modalKey]: {
                    ...prevState.modals[modalKey],
                    isOpen: false,
                  }
                }
              }));
            },
          },
        }
      });
    });
    return component;
  }
}));

/**
 * Retrieve an existing modal by key
 * @param modalKey string representing the key of the modal
 * @returns { isOpen: boolean, openModal: (data: any) => void, closeModal: () => void }
 */
export const useModal = <T,>(modalKey: string): ModalState<T> => {
  const { isOpen, openModal, closeModal, data } = useModalStore(state => state.modals[modalKey] || {});
  return {
    isOpen,
    openModal,
    closeModal,
    data
  };
};

type ModalState<T = any> = {
  isOpen: boolean;
  openModal: (data?: T) => void;
  closeModal: () => void;
  data?: T;
};

export type ModalStore<T = any> = {
  modals: Record<string, IModal<T>>;
  createModal: (key: string) => any;
};

interface IModal<T = any> {
  component: any;
  isOpen: boolean;
  openModal: (data?: T) => void;
  closeModal: () => void;
  data?: T;
}
