import { parseISO } from 'date-fns';
import { useMemo, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { cluster } from '@sightgain/core';
import { useAbort } from '../../../../effects';
import { Calendar, PassFailBarChartMetric } from '../../../reusables/scl';
import useCalendarEvents from '../../../reusables/useCalendarEvents';
import useExamEvents from '../../../reusables/useExamEvents';
import useRecentAchievements from '../../../reusables/useRecentAchievements';
import { usersService } from '../../../services';
import { CalendarEvent, User } from '../../../services/interfaces';
import AchievementsContainer from '../components/AchievementsContainer';
import ContentScheduler from '../components/ContentScheduler';
import StudentActivity from './AnalystActivity';
import AnalystInfo from './AnalystInfo';
import AssignedCurriculums from './AssignedCurriculums';
import useWeeklyProgress from './useWeeklyProgress';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 24,
  },
  leftColumn: {
    display: 'flex',
    marginBottom: 15,
    width: 'calc(50% - 12px)',
    flexFlow: 'column wrap',
    '& > *': {
      marginBottom: 10,
    },
  },
  studWrapper: {
    display: 'flex',
  },
  rightColumn: {
    width: 'calc(50% - 12px)',
    marginLeft: 12,
    marginTop: 30,
    '& > *': {
      minWidth: 'calc(50% - 12px)',
      marginBottom: 16,
    },
  },
  chartWrapper: {
    width: 'calc(100% - 12px)',
    marginTop: 20,
  },
}));

export default function AnalystOverview() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();

  const calendarEvents: (CalendarEvent & { start: Date })[] = useCalendarEvents(id).map(x => ({
    ...x,
    start: parseISO(x.day),
  }));
  const examEvents = useExamEvents(id);
  const events = useMemo(() => [...calendarEvents, ...examEvents], [calendarEvents, examEvents]);
  const recentAchievements = useRecentAchievements(id);
  const weeklyProgress = useWeeklyProgress(id);

  const [analyst, setAnalyst] = useState<User>();
  useAbort(
    () => usersService.find(id),
    result => setAnalyst(result),
  );

  const [isContentSchedulerOpen, setIsContentSchedulerOpen] = useState(false);

  if (!analyst) {
    return <></>;
  }

  const calendar = {
    scheduled: events.filter(e => e.status === 'scheduled').flatMap(s => s.start),
    completed: events.filter(e => e.status === 'completed').flatMap(c => c.start),
    scheduledMinutes: 0,
  };

  const curriculums = calendarEvents.flatMap(e => e.trainingGroup).filter(t => t.type === 'curriculum');

  const { week: labels, fail: failed, pass: passed } = cluster(weeklyProgress);

  return (
    <>
      {isContentSchedulerOpen && <ContentScheduler setOpen={setIsContentSchedulerOpen} attendee={id} />}
      <div className={classes.studWrapper}>
        <div className={classes.leftColumn}>
          <div>
            <Button component={Link} to="/curriculum/analysts" color="primary">
              <FontAwesomeIcon icon={['fal', 'long-arrow-left']} size="lg" />
              &nbsp; Back to Overview
            </Button>
          </div>
          <AnalystInfo
            analyst={analyst}
            onAssignCurriculum={() => {
              setIsContentSchedulerOpen(true);
            }}
          />
          <div className={classes.chartWrapper}>
            <PassFailBarChartMetric title="Weekly Progress" labels={labels} pass={passed} fail={failed} />
          </div>
        </div>
        <div className={classes.rightColumn}>
          <AchievementsContainer
            achievements={recentAchievements.map(achievement => achievement.name)}
            title="Recent Achievements"
            viewAllLink=" "
          />
          <Calendar
            scheduled={calendar.scheduled}
            completed={calendar.completed}
            onClick={() => {}}
            small
            autoWidth
            userId={id}
          />
          <StudentActivity userId={analyst.id} />
        </div>
      </div>
      <AssignedCurriculums curriculums={curriculums} />
    </>
  );
}
