import { chartTitle } from '@sightgain/core/strings';
import { RadarChartMetric } from '../../../../../reusables/metrics';
import { ResultPerformance } from '../../../../../services/interfaces';
import { performanceSeries, roundArray } from '../utilites';

export default function ChartTechByTactic({ performance }: { performance: ResultPerformance[] }) {
  const [labels, ...data] = performanceSeries(performance, 'prevented', 'detected', 'alerted');

  const series = ['Prevented', 'Detected', 'Alerted'].map((name, i) => ({
    name: `% ${name}`,
    data: roundArray(data[i].map(v => v * 100)),
  }));

  return (
    <RadarChartMetric
      title="Technology Performance By Tactic"
      series={series}
      labels={labels.map(label => chartTitle(label))}
    />
  );
}
