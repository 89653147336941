import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import appStore from '../../../AppStore';
import { calculateRoles, getFilteredModules } from '../../Layout/Navigation/utils';
import ModuleCard from './ModuleCard';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 40,
  },
  modules: {
    display: 'flex',
    flexDirection: 'row',
  },
  module: {
    width: '33%',
    marginLeft: 14,
    marginRight: 14,
  },
}));

export default function ModuleSelector() {
  const classes = useStyles();
  const [, availableRoles] = calculateRoles(appStore.activeModule);
  const modules = getFilteredModules(availableRoles);

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h2">
          Select Module
        </Typography>
      </div>
      <div className={classes.modules}>
        {modules.filter(m => m.name !== appStore.activeModule).map(module => (
          <div className={classes.module} key={module.name}>
            <ModuleCard {...module} />
          </div>
        ))}
      </div>
    </div>
  );
}
