import { subDays } from 'date-fns';
import { useContext, useState } from 'react';
import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { divideSafely } from '@sightgain/core/calculations';
import { useAbort } from '../../../../effects';
import TrendIndicator from '../../../reusables/metrics/TrendIndicator';
import { SocCacheItem } from './interfaces';
import SocContext from './SocContext';

const useStyles = makeStyles(() => ({
  responseSummary: {
    display: 'flex',
    justifyContent: 'space-evenly',
    padding: '22px 24px 21.4px 24px',
    borderRadius: 6,
    border: 'solid 1px rgba(255, 255, 255, 0.26)',
    gap: 10,
  },
}));

export default function ResponseSummary() {
  const classes = useStyles();
  const [data, setData] = useState({
    automatedResponseTrends: [] as number[],
    manualResponseTrends: [] as number[],
    noResponseTrends: [] as number[],
    missedResponseTrends: [] as number[],
  });
  const { loadDataForTimeframe } = useContext(SocContext);

  useAbort(
    () => {
      const today = new Date();
      const thirtyDaysAgo = subDays(today, 30);
      const sixtyDaysAgo = subDays(today, 60);
      const ninetyDaysAgo = subDays(today, 90);

      return Promise.all([
        loadDataForTimeframe(ninetyDaysAgo, sixtyDaysAgo),
        loadDataForTimeframe(sixtyDaysAgo, thirtyDaysAgo),
        loadDataForTimeframe(thirtyDaysAgo, today),
      ]);
    },
    ([ninety, sixty, thirty]: SocCacheItem[]) => {
      const totals = [ninety, sixty, thirty].map(timeframe => {
        const { dailyExerciseMetrics } = timeframe;

        return dailyExerciseMetrics.reduce(
          (acc, range) => {
            acc.automatedResponse += range.automatedResponseCount;
            acc.manualResponse += range.manualResponseCount;
            acc.noResponse += range.noResponseCount;
            acc.missedResponse += range.missedCount - range.noResponseCount;
            acc.total += range.count;
            return acc;
          },
          {
            automatedResponse: 0,
            manualResponse: 0,
            noResponse: 0,
            missedResponse: 0,
            total: 0,
          },
        );
      });

      setData({
        automatedResponseTrends: totals.map(t => Math.round(divideSafely(t.automatedResponse, t.total) * 100)),
        manualResponseTrends: totals.map(t => Math.round(divideSafely(t.manualResponse, t.total) * 100)),
        noResponseTrends: totals.map(t => Math.round(divideSafely(t.noResponse, t.total) * 100)),
        missedResponseTrends: totals.map(t => Math.round(divideSafely(t.missedResponse, t.total) * 100)),
      });
    },
  );

  return (
    <div className={classes.responseSummary}>
      <TrendIndicator
        duration="30 days"
        hideGraph
        label="Automated Response"
        trend={data.automatedResponseTrends}
        unit="%"
        improvement="higher"
      />
      <Divider orientation="vertical" flexItem />
      <TrendIndicator
        duration="30 days"
        hideGraph
        label="Manual Response"
        trend={data.manualResponseTrends}
        unit="%"
        improvement="higher"
      />
      <Divider orientation="vertical" flexItem />
      <TrendIndicator
        duration="30 days"
        hideGraph
        label="No Response"
        trend={data.noResponseTrends}
        unit="%"
        improvement="lower"
      />
      <Divider orientation="vertical" flexItem />
      <TrendIndicator
        duration="30 days"
        hideGraph
        label="Missed"
        trend={data.missedResponseTrends}
        unit="%"
        improvement="lower"
      />
    </div>
  );
}
