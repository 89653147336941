import { Button, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { useState } from 'react';
import appStore from '../../../../AppStore';
import { useAbort } from '../../../../effects';
import TextField from '../../../reusables/TextField';
import { usersService } from '../../../services';

export default function ReportingAPI() {
  const [showPassword, setShowPassword] = useState(false);
  const [token, setToken] = useState('');

  useAbort(() => usersService.reportingApiToken(), result => setToken(result));

  const handleClick = async () => {
    appStore.beginLoading();
    try {
      if (token) {
        await usersService.disableReporting();
        setToken('');
        appStore.success('Reporting API disabled');
      } else {
        await usersService.enableReporting();
        const newToken = await usersService.reportingApiToken();
        setToken(newToken);
        appStore.success('Reporting API enabled');
      }
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  return (
    <div>
      <TextField
        label="Reporting API Token"
        value={token}
        onChange={() => { }}
        type={!showPassword ? 'password' : 'text'}
        endAdornment={(
          <InputAdornment position="end">
            <IconButton onClick={() => setShowPassword(!showPassword)} size="large">
              {showPassword && <Visibility />}
              {!showPassword && <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        )}
      />
      <Button
        style={{ marginTop: 8 }}
        color="primary"
        variant="contained"
        onClick={handleClick}
      >{ token ? 'Disable' : 'Enable'}
      </Button>
    </div>
  );
}
