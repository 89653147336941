import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { TierBadge } from '../../../../reusables/Badges';
import { TrainingGroupLevel } from '../../../../services/interfaces';

const useStyles = makeStyles({
  cardDetail: {
    position: 'relative',
  },
  cardDetailContent: {
    display: 'flex',
  },
  cardText: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  cardTitle: {
    marginTop: 14,
  },
  cardTitleLarge: {
    alignSelf: 'center',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    flex: 1,
  },
  cardDetailRight: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
});

export default function CardDetails({
  large = false, level, title
}: CardDetailsProps) {
  const classes = useStyles();

  const titleVariant = large ? 'h3' : 'h5';

  return (
    <div className={classes.cardText}>
      {level && <TierBadge tier={level} />}
      <Typography
        variant={titleVariant}
        className={clsx(classes.cardTitle, large && classes.cardTitleLarge)}
      >{title}</Typography>
    </div>
  );
}

type CardDetailsProps = {
  large?: boolean;
  level?: TrainingGroupLevel;
  title: string;
}
