import { useMemo, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import AppBar from '@mui/material/AppBar';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Framework from './Framework';
import DateHeader from './DateHeader';
import { Divider } from '@mui/material';
import { ScoredFramework, ScoredFrameworkItem, TacticScore, ThresholdColors } from '../../../services/interfaces';
import { PageTab, ScoreType } from './interfaces';
import CapabilityMaturity from './capabilityMaturity';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  tabPanel: {
    height: 'calc(100vh - 480px)',
    background: '#192637',
    borderRadius: 6,
  },
  compare: {
    display: 'flex',
    gap: 12,
    '& > *': {
      width: 'calc(50% - 13px)',
    },
  },
  charts: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'stretch',
  },
  bar: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
}));

export default function FrameworkWrapper({
  activeTab,
  tabs,
  onTabClick,
  backgroundColor,
  thresholdColors,
  assessorThreshold,
  compoundWeights,
  onControlClick: onClick,
  scoredFramework,
  scoredFrameworkDay1,
  scoredFrameworkDay2,
}: FrameworkWrapperProps) {
  const classes = useStyles();

  const day1Ref = useRef(null);
  const day2Ref = useRef(null);

  const handleScroll = (e: any) => {
    const { scrollTop, scrollLeft } = e.target;
    (day1Ref.current as any).scrollTop = scrollTop;
    (day2Ref.current as any).scrollTop = scrollTop;
    (day1Ref.current as any).scrollLeft = scrollLeft;
    (day2Ref.current as any).scrollLeft = scrollLeft;
  };

  const scoreType = useMemo(() => {
    if (activeTab === PageTab.RISK_POSTURE) {
      return ScoreType.SECMIT;
    }

    if (activeTab === PageTab.CONTROL_EFFICACY) {
      return ScoreType.CAPMIT;
    }

    if (activeTab === PageTab.THREAT_INTELLIGENCE) {
      return ScoreType.HEAT;
    }

    if (activeTab === PageTab.ASSESSOR_SCORE) {
      return ScoreType.ASSESSOR;
    }

    if (activeTab === PageTab.COMPOUND) {
      return ScoreType.COMPOUND;
    }

    if (activeTab === PageTab.CAPABILITY_MATURITY) {
      return ScoreType.CAPABILITY_MATURITY;
    }

    return ScoreType.NONE;
  }, [activeTab]);

  if (activeTab === PageTab.CAPABILITY_MATURITY) {
    return (
      <div className={classes.root}>
        <AppBar className={classes.bar} position="static" color="default" style={{ backgroundColor: '#161926' }}>
          <Tabs
            value={tabs.indexOf(activeTab)}
            indicatorColor="secondary"
            textColor="primary"
            onChange={(e, i) => onTabClick(tabs[i])}
          >
            {tabs.map((tab, i) => (
              <Tab id={`tab-${i}`} key={tab} label={tab} />
            ))}
          </Tabs>
        </AppBar>
        <div className={classes.tabPanel} role="tabpanel">
          <CapabilityMaturity onScroll={handleScroll} />
        </div>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <AppBar className={classes.bar} position="static" color="default" style={{ backgroundColor: '#161926' }}>
        <Tabs
          value={tabs.indexOf(activeTab)}
          indicatorColor="secondary"
          textColor="primary"
          onChange={(e, i) => onTabClick(tabs[i])}
        >
          {tabs.map((tab, i) => (
            <Tab id={`tab-${i}`} key={tab} label={tab} />
          ))}
        </Tabs>
      </AppBar>
      {!(scoredFrameworkDay1 && scoredFrameworkDay2) && (
        <div className={classes.tabPanel} role="tabpanel">
          <Framework
            scoreType={scoreType}
            hideTitle
            onClick={onClick}
            scoredFramework={scoredFramework}
            activeTab={activeTab}
            backgroundColor={backgroundColor}
            thresholdColors={thresholdColors}
            assessorThreshold={assessorThreshold}
            compoundWeights={compoundWeights}
          />
        </div>
      )}
      {scoredFrameworkDay1 && scoredFrameworkDay2 && (
        <div className={`${classes.tabPanel} ${classes.compare}`} role="tabpanel">
          <div>
            <div style={{ marginBottom: 8 }}>
              <DateHeader date={scoredFrameworkDay1.toDate ?? ''} />
            </div>
            <Framework
              scoreType={scoreType}
              hideTitle
              onClick={onClick}
              scoredFramework={scoredFrameworkDay1}
              onScroll={handleScroll}
              divRef={day1Ref}
              activeTab={activeTab}
              dateHeader
              backgroundColor={backgroundColor}
              thresholdColors={thresholdColors}
              assessorThreshold={assessorThreshold}
              compoundWeights={compoundWeights}
            />
          </div>
          <Divider orientation="vertical" style={{ width: 2 }} />
          <div>
            <div style={{ marginBottom: 8 }}>
              <DateHeader date={scoredFrameworkDay2.toDate ?? ''} />
            </div>
            <Framework
              scoreType={scoreType}
              hideTitle
              onClick={onClick}
              scoredFramework={scoredFrameworkDay2}
              onScroll={handleScroll}
              divRef={day2Ref}
              activeTab={activeTab}
              dateHeader
              backgroundColor={backgroundColor}
              thresholdColors={thresholdColors}
              assessorThreshold={assessorThreshold}
              compoundWeights={compoundWeights}
            />
          </div>
        </div>
      )}
    </div>
  );
}

interface FrameworkWrapperProps {
  activeTab: PageTab;
  tabs: PageTab[];
  backgroundColor: (s?: TacticScore) => string;
  thresholdColors: ThresholdColors;
  assessorThreshold: number;
  compoundWeights: number;
  onControlClick: (control: ScoredFrameworkItem, columnName?: string) => void;
  onTabClick: (tab: PageTab) => void;
  scoredFramework: ScoredFramework | null;
  scoredFrameworkDay1: ScoredFramework | null;
  scoredFrameworkDay2: ScoredFramework | null;
}
