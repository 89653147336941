import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Divider,
  Grid,
  Modal,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import appStore from '../../../../AppStore';
import LiveFireBadge from '../../../reusables/LiveFireBadge/LiveFireBadge';
import useJobQueue from '../../../reusables/useJobQueue';
import { RunOption, RunOptionInput, TrainingGroupLivefireContent } from '../../../services/interfaces';
import { curriculumService, jobsService } from '../../../services';
import { useMemo, useState } from 'react';
import { useAbort } from '../../../../effects';
import DynamicInput from '../../../reusables/DynamicInput';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    width: '55%',
    height: 671,
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    border: 'solid 1px #5d6e80',
    backgroundColor: '#0b0c12',
    paddingTop: 72,
    paddingLeft: 72,
    paddingRight: 67,
    paddingBottom: 72,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerLeft: {
    paddingRight: 24,
  },
  bottomRow: {
    marginTop: 32,
    display: 'flex',
    gap: 25,
    flexWrap: 'wrap',
  },
  bottomRowLeft: {
    width: 393,
  },
  bottomRowRight: {
    flex: 1,
  },
  info: {
    padding: '29px 31.5px 28px 31px',
    borderRadius: 6,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
  infoRow: {
    display: 'flex',
  },
  infoRowLeft: {
    width: 175,
  },
  infoLabel: {
    marginBottom: 13,
  },
  description: {
    marginTop: 22,
    marginBottom: 26,
    maxHeight: 244,
    overflowY: 'auto',
  },
  exerciseLabel: {
    lineHeight: 1.19,
    marginTop: 11,
    marginBottom: 47,
  },
  descriptionLabel: {
    lineHeight: 1.5,
    letterSpacing: 0.6,
    marginBottom: 18,
    textTransform: 'uppercase',
  },
  date: {
    color: '#a7adb5',
  },
  livefireOptions: {
    marginBottom: 18,
  }
}));

export default function LivefireModal({
  open, onClose, onStart, content,
}: LivefireModalProps) {
  const classes = useStyles();
  const history = useHistory();
  const lftTrainings = useJobQueue({ watch: open, date: undefined });
  const [options, setOptions] = useState<RunOption[][]>([]);
  const [optionInputs, setInputs] = useState<RunOptionInput[][]>([]);

  useAbort(async () => {
    if (!content?.id) {
      return [];
    }

    return jobsService.runOptions(content?.testIds);
  }, runStages => {
    setInputs(runStages.map(runOptions => runOptions
      .filter(opt => opt.default !== null)
      .map(opt => ({ key: opt.key, value: opt.default as string }))));
    setOptions(runStages);
  }, [content?.id]);

  const disabled = useMemo(() => {
    const required = options.flat().filter(opt => opt.isRequired).map(opt => opt.key);
    const inputs = optionInputs.flat().filter(input => required.includes(input.key));

    return required.length !== inputs.length;
  }, [optionInputs, options]);

  if (!content) {
    return <></>;
  }

  const handleClick = async () => {
    appStore.beginLoading();

    try {
      onStart();
      onClose();

      const { id } = await curriculumService.run({
        id: content.livefireId,
        options: optionInputs,
      }, '{ id }');
      history.push(`/curriculum/learning/livefire/${id}`);
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
  };

  return (
    <Modal open={open} className={classes.modal} onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.headerLeft}>
            <Typography variant="h4" color="secondary">You have chosen to begin a live training exercise</Typography>
            <Typography variant="h1" className={classes.exerciseLabel}>
              {content.title}
            </Typography>
          </div>
          <div>
            <LiveFireBadge />
          </div>
        </div>
        <div className={classes.bottomRow}>
          <div className={classes.bottomRowRight}>
            <Typography variant="body2" className={classes.descriptionLabel}>
              <b>Exercise Description</b>
            </Typography>
            <Divider />
            <div className={classes.description}>
              <Typography variant="body1">{content.description}</Typography>
            </div>
            {options.map((opts, i) => (
              <Grid className={classes.livefireOptions} container key={`live-fire-options-${i}`} spacing={2}>
                {opts.map(opt => (
                  <Grid item xs={12} sm={6} key={`live-fire-option-${i}-${opt.key}`}>
                    <DynamicInput
                      option={opt}
                      onUpdate={input => setInputs(prev => {
                        const inputs = [...prev];
                        inputs[i] = [...prev[i], input];
                        return inputs;
                      })}
                      value={optionInputs[i].find(input => input.key === opt.key)?.value}
                    />
                  </Grid>
                ))}
              </Grid>
            ))}
            {content.id && (
              <Button
                variant="contained"
                color="primary"
                disabled={disabled}
                endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
                onClick={handleClick}
              >
                Get Started
              </Button>
            )}
            {!!lftTrainings && (
              <Typography variant="h4" color="secondary" align="center" style={{ marginTop: 40 }}>
                There {lftTrainings > 1 ? 'are' : 'is'} {lftTrainings} training{lftTrainings > 1 && 's'}
                &nbsp;ahead of you. Click the button to join the queue.
              </Typography>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
}

interface LivefireModalProps {
  open: boolean;
  onClose: () => unknown;
  onStart: () => unknown;
  content?: TrainingGroupLivefireContent;
}
