import { FormEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Modal from '../../../../reusables/Modal';
import { useFrameworkContext } from '../FrameworkContext';

export interface ThreatScoreModalProperties {
  open?: boolean;
  title?: string;
  groupId?: string;
  identifier?: string;
}

export default function ThreatScoreModal({
  open = false, title = '', groupId = '', identifier = '',
}: ThreatScoreModalProperties) {
  const [score, setScore] = useState('');
  const [scoreError, setScoreError] = useState('');

  const { createThreatIntel, setModalThreatScoreProperties } = useFrameworkContext();

  const handleClose = () => {
    setModalThreatScoreProperties({ open: false });
  };

  const handleSaveClick = async (e: FormEvent) => {
    e.preventDefault();
    if (!score) {
      setScoreError('score is required');
      return;
    }

    await createThreatIntel({ groupId: groupId as string, identifier: identifier as string, score: +score });

    handleClose();
  };

  const handleScoreChange: React.ChangeEventHandler<HTMLInputElement> = ({ target: { value } }) => {
    const numberValue = +value;

    if (numberValue > 100) {
      setScoreError('Cannot exceed 100');
      return;
    }

    if (numberValue < 0) {
      setScoreError('Must be at least 0');
      return;
    }

    const newValue = value === '' ? '' : `${numberValue}`;
    setScore(newValue);
    setScoreError('');
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      onSubmit={handleSaveClick}
      title={title}
      actions={[
        { text: 'Save', submit: true, color: 'primary' },
        { text: 'Cancel', onClick: handleClose },
      ]}
    >
      <TextField
        error={!!scoreError}
        helperText={scoreError}
        autoFocus
        variant="standard"
        margin="dense"
        label="Score"
        type="number"
        InputProps={{
          // @ts-ignore this is fixed in @mui/material 4.9.11
          min: 0,
          max: 100,
          endAdornment: (
            <InputAdornment position="end">
              out of 100
            </InputAdornment>
          ),
        }}
        fullWidth
        value={score}
        onChange={handleScoreChange}
      />
    </Modal>
  );
}

