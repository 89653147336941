import React from 'react';
import { Button } from '@mui/material';
import logsService from '../../../services/LogsService';

function DownloadLatest() {
  const downloadLatest = async () => {
    const file = await logsService.downloadLatest();
    const data = new Blob([window.atob(file)], { type: 'application/text' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', 'log.txt');
    link.click();
  };

  return (
    <Button color="primary" variant="contained" onClick={downloadLatest}>Download Latest</Button>
  );
}

export default React.memo(DownloadLatest);
