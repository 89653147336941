import { clusterBy } from '@sightgain/core';
import { average, round } from '@sightgain/core/calculations';
import { AnalystScore, LFT, StudentItem } from './interfaces';

// compute score scenario data
export function scoreData(students: StudentItem[], trainings: LFT[]): AnalystScore[] {
  const validEmails = students.map(s => s.email);

  const scores = (
    clusterBy(
      'analyst',
      trainings.map(({ analyst, score, instructorScore }) => ({ analyst: analyst.email, instructorScore, score })),
    ) as Array<{ analyst: string; instructorScore: number[]; score: number[] }>
  )
    // average the scores
    .map(({ analyst, instructorScore, score }) => ({
      analyst,
      instructorScore: round(average(instructorScore), 0),
      score: round(average(score), 0),
    }))
    // remove missing students
    .filter(({ analyst }) => validEmails.includes(analyst));

  return scores.map(({ analyst, instructorScore, score }) => {
    const student = students.find(s => s.email === analyst) as StudentItem;

    return { ...student, score, instructorScore };
  });
}

export function sortScores(scores: AnalystScore[], byInstructorScore = false) {
  return [...scores].sort((a, b) => (byInstructorScore ? b.instructorScore - a.instructorScore : b.score - a.score));
}
