import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import LiveFireSVG from '../../images/badge-livetraining.svg';

const useStyles = makeStyles(theme => ({
  badge: {
    position: 'relative',
    display: 'inline-flex',
  },
  icon: {
    position: 'absolute',
    fontSize: theme.spacing(4),
    top: theme.spacing(4.75),
    left: theme.spacing(5.5),
  },
}));

export default function LiveFireBadge() {
  const classes = useStyles();

  return (
    <div className={classes.badge}>
      <FontAwesomeIcon className={classes.icon} icon={['fal', 'bolt']} />
      <img src={LiveFireSVG} alt="Live Fire Training" />
    </div>
  );
}
