import { makeStyles } from '@mui/styles';
import SyntaxHighlighter, { theme } from './SyntaxHighlighter';

const useStyles = makeStyles(() => ({
  wrapper: {
    maxHeight: 300,
    maxWidth: '100%',
    overflow: 'scroll',
    marginBottom: 31,
  },
}));

function parseData(data: string) {
  try {
    return JSON.stringify(JSON.parse(data), null, 2);
  } catch (e) {
    return data;
  }
}

export default function HighlightedTextField({ data, language = 'text' }: HighlightedTextFieldProps) {
  const classes = useStyles();
  let prettyValue = data;
  if (language === 'json') {
    prettyValue = parseData(data);
  }

  return (
    <div className={classes.wrapper} data-language={language}>
      <SyntaxHighlighter language={language} style={theme}>
        {prettyValue}
      </SyntaxHighlighter>
    </div>
  );
}

interface HighlightedTextFieldProps {
  data: string;
  language?: string;
}
