import Typography from '@mui/material/Typography';
import Error from '@mui/icons-material/Error';
import Check from '@mui/icons-material/Check';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: '12px',
    color: 'red',
  },
  rule: {
    display: 'flex',
    justifyItems: 'center',
  },
  icon: {
    marginLeft: theme.spacing(3),
  },
  text: {
    marginLeft: theme.spacing(2),
  },
  passed: {
    color: 'green',
  },
}));

interface PasswordComplexityProps {
  password: string;
}

export default function PasswordComplexity({ password }: PasswordComplexityProps) {
  const classes = useStyles();

  if (!password) {
    return <></>;
  }

  // check individual complexities
  const rules = [
    { text: 'at least 8 characters long', pass: password.length >= 8 },
    { text: 'at least one upper case letter (A - Z)', pass: password.match(/[A-Z]/g) },
    { text: 'at least one lower case letter (a - z)', pass: password.match(/[a-z]/g) },
    { text: 'at least one digit (0 - 9)', pass: password.match(/[0-9]/g) },
    { text: 'at least one special character of !@#$%&*()', pass: password.match(/[!@#$%&*()]/g) },
  ].map((r, i) => ({ ...r, key: `validation-rule-${i}` }));

  return (
    <div className={classes.root}>
      <Typography>Password is not complex enough</Typography>

      {rules.map(({ key, pass, text }) => (
        <div key={key} className={clsx([classes.rule, { [classes.passed]: pass }])}>
          <div className={classes.icon}>
            {pass && <Check aria-hidden="false" aria-label="Rule passed" />}
            {!pass && <Error aria-hidden="false" aria-label="Rule failed" />}
          </div>
          <Typography className={classes.text}>{text}</Typography>
        </div>
      ))}
    </div>
  );
}
