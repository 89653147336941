import { FormControl, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ChangeEvent, useState } from 'react';

const useStyles = makeStyles({
  formControl: {
    minWidth: 140,
  },
});

export default function PreviewSelects() {
  const classes = useStyles();
  const [age, setAge] = useState('10');

  const handleChange = (event: ChangeEvent<{ value: unknown }>) => {
    setAge(event.target.value as string);
  };

  return (
    <>
      <h2> Selects </h2>
      <FormControl className={classes.formControl}>
        <Select
          labelId="demo-simple-select-outlined-label"
          id="demo-simple-select-outlined"
          value={age}
          onChange={handleChange as any}
        >
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
    </>
  );
}