import { endOfWeek, startOfWeek, subWeeks } from 'date-fns';
import { FetchPolicy, gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import {
  Achievement,
  AchievementProgress,
  ActivityTimeInput,
  CalendarEvent,
  CreateCalendarEventInput,
  GroupProgress,
  GuidedTrainingDetails,
  LiveFireTraining,
  QuestionInput,
  QuizResult,
  RecommendedCourses,
  RunLiveFire,
  Student,
  StudentActivity,
  StudentActivityContent,
  StudentActivityGroup,
  StudentActivityTime,
  StudentDashboard,
  StudentWeeklyProgress,
  TrainingContent,
  TrainingGroupContents,
  TrainingGroupDetails,
  TrainingGroupLivefireContent,
  TrainingGroupType,
} from '../interfaces';
import { typeLiveFireTraining } from '../LiveFireTrainingsService/fields';
import ServiceBase from '../ServiceBase';
import {
  typeAchievement,
  typeAchievementProgress,
  typeCalendarEvents,
  typeCompletedProgressByType,
  typeCourseDetails,
  typeCourseName,
  typeCurriculumDetails,
  typeEventTrainingGroupDetails,
  typeGroupedContentDetails,
  typeRecommendedCourses,
  typeStudent,
  typeStudentActivity,
  typeStudentActivityContent,
  typeStudentActivityGroup,
  typeStudentActivityTime,
  typeStudentWeeklyProgress,
  typeTrainingGroupContentsGuidedTraining,
  typeTrainingGroupContentsSummary,
  typeTrainingGroupLivefireContents,
} from './typeFields';

export class CurriculumService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('before', options => ({
      ...options,
      url: options.url === 'graphql' ? options.url : `jobs/${options.url}`,
    }));
    this.addMiddleWare('after', data => toDates(data, ['timestamp', 'completionDate', 'startDate', 'updatedAt']));
  }

  async curriculums(student: string | null = null, fields = typeCurriculumDetails): Promise<TrainingGroupDetails[]> {
    const query = gql`
      query Curriculums($student: String) {
        curriculums(student: $student) ${fields}
      }`;
    const { curriculums } = await this.graphql(query, { student }, 'no-cache');
    return curriculums;
  }

  async student(
    studentEmail: string | null = null,
    fields = typeStudent,
    fetchPolicy: FetchPolicy = 'cache-first',
  ): Promise<Student> {
    const query = gql`
      query Student($student: String!) {
        student(student: $student) ${fields}
      }`;
    const { student } = await this.graphql(query, { student: studentEmail }, fetchPolicy);
    return student;
  }

  async students(fields = typeStudent, fetchPolicy: FetchPolicy = 'cache-first'): Promise<Student[]> {
    const query = gql`
      query Students {
        students ${fields}
      }`;
    const { students } = await this.graphql(query, {}, fetchPolicy);
    return students;
  }

  async courseName(id: string, fields = typeCourseName) {
    return this.trainingGroupDetails(undefined, id, fields);
  }

  async eventTrainingGroupDetails(id: string, student: string | null = null, fields = typeEventTrainingGroupDetails) {
    return this.trainingGroupDetails(student, id, fields);
  }

  async curriculumDetails(id: string, student: string | null = null, fields = typeCurriculumDetails) {
    return this.trainingGroupDetails(student, id, fields);
  }

  async courseDetails(id: string, student: string | null = null, fields = typeCourseDetails) {
    return this.trainingGroupDetails(student, id, fields);
  }

  async trainingGroupDetails(student: string | null = null, id: string, fields: string): Promise<TrainingGroupDetails> {
    const query = gql`
      query TrainingGroupDetails($student: String, $id: String!) {
        trainingGroupDetails(student: $student, id: $id) ${fields}
      }`;
    const { trainingGroupDetails } = await this.graphql(query, { student, id });
    return trainingGroupDetails;
  }

  async trainingGroupParent(id: string): Promise<TrainingGroupDetails> {
    const query = gql`
      query TrainingGroupParent($id: String!) {
        trainingGroupParent(id: $id) {
          id
          title
        }
      }
    `;
    const { trainingGroupParent } = await this.graphql(query, { id });
    return trainingGroupParent;
  }

  async trainingGroupLivefireContents(
    id: string,
    fields = typeTrainingGroupLivefireContents,
  ): Promise<TrainingGroupLivefireContent[]> {
    const query = gql`
      query TrainingGroupLivefireContents($id: String!) {
        trainingGroupLivefireContents(id: $id) ${fields}
      }`;
    const { trainingGroupLivefireContents } = await this.graphql(query, { id });
    return trainingGroupLivefireContents;
  }

  async trainingContentLivefire(
    id: string,
    fields = typeTrainingGroupLivefireContents,
  ): Promise<TrainingGroupLivefireContent> {
    const query = gql`query TrainingContentLivefire($id: String!) {
      trainingContentLivefire(id: $id) ${fields}
    }`;

    const { trainingContentLivefire } = await this.graphql(query, { id });

    return trainingContentLivefire;
  }

  async groupedContentDetails(
    student: string,
    quizId: string,
    lessonIds: string[],
    fields = typeGroupedContentDetails,
  ): Promise<GuidedTrainingDetails> {
    const query = gql`
      query GroupedContentDetails($student: String!, $quizId: String!, $lessonIds: [String!]!) {
        groupedContentDetails(student: $student, quizId: $quizId, lessonIds: $lessonIds) ${fields}
      }`;
    const { groupedContentDetails } = await this.graphql(query, { student, quizId, lessonIds }, 'no-cache');
    return groupedContentDetails;
  }

  async trainingContent(id: string): Promise<TrainingContent> {
    const query = gql`
      query TrainingContent($id: String!) {
        trainingContent(id: $id) {
          id
          type
          livefireEvaluation {
            id
          }
        }
      }
    `;
    const { trainingContent } = await this.graphql(query, { id }, 'no-cache');
    return trainingContent;
  }

  async trainingGroupContentsSummary(student: string, id: string) {
    return this.trainingGroupContents(student, id, typeTrainingGroupContentsSummary);
  }

  async trainingGroupContentsGuidedTraining(student: string, id: string) {
    return this.trainingGroupContents(student, id, typeTrainingGroupContentsGuidedTraining);
  }

  async trainingGroupContents(student: string, id: string, fields: string): Promise<TrainingGroupContents> {
    const query = gql`
      query TrainingGroupContents($student: String!, $id: String!) {
        trainingGroupContents(student: $student, id: $id) ${fields}
      }`;
    const { trainingGroupContents } = await this.graphql(query, { student, id }, 'no-cache');
    return trainingGroupContents;
  }

  async submitQuiz(
    student: string,
    quizId: string,
    lessonIds: string[],
    answers: string,
    instance: QuestionInput[],
  ): Promise<QuizResult> {
    const query = gql`
      mutation SubmitQuiz($input: SubmitQuizInput!) {
        submitQuiz(input: $input)
      }
    `;
    const { submitQuiz } = await this.graphql(query, {
      input: {
        student,
        quizId,
        lessonIds,
        answers,
        instance,
      },
    });
    return submitQuiz;
  }

  async completeContent(student: string, contentId: string): Promise<void> {
    const query = gql`
      mutation CompleteContent($student: String!, $contentId: String!) {
        completeContent(student: $student, contentId: $contentId)
      }
    `;
    await this.graphql(query, { student, contentId });
  }

  async calendarEvents(attendee: string, day?: string): Promise<CalendarEvent[]> {
    const query = gql`
      query CalendarEvents($attendee: String, $day: String) {
        calendarEvents(attendee: $attendee, day: $day) ${typeCalendarEvents}
      }`;
    const { calendarEvents } = await this.graphql(query, { attendee, day }, 'network-only');
    return calendarEvents;
  }

  async createCalendarEvent(input: CreateCalendarEventInput, fields = typeCalendarEvents): Promise<CalendarEvent> {
    const query = gql`
      mutation CreateCalendarEvent($input: CreateCalendarEventInput!) {
        createCalendarEvent(input: $input) ${fields}     
      }
    `;

    const { createCalendarEvent } = await this.graphql(query, { input });
    return createCalendarEvent;
  }

  async studentActivity(
    student: string,
    groupType: TrainingGroupType,
    fields = typeStudentActivity,
    fetchPolicy: FetchPolicy = 'cache-first',
  ): Promise<StudentActivity> {
    const query = gql`
      query StudentActivity($student: String!, $groupType: TrainingGroupType!) {
        studentActivity(student: $student, groupType: $groupType) ${fields}
      }`;
    const { studentActivity } = await this.graphql(query, { student, groupType }, fetchPolicy);
    return studentActivity;
  }

  async studentActivityTime(
    input: ActivityTimeInput,
    fields = typeStudentActivityTime,
  ): Promise<StudentActivityTime[]> {
    const query = gql`
      query StudentActivityTime($input: ActivityTimeInput!) {
        studentActivityTime(input: $input) ${fields}
      }`;
    const { studentActivityTime } = await this.graphql(query, { input }, 'network-only');
    return studentActivityTime;
  }

  async studentActivityGroup(
    student: string,
    groupId: string,
    fields = typeStudentActivityGroup,
  ): Promise<StudentActivityGroup> {
    const query = gql`
      query StudentActivityGroup($student: String!, $groupId: String!) {
        studentActivityGroup(student: $student, groupId: $groupId) ${fields}
      }`;
    const { studentActivityGroup } = await this.graphql(query, { student, groupId });
    return studentActivityGroup;
  }

  async studentActivityContent(
    student: string,
    contentType: string,
    fields = typeStudentActivityContent,
  ): Promise<StudentActivityContent> {
    const query = gql`
      query StudentActivityContent($student: String!, $contentType: String!) {
        studentActivityContent(student: $student, contentType: $contentType) ${fields}
      }`;
    const { studentActivityContent } = await this.graphql(query, { student, contentType });
    return studentActivityContent;
  }

  async achievements() {
    const query = gql`
      query Achievements {
        achievements ${typeAchievement}
      }`;
    const { achievements } = await this.graphql(query);
    return achievements;
  }

  async achievementsProgress(student: string, fields = typeAchievementProgress): Promise<AchievementProgress[]> {
    const query = gql`
      query AchievementsProgress($student: String!) {
        achievementsProgress(student: $student) ${fields}     
      }
    `;

    const { achievementsProgress } = await this.graphql(query, { student });
    return achievementsProgress;
  }

  async logActivity(student: string, contentId: string, groupIds: string[]): Promise<string> {
    const query = gql`
      mutation LogActivity($input: LogActivityInput!) {
        logActivity(input: $input)
      }
    `;

    const { logActivity } = await this.graphql(query, { input: { student, contentId, groupIds } });
    return logActivity;
  }

  async recentAchievements(student: string, days?: number, fields = typeAchievement): Promise<Achievement[]> {
    const query = gql`
      query RecentAchievements($student: String!, $days: Int) {
        recentAchievements(student: $student, days: $days) ${fields}
      }
    `;

    const { recentAchievements } = await this.graphql(query, { student, days }, 'no-cache');
    return recentAchievements;
  }

  async studentDashboard(student: string): Promise<StudentDashboard> {
    const query = gql`
      query StudentDashboard(
        $student: String!
        $lifetimeDurationInput: ActivityTimeInput!
        $thisWeekDurationInput: ActivityTimeInput!
        $lastWeekDurationInput: ActivityTimeInput!
      ) {
        totalLifetimeTrainingDurationMinutes: studentTotalTimeSpentMinutes(input: $lifetimeDurationInput)
        trainingMinutesThisWeek: studentTotalTimeSpentMinutes(input: $thisWeekDurationInput)
        trainingMinutesLastWeek: studentTotalTimeSpentMinutes(input: $lastWeekDurationInput)
        totalLifetimeCourses: studentTotalCompletedCourses(student: $student)
      }
    `;

    const lifetimeDurationInput = {
      student,
      end: Date.now(),
      start: new Date(2000),
    };

    const thisWeekDurationInput = {
      student,
      end: endOfWeek(Date.now()),
      start: startOfWeek(Date.now()),
    };

    const aWeekAgo = subWeeks(Date.now(), 1);
    const lastWeekDurationInput = {
      student,
      end: endOfWeek(aWeekAgo),
      start: startOfWeek(aWeekAgo),
    };

    return this.graphql(query, {
      student,
      lifetimeDurationInput,
      thisWeekDurationInput,
      lastWeekDurationInput,
    });
  }

  async recommendedCourses(student: string, fields = typeRecommendedCourses): Promise<RecommendedCourses> {
    const query = gql`
      query RecommendedCourses($student: String!) {
        recommendedCourses(student: $student) ${fields}
      }
    `;

    const { recommendedCourses } = await this.graphql(query, { student });
    return recommendedCourses;
  }

  async studentWeeklyProgress(student: string, fields = typeStudentWeeklyProgress): Promise<StudentWeeklyProgress[]> {
    const query = gql`
      query StudentWeeklyProgress($student: String!) {
        studentWeeklyProgress(student: $student) ${fields}
      }
    `;

    const { studentWeeklyProgress } = await this.graphql(query, { student }, 'no-cache');
    return studentWeeklyProgress;
  }

  async completedProgressByType(
    student: string,
    groupType: TrainingGroupType,
    fields = typeCompletedProgressByType,
  ): Promise<GroupProgress[]> {
    const query = gql`
    query CompletedProgressByType($student: String!, $groupType: TrainingGroupType!) {
      completedProgressByType(student: $student, groupType: $groupType) ${fields}
    }
    `;
    const { completedProgressByType } = await this.graphql(query, { student, groupType });
    return completedProgressByType;
  }

  async run(input: RunLiveFire, fields = typeLiveFireTraining): Promise<LiveFireTraining> {
    const query = gql`
      mutation runLivefire($input: RunLiveFireInput!) {
        runLivefire(input: $input) ${fields}
      }
    `;

    const { runLivefire } = await this.graphql(query, { input });
    return runLivefire;
  }
}

const curriculumSerivce = new CurriculumService();
export default curriculumSerivce;
