import {
  CssBaseline,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';
import lightshine from '../../images/login_lightshine.svg';
import logo from '../../images/login_logo.svg';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  paper: {
    position: 'absolute',
    right: 0,
    top: 0,
    height: '100%',
    width: 'calc(100vw - 646px)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('lg')]: {
      paddingTop: 280,
      justifyContent: 'unset',
      right: 'unset',
    },
  },
  content: {
    minWidth: 500,
    paddingLeft: 12,
    paddingRight: 12,
    [theme.breakpoints.down('lg')]: {
      marginBottom: 150,
    },
  },
  background: {
    width: 627,
    opacity: 0.40,
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -2,
    visibility: 'visible',
    transition: 'opacity 1s linear',
    [theme.breakpoints.down('lg')]: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s 1s, opacity 1s linear',
    },
  },
  rectangle: {
    width: 646,
    height: '100%',
    objectFit: 'contain',
    boxShadow: '20px 20px 20px 0 rgba(0, 0, 0, 0.17)',
    backgroundColor: '#161926',
    position: 'absolute',
    left: 0,
    top: 0,
    zIndex: -3,
    visibility: 'visible',
    transition: 'opacity 1s linear',
    [theme.breakpoints.down('lg')]: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s 1s, opacity .75s linear',
    },
  },
  desk: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    zIndex: -1,
    visibility: 'visible',
    transition: 'opacity 1s linear',
    [theme.breakpoints.down('lg')]: {
      visibility: 'hidden',
      opacity: 0,
      transition: 'visibility 0s 1s, opacity .75s linear',
    },
  },
  logo: {
    width: 354,
    position: 'absolute',
    left: 155,
    bottom: 470,
    [theme.breakpoints.down('lg')]: {
      top: 100,
      marginLeft: -20,
      left: 'unset',
      bottom: 'unset',
    },
  },
  gradient: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'linear-gradient(#1f2f44, #161926)',
    zIndex: -4,
    overflow: 'hidden',
  },
}));

/**
 * 
 */
export default function NoAuth({ children, background = lightshine, foreground }: NoAuthProps) {

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.gradient} />
      <img src={background} className={classes.background} alt="lightshine" />
      <div className={classes.rectangle} />
      {foreground && <img src={foreground} className={classes.desk} alt="desk" />}
      <img className={classes.logo} src={logo} alt="SightGain" />
      <CssBaseline />
      <div className={classes.paper}>
        <div className={classes.content}>
          {children}
        </div>
      </div>
    </div>
  );
}

type NoAuthProps = {
  children: ReactNode;
  background?: string;
  foreground?: string;
}
