import { useEffect } from 'react';
import { curriculumService } from '../services';

/**
 * Log student activity every **timeout** miliseconds.
 *
 * @param student - Student ID
 * @param contentId - Content ID associated with current activity
 * @param timeout - Update interval
 */
export default function useActivity(student: string, contentId: string, groupIds: string[]) {
  useEffect(() => {
    if (contentId === undefined) {
      return;
    }

    const interval = setInterval(() => {
      curriculumService.logActivity(student, contentId, groupIds);
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, [contentId]);
}