import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment } from '@mui/material';
import { useEffect } from 'react';
import TextField from '../../../reusables/TextField';
import URLField from '../../../reusables/URLField';
import { SystemUser } from '../../../services/interfaces';

import Row from '../components/Row';
import { SIEMUpdateArgs } from '../interfaces';

export default function SplunkForm({ siemUser, dataIndexes, alertIndexes, update, setTestEnabled }: SplunkFormProps) {
  const {
    systemUser, query, protocol = 'https', host, port, password,
  } = siemUser;

  // eslint-disable-next-line no-useless-escape
  const validHost = (h: string) => /^([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])(\.([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]{0,61}[a-zA-Z0-9]))*$/.test(h);
  const validPort = (p?: number) => (p: string | number = '') => /^\d{1,5}$/.test(`${p ?? ''}`);

  // Allow Test Connection when all fields are supplied
  useEffect(() => {
    setTestEnabled(!!(validHost(host as string)
      && validPort(port)
      && systemUser
      && password));
  });

  return (
    <>
      <Row>
        <URLField
          protocol={protocol}
          host={host}
          port={port}
          onChange={update}
        />
      </Row>
      <Row>
        <TextField
          onChange={e => update({ systemUser: e.target.value })}
          startAdornment={(
            <InputAdornment position="start"><FontAwesomeIcon size="lg" icon={['fal', 'user']} /></InputAdornment>)}
          value={systemUser}
          label="Username"
        />
        <TextField
          onChange={e => update({ password: e.target.value })}
          startAdornment={(
            <InputAdornment position="start">
              <FontAwesomeIcon size="lg" icon={['fal', 'lock']} />
            </InputAdornment>
          )}
          label="Password"
          type="password"
          value={password}
        />
      </Row>
      <Row>
        <TextField
          onChange={e => update({ query: e.target.value })}
          value={query}
          label="Test Connection Query"
        />
      </Row>
      <Row>
        <TextField
          onChange={e => update({ dataIndexes: e.target.value })}
          value={dataIndexes}
          label="Data Indexes"
        />
        <TextField
          onChange={e => update({ alertIndexes: e.target.value })}
          value={alertIndexes}
          label="Alert Indexes"
        />
      </Row>
    </>
  );
}

interface SplunkFormProps {
  siemUser: SystemUser;
  dataIndexes: string;
  alertIndexes: string;
  update: (v: SIEMUpdateArgs) => void;
  setTestEnabled: (v: boolean) => void;
}
