import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { AuditRecord } from './interfaces';
import ServiceBase from './ServiceBase';

const typeAuditRecord = `
  {
    id
    createdAt
    name
    user {
      name
    }
  }
`;

class AuditService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['createdAt']));
  }

  /**
   * List Audit Records
   */
  async list(recordType: string, fields = typeAuditRecord): Promise<AuditRecord[]> {
    const input = { type: recordType };

    const query = gql`
      query AuditRecords($input: AuditRecordInput) {
        auditRecords(input: $input) ${fields}
      }
    `;

    const { auditRecords } = await this.graphql(query, { input });

    return auditRecords;
  }
}

const auditService = new AuditService();
export default auditService;
