import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { RichText } from '../../../../reusables/scl';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
  },
}));

interface LessonTextProps {
  title: string;
  text: string;
}

export default function LessonText({ title, text }: LessonTextProps) {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      <RichText text={text} />
    </div>
  );
}
