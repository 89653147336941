import { makeStyles } from '@mui/styles';
import { transpose } from '@sightgain/core';
import { average, parseNaN, round } from '@sightgain/core/calculations';
import Indicator from '../../../reusables/Indicator';
import { ScoredFramework, ScoredFrameworkItem } from '../../../services/interfaces';
import { PageTab } from './interfaces';

const useStyles = makeStyles(theme => ({
  summary: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginBottom: 20,
    marginTop: 20,
    gap: 20,
  },
}));

export default function FrameworkSummary({ scoredFramework, activeTab }: FrameworkSummaryProps) {
  const classes = useStyles();
  // is this an attack framework
  const isAttack = scoredFramework?.type === 'attack';
  // is this a threat tab
  const hasThreats = [PageTab.RISK_POSTURE, PageTab.THREAT_INTELLIGENCE].includes(activeTab);

  // get the tactics
  const items = (scoredFramework?.groups.flatMap(group => group.items) || []) as ScoredFrameworkItem[];

  // the number of controls that can be tested
  const testable = items.filter(t => t.testIds.length);

  // transpose the test trends
  const transposed = transpose<number | null>(items.map(t => t.testTrend));

  // merge the test trends
  const testTrendScores = transposed.map(s => round(average(s.filter(s => s !== null) as number[])));

  // count the types of test results
  const passedTrend = [...Array(24).keys()]
    .map((k, i) => testable.filter(({ testTrend }) => (testTrend[i] ?? 0) > 75).length)
    .map(s => round(parseNaN(s / testable.length) * 100, 0));

  // merge the attack trends
  const attackTrends = transpose<number | null>(items.map(trend => trend.postureTrend)).map(trend => {
    const scores = trend.filter(n => n !== null) as number[];
    return round(average(scores));
  });

  // get the number of tested controls
  const tested = transposed.map(s => s.filter(n => n !== null).length);

  // iterate over the 24 day period
  let untestedTrend: number[] = [];
  let untestedMax = 0;
  let untestedTitle = 'Untested Threat Techniques';
  if (hasThreats) {
    untestedTrend = [...Array(24).keys()].map(n => {
      // if there aren't 24 days worth of threat trend data, return a 0 for this day
      if (scoredFramework.threatTrends.length <= n) {
        return 0;
      }

      // get the identifiers related to this day
      const threats = scoredFramework.threatTrends[n];

      // see if a threat was tested or not
      const tested = threats.filter(t => items.some(i => i.identifier === t && i.testTrend[n] !== null));

      // subtract the number of tested threats from the number of threats
      return threats.length - tested.length;
    });

    untestedMax = Math.max(...scoredFramework.threatTrends.map(t => t.length));
  } else {
    untestedTitle = 'Untested Controls';
    untestedMax = testable.length;
    untestedTrend = [...Array(24).keys()].map(n => {
      const controls = new Map(testable.map(i => [i.identifier, i.testTrend[n]] as [string, number | null]));
      const tested = [...controls.values()].filter(v => v);

      return controls.size - tested.length;
    });
  }

  return (
    <div className={classes.summary}>
      <Indicator title="Average Test Score" data={testTrendScores} percentage improvement="higher" max={1000} />

      {!isAttack && (
        <Indicator title="Technical Controls Tested" data={tested} improvement="higher" max={items.length} />
      )}

      {isAttack && (
        <Indicator title="Organizational Risk Posture" percentage data={attackTrends} improvement="higher" max={1000} />
      )}

      <Indicator percentage title="Technical Controls Passed" data={passedTrend} improvement="higher" max={1000} />

      <Indicator title={untestedTitle} data={untestedTrend} improvement="lower" max={untestedMax} />
    </div>
  );
}

interface FrameworkSummaryProps {
  scoredFramework: ScoredFramework;
  activeTab: PageTab;
}
