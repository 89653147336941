import { format, parseISO } from 'date-fns';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { formatDateISO } from '@sightgain/core/dates';
import appStore from '../../../../../AppStore';
import { curriculumService } from '../../../../services';
import { TrainingGroupDetails, TrainingGroupPreview } from '../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  wrapper: {
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    marginTop: 49,
    height: 116,
  },
  headerLeft: {
    flex: 0,
    flexGrow: 2,
    paddingRight: 12,
  },
  headerRight: {
    flexShrink: 0,
    flexGrow: 1,
  },
  input: {
    marginTop: 20,
    width: '100%',
    borderRadius: '3px',
    border: 'solid 1px #61788e',
    '& > div': {
      '& textarea': {
        padding: '11px 18px',
        fontSize: 14,
        fontFamily: 'Lexend !important',
      },
    },
  },
  date: {
    verticalAlign: 'baseline',
    width: 120,
    '& > div > input': {
      padding: 0,
    },
  },
  icon: {
    marginLeft: -25,
    color: '#2ecff5',
  },
}));

export default function ItemDetails({
  className,
  setSelectedItem,
  setOpen,
  item,
  date,
  onScheduled,
  attendee,
}: ItemDetailsProps) {
  const classes = useStyles();
  const [description, setDescription] = useState('');
  const [day, setDay] = useState(format(date, 'yyyy-MM-dd'));
  const [openPicker, setOpenPicker] = useState(false);

  const handleClick = async () => {
    appStore.beginLoading();
    try {
      await curriculumService.createCalendarEvent({
        owner: appStore.userId,
        attendee,
        day,
        description,
        trainingGroupId: item?.id as string,
        status: 'scheduled',
      });
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
    onScheduled();
    setOpen(false);
  };

  return (
    <div className={`classes.wrapper ${className}`}>
      <Button color="primary" onClick={() => setSelectedItem()}>
        <FontAwesomeIcon icon={['fal', 'arrow-left']} />
        &nbsp; Back to Selection
      </Button>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Typography variant="body1">
            <b>Course Name</b>
          </Typography>
          <Typography variant="h3" style={{ marginTop: 17 }}>
            {item?.title ?? ''}
          </Typography>
        </div>
        <div className={classes.headerRight}>
          <Typography variant="body1">
            <b>Date Scheduled</b>
          </Typography>
          <div style={{ marginTop: 17 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                format="MM/dd/yyyy"
                value={parseISO(day)}
                onChange={d => setDay(formatDateISO(d as Date))}
                open={openPicker}
                onClose={() => setOpenPicker(false)}
                className={classes.date}
                onOpen={() => setOpenPicker(true)}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <Typography variant="body1">
        <b>Notes / Description</b>
      </Typography>
      <TextField
        className={classes.input}
        multiline
        rows={10}
        value={description}
        onChange={e => setDescription(e.target.value)}
        data-testid="description"
      />
      <div style={{ marginTop: 46 }}>
        <Button variant="contained" color="primary" onClick={handleClick} data-testid="save-new-item">
          Add New Item &nbsp;&nbsp;&nbsp;
          <FontAwesomeIcon icon={['fal', 'arrow-right']} />
        </Button>
        <Button variant="outlined" style={{ marginLeft: 24 }} onClick={() => setOpen(false)}>
          Cancel &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <FontAwesomeIcon icon={['fal', 'arrow-right']} />
        </Button>
      </div>
    </div>
  );
}

type ItemDetailsProps = {
  className: string;
  setSelectedItem: (item?: TrainingGroupPreview | TrainingGroupDetails) => unknown;
  setOpen: (b: boolean) => unknown;
  item?: TrainingGroupPreview | TrainingGroupDetails;
  date: Date;
  onScheduled: () => unknown;
  attendee: string;
};
