import { Grid } from '@mui/material';
import { round } from '@sightgain/core/calculations';
import Indicator from '../../../reusables/Indicator';
import { RoiResult } from '../../../services/interfaces';
import FiltersAndGraphs from '../components/FiltersAndGraphs';
import ROICharts from './ROICharts';
import ROIGraphsContainer from './ROIGraphsContainer';
import ROIIndicators from './ROIIndicators';
import TechnologyCostBenefitChart from './TechnologyCostBenefitChart';
import TechnologyPerformanceToRoi from './TechnologyPerformanceToRoi';
import TechnologyRiskReductionChart from './TechnologyRiskReductionChart';

export default function Financial({ roi, max, thresholds }: FinacialProps) {
  return (
    <div data-testid="view-roi-investments">
      <FiltersAndGraphs>
        <ROIIndicators>
          <Indicator
            title="Security Portfolio Value"
            data={[roi.securityPortfolioValue ?? 0]}
            min={0}
            max={0}
            currency
            improvement="higher"
          />
          <Indicator
            title="Security Program ROI"
            data={roi.roiRatio.map(r => round(r, 2))}
            min={Math.min(...roi.roiRatio)}
            max={Math.max(...roi.roiRatio)}
            improvement="higher"
          />
          <Indicator
            title="Average Technology Value"
            data={roi.avgTechnologyValue}
            min={0}
            max={Math.max(...roi.avgTechnologyValue)}
            currency
            improvement="higher"
          />
          <Indicator
            title="Prevention Rate"
            data={roi.preventionRate.map(r => round(r * 100))}
            min={0}
            max={100}
            percentage
            improvement="higher"
          />
        </ROIIndicators>
      </FiltersAndGraphs>
      <ROIGraphsContainer>
        <ROICharts>
          <TechnologyCostBenefitChart costBenefit={roi.technologyCostBenefit} />
          <TechnologyPerformanceToRoi performanceToRoi={roi.technologyPerformanceToRoi} />
        </ROICharts>
        <Grid item xs={12}>
          <TechnologyRiskReductionChart riskReduction={roi.technologyRiskReduction} max={max} thresholds={thresholds} />
        </Grid>
      </ROIGraphsContainer>
    </div>
  );
}

interface FinacialProps {
  roi: RoiResult;
  max: number;
  thresholds: [number, number];
}
