import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputAdornment, InputProps, MenuItem } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import TextField from './TextField';

const useStyles = makeStyles({
  urlField: {
    flex: 1,
    display: 'flex',
    alignItems: 'flex-start',
    gap: 12,
    '& > div': {
      flex: '0 0 125px',
    },
    '& > div:nth-child(2)': {
      flex: 1,
    }
  },
});

export default function URLField({
  disableProtocol = false,
  protocol = 'https',
  port,
  host = '',
  hostProps = {  },
  protocolProps = {  },
  portProps = {  },
  onChange,
}: URLFieldProps) {

  const classes = useStyles();
  const [hostError, setHostError] = useState<string>();
  const [portError, setPortError] = useState<string>();

  const handlePortchange = (value: string) => {
    setPortError(undefined);

    if (!value) {
      onChange({ port: undefined, host, protocol });
      return;
    }

    const nPort = +value;
    if (Number.isNaN(nPort) || nPort < 255 || nPort > 65535) {
      setPortError('invalid port number');
    }

    onChange({ port: nPort, host, protocol });
  };

  const handleHostChange = (value: string) => {
    setHostError(undefined);

    if (!value) {
      setHostError('host is required');
    }

    if(!value.match(/^[a-zA-Z0-9\-.]+$/)) {
      setHostError('invalid host name');
    }

    onChange({ port, host: value, protocol });
  };

  return (
    <div className={classes.urlField}>
      {!disableProtocol && (
        <TextField
          {...protocolProps}
          type="select"
          label="Protocol"
          value={protocol}
          onChange={e => onChange({ port, host, protocol: e.target.value })}
          style={{ marginTop: 16 }}
        >
          <MenuItem value="https">https</MenuItem>
          <MenuItem value="http">http</MenuItem>
        </TextField>
      )}
      <TextField
        {...hostProps}
        label="Host"
        value={host}
        error={hostError}
        onChange={e => handleHostChange(e.target.value)}
        startAdornment={(
          <InputAdornment position="start">
            <FontAwesomeIcon size="lg" icon={['fal', 'globe']} />
          </InputAdornment>
        )}
      />
      <TextField
        {...portProps} 
        label="Port"
        value={`${port ?? ''}`}
        type="number"
        error={portError}
        onChange={e => handlePortchange(e.target.value)}
        startAdornment={(
          <InputAdornment position="start">
            <FontAwesomeIcon size="lg" icon={['fal', 'plug']} />
          </InputAdornment>
        )}
      />
    </div>
  );
}

interface URLFieldProps {
  disableProtocol?: boolean;
  protocol?: string;
  host?: string;
  port?: number;
  onChange: (value: { port?: number, protocol: string; host: string; }) => unknown;
  hostProps?: InputProps;
  protocolProps?: InputProps;
  portProps?: InputProps;
}