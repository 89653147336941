import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { ThreatPreparednessGroup, ThreatPreparednessInput } from './interfaces';
import ServiceBase from './ServiceBase';

const typeThreatPreparednessGroup = `
{
  id
  name
  updatedAt
  stages {
    name
    groupId
    knownTechniques
    testedTechniques
    untestedTechniques
    unknownTechniques
    probabilityOfPrevention
    probabilityOfDetection
    probabilityOfAlerting
    likelihoodOfKnownTechniques
    likelihoodOfUnknownTechniques
    tacticalLikelihoodOfKnownTechniques
    tacticalLikelihoodOfUnknownTechniques
    tacticalLikelihood
    likelihood
  }
}
`;

class ThreatPreparednessService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', (data: any) => toDates(data, ['updatedAt']));
  }
  async preparedness(
    input: ThreatPreparednessInput,
    fields = typeThreatPreparednessGroup,
  ): Promise<ThreatPreparednessGroup[]> {
    const { threatPreparedness } = await this.graphql(
      gql`query ThreatPreparedness($input: ThreatPreparednessInput!) { threatPreparedness(input: $input) ${fields} }`,
      { input },
      'no-cache',
    );
    return threatPreparedness;
  }

  async refreshPreparedness(
    input: ThreatPreparednessInput,
    fields = typeThreatPreparednessGroup,
  ): Promise<ThreatPreparednessGroup[]> {
    const { refreshThreatPreparedness } = await this.graphql(
      gql`mutation RefreshThreatPreparedness($input: ThreatPreparednessInput!) { refreshThreatPreparedness(input: $input) ${fields}}`,
      { input },
    );
    return refreshThreatPreparedness;
  }
}

const threatPrep = new ThreatPreparednessService();
export default threatPrep;
