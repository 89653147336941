import { makeStyles } from '@mui/styles';
import { Typography, Link } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { HTMLProps, ReactNode } from 'react';

function cleanText(input: string) {
  let output = input;
  // Remove citation
  output = output.replace(/\(Citation:[^)]+\)/g, '');
  // Proper code blcoks
  output = output.replace(/<code>(.+?)<\/code>/g, '`$1`');

  return output;
}

const useStyles = makeStyles(() => ({
  markdown: {
    display: 'inline-block',
  },
  markdownTable: {
    width: '100%',
    borderCollapse: 'collapse',
    overflow: 'hidden',
    borderRadius: '6px',
    marginTop: '12px',
    marginBottom: '12px',
    '& th, & td': {
      borderBottom: '2px solid #303F52',
      background: '#39485B',
      padding: '10px',
    },
  },
  subHeader: {
    color: '#a7adb5',
  },
  code: {
    color: '#1592ff',
  },
}));

const components = {
  table: (props: HTMLProps<HTMLTableElement>) => {
    const classes = useStyles();
    return <table {...props} className={classes.markdownTable} />;
  },
  p: ({ children }: { children: ReactNode }) => <Typography variant="body2">{children}</Typography>,
  code: ({ children }: { children: ReactNode }) => {
    const classes = useStyles();
    return <code className={classes.code}>{children}</code>;
  },
  a: ({ node, children }: { node: any; children: ReactNode }) => <Link {...node.properties}>{children}</Link>,
  h3: ({ children }: { children: ReactNode }) => (
    <>
      <br />
      <Typography variant="h6">{children}</Typography>
    </>
  ),
  h4: ({ children }: { children: ReactNode }) => {
    const classes = useStyles();
    return (
      <>
        <br />
        <Typography className={classes.subHeader} variant="h6">
          {children}
        </Typography>
      </>
    );
  },
};

interface RichTextProps {
  text?: string;
}

export default function RichText({ text = '' }: RichTextProps) {
  const classes = useStyles();

  return (
    <ReactMarkdown className={classes.markdown} components={components} remarkPlugins={[remarkGfm]}>
      {cleanText(text || '')}
    </ReactMarkdown>
  );
}
