import {
  Divider,
  Link,
} from '@mui/material';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import ButtonLink from '../../../../reusables/ButtonLink';
import { HostEvent, IntegrationEvent } from '../../../../services/interfaces';
import { eventDescription } from './parseEvent';

const useStyles = makeStyles(() => ({
  wrapper: {
    marginBottom: 12,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  matched: {
    color: '#2ecff5',
  }
}));

function EventItem({ data, matched, type, onClick }: EventItemProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <ButtonLink component={Link} onClick={onClick}>
          {eventDescription(data.raw)}
        </ButtonLink>
        {type === 'lft' && (
          <FontAwesomeIcon
            icon={['fal', matched ? 'clone' : 'question-square']}
            size="lg"
            className={clsx(matched && classes.matched)}
          />
        )}
      </div>
      <Divider style={{ marginTop: 4 }} />
    </div>
  );
}

interface EventItemProps {
  data: HostEvent | IntegrationEvent;
  type: 'job' | 'lft';
  matched: boolean;
  onClick: () => unknown;
}

export default EventItem;
