import { createContext } from 'react';
import { SocCacheItem } from './interfaces';

const SocContext = createContext({
  loadDataForTimeframe: (fromDate: Date, toDate: Date) => Promise.resolve({} as SocCacheItem),
  timeSpan: 30,
  setTimeSpan: (timeSpan: number) => {},
});

export default SocContext;
