import { makeStyles } from '@mui/styles';
import PreviewShowcase from './PreviewShowcase';
import PreviewTypography from './PreviewTypography';
import PreviewBadges from './PreviewBadges';
import PreviewSelects from './PreviewSelects';
import PreviewTabs from './PrviewTabs';
import PreviewToggles from './PreivewToggles';
import PreviewCalendar from './PreviewCalendar';
import PreviewMetrics from './PreviewMetrics';
import PreviewProgress from './PreviewProgress';
import PreviewCurriculums from './PreviewCurriculums';

const useStyles = makeStyles(() => ({
  scroll: {
    overflow: 'scroll',
    height: '100%',
    width: '100%',
  },
  wrapper: {
    marginLeft: 390,
    marginRight: 'auto',
    '& > *': {
      marginTop: 20,
      marginBottom: 20,
    },
  },
}));

function Preview() {
  const classes = useStyles();

  return (
    <div className={classes.scroll}>
      <PreviewShowcase />
      <PreviewTypography />
      <PreviewBadges />
      <PreviewSelects />
      <PreviewTabs />
      <PreviewToggles />
      <PreviewCalendar />
      <PreviewMetrics />
      <PreviewProgress />
      <PreviewCurriculums />
    </div>
  );
}

export default Preview;
