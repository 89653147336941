import { makeStyles } from '@mui/styles';
import { TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Link, Typography } from '@mui/material';
import { TrainingGroupMetadata } from '../../../services/interfaces';

const useStyles = makeStyles(() => ({
  link: {
    color: '#1592ff',
    textDecoration: 'underline',
  },
}));

interface MetadataItem {
  table: {
    entryId?: string;
    link?: string;
    description?: string;
  }[];
}

interface MetadataProps {
  metadata: MetadataItem | TrainingGroupMetadata;
}

export default function Metadata({ metadata }: MetadataProps) {
  const classes = useStyles();

  if (!metadata.table) {
    return null;
  }

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {metadata.table?.map(row => (
            <TableRow key={row.entryId}>
              <TableCell>
                <Link href={row.link}>
                  <Typography variant="body2" className={classes.link}>
                    {row.entryId}
                  </Typography>
                </Link>
              </TableCell>
              <TableCell>
                <Typography variant="body1">{row.description}</Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
