import { format } from 'date-fns';
import { Button, Typography } from '@mui/material';
import TileDetailCard from './TileDetailCard';
import { makeStyles } from '@mui/styles';
import { Evaluation } from '../../../../../services/interfaces';
import { useModal } from '../../../../../reusables/BetterModal';

const useStyles = makeStyles(() => ({
  title: {
    color: '#8797AB',
    fontSize: 13,
  }
}));

interface TileEvaluationProps {
  evaluation: Pick<Evaluation, 'id' | 'name' | 'description' | 'vendor' | 'vendorId' | 'runCount' | 'lastRun'>;
  ignored?: boolean;
  onRun: (vendorId: string) => unknown;
  onIgnore: (vendorId: string) => Promise<void>;
  onInclude: (vendorId: string) => Promise<void>;
}

export default function TileEvaluation(
  { evaluation, onIgnore, onInclude, onRun, ignored = false }: TileEvaluationProps) {
  const classes = useStyles();
  const { name, description, vendor, vendorId, runCount, lastRun } = evaluation;

  const lastRunDate = lastRun
    ? format(lastRun.createdAt, 'yyyy-MM-dd, HH:mm a')
    : 'Never';
  const evaluationTestResultsModal = useModal('evaluationTestResults');

  function showEvaluationTestResults() {
    evaluationTestResultsModal.openModal(vendorId);
  }
  
  const header = (
    <div style={{ display: 'flex', gap: 24, marginBottom: 12 }}>
      <div style={{ flex: 3, marginLeft: 5 }}>
        <Typography className={classes.title}>Name</Typography>
        <Typography>{name}</Typography>
      </div>
      <div style={{ flex: 1 }}>
        <Typography className={classes.title}>Source</Typography>
        <Typography style={{ textTransform: 'uppercase' }}>{vendor}</Typography>
      </div>
      <div style={{ flex: 2 }}>
        <Typography className={classes.title}>Last Run</Typography>
        <Typography>{lastRunDate}</Typography>
      </div>
      <div style={{ flex: 1 }}>
        <Typography align='center' className={classes.title}># Runs</Typography>
        <Typography align='center'>{runCount}</Typography>
      </div>
      <div style={{ display: 'flex', gap: 18 }}>
        <Button
          onClick={() => onRun(vendorId)}
          color="primary"
        >
            Run
        </Button>

        {!ignored &&
          <Button
            onClick={() => onIgnore(vendorId)}
            color="secondary"
          >
          Ignore
          </Button>
        }
        {ignored &&
          <Button
            onClick={() => onInclude(vendorId)}
            color="secondary"
          >
            Include
          </Button>
        }

      </div>
    </div>
  );

  const content = (
    <div>
      <Typography className={classes.title}>Description</Typography>
      <Typography>{description}</Typography>
    </div>
  );

  return (
    <TileDetailCard
      id={
        <Typography 
          color='blue3.main' 
          fontSize={14} 
          style={{ cursor: 'pointer' }} 
          onClick={showEvaluationTestResults}
        >
          {vendorId}
        </Typography>
      }
      header={header}
      content={content}
      ignored={ignored}
    />
  );
}