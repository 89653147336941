import { Accordion, AccordionDetails, AccordionSummary, Divider, Typography } from '@mui/material';
import { ReactNode } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


export default function FiltersAndGraphs({ children }: FiltersAndGraphsProps) {
  return (
    <Accordion sx={{ margin: '20px 0px', '&:before': { display: 'none' } }} elevation={0}>
      <AccordionSummary
        sx={{ padding: 0, '& > .MuiAccordionSummary-content': { justifyContent: 'space-between' } }}
        expandIcon={<ExpandMoreIcon sx={{ color: 'grey2.main' }} />}
      >
        <Typography variant="textStyle4" color="grey2.main">
        Filters & Graphs
        </Typography>
        <Divider sx={{ width: 'calc(100% - 150px)', alignSelf: 'center', marginRight: '10px' }} flexItem />
      </AccordionSummary>
      <AccordionDetails>
        {children}
      </AccordionDetails>
    </Accordion>
  );
}

interface FiltersAndGraphsProps {
  children: ReactNode | ReactNode[];
}