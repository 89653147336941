import { ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';

export default function PreviewToggles() {
  const [selected, setSelected] = useState('curriculums');

  return (
    <>
      <h2> Toggles </h2>
      <ToggleButtonGroup exclusive value={selected} onChange={(_, n) => setSelected(n)}>
        <ToggleButton value="curriculums"> Curriculums</ToggleButton>
        <ToggleButton value="courses"> Courses</ToggleButton>
      </ToggleButtonGroup>
    </>
  );
}
