import { ReactNode } from 'react';

export default function Row({ children }: { children: ReactNode | ReactNode[] }) {
  return (
    <div style={{
      display: 'flex', gap: 25, marginBottom: 33, alignItems: 'flex-start',
    }}
    >
      {children}
    </div>
  );
}
