import { gql } from '@apollo/client';
import { Company } from './interfaces';
import ServiceBase from './ServiceBase';

const typeCompany = `
  {
    name
    employees
    industry
    location
    revenue
    ipValue
    ddosLoss
    piiRecords
    investments { name value }
  }
`;
export class CompanyService extends ServiceBase {
  /**
   * Returns the company information from the database
   */
  async find(fields = typeCompany): Promise<Company> {
    const query = gql`
      query Company {
        company ${fields}
      }
    `;

    const { company } = await this.graphql(query);
    return company;
  }

  /**
   * Saves company information to database
   */
  async save(input: Company, fields = typeCompany): Promise<Company> {
    const mutation = gql`
      mutation UpdateCompany($input: CompanyInput!) {
        updateCompany(input: $input) ${fields}
      }
    `;

    const { updateCompany } =
      await this.graphql(mutation, { input });
    return updateCompany;
  }
}

export default new CompanyService();
