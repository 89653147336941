import { ReactNode, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    width: 62,
    position: 'relative',
  },
  toggle: {
    height: '100%',
    width: 62,
    borderRight: 'solid 1px rgba(94, 111, 130, 0.39)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  toggleToggled: {
    borderRight: 'none',
  },
  content: {
    position: 'absolute',
    top: 0,
    left: 62,
    height: '100%',
    background: '#0a0c10',
    zIndex: 1500,
  },
  arrow: {
    color: '#4a5169',
    height: 20,
    marginBottom: 24,
  },
  text: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
  },
}));

interface VerticalAccrodionProps {
  title: string;
  width?: number;
  children: ReactNode;
}

export default function VerticalAccrodion({ width = 200, title, children }: VerticalAccrodionProps) {
  const [toggle, setToggle] = useState(false);
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div
        className={clsx(classes.toggle, { [classes.toggleToggled]: toggle })}
        tabIndex={0}
        onClick={() => setToggle(!toggle)}
        onKeyPress={() => setToggle(!toggle)}
        role="button"
      >
        <FontAwesomeIcon className={classes.arrow} icon={['fas', toggle ? 'chevron-left' : 'chevron-right']} />
        <Typography className={classes.text} variant="h5">
          {title}
        </Typography>
      </div>
      {toggle && (
        <div
          style={{
            width,
          }}
          className={classes.content}
        >
          {children}
        </div>
      )}
    </div>
  );
}
