import { makeStyles } from '@mui/styles';
import { ProgressBar } from '../../../../reusables/metrics';

const useStyles = makeStyles({
  cardProgress: {
    width: '50%',
  }
});

export default function CardProgress({ progress = 0 }: { progress: number }) {
  const classes = useStyles();
  return (
    <div className={classes.cardProgress}>
      <ProgressBar value={progress} size="medium" />
    </div>
  );
}