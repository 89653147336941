import { FormEvent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { DropzoneArea } from 'mui-file-dropzone';
import Modal from '../../../reusables/Modal';

const useStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.spacing(2),
    display: 'block',
  },
  form: {
    padding: theme.spacing(2),
    paddingTop: theme.spacing(0),
    display: 'block',
  },
}));

export default function UploadModal({ open, onClose, onUpload }: UploadModalProps) {
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);

  const handleAddClick = async (e: FormEvent) => {
    e.preventDefault();
    await onUpload(files);
    onClose();
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={handleAddClick}
      title="Upload Library"
      actions={[
        { text: 'Add', color: 'primary', submit: true },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <div className={classes.wrapper} data-testid="exploit-upload-modal">
        <div className={classes.form}>
          <DropzoneArea
            fileObjects={files}
            onChange={f => setFiles(f)}
            acceptedFiles={['application/zip', 'application/x-zip-compressed']}
          />
        </div>
      </div>
    </Modal>
  );
}

type UploadModalProps = {
  open: boolean;
  onClose: () => void;
  onUpload: (files: File[]) => Promise<void>;
}
