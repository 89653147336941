import { Button } from '@mui/material';
import { useState } from 'react';
import { LiveFireNotification } from '../../../Layout/Notifications';

export default function PreviewShowcase() {
  const [showLiveFire, setShowLiveFire] = useState(false);
  return (
    <>
      <h1> Showcase </h1>
      <Button variant="outlined" onClick={() => setShowLiveFire(true)}>Show Live Fire Notification</Button>
      <LiveFireNotification url="/preview" open={showLiveFire} onClose={() => setShowLiveFire(false)} />
    </>
  );
}