import { Link as RouterLink, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Divider,
  Link,
  Modal,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TierBadge, Timer } from '../../reusables/scl';
import LiveFireBadge from '../../reusables/LiveFireBadge/LiveFireBadge';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    width: '55%',
    height: 671,
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    border: 'solid 1px #5d6e80',
    backgroundColor: '#0b0c12',
    paddingTop: 72,
    paddingLeft: 72,
    paddingRight: 67,
    paddingBottom: 72,
    overflow: 'auto',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomRow: {
    marginTop: 32,
    display: 'flex',
    gap: 25,
    flexWrap: 'wrap',
  },
  bottomRowLeft: {
    width: 393,
  },
  bottomRowRight: {
    flex: 1,
  },
  info: {
    padding: '29px 31.5px 28px 31px',
    borderRadius: 6,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
  infoRow: {
    display: 'flex',
  },
  infoRowLeft: {
    width: 175,
  },
  infoLabel: {
    marginBottom: 13,
  },
  description: {
    marginTop: 22,
    marginBottom: 26,
    maxHeight: 244,
    overflowY: 'auto',
  },
  exerciseLabel: {
    lineHeight: 1.19,
    marginTop: 11,
    marginBottom: 47,
  },
  descriptionLabel: {
    lineHeight: 1.5,
    letterSpacing: 0.6,
    marginBottom: 18,
    textTransform: 'uppercase',
  },
  date: {
    color: '#a7adb5',
  },
}));

export default function LiveFireNotification({ open, onClose, url }: LiveFireNotificationProps) {
  const classes = useStyles();
  const history = useHistory();

  const handleClick = () => {
    history.push(url);
    onClose();
  };

  return (
    <Modal open={open} className={classes.modal} onClose={onClose}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div>
            <Typography variant="h4" color="secondary">You have chosen to begin a live training exercise</Typography>
            <Typography variant="h1" className={classes.exerciseLabel}>
              Training Exercise Name Goes Here
            </Typography>
            <Timer elapsed={6000}>
              <Typography>Due in</Typography>
            </Timer>
          </div>
          <div>
            <LiveFireBadge />
          </div>
        </div>
        <div className={classes.bottomRow}>
          <div className={classes.bottomRowLeft}>
            <div className={classes.info}>
              <div className={classes.infoRow}>
                <div className={classes.infoRowLeft}>
                  <Typography className={classes.infoLabel}>Training Level</Typography>
                  <TierBadge tier="tier-1" />
                </div>
                <div>
                  <Typography className={classes.infoLabel}>Category:</Typography>
                  <Link component={RouterLink} to="/">Category Name</Link>
                </div>
              </div>
              <Divider style={{ marginTop: 22, marginBottom: 22 }} />
            </div>
          </div>
          <div className={classes.bottomRowRight}>
            <Button
              variant="contained"
              color="primary"
              endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
              onClick={handleClick}
            >
              Get Started
            </Button>

          </div>
        </div>
      </div>
    </Modal>
  );
}

interface LiveFireNotificationProps {
  open: boolean,
  onClose: () => unknown;
  url: string; 
}
