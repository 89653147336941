import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Tag } from '../../../../reusables/scl';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
  },
  tag: {
    margin: 6,
  },
}));

interface LessonLabelsProps {
  title: string;
  labels: string[];
}

export default function LessonLabels({ title, labels }: LessonLabelsProps) {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      {labels?.map(label => (
        <Tag key={label} className={classes.tag} tag={label} />
      ))}
    </div>
  );
}
