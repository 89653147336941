import React, { createContext, useState, useContext } from 'react';

const FilterContext = createContext<any>({});

export const FilterProvider: React.FC = ({ children }) => {
  const [filters, setFilters] = useState([]);
  const [sortById, setSortById] = useState(false);
  const [latest, setLatest] = useState(false);

  return (
    <FilterContext.Provider value={{ filters, setFilters, sortById, setSortById, latest, setLatest }}>
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  return useContext(FilterContext);
};
