import { DashboardMixedChartMetric } from '../../../../../reusables/metrics';
import { ScorePerformance } from '../../../../../services/interfaces';
import { performanceSeries, roundArray } from '../utilites';

export default function ChartPersonnelByAdversary({ performance }: { performance: ScorePerformance[] }) {
  const [labels, scores, precisionScores, responseScores] = performanceSeries(
    performance, 'score', 'precisionScore', 'responseScore'
  );

  const series = [
    { name: 'Composite Score', type: 'column', data: roundArray(scores) },
    { name: 'Precision Score', type: 'column', data: roundArray(precisionScores) },
    { name: 'Timeliness Score', type: 'line', data: roundArray(responseScores) },
  ];



  return (
    <DashboardMixedChartMetric
      title="Personnel performance by adversary"
      series={series}
      labels={labels ?? []}
      valueAxis={{
        ticks: 4,
        series: [
          {
            seriesName: '% Prevented',
            show: true,
            min: 0,
            max: 100,
            decimalsInFloat: 0,
          },
        ],
      }}
      labelAxis={{
        formatter: v => v,
      }}
      stroke={{
        width: [0, 4, 2],
        curve: ['smooth', 'smooth', 'smooth'],
      }}
      dataLabels={{
        enabled: true,
        enabledOnSeries: [2],
      }}
    />
  );
}
