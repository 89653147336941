import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { Test } from './interfaces';
import ServiceBase from './ServiceBase';

export const typeTest = `
  {
    id
    vendorId
    description
    type
    name
    createdAt
    tags
    vendor
  }
`;

export class TestsService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['created']));
  }

  async refreshTests(): Promise<string> {
    const query = gql`
      mutation RefreshTests {
        refreshTests
      }
    `;
    const { refreshTests } = await this.graphql(query);
    return refreshTests;
  }

  async list(vendor: string | undefined = undefined, fields = typeTest): Promise<Test[]> {
    const query = gql`
      query Tests($vendor: String) {
        tests(vendor: $vendor) ${fields}
      }
    `;

    const { tests } = await this.graphql(query, { vendor });
    return tests;
  }
}

const testsService = new TestsService();
export default testsService;
