import Modal from '../../../reusables/Modal';
import { makeStyles } from '@mui/styles';
import TextField from '../../../reusables/TextField';
import { FormEvent, useState } from 'react';
import { Framework } from '../../../services/interfaces';
import { Typography } from '@mui/material';

const useStyles = makeStyles(({
  container: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    minWidth: 500
  },
}));

interface CloneModalProps {
  open?: boolean;
  onClose: () => void;
  framework: Framework;
  onClone: (name: string, newTitle: string) => Promise<void>;
}

export default function CloneModal({ open, onClose, framework, onClone }: CloneModalProps) {
  const classes = useStyles();
  const [newTitle, setNewTitle] = useState<string>(`${framework.title} Cloned`);

  const handleClone = async (event: FormEvent)=> { 
    event.preventDefault();
    await onClone(framework.name, newTitle);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={handleClone}
      actions={[
        { text: 'Clone', color: 'primary', submit: true },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <div className={classes.container}>
        <Typography>Clone {framework.title}</Typography>
        <div>
          <TextField
            value={newTitle}
            onChange={e => setNewTitle(e.target.value)}
            label={'Name'}
          />
        </div>
      </div>
    </Modal>
  );
}
