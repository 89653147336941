import { useCallback, useEffect, useState } from 'react';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import appStore from '../../../../../../AppStore';
import { useAbort } from '../../../../../../effects';
import LoadingContainer from '../../../../../reusables/LoadingContainer';
import Tabs from '../../../../../reusables/Tabs';
import { assessorsService, evaluationsService, jobsService } from '../../../../../services';
import FrameworkService from '../../../../../services/FrameworksService';
import { Assessor, AssessorInput, Evaluation, Role, TestResult } from '../../../../../services/interfaces';
import { useFrameworkContext } from '../../FrameworkContext';
import { TileDetailProps } from '../../interfaces';
import AssesorInputModal from './AssessorInputModal';
import Evaluations from './Evaluations';
import { FilterProvider } from './FilterContext';
import History from './History';
import Input from './Input';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 25,
  },
  content: {
    width: '100%',
    height: 'calc(100vh - 250px)',
    background: 'black',
    padding: 32,
    overflow: 'hidden',
  },
  title: {
    textTransform: 'capitalize',
  },
  cards: {
    marginTop: 24,
    gap: 12,
    height: 'calc(100vh - 420px)',
    overflowY: 'scroll',
  },
  button: {
    textWrap: 'nowrap',
  },
}));

export interface Filter {
  filter: (data: any) => boolean;
  column: string;
  title: string;
  value: string;
}

const tabs = [{ label: 'Completed Tests' }, { label: 'Available Evaluations' }, { label: 'Manual Input' }];

export default function TileDetail({
  startTab = 0,
  tacticId = '',
  testIds = [],
  onClose = async (rescore = false) => undefined,
  ignoredEvaluations = [],
  isReadonly,
}: TileDetailProps) {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [openInputModal, setOpenInputModal] = useState(false);
  const [evaluations, setEvaluations] = useState<Evaluation[]>([]);
  const [testResults, setTestResults] = useState<Required<TestResult>[]>([]);
  const [assessorInputs, setAssessorInputs] = useState<Assessor[]>([]);
  const [rescore, setRescore] = useState(false);
  const [itemDetails, setItemDetails] = useState({
    id: '',
    description: '',
    identifier: '',
    name: '',
    sub: [],
    testIds: [],
  });

  const { scoredFramework } = useFrameworkContext();

  useEffect(() => setSelectedTab(startTab), [startTab]);

  useEffect(() => setRescore(false), [tacticId]);

  const testResultSourceFn = useCallback(
    () =>
      jobsService.list(
        testIds,
        { status: 'ran' },
        { sortBy: 'started', sortDirection: 'desc' },
        TEST_FIELDS,
      ) as Promise<Required<TestResult>[]>,
    [testIds],
  );

  const evaluationsSourceFn = useCallback(() => evaluationsService.byTestIds(testIds, EVALUATION_FIELDS), [testIds]);

  const inputSourceFn = useCallback(() => assessorsService.find(tacticId, ASSESSOR_FIELDS), [tacticId]);

  useAbort(
    () => {
      if (!scoredFramework) {
        return itemDetails;
      }
      return FrameworkService.findItem(tacticId, scoredFramework.name, scoredFramework.version);
    },
    ({ id, description, identifier, name }) => {
      setItemDetails({
        id,
        name,
        description,
        identifier,
        sub: [],
        testIds: [],
      });
    },
    [scoredFramework],
  );

  const handleAddInput = async (input: AssessorInput) => {
    appStore.beginLoading();
    try {
      const newInput = await assessorsService.addInput(input, ASSESSOR_FIELDS);
      setAssessorInputs(prev => [newInput, ...prev]);
      setRescore(true);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
    setOpenInputModal(false);
  };

  const handleTabSelect = (n: number) => {
    setSelectedTab(n);
  };

  const handleOpenInputModal = () => {
    setOpenInputModal(true);
  };

  const handleCloseInputModal = () => {
    setOpenInputModal(false);
  };

  return (
    <div>
      <Button color="primary" onClick={() => onClose(rescore)}>
        <KeyboardBackspaceIcon />
        &nbsp; Back to Assessments
      </Button>
      <div className={classes.header}>
        <Grid container direction="column" spacing={1} flexBasis="fit-content">
          <Grid item>
            <Typography className={classes.title} variant="h2">
              {itemDetails.identifier} {itemDetails.name}
            </Typography>
          </Grid>
          <Grid item>
            <Typography className={classes.title} variant="body1">
              {itemDetails.description}
            </Typography>
          </Grid>
        </Grid>
        {appStore.activeRole !== Role.FRAMEWORK_ANALYST && (
          <Button
            disabled={isReadonly}
            variant="contained"
            className={classes.button}
            color="primary"
            onClick={handleOpenInputModal}
          >
            Add Input Data
          </Button>
        )}
      </div>
      <div>
        <Tabs selected={selectedTab} onSelect={handleTabSelect} labels={tabs.map(t => t.label)} scrollButtons="auto" />
        <div className={classes.content}>
          <FilterProvider>
            <LoadingContainer
              style={{ display: selectedTab === 0 ? 'block' : 'none' }}
              source={testResultSourceFn}
              callback={setTestResults}
            >
              <History data={testResults} />
            </LoadingContainer>
          </FilterProvider>
          <FilterProvider>
            <LoadingContainer
              style={{ display: selectedTab === 1 ? 'block' : 'none' }}
              source={evaluationsSourceFn}
              callback={setEvaluations}
            >
              <Evaluations data={evaluations} ignoredEvaluations={ignoredEvaluations} />
            </LoadingContainer>
          </FilterProvider>
          <LoadingContainer
            style={{ display: selectedTab === 2 ? 'block' : 'none' }}
            source={inputSourceFn}
            callback={setAssessorInputs}
          >
            <Input data={assessorInputs} />
          </LoadingContainer>
        </div>
      </div>
      {openInputModal && (
        <AssesorInputModal
          open={openInputModal}
          onAdd={handleAddInput}
          onClose={handleCloseInputModal}
          tacticId={tacticId}
        />
      )}
    </div>
  );
}

const ASSESSOR_FIELDS = `{
  id
  createdAt
  comments
  assessor {
    name
  }
  score
  value
  tacticId
  files {
    id
    fileName
    mimeType
    contents
  }
}`;

const TEST_FIELDS = `
{
  id
  started
  vendor
  name
  description
  testId
  srcZoneName
  dstZoneName
  detected
  prevented
}
`;

const EVALUATION_FIELDS = `
{
  id
  name
  description
  vendor
  vendorId
  runCount
  lastRun {
    createdAt
    status
  }
}
`;
