import { Calendar } from '../';

export default function PreviewCalendar() {

  const now = new Date();
  const y = now.getFullYear();
  const m = now.getMonth();

  const scheduled = [new Date(y, m, 24)];
  const completed = [new Date(y, m, 15)];
  
  return (
    <>
      <h2> Calendar </h2>
      <div>
        <Calendar
          scheduled={scheduled}
          completed={completed}
          trainingMins={1000}
          onClick={() => {}}
        />
        <br />
        <Calendar
          scheduled={scheduled}
          completed={completed}
          trainingMins={1000}
          small
          onClick={() => {}}
        />
      </div>
    </>
  );
}