import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import TextField from '../../../../components/reusables/TextField';
import { useAbort } from '../../../../effects';
import { testingService } from '../../../services';
import { TestingSetting } from '../../../services/interfaces';
import appStore from '../../../../AppStore';

const useStyles = makeStyles({
  grid: {
    height: 270,
    width: '100%',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
  },
});

export default function Settings({ className = '' }: { className?: string }) {
  const [settings, setSettings] = useState<TestingSetting[]>([]);
  const classes = useStyles();

  useAbort(() => testingService.settings(), result => setSettings(result), []);

  const handleChange = (input: string, idx: number) => {
    const newSettings = [...settings];
    newSettings[idx].value = input;
    setSettings(newSettings);
  };

  const handleSave = () => {
    appStore.beginLoading();
    try {
      for (const setting of settings) {
        testingService.saveSetting({ name: setting.name, value: setting.value });
      }
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  return (
    <div style={{ marginTop: 30 }}>
      <div className={classes.topRow}>
        <Typography variant="h5">Settings</Typography>
        <Button onClick={handleSave} variant="contained" color="primary">Save Changes</Button>
      </div>
      <div>
        {settings.map((setting, idx) => (
          <TextField
            key={setting.name}
            label={setting.name}
            value={settings[idx].value}
            onChange={e => handleChange(e.target.value, idx)}
          />
        ))}
      </div>
    </div>
  );
}