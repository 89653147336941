import { Button, Typography } from '@mui/material';
import StepWrapper from '../StepWrapper';
import ControlModal from './ControlModal';
import { useContext, useEffect, useState } from 'react';
import EvaluationWindowContext from '../../EvaluationWindowContext';

export default function Controls() {
  const [open, setOpen] = useState(false);
  const { frameworkControls, setFrameworkControls, handleDisableNextBtn } = useContext(EvaluationWindowContext);

  useEffect(() => {
    handleDisableNextBtn(false);
  }, []);

  return (
    <StepWrapper>
      <div>
        <Button onClick={() => setOpen(true)} variant="contained">
          Customize
        </Button>
        <Typography style={{ marginTop: 20 }}>
          {frameworkControls.length || 'All'} control{frameworkControls.length === 1 ? '' : 's'} will be scored
        </Typography>
        <ControlModal
          open={open}
          onSave={async () => {}}
          onClose={() => setOpen(false)}
          selectedControls={frameworkControls}
          setSelectedControls={setFrameworkControls}
        />
      </div>
    </StepWrapper>
  );
}

Controls.label = 'Framework Capability Controls';
