import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { IconButton, TextField, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HighlightedTextField from '../../../reusables/HighlightedTextField';

const useStyles = makeStyles(() => ({
  container: {
    backgroundColor: '#0b0c12',
    borderRadius: 6,
    overflow: 'hidden',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  header: {
    padding: '33px 29px 18px 29px',
  },
  subContainer: {
    padding: '26px 29px 22px 29px',
    borderTop: 'solid 2px #1F2336',
  },
  event: {
    fontFamily: 'IBMPlexMono, monospace',
    marginTop: 12,
  },
  eventNote: {
    marginTop: 12,
  },
  icon: {
    padding: 16,
  },
  input: {
    marginTop: 12,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    borderRadius: 3,
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
    '& > div': {
      '&:before': {
        border: 0,
      },
      '&:after': {
        border: 0,
      },
    },
  },
  eventInput: {
    '& > div': {
      '& textarea': {
        padding: '11px 18px',
        fontSize: 12,
        fontFamily: 'IBMPlexMono, monospace !important',
      },
    },
  },
  noteInput: {
    '& > div': {
      '& textarea': {
        padding: '11px 18px',
        fontSize: 14,
        fontFamily: 'Lexend !important',
      },
    },
  },
}));

interface ObservedEventProps {
  analyst: string;
  event: string;
  description: string;
}

export default function ObservedEvent({ analyst, event, description }: ObservedEventProps) {
  const classes = useStyles();
  const [edit, setEdit] = useState(false);
  const [eventField, setEventField] = useState(event);
  const [noteField, setNoteField] = useState(description);

  return (
    <div className={classes.container}>
      <div className={classes.topRow}>
        <div className={classes.header}>
          <Typography variant="h5">Observed Event</Typography>
          <Typography style={{ marginTop: 9 }}>Reported By {analyst}</Typography>
        </div>
        <div className={classes.icon}>
          {/* TODO: disabled until we support editing */}
          {false && (
            <IconButton onClick={() => setEdit(!edit)} size="small">
              <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
            </IconButton>
          )}
        </div>
      </div>
      <div className={classes.subContainer}>
        <Typography variant="h6">EVENT</Typography>
        {edit && (
          <TextField
            className={`${classes.input} ${classes.eventInput}`}
            multiline
            rows={8}
            value={eventField}
            onChange={e => setEventField(e.target.value)}
          />
        )}
        {!edit && (
          <div className={classes.event}>
            <HighlightedTextField data={event} />
          </div>
        )}
      </div>
      <div className={classes.subContainer}>
        <Typography variant="h6">EVENT NOTES</Typography>
        {edit && (
          <TextField
            className={`${classes.input} ${classes.noteInput}`}
            multiline
            rows={4}
            value={noteField}
            onChange={e => setNoteField(e.target.value)}
          />
        )}
        {!edit && (
          <div className={classes.eventNote}>
            <Typography>{description}</Typography>
          </div>
        )}
      </div>
    </div>
  );
}
