import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { formatDistanceToNow } from 'date-fns';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Modal,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ScrollableList, RichText } from '../../reusables/scl';
import { Notification } from '../../services/interfaces';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    width: '55%',
    height: '65%',
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    border: 'solid 1px #5d6e80',
    backgroundColor: '#0b0c12',
    padding: 72,
  },
  title: {
    marginBottom: 38,
  },
  titleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  markAllRead: {
    cursor: 'pointer',
    marginRight: 25,
    padding: '0 25px',
  },
  notificationsWrapper: {
    height: 'calc(100% - 66px)',
  },
  empty: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  notificationWrapper: {
    padding: 28,
    borderBottom: 'solid 1px rgba(255, 255, 255, 0.12)',
    '&:last-child': {
      border: 'none',
    },
    '&:hover': {
      backgroundColor: '#161926',
    },
  },
  notification: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: 40,
    cursor: 'pointer',
  },
  callToAction: {
    marginTop: 12,
    marginLeft: 31,
  },
  dot: {
    width: 11,
    height: 11,
    backgroundColor: '#e0694d',
    borderRadius: '50%',
    display: 'inline-block',
    marginRight: 20,
  },
  dotRead: {
    visibility: 'hidden',
  },
  titleAndDot: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 6,
  },
  notificationTitle: {
    color: '#ffffff',
  },
  notificationTitleRead: {
    color: '#a7adb5',
  },
  notificationText: {
    marginLeft: 31,
  },
  notificationRight: {
    alignSelf: 'flex-end',
    minWidth: 80,
    marginLeft: 20,
  },
}));

export default function Notifications({
  open, notifications, markRead, markAllRead, onClose,
}: NotificationsPorps) {
  const classes = useStyles();

  const onCallToAction = (id: string) => {
    markRead(id);
    onClose();
  };

  const unread = notifications.filter(notification => !notification.read);

  return (
    <Modal
      open={open}
      onClose={() => {
        onClose();
      }}
      className={classes.modal}
    >
      <div className={classes.wrapper}>
        <div className={classes.titleWrapper}>
          <Typography variant="h3" className={classes.title}>
            Notifications
          </Typography>
          <Button
            color="primary"
            disabled={unread.length === 0}
            onClick={() => markAllRead()}
            className={classes.markAllRead}
          >
              mark all as read
          </Button>
        </div>
        <div className={classes.notificationsWrapper}>
          {notifications.length === 0 && (
            <div className={classes.empty}>
              <Typography variant="h5">
                You don&apos;t have any notifications yet
              </Typography>
            </div>
          )}
          {notifications.length > 0 && (
            <ScrollableList direction="y">
              {notifications.map(notification => (
                <div key={notification.id} className={classes.notificationWrapper}>
                  <div
                    className={classes.notification}
                    tabIndex={0}
                    role="button"
                    onClick={() => markRead(notification.id)}
                    onKeyPress={() => markRead(notification.id)}
                  >
                    <div>
                      <div className={classes.titleAndDot}>
                        <div className={clsx(classes.dot, { [classes.dotRead]: notification.read })} />
                        <Typography
                          variant="h5"
                          className={
                            clsx(classes.notificationTitle, { [classes.notificationTitleRead]: notification.read })
                          }
                        >
                          {notification.title}
                        </Typography>
                      </div>
                      <div className={classes.notificationText}>
                        <RichText text={notification.text} />
                      </div>
                    </div>
                    <div className={classes.notificationRight}>
                      {`${formatDistanceToNow(notification.createdAt)} ago`}
                    </div>
                  </div>
                  {notification.callToAction && (
                    <div
                      className={classes.callToAction}
                      tabIndex={0}
                      role="button"
                      onClick={() => onCallToAction(notification.id)}
                      onKeyPress={() => onCallToAction(notification.id)}
                    >
                      <Button
                        variant="contained"
                        color="secondary"
                        component={RouterLink}
                        to={notification.callToAction.url as string}
                        onClick={() => onClose()}
                      >
                        {notification.callToAction.label}
                        &nbsp;&nbsp;&nbsp;
                        <FontAwesomeIcon icon={['fal', 'long-arrow-right']} />
                      </Button>
                    </div>
                  )}
                </div>
              ))}
            </ScrollableList>
          )}
        </div>
      </div>
    </Modal>
  );
}

interface NotificationsPorps {
  open: boolean;
  notifications: Notification[];
  markRead: (id: string) => unknown;
  markAllRead: () => unknown;
  onClose: () => unknown;
}
