import { CardContent as MuiCardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';

const useStyles = makeStyles({
  cardContent: {
    paddingTop: 30,
    paddingBottom: '30px !important',
    paddingLeft: 30,
    paddingRight: 30,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flex: 1,
  },
  light: {
    backgroundColor: '#1c1d23',
    flex: 0,
  },
  cardContentMedium: {
    paddingBottom: '16px !important',
  },
  cardContentDense: {
    paddingTop: 16,
  },
  cardRow: {
    flexDirection: 'row',
    alignItems: 'center',
    minHeight: 60,
  },
});



export default function CardContent(
  { className = '', children, variant = 'normal', row = false, light = false }: CardContentProps) {
  const classes = useStyles();

  return (
    <MuiCardContent className={clsx(
      classes.cardContent, 
      variant !== 'normal' && classes.cardContentMedium,
      variant === 'dense' && classes.cardContentDense,
      row && classes.cardRow,
      light && classes.light,
      className
    )}>
      {children}
    </MuiCardContent>
  );
}

type CardContentProps = {
  variant?: 'normal' | 'medium' | 'dense';
  light?: boolean;
  row?: boolean;
  className?: string;
  children: ReactNode | Iterable<ReactNode>;
}