import { DashboardMixedChartMetric } from '../../../../../reusables/metrics';
import { ResultPerformance } from '../../../../../services/interfaces';
import { performanceSeries, roundArray } from '../utilites';

export default function ChartTechOverTime({ performance }: { performance: ResultPerformance[] }) {
  const [labels, ...data] = performanceSeries(performance, 'prevented', 'detected', 'alerted');

  // create the series for the chart
  const series = ['% Prevented', '% Detected', '% Alerted']
    .map((name, i) => ({
      name,
      type: i === 3 ? 'line' : 'area',
      data: roundArray(data[i].map(v => v * 100)),
    }));

  return (
    <DashboardMixedChartMetric
      title="Technology performance over time"
      series={series}
      labels={labels}
      valueAxis={{
        ticks: 4,
        series: [
          {
            seriesName: '% Prevented',
            show: true,
            min: 0,
            max: 100,
            decimalsInFloat: 0,
          },
        ],
      }}
      labelAxis={{
        formatter: v => v,
      }}
      stroke={{
        width: [2,2,2,2],
        curve: ['smooth','smooth','smooth','smooth'],
      }}
    />
  );
}
