import clsx from 'clsx';
import { Line, LineChart, ResponsiveContainer } from 'recharts';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import { ArrowBadge } from '../Badges';
import { LargeNumericMetric } from './';

const useStyles = makeStyles(() => ({
  trendIndicator: {
    display: 'flex',
    flexDirection: 'column',
  },
  trendSummary: {
    color: '#f85c5c',
    marginTop: 3,
  },
  trendUp: {
    color: '#00ffb1',
  },
  subText: {
    fontSize: 20,
    alignSelf: 'bottom',
  },
  green: {
    color: '#00ffb1',
  },
  red: {
    color: 'red',
  },
  grey: {
    color: '#a7adb5',
  },
}));

export default function TrendIndicator({
  duration = '',
  hideGraph = false,
  label = '',
  subText = '',
  trend,
  unit = '',
  improvement = 'lower',
}: TrendIndicatorProps) {
  const classes = useStyles();

  const current = trend[trend.length - 1] ?? 0;
  const previous = trend[trend.length - 2] ?? 0;
  const diff = current - previous;
  const direction = current < previous ? 'down' : 'up';

  // determine if the color should be green
  const green = (diff > 0 && improvement === 'higher') || (diff < 0 && improvement === 'lower');
  // it should be grey i  f there is no change
  const grey = diff === 0;
  // it should be red if not green or grey
  const red = !green && !grey;

  // convert color to trend
  const trending = () => {
    if (grey) {
      return 'middle';
    }

    if ((green && improvement === 'higher') || (red && improvement === 'lower')) {
      return 'up';
    }

    return 'down';
  };

  let icon;
  if (subText) {
    icon = <Typography className={classes.subText}>{`(${subText})`}</Typography>;
  } else {
    icon = <ArrowBadge trend={trending()} improvement={improvement} />;
  }

  return (
    <div className={classes.trendIndicator}>
      {!hideGraph && (
        <div style={{ height: 22.4, width: 54.2 }}>
          <ResponsiveContainer>
            <LineChart data={trend.map(value => ({ name: 'series-1', value }))}>
              <Line type="linear" strokeWidth={2} dataKey="value" stroke="#fff" dot={false} />
            </LineChart>
          </ResponsiveContainer>
        </div>
      )}
      <LargeNumericMetric value={current} label={label} unit="%" icon={icon} />
      {duration && (
        <Typography
          variant="body2"
          className={clsx([
            classes.trendSummary,
            {
              // TODO: remove class conditions and change to just grey if SRP-1420 is to be permanent
              [classes.green]: green && false,
              [classes.red]: red && false,
              [classes.grey]: grey || true,
            },
          ])}
        >
          {`${capitalize(direction)} ${Math.abs(diff)}${unit} vs. last ${duration}`}
        </Typography>
      )}
    </div>
  );
}

TrendIndicator.defaultProps = {
  duration: '',
  hideGraph: false,
  label: '',
  subText: '',
  unit: '',
  improvement: 'lower',
};

interface TrendIndicatorProps {
  duration: string;
  hideGraph: boolean;
  label: string;
  subText: string;
  trend: number[];
  unit: string;
  improvement: 'higher' | 'lower';
}
