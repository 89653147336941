import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ExportEvaluationButton from './ExportEvaluationButton';
import DeleteEvaluationButton from './DeleteEvaluationButton';
import CloneEvaluationButton from './CloneEvaluationButton';
import { MgrListItem } from '../interfaces';
import useEvaluationsWatcher from '../useEvaluationsWatcher';
import { CircularProgress, Radio } from '@mui/material';
import LastRunText from './LastRun';
import appStore from '../../../../../../AppStore';

export default function EvaluationList({ search, activeId, isExams, onSelect, onClone }: EvaluationListProps) {
  const evaluations = useEvaluationsWatcher(appStore.SIP, isExams);

  if (!evaluations || typeof evaluations === 'string') {
    return (
      <div style={{ flex: 1, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress color="inherit" disableShrink />
      </div>
    );
  }

  const filtered = search
    ? evaluations.filter(
        d => d.name.toLowerCase().includes(search) || (d.vendorId && d.vendorId.toLowerCase().includes(search)),
      )
    : evaluations;

  return (
    <List style={{ flex: 1 }}>
      {filtered.map(item => (
        <ListItem
          key={`${item.vendorId}-${item.vendor}`}
          id={item.vendorId}
          alignItems="flex-start"
          className="listItem"
          button
          divider
          onClick={e => onSelect(item)}
          selected={item.id === activeId}
        >
          <Radio readOnly checked={item.id === activeId} />
          <ListItemText
            className="listItemText"
            primary={item.name}
            secondary={
              <>
                {item.vendorId}
                <br />
                <LastRunText lastRun={item.lastRun} />
              </>
            }
          />
          <div>
            <ExportEvaluationButton item={item} isExam={isExams} />
            <CloneEvaluationButton onClone={() => onClone(item)} />
            {(item.isCustom || isExams) && <DeleteEvaluationButton title={item.name} id={item.id} isExam={isExams} />}
          </div>
        </ListItem>
      ))}
      {filtered.length === 0 && (
        <ListItem>
          <ListItemText className="listItemText">
            <p style={{ textAlign: 'center' }}>No results</p>
          </ListItemText>
        </ListItem>
      )}
    </List>
  );
}

interface EvaluationListProps {
  search: string;
  activeId?: string;
  isExams?: boolean;
  onSelect: (item: MgrListItem) => unknown;
  onClone: (item: MgrListItem) => unknown;
}