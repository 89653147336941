import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Fade, Modal, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    border: 'solid 1px #5d6e80',
    backgroundColor: '#0b0c12',
    padding: 42,
    paddingLeft: 137,
    paddingRight: 137,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  emoji: {
    fontSize: 72,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
  iconPass: {
    color: '#00ffb1',
  },
  iconFail: {
    color: '#f85c5c',
  },
}));

const initialDisplayResult = { open: false, result: 'pass' };

interface QuizResultProps {
  result?: 'pass' | 'fail';
}

export default function QuizResult({ result }: QuizResultProps) {
  const [displayResult, setDisplayResult] = useState(initialDisplayResult);
  const classes = useStyles();

  useEffect(() => {
    if (result !== undefined) {
      setDisplayResult({
        open: true,
        result,
      });
      setTimeout(() => {
        setDisplayResult({ open: false, result });
      }, 3000);
    }
  }, [result]);

  const { open } = displayResult;
  const pass = displayResult.result === 'pass';

  return (
    <Modal open={open} onClose={() => setDisplayResult(initialDisplayResult)} className={classes.modal}>
      <Fade in={open}>
        <div className={classes.wrapper}>
          <div className={classes.emoji}>{pass ? '👏' : '👎'}</div>
          <div className={classes.label}>
            <FontAwesomeIcon
              icon={['fal', pass ? 'check-circle' : 'times-circle']}
              className={`${classes.icon} ${pass ? classes.iconPass : classes.iconFail}`}
              size="2x"
            />
            <Typography variant="h1">{pass ? 'Correct!' : 'Incorrect!'}</Typography>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
