import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  circleBackground: {
    fill: 'none',
    stroke: 'rgba(255, 255, 255, 0.09)',
  },
  circleProgress: {
    fill: 'none',
    stroke: '#2ecff5',
    strokeLinecap: 'round',
    strokeLinejoin: 'round',
  },
}));

interface CircularProgressProps {
  className?: string;
  // Must be between 0 and 100
  percent: number;
  // Size in pixels
  size: number;
  // pix width of the stroke
  strokeWidth?: number;
}

export default function CircularProgress({ percent, size, strokeWidth = 5, className = '' }: CircularProgressProps) {
  const classes = useStyles();

  const radius = (size - strokeWidth) / 2;
  const dashArray = radius * Math.PI * 2;
  const dashOffset = dashArray - dashArray * (percent / 100);

  return (
    <svg className={className} width={size} height={size} viewBox={`0 0 ${size + 1} ${size + 1}`}>
      <circle
        className={classes.circleBackground}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
      />
      <circle
        className={classes.circleProgress}
        cx={size / 2}
        cy={size / 2}
        r={radius}
        strokeWidth={`${strokeWidth}px`}
        transform={`rotate(-90 ${size / 2} ${size / 2})`}
        style={{
          strokeDasharray: dashArray,
          strokeDashoffset: -dashOffset,
        }}
      />
    </svg>
  );
}
