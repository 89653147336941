import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Role } from '../../services/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: 3,
    '&:last-child': {
      marginRight: 0,
    }
  },
}));

const icon = (role: Role): IconName => {
  switch (role) {
    case Role.ADMIN:
      return 'cog';
    case Role.ANALYST:
      return 'flask';
    case Role.INSTRUCTOR:
      return 'graduation-cap';
    case Role.BUSINESS:
      return 'briefcase';
    case Role.SELF_TESTER:
      return 'drivers-license';
    case Role.FRAMEWORK_ANALYST:
      return 'table';
    default:
      return 'user';
  }
};

function RoleBadge({ role }: { role: Role }) {
  const classes = useStyles();

  return (
    <Tooltip title={role}>
      <FontAwesomeIcon icon={['fas', icon(role)]} className={classes.icon} />
    </Tooltip>
  );
}

export default RoleBadge;
