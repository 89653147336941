import { ChangeEvent, useContext, useEffect } from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import EvaluationWindowContext from '../EvaluationWindowContext';
import StepTitle from './StepTitle';
import StepWrapper from './StepWrapper';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: '20%',
    marginLeft: theme.spacing(4),
  },
  snackBar: {
    width: '100%',
  },
  selectText: {
    fontSize: '14px',
  },
}));

export default function StoppageTime() {
  const classes = useStyles();
  const { label, stages, stoppageTime, handleUpdateStoppageTime, handleDisableNextBtn } =
    useContext(EvaluationWindowContext);

  const itemLabel = (n: number) => {
    if (n === 0) {
      return `${n + 1} Hour`;
    }
    return `${n + 1} Hours`;
  };

  const estimatedTime = stages.flatMap(s => s.tests).length * 2.5;

  const handleOnChange = (e: ChangeEvent<{ value: unknown }>) => {
    handleUpdateStoppageTime(e.target.value as number);
  };

  useEffect(() => {
    handleDisableNextBtn(!stoppageTime);
  }, [stoppageTime, handleDisableNextBtn]);

  return (
    <StepWrapper>
      <StepTitle>
        Estimated {capitalize(label)} Time:
        <span style={{ color: '#2196F3' }}> {estimatedTime}</span> Minutes
      </StepTitle>
      <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-helper-label">Stoppage Time</InputLabel>
        <Select
          data-testid="stoppage-time-selector"
          name="stoppageTime"
          labelId="demo-simple-select-helper-label"
          value={stoppageTime.toString()}
          onChange={handleOnChange as any}
        >
          <MenuItem value={5}>
            <Typography className={classes.selectText}>5 Minutes</Typography>
          </MenuItem>
          <MenuItem value={15}>
            <Typography className={classes.selectText}>15 Minutes</Typography>
          </MenuItem>
          <MenuItem value={30}>
            <Typography className={classes.selectText}>30 Minutes</Typography>
          </MenuItem>
          <MenuItem value={45}>
            <Typography className={classes.selectText}>45 Minutes</Typography>
          </MenuItem>
          {[...Array(24).keys()].map((value, n) => (
            <MenuItem key={`value${value}`} value={(n + 1) * 60}>
              <Typography className={classes.selectText}>{itemLabel(n)}</Typography>
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StepWrapper>
  );
}

StoppageTime.label = 'Set stoppage time';
