import clsx from 'clsx';
import Paper from '@mui/material/Paper';
import { makeStyles } from '@mui/styles';
import { ResponsiveLine, Serie } from '@nivo/line';
import { round } from '@sightgain/core/calculations';
import { toTimeSpan } from '@sightgain/core/strings';
import { ArrowBadge } from './Badges';
import { formatterCurrency, LargeNumericMetric } from './metrics';

const useStyles = makeStyles(theme => ({
  paper: {
    background: '#0b0c12',
    height: 152,
    minWidth: 300,
    padding: '10px 20px 24px 30px',
    width: '24%',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  title: {
    margin: '9px 0 2px',
    fontWeight: 'bold',
    letterSpacing: '0.6px',
    color: '#a7adb5',
  },
  scoreContainer: {
    width: '70px',
    marginTop: theme.spacing(1),
    flexGrow: 0,
    flexShrink: 0,
  },
  chartContainer: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    flexGrow: 0,
    flexShrink: 0,
    height: 30,
  },
  scoreSubTitle: {
    margin: 0,
    marginLeft: 0,
    fontSize: '12px',
  },
  blue: {
    color: '#21BEE3',
  },
  green: {
    color: '#00ffb1',
  },
  red: {
    color: 'red',
  },
  grey: {
    color: '#a7adb5',
  },
}));

const gradients = [
  {
    id: 'gradientBlue',
    type: 'linearGradient',
    colors: [
      { offset: 50, color: '#21BEE3', opacity: 1 },
      { offset: 100, color: '#0e5e71', opacity: 1 },
    ],
  },
  {
    id: 'gradientRed',
    type: 'linearGradient',
    colors: [
      { offset: 50, color: '#DC573B', opacity: 1 },
      { offset: 100, color: '#6c2313', opacity: 0.75 },
    ],
  },
  {
    id: 'gradientGray',
    type: 'linearGradient',
    colors: [
      { offset: 50, color: '#5E6F82', opacity: 1 },
      { offset: 100, color: '#000', opacity: 1 },
    ],
  },
];

const formatter = (currency: boolean, timespan: boolean) => {
  if (currency) {
    return formatterCurrency;
  }

  if (timespan) {
    return function FormatedValue(n: number) {
      const [minutes, seconds] = toTimeSpan(n).split(':');
      return (
        <>
          {minutes}
          <span style={{ fontSize: '50%' }}>min</span> {seconds}
          <span style={{ fontSize: '50%' }}>sec</span>
        </>
      );
    };
  }

  return (v: string | number | Date) => v;
};

const setColor = (s: Serie, color: string) => {
  if (!s) {
    return;
  }

  s.color = color;
};

export default function Indicator({
  hidden = false,
  colors = [],
  title = '',
  data,
  improvement = 'lower',
  min,
  max,
  percentage = false,
  currency = false,
  timespan = false,
}: IndicatorProps) {
  const classes = useStyles();

  let score = 0;
  let diff = 0;
  let series: Serie[] = [];

  // create a series
  if (!data.length || typeof data[0] === 'number') {
    series = [
      {
        color: '#000',
        id: title,
        name: title,
        data: (data as number[]).map((y, idx) => ({ x: idx, y })),
      },
    ];
  } else {
    series = data as Serie[];
  }

  // determine the number of items in the first dataset
  const len = series.length && series[0].data.length;

  // get the first value as the score
  if (len > 0) {
    score = series[0].data[len - 1].y as number;
  }

  // calculate the difference since last week
  if (len > 1) {
    diff = round(score - (series[0].data[len - 2].y as number), 2);
  }

  // determine if the color should be green
  const blue = (diff > 0 && improvement === 'higher') || (diff < 0 && improvement === 'lower');
  // it should be grey i  f there is no change
  const grey = diff === 0;
  // it should be red if not green or grey
  const red = !blue && !grey;

  let gradient;
  if (blue) {
    gradient = 'gradientBlue';
    setColor(series[0], '#21BEE3');
  } else if (grey) {
    gradient = 'gradientGray';
    setColor(series[0], '#5E6F82');
  } else {
    gradient = 'gradientRed';
    setColor(series[0], '#DC573B');
  }

  // convert color to trend
  const trend = () => {
    if (blue) {
      return improvement === 'higher' ? 'up' : 'down';
    }
    if (grey) {
      return 'middle';
    }

    return improvement === 'higher' ? 'down' : 'up';
  };

  let chartMin: 'auto' | number = 'auto';
  let chartMax: 'auto' | number = 'auto';

  if (percentage) {
    chartMin = 0;
    chartMax = 100;
  }

  return (
    <Paper className={classes.paper} hidden={hidden}>
      <div className={classes.chartContainer}>
        <ResponsiveLine
          data={series}
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          xScale={{ type: 'point' }}
          yScale={{
            type: 'linear',
            min: chartMin,
            max: chartMax,
            stacked: true,
            reverse: false,
          }}
          axisLeft={null}
          axisRight={null}
          enableGridX={false}
          enableGridY={true}
          enablePoints={false}
          defs={gradients}
          fill={[
            {
              match: '*',
              id: gradient,
            },
          ]}
          lineWidth={1}
          enableArea={true}
          areaOpacity={0.55}
          isInteractive={false}
          enableCrosshair={false}
          legends={[]}
          animate={false}
          colors={d => d.color}
          gridYValues={[0]}
        />
      </div>
      <LargeNumericMetric
        value={score}
        formatter={formatter(currency, timespan)}
        label=""
        unit={percentage ? '%' : ''}
        icon={<ArrowBadge trend={trend()} improvement={improvement} />}
      />
      <p className={classes.scoreSubTitle}>{title.toUpperCase()}</p>
      <p className={clsx(classes.scoreSubTitle, { [classes.blue]: blue, [classes.red]: red, [classes.grey]: grey })}>
        {grey && 'No change'}
        {!grey && (
          <span>
            {diff > 0 && '+'}
            {formatter(currency, timespan)(diff)}
            {percentage ? '%' : ''}
          </span>
        )}
      </p>
    </Paper>
  );
}

interface IndicatorProps {
  hidden?: boolean;
  colors?: string[];
  title?: string;
  data: number[] | Serie[];
  improvement?: 'higher' | 'lower';
  min?: number;
  max?: number;
  percentage?: boolean;
  currency?: boolean;
  timespan?: boolean;
}
