import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import appStore from '../../../AppStore';
import PasswordComplexity from '../../reusables/PasswordComplexity';
import PasswordText from '../../reusables/PasswordText';
import TextField from '../../reusables/TextField';
import { usersService } from '../../services';
import { UserInput } from '../../services/interfaces';

const fields = '{ id name email }';

const useStyles = makeStyles(theme => ({
  panel: {
    flex: '1 1 100%',
    padding: '27px 54px',
    backgroundColor: '#303f52',
    marginTop: 30,
    overflowY: 'auto',
  },
  row: {
    display: 'flex',
    gap: 15,
    margin: `${theme.spacing(3)} 0`
  },
  submitButton: {
    fontSize: 14,
    backgroundColor: '#21bee3',
    color: '#fff',
    borderRadius: 2,
  },
}));


// validates password strength
const passwordTest = (p: string) => /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/.test(p);

// validates the confirm password field
const confirmPasswordTest = (p?: string, c?: string) => {
  if (!p) {
    return '';
  }

  if (!c) {
    return 'Confirm Password is a required field';
  }

  if (c !== p) {
    return 'Passwords do not match';
  }

  return '';
};

// validates an email address
const emailTest = (e?: string) => {
  if (!e || !e.trim()) {
    return 'Email is a required field';
  }

  if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(e)) {
    return 'Invalid email address';
  }

  return '';
};

export default function Profile() {
  const { id, name, email } = appStore.user;
  const classes = useStyles();

  const [state, setState] = useState({
    name,
    email,
    password: '',
    confirmPassword: '',
  });

  const errors = {
    name: !state.name && 'name is a required field',
    email: emailTest(state.email),
    password: state.password && !passwordTest(state.password) ? 'Password is too weak' : undefined,
    confirmPassword: confirmPasswordTest(state.password, state.confirmPassword),
  };

  const hasErrors = !!(errors.name || errors.email || errors.password || errors.confirmPassword);

  const handleSave = async () => {
    if (hasErrors) {
      return;
    }

    try {
      appStore.beginLoading();
      const { password, confirmPassword, ...rest } = state;
      const userInput: UserInput = {
        id,
        roles: [],
        ...rest,
      };

      if (password) {
        userInput.password = password;
      }

      const resp = await usersService.save(userInput, fields);
      appStore.user.name = resp.name;
      appStore.user.email = resp.email;
      appStore.success('user profile saved successfully');
    } catch(err) {
      appStore.error(err);
    }

    appStore.endLoading();
  };

  const handleChange = (name: string) => ({ target: { value = '' } }: { target: { value: string }}) => {
    setState(prev => ({ ...prev, [name]: value }));
  };

  return (
    <div>
      <Typography variant="h1">Profile</Typography>
      <div className={classes.panel}>
        <div className={classes.row}>
          <TextField
            onChange={handleChange('name')}
            label="Name"
            value={state.name}
            error={errors.name}
          />
          <TextField
            disabled={true}
            onChange={handleChange('email')}
            label="Email"
            value={state.email}
            error={errors.email}
          />
        </div>
        <div className={classes.row}>
          <PasswordText
            onChange={handleChange('password')}
            label="Password"
            password={state.password}
            error={errors.password}
          />
          <PasswordText
            onChange={handleChange('confirmPassword')}
            label="Confirm Password"
            password={state.confirmPassword}
            error={errors.confirmPassword}
          />
        </div>
        <div className={classes.row}>
          <PasswordComplexity password={state.password} />
        </div>
        <div className={classes.row}>
          <Button
            disabled={hasErrors}
            className={classes.submitButton}
            variant="contained"
            endIcon={<FontAwesomeIcon size="xs" icon={['fal', 'arrow-right']} />}
            onClick={handleSave}
          >Save</Button>
        </div>
      </div>
    </div>
  );
}