import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import ChartMeanResponse from './charts/ChartMeanResponse';
import ChartPersonnelByTactic from './charts/ChartPersonnelByTactic';
import ChartPersonnelByAdversary from './charts/ChartPeronnelByAdversary';
import ChartPersonnelOverTime from './charts/ChartPersonnelOverTime';
import { OrganizationOverview } from '../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  gridContainer: {
    borderRadius: 6,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
  consoleGrid: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
    paddingBottom: 16,
  },
  consoleGridItem: {
    minHeight: 349,
  },
  consoleGridItemBottom: {
    minHeight: 300,
  },
}));

function TabPersonnel({ overview }: { overview: OrganizationOverview }) {
  const classes = useStyles();

  return (
    <div className={classes.gridContainer}>
      <Grid container className={classes.consoleGrid} spacing={3}>
        <Grid className={classes.consoleGridItem} item sm={12} md={6}>
          <ChartMeanResponse performance={overview.personnelPerformanceOverTime} />
        </Grid>
        <Grid className={classes.consoleGridItem} item sm={12} md={6}>
          <ChartPersonnelByTactic performance={overview.personnelPerformanceByTactic} />
        </Grid>
        <Grid className={classes.consoleGridItemBottom} item sm={12} md={6}>
          <ChartPersonnelOverTime performance={overview.personnelPerformanceOverTime} />
        </Grid>
        <Grid className={classes.consoleGridItemBottom} item sm={12} md={6}>
          <ChartPersonnelByAdversary performance={overview.personnelPerformanceByAdversary} />
        </Grid>
      </Grid>
    </div>
  );
}

export default TabPersonnel;
