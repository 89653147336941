import { makeStyles } from '@mui/styles';
import { Grid } from '@mui/material';
import ChartTechOverTime from './charts/ChartTechOverTime';
import ChartTechByTactic from './charts/ChartTechByTactic';
import ChartTechByAdversary from './charts/ChartTechByAdversary';
import ChartTechPreventionRates from './charts/ChartTechPreventionRates';
import { OrganizationOverview } from '../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  gridContainer: {
    borderRadius: 6,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
  consoleGrid: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
    paddingBottom: 16,
  },
  consoleGridItem: {
    minHeight: 349,
  },
  consoleGridItemBottom: {
    minHeight: 300,
  },
}));

export default function TabTechnology({ overview }: { overview: OrganizationOverview }) {
  const classes = useStyles();

  return (
    <div className={classes.gridContainer}>
      <Grid container className={classes.consoleGrid} spacing={3}>
        <Grid className={classes.consoleGridItem} item sm={12} md={6}>
          <ChartTechOverTime performance={overview.techPerformanceOverTime}/>
        </Grid>
        <Grid className={classes.consoleGridItem} item sm={12} md={6}>
          <ChartTechByTactic performance={overview.techPerformanceByTactic}/>
        </Grid>
        <Grid className={classes.consoleGridItemBottom} item sm={12} md={6}>
          <ChartTechPreventionRates performance={overview.techPreventionRates}/>
        </Grid>
        <Grid className={classes.consoleGridItemBottom} item sm={12} md={6}>
          <ChartTechByAdversary performance={overview.techPerformanceByAdversary}/>
        </Grid>
      </Grid>
    </div>
  );
}
