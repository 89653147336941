import { SRPModule } from '../interfaces';
import Risk from '../../../images/nav/SightGain RiskAssessment Logo.svg';
import { Role } from '../../../services/interfaces';
import ReturnOnInvestment from '../../../pages/Risk/ReturnOnInvestment';
import ThreatPreparedness from '../../../pages/Risk/ThreatPreparedness';
import FrameworkScoring from '../../../pages/Risk/FrameworkScore';
import FrameworkManagement from '../../../pages/Risk/FrameworkManagement';
import FrameworkEditor from '../../../pages/Risk/FrameworkManagement/Editor/FrameworkEditor';
import ProductBakeOff from '../../../pages/Risk/ProductBakeOff';
import Recommendations from '../../../pages/Risk/Recommendations';
import { SIP } from '../../../../AppStore';

const riskModule: SRPModule = {
  name: 'risk',
  description: 'Quantify risk exposure, find defensive gaps, and roadmap your way to improved performance.',
  img: Risk,
  pages: [
    {
      name: 'Return On Investment',
      icon: 'exchange-alt',
      to: '/risk/roi',
      exclude: [SIP.Picus],
      routes: [{
        path: '/risk/roi/:view?',
        key: 'roi',
        roles: [Role.ADMIN, Role.BUSINESS],
        component: ReturnOnInvestment,
      }],
    },
    {
      name: 'Threat Preparedness',
      icon: 'shield-check',
      to: '/risk/threat-preparedness',
      routes: [{
        path: '/risk/threat-preparedness',
        key: 'threat-preparedness',
        roles: [Role.ADMIN, Role.BUSINESS],
        component: ThreatPreparedness,
      }],
    },
    {
      name: 'Assessment Scoring',
      icon: 'signal-alt',
      to: '/risk/scoring',
      routes: [{
        path: '/risk/scoring/:view?',
        key: 'framework-scoring',
        limitedAccess: true,
        roles: [Role.ADMIN, Role.BUSINESS, Role.FRAMEWORK_ANALYST],
        component: FrameworkScoring,
      }],
    },
    {
      name: 'Framework Management',
      icon: 'cubes',
      to: '/risk/management',
      routes: [{
        path: '/risk/management',
        key: 'management',
        roles: [Role.ADMIN, Role.BUSINESS],
        component: FrameworkManagement,
      }, {
        path: '/risk/management/edit/:fw?',
        key: 'management-editor',
        roles: [Role.ADMIN, Role.BUSINESS],
        component: FrameworkEditor,
      }],
    },
    {
      name: 'Product Bake-Off',
      icon: 'oven',
      to: '/risk/product-bake-off',
      exclude: [SIP.Picus],
      routes: [{
        path: '/risk/product-bake-off',
        key: 'product-bake-off',
        roles: [Role.ADMIN, Role.BUSINESS],
        component: ProductBakeOff,
      }],
    },
    {
      name: 'Recommendations',
      icon: 'check',
      to: '/risk/recommendations',
      routes: [{
        path: '/risk/recommendations',
        key: 'recommendations',
        roles: [Role.ADMIN, Role.BUSINESS],
        component: Recommendations,
      }],
    }
  ],
};

export default riskModule;