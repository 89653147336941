import { makeStyles } from '@mui/styles';
import { Button, Link } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddProduct from './Components/AddProduct';
import { ReactNode } from 'react';
import { BakeOffProduct } from './interfaces';
import Title from '../components/Title';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
  },
  content: {
    width: '100%',
    height: 'calc(100% - 80px)',
  },
}));

export default function Wrapper({
  drilldown,
  exclude = [],
  addProduct = () => undefined,
  removeDrilldown,
  children,
  displayAddProduct = true,
}: WrapperProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {drilldown && (
        <Button
          component={Link}
          onClick={removeDrilldown}
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-left']} />}
        >
          Back to Product Bake-Off
        </Button>
      )}
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Title title={drilldown || 'Product Bake-Off'} />
        {!drilldown && displayAddProduct && <AddProduct exclude={exclude} onSubmit={addProduct} />}
      </div>
      <div className={classes.content}>{children}</div>
    </div>
  );
}

type WrapperProps = {
  drilldown?: string;
  exclude?: BakeOffProduct[];
  addProduct?: (p: BakeOffProduct) => unknown;
  removeDrilldown: () => unknown;
  children: ReactNode | ReactNode[];
  displayAddProduct?: boolean;
};
