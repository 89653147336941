import { useHistory } from 'react-router-dom';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getModulePath } from '../../Layout/Navigation/utils';

const useStyles = makeStyles(() => ({
  wrapper: {
    overflow: 'hidden',
    height: 277,
    width: '100%',
    borderRadius: 6,
    background: 'rgba(255, 255, 255, 0.09)',
    position: 'relative',
    transitionDuration: '500ms',
    '&:focus': {
      border: 'none',
      outline: 'none',
    },
    '&:hover, &:focus': {
      cursor: 'pointer',
      boxShadow: '10px 10px 10px 0 rgba(0, 0, 0, 0.16)',
      backgroundImage: 'linear-gradient(to bottom, #1592ff, #0b4980)',
      '& > div:first-child': {
        top: 41,
      },
      '& > div:first-child > img': {
        filter: 'brightness(0) invert(1)',
      },
      '& > div:first-child > p': {
        opacity: 1,
      },
    },
  },
  content: {
    position: 'absolute',
    left: 34,
    right: 34,
    top: 112,
    width: 'calc(100% - 68px)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    transitionDuration: '500ms',
  },
  text: {
    textAlign: 'center',
    marginTop: 48,
    opacity: 0,
    transitionDuration: '500ms',
  },
  cta: {
    marginTop: 34,
    opacity: 0,
    fontWeight: 'bold',
    transitionDuration: '500ms',
  },
}));

export default function ModuleCard({ img, description, name }: ModuleCardProps) {
  const classes = useStyles();
  const history = useHistory();

  const handleSelect = () => history.push(getModulePath(name));

  return (
    <div
      className={classes.wrapper}
      onClick={handleSelect}
      onKeyPress={handleSelect}
      tabIndex={0}
      role="button"
    >
      <div className={classes.content}>
        <img src={img} alt={description} height="32px" />
        <Typography className={classes.text} variant="body1">
          {description}
        </Typography>
        <Typography className={classes.cta}>
          Let&apos;s Go!
          &nbsp;
          <FontAwesomeIcon icon={['fal', 'arrow-right']} />
        </Typography>
      </div>
    </div>
  );
}

interface ModuleCardProps {
  img: '*.svg';
  description: string;
  name: string;
}
