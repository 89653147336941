import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Objective } from '../interface';

const useStyles = makeStyles(theme => ({
  detailBox: {
    background: theme.palette.darkBlue3.main,
    padding: '30px 20px',
    flex: 1,
  },
  objective: {
    '&:nth-child(n+2):before': {
      content: "''",
      border: `1px solid ${theme.palette.darkBlue4.main}`,
      alignSelf: 'stretch',
    },
  },
}));

interface ItemDetailsProps {
  objectives: Objective[];
}

export default function ItemDetails({ objectives }: ItemDetailsProps) {
  const classes = useStyles();
  return (
    <>
      {objectives.map(o => {
        return (
          <Grid className={classes.objective} container flexDirection="column" key={o.name} gap={2}>
            <Grid item>
              <Typography variant="textStyle9">{o.name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="textStyle4" color="grey2.main">
                {o.description}
              </Typography>
            </Grid>
            <Grid item>
              <Grid container justifyContent="space-between" gap={2}>
                <Grid item className={classes.detailBox}>
                  <Typography display="block" variant="textStyle3">
                    Level:
                  </Typography>
                  <Typography display="block" variant="textStyle1">
                    {o.scores.subItemSelfScoreMaturityScore?.maturityLevel?.toUpperCase()}
                  </Typography>
                </Grid>
                <Grid item className={classes.detailBox}>
                  <Typography display="block" variant="textStyle3">
                    Minimum Score:
                  </Typography>
                  <Typography display="block" variant="textStyle1">
                    {o.scores.subItemSelfScoreMaturityScore.threshold || 0}%
                  </Typography>
                </Grid>
                <Grid item className={classes.detailBox}>
                  <Typography display="block" variant="textStyle3">
                    Actual Score:
                  </Typography>
                  <Typography display="block" variant="textStyle1">
                    {o.scores.valueFor(o.scores.subItemSelfScoreMaturityScore, 0)}
                  </Typography>
                </Grid>
                <Grid item className={classes.detailBox}>
                  <Typography display="block" variant="textStyle3">
                    Status:
                  </Typography>
                  <Typography display="block" variant="textStyle1">
                    {o.scores.labelFor(o.scores.subItemSelfScoreMaturityScore, 'Not Achieved')}
                  </Typography>
                </Grid>
                <Grid item className={classes.detailBox}>
                  <Typography display="block" variant="textStyle3">
                    Test Results:
                  </Typography>
                  <Typography display="block" variant="textStyle1">
                    {o.scores.valueFor(o.scores.subItemTestsPassed, 0)}{' '}
                    {o.scores.labelFor(o.scores.subItemTestsPassed, 'Passed')}
                  </Typography>
                  <Typography display="block" variant="textStyle1">
                    {o.scores.valueFor(o.scores.subItemTestsFailed, 0)}{' '}
                    {o.scores.labelFor(o.scores.subItemTestsFailed, 'Failed')}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      })}
    </>
  );
}
