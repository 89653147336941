import { ChangeEvent, useContext } from 'react';
import { Checkbox, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { unique } from '@sightgain/core';
import appStore from '../../../../../../../../AppStore';
import { Role } from '../../../../../../../services/interfaces';
import { TargetUser } from '../../../interfaces';
import EvaluationWindowContext from '../../EvaluationWindowContext';
import TargetAnalyst from './TargetAnalyst';

const useStyles = makeStyles(theme => ({
  paper: {
    background: '#303030',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    minWidth: 450,
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'column',
  },
  th: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  wide: {
    fontSize: 12,
  },
  snackBar: {
    width: '100%',
  },
}));

const HEADERS = ['Select', 'Analyst', 'Email', 'Role(s)', 'Online'];

const isAdmin = appStore.hasRole(Role.ADMIN);
const isSelfTester = appStore.hasRole(Role.SELF_TESTER);
const checkAccess = appStore.checkAccess;

export default function TargetAnalysts() {
  const classes = useStyles();
  const { analysts, handleUpdateSelectedAnalysts } = useContext(EvaluationWindowContext);

  const groups = unique(analysts.map(a => a.group)).sort((a, b) => (a < b ? -1 : 1));

  const handleGroupChecked = (groupAnalysts: TargetUser[]) => (e: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    handleUpdateSelectedAnalysts(
      groupAnalysts
        // only keep analysts that do not already match the checked value
        .filter(a => a.isSelected !== checked)
        // remove aalysts that are not valid selections
        .filter(a => validateSelection(a))
        // return the list of valid ids
        .map(a => a.id),
    );
  };

  const validateSelection = (analyst: TargetUser) => {
    const { id } = appStore.user;

    // you can always uncheck; validation for checking
    if (analyst.isSelected) {
      return true;
    }

    if (!isAdmin) {
      if (isSelfTester && !checkAccess(Role.ADMIN) && analyst.id !== id) {
        appStore.error("Self-Tester's may only select themselves");
        return false;
      }

      if (!isSelfTester && analyst.id === id) {
        appStore.error("Only those with role 'Self-Tester' may select themselves.");
        return false;
      }

      return false;
    }

    return true;
  };

  const handleAnalystChecked = (analyst: TargetUser) => {
    if (validateSelection(analyst)) {
      handleUpdateSelectedAnalysts([analyst.id]);
    }
  };

  const isGroupChecked = (groupAnalysts: TargetUser[]) => {
    return groupAnalysts.length === groupAnalysts.filter(a => a.isSelected).length;
  };

  const isDisabled = (analyst: TargetUser) => {
    const { id } = appStore.user;

    if (!isAdmin) {
      if (isSelfTester && !checkAccess(Role.ADMIN) && analyst.id !== id) {
        return true;
      }

      if (!isSelfTester && analyst.id === id) {
        return true;
      }

      return true;
    }

    return false;
  };

  return (
    <Paper>
      <Table size="small" data-testid="analyst-table">
        <TableHead>
          <TableRow>
            {HEADERS.map(header => (
              <TableCell className={classes.th} align="center" key={header}>
                {header}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {groups.map(group => {
          const groupAnalysts = analysts.filter(a => a.group === group);
          return (
            <TableBody key={`analyst-group-${group}`}>
              <TableRow style={{ backgroundColor: 'rgba(255, 255, 255, 0.16)' }}>
                <TableCell align="center">
                  <Checkbox
                    checked={isGroupChecked(groupAnalysts)}
                    onChange={handleGroupChecked(groupAnalysts)}
                    color="primary"
                  />
                </TableCell>
                <TableCell colSpan={4} className={classes.th}>
                  {group}
                </TableCell>
              </TableRow>
              {groupAnalysts.map(analyst => (
                <TargetAnalyst
                  key={analyst.id}
                  analyst={analyst}
                  disabled={isDisabled(analyst)}
                  onCheck={() => handleAnalystChecked(analyst)}
                />
              ))}
            </TableBody>
          );
        })}
      </Table>
    </Paper>
  );
}
