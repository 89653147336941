import { Paper } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

const useStyles = makeStyles(theme => ({
  stepWrapper: {
    background: '#303030',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(3),
    minWidth: 450,
    minHeight: 130,
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: 20
  }
}));
export default function StepWrapper({ children }: { children: ReactNode | ReactNode[] }) {
  const classes = useStyles();

  return (
    <Paper className={classes.stepWrapper}>
      {children}
    </Paper>
  );
}