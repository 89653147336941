import { useMemo, useState } from 'react';
import Button from '@mui/material/Button';
import Chip, { ChipPropsColorOverrides } from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { alpha } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import appStore from '../../../../../../AppStore';
import { useAbort } from '../../../../../../effects';
import SearchBar from '../../../../../reusables/SearchBar';
import { evaluationsService } from '../../../../../services';
import { TestWithRunInfo } from '../../../../../services/interfaces';

type EvaluationTestsProps = {
  data?: string;
};

const useStyles = makeStyles(theme => ({
  datagrid: {
    border: 'none',
    borderColor: theme.palette.grey2.main,
    '& .hdr-cell': {
      background: alpha(theme.palette.grey2.main, 0.1),
    },
    '& .hdr-cell .MuiDataGrid-columnHeaderTitle': {
      fontSize: '0.875rem',
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.125rem',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: theme.palette.grey2.main,
    },
  },
  row: {
    borderColor: theme.palette.grey2.main,
  },
  wrapper: {
    borderRadius: 6,
    backgroundColor: '#0b0c12',
    padding: '20px',
    width: '80vw',
  },
  tableWrapper: {
    height: 'calc(80vh - 200px)',
  },
}));

export default function EvaluationTests({ data }: EvaluationTestsProps) {
  const classes = useStyles();
  const [tests, setTests] = useState<TestWithRunInfo[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState<string>('all');

  useAbort(
    async () => {
      if (!data) {
        return null;
      }
      const query = `
    {
      id
      name
      stages {
        tests {
          id
          lastRun {
            detected
            createdAt
            prevented
          }
          name
          vendorId
        }
      }
    }
    `;
      return await evaluationsService.find(appStore.SIP, data, query);
    },
    evaluation => {
      if (!evaluation) {
        return;
      }
      const tests = evaluation.stages.flatMap(s => s.tests);
      setTests(tests as TestWithRunInfo[]);
    },
  );

  function formatDateTime(date: Date): string {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${year}-${month}-${day}, ${hours}:${minutes}`;
  }

  function getTestStatus(test: TestWithRunInfo) {
    const { prevented, detected, createdAt } = test.lastRun || {};
    const status = !createdAt
      ? 'Never Run'
      : prevented && detected
      ? 'Detected & Blocked'
      : prevented
      ? 'Blocked'
      : detected
      ? 'Detected'
      : 'Missed';
    return status;
  }

  const filteredTests = useMemo(() => {
    if (!searchQuery && statusFilter === 'all') {
      return tests;
    }
    return tests.filter(test => {
      return (
        (test.name.includes(searchQuery) || test.vendorId.includes(searchQuery)) &&
        (statusFilter === 'all' || getTestStatus(test) === statusFilter)
      );
    });
  }, [tests, searchQuery, statusFilter]);

  return (
    <div className={classes.wrapper}>
      <Grid container flexDirection="column" spacing={5}>
        <Grid item>
          <Typography variant="textStyle7">Evaluation ID: </Typography>
          <Typography variant="textStyle7" color="blue3.main">
            S104-180
          </Typography>
        </Grid>
        <Grid item>
          <Grid container gap={4} justifyContent="space-between" alignItems="end">
            <Grid item flex={2}>
              <SearchBar placeholder="Search" onChange={setSearchQuery} />
            </Grid>
            <Grid item flex={1}>
              <Typography variant="textStyle1" color="grey2.main">
                Status
              </Typography>
              <Select value={statusFilter} fullWidth onChange={e => setStatusFilter(e.target.value)}>
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="Blocked">Blocked</MenuItem>
                <MenuItem value="Detected">Detected</MenuItem>
                <MenuItem value="Missed">Missed</MenuItem>
                <MenuItem value="Never Run">Never Run</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <div className={classes.tableWrapper}>
            <DataGrid
              disableRowSelectionOnClick
              className={classes.datagrid}
              rowHeight={80}
              getRowClassName={() => classes.row}
              rows={filteredTests}
              columns={[
                {
                  field: 'vendorId',
                  headerName: 'Test ID',
                  headerClassName: 'hdr-cell',
                  flex: 1,
                },
                {
                  field: 'name',
                  headerName: 'Test Name',
                  headerClassName: 'hdr-cell',
                  flex: 2,
                },
                {
                  field: 'status',
                  headerName: 'Status',
                  headerClassName: 'hdr-cell',
                  flex: 1,
                  renderCell: ({ row }: GridCellParams) => {
                    const colors = {
                      'Detected & Blocked': 'green2',
                      Blocked: 'darkBlue1',
                      Detected: 'blue2',
                      Missed: 'orange2',
                      'Never Run': 'grey2',
                    };
                    const status = getTestStatus(row);
                    return <Chip label={status} color={colors[status] as keyof ChipPropsColorOverrides} />;
                  },
                },
                {
                  field: 'lastRun',
                  headerName: 'Last Run',
                  headerClassName: 'hdr-cell',
                  flex: 1,
                  renderCell: ({ row }: GridCellParams) => {
                    return row.lastRun?.createdAt ? formatDateTime(new Date(row.lastRun.createdAt)) : 'Never';
                  },
                },
                {
                  field: 'actions',
                  headerName: 'Run Now',
                  headerClassName: 'hdr-cell',
                  renderCell: () => (
                    <Button variant="text" color="primary">
                      Run Now
                    </Button>
                  ),
                },
              ]}
              density="compact"
              onRowClick={e => {}}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
