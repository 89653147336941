import { unique } from '@sightgain/core';
import { baseEqual } from '@sightgain/core/strings';
import appStore from '../../../AppStore';
import { Role } from '../../services/interfaces';
import { SRPModule } from './interfaces';
import modules from './modules';

export const defaultAccess = (Object.values(Role) as string[]).filter(
  role => ![Role.NONE, Role.FRAMEWORK_ANALYST].includes(role as Role),
) as Role[];

export function defaultAccessWith(roles: Role[] | Role) {
  return [...defaultAccess, ...([] as Role[]).concat(roles)];
}

export function getModuleRoles(module?: SRPModule): Role[] {
  if (!module) {
    return [];
  }

  return unique(module.pages.flatMap(page => page.routes.flatMap(route => route.roles)));
}

export function calculateRoles(activeModuleName?: string) {
  // get the active module by the name
  const activeModule = modules.find(m => activeModuleName && baseEqual(activeModuleName, m.name));
  // get the module roles
  const moduleRoles = getModuleRoles(activeModule);

  // if we are an admin, add all available roles
  if (appStore.hasRole(Role.ADMIN)) {
    return [
      // admin has rights to all roles for a module
      moduleRoles,
      // admin could use any role
      Object.values(Role).map(role => role as Role),
    ];
  }

  return [
    // user has rights to only their roles for a module
    moduleRoles.filter(role => appStore.hasRole(role)),
    // user has rights to only their roles
    appStore.user.roles,
  ];
}

export function getFilteredModules(availableRoles: Role[] = []) {
  if (appStore.isLimitedLicense) {
    return modules.filter(m => m.pages.some(p => p.routes.some(r => r.limitedAccess)));
  }

  return modules.filter(m => getModuleRoles(m).some(r => availableRoles.includes(r)));
}

export function getFilteredPages(activeModule: string | SRPModule = 'readiness') {
  const module = typeof activeModule === 'string' ? modules.find(m => baseEqual(m.name, activeModule)) : activeModule;
  const activeRole = appStore.activeRole;
  const sip = appStore.SIP;
  return (module as SRPModule).pages.filter(
    page =>
      (!page.exclude || !page.exclude.includes(sip)) &&
      page.routes.some(r => r.roles.includes(activeRole) && (!appStore.isLimitedLicense || r.limitedAccess)),
  );
}

export function hasPageAccess(activeModule: string | SRPModule, path: string) {
  const pages = getFilteredPages(activeModule);
  return pages.some(p => path.startsWith(p.to));
}

export function getModulePath(activeModule: string | SRPModule) {
  const pages = getFilteredPages(activeModule);
  const firstPage = pages[0];
  return firstPage?.to ?? '/';
}
