import { Recommendation, RecommendationSummary } from './interfaces';

// TODO: This is dumb and ugly - refactor
export function summarize(recommendations: Recommendation[], types: string[]): RecommendationSummary[] {
  const defaultMap: any = {};

  types.forEach(t => {
    (defaultMap as any)[t] = {
      type: t,
      title: t,
      desc: '',
      priorities: 0,
      ignored: 0,
      resolved: 0,
      recent: 0,
    };
  });

  const summaryMap = recommendations.reduce((acc: Record<string, RecommendationSummary>, rec: Recommendation) => {
    if (!acc[rec.type]) {
      acc[rec.type] = {
        type: rec.type,
        title: rec.type,
        desc: '',
        priorities: 0,
        ignored: 0,
        resolved: 0,
        recent: 0,
      };
    }

    const summary = acc[rec.type];

    switch (rec.status) {
      case 'new':
        summary.recent++;
        summary.priorities++;
        break;
      case 'priority':
        summary.priorities++;
        break;
      case 'ignored':
        summary.ignored++;
        break;
      case 'resolved':
        summary.resolved++;
        break;
      default:
        break;
    }

    return acc;
  }, defaultMap);

  const all: RecommendationSummary = {
    type: 'all',
    title: 'All',
    desc: '',
    priorities: 0,
    ignored: 0,
    resolved: 0,
    recent: 0,
  };

  const summaryList = Object.values(summaryMap);

  summaryList.forEach((summary: RecommendationSummary) => {
    all.priorities += summary.priorities;
    all.ignored += summary.ignored;
    all.resolved += summary.resolved;
    all.recent += summary.recent;
  });

  return [all, ...summaryList];
}