import clsx from 'clsx';
import { Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';

const useStyles = makeStyles(() => ({
  techSummary: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
  },
  title: {
    marginBottom: 12,
  },
  trend: {
    color: '#00ffb1',
  },
  trendDown: {
    color: '#f85c5c',
  },
  value: {
    marginBottom: 7,
    marginTop: 14,
  },
}));

interface TechnologySummaryProps {
  percent: number;
  previous: number;
  title: string;
  total: number;
}

export default function TechnologySummary({ percent, previous, title, total }: TechnologySummaryProps) {
  const classes = useStyles();

  const diff = percent - previous;
  const trend = diff > 0 ? 'up' : 'down';

  return (
    <div className={classes.techSummary}>
      <Typography variant="inherit" className={classes.title}>
        <strong>{title}</strong>
      </Typography>
      <Divider />
      <Typography variant="inherit" className={classes.value}>{`${total} (${percent}%)`}</Typography>
      <Typography
        className={clsx(classes.trend, trend === 'down' && classes.trendDown)}
        variant="inherit"
      >{`${capitalize(trend)} ${Math.abs(diff)}%`}</Typography>
    </div>
  );
}
