import { useMemo } from 'react';
import { EvaluationRunner } from '../../interfaces';
import appStore from '../../../../../../../AppStore';
import PicusStepper from './Picus/PicusStepper';
import DefaultStepper from './DefaultStepper';

export default function Stepper({ runner }: { runner: EvaluationRunner }) {
  const Component = useMemo(() => {
    switch(appStore.SIP) {
      case 'picus':
        return PicusStepper;
      default:
        return DefaultStepper;
    }
  }, [appStore.SIP]);

  return <Component runner={runner} />;
}