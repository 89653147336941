import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect } from 'react';
import { CollectionMethod } from '../../interfaces';
import EvaluationWindowContext from '../EvaluationWindowContext';
import StepWrapper from './StepWrapper';
import StepTitle from './StepTitle';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: 180,
    marginLeft: theme.spacing(4),
  },
  selectText: {
    fontSize: '14px',
  },
}));
export default function Collection() {
  const classes = useStyles();
  const {
    collectionMethod,
    hasTicketing,
    handleUpdateCollectionMethod,
    handleDisableNextBtn,
  } = useContext(EvaluationWindowContext);

  useEffect(() => {
    handleDisableNextBtn(!collectionMethod);
  }, [collectionMethod, handleDisableNextBtn]);

  return (
    <StepWrapper>
      <StepTitle>
        Select a method for collecting events discovered by the analyst.
      </StepTitle>
      <FormControl className={classes.formControl}>
        <InputLabel>Collection Method</InputLabel>
        <Select
          data-testid="collection-method-selector"
          name="collectionMethod"
          defaultValue=""
          value={collectionMethod}
          onChange={e => handleUpdateCollectionMethod(e.target.value as CollectionMethod)}
        >
          <MenuItem
            value="ticket"
            disabled={!hasTicketing}
          >
            <Typography className={classes.selectText}>Ticketing</Typography>
          </MenuItem>
          <MenuItem value="srp">
            <Typography className={classes.selectText}>Console Input</Typography>
          </MenuItem>
        </Select>
      </FormControl>
    </StepWrapper>
  );
}

Collection.label = 'Select collection method';
