import { useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { organizationOverviewService } from '../../../../services';
import { Tabs } from '../../../../reusables/scl';
import { useAbort } from '../../../../../effects';
import Indicators from './Indicators';
import TabTechnology from './TabTechnology';
import TabPersonnel from './TabPersonnel';
import { OrganizationOverview } from '../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 24,
  },
  tabs: {
    marginTop: 28,
    marginBottom: 26,
  },
}));

export default function Overview() {
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(0);
  const [overview, setOverview] = useState<OrganizationOverview>();

  useAbort(() => organizationOverviewService.overview(), result => setOverview(result), []);

  if (!overview) {
    return <></>;
  }

  return (
    <div>
      <Typography variant="h2" className={classes.title}>
        Your Organization Overview
      </Typography>
      <Indicators technology={overview.techPerformanceOverTime} personnel={overview.personnelPerformanceOverTime} />
      <Tabs
        className={classes.tabs}
        selected={selectedTab}
        onSelect={setSelectedTab}
        labels={['Technology', 'Personnel']}
      />
      <div>
        {(selectedTab === 0) && <TabTechnology overview={overview} />}
        {(selectedTab === 1) && <TabPersonnel overview={overview} />}
      </div>
    </div>
  );
}
