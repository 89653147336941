import { useCallback, useMemo } from 'react';
import { MenuItem } from '@mui/material';
import { capitalize } from '@sightgain/core/strings';
import { RunOption, RunOptionInput } from '../services/interfaces';
import TextField from './TextField';

export default function DynamicInput({
  onUpdate,
  option: { key, label, options, isRequired },
  value,
}: DynamicInputProps) {
  const friendlyLabel = useMemo(() => capitalize(label.replace(/_/g, ' ')), [label]);

  const items = useMemo(
    () =>
      options &&
      options.map(option => (
        <MenuItem key={`${key}-${option.value}`} value={option.value}>
          {capitalize(option.label ?? option.value)}
        </MenuItem>
      )),
    [key, options],
  );

  const handleUpdate = useCallback(
    ({ target: { value } }: UpdateEvent) => {
      return onUpdate({ key, value });
    },
    [key, onUpdate],
  );

  return (
    <div className="dyanmic-input">
      <TextField
        type={options && options.length ? 'select' : 'text'}
        required={isRequired}
        label={friendlyLabel}
        onChange={handleUpdate}
        value={value}
      >
        {items}
      </TextField>
    </div>
  );
}

interface DynamicInputProps {
  option: RunOption;
  value?: string;
  onUpdate: (value: RunOptionInput) => unknown;
}

type UpdateEvent = {
  target: {
    value: string;
  };
};
