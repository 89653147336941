import { Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { UserGroup } from '../../../services/interfaces';
import { SelectGroupCallback } from './interfaces';

const useStyles = makeStyles(() => ({
  groupList: {
    overflowX: 'auto',
    height: 280,
  },
}));

const columns = [{
  field: 'name',
  headerName: 'Name',
  flex: 2,
  editable: false,
}, {
  field: 'description',
  headerName: 'Description',
  flex: 10,
  editable: false,
}];

export default function GroupList({ groups, onSelectGroup }: GroupListProps) {
  const classes = useStyles();

  return (
    <>
      <Typography variant="h5" style={{ marginBottom: 20, marginTop: 30 }}>Groups</Typography>
      <div data-testid="group-list-table" className={classes.groupList}>
        <DataGrid
          rows={groups}
          columns={columns as any}
          density="compact"
          onRowClick={e => onSelectGroup(e.row.id)}
        />
      </div>
    </>
  );
}

type GroupListProps = {
  groups: UserGroup[];
  onSelectGroup: SelectGroupCallback;
}