import { transpose } from '@sightgain/core';
import { round } from '@sightgain/core/calculations';
import { ResultPerformance, ScorePerformance } from '../../../../services/interfaces';

type Performance = ScorePerformance | ResultPerformance;
type TransposeType<T extends Performance> = string | T['performance'][keyof T['performance']];
type TransposeResult = [string[], ...number[][]];

export function performanceSeries<T extends Performance>(
  values: T[],
  ...fields: Array<keyof T['performance']>
): [string[], ...number[][]] {
  return transpose<TransposeType<T>>(
    values.map(v => [v.label, ...fields.map(f => (v.performance as T['performance'])[f])]),
  ) as TransposeResult;
}

export function roundArray(values: number[] = []): number[] {
  return values.map(v => round(v, 2));
}
