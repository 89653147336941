import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import appStore from '../../../AppStore';
import { FULL_SETTINGS_ROUTES, LIMITED_SETTINGS_ROUTES, SETTINGS_ROUTES } from './routes';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    backgroundColor: '#0a0c10',
    marginTop: 30,
  },
  tabs: {
    flex: '0 0 260px',
  },
  indicator: {
    display: 'none',
  },
  tab: {
    minWidth: 260,
    paddingLeft: 30,
    borderBottom: 'solid 1px rgba(94, 111, 130, 0.39)',
    lineHeight: '67px',
    alignItems: 'flex-start',
    letterSpacing: 'normal',
    fontSize: 12,
    color: '#4a5169',
  },
  panel: {
    flex: '1 1 100%',
    padding: '27px 54px',
    backgroundColor: '#303f52',
    overflowY: 'auto',
  },
  selected: {
    backgroundColor: '#161926 !important',
    '& span': {
      color: '#fff',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default function Settings() {
  const classes = useStyles();
  const { setting } = useParams<{ setting: string }>();
  const history = useHistory();

  const tabs = useMemo(() => {
    if (appStore.hideContentPacks) {
      return Object.keys(SETTINGS_ROUTES).sort();
    }

    if (appStore.isLimitedLicense) {
      return Object.keys(LIMITED_SETTINGS_ROUTES).sort();
    }

    return Object.keys(FULL_SETTINGS_ROUTES).sort();
  }, []);

  const ActiveTab = FULL_SETTINGS_ROUTES[setting];

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h1">Settings</Typography>
        <Button variant="contained" href="https://www.sightgain.com" target="_blank">
          Buy License
        </Button>
      </div>
      <Box className={classes.root}>
        <Tabs
          orientation="vertical"
          value={tabs.indexOf(setting)}
          onChange={(e, v) => history.push(`/settings/${tabs[v]}`)}
          classes={{ root: classes.tabs, indicator: classes.indicator }}
        >
          {tabs.map(t => (
            <Tab
              classes={{ root: classes.tab, selected: classes.selected }}
              label={capitalize(t.replace(/-/g, ' '))}
              key={t}
            />
          ))}
        </Tabs>
        <div className={classes.panel}>
          <ActiveTab />
        </div>
      </Box>
    </div>
  );
}
