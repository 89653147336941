import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  result: {
    border: '1px solid #ffffff',
    borderRadius: '50%',
    padding: '8px 10px',
    width: 25,
    height: 25,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  pass: {
    backgroundColor: 'rgba(0, 255, 177, 0.36)',
    '& svg': {
      color: '#00ffb1',
      fontSize: 17,
    },
  },
  fail: {
    backgroundColor: 'rgba(248, 92, 92, 0.36)',
    '& svg': {
      color: '#f85c5c',
      fontSize: 17,
    },
  },
}));

type ResultStatuses = 'pass' | 'fail';

interface ResultBadgeItemProps {
  status: string;
}

function ResultBadgeItem({ status }: ResultBadgeItemProps) {
  const classes = useStyles();

  return (
    <span className={`${classes.result} ${status}`}>
      <FontAwesomeIcon icon={['far', status === 'pass' ? 'check-circle' : 'times-circle']} />
    </span>
  );
}

interface ResultBadgeProps {
  result: ResultStatuses;
}

export default function ResultBadge({ result }: ResultBadgeProps) {
  const classes = useStyles();

  return (
    {
      pass: <ResultBadgeItem status={classes.pass} />,
      fail: <ResultBadgeItem status={classes.fail} />,
    }[result] || null
  );
}
