import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles(() => ({
  icon: {
    marginRight: 7,
  },
  badge: {
    fontSize: 12,
    lineHeight: 1.5,
    color: '#a7adb5',
    fontFamily: 'Lexend',
  },
}));

export default function CardBadge({ icon, text }: CardBadgeProps) {
  const classes = useStyles();

  return (
    <span className={classes.badge}>
      {!!icon && <FontAwesomeIcon className={classes.icon} icon={icon} />}
      {text}
    </span>
  );
}

type CardBadgeProps = {
  icon?: [IconPrefix, IconName];
  text: string;
}
