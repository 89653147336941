import { Button, ButtonProps, CircularProgress } from '@mui/material';
import { MouseEvent, useEffect, useState } from 'react';

export default function WaitingButton(
  { value, disabled = false, children, onClick, waitTime = 500, ...props }: WaitingButtonProps) {
  const [isWaiting, setIsWaiting] = useState(false);

  useEffect(() => {
    if (!isWaiting) {
      return;
    }

    setIsWaiting(false);
  }, [value]);
  

  const handleClick = async (e: MouseEvent) => {
    e.stopPropagation();
    if (isWaiting) {
      return;
    }

    setIsWaiting(true);
    await Promise.all([
      onClick(e),
      new Promise(resolve => setTimeout(() => resolve(undefined), waitTime)),
    ]);
  };

  return (
    <Button
      {...props}
      onClick={handleClick}
    >
      {!isWaiting && children}
      {isWaiting && (
        <CircularProgress color="primary" size="2rem" />
      )}
    </Button>
  );
}

type WaitingButtonProps = ButtonProps & {
  // a string property to inform the button that an update has occurred
  value: string;
  onClick: (e: MouseEvent) => void | Promise<void>;
  // the shortest period of time to pretend to think (ui fun)
  waitTime?: number;
};