import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowBadge, NumberBadge, ProgressBadge, StatusBadge, TierBadge } from '../../Badges';
import Timer from '../../Timer';

const useStyles = makeStyles({
  badgeWrapper: {
    maxWidth: 600,
    marginTop: 20,
    marginBottom: 20,
  },
});

export default function PreviewBadges() {
  const classes = useStyles();
  return (
    <>
      <h2> Badges </h2>
      <Grid container spacing={1} className={classes.badgeWrapper}>
        <Grid item xs={3}>
          <TierBadge tier="tier-1" />
        </Grid>
        <Grid item xs={3}>
          <TierBadge tier="tier-2" />
        </Grid>
        <Grid item xs={3}>
          <TierBadge tier="tier-3" />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3}>
          <ProgressBadge progress="completed" />
        </Grid>
        <Grid item xs={3}>
          <ProgressBadge progress="expired" />
        </Grid>
        <Grid item xs={3}>
          <ProgressBadge progress="passed" />
        </Grid>
        <Grid item xs={3}>
          <ProgressBadge progress="earned" />
        </Grid>
        <Grid item xs={3}>
          <StatusBadge status="running" />
        </Grid>
        <Grid item xs={3}>
          <StatusBadge status="waiting" />
        </Grid>
        <Grid item xs={3}>
          <StatusBadge status="ended" />
        </Grid>
        <Grid item xs={3} />
        <Grid item xs={3}>
          <ArrowBadge trend="up" />
        </Grid>
        <Grid item xs={3}>
          <ArrowBadge trend="down" />
        </Grid>
        <Grid item xs={3}>
          <NumberBadge tag>12</NumberBadge>
        </Grid>
        <Grid item xs={3}>
          <Timer />
        </Grid>
      </Grid>
    </>
  );
}