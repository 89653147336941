import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp, SizeProp } from '@fortawesome/fontawesome-svg-core';
import { MouseEventHandler } from 'react';

const useStyles = makeStyles(() => ({
  button: {
    background: 'none',
    color: 'inherit',
    border: 'none',
    padding: 0,
    font: 'inherit',
    cursor: 'pointer',
    outline: 'inherit',
  },
}));

function FaIconButton({ icon, size = '1x', onClick }: FaIconButtonProps) {
  const classes = useStyles();

  return (
    <button type="button" className={classes.button} onClick={onClick}>
      <FontAwesomeIcon icon={icon} size={size} />
    </button>
  );
}

interface FaIconButtonProps {
  // ex: ['fal', 'info-circle']
  icon: IconProp;
  onClick: MouseEventHandler;
  size?: SizeProp;
}

export default FaIconButton;
