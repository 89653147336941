import { FormEvent, useState } from 'react';
import TextField from '@mui/material/TextField';
import Modal from '../../../../reusables/Modal';
import { threatGroupsService } from '../../../../services';
import { useFrameworkContext } from '../FrameworkContext';

interface ThreatGroupModalProps {
  open: boolean;
}

export default function ThreatGroupModal({ open = false }: ThreatGroupModalProps) {
  const [nameError, setNameError] = useState('');
  const [threatGroup, setThreatGroup] = useState('');

  const { setModalThreatGroupProperties, setSettings, settings, setThreatGroups } = useFrameworkContext();

  const beforeClose = async (result = { threatGroup: '' }) => {
    if (!result.threatGroup) {
      setModalThreatGroupProperties({ open: false });
      return;
    }
    const { threatGroup: name } = result;
    const newThreatGroup = await threatGroupsService.createThreatGroup(name);
    setThreatGroups(threatGroups => [...threatGroups, newThreatGroup].sort((a, b) => (a.name < b.name ? -1 : 1)));
    setSettings({ ...settings, threatGroup: newThreatGroup.id });
    setModalThreatGroupProperties({ open: false });
  };

  const handleSaveClick = (event: FormEvent) => {
    event.preventDefault();
    let hasError = false;
    if (threatGroup === '') {
      setNameError('threat group name is required');
      hasError = true;
    }

    if (hasError) {
      return;
    }

    beforeClose({ threatGroup });
  };

  return (
    <Modal
      open={open}
      onClose={() => beforeClose()}
      onSubmit={handleSaveClick}
      title="Create Threat Group"
      actions={[
        { text: 'Save', submit: true, color: 'primary' },
        { text: 'Cancel', onClick: () => beforeClose() },
      ]}
    >
      <TextField
        error={!!nameError}
        helperText={nameError}
        autoFocus
        variant="standard"
        margin="dense"
        id="threat_group_name"
        label="Threat Group Name"
        type="text"
        onChange={({ target: { value } }) => setThreatGroup(value)}
        fullWidth
      />
    </Modal>
  );
}
