import { RadarChartMetric } from '../../../../../reusables/metrics';
import { ScorePerformance } from '../../../../../services/interfaces';
import { performanceSeries, roundArray } from '../utilites';

export default function ChartPersonnelByTactic({ performance }: { performance: ScorePerformance[] }) {
  const [labels, responseScores, precisionScores, scores] = performanceSeries(performance,
    'responseScore', 'precisionScore', 'score');

  const series = [
    { name: 'Timeliness', data: roundArray(responseScores) },
    { name: 'Precision', data: roundArray(precisionScores) },
    { name: 'Composite', data: roundArray(scores) },
  ];

  return (
    <RadarChartMetric
      title="Personnel performance by tactic"
      series={series}
      labels={labels ?? []}
    />
  );
}
