import Chart from 'react-apexcharts';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import ChartLabel from './ChartLabel';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 14,
  },
  title: {
    color: '#ffffff',
    textTransform: 'uppercase',
  },
  label: {
    marginLeft: 24,
  },
  chartWrapper: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    borderRadius: 6,
  },
}));

interface BarChartMetricProps {
  title: string;
  // Labels to use under each bar (ex. [01/01, 01/02])
  labels: string[];
  /**
   * Series to display. The name is the name of the series and data is an
   *  array that has the same length as 'labels'
   */
  series: {
    name: string;
    data: number[];
  }[];
  // Colors for each entry in series.
  colors: string[];
}

export default function BarChartMetric({ title, labels, series, colors }: BarChartMetricProps) {
  const classes = useStyles();

  const options = {
    chart: {
      toolbar: {
        show: false,
      },
      selection: {
        enabled: false,
      },
      sparkline: {
        enabled: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: '36%',
      },
    },
    grid: {
      show: false,
    },
    title: {
      show: false,
    },
    colors,
    dataLabels: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          colors: labels.map(() => '#a7adb5'),
          fontSize: 12,
          fontFamily: 'Lexend',
          fontWeight: 'normal',
        },
      },
      axisBorder: {
        color: '#61788e',
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.labelsWrapper}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {series.map((s, id) => (
          <ChartLabel key={s.name} className={classes.label} label={s.name} color={colors[id]} />
        ))}
      </div>
      <div className={classes.chartWrapper}>
        <Chart type="bar" series={series} options={options} height="100%" />
      </div>
    </div>
  );
}
