import { capitalize } from '@sightgain/core/strings';
import { DashboardBarChartMetric, formatterCurrency } from '../../../reusables/scl';
import { RoiCostBenefit } from '../../../services/interfaces';

export default function TechnologyCostBenefitChart({ costBenefit }: { costBenefit: RoiCostBenefit }) {
  const { labels, cost, value } = costBenefit;

  const series = [
    { name: 'Investment', data: cost },
    { name: 'Current Risk Reduction', data: value },
    // { name: 'Max Risk Reduction', data: maxValue }, // disabled for now
  ];

  return (
    <DashboardBarChartMetric
      title="Technology Cost Benefit"
      direction="horizontal"
      series={series}
      labels={labels.map(l => capitalize(l))}
      valueAxis={{
        formatter: formatterCurrency as (value: number) => string,
        ticks: 4,
      }}
    />
  );
}
