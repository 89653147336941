import { DashboardMixedChartMetric } from '../../../../../reusables/metrics';
import { ScorePerformance } from '../../../../../services/interfaces';
import { performanceSeries, roundArray } from '../utilites';

export default function ChartPersonnelOverTime({ performance }:{ performance: ScorePerformance[] }) {
  const [labels, scores, responseTimes ] = performanceSeries(performance, 'score', 'responseTime');

  const series = [{
    name: 'Composite Score',
    type: 'bar',
    data: roundArray(scores),
  }, {
    name: 'Mean Response Time',
    type: 'area',
    data: roundArray(responseTimes),
  }];

  return (
    <DashboardMixedChartMetric
      title="Personnel performance over time"
      series={series}
      labels={labels ?? []}
      valueAxis={{
        ticks: 4,
        series: [{
          seriesName: 'Composite Score',
          show: true,
          min: 0,
          max: 100,
          decimalsInFloat: 0,
        }]
      }}
      stroke={{
        width: [2,2,2],
        curve: ['smooth','smooth','smooth'],
      }}
    />
  );
}
