import React from 'react';
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import {
  IconButton,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import logoSmall from '../../images/nav/SightGain Favicon White.png';
import { SRPModule } from './interfaces';
import appStore from '../../../AppStore';
import { getModulePath } from './utils';

const useStyles = makeStyles(() => ({
  logo: {
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: 23,
    borderBottom: '1px solid #2c324b',
  },
  logoButton: {
    padding: 19,
    paddingLeft: 24,
    paddingRight: 16,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: 300,
    borderRadius: 6,
    height: 85,
  },
  logoButtonSmall: {
    borderRadius: 6,
  },
  logoButtonBg: {
    backgroundColor: '#364558',
  },
  onlyChild: {
    '&:only-child': {
      margin: '0 auto',
      height: 40,
    },
  },
  hide: {
    display: 'none',
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    border: 'solid 1px #5d6e80',
    backgroundColor: '#0b0c12',
    padding: 73,
    paddingLeft: 95,
    paddingRight: 95,
    width: 1105,
    height: 671,
  },
  modules: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: 20,
    marginTop: 32,
  },
}));

function Selector({
  open,
  activeModule,
  modules,
}: SelectorProps) {
  const classes = useStyles();
  const history = useHistory();

  if (!activeModule || modules.filter(m => m.name === activeModule).length === 0) {
    return null;
  }

  const { img } = modules.filter(m => m.name === activeModule)[0];

  const handleClick = () => {
    if (modules.length > 1) {
      history.push('/select-module');
      return;
    }

    if (appStore.isLimitedLicense) {
      history.push('/');
      return;
    }

    history.push(getModulePath(modules[0]));
  };

  return (
    <div className={classes.logo}>
      {/* hide images to make rendering faster, so that they are initially loaded */}
      <div
        className={clsx(classes.logoButton, classes.logoButtonBg, !open && classes.hide)}
      >
        <img src={img} alt="SightGain" height="32px" className={classes.onlyChild} />
        {modules.length > 1 && (
          <IconButton onClick={handleClick} size="large">
            <MenuIcon />
          </IconButton>
        )}
      </div>
      <div
        className={clsx(
          classes.logoButtonSmall,
          open && classes.hide,
          classes.logoButtonBg,
        )}
      >
        <IconButton onClick={handleClick} size="large">
          <img src={logoSmall} alt="SightGain" width="62px" />
        </IconButton>
      </div>
    </div>
  );
}

interface SelectorProps {
  open: boolean;
  activeModule: string;
  modules: SRPModule[];
}

export default React.memo(Selector);
