import { Button, Popover, Tooltip } from '@mui/material';
import { SketchPicker, RGBColor } from 'react-color';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  colorButton: {
    width: 25,
    height: 25,
    minWidth: 'auto',
    minHeight: 'auto',
    padding: 0,
    margin: '0 2px 0 0',
  }
});

function toColor(color: RGBColor) {
  const rgb = `${color.r},${color.g},${color.b}`;
  if (color.a) {
    return `rgba(${rgb}, ${color.a})`;
  }

  return `rgb(${rgb})`;
}

export default function ColorPicker({ color, defaultColor, onChange }: ColorPickerProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  return (
    <>
      <Tooltip title="click to adjust color">
        <Button
          className={classes.colorButton}
          style={{ backgroundColor: color }}
          onClick={event => setAnchorEl(event.currentTarget)}
        />
      </Tooltip>
      {defaultColor && (
        <Tooltip title="click to reset to default">
          <Button
            className={classes.colorButton}
            style={{ backgroundColor: defaultColor }}
            onClick={() => onChange(defaultColor)}
          />
        </Tooltip>
      )}
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      >
        <SketchPicker color={color} onChange={c => onChange(toColor(c.rgb))} />
      </Popover>
    </>
  );
}

interface ColorPickerProps {
  color: string;
  defaultColor?: string;
  onChange: (c: string) => unknown;
}