import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import ScrollableList from '../../../reusables/ScrollableList';
import { ActivityLFT } from '../../../reusables/useActivityWatcher/interfaces';
import { TrainingGroupPreview } from '../../../services/interfaces';
import ActivityCard from './ActivityCard';

const useStyles = makeStyles({
  completedCoursesList: {
    height: 'calc(100% - 38px)',
  },
  completedCourseListEmpty: {
    height: 'calc(100% - 38px)',
    background: 'rgba(255, 255, 255, 0.036)',
    borderRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  completedCourseListEmptyContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > h3': {
      marginBottom: 14,
    },
  },
  completedCard: {
    marginBottom: 12,
  },
});

export default function CompletedActivities({
  activities = [],
  type,
  selectedActivityId,
  setSelectedActivityId,
}: CompletedActivitiesProps) {
  const classes = useStyles();

  if (!activities.length) {
    return (
      <div className={classes.completedCourseListEmpty}>
        <div className={classes.completedCourseListEmptyContent}>
          <Typography variant="h3">{`You haven’t completed any ${capitalize(type)} yet!`}</Typography>
          <Typography variant="body1">Show us what you’ve got!</Typography>
        </div>
      </div>
    );
  }

  return (
    <div className={classes.completedCoursesList}>
      <ScrollableList direction="y">
        {activities.map(item => (
          <ActivityCard
            key={`activity-card-${item.id}`}
            item={item}
            type={type}
            selected={selectedActivityId === item.id}
            onClick={() => setSelectedActivityId(item.id)}
            completed
          />
        ))}
      </ScrollableList>
    </div>
  );
}

type CompletedActivitiesProps = {
  activities?: ActivityLFT[] | TrainingGroupPreview[];
  type: 'courses' | 'evaluations' | 'curriculums' | 'exams';
  selectedActivityId?: string;
  setSelectedActivityId: (id: string) => unknown;
};
