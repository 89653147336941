import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  circle: {
    backgroundColor: '#2ecff5',
    borderRadius: '50%',
    padding: '8px 10px',
    width: 28,
    height: 28,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 14,
    fontWeight: 'bold',
    color: '#262d48',
  },
}));

export default function CircleBadge({ text }: { text: string }) {
  const classes = useStyles();

  return (
    <div className={classes.circle}><span>{text}</span></div>
  );
}
