import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  completed: {
    color: '#2ecff5',
    '& > $meter': {
      borderLeft: 'none',
    },
    '& > $meter > span': {
      display: 'block',
      height: '100%',
      width: '100%',
      backgroundColor: '#21bee3',
      position: 'relative',
      overflow: 'hidden',
    },
  },
  current: {
    color: '#ffffff',
    '& > $meter': {
      borderLeft: 'none',
    },
    '& > $meter > span': {
      position: 'absolute',
      top: 0,
      left: '-46px',
      bottom: 0,
      right: 0,
      background:
        'repeating-linear-gradient(-55deg, #65d1eb 1px, #21bee3 2px, #21bee3 11px, #65d1eb 12px, #65d1eb 20px)',
      zIndex: 1,
      animationName: '$move',
      animationDuration: '0.8s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    },
  },
  future: {
    color: '#ffffff',
    '& > $label': {
      opacity: 0.23,
    },
  },
  label: {
    fontFamily: 'Lexend',
    fontSize: 10,
    fontWeight: 'bold',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.8,
    letterSpacing: 'normal',
    textAlign: 'left',
    marginLeft: 42,
    marginRight: 42,
  },
  meter: {
    marginTop: 13,
    height: 25,
    position: 'relative',
    background: 'rgba(255, 255, 255, 0.09)',
    borderLeft: 'solid 1px rgba(94, 111, 130, 0.39)',
    overflow: 'hidden',
  },
  meterFirst: {
    borderLeft: 'none',
    borderTopLeftRadius: 100,
    borderBottomLeftRadius: 100,
  },
  meterLast: {
    borderTopRightRadius: 100,
    borderBottomRightRadius: 100,
  },
  '@keyframes move': {
    '0%': {
      transform: 'translateX(0)',
    },
    '100%': {
      transform: 'translateX(46px)',
    },
  },
}));

interface ProgressStagesProps {
  stages: string[];
  current: number;
}

export default function ProgressStages({ stages, current }: ProgressStagesProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {stages.map((stage, id) => (
        <div
          key={stage}
          className={clsx({
            [classes.completed]: id < current,
            [classes.current]: id === current,
            [classes.future]: id > current,
          })}
        >
          <span className={classes.label}>{stage}</span>
          <div
            className={clsx(classes.meter, {
              [classes.meterFirst]: id === 0,
              [classes.meterLast]: id === stages.length - 1,
            })}
          >
            <span />
          </div>
        </div>
      ))}
    </div>
  );
}
