import { parseISO, formatDistance, subMinutes, isBefore, isSameMonth, startOfDay } from 'date-fns';
import preview1 from '../../reusables/scl/preview-1.jpg';
import preview2 from '../../reusables/scl/Image 2.jpg';
import preview3 from '../../reusables/scl/Image 6.jpg';
import preview4 from '../../reusables/scl/Image 9.jpg';
import { CalendarEvent } from '../../services/interfaces';

const statuses = ['inProgress', 'notStarted', 'completed'];
const labels: { [propName: string]: string } = {
  inProgress: 'In Progress',
  notStarted: 'Not Started',
  completed: 'Completed',
};

export const resolveCurriculumImageForDemo = (title: string) => {
  if (!title) {
    return null;
  }

  if (title.startsWith('MITRE')) {
    return preview1;
  }

  if (title.startsWith('National Initiative')) {
    return preview2;
  }

  if (title.startsWith('Threat')) {
    return preview3;
  }

  return preview4;
};

type GroupByStatusResult<T> = { label: string; items: T[] }
export const groupByStatus = <T extends { status: string }>(groups: T[]): GroupByStatusResult<T>[] => {
  const result: GroupByStatusResult<T>[] = [];

  statuses.forEach(status => {
    const filteredGroups = groups.filter(g => g.status === status);
    if (filteredGroups.length !== 0) {
      result.push({
        label: labels[status],
        items: filteredGroups,
      });
    }
  });

  return result;
};

/**
 * Calculate the default selected event. Filters all events in this month, and sorts them by day.
 *  Then if there is an event today, returns that. If there is an event in the future, it returns that.
 *  If there is an event in the past, it returns that.
 * @param {*} allEvents - List of events
 * @returns the default selected event or undefined if none is selected.
 */
type DefaultSelectedEventResult = CalendarEvent & { date: Date };
export const defaultSelectedEvent = (allEvents: CalendarEvent[]): DefaultSelectedEventResult | undefined => {
  const now = startOfDay(Date.now());
  const parsedEvents = allEvents.map(e => ({ ...e, date: parseISO(e.day) }));
  const events = parsedEvents.filter(e => isSameMonth(e.date, now));

  if (!events) {
    return undefined;
  }

  const sortedEvents = events.sort((a, b) => {
    return isBefore(a.date, b.date) ? -1 : 1;
  });

  return sortedEvents.find(e => e.date > now);
};

/**
 * Returns a friendly string about the duration of time
 * @param m number of minutes
 */
export const durationText = (m: number): string => {
  const n = new Date();
  return formatDistance(n, subMinutes(n, m));
};

/**
 * Returns a friendly value based on n and a label
 * @param n number related to amount of time
 * @param singular singlar label
 * @param plural plural label (default is singular label + s)
 */
export const labeledNumber = (n: number, singular = 'Day', plural?: string) => {
  let p = plural ?? '';

  if (!p) {
    p = `${singular}s`;

    if (/s|z|ch|sh|x$/.test(singular)) {
      p = `${singular}es`;
    }
  }

  return `${n} ${n > 1 ? p : singular}`;
};
