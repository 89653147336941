import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { round } from '@sightgain/core/calculations';
import appStore from '../../../AppStore';
import { useAbort } from '../../../effects';
import ColorPicker from '../../reusables/ColorPicker';
import { TemperatureComponent, TemperatureSlider } from '../../reusables/TemperatureSlider';
import TextField from '../../reusables/TextField';
import { settingsService } from '../../services';
import { ThresholdColors } from '../../services/interfaces';

export const defaultColors = {
  green: 'rgba(0, 255, 177, 0.49)',
  yellow: 'rgba(255, 255, 0, 0.49)',
  orange: 'rgba(255, 165, 6, 0.49)',
  red: 'rgba(248, 92, 92, 0.49)',
  disabled: '#606060',
  none: '#203045',
};

const boxStyles = makeStyles(() => ({
  body: {
    borderRadius: '0 0 6px 6px',
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
    padding: '33px 28px 55px',
  },
  header: {
    borderRadius: '6px 6px 0 0',
    backgroundColor: '#0a0c10',
    paddingLeft: 27,
  },
  headerText: {
    fontWeight: 'bold',
    letterSpacing: 0.6,
    lineHeight: '44px',
    textTransform: 'uppercase',
  },
  wrapper: {
    marginBottom: 33,
  },
}));

function Box({ title, children }: { title: string; children: React.ReactNode }) {
  const classes = boxStyles();
  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant="body2" className={classes.headerText}>
          {title}
        </Typography>
      </div>
      <div className={classes.body}>{children}</div>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 16,
  },
  control: {
    display: 'flex',
    flexDirection: 'column',
    gap: 20,
    alignItems: 'flex-start',
  },
  label: {},
  submitButton: {
    fontSize: 14,
    backgroundColor: '#21bee3',
    color: '#fff',
    borderRadius: 2,
  },
  retakes: {
    width: 160,
  },
  colorPicker: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
}));

const SETTING_NAMES = [
  'cronScoringFrequency',
  'thresholdColors',
  'preventThresholds',
  'detectThresholds',
  'threatPreparednessThresholds',
  'roiThresholds',
  'frameworkScoringThresholds',
  'cronTimeout',
  'assessorThreshold',
  'compoundWeights',
];

export default function MiscSettings() {
  const classes = useStyles();
  // settings pulled from server
  const [settings, setSettings] = useState<{ [name: string]: string }>({});
  // track which settings actually changed
  const [updated, setUpdated] = useState<string[]>([]);

  useAbort(
    () => {
      return settingsService.list(SETTING_NAMES);
    },
    result => setSettings(result.reduce((a, b) => ({ ...a, [b.name]: b.value }), {} as { [name: string]: string })),
    [],
  );

  const saveSettings = async () => {
    appStore.beginLoading();
    try {
      const updates = await Promise.all(updated.map(name => settingsService.save({ name, value: settings[name] })));
      setSettings(prev => updates.reduce((a, b) => ({ ...a, [b.name]: b.value }), { ...prev }));

      appStore.success('settings saved successfully');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const handleUpdate = (name: string, value: string | number[] | ThresholdColors) => {
    setSettings({ ...settings, [name]: typeof value === 'string' ? value : JSON.stringify(value) });
    setUpdated(prev => [...new Set([...prev, name])]);
  };

  const thresholdColors: ThresholdColors = useMemo(() => {
    if (!settings?.thresholdColors) {
      return { ...defaultColors };
    }

    return JSON.parse(settings.thresholdColors);
  }, [settings?.thresholdColors]);

  return (
    <>
      <Typography variant="h3" className={classes.title}>
        Miscellaneous
      </Typography>

      {/* Cron Scoring Frequency */}
      <Box title="Scoring Frequency">
        <TextField
          label="Scoring Frequency in minutes"
          value={`${round(+settings.cronScoringFrequency / 60, 0)}`}
          type="number"
          onChange={e => handleUpdate('cronScoringFrequency', `${round(+e.target.value * 60, 0)}`)}
        />
      </Box>

      {/* Cron Timeout */}
      <Box title="Scheduled Task Timeout">
        <TextField
          label="Scheduled Task Timeout in minutes"
          value={`${round(+settings.cronTimeout / 60, 0)}`}
          type="number"
          inputProps={{
            min: 120,
            max: 1440,
          }}
          onChange={e => handleUpdate('cronTimeout', `${round(+e.target.value * 60, 0)}`)}
        />
      </Box>

      {/* Threshold coloring */}
      <Box title="Customize Colors">
        <div className={classes.colorPicker}>
          <div>
            <Typography variant="body1" className={classes.label}>
              Low
            </Typography>
            <ColorPicker
              color={thresholdColors.red}
              defaultColor={defaultColors.red}
              onChange={red => handleUpdate('thresholdColors', { ...thresholdColors, red })}
            />
          </div>
          <div>
            <Typography variant="body1" className={classes.label}>
              Medium Low
            </Typography>
            <ColorPicker
              color={thresholdColors.orange}
              defaultColor={defaultColors.orange}
              onChange={orange => handleUpdate('thresholdColors', { ...thresholdColors, orange })}
            />
          </div>
          <div>
            <Typography variant="body1" className={classes.label}>
              Medium High
            </Typography>
            <ColorPicker
              color={thresholdColors.yellow}
              defaultColor={defaultColors.yellow}
              onChange={yellow => handleUpdate('thresholdColors', { ...thresholdColors, yellow })}
            />
          </div>
          <div>
            <Typography variant="body1" className={classes.label}>
              High
            </Typography>
            <ColorPicker
              color={thresholdColors.green}
              defaultColor={defaultColors.green}
              onChange={green => handleUpdate('thresholdColors', { ...thresholdColors, green })}
            />
          </div>
        </div>
      </Box>

      {/* Product Bake-Off Settings */}
      <Box title="Product Bake-Off Settings">
        <>
          {settings.preventThresholds && (
            <div>
              <Typography variant="body1" className={classes.label}>
                Prevent/Block
              </Typography>
              <TemperatureSlider
                valueLabelDisplay="on"
                component={TemperatureComponent}
                value={settings.preventThresholds}
                onChange={(_: any, value: number[]) => handleUpdate('preventThresholds', value)}
              />
            </div>
          )}

          {settings.detectThresholds && (
            <div>
              <Typography variant="body1" className={classes.label}>
                Detect/Alert
              </Typography>
              <TemperatureSlider
                valueLabelDisplay="on"
                component={TemperatureComponent}
                value={settings.detectThresholds}
                onChange={(_: any, value: number[]) => handleUpdate('detectThresholds', value)}
              />
            </div>
          )}
        </>
      </Box>

      {/* ROI Threshold Settings */}
      <Box title="ROI Threshold Settings">
        <div>
          <Typography variant="body1" className={classes.label}>
            ROI Performance Percentage
          </Typography>
          <TemperatureSlider
            valueLabelDisplay="on"
            component={TemperatureComponent}
            value={settings.roiThresholds}
            onChange={(_: any, value: number[]) => handleUpdate('roiThresholds', value)}
          />
        </div>
      </Box>

      {/* Threat Preparedness Threshold Settings */}
      <Box title="Threat Preparedness Threshold Settings">
        <div>
          <Typography variant="body1" className={classes.label}>
            Threat Preparedness Levels
          </Typography>
          <TemperatureSlider
            valueLabelDisplay="on"
            component={TemperatureComponent}
            value={settings.threatPreparednessThresholds}
            onChange={(_: any, value: number[]) => handleUpdate('threatPreparednessThresholds', value)}
            reversed
          />
        </div>
      </Box>

      {/* Threat Preparedness Threshold Settings */}
      <Box title="Framework Scoring Threshold Settings">
        <div>
          <Typography variant="body1" className={classes.label}>
            Framework Scoring Levels
          </Typography>
          <TemperatureSlider
            key="framework-slider"
            valueLabelDisplay="on"
            component={TemperatureComponent}
            value={settings.frameworkScoringThresholds}
            onChange={(_: any, value: number[]) => handleUpdate('frameworkScoringThresholds', value)}
          />
        </div>
        <div>
          <Typography variant="body1" className={classes.label}>
            Assessor Pass / Fail Value
          </Typography>
          <TemperatureSlider
            key="assessor-slider"
            valueLabelDisplay="on"
            component={TemperatureComponent}
            value={settings.assessorThreshold}
            defaultValue={[80]}
            onChange={(_: any, value: number[]) => handleUpdate('assessorThreshold', value)}
          />
        </div>
        <div>
          <Typography variant="body1" className={classes.label}>
            Compound Scoring - Assessor Weight
          </Typography>
          <TemperatureSlider
            key="assessor-slider"
            valueLabelDisplay="on"
            component={TemperatureComponent}
            value={settings.compoundWeights}
            defaultValue={[50]}
            onChange={(_: any, value: number[]) => handleUpdate('compoundWeights', value)}
          />
        </div>
      </Box>

      <Button
        className={classes.submitButton}
        onClick={saveSettings}
        variant="contained"
        endIcon={<FontAwesomeIcon size="xs" icon={['fal', 'arrow-right']} />}
      >
        Submit Misc Settings
      </Button>
    </>
  );
}
