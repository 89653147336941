import { ProgressStages } from '../../../../reusables/scl';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  subTitle: {
    color: '#f46043',
    marginBottom: 45,
  },
  stagesWrapper: {
    marginBottom: 50,
  },
}));

function PreparingStatus({ current, isExam }: { current: number, isExam: boolean; }) {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.subTitle} variant="h4">
      Your {isExam ? 'exam' : 'Live Fire Training Exercise'} is preparing and will begin soon.
      </Typography>
      <div className={classes.stagesWrapper}>
        <ProgressStages
          stages={['Preparing Tests', 'Starting Test', 'Ready']}
          current={current}
        />
      </div>
    </>
  );
}

export default PreparingStatus;