import { makeStyles } from '@mui/styles';
import {
  Link,
  Typography,
  Button,
} from '@mui/material';
import { Link as MLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-bottts-sprites';
import Stats from '../Overview/student/Stats';
import Avatar from '../../../reusables/Avatar';
import { User } from '../../../services/interfaces';

const useStyles = makeStyles(() => ({
  studInfo: {
    border: '1px solid rgba(255, 255, 255, 0.26)',
    borderRadius: 3,
    width: 'calc(100% - 12px)',
    marginRight: 12,
    '& > div': {
      border: 'none',
    },
  },
  studInfoWrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '36px',
    borderBottom: '1px solid rgba(255, 255, 255, 0.26) !important',
  },
  studDetails: {
    paddingLeft: 20,
    '& > *': {
      paddingBottom: 5,
    },
    '& h6': {
      color: '#2ecff5',
    },
  },
  studStatsWrapper: {
    borderBottom: '1px solid rgba(255, 255, 255, 0.26) !important',
    '& > div': {
      border: 'none',
    },
  },
  assignButtonWrapper: {
    padding: '18px 36px',
    '& > button': {
      marginRight: 18,
    },
  },
}));

export default function AnalystInfo({ analyst, onAssignCurriculum }: AnalystInfoProps) {
  const classes = useStyles();

  const avatarSvg = createAvatar(style, {
    seed: analyst?.email,
    dataUri: true,
    backgroundColor: '#dddbda',
  });

  return (
    <div className={classes.studInfo}>
      <div className={classes.studInfoWrapper}>
        <div>
          <Avatar image={avatarSvg} size="m" onEdit={() => { }} />
        </div>
        <div className={classes.studDetails}>
          <Typography variant="h3">
            {analyst?.name}
          </Typography>
          <Link
            href={`mailto:${analyst?.email}`}
            component="a"
            variant="body1"
            underline="always"
          >
            {analyst?.email}
          </Link>
        </div>
      </div>
      <div className={classes.studStatsWrapper}>
        <Stats analyst={analyst?.id} />
      </div>
      <div className={classes.assignButtonWrapper}>
        <Button
          variant="contained"
          color="primary"
          endIcon={(
            <FontAwesomeIcon
              icon={['fas', 'long-arrow-right']}
              size="2x"
            />
          )}
          onClick={onAssignCurriculum}
        >
          Assign Curriculum
        </Button>
        <Button
          component={MLink}
          variant="contained"
          color="secondary"
          endIcon={<FontAwesomeIcon icon={['fas', 'long-arrow-right']} size="2x" />}
          to="/curriculum/evaluations/run-evaluation"
        >
          Assign Live Training
        </Button>
      </div>
    </div>

  );
}

type AnalystInfoProps = {
  analyst?: User;
  onAssignCurriculum: () => unknown,
};
