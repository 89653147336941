import { makeStyles } from '@mui/styles';
import { CardMethod } from '../cards/intefaces';
import FaIconButton from '../../../../reusables/FaIconButton';

const useStyles = makeStyles({
  cardInfo: {
    padding: 16,
    paddingLeft: 30,
    display: 'flex',
  },

  cardInfoBtns: {
    justifySelf: 'flex-end',
  }
});

export default function CardInfo({ onBookmark, onInfo }: CardHeaderProps) {
  const classes = useStyles();

  return (
    <div className={classes.cardInfo}>
      <div className={classes.cardInfoBtns}>
        {onInfo && <FaIconButton icon={['fal', 'info-circle']} onClick={onInfo} />}
        {onBookmark && <FaIconButton icon={['fal', 'bookmark']} onClick={onBookmark} />}
      </div>
    </div>
  );
}

type CardHeaderProps = {
  onBookmark?: CardMethod;
  onInfo?: CardMethod;
}