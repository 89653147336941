import { useMemo } from 'react';
import { round, sum } from '@sightgain/core/calculations';
import { capitalize } from '@sightgain/core/strings';
import { DashboardBarChartMetric } from '../../../reusables/scl';
import { RoiInvestmentAction } from '../../../services/interfaces';

interface TestRates {
  labels: string[];
  actioned: number[];
  total: number[];
}

export default function TestRateChart({ actioned }: { actioned: RoiInvestmentAction }) {
  const {
    actioned: prevented,
    total,
    labels,
  } = useMemo(
    () =>
      actioned.ratios.reduce(
        (a, b) => {
          a.labels.push(b.name);
          a.actioned.push(round(sum(b.data.map(v => v.value))));
          a.total.push(round(sum(b.data.map(v => v.total))));
          return a;
        },
        { labels: [], actioned: [], total: [] } as TestRates,
      ),
    [actioned],
  );

  return (
    <DashboardBarChartMetric
      title="Total Number of Tests Actioned"
      direction="horizontal"
      series={[
        { name: 'Actioned', data: prevented },
        { name: 'Total', data: total },
      ]}
      labels={labels.map(l => capitalize(l))}
      valueAxis={{
        ticks: 4,
      }}
    />
  );
}
