import { AnalystInput } from '../../../../services/interfaces';
import { ERTestResult } from './interfaces';

export default function matchEvents(testResult: ERTestResult, analystInput: AnalystInput[]) {
  const matchedAnalystInputForEvent: { [index: string]: AnalystInput[] } = {};

  testResult.events.forEach((event, index) => {
    const matchedStudentData = analystInput?.filter(ai => ai.matchedEvents.some(m => m.id === event.id));
  
    matchedAnalystInputForEvent[`${index}`] = matchedStudentData;
  });

  return matchedAnalystInputForEvent;
}
