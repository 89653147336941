import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { addCommas, humanTime } from '@sightgain/core/strings';
import Tabs from '../../../../reusables/Tabs';
import { ResponseResult } from '../../../../services/interfaces';

const useStyles = makeStyles({
  responseResults: {
    '& .hdr-cell .MuiDataGrid-columnHeaderTitle': {
      textAlign: 'center',
      fontWeight: 'bold',
    },
    '& .id-cell': {
      fontWeight: 'bold',
    },
  },
});

/**
 * Multiplies number less than 1 by 100 and then rounds
 * @param value number to round
 */
const autoRound = (value: number) => Math.round(value < 1 ? value * 100 : value);

const testResultsColumns = [
  {
    field: 'id',
    headerName: ' ',
    cellClassName: 'id-cell',
    align: 'left',
    valueFormatter: ({ value }: { value: string }) => value,
  },
  {
    field: 'events',
    headerName: '# Events',
  },
  {
    field: 'alerts',
    headerName: '# Alerts',
  },
  {
    field: 'alertsFPR',
    headerName: 'Alerts False+%',
    width: 130,
  },
  {
    field: 'mttr',
    headerName: 'MTTR',
    valueFormatter: ({ value }: { value: number }) => humanTime(autoRound(value)),
  },
].map(
  c =>
    ({
      valueFormatter: ({ value }: { value: number }) => addCommas(autoRound(value)),
      width: 120,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'hdr-cell',
      sortable: false,
      ...c,
    } as GridColDef),
);

// normal ops has the same columns except 2
const normalOpsColumns = Array.from(testResultsColumns).filter(c => ['id', 'events', 'alerts'].includes(c.field));

export default function ResponseResults({ data }: { data: ResponseResult[] }) {
  const classes = useStyles();
  const [selected, setSelected] = useState(0);

  if (data?.length === 0) {
    return null;
  }

  const rows = data
    .filter(x => x.ops === ['Normal Ops Results', 'Test Results'][selected])
    .map(y => ({
      id: y.type,
      events: y.events,
      alerts: y.alerts,
      alertsFPR: y.alertsFPR,
      mttr: y.mttr,
    }));

  const columns = selected === 0 ? normalOpsColumns : testResultsColumns;

  return (
    <div className={classes.responseResults}>
      <Tabs selected={selected} onSelect={n => setSelected(n)} labels={['Normal Ops', 'Test Results']} />
      <DataGrid density="compact" rows={rows} columns={columns} autoHeight hideFooter />
    </div>
  );
}
