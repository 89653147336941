import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Typography, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProgressBar } from '../../../reusables/scl';

const useStyles = makeStyles(() => ({
  requirement: {
    paddingTop: 33,
    paddingLeft: 27,
    paddingRight: 27,
    paddingBottom: 25,
    borderRadius: 6,
    backgroundColor: 'rgba(255, 255, 255, 0.09)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  label: {
    color: '#ffffff',
    marginBottom: 26,
    height: '2rem',
  },
  doneWrapper: {
    marginBottom: 27,
    display: 'flex',
    alignItems: 'center',
  },
  doneEmoji: {
    fontSize: 48,
  },
  doneText: {
    marginLeft: 20,
    '& > p': {
      marginTop: 9,
    },
  },
  button: {
    width: '100%',
    justifyContent: 'space-between',
  },
  rainbowButton: {
    background: 'linear-gradient(to right, #21bee3, #00ffb1)',
    border: 'none',
    '&:hover': {
      background: 'linear-gradient(to right, #1A95B0, #00CC8F)',
    },
  },
  progressWrapper: {
    marginBottom: 30,
    width: 162,
  },
  blurb: {
    marginBottom: 21,
    flexGrow: 1,
  },
}));

interface RequirementProps {
  label: string;
  blurb: string;
  completePercent?: number;
  status?: string;
  buttonClassName?: string;
  buttonLabel: string;
  onClick: () => void;
}

export default function Requirement({
  label,
  blurb,
  completePercent,
  status,
  buttonClassName = '',
  buttonLabel,
  onClick,
}: RequirementProps) {
  const classes = useStyles();
  const done = completePercent === 100 || status === 'Passed';

  return (
    <div className={classes.requirement}>
      <Typography variant="h6" className={classes.label}>
        {label}
      </Typography>
      {done && (
        <div className={classes.doneWrapper}>
          <div className={classes.doneEmoji}>🎉</div>
          <div className={classes.doneText}>
            <Typography variant="h3">You’re Done!</Typography>
            <Typography variant="body1">
              Hooray! You’ve completed the guided training requirements for this course.
            </Typography>
          </div>
        </div>
      )}
      {!done && completePercent && (
        <div className={classes.progressWrapper}>
          <ProgressBar value={completePercent} />
        </div>
      )}
      {!done && status && (
        <Typography variant="h4" style={{ marginBottom: 16 }}>
          {status}
        </Typography>
      )}
      <Typography variant="body1" className={classes.blurb}>
        {blurb}
      </Typography>
      {done && (
        <Button
          className={clsx(classes.button, classes.rainbowButton)}
          variant="contained"
          color="primary"
          onClick={onClick}
          endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
        >
          Review
        </Button>
      )}
      {!done && (
        <Button
          className={clsx(classes.button, buttonClassName)}
          variant="contained"
          color="primary"
          onClick={onClick}
          endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
        >
          {buttonLabel}
        </Button>
      )}
    </div>
  );
}
