import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { SystemUser } from './interfaces';
import ServiceBase from './ServiceBase';

const typeSystemUserData = `
  {
    type
    systemUser
    host
    database
    port
    query
    protocol
    integrationType
  }
`;

export class SystemUsersService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['updated']));
  }

  async list(type?: string, fields = typeSystemUserData): Promise<SystemUser[]> {
    const query = gql`
      query SystemUsers($type: String) {
        systemUsers(type: $type) ${fields}
      }
    `;

    const { systemUsers } = await this.graphql(query, { ...(type && { type }) }, 'network-only');
    return systemUsers;
  }

  async enabled(type: string): Promise<boolean> {
    const query = gql`
      query EnabledSystemUsers($type: String!) {
        enabledSystemUsers(type: $type)
      }
    `;

    const { enabledSystemUsers } = await this.graphql(query, { type }, 'network-only');
    return enabledSystemUsers;
  }

  async remove(type: string): Promise<string> {
    const query = gql`
      mutation DeleteSystemUser($type: String!) {
        deleteSystemUser(type: $type)
      }
    `;

    const { deleteSystemUser } = await this.graphql(query, { type });
    return deleteSystemUser;
  }

  async createOrUpdate(data: SystemUser): Promise<SystemUser> {
    const query = gql`
      mutation SaveSystemUser($input: SystemUserDataInput!) {
        saveSystemUser(input: $input) ${typeSystemUserData}
      }
    `;

    const { saveSystemUser } = await this.graphql(query, { input: data });
    return saveSystemUser;
  }
}

const systemUsersService = new SystemUsersService();
export default systemUsersService;
