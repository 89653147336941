import { Link } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';

const useStyles = makeStyles({
  cardLink: {
    display: 'flex',
    textDecoration: 'none',
  },
});

interface CardLinkProps {
  children: ReactNode;
  to: string;
  className?: string;
}

export default function CardLink({ to, children, className }: CardLinkProps) {
  const classes = useStyles();
  return (
    <Link to={to} component={RouterLink} underline="none" className={clsx(classes.cardLink, className || '')}>
      {children}
    </Link>
  );
}
