import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Breadcrumbs as MBreadcrumbs, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  separator: {
    color: '#344356',
  },
  last: {
    fontWeight: 'bold',
  },
  link: {
    textDecoration: 'none',
  },
}));

interface BreadcrumbsProps {
  values: {
    label: string;
    url?: string;
  }[];
  className?: string;
}

export default function Breadcrumbs({ values, className }: BreadcrumbsProps) {
  const classes = useStyles();

  return (
    <MBreadcrumbs
      className={className}
      separator={
        <Typography variant="h6" className={classes.separator}>
          /
        </Typography>
      }
    >
      {values.map((value, index) => {
        if (index === values.length - 1) {
          return (
            <Typography key={value.label} variant="h2">
              {value.label}
            </Typography>
          );
        }

        return (
          <Link className={classes.link} key={value.label} to={value.url as string}>
            <Typography variant="h2" className={classes.last}>
              {value.label}
            </Typography>
          </Link>
        );
      })}
    </MBreadcrumbs>
  );
}
