import { makeStyles } from '@mui/styles';
import getBadge from './getBadge';
import React from 'react';

const useStyles = makeStyles(() => ({
  sm: {
    height: 70,
    width: 70,
  },
  md: {
    height: 90,
    width: 90,
  },
  lg: {
    height: 110,
    width: 110,
  },
}));

interface AchievementProps {
  image: string;
  size?: 'sm' | 'md' | 'lg';
}

function Achievement({ image, size = 'md' }: AchievementProps) {
  const classes = useStyles();

  return <img src={getBadge(image)} alt={`${image} achievement`} className={classes[size]} />;
}

export default React.memo(Achievement);
