import { makeStyles } from '@mui/styles';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(theme => ({
  unknown: {
    fill: theme.palette.primary.main,
    float: 'right',
  },
  iconPass: {
    color: '#00ffb1',
  },
  iconFail: {
    color: '#f85c5c',
  },
}));

export default function PassFail({ pass }: { pass?: boolean }) {
  const classes = useStyles();

  if (pass === undefined) {
    return (
      <IndeterminateCheckBoxIcon className={classes.unknown} />
    );
  }
  return (
    <FontAwesomeIcon
      icon={['fal', pass ? 'check-circle' : 'times-circle']}
      className={`${pass ? classes.iconPass : classes.iconFail}`}
      size="2x"
    />
  );
}
