import { useCallback, useMemo } from 'react';
import useSocketListener from '../../../reusables/useSocketListener';
import { jobsService, liveFireTrainingService } from '../../../services';
import { ERLFT, Review } from './Review/interfaces';

export default function useReviewWatcher({ type, id, fields }: { type: 'lft' | 'job', id: string, fields: string}) {
  const msgType = useMemo(() => type === 'job' ? 'jobs' : 'livefiretraining', [type]);
  const initArgs = useMemo(() => id, [id]);

  const callBack = useCallback(async (body: string) => {
    if (!(body === 'cron:completed' || body === id)) {
      return;
    }

    if (type === 'job') {
      return await jobsService.findById(id, fields, 'no-cache');
    }

    const { job, ...lft }: ERLFT = await liveFireTrainingService.find(id, fields, 'no-cache');
    return {
      ...job,
      ...lft,
    };
  }, [type, id, fields]);

  const job = useSocketListener<Review>(msgType, 'update', callBack, initArgs);

  return job;
}