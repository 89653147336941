import { IconButton, ListItemIcon, Tooltip } from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import React from 'react';

function CloneEvaluationButton({ onClone }: CloneEvaluationButtonProps) {
  return (
    <ListItemIcon>
      <Tooltip title="Clone Evaluation">
        <IconButton onClick={onClone} size="large" aria-label="clone">
          <FileCopyIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </ListItemIcon>
  );
}

interface CloneEvaluationButtonProps {
  onClone: () => unknown;
}

export default React.memo(CloneEvaluationButton);
