import { StepIconProps } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: '#2ecff5',
    width: 27,
    height: 27,
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapperActive: {
    backgroundColor: '#344356',
    border: '2px solid #21bee3',
  },
  wrapperUpcoming: {
    background: 'rgba(255, 255, 255, 0.12)',
    border: '2px solid #4d5a6b',
  },
  icon: {
    fontSize: 14,
    fontFamily: 'Lexend',
    fontWeight: 'bold',
    lineHeight: 1.43,
    color: '#262d48',
  },
  iconActiveUpcoming: {
    color: '#ffffff',
  },
}));

export default function ProgressBarStepsIcon({ active, completed, icon }: StepIconProps) {
  const classes = useStyles();

  const getWrapperClass = () => {
    if (active) {
      return classes.wrapperActive;
    }

    if (!completed) {
      return classes.wrapperUpcoming;
    }

    return '';
  };

  const getIconClass = () => {
    if (!completed) {
      return classes.iconActiveUpcoming;
    }

    return '';
  };

  return (
    <div className={`${classes.wrapper} ${getWrapperClass()}`}>
      <span className={`${classes.icon} ${getIconClass()}`}>{icon}</span>
    </div>
  );
}
