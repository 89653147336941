import { FetchPolicy, gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { Setting, SettingInput, WeightsAndThresholds } from './interfaces';
import ServiceBase from './ServiceBase';

const typeSetting = `
  {
    name
    value
    updatedAt
  }
`;

export class SettingsService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['updatedAt']));
  }

  /**
   * Returns a specific setting from the API
   * @param name name of setting to retrieve
   */
  async find(name: string, fields = typeSetting, fetchPolicy: FetchPolicy = 'cache-first'): Promise<Setting> {
    const query = gql`
      query Setting($name: String!) {
        setting(name: $name) ${fields}
      }
    `;

    const { setting } = await this.graphql(query, { name }, fetchPolicy);
    return setting;
  }

  /**
   * Returns a list of all settings
   */
  async list(names: string[], fields?: string): Promise<Setting[]>;
  async list(fields?: string): Promise<Setting[]>;
  async list(arg1?: string[] | string, arg2?: string): Promise<Setting[]> {
    const names = typeof arg1 !== 'string' ? arg1 : undefined;
    const fields = typeof arg1 === 'string' ? arg1 : arg2 ?? typeSetting;

    const query = gql`
      query Settings($names: [String!]) {
        settings(names: $names) ${fields}
      }
    `;
    const { settings } = await this.graphql(query, { ...(names && { names }) });
    return settings;
  }

  /**
   * Saves a setting
   * @param input Setting data
   */
  async save(input: SettingInput, fields = typeSetting): Promise<Setting> {
    const mutation = gql`
      mutation SaveSetting($input: SettingDataInput!) {
        saveSetting(input: $input) ${fields}
      }
    `;

    const { saveSetting } = await this.graphql(mutation, { input });

    const listQry = gql`
      query Settings($names: [String!]) {
        settings(names: $names) ${fields}
      }
    `;

    // remove the previous version
    this.removeGQLCache(listQry, { names: [input.name] }, 'SettingData', i => i.name !== input.name);
    // add the new version
    this.appendGQLCache(listQry, { names: [input.name] }, 'SettingData', saveSetting);

    return saveSetting;
  }

  /**
   * Creates a save method for a specific setting
   * @param name
   * @param fields
   */
  createSaveFn<T>(name: string, callback: (v: string) => T, fields = typeSetting) {
    return async (value: string | object) => {
      const input: { name: string; value: string } = {
        name,
        value: typeof value === 'string' ? value : JSON.stringify(value),
      };

      const result = await this.save(input, fields);
      return callback(result.value);
    };
  }

  /**
   * Saves the weights and thresholds to the backend
   * @param value
   */
  async saveWeightsAndThresholds(value: WeightsAndThresholds): Promise<WeightsAndThresholds> {
    const result = await this.save({ name: 'weightsAndThresholds', value: JSON.stringify(value) }, '{ name value }');
    return JSON.parse(result.value);
  }

  /**
   * Returns the weights and thresholds to use for calculations
   */
  async weightsAndThresholds(): Promise<WeightsAndThresholds> {
    const { value } = await this.find('weightsAndThresholds', '{ name value }');
    return JSON.parse(value);
  }
}

const settingsService = new SettingsService();
export default settingsService;
