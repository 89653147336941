import appStore from '../../AppStore';
import ServiceBase from './ServiceBase';

class AuthRefresh {
  api = new ServiceBase();

  isActive = true;

  timeout = 270000; // eq 4.5 minutes

  constructor() {
    ['mousemove', 'mousedown', 'touchstart', 'click', 'keypress', 'scroll'].map(e =>
      document.addEventListener(e, () => this.userIsActive(), true),
    );
  }

  userIsActive() {
    this.isActive = true;
  }

  start() {
    // refreshes the access token every 4.5 minutes
    setInterval(() => {
      if (!(appStore.isLoggedIn && this.isActive)) {
        return;
      }

      this.api.refreshCall();
    }, this.timeout);
  }
}

const authRefresh = new AuthRefresh();
authRefresh.start();
