import Basic from '../../../../images/badgesV2/Basic.png';
import Intermediate from '../../../../images/badgesV2/Intermediate.png';
import Advanced from '../../../../images/badgesV2/Advanced.png';
import DLP from '../../../../images/badgesV2/DLP.png';
import IPS_IDS from '../../../../images/badgesV2/IDS IPS Lv 3.png';
import Cloud from '../../../../images/badgesV2/General.png';
import Endpoint from '../../../../images/badgesV2/Endpoints Lv 3.png';
import Email from '../../../../images/badgesV2/Email.png';
import NGFW from '../../../../images/badgesV2/Firewall.png';
import NorthKorea from '../../../../images/badgesV2/Crouching Tiger.png';
import China from '../../../../images/badgesV2/Panadamonium.png';
import Russia from '../../../../images/badgesV2/Bear Essentials.png';
import Iran from '../../../../images/badgesV2/Snake Bite.png';
import Persistence from '../../../../images/badgesV2/MITRE Persistence.png';
import DefenseEvasion from '../../../../images/badgesV2/MITRE Evasion.png';
import PrivilegeEscalation from '../../../../images/badgesV2/MITRE Priv Escalation.png';
import Discovery from '../../../../images/badgesV2/MITRE Discovery.png';
import CredentialAccess from '../../../../images/badgesV2/MITRE Cred Access.png';
import Execution from '../../../../images/badgesV2/MITRE Execution.png';
import LateralMovement from '../../../../images/badgesV2/MITRE Lateral.png';
import Collection from '../../../../images/badgesV2/SIEM Lv 1.png';
import Exfiltration from '../../../../images/badgesV2/MITRE Exfiltration.png';
import CommandControl from '../../../../images/badgesV2/MITRE C&C.png';
import Impact from '../../../../images/badgesV2/ice breaker.png';
import InitialAccess from '../../../../images/badgesV2/MITRE Access.png';

export default function getBadge(badgeName: string) {
  return {
    Basic,
    Intermediate,
    Advanced,
    DLP,
    'IPS/IDS': IPS_IDS,
    Cloud,
    Endpoint,
    NGFW,
    Email,
    'North Korea': NorthKorea,
    China,
    Russia,
    Iran,
    Persistence,
    'Defense Evasion': DefenseEvasion,
    'Privilege Escalation': PrivilegeEscalation,
    Discovery,
    'Credential Access': CredentialAccess,
    Execution,
    'Lateral Movement': LateralMovement,
    Collection,
    Exfiltration,
    'Command And Control': CommandControl,
    Impact,
    'Initial Access': InitialAccess,
    'Three In a Row': Impact,
  }[badgeName];
}
