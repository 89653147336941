import { makeStyles } from '@mui/styles';
import CircularProgress from './CircularProgress';
import LargeNumberMetric from './LargeNumericMetric';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  circularProgress: {
    marginRight: 26,
  },
}));

interface PieGraphMetricProps {
  percent: number;
  label: string;
  labelSecondRow?: string;
}

export default function PieGraphMetric({ percent, label, labelSecondRow = '' }: PieGraphMetricProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <CircularProgress className={classes.circularProgress} size={88} percent={percent} />
      <LargeNumberMetric value={percent} unit="%" label={label} labelSecondRow={labelSecondRow} />
    </div>
  );
}
