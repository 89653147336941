import clsx from 'clsx';
import React, { useState } from 'react';
import Chart from 'react-apexcharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { round } from '@sightgain/core/calculations';
import { useAbort } from '../../../../../effects';
import avatar from '../../../../images/adversaries.png';
import IconBadge from '../../../../reusables/Badges/IconBadge';
import ProgressColumns from '../../../../reusables/metrics/ProgressColumns';
import { CardUserInfo, NumberBadge, StatusBadge } from '../../../../reusables/scl';
import { curriculumService } from '../../../../services';
import { LiveFireTrainingStatus, Student, User } from '../../../../services/interfaces';
import CompositeScoringModal from './CompositeScoringModal';

// Determine correct color based on score value
function scoreColor(value: number): string {
  const colors = ['red', 'orange', 'yellow', 'green'];
  // returns a number between 0 and 3 based on quarters of 100
  const index = Math.min(Math.ceil((+value + 1) / 25) - 1, 3);

  return colors[index];
}

// return ProgressColumns component when evaluation is running, else use the NumberBadge
function ProgressColumnOrBadge({ value, running, className }: { value: number; running: boolean; className: string }) {
  if (running) {
    return <ProgressColumns percentageFilled={value} />;
  }
  return (
    <NumberBadge tag className={className} color={scoreColor(value)}>
      {round(value, 0)}
    </NumberBadge>
  );
}

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 6,
    border: 'solid 1px rgba(255, 255, 255, 0.26)',
    marginRight: 12,
    width: '100%',
    '& > div': {
      padding: '25px 20px 20px 20px',
    },
  },
  middle: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    gap: 30,
    borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
  },
  right: {
    display: 'flex',
    flex: 2,
    flexDirection: 'row',
    borderLeft: '1px solid rgba(255, 255, 255, 0.1)',
    padding: '25px 20px 20px 20px',
  },
  ids: {
    display: 'flex',
    marginTop: 10,
    '& > *': {
      flex: 1,
    },
    '& span': {
      color: '#a7adb5',
    },
  },
  statWrapper: {
    paddingBottom: 18,
  },
  statRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      flex: 1,
    },
    '&:first-child': {
      borderBottom: '1px solid rgba(255, 255, 255, 0.1)',
      paddingBottom: 16,
    },
    '&:last-child': {
      paddingTop: 16,
    },
  },
  paddingFix: {
    paddingLeft: 32,
    marginRight: -32,
  },
  statusCard: {
    paddingLeft: 20,
    backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0.26), transparent)',
  },
  statusBar: {
    width: 16,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    backgroundImage: 'linear-gradient(to bottom, #21bee3, #00ffb1)',
  },
  blueBackground: {
    backgroundImage: 'linear-gradient(to bottom, #117fba, transparent)',
  },
  blueStatusBar: {
    backgroundcolor: '#117fba',
  },
  yellowBackground: {
    backgroundImage: 'linear-gradient(to bottom, #feba06, transparent)',
  },
  yellowStatusBar: {
    backgroundcolor: '#feba06',
  },
  redBackground: {
    backgroundImage: 'linear-gradient(to bottom, #f85c5c, transparent)',
  },
  redStatusBar: {
    backgroundcolor: '#f85c5c',
  },

  flex: {
    display: 'flex',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  alignCenter: {
    justifyContent: 'center',
  },
  center: {
    alignItems: 'center',
  },
  individualScoreContainer: {
    width: '45%',
  },
  individualScoreItem: {
    display: 'flex',
    height: '34%',
    '& > div:first-child': {
      background: '#313F53',
      padding: '10px',
      marginBottom: '5px',
      width: '80%',
    },
  },
  individualScoreNumber: {
    fontSize: '14px',
  },
  individualScoreLabel: {
    fontSize: '14px',
    paddingTop: '5px',
  },
  individualScoreConnectorLine: {
    width: '20%',
    borderBottom: '1px dotted white',
    height: '50%',
  },
  compositeScoreContainer: {
    background: '#313F53',
    height: '100%',
    padding: '10px 10px 20px 10px',
    width: '55%',
  },
  infoIcon: {
    padding: '10px',
    height: '15px',
    width: '15px',
    cursor: 'pointer',
    zIndex: 99,
    position: 'relative',
  },
  scoreInfoIcon: {
    display: 'inline',
    marginLeft: '12px',
    cursor: 'help',
  },
  radialChartContainer: {
    width: '100%',
    height: '150px',
    marginTop: '-40px',
  },
}));

const radialBarChartOptions = {
  chart: {
    type: 'radialBar',
  },
  plotOptions: {
    radialBar: {
      startAngle: -90,
      endAngle: 90,
      hollow: {
        margin: 15,
        size: '80%',
      },
      track: {
        background: 'rgba(0, 0, 0, 0.18)',
        strokeWidth: '28px',
      },
      dataLabels: {
        name: {
          show: false,
        },
        value: {
          offsetY: -10,
          fontSize: 28,
          fontFamily: 'Lexend',
          color: '#ffffff',
          formatter(val: number) {
            return `${val}/100`;
          },
        },
      },
    },
  },
  fill: {
    type: 'solid',
    opacity: 1,
    colors: ['#00ffb1'],
  },
  stroke: {
    lineCap: 'round',
  },
};

function Stats({
  type,
  name,
  description,
  trainingId,
  evaluationId,
  status,
  composite = 0,
  event = 0,
  precision = 0,
  timeliness = 0,
  analyst: analystUser,
  jobVendorId,
}: StatsProps) {
  const classes = useStyles();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [analyst, setAnalyst] = useState<Student>();

  // get analyst stats
  useAbort(
    () => (analystUser ? curriculumService.student(analystUser.email) : Promise.resolve(undefined)),
    analystResponse => {
      setAnalyst(analystResponse);
    },
    [],
  );

  const individualScores = [
    {
      label: 'Event score',
      value: event,
      info: 'Calculated by the ratio of events captured by the analyst to those missed.',
    },
    {
      label: 'Precision score',
      value: precision,
      info: 'Determined by the number of relevant analyst input items compared to mistaken input items.',
    },
    {
      label: 'Timeliness score',
      value: timeliness,
      info: 'Assesses the speed at which the analyst records observations once the evaluation has commenced.',
    },
  ];

  return (
    <div className={classes.wrapper}>
      <span
        className={clsx(classes.statusBar, {
          [classes.blueBackground]: status === 'running',
          [classes.yellowBackground]: status === 'cancelled' || status === 'queued',
          [classes.redBackground]: status === 'red',
        })}
      />
      <div
        style={{ flex: 2 }}
        className={clsx(classes.statusCard, {
          [classes.blueStatusBar]: status === 'running',
          [classes.yellowStatusBar]: status === 'cancelled' || status === 'queued',
          [classes.redStatusBar]: status === 'red',
        })}
      >
        <StatusBadge status={status as LiveFireTrainingStatus} />
        <Typography variant="h2" style={{ marginTop: 10 }}>
          {name}
        </Typography>
        <Typography variant="body1" style={{ marginTop: 10 }}>
          {description}
        </Typography>
        <Divider style={{ marginTop: 12 }} />
        <div className={classes.ids}>
          <Typography variant="body1">
            Job Vendor ID &nbsp; <span>{jobVendorId}</span>
          </Typography>
          <Typography variant="body1">
            Evaluation ID &nbsp; <span>{evaluationId}</span>
          </Typography>
        </div>
      </div>
      {analyst && (
        <>
          <div className={classes.middle}>
            <CardUserInfo
              key={analystUser?.email ?? ''}
              image={avatar}
              bottomRow={analystUser?.email ?? ''}
              topRow="Analyst"
            />
            <Divider style={{ marginTop: 12 }} />
            <div className={clsx([classes.flex, classes.spaceAround])}>
              <IconBadge icon={['fal', 'medal']}>{`${analyst.achievements}`}</IconBadge>
              <IconBadge icon={['fal', 'th-list']}>{`${analyst.curriculums}`}</IconBadge>
              <IconBadge icon={['fal', 'users-class']}>{`${analyst.courses}`}</IconBadge>
            </div>
          </div>

          <div className={classes.right}>
            <div className={classes.individualScoreContainer}>
              {individualScores.map(({ label, value, info }) => (
                <div key={`individual-score-${label}`} className={clsx([classes.individualScoreItem])}>
                  <div>
                    <ProgressColumnOrBadge
                      value={value}
                      running={status === 'running'}
                      className={classes.individualScoreNumber}
                    />

                    <div className={classes.individualScoreLabel}>
                      {label}
                      <Tooltip title={info} placement="left">
                        <div className={classes.scoreInfoIcon}>
                          <FontAwesomeIcon icon={['fal', 'info-circle']} />
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <div className={classes.individualScoreConnectorLine} />
                </div>
              ))}
            </div>

            <div className={classes.compositeScoreContainer}>
              <div style={{ textAlign: 'right' }}>
                <FontAwesomeIcon
                  className={classes.infoIcon}
                  icon={['fal', 'info-circle']}
                  onClick={() => setModalOpen(true)}
                />
              </div>
              <div className={classes.radialChartContainer}>
                <Chart type="radialBar" series={[composite]} options={radialBarChartOptions} height="200%" />
              </div>
              <div className={clsx([classes.flex, classes.alignCenter])}>
                <Typography style={{ fontSize: '16px' }} variant="body1">
                  Composite score
                </Typography>
              </div>
            </div>
          </div>
        </>
      )}
      <CompositeScoringModal open={modalOpen} onClose={() => setModalOpen(false)} />
    </div>
  );
}

interface StatsProps {
  name: string;
  description: string;
  evaluationId: string;
  trainingId?: string;
  type: string;
  status: string;
  composite?: number;
  event?: number;
  precision?: number;
  timeliness?: number;
  analyst?: User;
  jobVendorId: string;
}

export default React.memo(Stats);
