import { Typography } from '@mui/material';
import CircularProgress from '../../../reusables/metrics/CircularProgress';

interface ProgressProps {
  completed: number;
  value: number;
  total: number;
  color?: string;
}

export default function Progress({ completed, value, total, color = '#fff' }: ProgressProps) {
  const percent = completed / total * 100;
  return (
    <div style={{ position: 'relative', width: 22 }}>
      <div style={{ position: 'absolute' }}>
        <CircularProgress percent={percent} size={24} strokeWidth={2} />
      </div>
      <div
        style={{
          position: 'absolute', width: '100%', paddingTop: 3.5, paddingLeft: 1
        }}>
        <Typography variant="body2" style={{ textAlign: 'center', fontSize: 10, color }}>{value}</Typography>
      </div>
    </div>
  );
}
