import { useState, ChangeEvent } from 'react';

export default function useInput(initialValue?: string | number) {
  const [value, setValue] = useState((typeof initialValue === 'number' ? String(initialValue) : initialValue) || '');

  return {
    value,
    setValue,
    reset: () => setValue(''),
    bind: {
      value,
      onChange: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setValue(event.target.value);
      },
    },
  };
}
