import {
  Typography,
  Modal,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import PreparingStatus from './PreparingStatus';
import QueuedStatus from './QueuedStatus';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TrainingInputSource } from '../interfaces';
import SettingsService from '../../../../services/SettingsService';
import appStore from '../../../../../AppStore';
import { LiveFireTrainingSource, LiveFireTrainingStatus } from '../../../../services/interfaces';
import { currentStage, StageStatus } from './currentStage';
import useJobQueue from '../../../../reusables/useJobQueue';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    border: 'solid 1px #5d6e80',
    backgroundColor: '#0b0c12',
    padding: 85,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title: {
    marginBottom: 24,
  },
  btnWrapper: {
    marginTop: 20,
  }
}));

const openTicketService = async () => {
  try {
    // get ticketing url from settings
    const { value } = await SettingsService.find('ticketing_integration_url');
    if (!value) {
      throw new Error('Ticket service URL is not set in settings');
    }
    // open ticketing window
    window.open(value, '_blank');
  } catch (err) {
    appStore.error(err);
  }
};

function PreparingLivefireTraining({ name, source, testStatuses, createdAt, isExam }: PrepareProps) {
  const classes = useStyles();
  const current = currentStage(testStatuses);
  const ticketingSource = source === TrainingInputSource.TICKET;
  const numTrainings = useJobQueue({ date: createdAt });

  return (
    <Modal
      open={ticketingSource || (current !== StageStatus.READY)}
      className={classes.modal}
    >
      <div className={classes.wrapper}>
        <Typography className={classes.title} variant="h1">
          {name}
        </Typography>
        {(!!numTrainings && numTrainings > 0) && <QueuedStatus trainings={numTrainings} />}
        {(numTrainings === 0) && <PreparingStatus current={current} isExam={isExam} />}
        {ticketingSource && (
          <>
            <Typography>
              Please enter your observations in your ticketing system.
            </Typography>
            <div className={classes.btnWrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={openTicketService}
                endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
              >
                Ticketing System
              </Button>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

type PrepareProps = {
  name: string;
  source: LiveFireTrainingSource,
  testStatuses: LiveFireTrainingStatus[]
  createdAt: Date;
  isExam: boolean;
}

export default PreparingLivefireTraining;
