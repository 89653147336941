import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import ScrollableList from '../../../reusables/ScrollableList';
import { resolveCurriculumImageForDemo } from '../utils';
import { TrainingGroupDetails } from '../../../services/interfaces';
import { Card, CardBackground, CardContent, CardDetails } from '../components/Card';

const useStyles = makeStyles(() => ({
  curriculumsWrapper: {
    width: 'calc(100% - 12px)',
  },
  // TODO: consider moving this changes to Mui-theme for typography h6
  curriculumsTitle: {
    color: '#ffffff',
    textTransform: 'uppercase',
    paddingBottom: 10,
  },
  curriculumCard: {
    width: 453,
    margin: '0 13px 0 0',
  },
}));

type AssignedCurriculum = Pick<TrainingGroupDetails, 'id' | 'status' | 'title'>;

export default function AssignedCurriculums({ curriculums }: { curriculums: AssignedCurriculum[] }) {
  const classes = useStyles();
  return (
    <div className={classes.curriculumsWrapper}>
      <Typography variant="h6" className={classes.curriculumsTitle}>
        {`Assigned Curriculums (${curriculums.length})`}
      </Typography>
      <ScrollableList>
        {curriculums.map(curriculum => (
          <Card className={classes.curriculumCard} key={curriculum.id}>
            <CardBackground image={resolveCurriculumImageForDemo(curriculum.title)}>
              <CardContent>
                <CardDetails title={curriculum.title} />
              </CardContent>
            </CardBackground>
          </Card>
        ))}
      </ScrollableList>
    </div>
  );
}
