import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Divider, Grid } from '@mui/material';
import { curriculumService } from '../../../services';
import { useAbort } from '../../../../effects';
import { CurriculumHeader, Tabs, ScrollableList } from '../../../reusables/scl';
import appStore from '../../../../AppStore';
import Header from './Header';
import Metadata from './Metadata';
import { durationText, resolveCurriculumImageForDemo } from '../utils';
import LivefireModal from './LivefireModal';
import { TrainingGroupDetails, TrainingGroupLevel, TrainingGroupLivefireContent } from '../../../services/interfaces';
import { Card, CardBadge, CardButton, CardContent, CardDetails, CardLink, CardProgress } from '../components/Card';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  tabsWrapper: {
    marginTop: 20,
    marginBottom: 20,
  },
  liveFire: {
    height: 150,
  },
}));

export default function Selected() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [curriculumDetails, setCurriculumDetails] = useState<TrainingGroupDetails>();
  const [livefireContents, setLivefireContents] = useState([] as TrainingGroupLivefireContent[]);
  const [selectedContent, setSelectedContent] = useState<TrainingGroupLivefireContent>();

  useAbort(
    () =>
      Promise.all([
        curriculumService.curriculumDetails(id, appStore.userId),
        curriculumService.trainingGroupLivefireContents(id),
      ]),
    ([result, resultLivefire]) => {
      setCurriculumDetails(result);
      setLivefireContents(resultLivefire);
    },
  );

  if (!(curriculumDetails && livefireContents)) {
    return <></>;
  }

  const handleClose = async () => {
    setSelectedContent(undefined);
  };

  const handleStart = async () => {
    try {
      await curriculumService.logActivity(appStore.userId, selectedContent?.id as string, [curriculumDetails.id]);
    } catch (err) {
      appStore.error(err);
    }
  };

  const labels: string[] = [];

  if (curriculumDetails.courses.length > 0) {
    labels.push('Courses');
  }

  if (livefireContents.length > 0) {
    labels.push('Live Fire Training');
  }

  labels.push(...curriculumDetails.metadata.map(t => t.title));
  const metadata = curriculumDetails.metadata.find(x => x.title === labels[selectedTab]);

  return (
    <div className={classes.wrapper}>
      <Header
        backUrl="/curriculum/learning"
        backText="Back to Curriculums"
        progressText="Curriculum Progress"
        progress={curriculumDetails.progress ?? 0}
      />
      <LivefireModal open={!!selectedContent} onClose={handleClose} onStart={handleStart} content={selectedContent} />

      {curriculumDetails.title && (
        <div>
          <CurriculumHeader
            image={resolveCurriculumImageForDemo(curriculumDetails.title)}
            onBookmark={() => {}}
            title={curriculumDetails.title}
            description={curriculumDetails.description}
            level={curriculumDetails.difficulty}
            courses={curriculumDetails.courses.length}
            durationDays={curriculumDetails.durationDays}
            trainings={livefireContents.length}
          />
        </div>
      )}
      <Tabs className={classes.tabsWrapper} selected={selectedTab} onSelect={setSelectedTab} labels={labels} />
      {selectedTab === labels.indexOf('Courses') && (
        <ScrollableList direction="y">
          <Grid container spacing={2}>
            {curriculumDetails.courses.map(course => (
              <Grid key={course.id} item xs={4}>
                <CardLink to={`/curriculum/learning/${id}/course/${course.id}`}>
                  <Card>
                    <CardContent variant="medium">
                      <CardDetails level={course.difficulty} title={course.title} />
                    </CardContent>
                    <Divider />
                    <CardContent variant="medium" row>
                      <CardBadge icon={['fal', 'clock']} text={durationText(course.durationMinutes)} />
                      <CardProgress progress={course.progress ?? 0} />
                    </CardContent>
                  </Card>
                </CardLink>
              </Grid>
            ))}
          </Grid>
        </ScrollableList>
      )}
      {selectedTab === labels.indexOf('Live Fire Training') && (
        <ScrollableList direction="y">
          <Grid container spacing={2}>
            {livefireContents.map(content => (
              <Grid key={content.id} item xs={4}>
                {/** Passing an empty vendor string which make search use ID only. TODO: make system handle this better */}
                <CardButton onClick={() => setSelectedContent(content)}>
                  <Card>
                    <CardContent className={classes.liveFire} variant="medium">
                      <CardDetails level={TrainingGroupLevel.LiveFire} title={content.title} />
                    </CardContent>
                  </Card>
                </CardButton>
              </Grid>
            ))}
          </Grid>
        </ScrollableList>
      )}
      {metadata && <Metadata metadata={metadata} />}
    </div>
  );
}
