import { endOfDay, formatRFC3339, startOfDay } from 'date-fns';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import appStore from '../../../../AppStore';
import { useAbort } from '../../../../effects';
import { socService, systemUsersService } from '../../../services';
import AlertSummary from './AlertSummary';
import EnableSiem from './EnableSiem';
import { SocCache, SocCacheItem } from './interfaces';
import ResponseActions from './ResponseActions';
import ResponseSummary from './ResponseSummary';
import SocContext from './SocContext';
import TestsSummary from './TestsSummary';

const useStyles = makeStyles(() => ({
  socPage: {
    display: 'flex',
    flexDirection: 'column',
  },
  socRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 33,
    flexWrap: 'wrap',
    gap: 24,
    '& > *': {
      flex: 1,
    },
  },
  secondRow: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 33,
    flexWrap: 'wrap',
    gap: 24,
    '& > *': {
      flex: 1,
    },
  },
  col1: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  whatNow: {
    marginTop: 28,
    paddingTop: 25,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
    borderRadius: 6,
    overflow: 'hidden',
  },
  whatNowText: {
    paddingLeft: 25,
    paddingRight: 25,
  },
  whatNowTitle: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  callToAction: {
    marginTop: 20,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

function cacheKey(fromDate: Date, toDate: Date) {
  return `${formatRFC3339(fromDate)}${formatRFC3339(toDate)}`;
}

export default function SOC() {
  const classes = useStyles();

  const [cache, updateCache] = useState({} as SocCache);
  const [timeSpan, setTimeSpan] = useState(30);
  const [siemEnabled, setSiemEnabled] = useState<boolean>();

  useAbort(
    () => systemUsersService.enabled('siem'),
    (result: boolean) => {
      setSiemEnabled(result);
    },
  );

  const loadDataForTimeframe = async (fromDate: Date, toDate: Date) => {
    const from = startOfDay(fromDate);
    const to = endOfDay(toDate);

    const key = cacheKey(from, to);
    if (cache[key]) {
      return cache[key];
    }

    let payload = {} as SocCacheItem;

    appStore.beginLoading();
    try {
      const { dailyJobMetrics, dailyExerciseMetrics } = await socService.getDates(from, to);
      payload = { dailyJobMetrics, dailyExerciseMetrics };

      updateCache({
        ...cache,
        [key]: payload,
      });
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
    return payload;
  };

  const state = {
    timeSpan,
    setTimeSpan,
    loadDataForTimeframe,
  };

  return (
    <div className={classes.socPage}>
      <Typography variant="h2" style={{ marginBottom: 20 }}>
        Your SOC Overview
      </Typography>
      <SocContext.Provider value={state}>
        <div className={classes.socRow}>
          <ResponseSummary />
          {siemEnabled !== undefined && siemEnabled && <AlertSummary />}
          {siemEnabled !== undefined && !siemEnabled && <EnableSiem />}
        </div>

        <div className={classes.secondRow}>
          <div className={classes.col1}>
            <TestsSummary />
            <div className={classes.whatNow}>
              <Typography className={classes.whatNowText} variant="body2">
                <span className={classes.whatNowTitle}>What Now?</span>
                <br />
                <span>
                  Let us test your systems by launching a live fire exercise. Click the button below to get started.
                </span>
              </Typography>

              <Button
                className={classes.callToAction}
                variant="contained"
                color="secondary"
                endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
                component={Link}
                to="/curriculum/evaluations/run-evaluation"
              >
                Evaluation Launcher
              </Button>
            </div>
          </div>
          <ResponseActions />
        </div>
      </SocContext.Provider>
    </div>
  );
}
