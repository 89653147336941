import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Menu, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { BulkUserAction, BulkUserCallback } from './interfaces';

const useStyles = makeStyles(() => ({
  btn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 160,
  },
  header: {
    marginBottom: 20,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
  },
  headerLeft: {
    display: 'flex',
    flex: 1,
    maxWidth: '800px',
  },
}));

export default function Header({ bulkEnabled = false, onBulkClick, onNewUserClick, onNewGroupClick }: HeaderProps) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement>();

  const handleBulkAction = (action: BulkUserAction) => {
    setAnchorEl(undefined);
    onBulkClick(action);
  };

  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        <Typography variant="h3">Account Management</Typography>
      </div>
      <Button
        className={classes.btn}
        aria-label="Add a User"
        variant="contained"
        color="primary"
        onClick={onNewUserClick}
      >
        Add a User
        <FontAwesomeIcon icon={['fal', 'user-plus']} />
      </Button>
      <Button
        className={classes.btn}
        aria-label='Add a Group'
        variant="contained"
        color="primary"
        onClick={onNewGroupClick}
      >
        Add a Group
        <FontAwesomeIcon icon={['fal', 'users']} />
      </Button>
      <div>
        <Button
          className={classes.btn}
          aria-controls="bulk-actions-menu"
          aria-haspopup="true"
          aria-label="Bulk Actions"
          variant="contained"
          color="primary"
          disabled={!bulkEnabled}
          onClick={e => setAnchorEl(e.currentTarget)}>
            Bulk Actions
          <FontAwesomeIcon icon={['fal', 'arrow-down']} />
        </Button>
        <Menu
          id="bulk-actions-menu"
          anchorEl={anchorEl}
          keepMounted
          open={!!anchorEl}
          onClose={() => setAnchorEl(undefined)}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          <MenuItem onClick={() => handleBulkAction(BulkUserAction.ENABLE)}>Enable</MenuItem>
          <MenuItem onClick={() => handleBulkAction(BulkUserAction.DISABLE)}>Disable</MenuItem>
        </Menu>
      </div>
    </div>
  );
}

type HeaderProps = {
  bulkEnabled?: boolean;
  onBulkClick: BulkUserCallback;
  onNewUserClick: () => unknown;
  onNewGroupClick: () => unknown;
}