import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useAbort } from '../../../../effects';
import useSocketListener from '../../../reusables/useSocketListener';
import { assessmentsService, userPreferencesService } from '../../../services';
import { Assessment, FrameworkWithScores } from '../../../services/interfaces';
import Introduction from './Introduction';
import Setup from './Setup';

interface AssessmentContextInitialState {
  assessment?: Assessment<FrameworkWithScores>;
  assessments: Assessment<FrameworkWithScores>[];
  isReadonly?: boolean;
  editAssessment: Partial<Assessment<FrameworkWithScores>>;
  setEditAssessment: Dispatch<SetStateAction<Partial<Assessment<FrameworkWithScores>>>>;
  setAssessmentId: (id: string) => unknown;
  assessmentFrameworkId?: string;
  setAssessmentFrameworkId: Dispatch<SetStateAction<string | undefined>>;
  currentOverallMaturityLevel: string;
  setCurrentOverallMaturityLevel: Dispatch<SetStateAction<string>>;
}

export const AssessmentContext = createContext({} as AssessmentContextInitialState);

export const useAssessmentContext = () =>
  useContext<AssessmentContextInitialState>(AssessmentContext) as Required<AssessmentContextInitialState>;

export function AssessmentProvider({ children }: { children: ReactNode }) {
  const [editAssessment, setEditAssessment] = useState<Partial<Assessment<FrameworkWithScores>>>({});
  const [assessmentId, setAssessmentId] = useState<string>();
  const [showWelcome, setShowWelcome] = useState<boolean | undefined>();
  const [assessmentFrameworkId, setAssessmentFrameworkId] = useState<string | undefined>();
  const [currentOverallMaturityLevel, setCurrentOverallMaturityLevel] = useState<string>('all');

  // download the list of assessments and keep them updated
  const assessmentsCb = useCallback(async () => {
    const downloaded = await assessmentsService.list({ pagination: { limit: 1000 } }, 'network-only');

    // we only do this when we don't have an assessment id
    if (!assessmentId && downloaded.length) {
      const pref = await userPreferencesService.find('defaultAssessment');
      setAssessmentId(pref.value ?? downloaded[0].id);
    }

    return downloaded;
  }, [assessmentId]);
  // list of available assessments
  const assessments = useSocketListener('assessment', 'update', assessmentsCb);

  const assessmentCb = useCallback(
    async (body?: { assessmentId?: string }) => {
      if (assessmentId && (!body || body.assessmentId === assessmentId)) {
        const downloaded = await assessmentsService.find(assessmentId, 'network-only');
        setAssessmentFrameworkId(downloaded.frameworks[0].id);
        return downloaded;
      }
    },
    [assessmentId],
  );
  // selected assessment
  const assessment = useSocketListener('assessment', 'update', assessmentCb);

  useAbort(
    () => userPreferencesService.find('ShowAssessmentWelcome'),
    result => {
      if (result.value) {
        setShowWelcome(JSON.parse(result.value));
      } else {
        userPreferencesService.set('ShowAssessmentWelcome', 'true');
        setShowWelcome(true);
      }
    },
    [],
  );

  // updates the user preference when the assessment is changed
  useEffect(() => {
    if (!assessment?.id) {
      return;
    }

    userPreferencesService.set('defaultAssessment', assessment.id);
  }, [assessment?.id]);

  const handleOnClick = useCallback(async () => {
    await userPreferencesService.set('ShowAssessmentWelcome', 'false');
    setShowWelcome(false);
  }, []);

  /**
   * `showWelcome === undefined` welcome preference hasn't been retrieved yet
   * `!assessments` assessment list has not been retrieved yet
   * `assessments.length && !assessment` assessment has not been retrieved yet
   */
  if (showWelcome === undefined || !assessments || (assessments.length && !assessment)) {
    return <>{showWelcome}</>;
  }

  if (showWelcome) {
    return <Introduction onClick={handleOnClick} />;
  }

  // expired and finished assessments cannot be edited
  const now = new Date();
  const isReadonly = assessment && (assessment.endDate <= now || assessment.license.expires <= now);

  return (
    <AssessmentContext.Provider
      value={{
        assessment,
        assessments,
        isReadonly,
        editAssessment,
        setAssessmentId,
        setEditAssessment,
        assessmentFrameworkId,
        setAssessmentFrameworkId,
        currentOverallMaturityLevel,
        setCurrentOverallMaturityLevel,
      }}
    >
      {!assessment?.id && <Setup onExit={() => undefined} />}
      {assessment?.id && children}
    </AssessmentContext.Provider>
  );
}
