import { FormEvent, useState } from 'react';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { clone } from '@sightgain/core';
import Modal from '../../../../reusables/Modal';
import SearchBar from '../../../../reusables/SearchBar';
import { FrameworkItem } from '../../../../services/interfaces';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    minWidth: 500,
  },
  title: {
    padding: '0px 0px 28px 0px',
  },
  items: {
    height: 300,
    overflowY: 'auto',
    width: 400,
  },
});

interface SubTechniqueModalProps {
  open?: boolean;
  onClose: () => void;
  onUpdate: (items: FrameworkItem[]) => void;
  items: FrameworkItem[];
  itemsDraft: FrameworkItem[];
}

export default function SubTechniqueModal({ open, onClose, onUpdate, items, itemsDraft }: SubTechniqueModalProps) {
  const classes = useStyles();
  const [techniqueSearch, setTechniqueSearch] = useState<string>('');
  const [state, setState] = useState<FrameworkItem[]>(itemsDraft.map(x => clone(x)));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    let newState: FrameworkItem[] = [];

    if (checked) {
      const item = items.find(x => x.identifier === name);
      newState = [...state];
      if (item) {
        newState.push(item);
      }
    } else {
      newState = state.filter(x => x.identifier !== name);
    }

    setState(clone(newState));
  };

  const handleUpdate = async (event: FormEvent) => {
    event.preventDefault();
    onUpdate(state);
  };

  const filteredItems = items.filter(x => x.identifier.includes(techniqueSearch));

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={handleUpdate}
      actions={[
        { text: 'Update', color: 'primary', submit: true },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <div className={classes.container}>
        <div className={classes.title}>
          <Typography variant="h5">Select Sub-techniques</Typography>
        </div>
        <div>
          <SearchBar placeholder="Search Sub-techniques" onChange={setTechniqueSearch} />
        </div>
        <div className={classes.items}>
          <FormGroup>
            {filteredItems.map(item => (
              <FormControlLabel
                key={item.identifier}
                control={
                  <Checkbox
                    checked={!!state.find(x => x.identifier === item.identifier)}
                    onChange={handleChange}
                    name={item.identifier}
                  />
                }
                label={item.identifier}
              />
            ))}
          </FormGroup>
        </div>
      </div>
    </Modal>
  );
}
