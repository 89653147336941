import Accounts from './AccountManagement';
import Logs from './Logs';
import ExploitTesting from './ExploitTesting';
import CustomCalculations from './CustomCalculations';
import Content from './Content';
import SipSettings from './SipSettings';
import Ticketing from './Ticketing';
import Siem from './Siem';
import ExportData from './Export/ExportData';
import MiscSettings from './MiscSettings';
import Licensing from './Licensing/Licensing';

export interface SettingsRoutes {
  [key: string]: (() => JSX.Element) | React.MemoExoticComponent<() => JSX.Element>
}

export const SETTINGS_ROUTES: SettingsRoutes = {
  'accounts': Accounts,
  'security-instrumentation': SipSettings,
  'logs': Logs,
  'licensing': Licensing
};

export const LIMITED_SETTINGS_ROUTES: SettingsRoutes = {
  ...SETTINGS_ROUTES,
  'content-management': Content,
};

export const FULL_SETTINGS_ROUTES: SettingsRoutes = {
  ...LIMITED_SETTINGS_ROUTES,
  'custom-calculations': CustomCalculations,
  'atomic-red-team': ExploitTesting,
  'siem': Siem,
  'ticketing-system': Ticketing,
  'miscellaneous': MiscSettings,
  'export': ExportData,
};
