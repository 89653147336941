import { useEffect } from 'react';
import { socket } from '../services';
import apolloClient from '../services/apollo';

/**
 * Listens for cache delete notification and clears the apollo cache
 */
export default function useCacheRefresh() {
  useEffect(() => {
    const unsubscribe = socket.subscribe(() => {
      console.log('Clearing apollo client cache');
      apolloClient.clearStore();
    }, { type: 'cache', action: 'delete' });

    return () => {
      unsubscribe();
    };
  });
}