import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { ApplyContentInput, ContentInput, ContentPack } from './interfaces';
import ServiceBase from './ServiceBase';

const typeContentPack = `
  {
    appliedAt
    description
    fileName
    id   
    uploadedAt    
  }
`;

class ContentUploadService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['uploadedAt', 'appliedAt']));
  }

  async addContent(input: ContentInput, fields = typeContentPack): Promise<ContentPack[]> {
    const query = gql`
    mutation AddContent($input: ContentInput!) {
      addContent(input: $input) ${fields}
    }
  `;

    const { addContent } = await this.graphql(query, { input });

    return addContent;
  }

  async applyContent(input: ApplyContentInput, fields = typeContentPack): Promise<ContentPack> {
    const query = gql`
    mutation ApplyContent($input: ApplyContentInput!) {
      applyContent(input: $input) ${fields}
    }
  `;
    const { applyContent } = await this.graphql(query, { input });
    return applyContent;
  }

  async getContent(fields = typeContentPack) {
    const query = gql`
      query ContentPacks {
        contentPacks ${fields}
      }
    `;
    const { contentPacks } = await this.graphql(query);
    return contentPacks;
  }
}

const contentUploadService = new ContentUploadService();
export default contentUploadService;
