import { User, UserGroup } from '../../../services/interfaces';

export enum BulkUserAction {
  ENABLE,
  DISABLE,
}

export type BulkUserCallback = (action: BulkUserAction) => Promise<void>;
export type SelectUserCallback = (id: string) => void;
export type SelectUsersCallback = (ids: string[]) => void;
export type SelectGroupCallback = (id: string) => void;

export type EditableUser = Pick<User, 'email' | 'name' | 'roles' | 'group' | 'status'> & {
  id?: string;
}

export type EditableGroup = Pick<UserGroup, 'name' | 'description'> & {
  id?: string;
}

export interface EditingUser {
  id?: string | undefined
  name?: string;
  email?: string;
  status: 'Active' | 'Disabled'
  roles: string[];
  group?: string;
  password: string;
  confirmPassword: string;
}