import React, { useContext, useEffect, useMemo } from 'react';
import { Checkbox, FormControlLabel } from '@mui/material';
import EvaluationWindowContext from '../../EvaluationWindowContext';
import StepTitle from '../StepTitle';
import StepWrapper from '../StepWrapper';
import { getRunOption, INTEGRATIONS_KEY } from './utilities';

export default function IntegrationsSelector() {
  const { testOptions, testOptionInputs, handleUpdateTestOption, handleDisableNextBtn } =
    useContext(EvaluationWindowContext);

  // nothing is required here
  useEffect(() => {
    handleDisableNextBtn(false);
  });

  const checked = useMemo(() => {
    const integration = getRunOption(testOptionInputs, INTEGRATIONS_KEY);

    return JSON.parse(integration?.value ?? '[]') as string[];
  }, [testOptionInputs]);

  const options = useMemo(() => getRunOption(testOptions, INTEGRATIONS_KEY)?.options ?? [], [testOptions]);

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const updated = [...checked];
    if (event.target.checked) {
      updated.push(event.target.value);
    } else {
      updated.splice(updated.findIndex(v => v === event.target.value));
    }

    handleUpdateTestOption(0, {
      key: INTEGRATIONS_KEY,
      value: JSON.stringify(updated),
    });
  };

  if (!(testOptions.length && testOptions[0].length)) {
    return <></>;
  }

  return (
    <StepWrapper>
      <StepTitle>Select integrations for the evaluation</StepTitle>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {options.map(opt => (
          <FormControlLabel
            key={`picus-integration-${opt.value}`}
            label={opt.label}
            control={
              <Checkbox
                name="picus-integration"
                checked={checked.includes(opt.value)}
                value={opt.value}
                onChange={handleOnChange}
              />
            }
          />
        ))}
      </div>
    </StepWrapper>
  );
}

IntegrationsSelector.label = 'Select integrations';
