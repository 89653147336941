import { observer } from 'mobx-react';
import { FormControl, Link as MLink, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import appStore from '../../../AppStore';
import Avatar from '../../reusables/Avatar';
import { Role } from '../../services/interfaces';

const useStyles = makeStyles(() => ({
  role: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
  },
  Avatar: {
    margin: '20px auto',
    '@media (max-height: 700px)': {
      display: 'none',
    },
  },
  Link: {
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 'bold',
    textDecoration: 'underline',
    padding: '15px 0 0',
    '@media (max-height: 700px)': {
      display: 'none',
    },
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 24,
    marginTop: 24,
    '@media (max-height: 700px)': {
      display: 'none',
    },
  },
  name: {
    display: 'flex',
    justifyContent: 'center',
    fontSize: 22,
    '@media (max-height: 700px)': {
      display: 'none',
    },
  },
  select: {
    '&$select > div': {
      background: '#364558',
      border: 0,
    },
  },
  formControl: {
    minWidth: 220,
  },
}));

function User({ open, user }: UserProps) {
  const classes = useStyles();
  return (
    <>
      <div className={classes.flexCenter}>
        <Avatar image={user.image} size={open ? 'm' : 's'} onEdit={() => {}} />
      </div>
      {open && (
        <div>
          <Typography className={classes.name} variant="h5">
            {user.name}
          </Typography>
          <MLink className={classes.Link} href="/">
            {user.email}
          </MLink>
        </div>
      )}
      {appStore.activeRole && open && user.roles.length > 1 && (
        <div className={classes.role}>
          <FormControl className={classes.formControl}>
            <Select
              variant="standard"
              value={appStore.activeRole}
              onChange={e => appStore.setActiveRole(e.target.value as Role)}
              className={classes.select}
            >
              {user.roles.map(r => (
                <MenuItem value={capitalize(r)} key={r}>
                  {capitalize(r)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}
    </>
  );
}

interface UserProps {
  open: boolean;
  user: {
    roles: Role[];
    name: string;
    email: string;
    image: string;
  };
}

export default observer(User);
