import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { isAfter, isBefore } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import appStore from '../../../../../AppStore';
import { useAbort } from '../../../../../effects';
import { ProgressBar } from '../../../../reusables/metrics';
import { curriculumService, examsService } from '../../../../services';
import { CalendarEvent, ExamEvent, TrainingGroupDetails, TrainingGroupPreview } from '../../../../services/interfaces';
import { Card, CardContent, CardDetails, CardLink } from '../../components/Card';

const useStyles = makeStyles({
  selectedEventContent: {
    marginBottom: 40,
    height: 275,
    backgroundColor: 'rgba(255,255,255,0.09)',
    borderRadius: 6,
    padding: 24,
    overflow: 'scroll',
  },
  scheduleNewCourse: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  eventTitle: {
    color: '#ffffff',
    textDecoration: 'underline',
    marginBottom: 18,
  },
  curriculumProgressWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 28,
  },
  curriculumProgressLabel: {
    marginRight: 30,
  },
  curriculumProgressBar: {
    flex: 1,
  },
  inProgressCourseCard: {
    marginBottom: 12,
  },
  courses: {
    height: 200,
    overflowY: 'auto',
  },
  examDescription: {
    marginTop: 12,
    marginBottom: 12,
  }
});

export default function SelectedEvent({ event }: SelectedEventProps) {
  const classes = useStyles();
  const history = useHistory();
  const [trainingGroup, setTrainingGroup] = useState<TrainingGroupDetails>();

  useAbort(async () => event 
    && 'trainingGroup' in event 
    && curriculumService.eventTrainingGroupDetails(event.trainingGroup.id, appStore.userId),
  result => setTrainingGroup(result || undefined),
  [event]);

  const beginDisabled = useCallback(() => {
    if (!(event && 'exam' in event)) {
      return true;
    }

    const today = new Date();
    if (isAfter(event.start, today) || (event.end && isBefore(event.end, today))) {
      return true;
    }

    return false;
  }, [event]);

  const inProgress = useMemo(() => {
    if (!trainingGroup) {
      return;
    }

    const { id, title, progress, type, courses, classes } = trainingGroup;
    return {
      id, title, progress, type, courses, classes,
      items: (type === 'curriculum' ? courses : classes).filter(c => c.status !== 'completed'),
    };
  }, [trainingGroup]);

  const handleBeginExam = async () => {
    appStore.beginLoading();
    try { 
      const lft = await examsService.runScheduled(event?.id as string, appStore.userId);
      history.push(`/curriculum/learning/livefire/${lft.id}`);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const courseLink = useCallback((course: TrainingGroupPreview) => {
    if (!inProgress) {
      return;
    }
    const isCurriculum = inProgress.type === 'curriculum';
    const suffix = isCurriculum ? '' : 'courses/course/';
    const prefix = isCurriculum ? 'course' : 'training';
    return `/curriculum/learning/${suffix}${inProgress.id}/${prefix}/${course.id}`;
  }, [inProgress]);

  if (inProgress) {
    return (
      <div className={classes.selectedEventContent}>
        <Typography className={classes.eventTitle} variant="h4">
          {inProgress.title}
        </Typography>
        <div className={classes.curriculumProgressWrapper}>
          <Typography className={classes.curriculumProgressLabel} variant="body1">
            {inProgress.type === 'course' ? 'Course' : ' Curriculum'}
            {' '}
          Progress
          </Typography>
          <div className={classes.curriculumProgressBar}>
            <ProgressBar value={inProgress?.progress ?? 0} />
          </div>
        </div>
        <div className={classes.courses}>
          {inProgress.items.map(course => (
            <CardLink to={courseLink(course) as string} key={`course-inprogress-${course.id}`}>
              <Card className={classes.inProgressCourseCard}>
                <CardContent variant="dense">
                  <CardDetails level={course.difficulty} title={course.title} />
                </CardContent>
              </Card>
            </CardLink>
          ))}
        </div>
      </div>
    );
  }

  if (event && 'exam' in event) {
    return (
      <div className={classes.selectedEventContent}>
        <Typography className={classes.eventTitle} variant="h4">
          {event.exam.name}
        </Typography>
        {event.start && (
          <Typography>Must start after: {event.start.toString()}</Typography>
        )}
        {event.end && (
          <Typography>Must finish before: {event.end.toString()}</Typography>
        )}
        <Typography className={classes.examDescription}>{event.exam.description}</Typography>
        <div>
          <Button
            disabled={beginDisabled()}
            onClick={handleBeginExam}
            variant="contained"
            color="primary"
            endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
          >
                Begin Exam
          </Button>
        </div>
      </div>
    );
  }

  return (
    <div className={clsx(classes.scheduleNewCourse, classes.selectedEventContent)}>
      <Typography variant="h5">
        Use the Curriculum Calendar to Schedule a course.
      </Typography>
    </div>
  );
}

interface SelectedEventProps {
  event?: CalendarEvent | ExamEvent;
}