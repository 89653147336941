import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Container,
  Divider,
  Grid,
  Typography,
} from '@mui/material';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ToggleButton from '@mui/material/ToggleButton';
import { useAbort } from '../../../../../effects';
import { curriculumService } from '../../../../services';
import {
  ScrollableList,
  SearchBar,
} from '../../../../reusables/scl';
import { durationText, labeledNumber, resolveCurriculumImageForDemo } from '../../utils';
import { TrainingGroupDetails, TrainingGroupPreview } from '../../../../services/interfaces';
import { Card, CardBackground, CardBadge, CardButton, CardContent, CardDetails } from '../Card';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  innerWrapper: {
    marginLeft: -24,
    marginRight: -24,
  },
  header: {
    marginBottom: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  headerLeft: {
    flexGrow: 1,
    display: 'flex',
  },
  headerRight: {
    flex: 2,
    fontWeight: 'bold',
    paddingRight: '8px',
  },
  title: {
    marginRight: '20px',
    marginTop: '-4px',
  },
  card: {
    backgroundColor: 'rgba(255, 255, 255, 0.09)',
  },
  curriculumDetails: {
    justifyContent: 'flex-start',
    gap: 10,
  },
  list: {
    height: 500,
    flex: 1,
  },
}));

export default function SelectItem({ className, setSelectedItem }: SelectItemProps) {
  const classes = useStyles();
  const [curriculums, setCurriculums] = useState<TrainingGroupDetails[]>([]);
  const [viewCourses, setViewCourses] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  useAbort(() => curriculumService.curriculums(), result => {
    setCurriculums(result);
  });

  if (!curriculums) {
    return <></>;
  }

  const searchFilter = (x: TrainingGroupDetails | TrainingGroupPreview) => 
    (x.title.toLowerCase().includes(searchValue.toLowerCase()));

  let filteredItems: TrainingGroupDetails[] | TrainingGroupPreview[] = [];
  if (viewCourses) {
    filteredItems = curriculums.flatMap(c => c.courses).filter(searchFilter);
  } else {
    filteredItems = curriculums.filter(searchFilter);
  }

  return (
    <div className={`classes.wrapper ${className}`}>
      <div className={classes.innerWrapper}>
        <Container>
          <div className={classes.header}>
            <div className={classes.headerLeft}>
              <Typography variant="h2" className={classes.title}>Select an Item</Typography>
              <ToggleButtonGroup
                value={viewCourses}
                exclusive
                onChange={() => setViewCourses(!viewCourses)}
              >
                <ToggleButton value aria-label="list">
                  Courses
                </ToggleButton>
                <ToggleButton value={false} aria-label="list">
                  Curriculums
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className={classes.headerRight}>
              <SearchBar placeholder="Search" onChange={setSearchValue} />
            </div>
          </div>
        </Container>
        <ScrollableList direction="y" className={classes.list}>
          <Container style={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              {filteredItems.map(item => (
                <Grid item xs={6} key={item.id}>
                  <CardButton onClick={() => setSelectedItem(item)}>
                    <Card className={classes.card}>
                      {viewCourses && (
                        <>
                          <CardContent variant='medium'>
                            <CardDetails level={item.difficulty} title={item.title} />
                          </CardContent>
                          <Divider />
                          <CardContent variant="medium">
                            <CardBadge icon={['fal', 'clock']} text={durationText(item.durationMinutes)} />
                          </CardContent>
                        </>
                      )}
                      {!viewCourses && (
                        <>
                          <CardBackground image={resolveCurriculumImageForDemo(item.title)}>
                            <CardContent>
                              <CardDetails level={item.difficulty} title={item.title} />
                            </CardContent>
                          </CardBackground>
                          <Divider />
                          <CardContent className={classes.curriculumDetails} row variant="medium">
                            <CardBadge icon={['fal', 'users-class']} text={`${(item as TrainingGroupDetails).courses.length} Courses`} />
                            <CardBadge icon={['fal', 'clock']} text={labeledNumber(item.durationDays)} />
                          </CardContent>
                        </>
                      )}
                    </Card>
                  </CardButton>
                </Grid>
              ))}
            </Grid>
          </Container>
        </ScrollableList>
      </div>
    </div>
  );
}

type SelectItemProps = {
  className: string;
  setSelectedItem: (item?: TrainingGroupDetails | TrainingGroupPreview) => unknown;
}
