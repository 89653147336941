import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TileDetailCard from './TileDetailCard';
import { Assessor } from '../../../../../services/interfaces';
import DownloadLink from '../../../../../reusables/DownloadLink';
import Thumbnail from '../../../../../reusables/Thumbnail';
import { FileInfo } from '../../../../../services/interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles(() => ({
  title: {
    color: '#8797AB',
    fontSize: 13
  },
  fileLinks: {
    display: 'flex',
    gap: 15,
  }
}));

interface TileAssessorProps {
  assessor: Assessor;
}

export default function TileAssessor({ assessor }: TileAssessorProps) {
  const classes = useStyles();
  const {
    assessor: user, score, value, comments, createdAt, files, tacticId
  } = assessor;

  const downloadFn = (file: FileInfo) => async () => file.contents;

  const header = (
    <div style={{ display: 'flex', gap: 24, marginBottom: 12 }}>
      <div style={{ flex: 1, marginLeft: 5 }}>
        <Typography className={classes.title}>Assessor</Typography>
        <Typography style={{ textTransform: 'uppercase' }}>{user.name}</Typography>
      </div>
      <div style={{ flex: 1 }}>
        <Typography className={classes.title}>Pass / Fail / N/A / Value</Typography>
        <Typography style={{ textTransform: 'uppercase' }}>
          {score === 'value' ? value : score.toUpperCase()}
        </Typography>
      </div>
      <div style={{ flex: 1 }}>
        <Typography className={classes.title}>Entry Date</Typography>
        <Typography>{createdAt.toString()}</Typography>
      </div>
    </div>
  );

  const content = (
    <div>
      <Typography className={classes.title}>Comments</Typography>
      <Typography>{comments}</Typography>
      {!!files?.length && (
        <>
          <br/>
          <Typography className={classes.title}>Files</Typography>
          <div className={classes.fileLinks}>
            {files.map(file => (
              <DownloadLink
                key={`file-${file.id}`}
                fileName={file.fileName}
                mimeType={file.mimeType}
                download={downloadFn(file)}
              >
                <ThumbnailImage file={file} />
              </DownloadLink>
            ))}
          </div>
        </>
      )}
    </div>
  );

  return (
    <TileDetailCard
      id={tacticId}
      header={header}
      content={content}
    />
  );
}

function ThumbnailImage({ file }: { file: FileInfo }) {
  if (/^image/.test(file.mimeType)) {
    return (
      <Thumbnail
        alt={file.fileName}
        height={75}
        width={75}
        src={`data:${file.mimeType};base64,${file.contents}`}
      />
    );
  }

  const icon = MIME_ICONS[file.mimeType] ?? 'file' as IconName;

  return <FontAwesomeIcon icon={['fal', icon]} size="5x" />;
}

const MIME_ICONS: { [key: string]: IconName} = {
  'application/pdf': 'file-pdf',
  'text/cvs': 'file-csv',
  'application/msword': 'file-word',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'file-excel',
  'application/vnd.ms-excel': 'file-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'file-excel',
};