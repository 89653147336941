import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import SwitchProducts from './Components/SwitchProducts';
import DrillDown from './Components/DrillDown';
import { BakeOffProduct } from './interfaces';
import { ProductBakeOffResult } from '../../../services/interfaces';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  helperText: {
    textAlign: 'center',
  },
  drillDown: {
    paddingTop: 35,
  },
}));

export default function ProductDrillDown({ products, bakeOffResults }: ProductDrillDownProps) {
  const classes = useStyles();

  const [selected, onSelect] = useState(0);

  const results = useMemo(() => {
    const selectedProduct = products[selected];
    return bakeOffResults.filter(r => r.productName === selectedProduct.name && r.productType === selectedProduct.type);
  }, [selected, products, bakeOffResults]);

  return (
    <div className={classes.container}>
      <SwitchProducts products={products} selected={selected} onSelect={onSelect} />
      <DrillDown results={results} />
    </div>
  );
}

type ProductDrillDownProps = {
  bakeOffResults: ProductBakeOffResult[];
  products: BakeOffProduct[];
};