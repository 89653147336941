import { useCallback, useMemo } from 'react';
import useSocketListener from '../../../reusables/useSocketListener';
import { liveFireTrainingService } from '../../../services';
import { LiveFireTraining } from '../../../services/interfaces';

export default function useLFTWatcher({ id, fields }: { id: string, fields: string}) {
  const args = useMemo(() => id, [id]);
  
  const callback = useCallback(async (body: string) => {
    if (!(body === 'cron:completed' || id)) {
      return;
    }
    return await liveFireTrainingService.find(id, fields, 'no-cache');
  }, [id, fields]);

  const lft = useSocketListener<LiveFireTraining>('livefiretraining', 'update',callback , args);

  return lft;
}