import { CircularProgress } from '@mui/material';
import { useEffect, useState } from 'react';
import appStore from '../../AppStore';

export default function LoadingContainer<T>({ source, callback, children, style, ...props }: LoadingContainerProps<T>) {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let abort = false;

    (async () => {
      try {
        const results = await source();
        if (!abort) {
          callback(results);
          setIsLoading(false);
        }
      } catch (err) {
        appStore.error(err);
      }
    })();

    return () => { abort = true; };
  }, [source, callback]);

  if (isLoading) {
    return (
      <div {...props} style={{
        ...style, flex: 1,
        display: style?.display === 'none' ? 'none' : 'flex',
        justifyContent: 'center'
      }}>
        <CircularProgress color="inherit" disableShrink />
      </div>
    );
  }

  return (<div style={style} {...props}>{children}</div>);
}

interface LoadingContainerProps<T> extends React.HTMLAttributes<HTMLDivElement> {
  source: () => Promise<T>;
  callback: (result: T) => unknown;
}