import { useState } from 'react';
import { Menu, MenuItem, Typography } from '@mui/material';
import { round } from '@sightgain/core/calculations';
import appStore from '../../../../AppStore';
import { useScoreClass } from '../../../../effects';
import { ScrollableTable, StatusBadge } from '../../../reusables/scl';
import { ActivityLFT } from '../../../reusables/useActivityWatcher/interfaces';
import { liveFireTrainingService } from '../../../services';

enum ScoreAction {
  INCLUDE = 'include',
  EXCLUDE = 'exclude',
}

const LFT_FIELDS = '{ id status job { id isExcluded }  }';

export default function ActivityTable({ activity }: { activity: ActivityLFT[] }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<ActivityLFT | undefined>(undefined);
  const scoreClassName = useScoreClass();
  const headers = ['ID', 'Evaluation', 'Status', 'Composite Score', 'Instructor Score', 'Exclude Score?'];
  const renderFns = {
    ID: (row: ActivityLFT) => <Typography variant="body2">{row.job.evaluationId}</Typography>,
    Evaluation: (row: ActivityLFT) => <Typography variant="body1">{row.job.name}</Typography>,
    Status: (row: ActivityLFT) => <StatusBadge status={row.isReleased ? 'released' : row.status} />,
    'Composite Score': (row: ActivityLFT) => {
      const score = round(row.score);
      return (
        <Typography variant="body1" className={scoreClassName(score)}>
          {score !== undefined ? score : '--'}
        </Typography>
      );
    },
    'Instructor Score': (row: ActivityLFT) => (
      <Typography variant="body1" className={scoreClassName(row.instructorScore)}>
        {row.instructorScore}
      </Typography>
    ),
    'Exclude Score?': (row: ActivityLFT) => (
      <Typography variant="body1">{row.job.isExcluded ? 'EXCLUDED' : 'INCLUDED'}</Typography>
    ),
  };

  const handleClick = (row: ActivityLFT, event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setSelected(row);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSelected(undefined);
  };

  const handleAction = async (action: ScoreAction) => {
    if (!selected) {
      return;
    }

    appStore.beginLoading();
    try {
      switch (action) {
        case ScoreAction.INCLUDE:
          await liveFireTrainingService.includeLiveFireTraining(selected.id, LFT_FIELDS);
          break;
        case ScoreAction.EXCLUDE:
          await liveFireTrainingService.excludeLiveFireTraining(selected.id, LFT_FIELDS);
          break;
      }
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
    handleClose();
  };

  return (
    <>
      <ScrollableTable headers={headers} data={activity} renderFns={renderFns} onClick={handleClick} />

      <Menu
        open={!!anchorEl}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem onClick={() => handleAction(ScoreAction.EXCLUDE)}>Exclude Score</MenuItem>
        <MenuItem onClick={() => handleAction(ScoreAction.INCLUDE)}>Include Score</MenuItem>
      </Menu>
    </>
  );
}
