import { curriculumService } from '../services';
import { Achievement } from '../services/interfaces';
import useWatcher from './useWatcher';

/**
 * Creates a websocket listener for curriculum progress
 * @param userId identifier of the user
 */
export default function useRecentAchievements(userId: string, days?: number): Achievement[] {
  return useWatcher('achievementprogress', 'update', async (body: { student: string }) => {
    if (body.student !== userId) {
      return;
    }

    return await curriculumService.recentAchievements(userId, days);
  }, { student: userId }, []) as Achievement[];
}