import { makeStyles, withStyles } from '@mui/styles';
import { Stepper, Step, StepLabel, StepConnector } from '@mui/material';
import ProgressBarStepsIcon from './ProgressBarStepsIcon';

const useStyles = makeStyles(() => ({
  wrapper: {
    background: 'none',
  },
}));

const ProgressBarStepsConnector = withStyles({
  alternativeLabel: {
    left: 'calc(-50% + 14px)',
    right: 'calc(50% + 14px)',
  },
  completed: {
    top: 10,
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
    '& $line': {
      border: 'solid 4px #2ecff5',
      backgroundColor: '#2ecff5',
    },
  },
  active: {
    top: 10,
    left: 'calc(-50% + 12px)',
    right: 'calc(50% + 12px)',
    '& $line': {
      border: 'solid 4px #2ecff5',
      backgroundColor: '#2ecff5',
    },
  },
  line: {
    height: 4,
    border: 'none',
    background: 'rgba(255, 255, 255, 0.12)',
  },
})(StepConnector);

interface ProgressBarStepsProps {
  total: number;
  current: number;
}

export default function ProgressBarSteps({ total, current }: ProgressBarStepsProps) {
  const classes = useStyles();
  const labels = Array.from(Array(total).keys());

  return (
    <Stepper
      className={classes.wrapper}
      alternativeLabel
      activeStep={current - 1}
      connector={<ProgressBarStepsConnector />}
    >
      {labels.map(label => (
        <Step key={label}>
          <StepLabel StepIconComponent={ProgressBarStepsIcon} />
        </Step>
      ))}
    </Stepper>
  );
}
