import { ReactNode } from 'react';
import Dialog from '@mui/material/Dialog';

export default function CapabilityMaturityDialog({ open, children }: DialogProps) {
  return (
    <Dialog
      open={open}
      onClose={() => {} }
      onSubmit={() => {} }
      container={() => document.getElementById('capability-maturity')}
      fullWidth
      sx={{ position: 'absolute' }}
      slotProps={{ backdrop: { sx: { position: 'absolute' }  } }}
      PaperProps={{ sx: {
        width: '100%',
        height: '100%',
        margin: 0,
        maxWidth: 'none',
        maxHeight: 'none',
        background: '#192637',
        padding: '50px'
      } }}
    >
      { children }
    </Dialog>
  );
}

type DialogProps = {
  open: boolean;
  children: ReactNode;
}