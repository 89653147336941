import { DropzoneArea } from 'mui-file-dropzone';
import { FormEvent, useState } from 'react';
import { ImportFrameworkInput } from '../../../services/interfaces';
import Modal from '../../../reusables/Modal';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(({
  container: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    minWidth: 500
  },
}));

interface ImportFrameworkModalProps {
  open?: boolean;
  onImport: (input: ImportFrameworkInput) => Promise<void>;
  onClose: () => void;
}

export default function ImportFrameworkModal({ open = false, onImport, onClose }: ImportFrameworkModalProps) {
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);

  const handleImportClick = async (event: FormEvent) => {
    event.preventDefault();
    onImport({ files });
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={handleImportClick}
      actions={[
        { text: 'Import', color: 'primary', submit: true },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <div className={classes.container}>
        <DropzoneArea fileObjects={files} onChange={f => setFiles(f)} acceptedFiles={[
          'application/json',
        ]} />
      </div>
    </Modal>
  );
}
