import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'inline-block',
    paddingLeft: 8,
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 5,
    backgroundColor: '#1592ff',
    borderRadius: 2,
  },
  tag: {
    color: '#0b0c12',
  },
}));

interface TagProps {
  tag: string;
  className?: string;
}

export default function Tag({ tag, className = '' }: TagProps) {
  const classes = useStyles();

  return (
    <span className={clsx(classes.wrapper, className)}>
      <Typography className={classes.tag} variant="h6">
        {tag}
      </Typography>
    </span>
  );
}
