import { FetchPolicy, gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import {
  AddAnalystInputArgs,
  InstructorFeedbackArgs,
  LiveFireTraining,
  LiveFireTrainingListArgs,
  PaginationInput,
  StartLiveFireTrainingArgs,
} from '../interfaces';
import ServiceBase from '../ServiceBase';
import { typeLiveFireTraining } from './fields';

const dateFields = ['created', 'updated', 'started', 'ended', 'completed', 'createdAt', 'updatedAt'];

export class LiveFireTrainingService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, dateFields));
  }

  /**
   * Find a training by its ID
   */
  async find(
    id: string,
    fields = typeLiveFireTraining,
    fetchPolicy: FetchPolicy = 'network-only',
  ): Promise<LiveFireTraining> {
    const query = gql`
      query LiveFireTraining($id: ID!) {
        liveFireTraining(id: $id) ${fields}
      }
    `;

    const { liveFireTraining } = await this.graphql(query, { id }, fetchPolicy);
    return liveFireTraining;
  }

  /**
   * Retrieves Live Fire Training data from GQL
   */
  async list(
    filters: LiveFireTrainingListArgs,
    pagination: PaginationInput = {},
    fields = typeLiveFireTraining,
  ): Promise<LiveFireTraining[]> {
    const query = gql`
      query LiveFireTrainings($input: LiveFireTrainingListInput, $pagination: PaginationInput) {
        liveFireTrainings(input: $input, pagination: $pagination) ${fields}
      }
    `;

    const { liveFireTrainings } = await this.graphql(query, { input: filters, pagination }, 'network-only');
    return liveFireTrainings;
  }

  /**
   * Retrieves Live Fire Training statuses from GQL
   */
  async statuses(
    filters: LiveFireTrainingListArgs,
    pagination: PaginationInput = {},
  ): Promise<Pick<LiveFireTraining, 'id' | 'status' | 'createdAt'>[]> {
    const query = gql`
      query LiveFireTrainings($input: LiveFireTrainingListInput, $pagination: PaginationInput) {
        liveFireTrainings(input: $input, pagination: $pagination) {
          id
          status
          createdAt
        }
      }
    `;

    const { liveFireTrainings } = await this.graphql(query, { input: filters, pagination }, 'no-cache');
    return liveFireTrainings;
  }

  /**
   * Retrieves data as a CVS string to be downloaded
   */
  async csv(filters: LiveFireTrainingListArgs): Promise<string> {
    const query = gql`
      query LiveFireTrainingsExport($input: LiveFireTrainingListInput) {
        liveFireTrainingsExport(input: $input)
      }
    `;

    const { liveFireTrainingsExport } = await this.graphql(query, { input: filters }, 'no-cache');
    return liveFireTrainingsExport;
  }

  /**
   * Adds analyst input to a training
   */
  async addAnalystInput(input: AddAnalystInputArgs, fields = typeLiveFireTraining): Promise<LiveFireTraining> {
    const query = gql`
      mutation AddAnalystInput($input: AnalystInputInput!) {
        addAnalystInput(input: $input) ${fields}
      }
    `;

    const { addAnalystInput } = await this.graphql(query, { input });
    return addAnalystInput;
  }

  /**
   * Adds feedback to a training and then releases it
   */
  async addInstructorFeedback(input: InstructorFeedbackArgs, fields = typeLiveFireTraining): Promise<LiveFireTraining> {
    const query = gql`
      mutation AddInstructorFeedback($input: InstructorReviewInput!) {
        addInstructorFeedback(input: $input) ${fields}
      }
    `;

    const { addInstructorFeedback } = await this.graphql(query, { input });
    return addInstructorFeedback;
  }

  /**
   * Discards a live fire trainiing
   */
  async discard(id: string, fields = typeLiveFireTraining): Promise<LiveFireTraining> {
    const query = gql`
      mutation DiscardLiveFireTraining($id: ID!) {
        discardLiveFireTraining(id: $id) ${fields}
      }
    `;

    const { discardLiveFireTraining } = await this.graphql(query, { id });
    return discardLiveFireTraining;
  }

  /**
   * Ends a live fire trainiing
   */
  async end(id: string, fields = typeLiveFireTraining): Promise<LiveFireTraining> {
    const query = gql`
      mutation endLiveFireTraining($id: ID!) {
        endLiveFireTraining(id: $id) ${fields}
      }
    `;

    const { endLiveFireTraining } = await this.graphql(query, { id });
    return endLiveFireTraining;
  }

  /**
   * Rescores the last 10,000 live fire trainings... very expensive
   * @returns {Promise<string>}
   */
  async rescore() {
    const query = gql`
      mutation RescoreLiveFireTrainings($id: ID!) {
        rescoreLiveFireTrainings(id: $id)
      }
    `;

    const { rescoreLiveFireTrainings } = await this.graphql(query);
    return rescoreLiveFireTrainings;
  }

  /**
   * Starts a live fire trainings for each analyst and returns
   * the new training objects
   */
  async start(input: StartLiveFireTrainingArgs, fields = typeLiveFireTraining): Promise<LiveFireTraining[]> {
    const query = gql`
      mutation StartLiveFireTraining($input: LiveFireTrainingStartInput) {
        startLiveFireTraining(input: $input) ${fields}
      }
    `;

    const { startLiveFireTraining } = await this.graphql(query, { input });
    return startLiveFireTraining;
  }

  async includeLiveFireTraining(id: string, fields = typeLiveFireTraining) {
    const query = gql`
      mutation IncludeLiveFireTraining($id: ID!) {
        includeLiveFireTraining(id: $id) ${fields}
      }
    `;

    const { includeLiveFireTraining } = await this.graphql(query, { id });
    return includeLiveFireTraining;
  }

  async excludeLiveFireTraining(id: string, fields = typeLiveFireTraining) {
    const query = gql`
      mutation ExcludeLiveFireTraining($id: ID!) {
        excludeLiveFireTraining(id: $id) ${fields}
      }
    `;

    const { excludeLiveFireTraining } = await this.graphql(query, { id });
    return excludeLiveFireTraining;
  }
}

const liveFireTrainingService = new LiveFireTrainingService();
export default liveFireTrainingService;
