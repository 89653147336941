import { formatDistanceToNow } from 'date-fns';
import { FormEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { round } from '@sightgain/core/calculations';
import appStore from '../../../../AppStore';
import { useScoreClass } from '../../../../effects';
import { ScrollableTable, StatusBadge } from '../../../reusables/scl';
import WaitingButton from '../../../reusables/WaitingButton';
import { jobsService } from '../../../services';
import { FrameworkItem, Job, LiveFireTraining, LiveFireTrainingStatus } from '../../../services/interfaces';
import ControlModal from './Manager/EvaluationWindow/Steps/Controls/ControlModal';
import useJobsWatcher from './useJobsWatcher';

const useStyles = makeStyles(theme => ({
  detailBox: {
    background: theme.palette.darkBlue3.main,
    padding: '30px 20px',
    // flex: 1,
  },
  controlBox: {
    background: theme.palette.darkBlue3.main,
    padding: '12px 12px',
    textAlign: 'center',
  },
  excludedIcon: {
    color: '#f85c5c',
  },
  includedIcon: {
    color: 'rgb(0, 255, 177)',
  },
}));

export type LFT = Pick<
  LiveFireTraining,
  'id' | 'isReleased' | 'isExam' | 'status' | 'analyst' | 'createdAt' | 'instructorScore' | 'score'
>;

type RRJob = Pick<Job, 'id' | 'status' | 'createdAt' | 'evaluationId' | 'name' | 'isExcluded' | 'frameworkControls'> & {
  trainings: LFT[];
};

interface DataRow {
  id: string;
  evaluationId: string;
  type: 'lft' | 'job';
  jobId: string;
  name: string;
  status: LiveFireTrainingStatus;
  vendorId: string;
  createdAt: Date;
  analyst?: string;
  score?: number;
  isReleased?: boolean;
  isExam?: boolean;
  isExcluded?: boolean;
  instructorScore?: number;
  frameworkControls?: FrameworkItem[];
}

const jobFields = `
{
  id status isExcluded createdAt
  frameworkControls {
      id
      identifier
      name
    }
  name
  evaluationId 
  trainings { 
    id isReleased status analyst { id email } createdAt isExam
    score
    eventScore
    responseScore
    meanResponseTime
    precisionScore
    instructorScore
  } 
}`;

const headers = ['ID', 'Name', 'Status', 'Analyst', 'Date', 'Include in Scoring'];

function RecentlyRunEvaluations({
  type,
  status,
}: {
  type: '' | 'live-tests' | 'live-fire-training' | 'exam';
  status: string;
}) {
  const classes = useStyles();
  const scoreClassName = useScoreClass();
  const history = useHistory();
  const rows: RRJob[] | undefined = useJobsWatcher({ fields: jobFields });
  const [open, setOpen] = useState<boolean>(false);
  const [frameworkControls, setFrameworkControls] = useState<FrameworkItem[]>([]);
  const [frameworkControlsJobId, setFrameworkControlsJobId] = useState<string>('');

  const parsedRows: DataRow[] = (rows || [])
    .flatMap((row): DataRow | DataRow[] => {
      const jobRow: DataRow = {
        id: row.id,
        jobId: row.id,
        type: 'job',
        evaluationId: row.evaluationId,
        name: row.name,
        status: row.status as LiveFireTrainingStatus,
        vendorId: row.evaluationId,
        createdAt: row.createdAt,
        isExcluded: row.isExcluded,
        frameworkControls: row.frameworkControls,
      };

      if (!row.trainings.length) {
        return jobRow;
      }

      return row.trainings.map(training => ({
        ...jobRow,
        id: training.id,
        type: 'lft',
        status: training.status,
        analyst: training.analyst.email,
        score: round(training.score * 100, 0),
        instructorScore: training.instructorScore,
        isReleased: training.isReleased,
        isExam: training.isExam,
      }));
    })
    .filter(r => {
      // checks that the tab is showing the right types
      const isType =
        type === '' ||
        (type === 'live-tests' && r.type === 'job') ||
        (type === 'live-fire-training' && r.type === 'lft' && r.isExam === false) ||
        (type === 'exam' && r.type === 'lft' && r.isExam === true);

      // checks that the status filter matches the row status
      const isStatus =
        status === 'all statuses' ||
        (r.status === status &&
          // released is not a real status so it needs to be handled separately
          !(r.status === 'completed' && r.isReleased)) ||
        (status === 'released' && r.isReleased);

      return isType && isStatus;
    });

  const handleExcludeJob = async (id: string, isExcluded: boolean) => {
    try {
      await jobsService.excludeJob(id, isExcluded);
    } catch (err) {
      appStore.error(err);
    }
  };

  const handleOpen = (e: FormEvent, id: string, controls: FrameworkItem[]) => {
    e.preventDefault();
    setFrameworkControls(controls);
    setFrameworkControlsJobId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      await jobsService.setFrameworkControls(
        frameworkControlsJobId,
        frameworkControls.map(x => x.id),
      );
    } catch (err) {
      appStore.error(err);
    }
  };

  return (
    <div>
      <ScrollableTable
        headers={headers}
        data={parsedRows}
        onClick={(row: DataRow) => history.push(`/curriculum/evaluations/${row.type}/${row.id}`)}
        renderFns={{
          ID: (row: DataRow) => <Typography variant="body2">{row.evaluationId}</Typography>,
          Name: (row: DataRow) => <Typography variant="body1">{row.name}</Typography>,
          Status: (row: DataRow) => <StatusBadge status={row.isReleased ? 'released' : row.status} />,
          Analyst: (row: DataRow) => <Typography variant="body1">{row.analyst ?? 'Live Test'}</Typography>,
          Date: (row: DataRow) => (
            <Typography variant="body1">{`${formatDistanceToNow(row.createdAt)} ago`}</Typography>
          ),
          'Include in Scoring': (row: DataRow) => (
            <WaitingButton
              onClick={() => handleExcludeJob(row.jobId, !row.isExcluded)}
              value={row.isExcluded ? 'EXCLUDED' : 'INCLUDED'}
            >
              <FontAwesomeIcon
                className={row.isExcluded ? classes.excludedIcon : classes.includedIcon}
                icon={['fas', row.isExcluded ? 'times-circle' : 'check-circle']}
              />
            </WaitingButton>
          ),
        }}
      >
        {(row: DataRow) => (
          <Grid container gap={2}>
            <Grid item className={classes.detailBox}>
              <Typography display="block" variant="textStyle3">
                Composite Score:
              </Typography>
              <Typography
                display="block"
                textAlign="center"
                variant="textStyle10"
                className={scoreClassName(row.score)}
              >
                {row.score !== undefined ? row.score : '--'}
              </Typography>
            </Grid>
            <Grid item className={classes.detailBox}>
              <Typography display="block" variant="textStyle3">
                Instructor Score:
              </Typography>
              <Typography
                display="block"
                textAlign="center"
                variant="textStyle10"
                className={scoreClassName(row.instructorScore)}
              >
                {row.instructorScore !== undefined ? `${row.instructorScore}` : '--'}
              </Typography>
            </Grid>
            <Grid item className={classes.controlBox}>
              <Typography variant="textStyle3">Capability Controls:</Typography>
              <Typography>
                {row.frameworkControls?.length || 'All'} control{row.frameworkControls?.length === 1 ? '' : 's'} will be
                scored
              </Typography>
              <Button
                size="small"
                onClick={(e: FormEvent) => handleOpen(e, row.id, row?.frameworkControls ?? [])}
                variant="contained"
              >
                Customize
              </Button>
            </Grid>
          </Grid>
        )}
      </ScrollableTable>
      <ControlModal
        open={open}
        onClose={handleClose}
        onSave={handleSave}
        selectedControls={frameworkControls}
        setSelectedControls={setFrameworkControls}
      />
    </div>
  );
}

export default RecentlyRunEvaluations;
