import { makeStyles } from '@mui/styles';
import { Typography, Link } from '@mui/material';

const useStyles = makeStyles(() => ({
  title: {
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20,
  },
}));

interface LinkItem {
  label?: string;
  url?: string;
}

interface LessonReferenceProps {
  title: string;
  links: LinkItem[];
}

export default function LessonReference({ title, links }: LessonReferenceProps) {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h5" className={classes.title}>
        {title}
      </Typography>
      {links.map(link => (
        <Link key={link.url} href={link.url}>
          {link.label}
        </Link>
      ))}
    </div>
  );
}
