/**
 * Uses the web socket to get updates from the server
 * to tell who is online
 * @example
 * // returns [whosOnline, askNow]
 * const OnlineDisplay = () => {
 *   const [whosOnline, askNow] = useWhosOnline();
 *
 *   // request the server for the current users on mount
 *   useEffect(() => askNow(), []);
 *
 *   return (
 *     <div>
 *       {whosOnline.map(({ _id, name, email }) => (
 *        <p key={`online-users-${_id}`}>
 *          {name} ({email})
 *        </p>
 *       ))}
 *     </div>
 *   );
 * };
 */

import { useState, useEffect } from 'react';
import { socket } from '../services';
import { User } from '../services/interfaces';

const askNow = () => socket.send('whosonline', '');

export default function useWhosOnline() {
  const [whosOnline, setOnline] = useState([] as User[]);

  useEffect(() => {
    function handleLogon({ body: users }: { body: User[] }) {
      setOnline([...whosOnline, ...users.filter(({ id }) => !whosOnline.find(({ id: oid }) => id === oid))]);
    }

    function handleLogoff({ body: users }: { body: User[] }) {
      setOnline([...whosOnline.filter(({ id }) => !users.find(({ id: uid }) => id === uid))]);
    }

    const unsubscribe = [
      socket.subscribe(handleLogon, { type: 'users', action: 'logon' }),
      socket.subscribe(handleLogoff, { type: 'users', action: 'logoff' }),
    ];

    return () => unsubscribe.forEach(func => func());
  }, []);

  return [whosOnline, askNow] as [User[], () => void];
}
