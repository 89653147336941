import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { ResponseResult, SnrResult, SocResponse } from './interfaces';
import ServiceBase from './ServiceBase';

const dateFields = ['date', 'created', 'updated', 'started', 'ended', 'completed'];

export class SocService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, dateFields));
  }

  async getDates(from: Date, to: Date): Promise<SocResponse> {
    const query = gql`
      query socData($from: Date, $to: Date) {
        socData(from: $from, to: $to) {
          dailyJobMetrics {
            preventedCount
            detectedCount
            alertedCount
            missedCount
            count
            products {
              type
              preventedCount
              detectedCount
              alertedCount
            }
            date
          }
          dailyExerciseMetrics {
            automatedResponseCount
            manualResponseCount
            noResponseCount
            missedCount
            manualResponseTimeTotal
            count
            date
          }
        }
      }
    `;

    const { socData } = await this.graphql(query, { from, to });
    return socData;
  }

  async responseResults(from: Date, to: Date): Promise<ResponseResult[]> {
    const query = gql`
      query responseResults($from: Date, $to: Date) {
        responseResults(from: $from, to: $to) {
          ops
          type
          alerts
          events
          alertsFPR
          mttr
        }
      }
    `;

    const { responseResults } = await this.graphql(query, { from, to });
    return responseResults;
  }

  async snr(from: Date, to: Date): Promise<SnrResult> {
    const query = gql`
      query snr($from: Date, $to: Date) {
        snr(from: $from, to: $to) {
          snr {
            signal
            noise
            ratio
          }
          tests
        }
      }
    `;

    const { snr } = await this.graphql(query, { from, to });
    return snr;
  }
}

const socService = new SocService();
export default socService;
