import { makeStyles } from '@mui/styles';
import { useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useAbort } from '../../../../effects';
import { User, UserGroup, UserGroupInput, UserInput } from '../../../services/interfaces';
import { usersService } from '../../../services';
import Header from'./Header';
import AccountList from './AccountList';
import EditAccountModal from './EditAccountModal';
import { EditableUser, EditableGroup, BulkUserAction } from './interfaces';
import appStore from '../../../../AppStore';
import EditGroupModal from './EditGroupModal';
import GroupList from './GroupList';
import SelfSignupLink from './SelfSignupLink';


const GROUPFIELDS = '{ id description name }';
const USERFIELDS = '{ id name email roles group createdAt updatedAt status}';

const NEWUSER: EditableUser = {
  email: '',
  name: '',
  roles: [],
  group: 'Other Analysts',
  status: 'Active',
};

const useStyles = makeStyles(() => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

function sortByName(a: User, b: User): number {
  return a.name < b.name ? -1 : 1;
}

export default function AccountManagement() {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const [selectedUser, setSelectedUser] = useState<EditableUser | undefined>();
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<EditableGroup | undefined>();
  const [accounts, setAccounts] = useState<User[]>([]);
  const [groups, setGroups] = useState<UserGroup[]>([]);

  // pull user and groups from the backend
  useAbort(() => Promise.all([
    usersService.list(true, USERFIELDS),
    usersService.listGroups(GROUPFIELDS),
  ]), ([users, userGroups]) => {
    setAccounts(users.sort(sortByName));
    setGroups(userGroups.sort((a, b) => a.name < b.name ? -1 : 1));
  }, []);

  // determine which user modal should be open based on url
  useAbort(
    () => id === 'new' ? { ...NEWUSER } : accounts.find(a => a.id === id),
    a => setSelectedUser(a), [accounts, id]
  );

  // save a group
  const handleSaveGroup = useCallback(async (input: UserGroupInput) => {
    appStore.beginLoading();
    try {
      const saved = await usersService.saveGroup(input, GROUPFIELDS);
      const previous = groups.find(g => g.id === saved.id);
      if (previous) {
        accounts.filter(a => a.group === previous.name).forEach(a => a.group = saved.name);
      }
      const filtered = groups.filter(g => g.id !== saved.id);
      setGroups([...filtered, saved].sort((a, b) => a.name < b.name ? -1 : 1));
      setSelectedGroup(undefined);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  },[accounts, groups]);

  // save a user
  const handleSaveUser = useCallback(async (input: UserInput) => {
    appStore.beginLoading();
    try {
      const saved = await usersService.save(input, USERFIELDS);
      appStore.success(`User ${input.name} saved successfully`);
      const filtered = accounts.filter(a => a.id !== saved.id);
      setAccounts([...filtered, saved].sort((a, b) => a.name < b.name ? -1: 1));
      history.push('/settings/accounts');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  }, [accounts, history]);

  // go to the url for a selected user
  const handleSelectUser = (id: string) => history.push(`/settings/accounts/${id}`);

  // select a group
  // TODO: make this a URL like users but need to figure out how to handle the URL structure
  const handleSelectGroup = (id: string) => 
    setSelectedGroup(id === 'new' ? { name: '', description: '' } : groups.find(group => group.id === id));

  // handle bulk actions
  const handleBulkAction = useCallback(async (action: BulkUserAction) => {
    appStore.beginLoading();
    try {
      // get all the ids and create an update object
      const updates: any[] = selectedUsers.map(id => ({ id }));

      // update the objects based on action type
      switch (action) {
        case BulkUserAction.ENABLE:
          updates.forEach(u => u.status='Active');
          break;
        case BulkUserAction.DISABLE:
          updates.forEach(u => u.status='Disabled');
          break;
      }

      // get the updates
      const users = await usersService.saveUsers(updates, USERFIELDS);

      // create an array of ids to filter from being updated
      const ids = users.map(u => u.id);

      // replace updated accounts with their updates
      setAccounts([...accounts.filter(a => !ids.includes(a.id)), ...users].sort(sortByName));
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  }, [accounts, selectedUsers]);

  // ensure an email address is unique
  const handleVerifyUniqueEmail = useCallback((email?: string, uid?: string) => {
    if (!email) {
      return true;
    }

    return !accounts.find(x => x.id !== uid && x.email.toLowerCase().trim() === email.toLowerCase().trim());
  }, [accounts]);

  // ensure a group name is unique
  const handleVerifyUniqueGroup = useCallback((name?: string, gid?: string): boolean => {
    if (!name) {
      return true;
    }

    return !groups.find(x => x.id !== gid && x.name.toLocaleLowerCase().trim() === name.toLocaleLowerCase().trim());
  }, [groups]);

  return (
    <div>
      <div className={classes.paper}>
        <EditAccountModal
          key={`edit-account-${id}`}
          selectedUser={selectedUser}
          groups={groups}
          onClose={() => history.push('/settings/accounts')}
          onSave={handleSaveUser}
          verifyUniqueEmail={handleVerifyUniqueEmail}
        />
        <EditGroupModal
          key={`edit-group-${selectedGroup?.id}`}
          selectedGroup={selectedGroup}
          onSave={handleSaveGroup}
          onClose={() => handleSelectGroup('')}
          verifyUniqueGroup={handleVerifyUniqueGroup}
        />
        <div>
          <Header
            onNewGroupClick={() => handleSelectGroup('new')}
            onNewUserClick={() => handleSelectUser('new')}
            bulkEnabled={!!selectedUsers.length}
            onBulkClick={handleBulkAction}
          />
          <SelfSignupLink />
          <AccountList
            key="account-list"
            onClick={handleSelectUser}
            onSelect={ids => setSelectedUsers(ids)}
            accounts={accounts}
            groups={groups}
          />
          <GroupList key="group-list" groups={groups} onSelectGroup={handleSelectGroup} />
        </div>
      </div>
    </div>
  );
}
