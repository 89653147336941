import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { Notification } from './interfaces';
import ServiceBase from './ServiceBase';

const typeRecentNotifications = `
  {
    id
    title
    text
    read
    callToAction {
      label
      url
    }
    createdAt
  }
`;

export class NotificationService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['createdAt']));
  }

  async recentNotifications(fields = typeRecentNotifications): Promise<Notification[]> {
    const query = gql`query RecentNotifications {
        recentNotifications ${fields}
      }`;
    const { recentNotifications } = await this.graphql(query, undefined, 'network-only');
    return recentNotifications;
  }

  async markRead(notificationId: string): Promise<string> {
    const query = gql`
      mutation MarkNotificationAsRead($notificationId: String!) {
        markNotificationAsRead(notificationId: $notificationId)
      }
    `;
    const { markRead } = await this.graphql(query, { notificationId });
    return markRead;
  }

  async markAllRead(): Promise<string> {
    const query = gql`
      mutation MarkAllNotificationsAsRead {
        markAllNotificationsAsRead
      }
    `;
    const { markAllNotificationsAsRead } = await this.graphql(query);
    return markAllNotificationsAsRead;
  }
}

const notificationService = new NotificationService();
export default notificationService;
