import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'inline-flex',
    alignItems: 'center',
    padding: 12,
    background: 'black', 
    borderRadius: 3
  },
  box: {
    padding: 12, borderRadius: 2
  }
}));

interface SecurityZonesProps {
  zones: {
    name: string;
    color: string;
  }[];
}

export default function SecurityZones({ zones }: SecurityZonesProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {zones.map((zone, idx) => {
        return (
          <>
            <div key={zone.name} style={{ border: `1px solid ${zone.color}` }} className={classes.box}>
              {zone.name}
            </div>
            {idx < zones.length - 1 && <ArrowRightAltIcon key={`${zone.name}-arr`} />}
          </>
        );
      })}
    </div>
  );
}