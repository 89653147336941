import Typography from '@mui/material/Typography';
import LogTable from './Logs/LogTable';
import DownloadLatest from './Logs/DownloadLatest';
import DownloadAll from './Logs/DownloadAll';

export default function Logs() {
  return (
    <div>
      <Typography variant="h3">Logs</Typography>
      <LogTable>
        <DownloadLatest />
        <DownloadAll />
      </LogTable>
    </div>
  );
}
