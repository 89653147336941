import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { Endpoint, TestingLibrary, TestingSetting, TestingSettingCreate, Token, TokenCreateInput } from './interfaces';
import ServiceBase from './ServiceBase';

const tokenFields = `
  {
    id
    code
    expiration
    revoked
    createdAt
  }
`;

export class TestingService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['createdAt', 'expiration']));
  }

  async createToken(input: TokenCreateInput, fields = tokenFields): Promise<Token> {
    const query = gql`
      mutation TestingCreateToken($input: TokenCreate!) {
        testingCreateToken(input: $input) ${fields}
      }
    `;

    const { testingCreateToken } = await this.graphql(query, { input });
    return testingCreateToken;
  }

  async updateToken(id: string, fields = tokenFields): Promise<string> {
    const query = gql`
      mutation TestingUpdateToken($id: String!, $fields: TokenCreate!) {
        testingUpdateToken(id: $id, fields: $fields)
      }
    `;

    const { testingUpdateToken } = await this.graphql(query, { id, fields });
    return testingUpdateToken;
  }

  async deleteToken(id: string): Promise<string> {
    const query = gql`
      mutation TestingDeleteToken($id: String!) {
        testingDeleteToken(id: $id)
      }
    `;

    const { testingDeleteToken } = await this.graphql(query, { id });
    return testingDeleteToken;
  }

  async tokens(fields = tokenFields): Promise<Token[]> {
    const query = gql`
      query TestingTokens {
        testingTokens ${fields}
      }
    `;

    const { testingTokens } = await this.graphql(query, {}, 'no-cache');
    return testingTokens;
  }

  async tokenById(id: string): Promise<Token> {
    const query = gql`
      query TestingTokenById($id: String!) {
        testingTokenById(id: $id) ${tokenFields}
      }
    `;

    const { testingTokenById } = await this.graphql(query, { id });
    return testingTokenById;
  }

  async endpoints(): Promise<Endpoint[]> {
    const query = gql`
      query TestingEndpoints {
        testingEndpoints {
          id
          hostname
          os
          products {
            name
          }
        }
      }
    `;

    const { testingEndpoints } = await this.graphql(query, {}, 'no-cache');
    return testingEndpoints;
  }

  async deleteEndpoint(id: string): Promise<string> {
    const query = gql`
      mutation TestingDeleteEndpoint($id: String!) {
        testingDeleteEndpoint(id: $id)
      }
    `;

    const { testingDeleteEndpoint } = await this.graphql(query, { id });
    return testingDeleteEndpoint;
  }

  async libraries(): Promise<TestingLibrary[]> {
    const query = gql`
      query TestingLibraries {
        testingLibraries {
          id
          name
        }
      }
    `;

    const { testingLibraries } = await this.graphql(query, {}, 'no-cache');
    return testingLibraries;
  }

  async uploadLibrary(files: File[]): Promise<string> {
    const query = gql`
      mutation TestingUploadLibrary($files: [Upload!]!) {
        testingUploadLibrary(files: $files)
      }
    `;

    const { testingUploadLibrary } = await this.graphql(query, { files });
    return testingUploadLibrary;
  }

  async settings(): Promise<TestingSetting[]> {
    const query = gql`
      query TestingSettings {
        testingSettings {
          name
          value
        }
      }
    `;

    const { testingSettings } = await this.graphql(query, {}, 'no-cache');
    return testingSettings;
  }

  async saveSetting(setting: TestingSettingCreate): Promise<TestingSetting> {
    const query = gql`
      mutation TestingSaveSetting($setting: TestingSaveSetting!) {
        testingSaveSetting(setting: $setting) {
          name
          value
        }
      }
    `;

    const { testingSaveSetting } = await this.graphql(query, { setting });
    return testingSaveSetting;
  }
}

const testingService = new TestingService();
export default testingService;
