import { useState, useMemo } from 'react';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { useAbort } from '../../../../effects';
import auditService from '../../../services/AuditService';
import { AuditRecord } from '../../../services/interfaces';

export default function AuditTable() {
  const [auditRecords, setAuditRecords] = useState<AuditRecord[]>([]);

  useAbort(() => auditService.list('export'), records => setAuditRecords(records));

  const rows = useMemo(() => {
    return auditRecords;
  }, [auditRecords]);

  const columns = [
    {
      field: 'user',
      headerName: 'User',
      flex: 1,
      renderCell: ({ value }: GridCellParams) => {
        const { name } = value as Pick<AuditRecord['user'], 'name'>;
        return name;
      }
    },
    {
      field: 'name',
      headerName: 'File Name',
      flex: 1,
    },
    {
      field: 'createdAt',
      headerName: 'Export Date',
      flex: 1,
    }
  ];

  return (
    <div data-testid="audit-table">
      <DataGrid
        autoHeight
        rows={rows}
        columns={columns}
        density="compact"
        disableRowSelectionOnClick
      />
    </div>
  );
}