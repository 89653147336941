/* eslint-disable no-console */
import { v4 as uuid } from 'uuid';
import { action, computed, makeObservable, observable, toJS } from 'mobx';
import severities from './components/enums/severities';
import { Role, User } from './components/services/interfaces';

type AppStoreUser = Pick<User, 'id' | 'name' | 'email' | 'roles' | 'requirePasswordReset'>;

type Message = {
  key: string;
  message: string;
  severity: string;
  priority: number;
};

type RefreshCallback = () => unknown;
type RefreshListener = {
  success: RefreshCallback;
  failure: RefreshCallback;
};

export enum LicenseType {
  None = 'unset',
  Full = 'srp',
  Limited = 'sfa',
  ZeroTrustOnly = 'sfa-zt',
  MITREOnly = 'sfa-ma',
  CMMCOnly = 'sfa-cmmc',
}

export enum SIP {
  AttackIQ = 'attackiq',
  MSV = 'msv',
  Picus = 'picus',
  SET = 'testing',
  None = 'unset',
}

export class AppStore {
  // client id to identify the device being used
  _clientId = uuid();

  // number of items currently waiting for processing completion
  _loading = 0;

  // refresh token in order to get a new JWT
  _refresh: string | null = null;

  // JWT for backed access
  _access: string | null = null;

  // user data
  _user: AppStoreUser | null = null;

  // notification messages
  _messages: Message[] = [];

  // callback functions for when a token is being refreshed
  _refreshListeners: Array<RefreshListener> = [];

  _activeRole = Role.NONE;

  _activeModule = '';

  _licenseType = LicenseType.None;

  _sip: SIP = SIP.None;

  constructor() {
    makeObservable(this, {
      _access: observable,
      _loading: observable,
      _messages: observable,
      _refresh: observable,
      _refreshListeners: observable,
      _user: observable,
      _activeRole: observable,
      _activeModule: observable,
      _licenseType: observable,
      _sip: observable,

      access: computed,
      isLoggedIn: computed,
      isLoading: computed,
      nextMessage: computed,
      refresh: computed,
      user: computed,
      activeRole: computed,
      activeModule: computed,
      isLimitedLicense: computed,
      hideContentPacks: computed,
      SIP: computed,

      beginLoading: action,
      beginRefresh: action,
      clearPasswordReset: action,
      logout: action,
      endLoading: action,
      endRefresh: action,
      loadTokens: action,
      checkAccess: action,
      hasRole: action,
      message: action,
      removeMessage: action,
      setTokens: action,
      setActiveRole: action,
      setActiveModule: action,
      setLicenseType: action,
      setSIP: action,
    });
  }

  get isLimitedLicense(): boolean {
    return /^sfa/.test(this._licenseType);
  }

  get hideContentPacks() {
    return /^sfa-/.test(this._licenseType);
  }

  /**
   * Returns the client ID
   */
  get clientId() {
    return this._clientId;
  }

  /**
   * Returns the refresh token
   */
  get refresh() {
    return this._refresh;
  }

  /**
   * Returns the access token
   */
  get access() {
    return this._access;
  }

  /**
   * Indicates if loading
   */
  get isLoading() {
    return !!this._loading;
  }

  /**
   * Checks if a user is logged in
   */
  get isLoggedIn() {
    return !!this._user;
  }

  /**
   * Returns the next message
   */
  get nextMessage() {
    return [...this._messages].shift();
  }

  /**
   * Gets the user id from the appstore
   */
  get userId() {
    return this._user?.id ?? '';
  }

  /**
   * Gets the user from the appstore
   */
  get user() {
    return toJS(this._user) as AppStoreUser;
  }

  get activeRole() {
    return this._activeRole;
  }

  get activeModule() {
    return this._activeModule;
  }

  get LicenseType(): LicenseType {
    return this._licenseType;
  }

  get SIP(): SIP {
    return this._sip;
  }

  /**
   * Let's the store know that the password was reset successfully
   */
  clearPasswordReset() {
    if (!this._user) {
      return;
    }

    this._user.requirePasswordReset = false;
    sessionStorage.setItem('user', JSON.stringify(this._user));
  }

  /**
   * Indcate a refresh started
   * @param success Callback for after refresh completes successfully
   * @param failure Callback for after refresh failes
   * @returns the index of the new listener
   */
  beginRefresh(success: RefreshCallback, failure: RefreshCallback) {
    return this._refreshListeners.push({ success, failure });
  }

  /**
   * Indicate a refresh has ended
   */
  endRefresh(failed = false) {
    // remove the listeners
    const listeners = this._refreshListeners.splice(0);
    // call all the listeners
    listeners.forEach(({ success, failure }) => (failed ? failure() : success()));

    if (failed) {
      this.logout();
    }
  }

  /**
   * Increase number of items loading
   */
  beginLoading() {
    this._loading += 1;
  }

  /**
   * Reduce number of items loading
   */
  endLoading() {
    this._loading -= 1;
  }

  /**
   * Adds an error the application to display in the snackbar
   * @param err exception to add to the array
   */
  error(err: any) {
    console.error(err);
    return this.message(err.message || err, severities.ERROR, 100);
  }

  /**
   * Adds a warning message to the application
   * @param err exception or string to add to messages
   */
  warning(err: any) {
    console.warn(err);
    return this.message(err.message || err, severities.WARNING, 50);
  }

  /**
   * Adds an info message to the application
   * @param txt message to add to the messages
   */
  info(txt: string) {
    console.log(txt);
    return this.message(txt, severities.INFO, 25);
  }

  /**
   * Adds a success message to the application
   * @param txt message to add to the messages
   */
  success(txt: string) {
    console.log(txt);
    return this.message(txt, severities.SUCCESS, 0);
  }

  /**
   * Add a message to the snackbar and returns the key
   * @param message // string message
   * @param severity // value from severities enumerator
   * @param priority // level of priority
   */
  message(message: string, severity: string, priority: number) {
    const key = uuid();
    const messages = [
      ...this._messages,
      {
        key,
        message: typeof message === 'object' ? JSON.stringify(message) : message,
        severity: severity ?? severities.SUCCESS,
        priority,
      },
    ].sort((a, b) => (a.priority > b.priority ? -1 : 1));
    this._messages = messages;
    return key;
  }

  clearSessionStorage() {
    sessionStorage.removeItem('access');
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('refresh');
  }

  logout() {
    this._refresh = '';
    this._access = '';
    this._activeModule = '';
    this._activeRole = Role.NONE;
    this._user = null;
    this.clearSessionStorage();
  }

  /**
   * Sets the access information returned from the API
   * @param refresh // the refresh token to set
   * @param access // the access token to set
   * @param user // user data
   */
  setTokens(refresh: string, access: string, user?: AppStoreUser) {
    this._refresh = refresh;
    this._access = access;

    localStorage.setItem('clientId', this.clientId);
    sessionStorage.setItem('refresh', refresh);
    sessionStorage.setItem('access', access);

    if (user) {
      this._user = user;
      sessionStorage.setItem('user', JSON.stringify(user));
    }
  }

  /**
   * Attempts to pull the data from session storage
   */
  loadTokens() {
    this._refresh = sessionStorage.getItem('refresh');
    this._access = sessionStorage.getItem('access');
    const clientId = localStorage.getItem('clientId');
    const user = sessionStorage.getItem('user');

    if (clientId) {
      this._clientId = clientId;
    } else {
      localStorage.setItem('clientId', this.clientId);
    }

    if (!user) {
      return;
    }

    try {
      const parsed = JSON.parse(user);
      this._user = parsed;
    } catch (err) {
      // no one needs corrupt data
    }
  }

  // /**
  //  * Compares a list of allowed roles to the active role
  //  * @param allowed array of allowed roles
  //  */
  checkAccess(allowed: Role | Role[]): boolean {
    // no access for not logged in
    if (!this.isLoggedIn) {
      return false;
    }

    // empty array means all logged in users have access
    if (!allowed || !allowed.length) {
      return true;
    }

    return ([] as Role[]).concat(allowed).includes(this.activeRole);
  }

  /**
   * Returns true or false based on user having role
   * @param role role name
   */
  hasRole(role: Role) {
    if (!this.isLoggedIn) {
      return false;
    }

    return !!this._user?.roles.find(r => r === role);
  }

  /**
   * Removes a message from the queue
   * @param key message identifier
   */
  removeMessage(key: string) {
    const messages = [...this._messages].filter(m => m.key !== key);
    this._messages = messages;
  }

  setActiveRole(role: Role) {
    this._activeRole = role;
  }

  setActiveModule(module: string) {
    this._activeModule = module;
  }

  setLicenseType(licenseType: LicenseType) {
    this._licenseType = licenseType;
  }

  setSIP(sip: SIP) {
    this._sip = sip;
  }
}

// create the appstore
const appStore = new AppStore();

// load the user data if possible
appStore.loadTokens();

export default appStore;
