import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ChangeEvent } from 'react';
import { Role } from '../../../services/interfaces';

export default function RoleItem({
  name, label, description, checked = false, onUpdate,
}: RoleItemProps) {
  return (
    <Grid container item alignItems="center">
      <Grid item xs={12} md={3} lg={2}>
        <FormControl>
          <FormControlLabel
            key={`user-role-${name}`}
            value={name}
            control={<Checkbox data-testid={`edit-account-role-${name}`} color="primary" checked={checked} />}
            onChange={onUpdate}
            label={<Typography>{label}</Typography>}
            labelPlacement="end"
          />
        </FormControl>
      </Grid>
      <Grid item md={9}>
        <Typography component="div">{description}</Typography>
      </Grid>
    </Grid>
  );
}

interface RoleItemProps {
  checked?: boolean;
  description: string;
  name: Role;
  label: string;
  onUpdate: (e: ChangeEvent<unknown>, checked: boolean) => void;
}
