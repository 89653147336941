import { makeStyles } from '@mui/styles';
import {
  Badge,
  IconButton,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  icon: {
    color: '#a7adb5',
    fontSize: 27,
  },
  badgeCount: {
    width: '80px',
    '& > span': {
      top: 20,
      left: 32,
      backgroundColor: 'rgba(224, 105, 77, 0.49)',
      border: '1px solid #e0694d',
      borderRadius: '3px',
      width: 28,
      height: 23,
      fontWeight: 'bold',
    },
  },
}));

const renderIcon = (className: string, onClick: () => unknown) => (
  <IconButton onClick={onClick} size="large">
    <FontAwesomeIcon icon={['fal', 'bell']} className={className} />
  </IconButton>
);

export default function NotificationButton({ open, notificationCount, onClick }: NotificationButtonProps) {
  const classes = useStyles();

  if (!open) {
    if (notificationCount === 0) {
      return renderIcon(classes.icon, onClick);
    }

    return (
      <Badge
        color="secondary"
        badgeContent=" "
        overlap="circular"
        style={{ padding: '6px' }}
        variant="dot"
      >
        {renderIcon(classes.icon, onClick)}
      </Badge>
    );
  }

  if (notificationCount === 0) {
    return renderIcon(classes.icon, onClick);
  }

  // TODO: Find a cleaner way for this
  return (
    <Badge
      color="secondary"
      badgeContent={notificationCount}
      className={classes.badgeCount}
    >
      {renderIcon(classes.icon, onClick)}
    </Badge>
  );
}

interface NotificationButtonProps {
  open: boolean;
  notificationCount: number;
  onClick: () => unknown;
}
