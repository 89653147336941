import { Chip } from '@mui/material';
import { formatDate } from './DateHeader';
import { DayCompare } from './interfaces';

interface CompareChipProps {
  day1: string,
  day2: string,
  onClick?: DayCompare, 
  onDelete?: DayCompare,
}

export default function CompareChip({ day1, day2, onClick, onDelete }: CompareChipProps) {
  return (
    <Chip
      label={`${formatDate(day1)} vs ${formatDate(day2)}`}
      variant="outlined"
      onClick={() => onClick && onClick(day1, day2)}
      onDelete={() => onDelete && onDelete(day1, day2)}
    />
  );
}
