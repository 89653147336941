import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Link,
  Typography,
} from '@mui/material';
import { useAbort } from '../../../effects';
import { settingsService, supportService } from '../../services';
import usa from '../../images/icons/usa.png';
import book1 from '../../images/icons/book-link-blue.svg';
import book2 from '../../images/icons/book-link-orange.svg';
import book3 from '../../images/icons/book-link-yellow.svg';
import book4 from '../../images/icons/book-link-aqua.svg';
import { Support as SupportData } from '../../services/interfaces';

const bookIcons = [book1, book2, book3, book4];

const useStyles = makeStyles(() => ({
  title: {
    marginBottom: 37,
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  section: {
    width: '50%',
    marginBottom: 44,
  },
  content: {
    background: 'rgba(255, 255, 255, 0.09)',
    padding: 30,
  },
  row: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    color: '#ffffff',
    textTransform: 'uppercase',
    marginBottom: 15,
  },
  contactUs: {
    background: 'rgba(255, 255, 255, 0.09)',
    borderRadius: 6,
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 30,
    paddingRight: 30,
  },
  phoneNumber: {
    marginBottom: 11,
    fontWeight: 'bold',
  },
  phoneLink: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
  email: {
    fontWeight: 'bold',
    textDecoration: 'underline',
  },
  icon: {
    height: 42,
    width: 42,
    marginRight: 30,
  },
  links: {
    background: 'rgba(255, 255, 255, 0.09)',
    borderRadius: 6,
    paddingTop: 40,
    paddingBottom: 40,
    paddingLeft: 30,
    paddingRight: 30,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  linkWrapper: {
    width: '50%',
  },
  link: {
    display: 'block',
    color: '#ffffff',
    fontWeight: 'bold',
    textDecoration: 'underline',
    marginBottom: 11,
  },
}));

export default function Support() {
  const classes = useStyles();
  const [supportData, setSupportData] = useState({
    phone: '',
    email: '',
    links: [],
  } as SupportData);

  const [appVersion, setAppVersion] = useState('');

  useAbort(() => supportService.support(), result => setSupportData(result));
  useAbort(() => settingsService.find('appVersion'), result => setAppVersion(result.value));

  return (
    <div>
      <Typography variant="h2" className={classes.title}>
        Support
      </Typography>
      <div className={classes.wrapper}>
        <div className={classes.section}>
          <Typography variant="h6" className={classes.label}>
            Contact Us
          </Typography>
          <div className={classes.contactUs}>
            <div className={classes.row}>
              <div>
                <img className={classes.icon} src={usa} alt="" />
              </div>
              <div>
                <Link className={classes.phoneLink} href={`tel:${supportData.phone.replace(' ', '')}`}>
                  <Typography variant="body1" className={classes.phoneNumber}>
                    {supportData.phone}
                  </Typography>
                </Link>
                <Link className={classes.email} href={`mailto:${supportData.email}`}>
                  {supportData.email}
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.section}>
          <Typography variant="h6" className={classes.label}>
            Links
          </Typography>
          <div className={classes.links}>
            {supportData.links.map((link, value) => (
              <div className={classes.linkWrapper} key={link.url}>
                <div className={classes.row}>
                  <div>
                    <img className={classes.icon} src={bookIcons[value]} alt="" />
                  </div>
                  <div>
                    <Link className={classes.link} href={link.url}>
                      {link.title}
                    </Link>
                    <Typography variant="body1">
                      {link.description}
                    </Typography>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className={classes.section}>
          <Typography variant="h6" className={classes.label}>
            Version {appVersion}
          </Typography>
        </div>
      </div>
    </div>
  );
}
