import clsx from 'clsx';
import { CSSProperties } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { round } from '@sightgain/core/calculations';
import { BakeOffGroup, ProcessedData, ProcessedDataItem } from '../interfaces';

const useStyles = makeStyles(() => ({
  table: {
    background: 'transparent',
  },
  cell: {
    paddingTop: 8,
    paddingBottom: 9,
    '&:first-child': {
      borderRight: 'solid 1px #4d5a6b',
    },
    borderBottom: 'solid 1px #4d5a6b',
  },
  header: {
    background: '#0b0c12',
    '& > h6': {
      color: '#ffffff',
    },
  },
}));

const getValueText = (val: number, total: number) => {
  if (total === 0) {
    return 'No Data';
  }
  return `${val} / ${total} (${round((val / total) * 100, 0)}%)`;
};

export default function ProductTestResultsTable({ data, groups, thresholdStyle }: ProductTestResultsTableProps) {
  const classes = useStyles();
  return (
    <TableContainer>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell align="center" className={clsx(classes.cell, classes.header)}>
              <div className={classes.header} style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h6">Prevent/Block</Typography>
              </div>
            </TableCell>
            <TableCell align="center" className={clsx(classes.cell, classes.header)}>
              <div className={classes.header} style={{ display: 'flex', justifyContent: 'center' }}>
                <Typography variant="h6">Detect/Alert</Typography>
              </div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell align="center" style={thresholdStyle(data, true)} className={classes.cell}>
              <Typography component="span" variant="body2">
                {getValueText(data.prevented, data.total)}
              </Typography>
            </TableCell>
            <TableCell align="center" style={thresholdStyle(data, false)} className={classes.cell}>
              <Typography component="span" variant="body2">
                {getValueText(data.detected, data.total)}
              </Typography>
            </TableCell>
          </TableRow>
          {groups.map(item => {
            const value = data.groups[item.name] as ProcessedDataItem;
            return (
              <TableRow key={item.name}>
                <TableCell align="center" style={thresholdStyle(value, true)} className={classes.cell}>
                  <Typography component="span" variant="body2">
                    {getValueText(value.prevented, value.total)}
                  </Typography>
                </TableCell>
                <TableCell align="center" style={thresholdStyle(value, false)} className={classes.cell}>
                  <Typography component="span" variant="body2">
                    {getValueText(value.detected, value.total)}
                  </Typography>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

type ProductTestResultsTableProps = {
  data: ProcessedData;
  groups: BakeOffGroup[];
  thresholdStyle: (value: ProcessedDataItem, prevention?: boolean) => CSSProperties;
};
