import { endOfWeek, format, startOfWeek } from 'date-fns';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import { ArrowBadge } from '../Badges';
import LargeNumericMetric from './LargeNumericMetric';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  bottomTextUp: {
    marginTop: 3,
    color: '#00ffb1',
  },
  bottomTextDown: {
    marginTop: 3,
    color: '#f85c5c',
  },
  bottomTextMiddle: {
    marginTop: 3,
    color: '#a7adb5',
  },
}));

function dateLabel(duration: 'week' | 'month') {
  switch (duration) {
    case 'month': {
      const month = format(Date.now(), 'MMMM yyyy');
      return `Month of ${month}`;
    }
    case 'week': {
      const startDate = format(startOfWeek(Date.now()), 'MM/dd');
      const endDate = format(endOfWeek(Date.now()), 'MM/dd');
      return `Week of ${startDate}-${endDate}`;
    }
    default:
      return '';
  }
}

function trendDirection(value: number, valuePrevious: number) {
  if (value === valuePrevious) {
    return 'middle';
  }
  return value > valuePrevious ? 'up' : 'down';
}

export default function ProgressMonitoringMetric({
  formatter = v => v,
  value,
  valuePrevious,
  unit,
  unitShort,
  label,
  duration = 'week',
}: ProgressMonitoringMetricProps) {
  const classes = useStyles();
  const unitName = unitShort || unit;

  const diff = Math.round(Math.abs(value - valuePrevious) * 100) / 100;
  const trend = trendDirection(value, valuePrevious);

  return (
    <div className={classes.wrapper}>
      <LargeNumericMetric
        animate={false}
        formatter={formatter}
        value={value}
        label={label ?? dateLabel(duration)}
        unit={unitName}
        icon={<ArrowBadge trend={trend} />}
      />
      {trend !== 'middle' && (
        <Typography variant="body2" className={trend === 'up' ? classes.bottomTextUp : classes.bottomTextDown}>
          {`${capitalize(trend)} ${diff} ${unit} vs. last ${duration}`}
        </Typography>
      )}
      {trend === 'middle' && (
        <Typography variant="body2" className={classes.bottomTextMiddle}>
          No change
        </Typography>
      )}
    </div>
  );
}

interface ProgressMonitoringMetricProps {
  formatter?: (...args: any[]) => any;
  value: number;
  valuePrevious: number;
  unit: string;
  unitShort?: string;
  label?: string;
  duration?: 'week' | 'month';
}
