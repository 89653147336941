import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { examsService } from '../../../services';
import { useHistory } from 'react-router';
import appStore from '../../../../AppStore';

export default function ExamButton({ id }: BeginButtonProps) {
  const history = useHistory();

  const handleClick = async () => {
    appStore.beginLoading();
    try {
      const lft = await examsService.runScheduled(id, appStore.userId);
      history.push(`/curriculum/learning/livefire/${lft.id}`);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  return (
    <Button
      onClick={handleClick}
      variant="contained"
      color="primary"
      endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
    >
      Begin Exam
    </Button>
  );
}

type BeginButtonProps = {
  id: string,
};
