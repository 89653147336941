import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { AddLicenseInput, License, RenewLicenseInput } from './interfaces';
import ServiceBase from './ServiceBase';

const typeLicense = `
  {
    id
    name
    status
    expires
    allocation
    type
  }
`;

class LicenseService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['createdAt', 'issued', 'expires']));
  }

  async list(fields = typeLicense): Promise<License[]> {
    const query = gql`
      query Licenses($input: LicenseInput) {
        licenses(input: $input) ${fields}
      }
    `;

    const { licenses } = await this.graphql(query, {}, 'network-only');
    return licenses;
  }

  async renew(input: RenewLicenseInput): Promise<string> {
    const query = gql`
      mutation RenewLicense($input: RenewLicenseInput!) {
        renewLicense(input: $input)
      }
    `;

    const { renewLicense } = await this.graphql(query, { input });
    return renewLicense;
  }

  async add(input: AddLicenseInput): Promise<string> {
    const query = gql`
      mutation AddLicense($input: AddLicenseInput!) {
        addLicense(input: $input)
      }
    `;

    const { addLicense } = await this.graphql(query, { input });
    return addLicense;
  }
}

const licenseService = new LicenseService();
export default licenseService;
