import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';
const useStyles = makeStyles(() => ({
  cardBackgroundContainer: {
    minHeight: 150,
    position: 'relative',
  },
  cardBackground: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    opacity: 0.47,
    backgroundSize: 'cover',
    backgroundPosition: 'top center',
    transition: 'top 200ms, left 200ms, right 200ms, bottom 200ms ease-in',
    '$cardBackgroundContainer:hover &': {
      opacity: 0.62,
      top: '-5%',
      left: '-5%',
      right: '-5%',
    },
  },
  /**
   * separate class because function results could not reference another class
   */
  cardBackgroundImage: ({ image }: { image: string }) => ({
    backgroundImage: `url('${image}')`,
  })
}));

export default function CardBackground({ children, image }: CardBackgroundProps) {
  const classes = useStyles({ image });
  return (
    <div className={classes.cardBackgroundContainer}>
      <div className={clsx(classes.cardBackground, classes.cardBackgroundImage)} />
      <div style={{ position: 'relative' }}>
        {children}
      </div>
    </div>
  );
}

type CardBackgroundProps = {
  image: string;
  children: ReactNode | ReactNode[];
}
