import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import CircularProgress from '../../../reusables/metrics/CircularProgress';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    cursor: 'pointer',
    borderTop: 'solid 1px rgba(94, 111, 130, 0.39)',
    '&:last-child': {
      borderBottom: 'solid 1px rgba(94, 111, 130, 0.39)',
    },
  },
  selected: {
    backgroundColor: '#161926',
    cursor: 'default',
  },
  progressCircle: {
    marginRight: 14,
  },
  label: {
    color: '#4a5169',
  },
  labelSelected: {
    color: '#ffffff',
  },
  completed: {
    color: '#00ffb1',
  },
  inProgress: {
    color: '#f46043',
  },
}));

interface SideBoxProps {
  title: string;
  progress: number;
  status: 'completed' | 'notStarted' | 'inProgress';
  selected: string;
  setSelected: (v: string) => void;
}

export default function SideBox({ title, progress, status, selected, setSelected }: SideBoxProps) {
  const classes = useStyles();

  const states = {
    completed: 'Section Complete',
    inProgress: `${Math.round(progress)}% Complete`,
    notStarted: 'Not Started',
  };

  return (
    <div
      className={clsx(classes.wrapper, { [classes.selected]: selected === title })}
      onClick={() => setSelected(title)}
      onKeyPress={() => setSelected(title)}
      role="button"
      tabIndex={0}
    >
      <div className={classes.progressCircle}>
        <CircularProgress percent={progress} size={32} strokeWidth={3} />
      </div>
      <div>
        <Typography variant="h6" className={clsx(classes.label, { [classes.labelSelected]: selected === title })}>
          {title}
        </Typography>
        <Typography
          variant="body2"
          className={clsx({
            [classes.completed]: status === 'completed',
            [classes.inProgress]: ['inProgress', 'notStarted'].includes(status),
          })}
        >
          {states[status]}
        </Typography>
      </div>
    </div>
  );
}
