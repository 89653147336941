import { MouseEvent, useState } from 'react';
import { useAbort } from '../../../../effects';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { testingService } from '../../../services';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import appStore from '../../../../AppStore';
import { Token } from '../../../services/interfaces';

const useStyles = makeStyles({
  grid: {
    height: 270,
    width: '100%',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
  },
});

export default function Tokens({ className = '' }: { className?: string; }) {
  const classes = useStyles();
  const [tokens, setTokens] = useState<Token[]>([]);

  useAbort(() => testingService.tokens(), result => setTokens(result));

  const handleCreate = async () => {
    appStore.beginLoading();
    try {
      const token = await testingService.createToken({ expirationDays: 30 });
      setTokens([...tokens, token]);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const handleRemove = async (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    appStore.beginLoading();
    try {
      await testingService.deleteToken(id);
      setTokens(tokens.filter(t => t.id !== id));
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  /**
   * adds the remove button to the row
   */
  const actions = ({ row }: GridCellParams) => {
    const { id } = row as Token;
    return (
      <Button
        variant="contained"
        color="primary"
        onClick={e => handleRemove(e, id)}
        size="small"
      >
      Remove
      </Button>
    );
  };
  
  const columns = [
    {
      field: 'code',
      headerName: 'Token',
      flex: 2,
      editable: false,
    }, 
    {
      field: 'expiration',
      headerName: 'Expiration',
      flex: 3,
      editable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 3,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      renderCell: actions
    }
  ];

  return (
    <div className={className}>
      <div className={classes.topRow}>
        <Typography variant="h5">Tokens</Typography>
        <Button onClick={handleCreate} variant="contained" color="primary">Create</Button>
      </div>
      <div className={classes.grid}>
        <DataGrid
          rows={tokens}
          columns={columns}
          density="compact"
        />
      </div>
    </div>
  );
}
