import { Divider, Modal, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import CircleBadge from '../../../../reusables/Badges/CircleBadge';
import NumberBadge from '../../../../reusables/Badges/NumberBadge';
import ChevronRight from '@mui/icons-material/ChevronRight';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';

interface CompositeScoringModalProps {
  open: boolean;
  onClose(): void;
}

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    width: '70%',
    height: '65%',
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    border: 'solid 1px #5d6e80',
    backgroundColor: '#0b0c12',
    padding: 72,
    overflowY: 'auto',
  },
  title: {
    marginBottom: 38,
  },
  rectangle: {
    padding: '20px 20px 20px 20px',
    borderRadius: 6,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: '75px 250px',
    gridTemplateRows: 'auto 50px auto 50px auto',
    gridColumnGap: '0px',
    gridRowGap: '0px',
  },
  item1: {
    gridArea: '1 / 1 / 2 / 2'
  },
  item2: {
    gridArea: '1 / 2 / 2 / 3'
  },
  item3: {
    gridArea: '2 / 2 / 3 / 3'
  },
  item4: {
    gridArea: '3 / 2 / 4 / 3'
  },
  item5: {
    gridArea: '4 / 2 / 5 / 3'
  },
  item6: {
    gridArea: '5 / 2 / 6 / 3'
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  comparison: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    width: 92,
  },
  analyst: {
    padding: '10px 20px 10px 20px',
    borderRadius: 3,
    backgroundColor: '#f46043',
    width: 102
  },
  verticalLine: {
    width: 2,
    background:
      'repeating-linear-gradient(to top,white 0,white 2px,transparent 2px,transparent 5px)'
  },
  horizontalLine: {
    height: 2,
    background:
      'repeating-linear-gradient(to right,white 0,white 2px,transparent 2px,transparent 5px)'
  },
  container1: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative'
  }
}));

export default function CompositeScoringModal({ open, onClose }: CompositeScoringModalProps) {
  const classes = useStyles();

  const onCloseHandler = () => {
    onClose();
  };

  return (
    <Modal open={open} onClose={onCloseHandler} className={classes.modal}>
      <div className={classes.wrapper}>
        <div style={{ marginBottom: 34 }}>
          <Typography variant="h3" className={classes.title}>
            Composite Score
          </Typography>
          <div className={classes.rectangle}>
            <Typography variant="body1">
              This is the overall score of a particular test.
              It is calculated using the values related to the best event score in the following formula:
            </Typography>
            <Typography variant="body1" style={{ marginTop: 8 }}>
              <span style={{ fontWeight: 'bold' }}>
                &#123;event score&#125; * 0.7 + &#123;noise score&#125; * 0.2 + &#123;response score&#125; * 0.1
              </span>
            </Typography>
          </div>
        </div>
        <Divider />
        <div style={{ marginTop: 34 }}>
          <Typography variant="body1">
            Live Fire Training Scores are averages of your test scores:
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', marginTop: 17 }}>
            <div className={classes.rectangle} style={{ flexGrow: 1 }}>
              <div style={{ position: 'relative' }}>
                <div style={{ position: 'absolute', top: 0 }}><CircleBadge text="1" /></div>
                <div style={{ position: 'absolute', top: 0, padding: 14, width: '100%' }}>
                  <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <div style={{ width: 87, padding: '10px 21px', backgroundColor: '#1592ff', borderRadius: 3 }}>
                      <Typography variant="h5">Event</Typography>
                    </div>
                  </div>
                  <div style={{ height: 58 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <div className={classes.verticalLine} style={{ height: 24 }}></div>
                      <div style={{ width: 372 }}>
                        <div className={classes.horizontalLine} style={{ width: '100%' }}></div>
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                          <div className={classes.verticalLine} style={{ height: 24 }} />
                          <div className={classes.verticalLine} style={{ height: 24 }} />
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <div style={{
                      borderRadius: 6,
                      backgroundColor: 'rgba(255,255,255,0.07',
                      display: 'flex',
                      flexDirection: 'row',
                      padding: 20,
                      justifyContent: 'space-between',
                      width: 503,
                    }}>
                      <div className={classes.comparison}>
                        <NumberBadge tag color="orange">41</NumberBadge>
                        <span style={{ fontWeight: 'bold', marginTop: 12 }}>COMPARISON SCORE</span>
                      </div>
                      <div className={classes.comparison}>
                        <NumberBadge tag color="green">85</NumberBadge>
                        <span style={{ fontWeight: 'bold', marginTop: 12 }}>COMPARISON SCORE</span>
                      </div>
                      <div className={classes.comparison}>
                        <NumberBadge tag color="green">78</NumberBadge>
                        <span style={{ fontWeight: 'bold', marginTop: 12 }}>COMPARISON SCORE</span>
                      </div>
                      <div className={classes.comparison}>
                        <NumberBadge tag color="orange">53</NumberBadge>
                        <span style={{ fontWeight: 'bold', marginTop: 12 }}>COMPARISON SCORE</span>
                      </div>
                    </div>
                  </div>
                  <div style={{ justifyContent: 'center', display: 'flex', flexDirection: 'row', gap: 22 }}>
                    <div className={classes.container1}>
                      <div style={{ position: 'absolute', top: 0, left: '50%', right: '50%' }}>
                        <ExpandLess style={{ marginLeft: -12, marginTop: -6 }} />
                      </div>
                      <div className={classes.verticalLine} style={{ height: 35 }}></div>
                      <div className={classes.analyst}>
                        <Typography variant="h5" align="center">Analyst Input</Typography>
                      </div>
                    </div>
                    <div className={classes.container1}>
                      <div style={{ position: 'absolute', top: 0, left: '50%', right: '50%' }}>
                        <ExpandLess style={{ marginLeft: -12, marginTop: -6 }} />
                      </div>
                      <div className={classes.verticalLine} style={{ height: 35 }}></div>
                      <div className={classes.analyst}>
                        <Typography variant="h5" align="center">Analyst Input</Typography>
                      </div>
                    </div>
                    <div className={classes.container1}>
                      <div style={{ position: 'absolute', top: 0, left: '50%', right: '50%' }}>
                        <ExpandLess style={{ marginLeft: -12, marginTop: -6 }} />
                      </div>
                      <div className={classes.verticalLine} style={{ height: 35 }}></div>
                      <div className={classes.analyst}>
                        <Typography variant="h5" align="center">Analyst Input</Typography>
                      </div>
                    </div>
                    <div className={classes.container1}>
                      <div style={{ position: 'absolute', top: 0, left: '50%', right: '50%' }}>
                        <ExpandLess style={{ marginLeft: -12, marginTop: -6 }} />
                      </div>
                      <div className={classes.verticalLine} style={{ height: 35 }}></div>
                      <div className={classes.analyst}>
                        <Typography variant="h5" align="center">Analyst Input</Typography>
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: 30 }}>
                    <Typography variant="body2" align="center">
                      Comparison scores are based on matching data between analyst input and log events.
                    </Typography>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.grid}>
              <div className={classes.item1}>
                <div style={{
                  display: 'flex', alignItems: 'center', flexDirection: 'row', height: '100%', position: 'relative'
                }}>
                  <div className={classes.horizontalLine} style={{ width: 75, height: 2 }}></div>
                  <div style={{ position: 'absolute', right: 0 }}>
                    <ChevronRight style={{ marginRight: -7, marginTop: 5 }} />
                  </div>
                </div>
              </div>
              <div className={clsx(classes.item2, classes.rectangle, classes.center)}>
                <CircleBadge text="2" />
                <Typography variant="body2" style={{ marginTop: 16 }} align="center">
                  Returns the best event score per test
                </Typography>
              </div>
              <div className={classes.item3}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <div className={classes.verticalLine} style={{ height: 50 }}></div>
                  <ExpandMore style={{ marginTop: -15 }} />
                </div>
              </div>
              <div className={clsx(classes.item4, classes.rectangle, classes.center)}>
                <CircleBadge text="3" />
                <Typography variant="body2" style={{ marginTop: 16 }} align="center">
                  Calculates the response, noise and overall test score
                </Typography>
              </div>
              <div className={classes.item5}>
                <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
                  <div className={classes.verticalLine} style={{ height: 50 }}></div>
                  <ExpandMore style={{ marginTop: -15 }} />
                </div>
              </div>
              <div className={clsx(classes.item6, classes.rectangle, classes.center)}>
                <CircleBadge text="4" />
                <Typography variant="body2" style={{ marginTop: 16 }} align="center">
                  Takes the average of all of the text scores for the final LFT scores
                </Typography>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}