import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  charts: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 20,
    '& > div': {
      width: '32%',
      minWidth: 400,
      flexGrow: 1,
      height: 306,
    }
  }
});

export default function ROICharts({ children }: { children: JSX.Element[] }) {
  const classes = useStyles();
  return (
    <Grid item xs={12}>
      <div className={classes.charts}>
        {children}
      </div>
    </Grid>
  );
}