import { makeStyles } from '@mui/styles';
import { DropzoneArea } from 'mui-file-dropzone';
import { useState, FormEvent } from 'react';
import { AddLicenseInput } from '../../../services/interfaces';
import Modal from '../../../reusables/Modal/Modal';

const useStyles = makeStyles(({
  container: {
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    minWidth: 500
  },
  control: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  }
}));

interface AddModalProps {
  open?: boolean;
  onAdd: (input: AddLicenseInput) => Promise<void>;
  onClose: () => void;
}

export default function AddModal({ open = false, onAdd, onClose }: AddModalProps) {
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);

  const handleAddClick = async (event: FormEvent) => {
    event.preventDefault();
    if (files.length) {
      onAdd({ files });
    }
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={handleAddClick}
      actions={[
        { text: 'Add', color: 'primary', submit: true },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <div className={classes.container} data-testid="assessor-input-modal">
        <DropzoneArea  fileObjects={files} onChange={f => setFiles(f)} acceptedFiles={[
          'text/plain',
        ]} />
      </div>
    </Modal>
  );
}