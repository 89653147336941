import { FormControl, Input, InputLabel, ListItemText, Select, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { makeStyles } from '@mui/styles';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

import { useFrameworkContext } from './FrameworkContext';
import { AggregationMethod, FrameworkListItem, PageTab } from './interfaces';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import appStore from '../../../../AppStore';
import { Role } from '../../../services/interfaces';
import { useAssessmentContext } from '../components/AssessmentContext';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  titleView: {
    marginTop: 5,
    marginLeft: 15,
  },
  fab: {
    position: 'fixed',
    margin: 0,
    top: 'auto',
    right: 20,
    left: 'auto',
    bottom: 20,
  },
  selectors: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    gap: 16,
  },
  control: {
    whiteSpace: 'nowrap',
    flex: 1,
    maxWidth: 400,
  },
  frameworkSelect: {
    width: '70%',
  },
  frameworkVersionSelect: {
    width: '120px',
  },
  frameworkName: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > div': {
      display: 'flex',
      gap: '10px',
      alignItems: 'baseline',
    },
  },
}));

const MenuProps = {
  getContentAnchorEl: () => null,
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 150,
    },
  },
};

export default function FrameworkControls({
  activeTab,
  frameworks = [],
  currentFrameworkValue,
  setCurrentFrameworkId
}: FrameworkControlsProps) {
  const classes = useStyles();
  const { setAssessmentFrameworkId } = useAssessmentContext();

  const {
    settings,
    threatGroups,
    setModalThreatGroupProperties,
    setSettings,
    securityZones,
    setSecurityZones,
    observedZones,
  } = useFrameworkContext();

  const {
    aggregationMethod, threatGroup,
  } = settings;

  const handleZoneChange = (e: any) => {
    const value = e.target.value as number[];

    // selecting to 'all'
    if (!securityZones.includes(-1) && value.includes(-1)) {
      setSecurityZones([-1]);
      return;
    }

    // selecting from 'all'
    if (securityZones.includes(-1) && !!value.find(x => x !== -1)) {
      setSecurityZones(value.filter(x => x !== -1));
      return;
    }

    // selecting nothing
    if (value.length === 0) {
      setSecurityZones([-1]);
      return;
    }

    // selecting anything else
    setSecurityZones(value);
  };

  // do not load until ready
  if (!(frameworks.length && threatGroups.length && securityZones.length)) {
    return <></>;
  }

  return (
    <>
      <div className={classes.selectors}>
        <div className={classes.frameworkName}>
          <div>
            <Typography variant="textStyle7">
              {currentFrameworkValue?.title}
            </Typography>
            <TextField
              select
              className={classes.frameworkVersionSelect}
              id="framework-version-selector"
              variant="standard"
              disabled={!currentFrameworkValue?.version}
              value={currentFrameworkValue?.id}
              onChange={e => {
                setCurrentFrameworkId(e.target.value);
                setAssessmentFrameworkId(e.target.value);
              }}
            >
              {frameworks.filter(fw => fw.name === currentFrameworkValue?.name).map(({ id, version }) => (
                <MenuItem key={id} value={id}>
                  {version}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </div>

        {[PageTab.RISK_POSTURE, PageTab.CONTROL_EFFICACY].includes(activeTab) && (
          <TextField
            select
            className={classes.control}
            label="Aggregation Method"
            variant="standard"
            id="aggregation-selector"
            value={aggregationMethod}
            onChange={({ target: { value } }) => setSettings({
              ...settings,
              aggregationMethod: value as AggregationMethod,
            })}
          >
            <MenuItem value={AggregationMethod.AVERAGE}>Average Test Score</MenuItem>
            <MenuItem value={AggregationMethod.LOWEST}>Lowest Test Score</MenuItem>
          </TextField>
        )}

        <FormControl className={classes.control}>
          <InputLabel>Security Zones</InputLabel>
          <Select
            className={classes.control}
            label="Security Zones"
            multiple
            onChange={handleZoneChange}
            value={securityZones}
            input={<Input />}
            renderValue={() => securityZones?.map(x => observedZones.find(y => y.id === x)?.name).join(', ')}
            MenuProps={MenuProps as any}
          >
            {observedZones.sort((a, b) => (a.id - b.id)).map(x =>
              (<MenuItem key={x.id} value={x.id}>
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 14 }}>
                    {securityZones.indexOf(x.id) > -1
                      ? <FontAwesomeIcon icon={['fal', 'check-square']} size="lg" />
                      : <FontAwesomeIcon icon={['fal', 'square']} size="lg" />}
                  </div>
                  <ListItemText primary={x.name} />
                </div>
              </MenuItem>
              ))}
          </Select>
        </FormControl>

        {[PageTab.RISK_POSTURE, PageTab.THREAT_INTELLIGENCE].includes(activeTab) && (
          <TextField
            select
            className={classes.control}
            label="Threat Group"
            variant="standard"
            id="threat-selector"
            value={threatGroup}
            onChange={({ target: { value } }) => setSettings({
              ...settings, threatGroup: value,
            })}
          >
            {threatGroups.map(({ id, name }) => (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            ))}
          </TextField>
        )}
        {appStore.activeRole !== Role.FRAMEWORK_ANALYST && activeTab === PageTab.THREAT_INTELLIGENCE && (
          <Tooltip title="Add Threat Group" aria-label="add threat group">
            <Fab
              className={classes.fab}
              color="primary"
              size="large"
              onClick={() => setModalThreatGroupProperties({ open: true })}
            >
              <AddIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
    </>
  );
}

interface FrameworkControlsProps {
  activeTab: PageTab;
  frameworks?: FrameworkListItem[];
  currentFrameworkValue?: FrameworkListItem;
  setCurrentFrameworkId: (currentId: string) => void;
}
