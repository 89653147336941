import clsx from 'clsx';
import { FormEvent, useState } from 'react';
import { useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, Button, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pick } from '@sightgain/core';
import appStore from '../../../AppStore';
import background from '../../images/background_analyst_signup.svg';
import NoAuth from '../../reusables/NoAuth';
import PasswordComplexity from '../../reusables/PasswordComplexity';
import { usersService } from '../../services';
import { UserSignUpInput } from '../../services/interfaces';
import Success from './Success';

const useStyles = makeStyles(theme => ({
  signup: {
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    marginBottom: 32,
  },
  row: {
    marginTop: 18,
  },
  submitButton: {
    marginTop: 32,
    fontSize: 14,
    backgroundColor: '#21bee3',
    color: '#fff',
    borderRadius: 2,
  },
  messages: {
    marginTop: 15,
  },
  textField: {
    width: '100%',
    borderRadius: 3,
    fontSize: 14,
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
    '& > div': {
      '&:before': {
        border: 0,
      },
      '&:after': {
        border: 0,
      },
      '& input': {
        backgroundColor: 'rgba(255, 255, 255, 0.11)',
        padding: '11px 18px',
        fontFamily: 'Lexend !important',
      },
    },
  },
  error: {
    color: '#f46043',
    '&::-webkit-input-placeholder': {
      color: '#f46043',
      opacity: 1,
    },
  },
  placeholder: {
    '&::-webkit-input-placeholder': {
      color: '#ffffff',
      opacity: 1,
    },
  },
}));

const EMPTYERRORS = {
  name: '',
  email: '',
  password: '',
  confirmPassword: '',
};

type FieldName = 'name' | 'email' | 'password' | 'confirmPassword';

export default function SignupForm() {
  const classes = useStyles();
  const { token } = useParams<{ token: string }>();
  const [input, setInput] = useState<UserSignUpInput & { confirmPassword: string }>({
    name: '',
    email: '',
    password: '',
    token,
    confirmPassword: '',
  });
  const [errors, setErrors] = useState({ ...EMPTYERRORS });
  const [failure, setFailure] = useState('');
  const [success, setSuccess] = useState(false);

  const handleOnChange = (key: string) => (e: { target: { value: string } }) => {
    const updated = { ...input, [key]: e.target.value };
    const errs = { ...EMPTYERRORS };

    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(updated.email)) {
      errs.email = 'invalid email address';
    }

    if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/.test(updated.password)) {
      errs.password = 'password is too weak';
    }

    if (updated.password && updated.password !== updated.confirmPassword) {
      errs.confirmPassword = 'passwords do not match';
    }

    ['name', 'email', 'password'].forEach(k => {
      if (!updated[k as FieldName]) {
        errs[k as FieldName] = 'required';
      }
    });

    setInput(updated);
    setErrors(errs);
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    appStore.beginLoading();
    try {
      await usersService.signup(pick(input, ['name', 'email', 'password', 'token']) as UserSignUpInput);
      setSuccess(true);
      setFailure('');
    } catch (err) {
      setSuccess(false);
      setFailure((err as Error).message);
    }
    appStore.endLoading();
  };

  const hasErrors = !!Object.keys(errors).filter(k => errors[k as FieldName]).length;

  return (
    <NoAuth background={background}>
      {!success && (
        <form data-testid="signup-form" action="" onSubmit={handleSubmit}>
          <div className={classes.signup}>
            <Typography className={classes.heading} variant="h1">
              Sign Up
            </Typography>
            <div>
              <TextField
                placeholder="Name"
                onChange={handleOnChange('name')}
                value={input.name}
                error={!!errors.name}
                helperText={errors.name}
                required
                className={clsx(classes.textField, errors.name && classes.error)}
                inputProps={{ className: errors.name ? classes.error : classes.placeholder }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                autoComplete="email"
                placeholder="Email Address"
                onChange={handleOnChange('email')}
                value={input.email}
                error={!!errors.email}
                helperText={errors.email}
                required
                className={clsx(classes.textField, errors.email && classes.error)}
                inputProps={{ className: errors.email ? classes.error : classes.placeholder }}
              />
            </div>
            <div className={classes.row}>
              <TextField
                type="password"
                placeholder="Password"
                onChange={handleOnChange('password')}
                value={input.password}
                error={!!errors.password}
                helperText={errors.password}
                required
                className={clsx(classes.textField, errors.password && classes.error)}
                inputProps={{ className: errors.password ? classes.error : classes.placeholder }}
              />
            </div>
            <div className={classes.row}>
              <PasswordComplexity password={input.password} />
            </div>
            <div className={classes.row}>
              <TextField
                type="password"
                placeholder="Confirm Password"
                onChange={handleOnChange('confirmPassword')}
                value={input.confirmPassword}
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword}
                required
                className={clsx(classes.textField, errors.confirmPassword && classes.error)}
                inputProps={{ className: errors.confirmPassword ? classes.error : classes.placeholder }}
              />
            </div>
            <div className={classes.row}>
              <Button
                type="submit"
                className={classes.submitButton}
                variant="contained"
                endIcon={<FontAwesomeIcon size="xs" icon={['fal', 'arrow-right']} />}
                disabled={hasErrors}
              >
                Sign Up
              </Button>
            </div>
          </div>
        </form>
      )}
      <div className={classes.messages}>
        {failure && <Alert severity="error">{failure}</Alert>}
        {success && <Success />}
      </div>
    </NoAuth>
  );
}
