import { Grid } from '@mui/material';
import { observer } from 'mobx-react';
import { FormEvent, useEffect, useMemo, useState } from 'react';
import appStore from '../../AppStore';
import { usersService } from '../services';
import Modal from './Modal';
import PasswordComplexity from './PasswordComplexity';
import PasswordText from './PasswordText';

export default observer(function PasswordResetModal() {
  const user = appStore.user;
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    setPassword('');
    setConfirmPassword('');
  }, []);

  const errors: PasswordResetErrors = useMemo(() => {
    const err = { password: '', confirmPassword: '' };

    if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%&*()]).{8,}/.test(password)) {
      err.password = 'password is too weak';
    }

    if (!password) {
      err.password = 'required';
    }

    if (password !== confirmPassword) {
      err.confirmPassword = 'passwords do not match';
    }

    return err;

  }, [password, confirmPassword]);

  const handleUpdatePassword = async (e: FormEvent) => {
    e.preventDefault();

    if (errors.password || errors.confirmPassword) {
      return;
    }

    appStore.beginLoading();
    try {
      await usersService.save({ id: user.id, password, requirePasswordReset: false });
      appStore.clearPasswordReset();
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  return (
    <Modal
      open={!!(user && user.requirePasswordReset)}
      title="Password reset is required"
      onSubmit={handleUpdatePassword}
      onClose={() => undefined}
      actions={[
        { text: 'Submit', submit: true },
      ]}
    >
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <PasswordText
            onChange={e => setPassword(e.target.value)}
            label="Password"
            password={password}
            error={errors.password}
          />
        </Grid>
        <Grid item xs={6}>
          <PasswordText
            onChange={e => setConfirmPassword(e.target.value)}
            label="Confirm Password"
            password={confirmPassword}
            error={errors.confirmPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <PasswordComplexity password={password} />
        </Grid>
      </Grid>
    </Modal>
  );
});

type PasswordResetErrors = {
  password: string;
  confirmPassword: string;
}