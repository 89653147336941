import { useCallback, useMemo } from 'react';
import { curriculumService } from '../services';
import { CalendarEvent } from '../services/interfaces';
import useSocketListener from './useSocketListener';

export default function useCalendarEvents(userId: string): CalendarEvent[] {
  const [initArgs, value] = useMemo(() => [{ attendee: userId }, []], [userId]);

  const callback = useCallback(async (body: { attendee: string }) => {
    if (body.attendee !== userId) {
      return;
    }

    return await curriculumService.calendarEvents(userId);
  }, [userId]);

  return useSocketListener('event', 'update', callback, initArgs, value) as CalendarEvent[];
}