import { ChangeEvent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, FormControl, MenuItem, Select } from '@mui/material';
import appStore from '../../../../AppStore';
import { SearchBar } from '../../../reusables/scl';
import List from './List';
import { CurrAchievementProgress } from './interfaces';
import useWatcher from '../../../reusables/useWatcher';
import { curriculumService } from '../../../services';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '39px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
  },
  headerLeft: {
    display: 'flex',
    gap: 26,
    flex: 1,
    maxWidth: '800px',
  },
  formControl: {
    minWidth: 200,
  },
  content: {
    borderTop: 'solid 1px rgba(94, 111, 130, 0.39)',
  },
}));

function Achievements() {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [selectedLevel, setSelectedLevel] = useState('all');

  const achievements = useWatcher(
    'achievementprogress',
    'update',
    async (body: { student: string }) => {
      if (body.student !== appStore.userId) {
        return;
      }

      return await curriculumService.achievementsProgress(
        appStore.userId,
        '{ student startDate completionDate completedPercent guidance achievement { name tags } }',
      );
    },
    { student: appStore.userId },
    [],
  ) as CurrAchievementProgress[];

  const levelsChange = (e: ChangeEvent<{ name?: string; value: unknown }>) => {
    setSelectedLevel(e.target.value as string);
  };

  const filter = ({ achievement }: CurrAchievementProgress) =>
    achievement.name.toLowerCase().includes(searchValue.toLowerCase()) &&
    (selectedLevel === 'all' || achievement.tags.includes(selectedLevel));

  const filteredAchievements = achievements.filter(x => filter(x));

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Typography variant="h2">Achievements</Typography>
          <SearchBar placeholder="Search Achievements" onChange={setSearchValue} />
        </div>
        <div>
          <FormControl className={classes.formControl} style={{ marginLeft: '20px' }}>
            <Select value={selectedLevel} onChange={levelsChange as any}>
              <MenuItem value="all">All Levels</MenuItem>
              <MenuItem value="tier-1">Beginner</MenuItem>
              <MenuItem value="tier-2">Intermediate</MenuItem>
              <MenuItem value="tier-3">Advanced</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div className={classes.content}>
        <List title="Earned" achievements={filteredAchievements.filter(a => a.completedPercent === 100)} />
        <List
          title="In Progress"
          achievements={filteredAchievements.filter(a => a.completedPercent < 100 && a.completedPercent > 0)}
        />
        <List title="Not Started" achievements={filteredAchievements.filter(a => a.completedPercent === 0)} />
      </div>
    </div>
  );
}

export default Achievements;
