import { useMemo, useState } from 'react';
import appStore from '../../../../AppStore';
import { Button } from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import { contentUploadService } from '../../../services';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DataGrid } from '@mui/x-data-grid';
import Modal from '../../../reusables/Modal';
import { useAbort } from '../../../../effects';
import { ContentPack } from '../../../services/interfaces';

const useStyles = makeStyles( () => ({
  btn: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 160,
  },
  header: {
    marginBottom: 15,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
  },
  headerLeft: {
    display: 'flex',
    flex: 1,
    maxWidth: '800px',
  },
  modalTitle: {
    marginBottom: 38,
  },
  modalWrapper: {
    width: 500,
  },
}));

export default function ContentSettings() {
  const classes = useStyles();
  const [files, setFiles] = useState<File[]>([]);
  const [showUpload, setShowUpload] = useState<boolean>(false);
  const [rows, setRows] = useState<ContentPack[]>([]);

  useAbort(() => contentUploadService.getContent(), setRows, []);

  const applyContent = async (contentId: string) => {
    appStore.beginLoading();
    try {
      const contentPack = await contentUploadService.applyContent( { contentId });
      setRows( ([...rows]) => {
        const idx = rows.findIndex(r => r.id === contentPack.id);
        rows.splice(idx, 1, contentPack);
        return rows;
      });
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const doFileUpload = async () => {
    if(!files.length) {
      return;
    }
    appStore.beginLoading();
    try {
      const contentPacks = await contentUploadService.addContent({ files });
      setShowUpload(false);
      setRows(rows => {
        return [...rows, ...contentPacks];
      });
    } catch(err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const sortedRows = useMemo(() => {
    const unApplied = rows.filter(row => !row.appliedAt);
    unApplied.sort( (a,b) => a.uploadedAt > b.uploadedAt ? 1 : -1);

    const applied = rows.filter(row => !!row.appliedAt);
    applied.sort( (a,b) => a.uploadedAt > b.uploadedAt ? 1 : -1);

    return [...unApplied, ...applied];
  }, [rows]);

  const columns: any[] = [
    {
      field: 'fileName',
      headerName: 'File Name',
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'description',
      headerName: 'Description',
      flex: 2,
      editable: false,
      sortable: false,
    },
    {
      field: 'uploadedAt',
      headerName: 'Uploaded At',
      flex: 1,
      editable: false,
      sortable: false,
    },
    {
      field: 'appliedAt',
      headerName: 'Apply Content',
      flex: 1,
      editable: false,
      sortable: false,
      renderCell: ({ id, value }: { id: string, value: Date}) => {
        if(value) {
          return value.toString();
        }
        
        return <Button color='secondary' onClick={() => applyContent(id) }>Apply</Button>;
      }
    },
  ];

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Typography variant="h3">Content Management</Typography>
        </div>
        <Button
          className={classes.btn}
          aria-label="Upload Content"
          variant="contained"
          color="primary"
          onClick={() => { setShowUpload(true); }}
        >
          Add Content
          <FontAwesomeIcon icon={['fal', 'upload']} />
        </Button>
      </div>
      <div data-testid="framework-list-table">
        <DataGrid
          autoHeight
          rows={sortedRows}
          columns={columns}
          density="compact"
        />
      </div>
      <Modal
        open={showUpload}
        onClose={() => { setShowUpload(false); }}
        actions={[{
          color: 'secondary',
          text: 'Upload',
          onClick: doFileUpload
        }]}
      >
        <div className={classes.modalWrapper}>
          <Typography variant="h3" className={classes.modalTitle}>
            Upload Content Pack
          </Typography>
          <DropzoneArea
            fileObjects={files}
            onChange={f => setFiles(f)}
            // acceptedFiles={['image/png', 'image/jpeg', 'application/zip', 'application/x-tar', 'application/gzip', 'application/x-zip-compressed']}
          />
        </div>
      </Modal>
    </>
  );
}