import { Checkbox, TableCell, TableRow } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';
import { TargetUser } from '../../../interfaces';

const useStyles = makeStyles(() => ({
  selected: {
    color: '#2196F3',
  },
  tr: {
    '&:hover': {
      backgroundColor: '#424242',
      cursor: 'pointer',
    },
  },
  td: {
    fontSize: 10,
  },
}));

export default function TargetAnalyst({
  analyst, onCheck, disabled }: EvaluationAnalystProps) {
  const classes = useStyles();

  const StyledTableCell = ({ children }: { children: ReactNode}) => (
    <TableCell align="center" className={clsx([classes.td, analyst.isSelected && classes.selected])}>
      {children}
    </TableCell>
  );

  return ( 
    <TableRow id={analyst.id} className={classes.tr}>
      <StyledTableCell>
        <Checkbox
          checked={analyst.isSelected}
          disabled={disabled}
          onChange={onCheck}
          name="checkedB"
          color="primary"
        />
      </StyledTableCell>
      <StyledTableCell>
        {analyst.name}
      </StyledTableCell>
      <StyledTableCell>
        {analyst.email}
      </StyledTableCell>
      <StyledTableCell>
        {analyst.roles.join(', ')}
      </StyledTableCell>
      <StyledTableCell>
        {
          analyst.isOnline
            ? <CheckCircleOutlineIcon style={{ color: 'green' }} />
            : <HighlightOffIcon style={{ color: 'red' }} />
        }
      </StyledTableCell>
    </TableRow>
  );
}

interface EvaluationAnalystProps {
  analyst: TargetUser;
  disabled?: boolean;
  onCheck: () => unknown;
}