import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import {
  LargeNumericMetric,
  PieGraphMetric,
  ProgressMonitoringMetric,
} from '../';

const useStyles = makeStyles({
  metricWrapper: {
    display: 'flex',
    flexDirection: 'row',
    marginBottom: 20,
    marginTop: 20,
    '& > *': {
      marginRight: 30,
    },
  },
});

export default function PreviewMetrics() {
  const classes = useStyles();
  return (
    <>
      <h2> Large Numeric Metric </h2>
      <div className={classes.metricWrapper}>
        <LargeNumericMetric value={1000} label="Lorem Ipsum Dollor" />
        <LargeNumericMetric value={100} label="Dollars" />
        <LargeNumericMetric value={50} label="Percent" unit="%" />
        <LargeNumericMetric
          value={30}
          label="Est. duration"
          icon={<FontAwesomeIcon size="2x" icon={['fal', 'clock']} />}
        />
      </div>
      <h2> Progress Monitoring Metric </h2>
      <div className={classes.metricWrapper}>
        <ProgressMonitoringMetric
          value={120}
          valuePrevious={40}
          unit="minutes"
          unitShort="min"
        />
        <ProgressMonitoringMetric
          value={40}
          valuePrevious={100}
          unit="minutes"
          unitShort="min"
        />
      </div>
      <h2> Pie Graph Metric </h2>
      <div className={classes.metricWrapper}>
        <PieGraphMetric percent={25} label="Completed" />
        <PieGraphMetric percent={50} label="Lorem Ipsum Dollor" />
        <PieGraphMetric percent={75} label="Lorem Ipsum Dollor" />
        <PieGraphMetric percent={100} label="Lorem Ipsum Dollor" />
      </div>
    </>
  );
}