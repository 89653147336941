import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { Framework, FrameworkItem, FrameworkSet, ImportFrameworkInput, SaveFrameworkInput } from './interfaces';
import ServiceBase from './ServiceBase';

const dateFields = ['last_updated', 'created_at', 'createdAt', 'updatedAt'];

const typeFramework = `
  {
    _id
    id
    active
    name
    title
    lastUpdated
    createdAt
    updatedAt
    version
    isSystem
    groups {
      name
      items {
        identifier
        name
        sub {
          identifier
          name
        }
      }
    }
  }
`;

const findItemFields = `
  {
    id
    description
    identifier
    name
    sub {
      id
      identifier
    }
  }
`;

export class FrameworksService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('before', options => ({
      ...options, // ignore graphql
      url: options.url === 'graphql' ? options.url : `frameworks/${options.url}`,
    }));
    this.addMiddleWare('after', data => toDates(data, dateFields));
  }

  /**
   * Returns all enabled frameworks
   */
  async list(fields = typeFramework): Promise<Framework[]> {
    const query = gql`
      query GetFrameworks($showInactive: Boolean) {
        getFrameworks(inactive: $showInactive) ${fields}
      }
    `;
    const { getFrameworks } = await this.graphql(
      query,
      {
        showInactive: false,
      },
      'network-only',
    );

    return getFrameworks;
  }

  /**
   * Return all frameworks (including inactive)
   */
  async all(): Promise<Framework[]> {
    const query = gql`
      query GetFrameworks($showInactive: Boolean) {
        getFrameworks(inactive: $showInactive) ${typeFramework}
      }
    `;
    const { getFrameworks } = await this.graphql(
      query,
      {
        showInactive: true,
      },
      'network-only',
    );
    return getFrameworks;
  }

  async listFrameworkSets(): Promise<FrameworkSet[]> {
    const query = gql`
      query GetFrameworkSets {
        getFrameworkSets {
          id
          isSystem
          name
          title
          versions {
            name
            version
            isSystem
          }
          createdAt
          updatedAt
        }
      }
    `;

    const { getFrameworkSets } = await this.graphql(query, {}, 'network-only');
    return getFrameworkSets;
  }

  /**
   * Returns a framework by id
   * @param name framework identifier
   * @param version framework version
   * @param fields GQL object structure
   */
  async find(name: string, version = 'latest', revision = 1, fields = typeFramework): Promise<Framework> {
    const query = gql`
      query GetFramework($name: String!, $version: String, $revision: Int) {
        getFramework(name: $name, version: $version, revision: $revision) ${fields}
      }
    `;
    const { getFramework } = await this.graphql(query, { name, version, revision }, 'network-only');
    return getFramework;
  }

  async updateFramework(id: string, active: boolean): Promise<Framework> {
    const query = gql`
      mutation updateFramework($input: UpdateFrameworkInput) {
        updateFramework(input: $input) {
          _id
          active
        }
      }
    `;
    const { updateFramework } = await this.graphql(query, { input: { id, active } });

    // remove the framework from the cache
    const updateQuery = gql`
      query GetFrameworks($inactive: Boolean) {
        getFrameworks(inactive: $inactive) {
          _id
          name
          title
        }
      }
    `;
    if (active === false) {
      this.removeGQLCache(updateQuery, { inactive: false }, 'getFrameworks', (v: Partial<Framework>) => v.id !== id);
    } else {
      this.appendGQLCache(updateQuery, { inactive: false }, 'getFrameworks', updateFramework);
    }

    return updateFramework;
  }

  async findItem(identifier: string, framework: string, version = '', fields = findItemFields): Promise<FrameworkItem> {
    const query = gql`
      query getFrameworkItem($itemId: String!, $framework: String, $version: String) {
        getFrameworkItem(itemId: $itemId, framework: $framework, version: $version) ${fields}
      }
    `;
    const { getFrameworkItem } = await this.graphql(query, { itemId: identifier, framework, version });

    return getFrameworkItem;
  }

  async cloneFramework(name: string, newTitle: string): Promise<void> {
    const query = gql`
      mutation CloneFramework($input: CloneFrameworkInput) {
        cloneFramework(input: $input)
      }
    `;

    await this.graphql(query, { input: { name, newTitle } });
  }

  async deleteFramework(name: string, version: string): Promise<void> {
    const query = gql`
      mutation DeleteFramework($input: DeleteFrameworkInput) {
        deleteFramework(input: $input)
      }
    `;

    await this.graphql(query, { input: { name, version } });
  }

  async importFramework(input: ImportFrameworkInput): Promise<void> {
    const query = gql`
      mutation ImportFramework($input: ImportFrameworkInput) {
        importFramework(input: $input)
      }
    `;

    await this.graphql(query, { input });
  }

  async saveFramework(input: SaveFrameworkInput): Promise<void> {
    const query = gql`
      mutation SaveFramework($input: SaveFrameworkInput) {
        saveFramework(input: $input)
      }
    `;

    await this.graphql(query, { input });
  }
}

const frameworksService = new FrameworksService();
export default frameworksService;
