import { ReactNode, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, FormControl, Input, ListItemText, MenuItem, Select } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridPaginationModel, GridSortItem, GridValueFormatterParams } from '@mui/x-data-grid';
import { capitalize } from '@sightgain/core/strings';
import appStore from '../../../../AppStore';
import { useAbort } from '../../../../effects';
import { SearchBar } from '../../../reusables/scl';
import { Log } from '../../../services/interfaces';
import logsService from '../../../services/LogsService';
import renderCellExpand from './RenderCellExpand';

const useStyles = makeStyles(() => ({
  buttons: {
    display: 'flex',
    gap: 20,
    flex: 1,
    marginTop: 20,
    marginBottom: 30,
  },
  buttonContainer: {
    display: 'flex',
  },
  filter: {
    marginBottom: 20,
    display: 'flex',
    gap: 20,
    '& > div:first-child': {
      flex: 3,
    },
    '& > div:last-child': {
      flex: 1,
    },
  },
}));

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 150,
    },
  },
};

const levels = ['audit', 'error', 'warn', 'info', 'debug'];

export default function LogTable({ children }: { children: ReactNode | ReactNode[] }) {
  const classes = useStyles();
  const [logsStarted, setLogsStarted] = useState<boolean>();
  const [autoRefresh, setAutoRefresh] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(100);
  const [rows, setRows] = useState<Log[]>([]);
  const [rowCount, setRowCount] = useState(0);
  const [refreshDate, setRefreshDate] = useState(new Date());
  const [searchValue, setSearchValue] = useState('');
  const [selectedLevels, setSelectedLevels] = useState(levels);
  const [sortModel, setSortModel] = useState<GridSortItem[]>([{ field: 'createdAt', sort: 'desc' }]);

  const columns = [
    {
      field: 'id',
      width: 275,
      sortable: false,
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      type: 'dateTime',
      width: 175,
    },
    {
      field: 'level',
      headerName: 'Level',
      width: 125,
      valueFormatter: ({ value }: GridValueFormatterParams) => capitalize(value as string),
      sortable: false,
    },
    {
      field: 'message',
      headerName: 'Message',
      flex: 1,
      renderCell: renderCellExpand,
      sortable: false,
    },
    {
      field: 'meta',
      headerName: 'Meta',
      flex: 3,
      renderCell: renderCellExpand,
      sortable: false,
    },
  ];

  useEffect(() => {
    let interval: NodeJS.Timeout;

    function clear() {
      if (interval) {
        clearInterval(interval);
      }
    }

    if (autoRefresh) {
      interval = setInterval(() => {
        setPage(0);
        setRefreshDate(new Date());
      }, 5000);
    } else {
      clear();
    }

    return () => clear();
  }, [autoRefresh]);

  useAbort(
    () =>
      Promise.all([
        logsService.list(
          refreshDate,
          {
            limit: pageSize,
            offset: page * pageSize,
            sortDirection: sortModel[0]?.sort ?? 'desc',
          },
          searchValue,
          selectedLevels,
        ),
        logsService.started(),
      ]),
    ([logsResult, started]) => {
      setRows(logsResult.rows);
      setRowCount(logsResult.count);
      setLogsStarted(started);
      if (started === false) {
        setAutoRefresh(false);
      }
    },
    [page, pageSize, refreshDate, searchValue, selectedLevels, sortModel],
    undefined,
    false,
  );

  const start = async () => {
    try {
      await logsService.start();
      setAutoRefresh(true);
    } catch (err) {
      appStore.error(err);
    }
  };

  const stop = async () => {
    try {
      await logsService.stop();
    } catch (err) {
      appStore.error(err);
    }
    setLogsStarted(false);
    setAutoRefresh(false);
  };

  const handleSortChange = (model: GridSortItem[]) => {
    /* if statement to prevent the infinite loop by confirming model is
     different than the current sortModel state */
    if (JSON.stringify(model) !== JSON.stringify(sortModel)) {
      setSortModel(model);
    }
  };

  const handlePaginationModelChange = (params: GridPaginationModel) => {
    if (params.pageSize !== pageSize) {
      setPageSize(params.pageSize);
    }

    if (params.page !== page) {
      setPage(params.page);
    }
  };

  if (logsStarted === undefined) {
    return <></>;
  }

  return (
    <div>
      <div className={classes.buttonContainer}>
        <div className={classes.buttons}>
          {!logsStarted && (
            <Button color="primary" variant="contained" onClick={start}>
              Start
            </Button>
          )}
          {logsStarted && (
            <>
              <Button color="primary" variant="contained" onClick={stop}>
                Stop
              </Button>
              <Button
                color={autoRefresh ? 'error' : 'primary'}
                variant="contained"
                onClick={() => setAutoRefresh(!autoRefresh)}
              >
                {autoRefresh ? 'Disable' : 'Enable'} Auto-Refresh
              </Button>
            </>
          )}
        </div>
        <div className={classes.buttons} style={{ justifyContent: 'flex-end' }}>
          {children}
        </div>
      </div>
      <div className={classes.filter}>
        <SearchBar placeholder="Search Log Events" onChange={setSearchValue} />
        <FormControl>
          <Select
            labelId="demo-mutiple-checkbox-label"
            id="demo-mutiple-checkbox"
            multiple
            value={selectedLevels}
            onChange={selected => setSelectedLevels(selected.target.value as string[])}
            input={<Input />}
            renderValue={() => capitalize(selectedLevels.join(', '))}
            MenuProps={MenuProps}
          >
            {levels.map(name => (
              <MenuItem key={name} value={name}>
                <div style={{ marginRight: 14 }}>
                  {selectedLevels.indexOf(name) > -1 ? (
                    <FontAwesomeIcon icon={['fal', 'check-square']} size="lg" />
                  ) : (
                    <FontAwesomeIcon icon={['fal', 'square']} size="lg" />
                  )}
                </div>
                <ListItemText primary={capitalize(name)} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div style={{ height: 475, width: '100%' }}>
        <DataGrid
          rows={rows}
          rowCount={rowCount}
          columns={columns}
          density="compact"
          pagination
          paginationMode="server"
          disableColumnFilter
          disableColumnMenu
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={handleSortChange}
          onPaginationModelChange={handlePaginationModelChange}
          disableRowSelectionOnClick
          columnVisibilityModel={{ id: false }}
        />
      </div>
    </div>
  );
}
