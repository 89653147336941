import { Key, ComponentType } from 'react';

type ComponentRegistry = {
  [key: string]: ComponentType<any>;
};

const components: ComponentRegistry = {};

export const registerComponent = (name: string, Comp: ComponentType<any>) => {
  components[name] = Comp;
};

export default (key: Key, props: any, comp?: string) => {
  const Comp = comp && Object.keys(comp).length ? components[comp] : null;

  if (!Comp) {
    return null;
  }

  return <Comp key={key} {...props} />;
};
