import { DataGrid } from '@mui/x-data-grid';
import { makeStyles } from '@mui/styles';
import { numberColumn, renderBoolValue } from '../../../../reusables/DataGrid';
import { ProductBakeOffResult } from '../../../../services/interfaces';

const columns = [{
  field: 'vendorJobId',
  headerName: 'Job ID',
  flex: 1,
  editable: false,
  ...numberColumn,
}, {
  field: 'vendorTestId',
  headerName: 'Test ID',
  flex: 1,
  editable: false,
}, {
  field: 'testName',
  headerName: 'Test Name',
  flex: 3,
  editable: false,
}, {
  field: 'prevented',
  headerName: 'Prevented',
  flex: 1,
  editable: false,
  sortable: false,
  renderCell: renderBoolValue,
  headerAlign: 'center',
  align: 'center',
}, {
  field: 'detected',
  headerName: 'Detected',
  flex: 1,
  editable: false,
  sortable: false,
  renderCell: renderBoolValue,
  headerAlign: 'center',
  align: 'center',
}, {
  field: 'createdAt',
  headerName: 'Date & Time',
  flex: 1.6,
  editable: false,
  type: 'dateTime',
}];

const useStyles = makeStyles(() => ({
  drillDown: {
    marginTop: 35,
    height: 'calc(100vh - 510px)',
  },
}));

export default function DrillDown({ results = [] }: DrillDownProps) {
  const classes = useStyles();
  return (
    <div className={classes.drillDown}>
      <DataGrid
        getRowId={row => `${row.vendorJobId}:${row.vendorTestId}`}
        rows={results}
        columns={columns as any}
        hideFooter
        density="compact"
        disableRowSelectionOnClick
      />
    </div>
  );
}

type DrillDownProps = {
  results?: ProductBakeOffResult[];
};
