import { RunOption, RunOptionInput } from '../../../../../../../services/interfaces';

export const AGENT_KEY = 'picus-agent-select';
export const AGENT_PROTOCOLS_KEY = 'picus-agent-protocols';
export const INTEGRATIONS_KEY = 'picus-integrations-select';
export const PROTOCOLS_KEY = 'picus-protocols-select';

export function getRunOption(options: RunOption[][], key: string): RunOption | undefined;
export function getRunOption(options: RunOptionInput[][], key: string): RunOptionInput | undefined;
export function getRunOption(options: RunOptionInput[][] | RunOption[][], key: string) {
  return options.flat().find(opt => opt.key === key);
}

export function getSelectedProtocols(options: RunOptionInput[][], key: string): string[] {
  return JSON.parse(getRunOption(options, key)?.value ?? '[]');
}

export function getAgentProtocols(options: RunOption[][], inputs: RunOptionInput[][]) {
  const agent = getRunOption(inputs, AGENT_KEY)?.value;
  const protocols = getRunOption(options, AGENT_PROTOCOLS_KEY)?.options ?? [];
  const agentProtocols = JSON.parse(protocols.find(p => p.value === agent)?.label ?? '[]') as string[];
  return agentProtocols.map(p => `${PROTOCOLS_KEY}-${p}`);
}
