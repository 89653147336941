import { useParams } from 'react-router';
import AnalystOverview from './AnalystOverview';
import AnalystList from './AnalystList';

export default function Analysts() {
  const { id } = useParams<{ id: string }>();
  if (id) {
    return <AnalystOverview />;
  }

  return <AnalystList />;
}