import { useContext, useEffect, useMemo } from 'react';
import { EvaluationRunner } from '../../../interfaces';
import EvaluationWindowContext from '../../EvaluationWindowContext';
import Controls from '../Controls';
import Description from '../Description';
import Steps from '../Steps';
import AgentSelector from './AgentSelector';
import IntegrationsSelector from './IntegrationsSelector';
import ProtocolsSelector from './ProtocolsSelector';
import { PROTOCOLS_KEY } from './utilities';

export default function PicusStepper({ runner }: { runner: EvaluationRunner }) {
  const { selected, testOptions, handleUpdateIsRunnable, handleUpdateScenarioTarget } =
    useContext(EvaluationWindowContext);

  // if no protocols are needed, skip the protocols step
  const steps = useMemo(() => {
    // either test options haven't loaded yet or protocols are needed
    if (!testOptions.length || testOptions[0].some(opt => opt.key.includes(PROTOCOLS_KEY))) {
      return [Description, AgentSelector, ProtocolsSelector, IntegrationsSelector, Controls];
    }

    return [Description, AgentSelector, IntegrationsSelector, Controls];
  }, [testOptions]);

  useEffect(() => {
    // TODO: remove once we can get logs from Picus
    handleUpdateScenarioTarget('liveTest');
  }, [selected, handleUpdateScenarioTarget]);

  useEffect(() => {
    if (testOptions.length && !testOptions[0][0].options?.length) {
      handleUpdateIsRunnable('No compatible agents are configured');
      return;
    }

    handleUpdateIsRunnable(!!testOptions);
  }, [testOptions, handleUpdateIsRunnable]);

  return <Steps runner={runner} steps={steps} />;
}
