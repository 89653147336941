import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 44,
  },
  emoji: {
    fontSize: 72,
    marginBottom: 32,
  },
  text: {
    marginBottom: 44,
  },
  button: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 290,
    background: 'linear-gradient(to right, #21bee3, #00ffb1)',
    border: 'none',
    marginBottom: 16,
    '&:hover': {
      background: 'linear-gradient(to right, #1A95B0, #00CC8F)',
    },
  },
}));

interface QuizCompletedProps {
  onReviewAnswers: () => void;
}

export default function QuizCompleted({ onReviewAnswers }: QuizCompletedProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.emoji}>🎉</div>
        <Typography className={classes.text} variant="h3">
          You’re Done, and your answers have been submitted!
        </Typography>
        <Typography className={classes.text} variant="h5">
          Please feel free to review your answers by clicking through the quiz menu!
        </Typography>
        <Button
          className={classes.button}
          onClick={onReviewAnswers}
          variant="contained"
          color="primary"
          endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
        >
          Review
        </Button>
      </div>
    </div>
  );
}
