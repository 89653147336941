import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 6,
    background: 'rgba(255, 255, 255, 0.09)',
  },
}));

function NoProducts() {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h3">
        Add a product to review performance
      </Typography>
    </div>
  );
}

export default NoProducts;
