import { Tooltip } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';
import { NumberBadge } from '../../../../reusables/Badges';

const useStyles = makeStyles({
  cardTag: {
    fontSize: 12,
    lineHeight: 1.25,
    fontWeight: 'bolder',
  }
});

export default function CardTag({ children, tip='' }: { children: ReactNode, tip?: string }) {
  const classes = useStyles();

  return (
    <Tooltip title={tip} placement="bottom-start" arrow>
      <div>
        <NumberBadge className={classes.cardTag} tag color="blue">{children}</NumberBadge>
      </div>
    </Tooltip>
  );
}