import { Typography, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ExamEvent } from '../../../services/interfaces';
import ExamButton from './ExamButton';

const useStyles = makeStyles(() => ({
  item: {
    padding: '25px 33px 25px 33px',
    '&:not(:last-child)': {
      borderBottom: '1px solid #203045',
    },
  },
  title: {
    textTransform: 'uppercase',
  },
  date: {
    fontSize: 14,
    marginBottom: 32,
  },
  info: {
    flex: 1,
    '& h2': {
      marginBottom: 26,
    },
    '& h6': {
      marginBottom: 12,
      color: '#a7adb5',
    },
  },
}));

export default function ExamEventItem({ item }: {item: ExamEvent}) {
  const classes = useStyles();
  return (
    <div className={classes.item} key={item.exam.id}>
      <div className={classes.info}>
        <Typography variant="h2">
          {item.exam.name}
        </Typography>
        <Typography variant="h6" className={classes.title}>Date Schedule</Typography>
        <div className={classes.date}>
          {!!item.start && (<Typography variant="body1" display="inline">
            Must start after: {item.start.toString()} 
            {' '}
          </Typography>)}
          {!!item.end && (<Typography>
            Must start before: {item.end.toString()}
          </Typography>)}
          &nbsp;
        </div>
        {item.exam.description && (
          <>
            <Typography variant="h6" className={classes.title}>Description</Typography>
            <Typography variant="body1">
              {item.exam.description}
            </Typography>
          </>
        )}
      </div>
      <div>
        <Divider style={{ marginTop: 26, marginBottom: 26 }} />
        {item.status === 'scheduled' && <ExamButton id={item.id} />}
      </div>
    </div>
  );
}