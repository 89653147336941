import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { renderComponent } from './components';

const useStyles = makeStyles(() => ({
  contentLessonMain: {
    flex: 15,
    paddingLeft: 40,
    paddingTop: 40,
    paddingBottom: 40,
    paddingRight: 40,
    height: 'calc(100vh - 222px)',
    overflowY: 'scroll',
  },
}));

interface LessonItem {
  title?: string;
  content: {
    component?: string;
    props?: any;
  }[];
}

interface LessonProps {
  lessons: LessonItem[];
}

export default function Lesson({ lessons }: LessonProps) {
  const classes = useStyles();

  console.log(lessons);

  return (
    <div className={classes.contentLessonMain}>
      {lessons.map(lesson => (
        <div key={lesson.title}>
          <Typography variant="h3">{lesson.title}</Typography>
          {lesson.content.map(({ component, props }, id) => renderComponent(`${component}-${id}`, props, component))}
        </div>
      ))}
    </div>
  );
}
