import CircularProgress from '@mui/material/CircularProgress';
import Backdrop from '@mui/material/Backdrop';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';

import appStore from '../../AppStore';

const useStyles = makeStyles(theme => ({
  backdrop: {
    // get above all of the MUI elements
    // https://mui.com/material-ui/customization/z-index/
    zIndex: 1600,
    color: theme.palette.primary.main,
  },
}));

export default observer(() => {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={appStore.isLoading} data-testid="loading">
        <CircularProgress color="inherit" disableShrink />
      </Backdrop>
    </div>
  );
});
