import { ScoreMeta, Scorable } from '../../../../services/interfaces';
import { MaturityLevel } from './interface';

export default class FrameworkItemScores {
  private scores: ScoreMeta[];
  private maturityLevel: MaturityLevel;

  constructor(scorable: Scorable, maturityLevel: MaturityLevel) {
    this.scores = scorable.scores;
    this.maturityLevel = maturityLevel;
  }

  get itemOverallMaturityScore() {
    return this.getScore('itemOverallMaturityScore');
  }

  get itemAutomatedMaturityScore() {
    return this.getScore('itemAutomatedMaturityScore');
  }

  get itemSelfScoreMaturityScore() {
    return this.getScore('itemSelfScoreMaturityScore');
  }

  get subItemAutomatedMaturityScore() {
    return this.getScore('subItemAutomatedMaturityScore');
  }

  get subItemInferredMaturityScore() {
    return this.getScore('subItemInferredMaturityScore');
  }

  get subItemSelfScoreMaturityScore() {
    return this.getScore('subItemSelfScoreMaturityScore');
  }

  get subItemTestsPassed() {
    return this.getScore('subItemTestsPassed');
  }

  get subItemTestsFailed() {
    return this.getScore('subItemTestsFailed');
  }

  get itemOverallTargetMaturityScore() {
    return this.getScore('itemOverallTargetMaturityScore');
  }

  get itemSelfScoreTargetMaturityScore() { 
    return this.getScore('itemSelfScoreTargetMaturityScore');
  }

  get itemAutomatedTargetMaturityScore() {
    return this.getScore('itemAutomatedTargetMaturityScore');
  }

  get  itemSelfScoreAdvancedMaturityScore() {
    return this.getScore('itemSelfScoreAdvancedMaturityScore');
  }

  get itemAutomatedAdvancedMaturityScore() {
    return this.getScore('itemAutomatedAdvancedMaturityScore');
  }

  get itemOverallAdvancedMaturityScore() {
    return this.getScore('itemOverallAdvancedMaturityScore');
  }

  setMaturityLevel(value: MaturityLevel) {
    this.maturityLevel = value;
  }

  isPassed(score: ScoreMeta | undefined) {
    if (!score) {
      return false;
    }

    return score.passed;
  }

  labelFor(score: ScoreMeta | undefined, defaultValue: string = '') {
    return (score && score.label) || defaultValue;
  }

  valueFor(score: ScoreMeta | undefined, defaultValue: number = 0) {
    return (score && score.score) || defaultValue;
  }

  private getScore(name: string): ScoreMeta {
    const scoresMatchingName = this.scores.filter(s => s.name === name);
    const scoreMatchingMaturityLevel = scoresMatchingName.find(s => s.maturityLevel === this.maturityLevel);

    if (scoreMatchingMaturityLevel) {
      return scoreMatchingMaturityLevel;
    }

    return scoresMatchingName[0];
  }
}