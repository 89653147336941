import { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SettingsIcon from '@mui/icons-material/Settings';
import { Box, Chip, Grid, IconButton, MenuItem, Select, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Checkbox, { CheckboxProps } from '@mui/material/Checkbox';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridCellParams, GridFooter, GridRowSelectionModel } from '@mui/x-data-grid';
import { formatDateISO } from '@sightgain/core/dates';
import SearchBar from '../../../../reusables/SearchBar';
import { Assessment } from '../../../../services/interfaces';
import { useAssessmentContext } from '../AssessmentContext';
import { AssessmentView, AssessmentViewProps } from './interfaces';

const useStyles = makeStyles(theme => ({
  checkIcon: {
    color: theme.palette.blue3.main,
    height: '20px',
    width: '20px',
  },
  datagrid: {
    minHeight: '600px',
    border: 'none',
    borderColor: theme.palette.grey2.main,
    '& .hdr-cell': {
      background: alpha(theme.palette.grey2.main, 0.1),
    },
    '& .hdr-cell .MuiDataGrid-columnHeaderTitle': {
      fontSize: '0.875rem',
      fontWeight: 700,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: '1.125rem',
      letterSpacing: 'normal',
      textAlign: 'left',
      color: theme.palette.grey2.main,
    },
  },
  row: {
    borderColor: theme.palette.grey2.main,
  },
}));

interface Row {
  id: string;
  name: string;
  score: number;
  license: string;
  expires: Date;
  created: Date;
}

export default function List({ onChangeView, onExit }: AssessmentViewProps) {
  const classes = useStyles();
  const { assessment, assessments, setAssessmentId, setEditAssessment } = useAssessmentContext();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [licenseFilter, setLicenseFilter] = useState<string>('all');
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([assessment.id]);

  const rows: Row[] = useMemo(
    () =>
      assessments.map(assessment => ({
        id: assessment.id,
        name: assessment.name,
        score: assessment.frameworks[0].scores['defaultMaturityScore'] || 0,
        license: assessment.license.type,
        expires: assessment.license.expires,
        created: assessment.createdAt,
      })),
    [assessments],
  );

  const filteredRows = useMemo(() => {
    if (!searchQuery && licenseFilter === 'all') {
      return rows;
    }

    return rows.filter(row => {
      const status = row.expires.getTime() >= Date.now() ? 'active' : 'expired';
      return new RegExp(searchQuery, 'i').test(row.name) && (licenseFilter === 'all' || status === licenseFilter);
    });
  }, [rows, searchQuery, licenseFilter]);

  const onClick = useCallback(
    (id: string) => {
      const found = assessments.find(a => a.id === id) as Assessment;
      setEditAssessment(found);
      onChangeView(AssessmentView.DETAILS);
    },
    [onChangeView, setEditAssessment, assessments],
  );

  const save = useCallback(
    async (id: string) => {
      setAssessmentId(id);
      onExit();
    },
    [onExit, setAssessmentId],
  );

  // Render actions that can be performed per row of the DataGrid
  const actions = ({ row }: GridCellParams) => {
    return (
      <>
        <IconButton aria-label="delete" size="large" onClick={() => onClick(row.id)}>
          <SettingsIcon fontSize="small" color="primary" />
        </IconButton>
      </>
    );
  };

  // Replaces the default DataGrid checkbox with a circle checkmark icon
  function CustomCheckbox(props: CheckboxProps) {
    return (
      <Checkbox
        icon={<FontAwesomeIcon icon={['far', 'check-circle']} className={classes.checkIcon} />}
        checkedIcon={<FontAwesomeIcon icon={['fas', 'check-circle']} className={classes.checkIcon} />}
        {...props}
      />
    );
  }

  const CustomFooter = () => {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          borderColor: 'grey1.main',
          paddingTop: '10px',
        }}
        borderTop={1}
      >
        <GridFooter sx={{ border: 'none' }} />
        <Grid item display="flex" justifyContent="end" gap={4}>
          <Button variant="contained" color="primary" onClick={() => save(selectionModel[0] as string)}>
            Done
          </Button>
          <Button
            color="orange1"
            variant="text"
            sx={{
              padding: '5px 15px',
            }}
            onClick={onExit}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    );
  };

  return (
    <Grid container gap={2} flexDirection="column" justifyContent="space-around">
      <Grid item>
        <Typography variant="textStyle9">Assessments</Typography>
      </Grid>
      <Grid item>
        <Grid container alignItems="center" gap={2} padding={1}>
          <Grid item flex={2}>
            <SearchBar placeholder="Search Assessments" onChange={setSearchQuery} />
          </Grid>
          <Grid item flex={1}>
            <Select value={licenseFilter} fullWidth onChange={e => setLicenseFilter(e.target.value)}>
              <MenuItem value="all">All Licenses</MenuItem>
              <MenuItem value="active">Active Licenses</MenuItem>
              <MenuItem value="expired">Expired Licenses</MenuItem>
            </Select>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => onChangeView(AssessmentView.NEW)}
              startIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
            >
              Create Assessment
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <DataGrid
          className={classes.datagrid}
          rowHeight={80}
          getRowClassName={() => classes.row}
          rows={filteredRows}
          checkboxSelection
          disableRowSelectionOnClick
          slots={{
            baseCheckbox: CustomCheckbox,
            footer: CustomFooter,
          }}
          onRowSelectionModelChange={newSelectionModel => {
            if (selectionModel.length === 1 && newSelectionModel.length === 0) {
              return;
            }
            setSelectionModel(newSelectionModel.splice(-1));
          }}
          rowSelectionModel={selectionModel}
          columns={[
            {
              field: 'name',
              headerName: 'Assessment Name',
              headerClassName: 'hdr-cell',
              flex: 1,
              minWidth: 300,
            },
            {
              field: 'score',
              headerName: 'Score',
              headerClassName: 'hdr-cell',
              flex: 1,
            },
            {
              field: 'license',
              headerName: 'License',
              headerClassName: 'hdr-cell',
              flex: 1,
              minWidth: 100,
              renderCell: ({ row }: GridCellParams) => {
                const status = row.expires.getTime() >= Date.now() ? 'Active' : 'Expired';
                const color = status === 'Active' ? 'green2' : 'orange1';
                return <Chip label={status} color={color} />;
              },
            },
            {
              field: 'expires',
              headerName: 'Expires',
              headerClassName: 'hdr-cell',
              renderCell: ({ row }: GridCellParams) => {
                return <Typography color="grey1.main">{formatDateISO(row.expires)}</Typography>;
              },
            },
            {
              field: 'created',
              headerName: 'Created',
              headerClassName: 'hdr-cell',
              renderCell: ({ row }: GridCellParams) => {
                return <Typography color="grey1.main">{formatDateISO(row.created)}</Typography>;
              },
            },
            {
              field: 'actions',
              headerName: 'Actions',
              headerClassName: 'hdr-cell',
              renderCell: actions,
            },
          ]}
          sx={{
            '& .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
            '& .MuiDataGrid-columnHeaderCheckbox': {
              background: 'rgba(135, 151, 171, 0.1)',
            },
          }}
        />
      </Grid>
    </Grid>
  );
}
