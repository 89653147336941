import { Accordion, AccordionSummary, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useContext, useEffect } from 'react';
import { Evaluation, Exam } from '../../../../../../services/interfaces';
import EvaluationWindowContext from '../EvaluationWindowContext';
import FaIconButton from '../../../../../../reusables/FaIconButton';

const useStyles = makeStyles((theme: any) => ({
  root: {
    width: '100%',
    paddingBottom: '20px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.typography.fontWeightRegular,
  },
  subHeading: {
    fontSize: theme.typography.pxToRem(11),
    fontWeight: theme.typography.fontWeightRegular,
  },
  expansionPanel: {
    background: '#303030',
  },
}));

export default function Stages() {
  const classes = useStyles();
  const { stages, handleDisableNextBtn } = useContext(EvaluationWindowContext);

  useEffect(() => {
    // nothing to do so next should be enabled
    handleDisableNextBtn(false);
  });

  return (
    <div className={classes.root}>
      {stages.map(s => (
        <Accordion key={s.name} className={classes.expansionPanel}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3a-content" id="panel3a-header">
            <Typography className={classes.heading}>{s.name}</Typography>
          </AccordionSummary>

          {s.tests.map(test => (
            <AccordionSummary
              aria-controls="panel3a-content"
              id="panel3a-header"
              className="tacticRow"
              key={test.vendorId}
            >
              <Typography className={classes.subHeading}>{test.name}</Typography>
            </AccordionSummary>
          ))}
        </Accordion>
      ))}
    </div>
  );
}

Stages.label = 'Review scenario tactics';

Stages.afterLabel = function ({ evaluation }: { evaluation: Evaluation | Exam }) {
  // cannot offer this in an exam
  if (!evaluation || !('stages' in evaluation)) {
    return <></>;
  }

  const copyText = evaluation.stages.flatMap(stage => stage.tests.map(test => test.name)).join('\n');

  return <FaIconButton onClick={() => navigator.clipboard.writeText(copyText)} icon={['fas', 'copy']} />;
};
