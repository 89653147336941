import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from '../../../theme';
import { ReactNode } from 'react';

interface SightgainThemeProviderProps {
  children: ReactNode;
}

export default function SightgainThemeProvider({ children }: SightgainThemeProviderProps) {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
}
