import { useMemo } from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { formatDateISO } from '@sightgain/core/dates';
import { useAssessmentContext } from '../components/AssessmentContext';
import AssessmentsButton from './AssessmentsButton';
import RunEvaluationButton from './RunEvaluationButton';

export default function Assessments() {
  const { assessment, assessmentFrameworkId } = useAssessmentContext();

  const assessmentFramework = useMemo(() => {
    const framework = assessment.frameworks.find(fw => fw.id === assessmentFrameworkId)!;
    if (framework) {
      return framework;
    }
    return assessment.frameworks[0];
  }, [assessment, assessmentFrameworkId]);

  return (
    <Box sx={{ bgcolor: 'darkBlue1.main', borderRadius: '4px', padding: '20px', marginBottom: '20px' }}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="textStyle5">{assessment.name}</Typography>
            </Grid>
            <Grid item>
              <Typography variant="textStyle1" color="grey2.main">
                {assessmentFramework.title}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="textStyle1" color="grey2.main">
                Capability Score: {assessmentFramework.scores?.defaultMaturityScore || 0}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3} alignItems="center">
            <Grid item>
              <Typography sx={{ paddingRight: 2 }} color="grey2.main" variant="textStyle1">
                License:
              </Typography>
              <Chip label={assessment.license.expires > new Date() ? 'Active' : 'Expired'} />
            </Grid>
            <Grid item>
              <Typography sx={{ paddingRight: 2 }} color="grey2.main" variant="textStyle1">
                Expires:
              </Typography>
              <Typography variant="textStyle1">{formatDateISO(assessment.license.expires)}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={3} alignItems="center">
            <RunEvaluationButton />
            <AssessmentsButton />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
