import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { SOCReportingData } from './interfaces';
import ServiceBase from './ServiceBase';

const dateFields = ['date', 'created', 'updated', 'started', 'ended', 'completed'];

export class ReportingService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, dateFields));
  }

  async reportingData(from: Date, to: Date): Promise<SOCReportingData> {
    const query = gql`
      query ReportingData($from: Date, $to: Date) {
        testResultsReport(from: $from, to: $to) {
          date
          resolution
          resolutionTime
          prevented
          detected
          alerted
          test {
            name
            vendor
            testId
          }
          job {
            name
            evaluationId
          }
        }

        liveFireTrainings {
          analyst
          completed
          instructorScore
          isExam
          name
          scores {
            score
          }
          status
        }

        analystsReport {
          name
          email
          qualified
          training {
            completed {
              id
              name
              progressPercentage
            }
            incomplete {
              id
              name
              progressPercentage
            }
          }
        }
      }
    `;
    return await this.graphql(query, { from, to });
  }
}

const reportingService = new ReportingService();
export default reportingService;
