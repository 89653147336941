import { SRPModule } from '../interfaces';
import Readiness from '../../../images/nav/SightGain Readiness Logo.svg';
import { Role } from '../../../services/interfaces';
import SOC from '../../../pages/Readiness/SOC';
import Overview from '../../../pages/Readiness/Organizational/Overview';
import TestAnalysis from '../../../pages/TestAnalysis';

const readinessModule: SRPModule = {
  name: 'readiness',
  description: 'Constantly evaluate your cybersecurity'
  + ' readiness by safely executing real-world attack simulations in your live environment.',
  img: Readiness,
  pages: [
    {
      name: 'SOC Overview',
      icon: 'columns',
      to: '/readiness/soc',
      // roles: [Role.ADMIN, Role.ANALYST, Role.INSTRUCTOR],
      routes: [{
        path: '/readiness/soc',
        key: 'soc',
        roles: [Role.ADMIN, Role.ANALYST, Role.INSTRUCTOR],
        component: SOC,
      }],
    },
    {
      name: 'Organzation Overview',
      icon: 'columns',
      to: '/readiness/org/overview',
      routes: [{
        path: '/readiness/org/overview',
        key: 'organizational-overview',
        roles: [Role.BUSINESS],
        component: Overview,
      }],
    },
    {
      name: 'Test Analysis',
      icon: 'analytics',
      to: '/readiness/test-analysis',
      // roles: [Role.ADMIN, Role.INSTRUCTOR, Role.ANALYST],
      routes: [{
        path: '/readiness/test-analysis',
        key: 'test-analysis',
        roles: [Role.ADMIN, Role.INSTRUCTOR, Role.ANALYST],
        component: TestAnalysis,
      }],
    },
  ],
};

export default readinessModule;