import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faFighterJet } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormControl from '@mui/material/FormControl';
import { makeStyles } from '@mui/styles';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ChangeEvent } from 'react';
import { JobActor } from '../../../../../../../services/interfaces';
import { ActorComponentProps } from '../../../interfaces';

const useStyles = makeStyles(theme => ({
  formControl: {
    minWidth: 120,
    marginTop: theme.spacing(-2),
  },
  select: {
    width: '200px',
    height: '50px',
    marginLeft: '2px',
  },
  attackerSelect: {
    display: 'flex',
    gap: theme.spacing(1)
  },
  jet: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    height: 50,
    '& svg': {
      height: 25,
      width: 25,
      marginTop: -16,
    }
  }
}));

export default function AttackerSelect({
  nodes, selectChange, actorSetting,
}: ActorComponentProps) {
  const classes = useStyles();

  const handleChange = (e: ChangeEvent<unknown>, node: JobActor) => {
    selectChange({ value: node.id, name: 'attacker' });
  };

  const value = nodes.find(node => node.id === actorSetting);

  return (
    <div className={classes.attackerSelect}>
      <div className={classes.jet}>
        <FontAwesomeIcon icon={faFighterJet as IconDefinition} />
      </div>
      <FormControl className={classes.formControl}>
        <Autocomplete
          className={classes.select}
          options={nodes}
          getOptionLabel={option => option.name}
          renderInput={params => <TextField {...params} label="Attacker Node" />}
          onChange={handleChange}
          value={value}
          size="small"
          disableClearable
        />
      </FormControl>
    </div>
  );
}
