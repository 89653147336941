import appStore from '../../../AppStore';
import { Listener, ListenerCB, ListenerOptions, Message, MessageData } from './intefaces';
import keycloak from '../../../keycloak';
import apolloClient from '../apollo';
import { Setting } from '../interfaces';
import { gql } from '@apollo/client';

class Socket {
  // Websocket client object
  client: WebSocket | undefined;

  // Timer object for heartbeat interval
  ping: NodeJS.Timeout | undefined;

  // callback methods to listen for messages from server
  listeners: Listener[] = [];

  constructor() {
    this._connect();

    // have settings update the cache when changed
    this.subscribe((message: Message) => {
      const setting = JSON.parse(message.body) as Setting;

      const qry = {
        query: gql`
          query Setting($name: String!) {
            setting(name: $name) { name value }
          }
        `,
        variables: { name: setting.name },
      };

      const existing = apolloClient.readQuery(qry);

      if (!existing && existing.SettingData) {
        return;
      }

      const index = (existing.SettingData as Setting[]).findIndex(s => s.name === setting.name);
      const updated = [...existing.Setting];

      if (index === -1) {
        updated.push(setting);
      } else {
        updated.splice(index, 1, setting);
      }

      apolloClient.writeQuery({ ...qry, data: {
        SettingData: updated,
      } });

    }, { type: 'settigns', action: 'update' });
  }

  /**
   * Send a message to the server through the websocket connection
   * @param action type of action wanted from the server
   * @param body content to send to the server (this will be stringified)
   */
  send(action: string, body: any) {
    const {
      clientId, isLoggedIn, access, user,
    } = appStore;

    // wait for the connection and login
    if (this.client?.readyState !== 1 || !keycloak.authenticated || !isLoggedIn) {
      setTimeout(() => this.send(action, body), 1000);
      return;
    }

    this.client?.send(JSON.stringify({
      id: user.id,
      action,
      clientId,
      token: access,
      body,
    }));
  }

  /**
   * Subscribe to the listener and return an unsubscribe function
   * @param callback function to receive message object { type, action, body }
   * @param options { type, action } limit subscription to only specific types or actions
   */
  subscribe(callback: ListenerCB, options: ListenerOptions = {}) {
    const listener = { callback, ...options };
    this.listeners.push(listener);

    return () => this._unsubscribe(listener);
  }

  /**
   * Connect the websocket and setup the events
   */
  _connect() {
    // wait for login
    if (!appStore.isLoggedIn) {
      return this._close();
    }

    // if the client already exists, why are we making a new one.
    if (this.client) {
      return;
    }

    this.client = new WebSocket(`wss://${window.location.host}/ws/`);
    this.client.onopen = () => this._open();
    this.client.onmessage = message => this._message(message);
    this.client.onclose = event => this._close(event.code);
    // this._heartbeat(); // This seems to cause client to reconnect endlessly
  }

  /**
   * A quick hello to the server to tell it who we are
   * The message is actually ignored by the server but connection data is collected
   */
  _open() {
    this.send('connect', 'hello');
  }

  /**
   * Closes and attempts to reconnect to the websocket
   */
  async _close(code = 0) {
    if (code === 4401) {
      await keycloak.updateToken(300);
      if (keycloak.refreshToken) {
        appStore.setTokens(keycloak.refreshToken ?? '', keycloak.token ?? '');
      }
    }
    if (this.ping) {
      clearTimeout(this.ping);
    }
    this.client = undefined;
    setTimeout(() => this._connect(), 5000);
  }

  /**
   * Heartbeat with server to ensure keep alive
   */
  _heartbeat() {
    this.ping = setTimeout(() => {
      // heartbeats are for live connetions
      if (this.client && this.client.readyState < 2) {
        this.client?.close();
      }
    }, 31000);
  }

  /**
   * Calls the listeners
   * @param {object} message message object from back end
   */
  _message(message: MessageData) {
    // postpone our death
    //this._heartbeat(); // this seems to cause client to reconnect endlessly

    // get data for filtering
    const data = JSON.parse(message.data) as Message;
    const { type, action } = data;

    // respond to server's heartbeat
    if (action === 'ping') {
      this.send('pong', '');
      return;
    }

    // call the listeners
    this.listeners
      // only call subscribers that are listening specifically for this message
      .filter(listener => (!listener.type || type === listener.type)
        && (!listener.action || action === listener.action))
      .forEach(({ callback }) => callback(data));
  }

  /**
   * Unsubscribe from the listeners
   * @param listener listener object created by subscribe
   */
  _unsubscribe(listener: Listener) {
    this.listeners = this.listeners.filter(item => item !== listener);
  }
}

const socket = new Socket();
export default socket;
