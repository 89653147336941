import { useParams } from 'react-router';
import CurriculumService from '../../services/CurriculumService';
import { useAbort } from '../../../effects';
import { Redirect } from 'react-router-dom';
import { useState } from 'react';

export default function AnalystsEmail() {
  const { email } = useParams<{ email: string }>();
  const [student, setStudent] = useState<any>(undefined);

  useAbort(() => CurriculumService.student(email), result => {
    setStudent(result);
  });
  
  if (!student) {
    return <></>;
  }

  return <Redirect to={`/curriculum/analysts/${student.id}`} />;
}