import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import Button from '@mui/material/Button';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import appStore from '../../../AppStore';
import systemUsersService from '../../services/SystemUsersService';
import SplunkForm from './Siem/SplunkForm';
import Row from './components/Row';
import { useAbort } from '../../../effects';
import TextField from '../../reusables/TextField';
import { settingsService, siemService } from '../../services';
import { SystemUser } from '../../services/interfaces';
import { SIEMUpdateArgs } from './interfaces';

const useStyles = makeStyles(() => (
  {
    description: {
      marginTop: 16,
      marginBottom: 39,
    },
    button: {
      fontSize: 14,
      color: '#fff',
      borderRadius: 2,
      outline: 'none',
    },
    testBtn: {
      border: 'solid 0.5px #fff',
      backgroundColor: 'rgba(40, 43, 54, 0.62)',
    },
    submitBtn: {
      backgroundColor: '#21bee3',
    },
  }
));

const SYSTEM_USER_TYPE = 'siem';
const FAKE_PASSWORD = [...Array(25).keys()].map(() => '*').join('');

const EMPTY_CONFIG = {
  integrationType: '',
  database: '',
  systemUser: '',
  password: '',
  query: '',
  host: '',
  protocol: 'https',
  type: SYSTEM_USER_TYPE,
};

export default function Siem() {
  const classes = useStyles();
  const [siem, setSIEM] = useState<SystemUser>({ ...EMPTY_CONFIG });
  const [dataIndexes, setDataIndexes] = useState('');
  const [alertIndexes, setAlertIndexes] = useState('');
  const [submitSiem, setSubmitSiem] = useState(false);
  const [testEnabled, setTestEnabled] = useState(false);
  const [loadedIntegrationType, setLoadedIntegrationType] = useState('none');

  useAbort(
    () => Promise.all([
      systemUsersService.list(SYSTEM_USER_TYPE),
      settingsService.find('dataIndexes'),
      settingsService.find('alertIndexes'),
    ]),
    ([[siemUser], dataIndx, alertIndx]) => {
      setSIEM({ ...EMPTY_CONFIG, ...(siemUser && {
        ...siemUser,
        password: FAKE_PASSWORD,
      }) });
      
      setLoadedIntegrationType(siemUser.integrationType as string);

      setDataIndexes(dataIndx.value);
      setAlertIndexes(alertIndx.value);
    },
  );

  const update = ({ alertIndexes: alertIndx, dataIndexes: dataIndx, ...value }: SIEMUpdateArgs) => {

    setSIEM(p => ({ ...p, ...value }));
    if (dataIndx !== undefined) {
      setDataIndexes(dataIndx);
    }
    if (alertIndx !== undefined) {
      setAlertIndexes(alertIndx);
    }
  };

  const save = async () => {
    appStore.beginLoading();
    
    try {
      let newConfig: SystemUser = { ...EMPTY_CONFIG, updatedAt: new Date() };

      await systemUsersService.remove('siem');

      if (siem.integrationType) {  
        newConfig = await systemUsersService.createOrUpdate(siem);
        newConfig.password = FAKE_PASSWORD;
      }

      setLoadedIntegrationType(siem.integrationType as string);
      await Promise.all([
        settingsService.save({ name: 'dataIndexes', value: dataIndexes }),
        settingsService.save({ name: 'alertIndexes', value: alertIndexes })
      ]);

      setSIEM(newConfig);
      setSubmitSiem(false);
      appStore.success('Configuration Saved');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const testConnection = async () => {
    appStore.beginLoading();
    try {
      await siemService.testConnection(siem);
      appStore.success('Connection Established Successfully');
      setSubmitSiem(true);
    } catch (err) {
      appStore.error(err);
      setSubmitSiem(false);
    }
    appStore.endLoading();
  };

  const handleChange = ({ target: { value } }: { target: { value: string }}) => {
    update({ integrationType: value === 'none' ? '' : value });
  
    if (value === 'none' && value !== loadedIntegrationType) {
      setSubmitSiem(true);
      setTestEnabled(false);
    } else {
      setSubmitSiem(false);
    }
  };

  return (
    <div>
      <Typography variant="h3">SIEM System Integration</Typography>
      <Typography variant="body1" className={classes.description}>
        Configure a SIEM system integration.
      </Typography>
      <Row>
        <TextField
          label="Integration Type"
          value={siem.integrationType || 'none'}
          type="select"
          onChange={handleChange}
        >
          <MenuItem value="none">
            None
          </MenuItem>
          <MenuItem value="splunk">
            Splunk
          </MenuItem>
        </TextField>
      </Row>

      {siem.integrationType === 'splunk' && (
        <SplunkForm
          siemUser={siem}
          dataIndexes={dataIndexes}
          alertIndexes={alertIndexes}
          update={update}
          setTestEnabled={setTestEnabled}
        />
      )}

      <Row>
        <Button
          className={clsx(classes.button, classes.testBtn)}
          onClick={testConnection}
          disabled={!testEnabled}
          variant="contained"
          endIcon={<FontAwesomeIcon size="sm" icon={['fal', 'arrow-right']} />}
        >
          Test Connection
        </Button>
        <Button
          className={clsx(classes.button, classes.submitBtn)}
          onClick={save}
          disabled={!submitSiem}
          variant="contained"
          endIcon={<FontAwesomeIcon size="sm" icon={['fal', 'arrow-right']} />}
        >
          Submit
        </Button>
      </Row>
    </div>
  );
}
