import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { IconProp } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles(() => ({
  tag: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.25,
  },
}));

interface StackedIconBadgeProps {
  children: ReactNode;
  className?: string;
  icon: IconProp;
  wrapper: IconProp;
}

export default function StackedIconBadge({ icon, wrapper, children, className = '' }: StackedIconBadgeProps) {
  const classes = useStyles();

  return (
    <span className={`${classes.tag} ${className}`}>
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon icon={wrapper} size="lg" color="#117fba" />
        <FontAwesomeIcon icon={icon} size="xs" color="#117fba" />
      </span>
      &nbsp;
      {children}
    </span>
  );
}
