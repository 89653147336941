import { makeStyles } from '@mui/styles';
import { ElementType, ReactNode } from 'react';

const useStyles = makeStyles(theme => ({
  button: {
    all: 'unset',
    color: theme.palette.primary.main,
  },
  component: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

interface ButtonLinkProps {
  component?: ElementType<any>;
  className?: string;
  children?: ReactNode;
  onClick: () => void;
}

export default function ButtonLink({ component = 'a', className = '', onClick, children }: ButtonLinkProps) {
  const classes = useStyles();

  const Comp = component;

  return (
    <div className={className}>
      <button onClick={onClick} className={classes.button} type="button">
        <Comp className={classes.component}>{children}</Comp>
      </button>
    </div>
  );
}
