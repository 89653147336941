import { Button, Grid } from '@mui/material';
import { useState } from 'react';
import Modal from '../../../reusables/Modal';
import AssessmentViews from './AssessmentViews/AssessmentViews';
import { AssessmentView } from './AssessmentViews/interfaces';

export default function AssessmentsButton() {
  const [open, setOpen] = useState(false);

  return (
    <Grid item>
      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
        Assessments
      </Button>
      {open && (
        <Modal open={true} onClose={() => setOpen(false)} maxWidth="lg" fullWidth>
          <AssessmentViews onExit={() => setOpen(false)} view={AssessmentView.LIST} />
        </Modal>
      )}
    </Grid>
  );
}