import BarChartMetric from './BarChartMetric';

interface PassFailBarChartMetricProps {
  title: string;
  /**
   * Array containing number of passed and faild entries. Arrays must
   * have the same length.
   */
  pass: number[];
  fail: number[];
  /**
   * Labels to display under each bar. Must have the same length
   * as pass and fail.
   */
  labels: string[];
}

export default function PassFailBarChartMetric({ title, pass, fail, labels }: PassFailBarChartMetricProps) {
  return (
    <BarChartMetric
      title={title}
      labels={labels}
      colors={['#2ecff5', '#f46043']}
      series={[
        {
          name: 'Pass',
          data: pass,
        },
        {
          name: 'Fail',
          data: fail,
        },
      ]}
    />
  );
}
