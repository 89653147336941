import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { round } from '@sightgain/core/calculations';
import { LargeNumericMetric, TierBadge } from '../../../reusables/scl';
import { ActivityLFT } from '../../../reusables/useActivityWatcher/interfaces';

const useStyles = makeStyles(() => ({
  wrapperEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
  },
  wrapperLoading: {
    display: 'flex',
    flexDirection: 'column',
    height: 250,
  },
  wrapper: {
    padding: 30,
    width: '100%',
    background: 'rgba(255, 255, 255, 0.036)',
    borderRadius: 6,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 14,
  },
  statsWrapper: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function SelectedTraining({ training }: { training?: ActivityLFT }) {
  const classes = useStyles();

  if (!(training && training.score)) {
    return (
      <div className={clsx(classes.wrapper, classes.wrapperEmpty)}>
        <Typography variant="body1">Select a completed item below to view your stats</Typography>
      </div>
    );
  }

  const stats = {
    composite: round(training.score, 0),
    event: round(training.eventScore, 0),
    precision: round(training.precisionScore, 0),
    timeliness: round(training.responseScore, 0),
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <TierBadge tier="livefire" />
        <Button component={Link} to={`/curriculum/evaluations/lft/${training.id}`} color="primary">
          {training.isExam ? 'Exam' : 'Evaluation'} Details &nbsp;
          <FontAwesomeIcon icon={['fal', 'arrow-right']} />
        </Button>
      </div>
      <Typography variant="h2">{training.job.name}</Typography>
      <div className={classes.statsWrapper}>
        <LargeNumericMetric value={stats.composite} label="Composite Score" />
        <Divider orientation="vertical" flexItem />
        <LargeNumericMetric value={stats.event} label="Event Score" />
        <Divider orientation="vertical" flexItem />
        <LargeNumericMetric value={stats.precision} label="Precision Score" />
        <Divider orientation="vertical" flexItem />
        <LargeNumericMetric value={stats.timeliness} label="Timeliness Score" />
      </div>
    </div>
  );
}
