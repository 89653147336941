import { Typography } from '@mui/material';
import emptybox from '../../../../images/emptybox.svg';

export default function None() {
  return (
    <div style={{
      height: '100%',
      flexDirection: 'column',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: 12,
      flex: 1
    }}>
      <img src={emptybox} alt="no recommendations"/>
      <Typography variant="h5">You have no recommendations</Typography>
    </div>
  );
}