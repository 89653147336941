import { makeStyles } from '@mui/styles';
import { Avatar, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contentRight: {
    marginLeft: 12,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  topRowLabel: {
    margin: 0,
    fontFamily: 'Lexend',
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: '0.6px',
    color: '#a7adb5',
    textTransform: 'uppercase',
  },
  bottomRow: {

  },
  avatar: {
    width: 80,
    height: 80,
  },
}));

export default function CardUserInfo({
  image,
  topRow,
  bottomRow,
}: CardUserInfoProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Avatar className={classes.avatar} src={image} alt="User Icon" />
      <div className={classes.contentRight}>
        <span className={classes.topRowLabel}>{topRow}</span>
        <Typography variant="body1">{bottomRow}</Typography>
      </div>
    </div>
  );
}

type CardUserInfoProps = {
  image: string;
  // Text to display in top and bottom rows respectively
  topRow: string;
  bottomRow: string;
};
