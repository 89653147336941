import { Button, Popover } from '@mui/material';
import { AddSharp } from '@mui/icons-material';
import { useState } from 'react';
import { DateFilter, FilterControls, NumericFilter, StringFilter, SelectFilter } from './FilterControls';
import { makeStyles } from '@mui/styles';
import { Filter } from './TileDetail';
import { useFilterContext } from './FilterContext';

const useStyles = makeStyles(() => ({
  button: {
    display: 'flex',
    justifyContent: 'flex-start',
    paddingLeft: 12,
    paddingRight: 12,
  },
  popover: {
    padding: 20,
    display: 'flex',
    gap: 32,
    background: '#303F52'
  }
}));

export interface FilterSource {
  name: string;
  title: string;
  filterType: string;
  menuItems?: {
    value: any;
    text: string;
  }[];
}

export default function FilterButton({ sources }: { sources: FilterSource[] }) {
  const classes = useStyles();
  const { filters, setFilters } = useFilterContext();
  const [selectedName, setSelectedName] = useState<null | string>(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [filterComponent, setFilterComponent] = useState<any>(null);

  const [newFilter, setNewFilter] = useState<Filter>(() => {
    return {
      column: '',
      title: '',
      filter: () => false,
      value: '',
    };
  });

  const handleFilterChange
    = (filterFunction: (item: string) => boolean, value: string, column: string, title: string) => {
      setNewFilter(prevFilter => ({
        ...prevFilter,
        filter: filterFunction,
        value,
        column,
        title
      }));
    };

  const handleClick = (event:any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setFilterComponent(null);
    setSelectedName(null);
  };

  const handleSelect = (source: FilterSource) => {
    setSelectedName(source.name);
    switch (source.filterType) {
      case 'string':
        setFilterComponent(<StringFilter onChange={handleFilterChange} source={source} />);
        break;
      case 'select':
        setFilterComponent(
          <SelectFilter
            onChange={handleFilterChange}
            source={source}
            menuItems={source.menuItems}
          />
        );
        break;
      case 'numeric':
        setFilterComponent(<NumericFilter onChange={handleFilterChange} source={source} />);
        break;
      case 'date':
        setFilterComponent(<DateFilter onChange={handleFilterChange} source={source} />);
        break;
      default:
        setFilterComponent(null);
    }
  };

  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  const handleApply = () => {
    setFilters([...filters, newFilter]);
    handleClose();
  };

  return (
    <div>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        variant='outlined'
        style={{ width: 140 }}
      >
        Add Filter &nbsp; <AddSharp />
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <div className={classes.popover}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {sources.map(source =>(
              <Button
                key={source.name}
                onClick={() => handleSelect(source)}
                className={classes.button}
                color={selectedName === source.name ? 'secondary' : 'primary' as any}
              >
                {source.title}
              </Button>
            ))}
          </div>
          {filterComponent && (
            <FilterControls onCancel={handleClose} onApply={handleApply}>
              {filterComponent}
            </FilterControls>
          )}
        </div>
      </Popover>
    </div>
  );
}
