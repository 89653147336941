import { useCallback } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { settingsService, vendorService } from '../../services';
import appStore from '../../../AppStore';
import useSocketListener from '../../reusables/useSocketListener';

const useStyles = makeStyles(() => ({
  refreshButton: {
    fontSize: 14,
    color: '#fff',
    borderRadius: 2,
    outline: 'none',
    backgroundColor: '#f46043',
  },
}));

function statusMessage(status?: string, updatedAt?: Date): string {
  if (!(status || updatedAt)) {
    return 'Checking for last refresh status...';
  }

  // refresh has never run
  if (!status) {
    return 'Refresh with Security Instrumentation Provider has not been started';
  }

  if (status === 'running') {
    return `Refresh has been running since ${updatedAt}`;
  }

  return `Last refresh ${status === 'failure' ? 'failed' : 'succeeded' } on ${updatedAt}`;
}

export default function RefreshSync({ type }: { type: string }) {
  const classes = useStyles();
  const callback = useCallback(async (body: string) => {
    if (body !== 'SyncRefresh') {
      return;
    }

    const resp = await settingsService.find(`${type}SyncRefresh`);
    return resp;
  }, [type]);

  const status = useSocketListener('settings', 'update', callback, 'SyncRefresh');

  const refresh = async () => {
    appStore.beginLoading();
    try {
      await vendorService.refresh();
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const message = statusMessage(status?.value, status?.updatedAt);

  return (
    <div>
      <Typography style={{ marginBottom: '20px' }}>Check for new Actions</Typography>
      <Button
        className={classes.refreshButton}
        onClick={refresh}
        variant="contained"
        color="secondary"
        endIcon={<FontAwesomeIcon size="sm" icon={['fal', 'sync-alt']} />}
      >
        Refresh Now
      </Button>
      <p>
        {message}
      </p>
    </div>
  );
}
