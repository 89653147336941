import { createContext, ReactNode, useContext } from 'react';
import { FrameworkContextInitialState } from './interfaces';

const FrameworkNewContext = createContext({} as FrameworkContextInitialState);

export function FrameworkContext({ initialState, children }
: { initialState: FrameworkContextInitialState, children: ReactNode}) {
  return (
    <FrameworkNewContext.Provider value={initialState}>
      { children }
    </FrameworkNewContext.Provider>
  );
}

export const useFrameworkContext = () => useContext<FrameworkContextInitialState>(FrameworkNewContext);
