import {
  Box, Paper, Popover, Typography, IconButton,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';
import { GridCellParams } from '@mui/x-data-grid';

function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight
    || element.scrollWidth > element.clientWidth
  );
}

const useStyles = makeStyles(() => createStyles({
  root: {
    alignItems: 'center',
    lineHeight: '24px',
    width: '100%',
    height: '100%',
    position: 'relative',
    display: 'flex',
    '& .cellValue': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  message: {
    overflowWrap: 'anywhere',
    maxHeight: 350,
  },
}));

const CellExpand = React.memo(function CellExpandSource(props: { width: number, value: string }) {
  const { value, width } = props;
  const classes = useStyles();

  const wrapper = React.useRef(null);
  const cellValueRef = React.useRef(null);
  const [anchorEl, setAnchorEl] = React.useState<Element>();

  const [isCurrentlyOverflown, setCurrentlyOverflown] = React.useState(false);

  React.useEffect(() => {
    if (!cellValueRef?.current) {
      return;
    }
    setCurrentlyOverflown(isOverflown(cellValueRef.current));
  }, [isCurrentlyOverflown, value]);

  const handleClick = (event: React.MouseEvent) => {
    setAnchorEl(event.currentTarget as Element);
  };

  const handleClose = () => {
    setAnchorEl(undefined);
  };
  const open = Boolean(anchorEl);

  return (
    <div ref={wrapper} className={classes.root}>
      <div ref={cellValueRef} className="cellValue">
        {value}
      </div>
      {isCurrentlyOverflown && (
        <>
          <IconButton aria-label="expand" color="primary" onClick={handleClick} size="large">
            <ExpandMoreIcon />
          </IconButton>
          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <Paper elevation={1}>
              <Box width={width} p={2}>
                <Typography variant="body2" className={classes.message}>{value}</Typography>
              </Box>
            </Paper>
          </Popover>
        </>
      )}
    </div>
  );
});

export default function renderCellExpand(params: GridCellParams) {
  return (
    <CellExpand
      value={params.value ? params.value.toString() : ''}
      width={450}
    />
  );
}
