import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { ReactNode } from 'react';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  titleWrapper: {
    marginBottom: 12,
  },
  labelsWrapper: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    color: '#ffffff',
    textTransform: 'uppercase',
  },
  label: {
    marginLeft: 24,
  },
  chartWrapper: {
    height: 'calc(100% - 43px)',
    paddingTop: 13,
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    overflow: 'hidden',
    borderRadius: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& > :not(div[role="progressbar"])': {
      flex: 1,
      height: '100%',
    },
  },
}));

interface ChartWrapperProps {
  title: any;
  chartClassName?: string;
  children: ReactNode;
}

export default function ChartWrapper({ title, children, chartClassName = '' }: ChartWrapperProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.titleWrapper}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      </div>
      <div className={clsx(classes.chartWrapper, chartClassName)}>{children}</div>
    </div>
  );
}
