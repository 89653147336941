import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';

const useStyles = makeStyles(theme => ({
  date: {
    verticalAlign: 'baseline',
    width: 120,
    '& > div > input': {
      padding: 0,
    },
  },
  icon: {
    marginLeft: -35,
    color: '#2ecff5',
  },
  pickers: {
    display: 'flex',
    flexDirection: 'row',
    gap: 20,
    marginLeft: 12
  },
  select: {
    display: 'flex',
    alignItems: 'center',
  }
}));

interface PickerProps {
  value: Date | undefined;
  onChange: (date: Date) => void;
  minDate: Date;
}

export default function Picker({ value, onChange, minDate }: PickerProps) {
  const classes = useStyles();
  const [openPicker, setOpenPicker] = useState(false);
  const [openTimePicker, setOpenTimePicker] = useState(false);

  return (
    <div className={classes.pickers}>
      <div>
          Date:
        <br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            format="MM/dd/yyyy"
            value={value}
            onChange={d => onChange(d as Date)}
            open={openPicker}
            onClose={() => setOpenPicker(false)}
            className={classes.date}
            minDate={minDate}
            onOpen={() => setOpenPicker(true)}
          />
        </LocalizationProvider>
      </div>
      <div>
          Time:
        <br />
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <TimePicker
            value={value}
            onChange={d => onChange(d as Date)}
            open={openTimePicker}
            onClose={() => setOpenTimePicker(false)}
            className={classes.date}
            onOpen={() => setOpenPicker(true)}
          />
        </LocalizationProvider>
      </div>
    </div>
  );
}
