import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { RoiInput, RoiResult } from './interfaces';
import ServiceBase from './ServiceBase';

const typeRoiResult = `
  {
    securityPortfolioValue
    roiRatio
    avgTechnologyValue
    preventionRate
    detectionRate
    actionedTests
    missedTests
    updatedAt
    technologyCostBenefit {
      labels
      cost
      value
    }
    technologyPerformanceToRoi {
      labels
      cost
      value
      roi
      prevention
      detection
    }
    technologyRiskReduction {
      labels
      series {
        name
        data
        limit
      }
    }
    investmentActions {
      labels
      ratios {
        name
        data {
          value
          total
        }
      }
    }
  }
`;

class RoiService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['updatedAt']));
  }

  async roi(input: RoiInput, fields = typeRoiResult): Promise<RoiResult> {
    const { roi } = await this.graphql(
      gql`query Roi($input: RoiInput) { roi(input: $input) ${fields} }`,
      { input },
      'no-cache',
    );
    return roi;
  }

  async refreshRoi(input: RoiInput, fields = typeRoiResult): Promise<RoiResult> {
    const { refreshRoi } = await this.graphql(
      gql`mutation RoiRefresh($input: RoiInput) { refreshRoi(input: $input) ${fields} }`,
      { input },
    );
    return refreshRoi;
  }
}

const roiService = new RoiService();
export default roiService;
