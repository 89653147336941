import { makeStyles, withStyles } from '@mui/styles';
import { Button, Step, StepConnector, StepContent, StepLabel, Stepper } from '@mui/material';
import { useContext } from 'react';
import EvaluationWindowContext from '../EvaluationWindowContext';
import clsx from 'clsx';
import { EvaluationRunner, EvaluationStep } from '../../interfaces';

const useStyles = makeStyles(theme => ({
  stepper: {
    width: '100%',
    marginTop: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(0),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  hidden: {
    display: 'none',
  },
  stepBtn: {
    display: 'block',
    float: 'left',
  },
}));

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#2196F3',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#2196F3',
    },
  },
  line: {},
})(StepConnector);

export default function Steps({ runner, steps }: StepsProps) {
  const classes = useStyles();
  const {
    activeStep, disableNextBtn, label, selected, handleSetActiveStep, handleDisableNextBtn, handleRunEvaluation
  } = useContext(EvaluationWindowContext);

  const lastStep = steps.length - 1;

  const handleNextStep = () => {
    if (activeStep === lastStep) {
      handleRunEvaluation();
      return;
    }

    handleSetActiveStep(activeStep + 1);
    handleDisableNextBtn(true);
  };

  return (
    <div className={classes.stepper}>
      <Stepper
        activeStep={activeStep}
        orientation="vertical"
        connector={<QontoConnector />}
        style={{ backgroundColor: '#344356' }}
      >
        {steps.map(Component => (
          <Step key={`scenario-${Component.label.split(' ').join('-').toLowerCase()}`}>
            <StepLabel>
              {Component.label.replace(/\{label\}/g, label)}
              {' '}
              {selected?.id && Component.afterLabel && Component.afterLabel(selected)}
            </StepLabel>
            {selected?.id && (
              <StepContent>
                <Component runner={runner} />
                <div className={classes.actionsContainer}>
                  <div style={{ width: '100%' }}>
                    <Button
                      className={clsx([classes.button, classes.stepBtn, activeStep === 0 && classes.hidden])}
                      variant="outlined"
                      disabled={activeStep === 0}
                      onClick={() => handleSetActiveStep(Math.max(activeStep - 1, 0))}
                      id="backButton"
                    >
                      Back
                    </Button>
                    <Button
                      className={clsx([classes.button, classes.stepBtn])}
                      disabled={disableNextBtn}
                      onClick={handleNextStep}
                      id="nextButton"
                      variant="contained"
                      color="primary"
                    >
                      { activeStep === lastStep ? 'Start' : 'Next' }
                    </Button>
                  </div>
                </div>
              </StepContent>
            )}
          </Step>
        ))}
      </Stepper>
    </div>
  );
}

interface StepsProps {
  runner: EvaluationRunner;
  steps: EvaluationStep[];
}