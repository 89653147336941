import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

const useStyles = makeStyles({
  cardButton: {
    cursor: 'pointer',
    display: 'flex',
    flex: 1,
  },
});

export default function CardButton({ children, onClick }: CardButtonProps) {
  const classes = useStyles();
  return (
    <div
      role="button"
      tabIndex={0}
      onClick={onClick}
      onKeyPress={onClick}
      className={classes.cardButton}
    >
      {children}
    </div>
  );
}

type CardButtonProps = {
  children: ReactNode;
  onClick: () => unknown;
};
