import { addCommas, capitalize } from '@sightgain/core/strings';
import { DashboardMixedChartMetric } from '../../../reusables/metrics';
import { RoiPerformanceToROI } from '../../../services/interfaces';

export default function TechnologyPerformanceToRoi({ performanceToRoi }: { performanceToRoi: RoiPerformanceToROI }) {
  const { cost, labels, detection, prevention, roi, value } = performanceToRoi;

  const series = [
    { name: 'Prevention', type: 'column', data: prevention },
    { name: 'Detection', type: 'column', data: detection },
    { name: 'ROI', type: 'line', data: roi },
    { name: 'Investment', type: 'area', data: cost },
    { name: 'Risk Reduction', type: 'area', data: value, color: '#9256FB' },
  ];

  return (
    <DashboardMixedChartMetric
      title="Technology Performance to ROI"
      series={series}
      labels={labels.map(l => capitalize(l))}
      valueAxis={{
        ticks: 6,
        series: [
          {
            seriesName: 'Prevention',
            show: true,
            min: 0,
            max: 100,
            decimalsInFloat: 0,
          },
          {
            seriesName: 'Detection',
            show: false,
            min: 0,
            max: 100,
          },
          {
            seriesName: 'ROI',
            show: false,
            min: 0,
            max: Math.max(...roi),
          },
          {
            seriesName: 'Investment',
            show: false,
            min: 0,
            max: Math.max(...cost),
          },
          {
            seriesName: 'Risk Reduction',
            show: false,
            min: 0,
            max: Math.max(...value),
          },
        ],
      }}
      labelAxis={{
        formatter: v => v,
      }}
      tooltip={{
        formatterY(y, { index }) {
          if (!y) return y;

          // ROI
          if (index === 2) {
            return Math.round(y * 100) / 100;
          }

          // Prevention / Detection
          const rounded = Math.round(y);
          if (index < 3) {
            return `${addCommas(rounded)}`;
          }

          // Investment / Risk Reduction
          return `$${addCommas(rounded)}`;
        },
      }}
      stroke={{
        width: [5, 5, 5],
        curve: ['straight', 'straight', 'smooth', 'smooth', 'smooth'],
      }}
      fillOpacity={[1, 1, 1]}
    />
  );
}
