import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import Tabs from '../../Tabs';

const useStyles = makeStyles({
  tabsWrapper: {
    marginTop: 20,
    marginBottom: 20,
  },
});

export default function PreviewTabs() {
  const classes = useStyles();
  const [selected, setSelected] = useState(3);

  return (
    <>
      <h2> Tabs </h2>
      <div className={classes.tabsWrapper}>
        <Tabs
          selected={selected}
          onSelect={n => setSelected(n)}
          labels={['Courses', 'Knowledge', 'Skills', 'Abilities']}
        />
      </div>
    </>
  );
}