import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo } from 'react';
import { InputAdornment } from '@mui/material';
import Row from '../components/Row';
import TextField from '../../../reusables/TextField';
import { TicketingSetting } from '../interfaces';
import URLField from '../../../reusables/URLField';

export default function APITicketingForm({ data, update, setTestEnabled, enableUsername }: APITicketingFormProps) {
  const {
    host, protocol, port, password, systemUser,
  } = data;

  // Allow Test Connection when host, port and API Key are supplied
  useEffect(() => {
    setTestEnabled(!!(host && protocol && port && password && (systemUser || !enableUsername)));
  }, [host, protocol, port, password, systemUser, enableUsername, setTestEnabled]);

  const passwordLabel = useMemo(() => enableUsername ? 'Password' : 'API Key', [enableUsername]);

  return (
    <>
      <Row>
        <URLField
          host={host}
          protocol={protocol}
          port={port}
          onChange={update}
        />
      </Row>
      {enableUsername && (
        <Row>
          <TextField
            error={!systemUser && 'Username is required'}
            onChange={e => update({ systemUser: e.target.value })}
            value={systemUser}
            startAdornment={(
              <InputAdornment position="start">
                <FontAwesomeIcon size="lg" icon={['fal', 'user']} />
              </InputAdornment>
            )}
            label="Username"
          />
        </Row>
      )}
      <Row>
        <TextField
          error={!password && `${passwordLabel} is required`}
          onChange={e => update({ password: e.target.value })}
          type="password"
          value={password}
          startAdornment={(
            <InputAdornment position="start">
              <FontAwesomeIcon size="lg" icon={['fal', 'lock']} />
            </InputAdornment>
          )}
          label={passwordLabel}
        />
      </Row>
    </>
  );
}

interface APITicketingFormProps {
  data: TicketingSetting;
  update: (v: Partial<TicketingSetting>) => void;
  setTestEnabled: (v: boolean) => void;
  enableUsername?: boolean;
}
