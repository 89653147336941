import { useState } from 'react';
import { Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAbort } from '../../../../../effects';
import AddProductDialog from './AddProductDialog';
import { jobsService, productsService } from '../../../../services';
import { BakeOffProduct } from '../interfaces';

export default function AddProduct({ onSubmit, exclude = [] }: AddProductProps) {
  const [open, setOpen] = useState(false);
  const [products, setProducts] = useState<BakeOffProduct[]>();

  useAbort(async () => await Promise.all([
    productsService.list('{ name type }'),
    jobsService.products(),
  ]), ([current, previous]) => {
    const unique = [...current, ...previous].reduce((a, b) => {
      if (!a.find(p => p.name === b.name && p.type === b.type)) {
        a.push(b);
      }

      return a;
    }, [] as BakeOffProduct[]);

    setProducts(unique);
  });

  const handleOnSubmit = (item: BakeOffProduct) => {
    onSubmit(item);
    setOpen(false);
  };

  const filteredList = exclude && products
    ? products.filter(item => !exclude.find(excluded => 
      excluded.name === item.name && excluded.type === item.type
    )) : products;

  if (!filteredList?.length) {
    return null;
  }

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setOpen(true)}
        endIcon={<FontAwesomeIcon icon={['fal', 'plus']} />}
      >
          Add a product
      </Button>
      {open && (
        <AddProductDialog
          open={open}
          products={filteredList}
          handleClose={() => setOpen(false)}
          onSubmit={handleOnSubmit}
        />
      )}
    </div>
  );
}

type AddProductProps = {
  onSubmit: (p: BakeOffProduct) => unknown;
  exclude: BakeOffProduct[];
};
