import Chart from 'react-apexcharts';
import CircularProgress from '@mui/material/CircularProgress';
import ChartWrapper from './ChartWrapper';
import { allColors } from './utils';
import { FC } from 'react';

type Direction = 'horizontal' | 'vertical';

type Series = {
  name: string;
  data: number[];
};

type ValueAxis = {
  formatter?: (value: number) => string;
  ticks?: number;
};

type LabelAxis = {
  formatter?: (value: string) => string;
};

type DashboardBarChartMetricProps = {
  title: string;
  direction: Direction;
  labels: string[];
  series: Series[];
  valueAxis?: ValueAxis;
  labelAxis?: LabelAxis;
};

const DashboardBarChartMetric: FC<DashboardBarChartMetricProps> = ({
  title,
  direction,
  labels,
  series,
  valueAxis = { formatter: v => v, ticks: 4 },
  labelAxis = { formatter: v => v },
}) => {
  const colors = allColors.slice(0, series.length);
  const horizontal = direction === 'horizontal';

  const valueAxisOptions = {
    labels: {
      formatter: valueAxis.formatter,
      style: {
        colors: labels.map(() => '#a7adb5'),
        fontSize: 12,
        fontFamily: 'Lexend',
        fontWeight: 'normal',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    tickAmount: valueAxis.ticks,
  };

  const labelAxisOptions = {
    labels: {
      formatter: labelAxis.formatter,
      style: {
        colors: labels.map(() => '#a7adb5'),
        fontSize: 12,
        fontFamily: 'Lexend',
        fontWeight: 'normal',
      },
    },
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  };

  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal,
      },
    },
    stroke: {
      show: true,
      // Don't add spacing between bars when we have a lot of bars as they are less visible that way
      //  For larger bars though, it is more visible to have a bit of spacing
      width: labels.length >= 5 ? 0 : 3,
      colors: ['transparent'],
    },
    grid: {
      show: false,
    },
    labels,
    colors,
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left',
      fontSize: '12px',
      fontFamily: 'Lexend',
      fontWeight: 'normal',
      labels: {
        colors: ['#fff'],
      },
      markers: {
        radius: 12,
      },
      itemMargin: {
        horizontal: 18,
      },
    },
    xaxis: horizontal ? valueAxisOptions : labelAxisOptions,
    yaxis: horizontal ? labelAxisOptions : valueAxisOptions,
    tooltip: {
      theme: 'dark',
      y: {
        formatter: valueAxis.formatter,
      },
    },
  };

  return (
    <ChartWrapper title={title}>
      {series.length === 0 ? (
        <CircularProgress disableShrink />
      ) : (
        <Chart type="bar" series={series} options={options} height="100%" />
      )}
    </ChartWrapper>
  );
};

export default DashboardBarChartMetric;
