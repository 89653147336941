import { makeStyles } from '@mui/styles';
import {
  Typography,
} from '@mui/material';
import EventField from './EventField';
import { HostEvent } from '../../../../services/interfaces';
import HighlightedTextField from '../../../../reusables/HighlightedTextField';
import { eventLanguage } from './parseEvent';

const useStyles = makeStyles(() => ({
  fields: {
    marginTop: 36,
    display: 'flex',
    gap: 20,
    justifyContent: 'space-between',
    '& > div': {
      flex: 1,
    },
  },
}));

export default function SystemHostEvent({ data }: { data: HostEvent }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.fields}>
        <EventField label="Event Record ID" value={data.recordId} disp={!!data.recordId} />
        <EventField label="Hostname" value={data.name} />
        <EventField label="Host IP" value={data.ip} />
      </div>
      <div className={classes.fields}>
        <EventField label="Event Provider" value={data.provider} />
        <EventField label="Event Channel" value={data.channel} />
        <EventField label="Event ID" value={data.eventId} />
      </div>
      <div className={classes.fields} style={{ flexDirection: 'column' }}>
        <Typography variant="h5" style={{ marginBottom: 15 }}>Event</Typography>
        <HighlightedTextField data={data.raw} language={eventLanguage(data.raw)} />
      </div>
    </div>
  );
}
