import { Redirect } from 'react-router-dom';
import appStore from '../../../AppStore';
import { Role } from '../../services/interfaces';
import { getModulePath } from '../Navigation/utils';

export default function DefaultPage() {
  if (appStore.isLimitedLicense) {
    return <Redirect to='/risk/scoring' />;
  }

  if (appStore.activeRole === Role.FRAMEWORK_ANALYST) {
    return <Redirect to={getModulePath('risk')} />;
  }

  if (appStore.isLoggedIn) {
    return <Redirect to={getModulePath('readiness')} />;
  }

  return <Redirect to="/" />;
}
