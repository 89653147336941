import { useCallback, useMemo } from 'react';
import useSocketListener from './useSocketListener';

// TODO: change all references from useWatcher to useSocketListener instead

/**
 * Waits for websocket messages for a specific type and action
 * and then calls a refresh method and returns the results using an
 * update to the state
 * @deprecated use useSocketListener instead
 * @param type SRP message type
 * @param action SRP message action
 * @param callback callback back when a message is received
 * @param initialArgs initial body value to make the initial call
 * @param initialValue initial value while loading results
 */
export default function useWatcher<T>(
  type: string,
  action: string,
  callback: (body: any) => Promise<T | undefined>,
  initialArgs: any = '',
  initialValue?: T | (() => T),
): T | undefined {

  // Create unchanging versions of variables for backwards compatibility
  const callbackFn = useCallback(callback, []);
  const initArgs = useMemo(() => initialArgs, []);
  const value = useMemo(() => initialValue, []);

  return useSocketListener(type, action, callbackFn, initArgs, value);
}