import { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { truncate } from '@sightgain/core/strings';
import { ProgressBadge } from '../../../reusables/Badges';
import { ActivityLFT } from '../../../reusables/useActivityWatcher/interfaces';
import { TrainingGroupLevel, TrainingGroupPreview } from '../../../services/interfaces';
import { Card, CardBackground, CardButton, CardContent, CardDetails, CardLink, CardProgress } from '../components/Card';
import CardTag from '../components/Card/CardTag';
import { resolveCurriculumImageForDemo } from '../utils';

const useStyles = makeStyles(() => ({
  activityCard: {
    marginBottom: 12,
  },
  cardRightContent: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignContent: 'center',
    gap: 10,
  },
}));

export default function ActivityCard({ completed = false, type, item, selected = false, onClick }: ActivityCardProps) {
  const classes = useStyles();

  // get the path for the incomplete items
  const path = {
    evaluations: `learning/livefire/${item.id}`,
    courses: `learning/activity/course/${item.id}`,
    curriculums: `learning/${item.id}`,
    exams: `evaluations/${item.id}`,
  }[type];

  const handleClick = () => {
    if (onClick) {
      onClick(item.id);
    }
  };

  const { difficulty, progress: tProgress, title, curriculums = [] } = item as TrainingGroupPreview;
  const { job, status, score, instructorScore } = item as ActivityLFT;

  const isCurriculum = type === 'curriculums';
  const isEvaluation = type === 'evaluations';
  const isCourse = type === 'courses';
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const isExam = type === 'exams';

  const level = difficulty ?? TrainingGroupLevel.LiveFireComment;
  const label = title ?? job?.name;
  const progress = tProgress ?? (completed ? 100 : 0);
  const image = isCurriculum ? resolveCurriculumImageForDemo(title) : undefined;

  let parentText = '';
  let parentInfo = '';
  if (isCourse) {
    parentText = curriculums.length === 1 ? truncate(curriculums[0].title, 25) : `${curriculums.length} Curriculums`;
    parentInfo = curriculums.length === 1 ? '' : curriculums.map(c => c.title).join('\n');
  }

  let friendlyStatus = status;

  if (score && completed) {
    friendlyStatus = 'Failed';
    if (Math.max(...[instructorScore, score]) >= 0.8) {
      friendlyStatus = 'Passed';
    }
  }

  return (
    <Wrapper completed={completed} to={`/curriculum/${path}`} onClick={handleClick}>
      <Card className={classes.activityCard} selected={selected}>
        {isCourse && (
          <CardContent variant="dense" row light>
            <CardTag tip={parentInfo}>{parentText}</CardTag>
          </CardContent>
        )}
        <Background image={image}>
          <CardContent variant={isCurriculum ? 'normal' : 'dense'} row>
            <CardDetails level={level} title={label} large={isCurriculum} />
            <div className={classes.cardRightContent}>
              {isEvaluation && <Typography variant="body1">{job?.evaluationId}</Typography>}
              <div>
                <ProgressBadge progress={friendlyStatus.toLowerCase()} />
              </div>
            </div>
          </CardContent>
        </Background>
        {isCurriculum && !completed && (
          <CardContent>
            <CardProgress progress={progress} />
          </CardContent>
        )}
      </Card>
    </Wrapper>
  );
}

type ActivityCardProps = {
  item: ActivityLFT | TrainingGroupPreview;
  type: 'evaluations' | 'curriculums' | 'courses' | 'exams';
  completed?: boolean;
  selected?: boolean;
  onClick?: (id: string) => unknown;
};

const Wrapper = ({ children, completed, onClick, to }: WrapperProps) => {
  if (completed) {
    return <CardButton onClick={onClick}>{children}</CardButton>;
  }

  return <CardLink to={to}>{children}</CardLink>;
};

type WrapperProps = {
  children: ReactNode;
  completed: boolean;
  to: string;
  onClick: () => unknown;
};

const Background = ({ children, image }: { children: ReactNode; image: string }) => {
  if (!image) {
    return <>{children}</>;
  }

  return <CardBackground image={image}>{children}</CardBackground>;
};
