import { Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Modal from '../../../../reusables/Modal';
import DateHeader from '../DateHeader';
import { useFrameworkContext } from '../FrameworkContext';
import { TacticCompareModalProps } from '../interfaces';
import TacticDetailTile from '../TacticDetailTile';

const useStyles = makeStyles(theme => ({
  container: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: 20,
    marginBottom: 20
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    gap: 12
  },
  content: {
    display: 'flex',
    gap: 12
  },
  spacer: {
    height: 18
  }
}));

export default function TacticCompareModal({
  open,
  tactic,
  columnName = '',
  onClose,
  day1,
  day2,
}: TacticCompareModalProps) {
  const classes = useStyles();

  const {
    backgroundColor,
    currentFramework,
    
    scoredFrameworkDay1,
    scoredFrameworkDay2,
  } = useFrameworkContext();

  const tactic1 = scoredFrameworkDay1?.groups
    .flatMap(x => x.items)
    .find(x => x.identifier === tactic?.identifier && x.name === tactic?.name);
  
  const tactic2 = scoredFrameworkDay2?.groups
    .flatMap(x => x.items)
    .find(x => x.identifier === tactic?.identifier && x.name === tactic?.name);

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={() => { }}
      actions={[
        { text: 'Close', onClick: onClose },
      ]}
    >
      <div className={classes.container}>
        <div className={classes.header}>
          <Typography variant="h5" style={{ fontSize: 13 }}>{currentFramework.title}</Typography>
          <Typography variant="h3" style={{ textTransform: 'uppercase', marginBottom: 40 }}>{columnName}</Typography>
        </div>
        <div className={classes.content}>
          <div>
            <DateHeader date={day1} />
            <div className={classes.spacer} />
            <TacticDetailTile
              name={tactic1?.name ?? ''}
              score={tactic1?.postureScore.score ?? 0}
              color={backgroundColor(tactic?.postureScore)}
              trend={tactic1?.postureTrend ?? []}
            />
          </div>
          <Divider orientation="vertical" flexItem style={{ width: 2 }} />
          <div>
            <DateHeader date={day2} />
            <div className={classes.spacer} />
            <TacticDetailTile
              name={tactic2?.name ?? ''}
              score={tactic2?.postureScore.score ?? 0}
              color={backgroundColor(tactic?.postureScore)}
              trend={tactic2?.postureTrend ?? []}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}
