import { makeStyles } from '@mui/styles';
import { Box, Tooltip, Typography } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import clsx from 'clsx';
import { Achievement, ScrollableList, ProgressBadge, ProgressBar } from '../../../reusables/scl';
import { CurrAchievementProgress } from './interfaces';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 48,
    '&:not(:last-child)': {
      borderBottom: '1px solid rgba(94, 111, 130, 0.39)',
    },
  },
  achievement: {
    textAlign: 'center',
    paddingTop: '32px',
  },
  list: {
    display: 'flex',
    gap: 46,
    paddingBottom: 48,
  },
  title: {
    position: 'absolute',
    color: '#fff',
    textTransform: 'uppercase',
    letterSpacing: '0.6px',
    lineHeight: '1.5',
    zIndex: 1,
  },
  status: {
    marginTop: 14,
    width: '88%',
    margin: '0 auto',
  },
  percent: {
    fontSize: 10,
    fontWeight: 'bold',
    lineHeight: '1.8',
    letterSpacing: '0.5px',
  },
  dimmed: {
    opacity: '0.2',
  },
  overlayWrapper: {
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    margin: 'auto',
  },
}));

export default function List({ title, achievements }: ListProps) {
  const classes = useStyles();

  if (!achievements.length) {
    return <></>;
  }

  return (
    <div className={classes.container}>
      <ScrollableList className={classes.list}>
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
        {achievements.map(a => (
          <div key={a.achievement.name} className={classes.achievement}>
            <Tooltip title={[`${a.achievement.name}:`, ...a.guidance].join(' ')} arrow placement="top">
              <div className={classes.overlayWrapper}>
                <div className={clsx({ [classes.dimmed]: a.completedPercent !== 100 })}>
                  <Achievement key={a.achievement.name} image={a.achievement.name} size="lg" />
                </div>
                {a.completedPercent !== 100 && (
                  <FontAwesomeIcon icon={['fal', 'lock-alt']} size="2x" className={classes.overlay} />
                )}
              </div>
            </Tooltip>
            <div className={classes.status}>
              {a.completedPercent === 100 && <ProgressBadge progress="earned" />}
              {a.completedPercent !== 100 && (
                <Box display="flex" alignItems="center">
                  <Box width="100%" mr={1}>
                    <ProgressBar size="small" value={a.completedPercent} />
                  </Box>
                  <Box>
                    <Typography variant="body2" className={classes.percent}>
                      {a.completedPercent}%
                    </Typography>
                  </Box>
                </Box>
              )}
            </div>
          </div>
        ))}
      </ScrollableList>
    </div>
  );
}

type ListProps = {
  title: string;
  achievements: CurrAchievementProgress[];
};
