import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { InputAdornment } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Row from '../components/Row';
import TextField from '../../../reusables/TextField';
import ticketingService from '../../../services/TicketingService';
import appStore from '../../../../AppStore';
import { OnSaveCallback, TicketingSetting } from '../interfaces';
import URLField from '../../../reusables/URLField';

export default function BasicTicketingForm({
  data, update, setTestEnabled, submitEnabled, registerSaveCallback,
}: BasicTicketingFormProps) {
  const {
    host, protocol, port, password, systemUser, database, integrationType,
  } = data;

  const [formData, setFormData] = useState<{ [key: string]: string }>({});
  const [settings, setSettings] = useState<CustomSettings>({ steps: [] });
  const [reload, setReload] = useState(false);

  

  // Allow Test Connection when host, username, password, and instance are supplied
  useEffect(() => {
    setTestEnabled(!!(host && protocol && port && systemUser && password && database));
  }, [host, protocol, port, systemUser, password, database, setTestEnabled]);

  // Get custom settings
  useEffect(() => {
    async function getSettings() {
      try {
        const result = await ticketingService.settings({
          integrationType,
          systemUser,
          password,
          database,
          host,
          protocol,
          port,
          type: 'ticketing',
          updatedAt: new Date(),
        }, formData);
        setSettings(result);
      } catch (e) {
        appStore.error(e);
      }
    }

    if (submitEnabled) { getSettings(); }
  }, [submitEnabled, reload, integrationType, systemUser, password, database, host, protocol, port, formData]);

  useEffect(() => {
    registerSaveCallback(async () => {
      try {
        await ticketingService.saveSettings(integrationType, formData);
      } catch (e) {
        appStore.error(e);
      }
    });
  }, [formData, integrationType, registerSaveCallback]);

  return (
    <>
      {/* URL */}
      <Row>
        <URLField
          host={host}
          protocol={protocol}
          port={port}
          onChange={update}
        />
      </Row>

      {/* Username */}
      <Row>
        <TextField
          error={!systemUser && 'Username is required'}
          onChange={e => update({ systemUser: e.target.value })}
          value={systemUser}
          startAdornment={(
            <InputAdornment position="start">
              <FontAwesomeIcon size="lg" icon={['fal', 'user']} />
            </InputAdornment>
          )}
          label="Username"
        />
      </Row>

      {/* Password */}
      <Row>
        <TextField
          error={!password && 'Password is required'}
          onChange={e => update({ password: e.target.value })}
          type="password"
          value={password}
          startAdornment={(
            <InputAdornment position="start">
              <FontAwesomeIcon size="lg" icon={['fal', 'lock']} />
            </InputAdornment>
          )}
          label="Password"
        />
      </Row>

      {/* Instance */}
      <Row>
        <TextField
          error={!database && 'Instance is required'}
          onChange={e => update({ database: e.target.value })}
          value={database}
          startAdornment={(
            <InputAdornment position="start">
              <FontAwesomeIcon size="lg" icon={['fal', 'server']} />
            </InputAdornment>
          )}
          label="Instance"
        />
      </Row>

      {/* Custom Settings */}
      {
        settings.steps && settings.steps.map(step => step.fields.map(field => {
          const {
            label, name, options, value,
          } = field;
          return (
            <Row key={`custom-setting-field-${name}`}>
              <TextField
                label={label}
                type='select'
                value={formData[name] || value}
                onChange={e => {
                  const newData = { ...formData };
                  newData[name] = e.target.value;
                  setFormData(newData);
                  setReload(!reload);
                }}
              >
                {
                  options.map(opt => (
                    <MenuItem key={`custom-field-${name}-options-${value}`} value={opt.value}>
                      {opt.name}
                    </MenuItem>
                  ))
                }
              </TextField>
            </Row>
          );
        }))
      }
    </>
  );
}

interface BasicTicketingFormProps {
  data: TicketingSetting,
  update: (v: Partial<TicketingSetting>) => void,
  setTestEnabled: (v: boolean) => void,
  submitEnabled: boolean,
  registerSaveCallback: (v: OnSaveCallback) => void,
}

interface CustomSettings {
  steps: Array<{
    description: string;
    fields: Array<{
      label: string;
      name: string;
      type: string;
      options: Array<{ name: string, value: string; }>;
      value: string;
    }>;
  }>
}
