type Order = 'asc' | 'desc';

function numericSort(order: Order, a: number, b: number) {
  return order === 'asc' ? a - b : b - a;
}

function stringSort(order: Order, a: string, b: string) {
  return order === 'asc' ? a.localeCompare(b) : -a.localeCompare(b);
}

export default function comparator(orderByField: string, order: Order) {
  return (a: any, b: any) => {
    const valA = a[orderByField];
    const valB = b[orderByField];

    if (typeof valA === 'number') {
      return numericSort(order, valA, valB);
    }

    return stringSort(order, valA, valB);
  };
}
