import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import { ProgressBar } from '../../../reusables/scl';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    marginBottom: '10px',
    fontSize: '14px',
  },
  headerLeft: {
    width: '50%',
    fontWeight: 'bold',
    letterSpacing: '0.6px',
  },
  headerRight: {
    width: '50%',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  progressWrapper: {
    width: '300px',
    marginLeft: '20px',
  },
}));

export default function Header({
  backUrl,
  backText,
  progressText,
  progress = 0,
}: HeaderProps) {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.headerLeft}>
        {backUrl && (
          <Button
            component={Link}
            to={backUrl}
            color="primary"
            startIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-left']} size="lg" />}
          >
            {backText}
          </Button>
        )}
      </div>
      <div className={classes.headerRight}>
        <div>{progressText}</div>
        <div className={classes.progressWrapper}>
          <ProgressBar size="large" value={progress} />
        </div>
      </div>
    </div>
  );
}

type HeaderProps = {
  backUrl: string;
  backText: string;
  progressText: string;
  progress?: number;
}
