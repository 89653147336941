import { useState } from 'react';
import { Divider, Grid, Typography } from '@mui/material';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { makeStyles } from '@mui/styles';
import { truncate } from '@sightgain/core/strings';
import appStore from '../../../../AppStore';
import { SearchBar } from '../../../reusables/scl';
import useWatcher from '../../../reusables/useWatcher';
import { curriculumService } from '../../../services';
import { TrainingGroupDetails, TrainingGroupPreview } from '../../../services/interfaces';
import {
  Card,
  CardBackground,
  CardBadge,
  CardContent,
  CardDetails,
  CardLink,
  CardProgress,
  CardText,
} from '../components/Card';
import CardTag from '../components/Card/CardTag';
import { durationText, groupByStatus, labeledNumber, resolveCurriculumImageForDemo } from '../utils';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '20px',
    display: 'flex',
  },
  heading: {
    marginBottom: '20px',
    marginTop: '20px',
  },
  content: {
    marginBotton: 34,
  },
  headerLeft: {
    width: '40%',
    display: 'flex',
    alignItems: 'center',
  },
  headerRight: {
    width: '60%',
    fontWeight: 'bold',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  title: {
    marginRight: '20px',
    marginTop: '-4px',
  },
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    height: 'calc(100vh - 158px)',
    paddingRight: 12,
  },
  curriculumProgress: {
    flex: 0,
  },
  link: {
    width: '100%',
  },
}));

type LearningCourse = Pick<
  TrainingGroupPreview,
  'id' | 'title' | 'progress' | 'status' | 'difficulty' | 'durationMinutes'
> & { curriculums: Array<{ id: string; title: string }> };

function clickPath(id: string, parentId: string): string {
  if (parentId) {
    return `/curriculum/learning/${parentId}/course/${id}`;
  }

  return `/curriculum/learning/${id}`;
}

function CourseTag({ course: { curriculums = [] } }: { course: LearningCourse }) {
  let curriculumText = `${curriculums.length} Curriculums`;
  let info = curriculums.map(c => c.title).join('\n');

  if (curriculums.length === 1) {
    info = '';
    curriculumText = truncate(curriculums[0].title, 25);
  }
  return (
    <CardContent variant="dense" row light>
      <CardTag tip={info}>{curriculumText}</CardTag>
    </CardContent>
  );
}

function Learning() {
  const classes = useStyles();
  const [viewCourses, setViewCourses] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const curriculums = useWatcher(
    'progress',
    'update',
    async (body: { student: string }) => {
      if (body.student !== appStore.userId) {
        return;
      }

      return await curriculumService.curriculums(appStore.userId);
    },
    { student: appStore.userId },
    [],
  ) as TrainingGroupDetails[];

  const filter = (x: TrainingGroupDetails | LearningCourse) =>
    x.title.toLowerCase().includes(searchValue.toLowerCase());

  let filteredItems: TrainingGroupDetails[] | LearningCourse[] = [];
  let itemLabel = 'Curriculums';

  if (viewCourses) {
    itemLabel = 'Courses';
    filteredItems = curriculums
      .flatMap(curr => curr.courses.map(c => ({ ...c, curriculums: [{ id: curr.id, title: curr.title }] })))
      .filter(filter)
      .reduce((a, b) => {
        const prev = a.find(c => c.id === b.id);
        if (!prev) {
          a.push(b);
          return a;
        }

        prev.curriculums.push(b.curriculums[0]);
        return a;
      }, [] as LearningCourse[]);
  } else {
    filteredItems = curriculums.filter(filter);
  }

  // group the items by status
  const groupedItems = groupByStatus<TrainingGroupDetails | LearningCourse>(filteredItems);

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Typography variant="h2" className={classes.title}>
            Learning
          </Typography>
          <ToggleButtonGroup exclusive value={viewCourses} onChange={() => setViewCourses(!viewCourses)}>
            <ToggleButton value={false} aria-label="list">
              Curriculums
            </ToggleButton>
            <ToggleButton value aria-label="list">
              Courses
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
        <div className={classes.headerRight}>
          <SearchBar placeholder={`Search ${itemLabel}`} onChange={setSearchValue} />
        </div>
      </div>

      <div className={classes.container}>
        {groupedItems.map(group => (
          <div key={group.label}>
            <div className={classes.heading}>
              <Typography variant="h3">{group.label}</Typography>
            </div>
            <Grid className={classes.content} container spacing={2}>
              {group.items.map(item => (
                <Grid item container xs={4} key={item.id}>
                  <CardLink
                    className={classes.link}
                    to={clickPath(item.id, 'curriculums' in item ? item.curriculums[0].id : '')}
                  >
                    <Card>
                      {viewCourses && (
                        <>
                          <CourseTag course={item as LearningCourse} />
                          <CardContent variant="dense">
                            <CardDetails level={item.difficulty} title={item.title} />
                          </CardContent>
                          <Divider />
                          <CardContent variant="medium" row>
                            <CardBadge icon={['fal', 'clock']} text={durationText(item.durationMinutes)} />
                            <CardProgress progress={item.progress ?? 0} />
                          </CardContent>
                        </>
                      )}

                      {!viewCourses && (
                        <>
                          <CardBackground image={resolveCurriculumImageForDemo(item.title)}>
                            <CardContent>
                              <CardDetails title={item.title} />
                            </CardContent>
                          </CardBackground>
                          <Divider />
                          <CardContent variant="dense">
                            <CardText variant="description">{(item as TrainingGroupDetails).description}</CardText>
                          </CardContent>
                          <CardContent className={classes.curriculumProgress} row variant="dense">
                            <CardProgress progress={item.progress ?? 0} />
                            <CardBadge
                              icon={['fal', 'users-class']}
                              text={`${(item as TrainingGroupDetails).courses.length} Courses`}
                            />
                            <CardBadge
                              icon={['fal', 'clock']}
                              text={labeledNumber((item as TrainingGroupDetails).durationDays)}
                            />
                          </CardContent>
                        </>
                      )}
                    </Card>
                  </CardLink>
                </Grid>
              ))}
            </Grid>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Learning;
