import { useState } from 'react';
import { useAbort } from '../../../../effects';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { testingService, evaluationsService, testsService } from '../../../services';
import { DataGrid } from '@mui/x-data-grid';
import UploadModal from './UploadModal';
import appStore from '../../../../AppStore';
import { TestingLibrary } from '../../../services/interfaces';

const useStyles = makeStyles({
  grid: {
    height: 270,
    width: '100%',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
  },
});

export default function Libraries({ className = '' }: { className?: string }) {
  const classes = useStyles();
  const [libraries, setLibraries] = useState<TestingLibrary[]>([]);
  const [uploadOpen, setUploadOpen] = useState(false);

  useAbort(() => testingService.libraries(), result => setLibraries(result), []);

  const handleUpload = async (files: File[]) => {
    appStore.beginLoading();
    try {
      await testingService.uploadLibrary(files);
      const updated = await testingService.libraries();
      await Promise.all([testsService.refreshTests(), evaluationsService.refreshEvaluations()]);  
      setLibraries(updated);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };
  
  const columns = [
    {
      field: 'name',
      headerName: 'Library Name',
      flex: 2,
      editable: false,
    }, 
  ];

  return (
    <div className={className}>
      <div className={classes.topRow}>
        <Typography variant="h5">Libraries</Typography>
        <Button onClick={() => setUploadOpen(true)} variant="contained" color="primary">Upload</Button>
      </div>
      <div className={classes.grid}>
        <DataGrid
          rows={libraries}
          columns={columns}
          density="compact"
        />
      </div>
      <UploadModal open={uploadOpen} onClose={() => setUploadOpen(false)} onUpload={handleUpload} />
    </div>
  );
}
