import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { baseEqual } from '@sightgain/core/strings';
import appStore from '../../../../AppStore';
import LiveFireBadge from '../../../reusables/LiveFireBadge/LiveFireBadge';
import { ScrollableList, Timer } from '../../../reusables/scl';
import { liveFireTrainingService } from '../../../services';
import { LiveFireTrainingStatus } from '../../../services/interfaces';
import ErrorPage from '../../ErrorPage';
import AnalystInputForm from './components/AnalystInputForm';
import { LearningTraining } from './interfaces';
import ObservedEvent from './ObservedEvent';
import PreparingLivefireTraining from './Preparing/PreparingLivefireTraining';
import useLFTWatcher from './useLFTWatcher';

const useStyles = makeStyles(() => ({
  header: {
    minHeight: '30px',
    marginBottom: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  container: {
    display: 'flex',
    gap: '24px',
  },
  leftDivider: {
    borderTop: 'solid 2px #2c3c50',
    borderBottom: 'solid 2px #2c3c50',
    paddingTop: 39,
    paddingBottom: 39,
    marginBottom: 25,
    display: 'flex',
    '& > *': {
      flex: 1,
    },
  },
  rightPane: {
    flex: 3,
    borderRadius: 6,
    overflow: 'hidden',
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
  },
  date: {
    color: '#a7adb5',
    marginTop: 12,
  },
  events: {
    flex: 1,
    height: 888,
    overflow: 'hidden',
    display: 'flex',
    '& > *': {
      flexGrow: 1,
    },
  },
  flex: {
    flexGrow: 1,
  },
  welcome: {
    flexGrow: 1,
    height: 888,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const testStatuses = (training: LearningTraining): LiveFireTrainingStatus[] => {
  if (!training) {
    return ['waiting'];
  }

  // force the dialog to close when the training ends early
  if (!['queued', 'waiting', 'preparing', 'running', 'sleeping', 'stoppage time'].includes(training.status)) {
    return ['ran'];
  }

  const tests = training.job.stages.flatMap(s => s.testResults);
  return tests.map(test => test.status as LiveFireTrainingStatus);
};

const fields = `
{
  id
  status
  analyst {
    id
    email
  }
  analystInput {
    createdAt
    description
    event
    id
  }
  job {
    id
    name
    createdAt
    stages {
      id
      testResults { id status }
    }
  }
  source
  status
  timePassed
  createdAt
  isExam
}
`;

export default function LivefireTraining() {
  const { id } = useParams<{ id: string }>();
  const classes = useStyles();
  const training = useLFTWatcher({ id, fields }) as LearningTraining;

  const endEvaluation = async () => {
    appStore.beginLoading();
    try {
      await liveFireTrainingService.end(id, '{ id status }');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  if (!id || !training) {
    return <></>;
  }

  const running = ['running', 'stoppage time', 'sleeping'].includes(training.status);

  const { analyst, analystInput } = training;

  // Prevent an analyst from accessing another analysts training
  if (!baseEqual(analyst.email, appStore.user.email)) {
    return <ErrorPage title="Forbidden" message="403: Sorry but you cannot access the trainings of another analyst" />;
  }

  return (
    <div>
      <PreparingLivefireTraining
        name={training.job.name}
        source={training.source}
        testStatuses={testStatuses(training)}
        createdAt={training.job.createdAt}
        isExam={training.isExam}
      />
      <div className={classes.header}>
        <div>
          <Timer elapsed={training.timePassed} running={running}>
            Run Time:
          </Timer>
        </div>
        <div>
          {running && (
            <Button variant="contained" color="primary" onClick={endEvaluation} data-testid="end-evaluation">
              End Evaluation&nbsp; &nbsp; &nbsp;
              <FontAwesomeIcon icon={['fal', 'stop-circle']} />
            </Button>
          )}
        </div>
      </div>
      <div className={classes.container}>
        <AnalystInputForm training={training} running={running} />
        <div className={classes.rightPane}>
          {analystInput.length !== 0 && (
            <div className={classes.events}>
              <ScrollableList direction="y" className={classes.flex}>
                <div style={{ padding: '32px 26px 0 26px' }}>
                  {analystInput.map(e => (
                    <div style={{ marginBottom: 20 }} key={`analyst-input-${e.id}`}>
                      <ObservedEvent analyst={analyst.email} {...e} />
                    </div>
                  ))}
                </div>
              </ScrollableList>
            </div>
          )}
          {analystInput.length === 0 && (
            <div className={classes.welcome}>
              <LiveFireBadge />
              <Typography variant="h2" align="center" style={{ marginBottom: 30 }}>
                Welcome to Your Live Evaluation!
              </Typography>
              <Typography align="center">
                It&apos;s time to show what you&apos;ve got! To get started, fill out a New Observed Event
              </Typography>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
