import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import {
  CreateThreatIntelInput,
  ThreatGroup,
  ThreatGroupTrend,
  ThreatGroupTrendInput,
  ThreatIntel,
} from './interfaces';
import ServiceBase from './ServiceBase';

const typeThreatIntel = `
{
  id
  frameworkItem {
    identifier
  }
  createdAt
  score
}
`;

const typeThreatGroup = `
{
  id
  createdAt
  name
  scores ${typeThreatIntel}
}
`;

const typeThreatGroupTrend = `
{
  from
  to
  group
  scores ${typeThreatIntel}
}
`;

export class ThreatGroupsService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['from', 'to', 'createdAt']));
  }

  async changesSince(sinceDate: Date): Promise<number> {
    const query = gql`
      query ThreatGroupChangesSince($sinceDate: Date!) {
        threatGroupChangesSince(sinceDate: $sinceDate)
      }
    `;
    const { threatGroupChangesSince } = await this.graphql(query, { sinceDate });
    return threatGroupChangesSince;
  }

  /**
   * Creates a new threat group
   */
  async createThreatGroup(group: string, fields = typeThreatGroup): Promise<ThreatGroup> {
    const query = gql`
      mutation CreateThreatGroup($group: String!) {
        createThreatGroup(group: $group) ${fields}
      }
    `;

    const { createThreatGroup } = await this.graphql(query, { group });

    console.log(createThreatGroup);

    this.appendGQLCache(
      gql`
        query ThreatGroups {
          threatGroups {
            id
            name
          }
        }
      `,
      undefined,
      'threatGroups',
      createThreatGroup,
      (a, b) => (a.name < b.name ? -1 : 1),
    );

    return createThreatGroup;
  }

  /**
   * Creates a new threat intel score
   */
  async createIntel(input: CreateThreatIntelInput, fields = typeThreatIntel): Promise<ThreatIntel> {
    const query = gql`
      mutation CreateThreatIntel($input: CreateThreatIntelInput!) {
        createThreatIntel(input: $input) ${fields}
      }
    `;

    const { createThreatIntel } = await this.graphql(query, { input }, 'network-only');

    return createThreatIntel;
  }

  /**
   * Returns all threat groups
   */
  async list(fields = typeThreatGroup): Promise<ThreatGroup[]> {
    const query = gql`query ThreatGroups { threatGroups ${fields} }`;
    const { threatGroups } = await this.graphql(query);

    return threatGroups;
  }

  /**
   * Returns 24 weeks of trend data
   */
  async weeklyTrends(
    input: ThreatGroupTrendInput,
    fields = typeThreatGroupTrend,
    refresh = false,
  ): Promise<ThreatGroupTrend[]> {
    const query = gql`
      query ThreatGroupTrends($input: ThreatGroupTrendInput!) {
        threatGroupTrends(input: $input) ${fields}
      }
    `;
    const fetchPolicy = refresh ? 'no-cache' : undefined;

    const { threatGroupTrends } = await this.graphql(query, { input }, fetchPolicy);

    return threatGroupTrends;
  }
}

const threatGroupsService = new ThreatGroupsService();
export default threatGroupsService;
