import { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { unique } from '@sightgain/core';
import { contrastTextColor } from '@sightgain/core/colors';
import { useAbort } from '../../../../effects';
import { useThresholdColors } from '../../../reusables/useSetting';
import { frameworksService, jobsService, settingsService } from '../../../services';
import { JobSummary, ProductBakeOffResult } from '../../../services/interfaces';
import { useUserPreference } from '../../../services/UserPreferencesService';
import GroupList from './Components/GroupList';
import NoProducts from './Components/NoProducts';
import ProductTestResults from './Components/ProductTestResults';
import SelectEvaluation from './Components/SelectEvaluation';
import { BakeOffGroup, BakeOffProduct, ProcessedDataItem, Thresholds } from './interfaces';
import ProductDrillDown from './ProductDrillDown';
import Wrapper from './Wrapper';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
  },
  left: {
    flex: 1,
    backgroundColor: '#0b0c12',
  },
  right: {
    paddingLeft: 25,
    flex: 2,
    display: 'flex',
    '& > div': {
      flex: 1,
      width: '100%',
    },
  },
}));

// TODO: Find a way to automaticaly clear selection in case of error? Is it needed?
export default function ProductBakeOff() {
  const classes = useStyles();
  const [thresholds, setThresholds] = useState<Thresholds>({
    detectThresholds: [70, 80],
    preventThresholds: [70, 80],
  });

  const [groups, setGroups] = useState<BakeOffGroup[]>([]);
  const [groupName, setGroupName] = useState<string>();
  const [bakeOffResults, setBakeOffResults] = useState<ProductBakeOffResult[]>([]);
  const [products, setProducts] = useUserPreference<BakeOffProduct[]>('appBakeOffProducts', []);
  const [selected, setSelected] = useUserPreference<JobSummary | undefined>('appBakeOffSummary', undefined);
  const thresholdColors = useThresholdColors();

  const thresholdStyle = useCallback(
    (value: ProcessedDataItem, prevention = true) => {
      const [limit1, limit2] = prevention ? thresholds.preventThresholds : thresholds.detectThresholds;
      const { prevented, detected, total } = value;
      const numValue = prevention ? prevented : detected;
      const percent = (numValue / total) * 100;

      if (total === 0) {
        return { backgroundColor: 'transparent', color: '#fff' };
      }

      if (percent < limit1) {
        return { backgroundColor: thresholdColors.red, color: contrastTextColor(thresholdColors.red) };
      }

      if (percent < limit2) {
        return { backgroundColor: thresholdColors.yellow, color: contrastTextColor(thresholdColors.yellow) };
      }

      return { backgroundColor: thresholdColors.green, color: contrastTextColor(thresholdColors.green) };
    },
    [thresholdColors, thresholds],
  );

  // get the groups from the framework for the left column
  useAbort(
    () => frameworksService.find('mitre', 'latest', undefined, '{ id groups { name items { id testIds } } }'),
    ({ groups }) =>
      setGroups(
        groups.map(group => ({
          name: group.name,
          testIds: unique(group.items.flatMap(item => item.testIds)),
        })),
      ),
    [],
  );

  // reset the group name if an assessment change removes it
  useEffect(() => {
    if (groupName && !groups.find(group => group.name === groupName)) {
      setGroupName(undefined);
    }
  }, [groups, groupName]);

  // get the thresholds
  useAbort(
    () => settingsService.list(Object.keys(thresholds) as string[]),
    settings => {
      if (!settings) {
        return;
      }

      setThresholds(values =>
        settings.reduce((a, b) => {
          const { name, value } = b;
          if (name in a) {
            a[name as keyof Thresholds] = JSON.parse(value);
          }

          return a;
        }, values),
      );
    },
    [],
  );

  // get the jobs that relate to the evaluation during the assessment
  useAbort(
    () => {
      if (!selected) {
        return;
      }
      const input = {
        evaluationId: selected.evaluationId,
        startDate: new Date(0),
        endDate: new Date(),
        products: products.map(p => ({ name: p.name, type: p.type })),
      };

      return jobsService.bakeOff(input);
    },
    result => {
      if (!result?.length) {
        return;
      }

      setBakeOffResults(result);
      return;
    },
    [selected, products],
  );

  // add a product to the comparison
  const addProduct = (product: BakeOffProduct) => {
    setProducts([...products, product]);
  };

  // remove a product from the comparison
  const clearProduct = (product: BakeOffProduct) => {
    const filteredList = products.filter(prod => prod.name !== product.name || prod.type !== product.type);
    setProducts(filteredList);
    if (filteredList.length === 0) {
      setSelected(undefined);
    }
  };

  const groupResults = useMemo(() => {
    if (!groupName) {
      return [];
    }

    const group = groups.find(g => g.name === groupName);
    if (!group) {
      return [];
    }

    return bakeOffResults.filter(r => group.testIds.includes(r.vendorTestId));
  }, [groupName, groups, bakeOffResults]);

  if (groupName) {
    return (
      <Wrapper drilldown={groupName} removeDrilldown={() => setGroupName(undefined)}>
        <ProductDrillDown products={products} bakeOffResults={groupResults} />
      </Wrapper>
    );
  }

  const nothingSelected = products.length === 0;
  const displayAddProduct = products.length < 3;

  return (
    <Wrapper
      removeDrilldown={() => setGroupName(undefined)}
      addProduct={addProduct}
      exclude={products}
      displayAddProduct={displayAddProduct}
    >
      {nothingSelected && <NoProducts />}
      {products.length > 0 && (
        <div className={classes.container}>
          <div className={classes.left}>
            {selected && (
              <GroupList
                name={selected.name}
                groups={groups}
                onClear={() => {
                  setSelected(undefined);
                  setGroupName(undefined);
                }}
                onClick={v => setGroupName(v)}
              />
            )}
            {!selected && products.length > 0 && <SelectEvaluation onSelect={setSelected} />}
          </div>
          <div className={classes.right}>
            {products.map(product => (
              <ProductTestResults
                key={`${product.name}`}
                product={product}
                bakeOffResults={bakeOffResults.filter(
                  r => r.productName === product.name && r.productType === product.type,
                )}
                groups={groups}
                thresholdStyle={thresholdStyle}
                onClose={() => clearProduct(product)}
                evaluationName={selected?.name ?? ''}
              />
            ))}
          </div>
        </div>
      )}
    </Wrapper>
  );
}
