import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  cardCategory: {
    fontSize: 14,
    textDecoration: 'underline',
    color: '#1592ff',
  }
});

export default function CardText({ variant = 'description', children }: CardTextProps) {
  const classes = useStyles();
  let hVariant: 'h5' | 'h6' = 'h5';
  let className = '';

  if (variant === 'category') {
    hVariant = 'h6';
    className = classes.cardCategory;
  }

  return (
    <Typography variant={hVariant} className={className}>{children}</Typography>
  );
}

type CardTextProps = {
  children: string;
  variant?: 'description' | 'category';
}
