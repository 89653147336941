import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    overflow: 'hidden',
    width: ({ width }: ThumbnailStyleProps) => width,
    height: ({ height }: ThumbnailStyleProps) => height,
  },
  innerStyles: {
    height: '100%',
    width: '100%',
    backgroundImage: ({ src }: ThumbnailStyleProps) => `url(${src})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    transition: 'transform 1s',
    '&:hover': {
      transform: 'scale(2)',
    },
  },
}));

export default function Thumbnail({
  alt, src, height = 150, width = 150,
}: ThumbnailProps) {
  const classes = useStyles({ src, height, width });

  return (
    <div className={classes.wrapper}>
      <div className={classes.innerStyles} title={alt} role="img" />
    </div>
  );
}

interface ThumbnailProps {
  alt: string;
  height?: number | string;
  width?: number | string;
  src: string;
}

interface ThumbnailStyleProps {
  width: number | string;
  height: number | string;
  src: string;
}