import { Button, Grid, Switch, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { formatDate } from '@sightgain/core/dates';
import { Framework } from '../../../services/interfaces';

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 6,
    background: '#192637',
    overflow: 'hidden',
    marginBottom: 12,
    '&:hover': {
      background: '#161926',
    },
    position: 'relative',
  },
  left: {
    flex: 2,
  },
  right: {
    flex: 4,
    display: 'flex',
    padding: 24,
    gap: 48,
  },
  control: {
    gap: 12,
    display: 'flex',
  },
  title: {
    color: '#8797AB',
    fontSize: 13,
  },
  chip: {
    borderRadius: '0px 20px 20px 0px',
    padding: '5px 24px 5px 24px',
    background: '#1899B6',
    width: 170,
    height: 30,
  },
  disabledOverlay: {
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      background: 'rgba(0, 0, 10, 0.5)',
      pointerEvents: 'none',
      mixBlendMode: 'multiply',
    },
  },
  cloned: {
    background: '#09B581',
  },
}));

interface FrameworkProps {
  onToggle: () => void;
  onClone: () => void;
  onDelete: () => void;
  onEdit: () => void;
  fw: Framework;
}

export default function FrameworkCard({ onToggle, onClone, onDelete, onEdit, fw }: FrameworkProps) {
  const classes = useStyles();
  const { title, version, active, isSystem, updatedAt, createdAt } = fw;
  const disabled = !active;

  return (
    <div className={`${classes.card} ${disabled && classes.disabledOverlay}`}>
      <div style={{ display: 'flex' }}>
        <div className={classes.left}>
          <div className={`${classes.chip} ${!isSystem && classes.cloned}`}>
            <Typography style={{ fontSize: 13 }} textAlign="center">
              {isSystem ? 'System Provided' : 'Cloned'}
            </Typography>
          </div>
          <div style={{ padding: '18px 24px 24px 24px' }}>
            <Typography variant="h5">{title}</Typography>
          </div>
        </div>
        <Grid
          container
          display="flex"
          sx={{ marginLeft: 4, marginRight: 4, paddingTop: 2, paddingBottom: 2 }}
          alignItems="center"
          gap={2}
        >
          <Grid item flex="1">
            <Typography className={classes.title}>Version</Typography>
            <Typography>{version}</Typography>
          </Grid>
          <Grid item flex="1">
            <Typography className={classes.title}>Date Created</Typography>
            <Typography>{formatDate(createdAt)}</Typography>
          </Grid>
          <Grid item flex="1">
            <Typography className={classes.title}>Date Modified</Typography>
            <Typography>{formatDate(updatedAt)}</Typography>
          </Grid>
          <Grid item className={classes.control} flex="0">
            <Button disabled={disabled} onClick={onClone}>
              Clone
            </Button>
            {!isSystem && (
              <>
                <Button disabled={disabled} color="primary" onClick={onEdit}>
                  Edit
                </Button>
                <Button disabled={disabled} color="secondary" onClick={onDelete}>
                  Delete
                </Button>
              </>
            )}
            <Switch checked={!disabled} onChange={onToggle} />
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
