import { chunkFn, unique } from '@sightgain/core';
import { jobsService } from '../../../../../services';
import { Evaluation, Exam } from '../../../../../services/interfaces';
import { EvaluationRunner } from '../interfaces';

/**
 * A runner customizes how calls to the backend are made for a specific vendor
 * in order to fetch the options for actors and tests.
 */

const defaultRunner: EvaluationRunner = {
  async fetchActorOptions(evaluation: Evaluation | Exam) {
    if ('testIds' in evaluation) {
      return chunkFn(evaluation.testIds, testId => jobsService.actors([testId]), 5);
    }

    if (['msv', 'srp'].includes(evaluation.vendor)) {
      return chunkFn(evaluation.stages, stage => jobsService.actors(unique(stage.tests.map(test => test.vendorId))), 5);
    }

    return [await jobsService.actors([evaluation.vendorId])];
  },
  fetchTestOptions(evaluation: Evaluation | Exam) {
    if ('stages' in evaluation) {
      return jobsService.runOptions(evaluation.stages.map(stage => stage.tests.map(test => test.vendorId)));
    }

    return jobsService.runOptions([evaluation.testIds]);
  },
};

const picusRunner: EvaluationRunner = {
  // Picus doesn't use actors so no reason to call graphql
  async fetchActorOptions() {
    return [];
  },
  // Picus options need to be retrieved by Template ID
  fetchTestOptions(evaluation: Evaluation | Exam) {
    if ('testIds' in evaluation) {
      throw new Error('Exams are not implemented in Picus');
    }
    return jobsService.runOptions([[evaluation.vendorId]]);
  },
};

export default function getRunner(vendor: string) {
  switch (vendor) {
    case 'picus':
      return picusRunner;
    default:
      return defaultRunner;
  }
}
