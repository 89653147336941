import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  outerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1
  },
  innerContainer: {
    maxWidth: 600,
    height: 600,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function Welcome({ onContinue }: { onContinue: () => void }) {
  const classes = useStyles();

  return (
    <div className={classes.outerContainer}>
      <div className={classes.innerContainer}>
        <FontAwesomeIcon color="#F46043" icon={['fal', 'cubes']} size="4x" />
        <Typography variant="h1" style={{ marginTop: 32 }}>Framework Management</Typography>
        <Typography variant="body1" style={{ marginTop: 32, fontSize: 18 }} textAlign="center">
          Dive into cybersecurity excellence.
          Seamlessly create new or clone existing frameworks for comprehensive testing initiatives.
        </Typography>
        <Typography style={{ marginTop: 32, marginBottom: 48, fontSize: 18, color: '#8797AB' }}>
          Continue to kickstart your testing journey.
        </Typography>
        <Button
          variant="contained"
          onClick={() => onContinue()}
        >
          Continue
        </Button>
      </div>
    </div>
  );
}
