import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import ButtonLink from '../../../../reusables/ButtonLink';
import { BakeOffGroup } from '../interfaces';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    paddingTop: 27,
    paddingBottom: 27,
  },
  header: {
    paddingLeft: 28,
    paddingRight: 28,
  },
  title: {
    maxHeight: 27.78,
  },
  buttonBack: {
    width: 80,
    fontSize: 12,
    '& > span > span': {
      marginRight: 10,
    },
  },
  content: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 200.5,
  },
  item: {
    borderTop: 'solid 1px #2c324b',
    '&:last-child': {
      borderBottom: 'solid 1px #2c324b',
    },
  },
  text: {
    color: '#21bee3',
    marginTop: 8,
    marginBottom: 9,
    paddingLeft: 28,
    paddingRight: 28,
  },
}));

export default function GroupList({ onClear = () => undefined, groups, name, onClick }: EvaluationStagesProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Button
          className={classes.buttonBack}
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-left']} />}
          onClick={onClear}
        >
          Back
        </Button>
        <Typography className={classes.title} variant="h3">
          {name}
        </Typography>
      </div>
      <div className={classes.content}>
        {groups.map(item => (
          <ButtonLink component="span" className={classes.item} key={item.name} onClick={() => onClick(item.name)}>
            <Typography variant="h6" className={classes.text}>
              {capitalize(item.name)}
            </Typography>
          </ButtonLink>
        ))}
      </div>
    </div>
  );
}

type EvaluationStagesProps = {
  onClear: () => unknown;
  groups: BakeOffGroup[];
  name: string;
  onClick: (stage: string) => unknown;
};
