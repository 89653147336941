import { createContext } from 'react';
import { EvaluationWindowContextState } from '../interfaces';

const EvaluationWindowContext = createContext<EvaluationWindowContextState>({
  activeStep: 0,
  disableNextBtn: true,
  label: 'evaluation',
  hasTicketing: true,
  isRunnable: false,
  stages: [],
  actorSettings: [],
  analysts: [],
  collectionMethod: '',
  scenarioTarget: '',
  stoppageTime: 240,
  testOptionInputs: [],
  testOptions: [],
  frameworkControls: [],
  handleSetActiveStep: () => undefined,
  handleDisableNextBtn: () => undefined,
  handleUpdateActorSettings: () => undefined,
  handleUpdateCollectionMethod: () => undefined,
  handleUpdateIsRunnable: () => undefined,
  handleUpdateScenarioTarget: () => undefined,
  handleUpdateSelectedAnalysts: () => undefined,
  handleUpdateStoppageTime: () => undefined,
  handleUpdateDryRun: () => undefined,
  handleUpdateStartAfter: () => undefined,
  handleUpdateStartBefore: () => undefined,
  handleUpdateTestOption: () => undefined,
  handleRunEvaluation: () => undefined,
  setFrameworkControls: () => undefined,
});

export default EvaluationWindowContext;
