import clsx from 'clsx';
import { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputAdornment, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { validURL } from '@sightgain/core/validation';
import appStore from '../../../AppStore';
import { useAbort } from '../../../effects';
import AttackIQ from '../../images/attackiq.svg';
import Cymulate from '../../images/cymulate.svg';
import Verodin from '../../images/mandiant.svg';
import Picus from '../../images/picus.svg';
import Testing from '../../images/testing.svg';
import TextField from '../../reusables/TextField';
import URLField from '../../reusables/URLField';
import { vendorService } from '../../services';
import { SystemUser } from '../../services/interfaces';
import settingsService from '../../services/SettingsService';
import systemUsersService from '../../services/SystemUsersService';
import Row from './components/Row';
import RefreshSync from './RefreshSync';
import { toURL } from './utilities';

const useStyles = makeStyles(theme => ({
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  logo: {
    height: 24,
    marginTop: 14,
    marginLeft: 14,
  },
  verodinLogo: {
    height: 24,
    marginTop: 14,
    marginLeft: 14,
    paddingRight: 14,
  },
  picusLogo: {
    height: 24,
    marginTop: 14,
    marginLeft: 14,
    paddingRight: 14,
  },
  attackiqLogo: {
    height: 25,
    marginTop: 14,
    marginLeft: 14,
    paddingRight: 14,
  },
  cymulateLogo: {
    marginTop: -5,
    marginLeft: 14,
    paddingRight: 14,
  },
  testingLogo: {
    height: 42,
    marginTop: 5,
    marginLeft: 0,
    paddingRight: 14,
  },
  vendor: {
    backgroundColor: '#e5e6e9',
    height: 50,
    width: '100%',
  },
  description: {
    marginTop: 16,
    marginBottom: 39,
  },
  button: {
    fontSize: 14,
    color: '#fff',
    borderRadius: 2,
    outline: 'none',
  },
  testBtn: {
    border: 'solid 0.5px #fff',
    backgroundColor: 'rgba(40, 43, 54, 0.62)',
  },
  submitBtn: {
    backgroundColor: '#21bee3',
  },
}));

const SYSTEM_USER_TYPE = 'sip';

const EMPTY_CONFIG = {
  integrationType: '',
  systemUser: '',
  password: '',
  host: '',
  protocol: 'https',
  type: SYSTEM_USER_TYPE,
};

const FAKE_PASSWORD = [...Array(25).keys()].map(() => '*').join('');

export default function SipSettings() {
  const classes = useStyles();
  // the vendor selected
  const [sip, setSIP] = useState('');
  const [sipSettings, setSipSettings] = useState<SystemUser>({ ...EMPTY_CONFIG });
  const [submitSip, setSubmitSip] = useState(false);

  const { systemUser, password, host, protocol = 'https', port } = sipSettings;

  const testEnabled = () => validURL(toURL({ host, port, protocol })) && password;

  const testConnection = async () => {
    const test = async () => {
      appStore.beginLoading();
      await vendorService.testConnection(sipSettings);
      appStore.endLoading();
      setSubmitSip(true);
      appStore.success('Connection Established Successfully');
    };

    test().catch(err => {
      appStore.endLoading();
      setSubmitSip(false);
      appStore.error(err);
    });
  };

  useAbort(
    () => {
      if (!sip) {
        return Promise.resolve(undefined);
      }

      return systemUsersService.list(SYSTEM_USER_TYPE);
    },
    result => {
      if (result) {
        // retrieve sip setting user data, otherwise clear form
        const sipSettingsConfig = result.find(d => d.integrationType === sip);
        const sipConfig = {
          ...EMPTY_CONFIG,
          integrationType: sip,
          ...(sipSettingsConfig && {
            ...sipSettingsConfig,
            password: FAKE_PASSWORD,
          }),
        };

        // set default values for picus
        if (sipConfig.integrationType === 'picus' && sipConfig.host === '') {
          sipConfig.host = 'api.picussecurity.com';
          sipConfig.protocol = 'https';
          sipConfig.port = 443;
        }

        setSipSettings(sipConfig);
        setSubmitSip(false);
      }
    },
    [sip],
  );

  useAbort(
    () => settingsService.find('sip'),
    result => setSIP(result.value ?? 'msv'),
    [],
  );

  const save = async () => {
    appStore.beginLoading();

    try {
      const res = await systemUsersService.createOrUpdate(sipSettings);

      await vendorService.reconnect(sipSettings.integrationType as string);

      setSipSettings({ ...EMPTY_CONFIG, ...res, password: FAKE_PASSWORD });
      appStore.success('Configuration Saved');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const update = (value: Partial<SystemUser>) => setSipSettings(p => ({ ...p, ...value }));

  // display the username box only for specific SIPs
  const allowBasic = ['msv', 'testing'].includes(sip);

  // set the password label based on the SIP
  const passwordLabel = useMemo(() => {
    if (sip === 'msv') {
      return 'Password or API Token';
    }

    if (sip === 'testing') {
      return 'Password';
    }

    return 'API Token';
  }, [sip]);

  return (
    <div>
      <Typography variant="h3">Security Instrumentation</Typography>
      <Typography variant="body1" className={classes.description}>
        Configure the Security Instrumentation Platform (SIP) that you would like to use in Live Fire exercises.
      </Typography>
      <Row>
        <div style={{ width: '26em' }}>
          <TextField value={sip} onChange={e => setSIP(e.target.value)} type="select" label="Platform">
            <MenuItem value="">
              <em>None</em>
            </MenuItem>

            <MenuItem value="attackiq">
              <div className={classes.vendor}>
                <img alt="noteable" className={classes.attackiqLogo} src={AttackIQ} />
              </div>
            </MenuItem>
            <MenuItem value="cymulate">
              <div className={classes.vendor}>
                <img alt="noteable" className={classes.cymulateLogo} src={Cymulate} />
              </div>
            </MenuItem>
            <MenuItem value="msv">
              <div className={classes.vendor}>
                <img alt="noteable" className={classes.verodinLogo} src={Verodin} />
              </div>
            </MenuItem>
            <MenuItem value="picus">
              <div className={classes.vendor}>
                <img alt="noteable" className={classes.picusLogo} src={Picus} />
              </div>
            </MenuItem>
            <MenuItem value="testing">
              <div className={classes.vendor}>
                <img alt="noteable" className={classes.testingLogo} src={Testing} />
              </div>
            </MenuItem>
          </TextField>
        </div>
      </Row>
      {sip && (
        <>
          <Row>
            <URLField host={host} port={port} protocol={protocol} onChange={update} />
          </Row>
          <Row>
            {allowBasic && (
              <TextField
                onChange={e => update({ systemUser: e.target.value })}
                startAdornment={
                  <InputAdornment position="start">
                    <FontAwesomeIcon size="lg" icon={['fal', 'user']} />
                  </InputAdornment>
                }
                value={systemUser}
                label="Username"
              />
            )}
            <TextField
              onChange={e => update({ password: e.target.value })}
              type="password"
              value={password}
              startAdornment={
                <InputAdornment position="start">
                  <FontAwesomeIcon size="lg" icon={['fal', 'lock']} />
                </InputAdornment>
              }
              label={passwordLabel}
            />
          </Row>
          <Row>
            <Button
              className={clsx(classes.button, classes.testBtn)}
              onClick={testConnection}
              disabled={!testEnabled()}
              variant="contained"
              endIcon={<FontAwesomeIcon size="sm" icon={['fal', 'arrow-right']} />}
            >
              Test Connection
            </Button>
            <Button
              className={clsx(classes.button, classes.submitBtn)}
              onClick={save}
              disabled={!submitSip}
              variant="contained"
              endIcon={<FontAwesomeIcon size="sm" icon={['fal', 'arrow-right']} />}
            >
              Submit
            </Button>
          </Row>
          <div>
            <RefreshSync type={sip} />
          </div>
        </>
      )}
    </div>
  );
}
