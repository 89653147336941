import { makeStyles } from '@mui/styles';
import {
  Button,
  IconButton,
  Modal,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SystemHostEvent from './SystemHostEvent';
import SystemIntegrationEvent from './SystemIntegrationEvent';
import AnalystEventInput from './AnalystEventInput';
import { AnalystInput, HostEvent, IntegrationEvent } from '../../../../services/interfaces';
import { eventDescription } from './parseEvent';

const useStyles = makeStyles({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    // drawers are 1301 and modals are 1300
    zIndex: 1302,
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    backgroundColor: '#0b0c12',
    width: '100%',
    height: '100%',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& > div': {
      flex: 1,
    },
    padding: '36px 78px 26px 78px',
    borderBottom: 'solid 1px #2c324b',
  },
  buttons: {
    display: 'flex',
    gap: 48,
    alignItems: 'center',
  },
  bottomRow: {
    display: 'flex',
    '& > div': {
      width: '50%',
    },
    height: 'calc(100vh - 103px)',
  },
  bottomRowLeft: {
    padding: '26px 50px 26px 78px',
    borderRight: 'solid 1px #2c324b',
  },
  bottomRowRight: {
    padding: '26px 78px 26px 60px',
    overflowY: 'auto',
    '& > div:not(:first-child)': {
      borderTop: 'solid 1px #2c324b',
      paddingTop: 26,
    },
  },
  studentEvent: {
    marginBottom: 40,
  },
});

export default function EventModal({
  title,
  events,
  analystInputs,
  open,
  setOpen,
  eventIdx,
  setEventIdx,
  data,
}: EventModalProps) {
  const classes = useStyles();

  const previousEnabled = eventIdx > 0;
  const nextEnabled = eventIdx < (events.length - 1);
  const handlePrevious = () => {
    setEventIdx(eventIdx - 1);
  };

  const handleNext = () => {
    setEventIdx(eventIdx + 1);
  };

  const isHostEvent = data.type === 'host';

  return (
    <Modal open={open} onClose={() => setOpen(false)} className={classes.modal}>
      <div className={classes.wrapper}>
        <div className={classes.header}>
          <div className={classes.buttons}>
            <Typography variant="h2">{title}</Typography>
            <Typography variant="body2">{`${eventIdx + 1} of ${events.length}`}</Typography>
            <Button
              onClick={handlePrevious}
              variant="outlined"
              disabled={!previousEnabled}
              startIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-left']} />}
            >
              Previous Event
            </Button>
            <Button
              onClick={handleNext}
              variant="outlined"
              disabled={!nextEnabled}
              endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
            >
              Next Event
            </Button>
          </div>
          <IconButton onClick={() => setOpen(false)} size="large">
            <FontAwesomeIcon icon={['fal', 'times']} />
          </IconButton>
        </div>
        <div className={classes.bottomRow}>
          <div className={classes.bottomRowLeft}>
            <Typography variant="h3">{eventDescription(data.raw)}</Typography>
            {isHostEvent && (
              <SystemHostEvent data={data as HostEvent} />
            )}
            {!isHostEvent && (
              <SystemIntegrationEvent data={data} />
            )}
          </div>
          <div className={classes.bottomRowRight}>
            {analystInputs?.map(input => (
              <div key={`analyst-event-${input.id}`} className={classes.studentEvent}>
                <AnalystEventInput input={input} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

interface EventModalProps {
  title: string;
  events: Array<HostEvent | IntegrationEvent>;
  analystInputs: AnalystInput[];
  open: boolean;
  setOpen: (value: boolean) => unknown;
  eventIdx: number;
  setEventIdx: (value: number) => unknown;
  data: HostEvent | IntegrationEvent;
}
