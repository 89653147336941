import { AssessmentProvider } from '../components/AssessmentContext';
import { CompareProvider } from './CompareContext';
import FrameworkScoring from './FrameworkScoring';

export default function FrameworkScoringContainer() {
  return (
    <CompareProvider>
      <AssessmentProvider>
        <FrameworkScoring />
      </AssessmentProvider>
    </CompareProvider>
  );
}