import { Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { round } from '@sightgain/core/calculations';
import { secondsToString } from '@sightgain/core/strings';
import { ProgressMonitoringMetric } from '../../../../reusables/scl';
import { ResultPerformance, ScorePerformance } from '../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'row',
    '& > div:first-child': {
      marginRight: 25,
    },
  },
  group: {
    display: 'flex',
    flexBasis: '50%',
    justifyContent: 'space-evenly',
    padding: '22px 28px 21.4px 29px',
    marginRight: 24,
    borderRadius: 6,
    border: 'solid 1px rgba(255, 255, 255, 0.26)',
  },
}));

export default function Indicators({ technology, personnel }: IndicatorsProps) {
  const classes = useStyles();

  const currTech = technology[technology.length - 1]?.performance;
  const lastTech = technology[technology.length - 2]?.performance;
  const currPers = personnel[personnel.length - 1]?.performance;
  const lastPers = personnel[personnel.length - 2]?.performance;

  return (
    <div className={classes.wrapper}>
      <div className={classes.group}>
        <ProgressMonitoringMetric
          formatter={v => round(v * 100, 2)}
          label="Prevented"
          value={currTech?.prevented ?? 0}
          valuePrevious={lastTech?.prevented ?? 0}
          unit="%"
        />
        <Divider orientation="vertical" flexItem />
        <ProgressMonitoringMetric
          formatter={v => round(v * 100, 2)}
          label="Detected"
          value={currTech?.detected ?? 0}
          valuePrevious={lastTech?.detected ?? 0}
          unit="%"
        />
        <Divider orientation="vertical" flexItem />
        <ProgressMonitoringMetric
          formatter={v => round(v * 100, 2)}
          label="Alerted"
          value={currTech?.alerted ?? 0}
          valuePrevious={lastTech?.alerted ?? 0}
          unit="%"
        />
      </div>
      <div className={classes.group}>
        <ProgressMonitoringMetric
          formatter={(value: number) => secondsToString(value)}
          label="Average Response Time"
          value={currPers?.responseTime ?? 0}
          valuePrevious={lastPers?.responseTime ?? 0}
          unit="seconds"
          unitShort=" "
        />
        <Divider orientation="vertical" flexItem />
        <ProgressMonitoringMetric
          formatter={v => round(v, 2)}
          label="Composite Score"
          value={currPers?.score ?? 0}
          valuePrevious={lastPers?.score ?? 0}
          unit=""
        />
        <Divider orientation="vertical" flexItem />
        <ProgressMonitoringMetric
          formatter={v => round(v, 2)}
          label="Event Noise Score"
          value={currPers?.precisionScore ?? 0}
          valuePrevious={lastPers?.precisionScore ?? 0}
          unit=""
        />
      </div>
    </div>
  );
}

interface IndicatorsProps {
  technology: ResultPerformance[];
  personnel: ScorePerformance[];
}
