import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonProps, Typography, TypographyProps } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { HTMLProps, useCallback, useMemo } from 'react';
import { jobsService, threatGroupsService } from '../services';
import useSocketListener from './useSocketListener';
import { format } from 'date-fns';

const useStyles = makeStyles({
  scoringRefreshButton: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
    margin: '0 15px',
  },
});

const DEFAULT_BUTTON: ButtonProps = {
  variant: 'contained',
  color: 'secondary',
  endIcon: <FontAwesomeIcon size="sm" icon={['fal', 'sync-alt']} />,
};

export default function ScoringRefreshButton({
  scoredDate,
  testIds,
  refresh,
  messageProps = {},
  buttonProps = {},
  ...props
}: ScoringRefreshButtonProps) {
  const classes = useStyles();
  const jobCallback = useCallback(async () => {
    const n = await jobsService.testResultsSince({ sinceDate: scoredDate, testIds });
    return n;
  }, [scoredDate, testIds]);

  const threatCallback = useCallback(async () => {
    const n = await threatGroupsService.changesSince(scoredDate);
    return n;
  }, [scoredDate]);

  const jobChanges = useSocketListener('jobs', 'update', jobCallback);
  const threatChanges = useSocketListener('threatgroups', 'update', threatCallback);
  const prettyDate = format(scoredDate, 'Pp z');

  const message = useMemo(() => {
    if (jobChanges && threatChanges) {
      return `${jobChanges + threatChanges} update${
        jobChanges + threatChanges > 1 ? 's' : ''
      } since scored last (${scoredDate})`;
    }

    if (threatChanges) {
      return `${threatChanges} threat update${threatChanges > 1 ? 's' : ''} since scored last: ${prettyDate}`;
    }

    if (jobChanges) {
      return `${jobChanges} new test result${jobChanges > 1 ? 's' : ''} since scored last: ${prettyDate}`;
    }

    return `0 updates since scored last: ${prettyDate}`;
  }, [jobChanges, threatChanges]);

  return (
    <div className={classes.scoringRefreshButton} {...props}>
      <Button {...{ ...DEFAULT_BUTTON, ...buttonProps }} onClick={refresh} style={{ width: 200 }}>
        Refresh Now
      </Button>
      <Typography {...messageProps}>{message}</Typography>
    </div>
  );
}

type ScoringRefreshButtonProps = HTMLProps<HTMLDivElement> & {
  scoredDate: Date;
  testIds?: string[];
  refresh: () => Promise<void>;
  buttonProps?: ButtonProps;
  messageProps?: TypographyProps;
}