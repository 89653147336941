export interface RecommendationSummary {
  type: string;
  title: string;
  desc: string;
  priorities: number;
  ignored: number;
  resolved: number;
  recent: number;
}

export interface Recommendation {
  id: string;
  type: string;
  title: string;
  content: string;
  detail: string;
  status: string; // new, priority, ignored, resolved?
  date: Date; 
  riskLevel: RuleRiskLevel;
}

export enum RuleRiskLevel {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}