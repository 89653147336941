import React, { ReactNode, useMemo } from 'react';

import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { mapNumberToRange, round } from '@sightgain/core/calculations';
import { contrastTextColor, hexToRGBA } from '@sightgain/core/colors';

import { Ratio } from '../../services/interfaces';
import { useThresholdColors } from '../useSetting';
import ChartWrapper from './ChartWrapper';

const useStyles = makeStyles(() => ({
  table: {
    background: 'none',
  },
  chart: {
    height: 'auto',
    overflow: 'unset !important',
    paddingTop: 'unset !important',
  },
}));

function valueOf(n: number | Ratio) {
  if (typeof n === 'number') {
    return n;
  }

  if (n.total === 0) {
    // 0/0 is -1 so that it doesn't get an associated color because it will be less than min
    // N/0 is 1 because it is more than 100% or sort of like extra credit
    return n.value === 0 ? -1 : 1;
  }

  return round(n.value / n.total, 0);
}

function valueFormat(n: number | Ratio): number | string {
  if (typeof n === 'number') {
    return n;
  }

  return `${round(n.value, 0)}/${round(n.total, 0)}`;
}

export default function HeatmapChartMetric({
  title,
  series,
  labels,
  from,
  to,
  formatter = v => valueFormat(v),
  variant = 'green',
  thresholds = [0, 0],
  reverse = false,
}: HeatmapChartMetricProps) {
  const classes = useStyles();
  const thresholdColors = useThresholdColors();

  const colors = useMemo(() => {
    const cloned = [
      { backgroundColor: thresholdColors.green, color: contrastTextColor(thresholdColors.green) },
      { backgroundColor: thresholdColors.yellow, color: contrastTextColor(thresholdColors.yellow) },
      { backgroundColor: thresholdColors.red, color: contrastTextColor(thresholdColors.red) },
    ];

    if (reverse) {
      cloned.reverse();
    }

    return cloned;
  }, [reverse, thresholdColors]);

  const maxValue = Math.max(...series.flatMap(s => s.data.map(n => valueOf(n))));

  const cellStyle = (v: number, limit?: number) => {
    if (v < from || v > to) {
      return { color: '#fff', backgroundColor: 'none' };
    }

    if (variant === 'thresholds') {
      let [min, max] = thresholds;

      /**
       * when a different threshold is needed for each cell, limit
       * can be set to change min/max to percentages of the limit (which would be 100%)
       */
      if (limit !== undefined) {
        min = (min / 100) * limit;
        max = (max / 100) * limit;
      }

      if (v < min) {
        return colors[0]; // lower
      }

      if (v > max) {
        return colors[2]; // high
      }

      return colors[1]; // middle
    }

    const opacity = mapNumberToRange(v / maxValue, 0, 1, 0.15, 0.6);

    const bgColor = variant === 'green' ? hexToRGBA('#0f0', opacity) : hexToRGBA('#f00', opacity);
    return {
      color: contrastTextColor(bgColor),
      backgroundColor: bgColor,
    };
  };

  const widthEstimate = series.reduce((acc, x) => {
    if (x.name.length > acc) {
      return x.name.length * 10;
    }
    return acc;
  }, 0);

  return (
    <ChartWrapper chartClassName={classes.chart} title={title}>
      <TableContainer>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell style={{ maxWidth: widthEstimate, minWidth: widthEstimate }}> </TableCell>
              {[...labels].map(label => (
                <TableCell key={label}>{label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {series.map(({ name, data, limit }) => (
              <TableRow key={name}>
                <TableCell>
                  <Typography variant="body1">
                    <strong>{name}</strong>
                  </Typography>
                </TableCell>
                {React.Children.toArray(
                  data.map((v, i) => (
                    <TableCell key={`${name}${i}`} style={cellStyle(valueOf(v), limit && limit[i])}>
                      {formatter(v)}
                    </TableCell>
                  )),
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </ChartWrapper>
  );
}

interface HeatmapChartMetricProps {
  title: string | ReactNode;
  // Labels to use on the x axis
  labels: string[];
  /**
   * Series to display. The name is the name of the series and data is an
   *  array that has the same length as 'labels'
   */
  series: Array<{
    name: string;
    data: Array<number | Ratio>;
    /** @descrip when set, the threshold will be used a percentage of this number for this series */
    limit?: number[];
  }>;
  from: number;
  to: number;
  formatter?: (v: number | Ratio) => number | string;
  variant?: 'red' | 'green' | 'thresholds';
  thresholds?: number[];
  /** @descrip when true, closer to max is green */
  reverse?: boolean;
}
