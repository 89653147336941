import { makeStyles } from '@mui/styles';
import ScoringRefreshButton from '../../../reusables/ScoringRefreshButton';
import Typography from '@mui/material/Typography';
import { ReactNode } from 'react';

const useStyles = makeStyles(() => ({
  title: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
  },
  header: {
    marginBottom: 24,
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
}));

interface TitleProps {
  title: string;
  titleButton?: ReactNode;
  scoredDate?: Date;
  refresh?: () => Promise<void>;
  testIds?: string[];
  children?: ReactNode;
}

export default function Title({
  title,
  scoredDate,
  refresh = () => Promise.resolve(),
  testIds,
  titleButton,
  children,
}: TitleProps) {
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        <Typography variant="h2">{title}</Typography>
        {titleButton}
      </div>
      <div className={classes.headerRight}>
        {!!scoredDate && (
          <ScoringRefreshButton
            scoredDate={scoredDate}
            refresh={refresh}
            testIds={testIds?.length ? testIds : undefined}
          />
        )}
        {children}
      </div>
    </div>
  );
}
