import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import appStore from '../../../../AppStore';
import { liveFireTrainingService, reportingService } from '../../../services';
import AuditTable from './AuditTable';
import ReportingAPI from './ReportingAPI';

const useStyles = makeStyles(() => ({
  root: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    gap: 25,
  },
  button: {
    fontSize: 14,
    color: '#fff',
    borderRadius: 2,
    outline: 'none',
    backgroundColor: '#21bee3',
  },
}));

export default function ExportData() {
  const classes = useStyles();

  const exportLFTData = async () => {
    appStore.beginLoading();

    try {
      const data = await liveFireTrainingService.csv({ isRunning: false });

      // This is hacky, but it works.
      // TODO: Update backend to support a better means of downloading files with JWT token
      const blob = new Blob([data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `download-${Date.now()}.csv`;
      link.click();
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
  };

  const exportReportingData = async () => {
    appStore.beginLoading();

    const today = new Date();
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(today.getFullYear() - 1);

    const reportingData = await reportingService.reportingData(oneYearAgo, today);

    try {
      const stringify = JSON.stringify(reportingData);
      const blob = new Blob([stringify], { type: 'application/json' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `download-${Date.now()}.json`;
      link.click();
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
  };

  return (
    <div className={classes.root}>
      <Typography variant="h3">Export</Typography>
      <Grid container spacing={5} justifyContent='flex-end'>
        <Grid item>
          <Button
            fullWidth
            className={classes.button}
            onClick={exportLFTData}
            variant="contained"
            endIcon={<FontAwesomeIcon size="sm" icon={['fal', 'arrow-right']} />}
          >
            Export LiveFire Training
          </Button>
        </Grid>
        <Grid item>
          <Button
            fullWidth
            className={classes.button}
            onClick={exportReportingData}
            variant="contained"
            endIcon={<FontAwesomeIcon size="sm" icon={['fal', 'arrow-right']} />}
          >
            Export Reporting Data
          </Button>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <AuditTable />
        </Grid>
      </Grid>
      <Grid container style={{ marginTop: 32 }}>
        <Grid item>
          <ReportingAPI />
        </Grid>
      </Grid>
    </div>
  );
}
