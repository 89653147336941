import { capitalize } from '@sightgain/core/strings';
import CsvDownload from '../../../reusables/CsvDownload';
import { formatterCurrency, HeatmapChartMetric } from '../../../reusables/scl';
import { RoiRiskReduction } from '../../../services/interfaces';

export default function TechnologyRiskReductionChart({
  thresholds,
  riskReduction,
  max,
}: TechnologyRiskReductionChartProps) {
  const { labels, series } = riskReduction;

  const datas = () => {
    return series.map(s => ({
      name: s.name,
      ...labels.reduce((acc, l, idx) => {
        acc[l] = `${s.data[idx]}` ?? '';
        return acc;
      }, {} as Record<string, string>),
    }));
  };

  return (
    <HeatmapChartMetric
      title={
        <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
          Technology Risk Reduction By Technique
          <CsvDownload filename="TechnologyRiskReduction.csv" datas={datas} />
        </div>
      }
      labels={labels.map(l => capitalize(l))}
      series={series.map(({ name, data, limit }) => ({
        name: capitalize(name),
        data,
        limit,
      }))}
      from={1}
      to={max}
      formatter={formatterCurrency}
      thresholds={thresholds}
      variant="thresholds"
      reverse
    />
  );
}

interface TechnologyRiskReductionChartProps {
  riskReduction: RoiRiskReduction;
  max: number;
  thresholds: [number, number];
}
