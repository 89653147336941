import { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { curriculumService } from '../../../services';
import {
  TrainingGroupContents,
  TrainingGroupDetails,
  TrainingGroupLivefireContent,
  TrainingGroupPreview,
} from '../../../services/interfaces';
import Header from './Header';
import appStore from '../../../../AppStore';
import Requirement from './Requirement';
import { useAbort } from '../../../../effects';
import SideBox from './SideBox';
import ClassCompleted from './ClassCompleted';
import { Card, CardContent, CardDetails } from '../components/Card';
import LivefireModal from './LivefireModal';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 'calc(100vh - 263px)',
    display: 'flex',
  },
  courseCard: {
    marginTop: 30,
    marginBottom: 30,
  },
  side: {
    background: '#0a0c10',
    width: 214,
    paddingTop: 18,
    paddingBottom: 18,
    height: '100%',
    overflowY: 'scroll',
  },
  main: {
    position: 'relative',
    background: '#303f52',
    flex: 1,
    padding: 69,
    height: '100%',
    overflowY: 'scroll',
  },
  completedOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1000,
  },
  mainDescription: {
    marginTop: 14,
    marginBottom: 30,
  },
  liveFireButton: {
    backgroundColor: '#f67339',
    border: 'none',
    '&:hover': {
      backgroundColor: '#c46034',
    },
  },
  requirements: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > div': {
      width: 347,
    },
    marginTop: 21,
    gap: 21,
  },
  courseHeading: {
    marginBottom: 25,
  },
}));

const guidedTrainingBlurb = 'Reference material evaluates students via knowledge'
  + 'assessments of different techniques, technologies, skill levels, and threat groups.';
const livefireTrainingBlurb = 'Live Fire training evaluates student proficiency to accurately'
  + ' detect and appropriately respond to emulations of malicious activity in a representative environment.';

const sortedClasses = (course: TrainingGroupDetails) => {
  const completed = course.classes.filter(c => c.status === 'completed');
  const inProgress = course.classes.filter(c => c.status === 'inProgress');
  const notStarted = course.classes.filter(c => c.status === 'notStarted');

  return [...inProgress, ...notStarted, ...completed];
};

function SelectedCourse() {
  const classes = useStyles();
  const { curriculumId, courseId } = useParams<{ curriculumId?: string; courseId: string; }>();
  const history = useHistory();
  const [course, setCourse] = useState<TrainingGroupDetails>();
  const [selected, setSelected] = useState<string>('');
  const [dismissed, setDismissed] = useState(false);
  const [classContent, setClassContent] = useState<TrainingGroupContents>();
  const [livefire, setLivefire] = useState<TrainingGroupLivefireContent>();

  const selectedClass = course?.classes.find(c => c.title === selected);
  const selectedClassId = selectedClass?.id;

  useAbort(() => {
    if (!course) {
      return;
    }

    const courseClass = course?.classes.find(c => c.title === selected);
    return curriculumService.trainingGroupContentsSummary(appStore.userId, (courseClass as TrainingGroupPreview).id);
  }, result => {
    if (result) {
      setClassContent(result);
      setDismissed(selectedClass?.status !== 'completed');
    }
  }, [selected]);

  useAbort(() => curriculumService.courseDetails(courseId, appStore.userId), result => {
    setCourse(result);
    setSelected(sortedClasses(result)[0]?.title);
  });

  const onGuidedTraining = () => {
    history.push(`/curriculum/learning/${curriculumId}/course/${courseId}/training/${selectedClassId}`);
  };

  const closeLivefireModel = async () => {
    setLivefire(undefined);
  };

  const startLivefireModal = async () => {
    try {
      await curriculumService.logActivity(appStore.userId, livefire?.id as string, [selectedClassId as string]);
    } catch (err) {
      appStore.error(err);
    }
  };

  const onLiveFire = async (tid: string) => {
    appStore.beginLoading();

    try {
      const lc = await curriculumService.trainingContentLivefire(tid);
      setLivefire(lc);
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
  };

  if (!course || !classContent) {
    return <></>;
  }

  const completedGuidedTrainings = classContent.guidedTraining.filter(g => g.completed).length;
  const completedGuidedTrainingsPercentage = (completedGuidedTrainings / classContent.guidedTraining.length) * 100;

  let backUrl = `/curriculum/learning/${curriculumId}`;
  if (curriculumId === 'activity') {
    backUrl = '/curriculum/activity/courses';
  }

  const sorted = sortedClasses(course);
  
  return (
    <div>
      <Header
        backUrl={backUrl}
        backText={'Back to Courses'}
        progressText="Course Progress"
        progress={course.progress ?? 0}
      />
      <LivefireModal
        open={!!livefire}
        onClose={closeLivefireModel}
        onStart={startLivefireModal}
        content={livefire}
      />
      <Card className={classes.courseHeading}>
        <CardContent variant="dense">
          <CardDetails level={course.difficulty} title={course.title} />
        </CardContent>
      </Card>
      <div className={classes.wrapper}>
        {sorted.length > 1 && (
          <div className={classes.side}>
            {sorted.map(c => (
              <SideBox
                key={c.id}
                title={c.title}
                progress={c.progress ?? 0}
                status={c.status}
                selected={selected}
                setSelected={setSelected}
              />
            ))}
           
          </div>
        )} 
        <div className={classes.main}>
          {!dismissed && (
            <div className={classes.completedOverlay}>
              <ClassCompleted onDismiss={() => setDismissed(true)} />
            </div>
          )}
          <Typography variant="h3">
            {classContent.title}
          </Typography>
          <Typography variant="body1" className={classes.mainDescription}>
            {classContent.description || 'No Description'}
          </Typography>
          <Typography variant="h5">
            Requirements
          </Typography>
          <div className={classes.requirements}>
            {classContent.guidedTraining.length > 0 && (
              <Requirement
                completePercent={completedGuidedTrainingsPercentage}
                label="Reference Material"
                blurb={guidedTrainingBlurb}
                buttonLabel="View Reference Material"
                onClick={onGuidedTraining}
              />
            )}
            {classContent.livefire.map(x => (
              <Requirement
                key={x.id}
                status={x.status} 
                label={x.name ?? 'Live Fire Training'}
                blurb={x.description ?? livefireTrainingBlurb}
                buttonLabel="Take Live Fire Training"
                buttonClassName={classes.liveFireButton}
                onClick={() => onLiveFire(x.id)}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SelectedCourse;
