import { IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactNode, useState } from 'react';
import { ExpandLess } from '@mui/icons-material';

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 6,
    background: '#192637',
    overflow: 'hidden',
    marginBottom: 12,
  },
  ignored: {
    border: '1.5px solid #F46043',
  },
  ignoredText: {
    background: '#F46043',
    padding: 8,
  },
  topRow: {
    display: 'flex',
  },
  left: {
    background: '#203045',
    display: 'flex',
    width: 320,
  },
  id: {
    padding: 24,
  },
  divider: {
    background: '#203045',
    height: 1,
  },
  content: {
    paddingTop: 32,
    paddingLeft: 24,
    paddingRight: 24,
    paddingBottom: 32,
  },
  header: {
    padding: 24,
    flex: 1,
  },
  control: {
    padding: 24,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    color: '#8797AB',
    fontSize: 13,
  },
}));

interface TileDetailCardProps {
  id: ReactNode;
  header: ReactNode;
  content: ReactNode;
  ignored?: boolean;
}

export default function TileDetailCard({ id, header, content, ignored = false }: TileDetailCardProps) {
  const classes = useStyles();
  const [showContent, setShowContent] = useState(false);

  return (
    <div className={`${classes.card} ${ignored && classes.ignored}`}>
      <div className={classes.topRow}>
        <div className={classes.left}>
          {ignored && (
            <div className={classes.ignoredText}>
              <Typography style={{ textAlign: 'center' }}>Ignored</Typography>
            </div>
          )}
          <div className={classes.id}>
            {!ignored && <Typography className={classes.title}>ID</Typography>}
            {typeof id === 'string' ? (
              <Typography color="blue3.main" fontSize={14}>
                {id}
              </Typography>
            ) : (
              id
            )}
          </div>
        </div>
        <div className={classes.header}>{header}</div>
        <div className={classes.control}>
          <IconButton onClick={() => setShowContent(!showContent)} size="large">
            {showContent ? <ExpandLess /> : <ExpandMoreIcon />}
          </IconButton>
        </div>
      </div>
      {showContent && (
        <>
          <div className={classes.divider} />
          <div className={classes.content}>{content}</div>
        </>
      )}
    </div>
  );
}
