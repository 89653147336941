import { FormEvent, useState } from 'react';
import Modal from '../../../../reusables/Modal';
import { makeStyles } from '@mui/styles';
import { TextField, Typography } from '@mui/material';

const useStyles = makeStyles(({
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 12,
    minWidth: 500
  },
  title: {
    padding: '0px 0px 28px 0px'
  },
}));

interface SubTechniqueModalProps {
  open?: boolean;
  onClose: () => void;
  onSave: (name: string) => void;
  name: string;
}

export default function NameModal({ open, onClose, onSave, name }: SubTechniqueModalProps) {
  const classes = useStyles(); 
  const [newName, setNewName] = useState<string>(name);

  const handleSave = async (event: FormEvent) => {
    event.preventDefault();
    onSave(newName);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={handleSave}
      actions={[
        { text: 'Update', color: 'primary', submit: true },
        { text: 'Cancel', onClick: onClose },
      ]}
    >
      <div className={classes.container}>
        <div className={classes.title}><Typography variant="h5">Framework Name</Typography></div>
        <div>
          <TextField fullWidth value={newName} onChange={e => setNewName(e.target.value)} />
        </div>
      </div>
    </Modal>
  );
}