import { makeStyles } from '@mui/styles';
import IconBadge from './IconBadge';
import StackedIconBadge from './StackedIconBadge';

const useStyles = makeStyles({
  tierBadge: {
    display: 'flex',
    textTransform: 'uppercase',
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: '0.6px',
    fontFamily: 'Lexend',
    '&::before': {
      content: '""',
      width: '15px',
      height: '15px',
      borderRadius: '7.5px',
      alignSelf: 'center',
      marginRight: '10px',
    },
  },
  tier3: {
    color: '#f46043',
    '&::before': {
      backgroundColor: '#f46043',
    },
  },
  tier2: {
    color: '#feba06',
    '&::before': {
      backgroundColor: '#feba06',
    },
  },
  tier1: {
    color: '#6af16f',
    '&::before': {
      backgroundColor: '#6af16f',
    },
  },
  livefireBadge: {
    textTransform: 'uppercase',
    color: '#f67339',
    fontSize: 12,
    lineHeight: 1.5,
    '& > svg, & > span > svg': {
      color: '#f67339',
    },
  },
});

const badgeText = {
  'tier-1': 'Beginner',
  'tier-2': 'Intermediate',
  'tier-3': 'Advanced',
};

function TierBadge({ tier }: TierBadgeProps) {
  const classes = useStyles();

  if (!tier) {
    return <span />;
  }

  if (tier === 'livefire') {
    return <IconBadge className={classes.livefireBadge} icon={['fal', 'bolt']}> Live Fire </IconBadge>;
  }

  if (tier === 'livefire-comment') {
    return (
      <StackedIconBadge
        className={classes.livefireBadge}
        icon={['fal', 'bolt']}
        wrapper={['fal', 'comment-alt']}
      >
        &nbsp;Live Fire Evaluation
      </StackedIconBadge>
    );
  }

  const className = tier?.replace('-', '') as 'tier1' | 'tier2' | 'tier3';
  const text = badgeText[tier];

  return <span className={`${classes.tierBadge} ${classes[className]}`}>{text}</span>;
}

type TierBadgeProps = {
  tier?: 'tier-3' | 'tier-2' | 'tier-1' | 'livefire' | 'livefire-comment';
};

export default TierBadge;
