import { makeStyles } from '@mui/styles';
import { Accordion, AccordionSummary, Typography, AccordionDetails, List, ListItem } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Avatar, resolveImage } from '../../../../reusables/scl';
import renderComponent from './renderComponent';

const useStyles = makeStyles(() => ({
  wrapper: {
    backgroundColor: 'unset',
    '&:before': {
      backgroundColor: 'unset',
    },
  },
  summary: {
    padding: 0,
    '& > *': {
      display: 'flex',
      alignItems: 'center',
      '& > *': {
        marginRight: 6,
      },
    },
  },
  title: {
    fontWeight: 'bold',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

interface LessonItem {
  image?: string;
  title?: string;
  content?: {
    component?: string;
    props?: any;
  }[];
}

interface LessonListProps {
  title: string;
  items: LessonItem[];
}

export default function LessonList({ title, items }: LessonListProps) {
  const classes = useStyles();

  if (!items.length) {
    return null;
  }

  return (
    <Accordion className={classes.wrapper}>
      <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List>
          {items.map(item => (
            <ListItem key={item.title}>
              <Accordion className={classes.wrapper}>
                <AccordionSummary className={classes.summary} expandIcon={<ExpandMoreIcon />}>
                  {item.image && <Avatar size="xs" image={resolveImage(item.image)} />}
                  <Typography variant="h5" className={classes.title}>
                    {item.title}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails className={classes.content}>
                  {item?.content?.map(({ component, props }, id) =>
                    renderComponent(`${component}-${id}`, component, props),
                  )}
                </AccordionDetails>
              </Accordion>
            </ListItem>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
}
