import { useState } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { clone } from '@sightgain/core';
import { formatDate } from '@sightgain/core/dates';
import appStore from '../../../../../AppStore';
import { useAbort } from '../../../../../effects';
import FrameworksService from '../../../../services/FrameworksService';
import {
  Framework,
  FrameworkGroup,
  FrameworkGroupSource,
  FrameworkItem,
  FrameworkItemSource,
  FrameworkSource,
} from '../../../../services/interfaces';
import FrameworkView from './FrameworkView';
import NameModal from './NameModal';

const mapFrameworkToFrameworkSource = (inputFramework: Framework): FrameworkSource => {
  const mapFrameworkItemToFrameworkItemSource = (item: FrameworkItem): FrameworkItemSource => ({
    description: item.description,
    id: item.identifier,
    sub: item.sub?.map((subItem: FrameworkItem) => mapFrameworkItemToFrameworkItemSource(subItem)),
    title: item.name,
  });

  const mapFrameworkGroupToFrameworkGroupSource = (group: FrameworkGroup, idx: number): FrameworkGroupSource => ({
    active: true, // You can adjust this as needed
    items: group.items.map((item: FrameworkItem) => mapFrameworkItemToFrameworkItemSource(item)),
    order: idx, // You can adjust this as needed
    title: group.name,
    type: 'techniques', // You can adjust this as needed
  });

  return {
    framework: inputFramework.name,
    groups: inputFramework.groups.map(mapFrameworkGroupToFrameworkGroupSource),
    lastModified: new Date().toISOString(),
    title: inputFramework.title,
    type: inputFramework.type ?? 'control',
    version: inputFramework.version,
    isSystem: inputFramework.isSystem,
  };
};

const useStyles = makeStyles(() => ({
  page: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    gap: 12,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 32,
  },
  content: {
    height: '100%',
    display: 'flex',
    flex: 1,
  },
}));

export default function FrameworkEditor() {
  const classes = useStyles();
  const { fw = 'framework' } = useParams<{ fw: string }>();
  const [framework, setFramework] = useState<Framework | undefined>(undefined);
  const [frameworkDraft, setFrameworkDraft] = useState<Framework | undefined>(undefined);
  const [openNameModal, setOpenNameModal] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);

  useAbort(
    () => Promise.all([FrameworksService.find(fw, 'latest', 1), FrameworksService.find(fw, 'latest', 2)]),
    ([fw, fwDraft]) => {
      setFramework(fw);

      if (fwDraft) {
        setFrameworkDraft(fwDraft);
      } else {
        setFrameworkDraft(clone(fw));
      }
    },
    [refresh],
  );

  // wait for framework
  if (!framework || !frameworkDraft) {
    return <></>;
  }

  const handleNameSave = (name: string) => {
    // immer or deep clone needed?
    setFrameworkDraft({ ...frameworkDraft, title: name });
    setOpenNameModal(false);
  };

  const handleSave = async () => {
    appStore.beginLoading();

    try {
      // convert to framework source
      const framework = mapFrameworkToFrameworkSource(frameworkDraft);

      framework.revision = 2;

      // save frameworkDraft api call
      await FrameworksService.saveFramework({ framework });
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
    setRefresh(refresh + 1);
  };

  return (
    <div data-testid="editor-page" className={classes.page}>
      <div style={{ marginBottom: 19 }}>
        <Button
          component={Link}
          to="/risk/management"
          color="primary"
          startIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-left']} size="lg" />}
        >
          Back to Framework Management
        </Button>
      </div>
      <div className={classes.header}>
        <div style={{ display: 'flex', gap: 12 }}>
          <Typography variant="h2">{frameworkDraft.title}</Typography>
          <IconButton onClick={() => setOpenNameModal(true)} size="small">
            <FontAwesomeIcon icon={['far', 'pencil-alt']} color="#2ecff5" />
          </IconButton>
        </div>
        <div style={{ display: 'flex', gap: 24 }}>
          <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
            <Typography>
              <span style={{ color: '#8797AB' }}>Created: </span>
              {formatDate(framework?.createdAt)}
            </Typography>
            <Typography>
              <span style={{ color: '#8797AB' }}>Modified: </span>
              {formatDate(frameworkDraft?.updatedAt)}
            </Typography>
          </div>
          <Button variant="contained" onClick={handleSave}>
            Save Framework
          </Button>
        </div>
      </div>

      <div className={classes.content}>
        <FrameworkView framework={framework} frameworkDraft={frameworkDraft} setFrameworkDraft={setFrameworkDraft} />
      </div>
      <NameModal
        open={openNameModal}
        onClose={() => setOpenNameModal(false)}
        onSave={handleNameSave}
        name={frameworkDraft.title}
      />
    </div>
  );
}
