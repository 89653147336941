import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useContext, useState } from 'react';
import { Bar, BarChart, ResponsiveContainer } from 'recharts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { divideSafely, round } from '@sightgain/core/calculations';
import { useAbort } from '../../../../effects';
import { LargeNumericMetric } from '../../../reusables/metrics';
import { Snr, SnrResult } from '../../../services/interfaces';
import socService from '../../../services/SocService';
import SocContext from './SocContext';

const useStyles = makeStyles(() => ({
  ratioChart: {
    width: 200,
  },
  ratio: {
    display: 'flex',
  },
  ratioMessage: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  socAlerts: {
    flexBasis: '50%',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: '#0b0c12',
    borderRadius: 6,
    alignItems: 'center',
  },
  ratioAlert: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '100%',
  },
  ratioTitle: {
    margin: 0,
  },
  alertStatus: {
    height: 23,
    padding: '2px 10px 3px 8px',
    borderRadius: 3,
    border: 'solid 1px #e0694d',
    backgroundColor: 'rgba(244, 105, 77, 0.49)',
  },
  alertStatusText: {
    fontWeight: 'bold',
  },
  callToAction: {
    fontWeight: 'bold',
    color: '#21bee3',
    textDecoration: 'none',
    lineHeight: '1.14',
  },
}));

export default function AlertSummary() {
  const { timeSpan } = useContext(SocContext);
  const classes = useStyles();
  const [snr, setSnr] = useState<Snr>();

  useAbort(
    () => {
      const today = endOfDay(new Date());
      const fromDate = startOfDay(subDays(today, timeSpan));
      return socService.snr(startOfDay(fromDate), endOfDay(today));
    },
    (result: SnrResult) => setSnr(result.snr),
    [timeSpan],
  );

  if (snr === undefined) {
    return <div className={classes.socAlerts} />;
  }

  const { signal, noise } = snr;
  let dividedSignal = signal === 0 ? 0 : 1;
  let dividedNoise = noise === 0 ? 0 : 1;

  if (signal > noise) {
    dividedSignal = noise === 0 ? signal : divideSafely(signal, noise);
  } else {
    dividedNoise = signal === 0 ? noise : divideSafely(noise, signal);
  }

  const ratioMetric = `${round(dividedSignal, dividedSignal > 1 ? 0 : 2)}:${round(
    dividedNoise,
    dividedNoise > 1 ? 0 : 2,
  )}`;

  if (signal === 0 && noise === 0) {
    return (
      <div className={classes.socAlerts} style={{ justifyContent: 'center' }}>
        <FontAwesomeIcon icon={['fal', 'signal-alt-slash']} size="2x" />
        <Typography variant="h5" style={{ marginLeft: 12 }}>
          No SIEM Data Reported
        </Typography>
      </div>
    );
  }

  let alertMsg;
  if (dividedSignal <= 1 && dividedNoise > 5) {
    alertMsg = 'Tune SIEM to suppress nuisance alerts';
  } else if (dividedNoise <= 1 && dividedSignal > 1.5) {
    alertMsg = 'Tune SIEM to alert malicious activity';
  }

  return (
    <div className={classes.socAlerts}>
      <div className={classes.ratio}>
        <div className={classes.ratioChart} style={{ width: 100, height: 100 }}>
          <ResponsiveContainer>
            <BarChart data={[{ name: 'series-1', fv: signal, tv: noise }]} barGap={7} barSize={14}>
              <Bar dataKey="fv" fill="#2ecff5" />
              <Bar dataKey="tv" fill="#f46043" />
            </BarChart>
          </ResponsiveContainer>
        </div>
        <div className={classes.ratioMessage}>
          <LargeNumericMetric value={ratioMetric} label="SIEM signal to noise ratio" animate={false} />
        </div>
      </div>
      <Divider orientation="vertical" flexItem />
      <div className={classes.ratioAlert}>
        {!alertMsg && <Typography variant="h5">No SIEM Alerts</Typography>}
        {alertMsg && (
          <>
            <Typography variant="h5" className={classes.ratioTitle}>
              Evaluation
            </Typography>
            <div className={classes.alertStatus}>
              <Typography variant="body2" className={classes.alertStatusText}>
                {alertMsg}
              </Typography>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
