import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { Recommendation } from '../pages/Risk/Recommendations/interfaces';
import ServiceBase from './ServiceBase';

const typeRecommendation = `
  {
    id
    type
    title
    content
    detail
    status
    date
    riskLevel
  }
`;

export class RecommendationsService extends ServiceBase {
  constructor() {
    super();
    // TODO: add date after converting to date object
    this.addMiddleWare('after', data => toDates(data, ['created', 'date']));
  }

  async list(fields = typeRecommendation): Promise<Recommendation[]> {
    const query = gql`
      query Recommendations {
        recommendations ${fields}
      }
    `;

    const { recommendations } = await this.graphql(query, {}, 'network-only');
    return recommendations;
  }

  async ignore(ids: string[], fields = typeRecommendation): Promise<string> {
    const query = gql`
      mutation IgnoreRecommendations($ids: [ID!]!) {
        ignoreRecommendations(ids: $ids)
      }
    `;

    const { ignoreRecommendations } = await this.graphql(query, { ids });
    return ignoreRecommendations;
  }

  async resolve(ids: string[], fields = typeRecommendation): Promise<string> {
    const query = gql`
      mutation resolveRecommendations($ids: [ID!]!) {
        resolveRecommendations(ids: $ids)
      }
    `;

    const { resolveRecommendations } = await this.graphql(query, { ids });
    return resolveRecommendations;
  }

  async restore(ids: string[], fields = typeRecommendation): Promise<string> {
    const query = gql`
      mutation restoreRecommendations($ids: [ID!]!) {
        restoreRecommendations(ids: $ids)
      }
    `;

    const { restoreRecommendations } = await this.graphql(query, { ids });
    return restoreRecommendations;
  }
}

const recommendationsService = new RecommendationsService();
export default recommendationsService;
