import { useCallback, useMemo } from 'react';
import useSocketListener from '../../../reusables/useSocketListener';
import { curriculumService } from '../../../services';
import { StudentWeeklyProgress } from '../../../services/interfaces';

export default function useWeeklyProgress(student: string) {
  const [initArgs, value] = useMemo(() => [{ student }, [] as StudentWeeklyProgress[]], [student]);
  const callback = useCallback(async (body: { student: string }) => {
    if (body.student !== student) {
      return;
    }

    return await curriculumService.studentWeeklyProgress(student);
  }, [student]);

  return useSocketListener('quiz', 'update', callback, initArgs, value) as StudentWeeklyProgress[];
}