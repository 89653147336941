import { useContext, useEffect } from 'react';
import { capitalize } from '@sightgain/core/strings';
import { EvaluationStepProps } from '../../interfaces';
import EvaluationWindowContext from '../EvaluationWindowContext';

/**
 * @param isRunnable a value of false is the default waiting status.
 * True will enable the next button. A string value will be displayed as an error.
 */
function Description({ runner }: EvaluationStepProps) {
  const { selected, isRunnable, handleDisableNextBtn } = useContext(EvaluationWindowContext);

  useEffect(() => {
    handleDisableNextBtn(isRunnable !== true);
  }, [isRunnable, handleDisableNextBtn]);

  if (!selected) {
    return <></>;
  }

  return (
    <div>
      <h2 id="evalutionTitle">{selected.name}</h2>
      {'vendorId' in selected && <h4 id="evaluationVid">{`${capitalize(selected.vendor)} - ${selected.vendorId}`}</h4>}
      {selected.description && <p>{selected.description}</p>}
      {typeof isRunnable === 'string' && <p style={{ color: 'red', fontWeight: 'bold' }}>{isRunnable}</p>}
    </div>
  );
}

Description.label = 'Select an {label}';

export default Description;
