import { useCallback, useMemo } from 'react';
import { examsService } from '../services';
import { ExamEvent } from '../services/interfaces';
import useSocketListener from './useSocketListener';

export default function useExamEvents(userId: string): ExamEvent[] {
  const [initArgs, value] = useMemo(() => [{ attendee: userId }, []], [userId]);

  const callback = useCallback(async (body: { attendee: string }) => {
    if (body.attendee !== userId) {
      return;
    }

    return await examsService.events(userId);
  }, [userId]);

  return useSocketListener('examEvent', 'update', callback, initArgs, value) as ExamEvent[];
}