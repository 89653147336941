import { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@mui/styles';
import { Button } from '@mui/material';
import clsx from 'clsx';
import {
  eachDayOfInterval,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  format,
  addMonths,
  subMonths,
} from 'date-fns';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  frame: {
    fontFamily: 'Lexend',
    width: '931px',
  },
  innerFrame: {
    borderRadius: '6px',
    backgroundColor: 'rgba(255, 255, 255, 0.09)',
  },
  frameSmall: {
    width: '692px',
  },
  frameAuto: {
    width: '100%',
  },
  gradient: {
    zIndex: -1,
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '80px',
    padding: '46px 51px 55px',
    opacity: 0.48,
    backgroundImage: 'linear-gradient(rgba(41, 75, 106, 0) 5%, rgba(39, 72, 101, 0.02) 50%, rgba(0, 0, 0, 0.51) 100%)',
  },
  body: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    position: 'relative',
  },
  day: {
    width: '14.2%',
    height: '60px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontStretch: 'normal',
    fontStyle: 'normal',
    letterSpacing: 'normal',
    textAlign: 'center',
    color: '#ffffff',
    fontSize: '14px',
  },
  daySmall: {
    height: '50px',
  },
  dayHeader: {
    fontSize: '16px',
    fontWeight: 'bold',
  },
  circle: {
    width: '36px',
    height: '36px',
    borderRadius: '18px',
    lineHeight: '36px',
    textAlign: 'center',
    background: '#000',
    cursor: 'pointer',
  },
  selected: {
    width: '36px',
    height: '36px',
    borderRadius: '36px',
    lineHeight: '36px',
    textAlign: 'center',
    background: '#6A6C6E',
    cursor: 'pointer',
  },
  circleSmall: {
    display: 'inline-block',
    width: '16px',
    height: '16px',
    borderRadius: '8px',
    lineHeight: '16px',
    background: '#000',
  },
  scheduled: {
    background: '#1592ff',
  },
  key: {
    display: 'inline-block',
    verticalAlign: 'top',
    marginLeft: '8px',
    fontSize: '12px',
  },
  otherMonth: {
    color: '#a7adb5',
  },
  button: {
    color: '#21bee3',
    all: 'unset',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '10px',
  },
  headerLeft: {
    width: '50%',
    fontWeight: 'bold',
    fontSize: '12px',
    letterSpacing: '0.6px',
  },
  headerRight: {
    width: '50%',
    textAlign: 'right',
    fontSize: '14px',
    fontWeight: 'bold',
  },
  large: {
    height: 467,
  },
  small: {
    height: 390,
  },
  hover: {
    '&:hover': {
      width: '36px',
      height: '36px',
      borderRadius: '18px',
      lineHeight: '36px',
      textAlign: 'center',
      background: '#6A6C6E',
      cursor: 'pointer',
    },
  },
  footer: {
    display: 'flex',
    paddingBottom: 18,
    paddingTop: 18,
  },
  footerLeft: {
    width: '50%',
    '& > div': {
      marginLeft: '30px',
      display: 'flex',
      alignItems: 'center',
    },
  },
  footerRight: {
    width: '50%',
    textAlign: 'right',
    '& > div': {
      marginRight: '30px',
    },
  },
}));

function daysInView(date: Date) {
  return eachDayOfInterval({
    start: startOfWeek(startOfMonth(date)),
    end: endOfWeek(endOfMonth(date)),
  });
}

interface CalendarProps {
  date?: Date;
  scheduled?: Date[];
  completed?: Date[];
  trainingMins?: number | null;
  small?: boolean;
  large?: boolean;
  autoWidth?: boolean;
  onClick: (v: Date) => void;
  userId?: string;
  hover?: boolean;
}

export default function Calendar({
  date = new Date(new Date().setHours(0, 0, 0, 0)),
  scheduled = [],
  completed = [],
  trainingMins = null,
  small = false,
  large = false,
  autoWidth = false,
  userId = '',
  onClick = () => {},
  hover = false,
}: CalendarProps) {
  const classes = useStyles();
  const [today, setToday] = useState(date);
  const [selected, setSelected] = useState(today);
  const daysOfWeek = ['SU', 'M', 'T', 'W', 'TH', 'F', 'S'];
  const days = daysInView(today);

  const scheduledDays = scheduled.map(day => format(day, 'MMddyyyy'));
  const completedDays = completed.map(day => format(day, 'MMddyyyy'));

  const otherMonth = (day: Date) => {
    if (format(day, 'MMyyyy') !== format(today, 'MMyyyy')) {
      return classes.otherMonth;
    }
  };

  const circle = (day: Date) => {
    const dateString = format(day, 'MMddyyyy');

    if (day.toString() === selected.toString()) {
      return classes.selected;
    }
    if (completedDays.includes(dateString)) {
      return classes.circle;
    }
    if (scheduledDays.includes(dateString)) {
      return clsx(classes.circle, classes.scheduled);
    }
    if (hover) {
      return classes.hover;
    }
  };

  const handleClick = (day: Date) => {
    setSelected(day);
    onClick(day);
  };

  return (
    <div
      className={clsx(classes.frame, {
        [classes.frameSmall]: small === true,
        [classes.frameAuto]: autoWidth === true,
      })}
    >
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <span>SCHEDULE</span>
          <span style={{ color: '#a7adb5' }}>
            {' / '}
            {format(today, 'MMMM').toUpperCase()}
          </span>
        </div>
        <div className={classes.headerRight}>
          {small && (
            <div>
              <Button component={Link} to={`/curriculum/calendar/${userId}`} className={classes.button}>
                View Calendar
                <FontAwesomeIcon icon={['fal', 'long-arrow-right']} size="lg" />
              </Button>
            </div>
          )}
          {!small && (
            <div style={{ cursor: 'pointer' }}>
              <FontAwesomeIcon
                icon={['fal', 'long-arrow-left']}
                size="2x"
                onClick={() => setToday(subMonths(today, 1))}
              />
              <FontAwesomeIcon
                icon={['fal', 'long-arrow-right']}
                size="2x"
                onClick={() => setToday(addMonths(today, 1))}
                style={{ marginLeft: '24px' }}
              />
            </div>
          )}
        </div>
      </div>
      <div className={classes.innerFrame}>
        <div className={clsx(classes.body, { [classes.large]: large, [classes.small]: small })}>
          {daysOfWeek.map((day, dayIndex) => (
            <div className={clsx(classes.day, classes.dayHeader)} key={dayIndex}>
              {day}
            </div>
          ))}
          {days.map((day, dayIndex) => (
            <div className={clsx(classes.day, { [classes.daySmall]: small === true })} key={dayIndex}>
              <div className={clsx(circle(day), otherMonth(day))} onClick={() => handleClick(day)} role="presentation">
                {format(day, 'd')}
              </div>
            </div>
          ))}
          <div className={classes.gradient} />
        </div>
        <div className={classes.footer}>
          <div className={classes.footerLeft}>
            <div>
              <div className={clsx(classes.circleSmall, classes.scheduled)} />
              <div className={classes.key}>Scheduled</div>
              <div className={classes.circleSmall} style={{ marginLeft: '32px' }} />
              <div className={classes.key}>Completed</div>
            </div>
          </div>
          <div className={classes.footerRight}>
            <div>
              {trainingMins && (
                <>
                  <strong>{trainingMins.toLocaleString()}</strong> Total Minutes Training
                  <span style={{ color: '#1592ff' }}> / </span>
                </>
              )}
              {format(today, 'MMMM yyyy')}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
