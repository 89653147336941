import { useCallback, useMemo } from 'react';
import { MgrListItem } from './interfaces';
import useSocketListener from '../../../../reusables/useSocketListener';
import { evaluationsService, examsService } from '../../../../services';
import { Evaluation } from '../../../../services/interfaces';

const EVALUATION_FIELDS = 
  '{ id name description vendorId vendor stages { tests { id vendorId } } isCustom lastRun { createdAt status } }';
const EXAM_FIELDS = '{ id name description testIds vendor }';

export async function listEvaluations(vendor: string, isExams = false) {
  if (isExams) {
    return await examsService.list(EXAM_FIELDS, 'network-only');
  }

  const evaluations = await evaluationsService.list(undefined, vendor, EVALUATION_FIELDS, 'network-only');
  return evaluations.map(evaluationToMgrListItem);
}

function evaluationToMgrListItem(evaluation: Evaluation) {
  const { id, name, description, vendor, vendorId, stages, isCustom, lastRun } = evaluation;
  const testIds = stages.flatMap(s => s.tests.map(t => t.vendorId));

  return { id, name, description, vendor, vendorId, testIds, isCustom, lastRun };
}

export default function useEvaluationsWatcher(vendor: string, isExams = false) {
  const callback = useCallback(() => {
    return listEvaluations(vendor, isExams);
  }, [vendor, isExams]);

  const value = useMemo(() => isExams ? 'loading' : 'pending', [isExams]);

  const evaluations = useSocketListener<MgrListItem[] | string>(
    'customEvaluation', 'update', callback, undefined, value);

  return evaluations;
}