import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { FrameworkScoringInput, ScoredFramework, ScoredFrameworkItem } from './interfaces';
import ServiceBase from './ServiceBase';

const typeScoredFrameworkItem = `
{
  name
  sub
  testIds { identifier test }
  identifier testTrend postureTrend variance
  testScore { score isValid }
  postureScore { score isValid }
  threatScore { score isValid }
  assessorScore
  assessorValue
  testsRan
}
`;

const typeScoredFramework = `
{
  id 
  name 
  title 
  version 
  type 
  updatedAt 
  threatTrends
  toDate 
  groups {
    name items ${typeScoredFrameworkItem}
  }
  observedZones {
    id
    name
  }
}
`;

class FrameworkScoringService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['created', 'updatedAt', 'from', 'to']));
  }

  async frameworkScoring(input: FrameworkScoringInput, fields = typeScoredFramework): Promise<ScoredFramework> {
    const query = gql`query FrameworkScoring($input: FrameworkScoringInput!) { frameworkScoring(input: $input) ${fields} }`;
    const { frameworkScoring } = await this.graphql(query, { input }, 'no-cache');

    return frameworkScoring;
  }

  async refreshItem(
    input: FrameworkScoringInput,
    identifier: string,
    fields = typeScoredFrameworkItem,
  ): Promise<ScoredFrameworkItem> {
    const query = gql`mutation RefreshFrameworkScoringItem($input: FrameworkScoringInput!, $identifier: String!) { refreshFrameworkScoringItem(input: $input, identifier: $identifier) ${fields} }`;
    const { refreshFrameworkScoringItem } = await this.graphql(query, { input, identifier }, 'no-cache');
    return refreshFrameworkScoringItem;
  }

  async refreshScoring(input: FrameworkScoringInput, fields = typeScoredFramework): Promise<ScoredFramework> {
    const query = gql`mutation RefreshFrameworkScoring($input: FrameworkScoringInput!) { refreshFrameworkScoring(input: $input) ${fields} }`;
    const { refreshFrameworkScoring } = await this.graphql(query, { input }, 'no-cache');
    return refreshFrameworkScoring;
  }
}

const frameworkScoringService = new FrameworkScoringService();
export default frameworkScoringService;
