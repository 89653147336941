import { gql } from '@apollo/client';
import { OrganizationOverview } from './interfaces';
import ServiceBase from './ServiceBase';

const typeScorePerformance = '{ label performance { responseTime responseScore precisionScore score trainings } }';
const typeResultPerformance = '{ label performance { prevented detected alerted results } }';
const typeOrgOverview = `
  {
    techPerformanceByAdversary ${typeResultPerformance}
    techPerformanceByTactic ${typeResultPerformance}
    techPerformanceOverTime ${typeResultPerformance}
    techPreventionRates ${typeResultPerformance}
    personnelPerformanceByAdversary ${typeScorePerformance}
    personnelPerformanceByTactic ${typeScorePerformance}
    personnelPerformanceOverTime ${typeScorePerformance}
  }
`;

class OrganizationOverviewService extends ServiceBase {
  async overview(fields = typeOrgOverview): Promise<OrganizationOverview> {
    const query = gql`query OrganizationOverview { organizationOverview ${fields} }`;
    const { organizationOverview } = await this.graphql(query, {}, 'no-cache');
    return organizationOverview;
  }
}

const organizationOverviewService = new OrganizationOverviewService();
export default organizationOverviewService;