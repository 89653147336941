import { Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { FormEvent, useState } from 'react';
import parse from 'html-react-parser';
import userPreferencesService from '../../services/UserPreferencesService';
import Modal from '../Modal';
import { settingsService } from '../../services';
import appStore from '../../../AppStore';
import { useAbort } from '../../../effects';

export default observer(function EULAModal() {
  const { isLoggedIn } = appStore;
  const [eula, setEula] = useState<boolean>(true);
  const [eulaText, setEulaText] = useState<string>('');

  useAbort(() => {
    if (!isLoggedIn) {
      return;
    }

    return userPreferencesService.find('eula');
  }, pref => {
    if (!pref) {
      return;
    }
    setEula(JSON.parse(pref.value));
  }, [isLoggedIn]);

  useAbort(() => {
    if (eula) {
      return;
    }

    return settingsService.find('eula');
  }, setting => setEulaText(setting?.value ?? ''), [eula], undefined, false);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    setEula(true);
    return userPreferencesService.set('eula', 'true');
  };

  return (
    <Modal
      open={!!(!eula && eulaText)}
      title="Software License and Services Agreement"
      actions={[{ submit: true, text: 'Accept', color: 'primary' }]}
      fullWidth
      onClose={() => undefined}
      onSubmit={handleSubmit}
      centerActions
    >
      <Typography variant="body1">
        {parse(eulaText.replace(/\r?\n/g, '<br />'))}
      </Typography> 
      <hr />
    </Modal>
  );
});