import { ChangeEvent, useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, TextField, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  wrapper: {
    borderRadius: 6,
    backgroundColor: '#313F53',
    padding: '27px 37px 48px',
    height: '100%',
    minWidth: 450,
    '&:hover': {
      '& > div > button': {
        opacity: 1,
      },
    },
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  collapse: {
    transitionDuration: '300ms',
    opacity: 0,
    '&:hover': {
      background: 'none',
    },
  },
  eventInput: {
    '& > div': {
      '& textarea': {
        padding: '11px 18px',
      },
    },
  },
  input: {
    marginTop: 12,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    borderRadius: 3,
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
    '& > div': {
      '&:before': {
        border: 0,
      },
      '&:after': {
        border: 0,
      },
      '& input': {
        padding: '11px 18px',
      },
    },
  },
  wrapperCollapsed: {
    height: '100%',
    width: 62,
    borderRadius: 6,
    backgroundColor: '#313F53',
    padding: '27px 37px 48px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  arrowCollapsed: {
    color: '#4a5169',
    height: 20,
    marginBottom: 14,
  },
  textCollapsed: {
    writingMode: 'vertical-lr',
    transform: 'rotate(180deg)',
  },
}));

function AnalystFeedbackAndInstructorScore({
  feedback,
  handleNotesChange,
  showButtons,
  instructorScore,
  handleScoreChange,
}: AnalystFeedbackAndInstructorScoreProps) {
  const classes = useStyles();
  const [collapsed, setCollapsed] = useState(false);

  if (collapsed) {
    return (
      <div
        className={classes.wrapperCollapsed}
        onClick={() => setCollapsed(false)}
        onKeyPress={() => setCollapsed(false)}
        tabIndex={0}
        role="button"
      >
        <FontAwesomeIcon className={classes.arrowCollapsed} icon={['fas', 'chevron-right']} />
        <Typography className={classes.textCollapsed} variant="h5">Analyst Feedback</Typography>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <Typography variant="h3">Analyst Feedback</Typography>
        <IconButton
          className={classes.collapse}
          onClick={() => setCollapsed(true)}
          size="large">
          <FontAwesomeIcon icon={['fal', 'minus']} />
        </IconButton>
      </div>
      <TextField
        className={`${classes.input} ${classes.eventInput}`}
        multiline
        rows={13}
        value={feedback}
        onChange={handleNotesChange}
        disabled={!showButtons}
      />
      <Typography variant="h5" style={{ marginTop: 31 }}>Instructor Score</Typography>
      <TextField
        className={`${classes.input} ${classes.eventInput}`}
        value={instructorScore}
        onChange={handleScoreChange}
        disabled={!showButtons}
      />
    </div>
  );
}

interface AnalystFeedbackAndInstructorScoreProps {
  feedback: string;
  handleNotesChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
  showButtons: boolean;
  instructorScore: number;
  handleScoreChange: (e: ChangeEvent<HTMLInputElement>) => unknown;
}

export default AnalystFeedbackAndInstructorScore;
