import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  subTitle: {
    color: '#f46043',
    marginBottom: 45,
  },
}));

function QueuedStatus({ trainings }: { trainings: number }) {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.subTitle} variant="h4">
        You have {trainings} training{trainings > 1 && 's'} ahead of you, please wait.
      </Typography>
    </>
  );
}

export default QueuedStatus;
