import { Button, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import LicenseCard from './LicenseCard';
import { useState } from 'react';
import { AddLicenseInput, License, RenewLicenseInput } from '../../../services/interfaces';
import licenseService from '../../../services/LicenseService';
import { useAbort } from '../../../../effects';
import RenewModal from './RenewModal';
import appStore from '../../../../AppStore';
import AddModal from './AddModal';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    marginBottom: 24,
  },
  controls: {
    display: 'flex',
    gap: 24
  },
  nolicenses: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export default function Licensing() {
  const classes = useStyles();
  const [filter, setFilter] = useState<string>('all');
  const [licenses, setLicenses] = useState<License[]>([]);
  const [license, setLicense] = useState<License | undefined>(undefined);
  const [openAddModal, setOpenAddModal] = useState<boolean>(false);
  const [refresh, setRefresh] = useState<number>(0);

  useAbort(
    () => licenseService.list(),
    result => {
      setLicenses(result);
    }, [refresh]
  );

  const handleRenew = async (input: RenewLicenseInput) => {
    appStore.beginLoading();
    try {
      await licenseService.renew(input);
      setRefresh(refresh + 1);
      setLicense(undefined);
      appStore.success('License updated');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };
  
  const handleAdd = async (input: AddLicenseInput) => {
    appStore.beginLoading();
    try {
      await licenseService.add(input);
      setRefresh(refresh + 1);
      setOpenAddModal(false);
      appStore.success('Licenses added');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };
  
  const filterFn = (license: License) => {
    if (filter === 'all') {
      return true;
    }

    if (license.status === filter) {
      return true;
    }

    return false;
  };

  return (
    <div>
      <div className={classes.header}>
        <Typography variant="h3">License Management</Typography>
        <div className={classes.controls}>
          <Select value={filter} onChange={e => setFilter(e.target.value)} defaultValue="all" style={{ width: 200 }}>
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="active">Active</MenuItem>
            <MenuItem value="expired">Expired</MenuItem>
          </Select>
          <Button variant="contained" onClick={() => setOpenAddModal(true)}>Add License</Button>
        </div>
      </div>
      <div>
        {licenses
          .filter(filterFn)
          .map(l => <LicenseCard key={l.id} license={l} onRenew={() => setLicense(l)} onExtend={() => setLicense(l)} />)
        }
      </div>
      {!licenses.length && (
        <div className={classes.nolicenses}>
          <Typography variant="h4">No licenses found</Typography>
        </div>
      )}
      {!!license &&
        <RenewModal
          open={!!license}
          onAdd={handleRenew}
          onClose={() => setLicense(undefined)}
          license={license}
        />
      }
      {openAddModal &&
        <AddModal
          open={openAddModal}
          onAdd={handleAdd}
          onClose={() => setOpenAddModal(false)}
        />
      }
    </div>
  );
}
