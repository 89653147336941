import { format } from 'date-fns';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import { LastRun } from '../../../../../services/interfaces';

const useStyles: any = makeStyles({
  running: {
    color: '#117fba',
  },
  queued: {
    color: 'rgba(71, 146, 247, 0.28)',
  },
  waiting: {
    color: 'rgba(71, 146, 247, 0.28)',
  },
  ended: {
    color: '#5d6878',
  },
  completed: {
    color: '#21bee3',
  },
  errored: {
    color: '#f85c5c',
  },
  cancelled: {
    color: '#a7adb5',
  },
  discarded: {
    color: '#a7adb5',
  },
  passed: {
    color: '#0a0c10',
  },
  released: {
    color: 'rgb(0, 255, 177)',
  },
});

export default function LastRunText({ lastRun }: { lastRun?: LastRun }) {
  const classes = useStyles();

  return (
    <>
      <span className={lastRun?.status && classes[lastRun?.status]}>{capitalize(lastRun?.status ?? '')}</span>{' '}
      {lastRun?.createdAt && format(lastRun.createdAt, 'yyyy-MM-dd, HH:mm')}
    </>
  );
}
