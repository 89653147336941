import { observer } from 'mobx-react';
import { Route, } from 'react-router-dom';
import appStore from '../../../AppStore';
import ErrorPage from '../../pages/ErrorPage';
import { ReactNode, useEffect } from 'react';
import { useKeycloak } from '@react-keycloak/web';
import { usersService } from '../../services';
import { useAbort } from '../../../effects';
import { defaultAccess } from '../Navigation/utils';

function PrivateRoute({ children, roles = defaultAccess, ...rest }: PrivateRouteProps) {
  const { keycloak } = useKeycloak();
  
  useAbort(() => {
    if (!appStore.user && !!keycloak.authenticated && keycloak.idTokenParsed?.sub) {
      return usersService.sso(keycloak.idTokenParsed.sub, keycloak.token ?? '');
    }
    return Promise.resolve(undefined);
  }, user => {
    if (user) {
      appStore.setTokens(keycloak.refreshToken ?? '', keycloak.token ?? '', user);
    }
  });

  useEffect(() => {
    async function redirect() {
      if (!keycloak.authenticated) {
        await keycloak.login();
      }
    }
    redirect();
  });

  // return nothing, if we are still not authenticated during react re-renders
  if (!keycloak.authenticated || !appStore.user) {
    return null;
  }

  let valid = false;
  if (roles.length === 0) {
    valid = true;
  } else {
    for (const role of roles) {
      if (keycloak.hasRealmRole(role)) {
        valid = true;
        break;
      }
    }
  }
  
  if (!valid) {
    return (
      <Route {...rest}>
        <ErrorPage
          title="Forbidden"
          message="403: Sorry but you do not have the appropriate roles to access this page"
        />
      </Route>
    );
  }

  return <Route {...rest}>{children}</Route>;
}

interface PrivateRouteProps {
  children: ReactNode;
  roles?: string | string[];
  [prop: string]: any;
}

export default observer(PrivateRoute);
