import { useState } from 'react';
import {
  InputAdornment,
  IconButton,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import TextField from './TextField';

export default function PasswordText({ password, ...props }: PasswordTextProps) {
  const [show, setShow] = useState(false);

  return (
    <TextField
      {...props}
      value={password}
      type={!show ? 'password' : 'text'}
      endAdornment={(
        <InputAdornment position="end">
          <IconButton onClick={() => setShow(!show)} size="large">
            {show && <Visibility />}
            {!show && <VisibilityOff />}
          </IconButton>
        </InputAdornment>
      )}
      inputProps={{ 'data-testid': props.label }}
    />
  );
}

interface PasswordTextProps {
  label: string;
  password: string;
  error?: string;
  onChange: (e: { target: { value: string } }) => unknown;
}
