import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Assessor } from '../../../../../services/interfaces';
import TileAssessor from './TileAssessor';

const useStyles = makeStyles(() => ({
  cards: {
    marginTop: 24,
    gap: 12,
    height: 'calc(100vh - 320px)',
    overflowY: 'scroll'
  },
  empty: {
    marginTop: 150,
    textAlign: 'center',
  },
}));

interface InputProps {
  data: Assessor[];
}

export default function Input({ data }: InputProps) {
  const classes = useStyles();

  return (
    <div className={classes.cards}>
      {!data.length && (
        <Typography variant="h3" className={classes.empty}>
          Please select the &ldquo;Add Input Data&rdquo; button to add your assessment
        </Typography>
      )}
      {!!data.length && data.map(item =>  {
        if ('score' in item) {
          return <TileAssessor assessor={item} key={item.id} />;
        }

        return null;
      })}
    </div>
  );
}
