import { alpha, createTheme, PaletteOptions } from '@mui/material/styles';
import { IconPack, library } from '@fortawesome/fontawesome-svg-core';
import { fal } from '@fortawesome/pro-light-svg-icons';
import { far } from '@fortawesome/pro-regular-svg-icons';
import { fas } from '@fortawesome/pro-solid-svg-icons';
import './components/fonts/lexend.css';
import './components/fonts/plexmono.css';
import { TypographyOptions } from '@mui/material/styles/createTypography';

library.add(fal as IconPack);
library.add(far as IconPack);
library.add(fas as IconPack);

const colors = {
  black: '#0B0C12',
  black2: '#0a0c10',
  blue1: '#1592FF',
  blue2: '#1899B6',
  blue3: '#21BEE3',
  blue4: '#117fba',
  blue5: '#2ecff5',
  darkBlue1: '#161926',
  darkBlue2: '#192637',
  darkBlue3: '#203045',
  darkBlue4: '#303F52',
  darkBlue5: '#282b36',
  darkBlue6: '#151722',
  darkBlue7: '#2c324b',
  darkBlue8: '#4a5169',
  green1: '#00FFB1',
  green2: '#09B581',
  grey1: '#5E6F82',
  grey2: '#8797AB',
  grey3: '#a7adb5',
  grey4: '#5d6e80',
  grey5: '#868a91',
  grey6: '#61788e',
  grey7: '#39485a',
  orange1: '#F46043',
  orange2: '#f67339',
  orange3: '#c46034',
  white: '#FFFFFF',
  yellow1: '#FEBA06',
};

const defaultPalette: PaletteOptions = {
  mode: 'dark',
  primary: {
    main: colors.blue3,
  },
  secondary: {
    main: colors.orange1,
  },
  error: {
    main: colors.orange1,
  },
  warning: {
    main: colors.yellow1,
  },
  info: {
    main: colors.blue4,
  },
  success: {
    main: colors.green2,
  },
  subduedText: {
    main: colors.grey3,
  },
  skyBlue: {
    main: colors.blue1,
  },
  white: {
    main: colors.white,
  },
};

type PaletteColor = {
  main: string;
  contrastText: string;
};

type ColorsKeys = keyof typeof colors;

type Palette = {
  [K in ColorsKeys]: PaletteColor;
};

const palette: Palette = Object.keys(colors).reduce((acc, key) => {
  acc[key as ColorsKeys] = {
    main: colors[key as ColorsKeys],
    contrastText: '#ffffff',
  };

  return acc;
}, {} as Palette);

const typography: TypographyOptions = {
  fontFamily: 'Lexend',
  fontSize: 16,
  body1: {
    fontSize: 14,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.57,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.white,
  },
  body2: {
    fontSize: 12,
    fontWeight: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.white,
  },
  h1: {
    fontSize: 32,
    fontWeight: 'normal',
    lineHeight: 1.19,
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.white,
  },
  h2: {
    fontSize: 26,
    fontWeight: 'normal',
    lineHeight: 1.31,
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.white,
  },
  h3: {
    fontSize: 22,
    fontWeight: 'normal',
    lineHeight: 1.27,
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.white,
  },
  h4: {
    fontSize: 18,
    fontWeight: 'normal',
    lineHeight: 1.22,
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.blue3,
  },
  h5: {
    fontSize: 16,
    fontWeight: 'bold',
    lineHeight: 1.25,
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.white,
  },
  h6: {
    fontSize: 12,
    fontWeight: 'bold',
    lineHeight: 1.5,
    letterSpacing: 0.6,
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.orange1,
  },
  textStyle13: {
    fontSize: '3.43rem',
    fontWeight: 600,
    lineHeight: 4.31,
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.white,
  },
  textStyle12: {
    fontSize: '2.25rem',
    fontWeight: 700,
    lineHeight: '2.81',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.white,
  },
  textStyle11: {
    fontSize: '2.0rem',
    fontWeight: 400,
    lineHeight: '2.5rem',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.white,
  },
  textStyle10: {
    fontSize: '1.625rem',
    fontWeight: 600,
    lineHeight: '2.0rem',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.white,
  },
  textStyle9: {
    fontSize: '1.25rem',
    fontWeight: 600,
    lineHeight: '1.56rem',
    letterSpacing: 'normal',
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.white,
  },
  textStyle8: {
    fontSize: '1.25rem',
    fontWeight: 400,
    lineHeight: '1.56rem',
    letterSpacing: 0.6,
    fontStretch: 'normal',
    fontStyle: 'normal',
    color: colors.orange1,
  },
  textStyle7: {
    fontSize: '1.125rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.375rem',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.white,
  },
  textStyle6: {
    fontSize: '1.125rem',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.375rem',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.white,
  },
  textStyle5: {
    fontSize: '1.0rem',
    fontWeight: 600,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.5rem',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.white,
  },
  textStyle4: {
    fontSize: '1.0rem',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25rem',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.white,
  },
  textStyle3: {
    fontSize: '0.875rem',
    fontWeight: 700,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.125rem',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.white,
  },
  textStyle2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.125rem',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.white,
  },
  textStyle1: {
    fontSize: '0.8125rem',
    fontWeight: 400,
    fontStretch: 'normal',
    fontStyle: 'normal',
    lineHeight: '1.25rem',
    letterSpacing: 'normal',
    textAlign: 'left',
    color: colors.white,
  },
};

export default createTheme({
  typography,
  components: {
    MuiButton: {
      variants: [
        {
          props: { variant: 'transparent', color: 'orange1' },
          style: {
            background: alpha(colors.orange1, 0.2),
            border: `1px solid ${colors.orange1}`,
          },
        },
      ],
      styleOverrides: {
        text: {
          padding: 0,
        },
        endIcon: {
          marginLeft: '1.5rem',
          marginRight: 0,
        },
        startIcon: {
          marginRight: '1.5rem',
          marginLeft: 0,
        },
        containedPrimary: {
          backgroundColor: '#21bee3',
          color: '#ffffff',
          border: '1px solid #21bee3',
          padding: '5px 15px',
          '& svg': {
            fontSize: '17px !important',
          },
          '&:hover': {
            backgroundColor: '#1899b6',
            borderColor: '#1899b6',
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#1899b6',
            borderColor: '#1899b6',
          },
          '&:focus': {
            backgroundColor: '#1899b6',
            boxShadow: '0 0 0 5px rgba(33, 190, 227, 0.25)',
            borderColor: '#1899b6',
          },
        },
        containedSecondary: {
          backgroundColor: '#f67339',
          color: '#ffffff',
          border: '1px solid #f67339',
          padding: '5px 15px',
          '& svg': {
            fontSize: '17px !important',
          },
          '&:hover': {
            backgroundColor: '#c46034',
            borderColor: '#c46034',
            boxShadow: 'none',
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: '#c46034',
            borderColor: '#c46034',
          },
          '&:focus': {
            backgroundColor: '#c46034',
            boxShadow: '0 0 0 5px rgba(246, 115, 57, 0.25)',
            borderColor: '#c46034',
          },
        },
        outlinedPrimary: {
          backgroundColor: 'rgba(40, 43, 54, 0.62)',
          color: '#ffffff',
          boxShadow: '0 0 0 1px #ffffff',
          padding: '5px 15px',
          border: 0,
          '& svg': {
            fontSize: '17px !important',
          },
          '&:hover': {
            backgroundColor: 'rgba(21, 23, 34, 0.6)',
            borderColor: '#ffffff',
            boxShadow: '0 0 0 1px #ffffff',
            border: 0,
          },
          '&:active': {
            boxShadow: 'none',
            backgroundColor: 'rgba(21, 23, 34, 0.6)',
            borderColor: '#ffffff',
          },
          '&:focus': {
            boxShadow: '0 0 0 5px rgba(255, 255, 255, 0.25)',
            backgroundColor: 'rgba(21, 23, 34, 0.6)',
            border: 0,
          },
        },
        outlinedSecondary: {
          color: '#21bee3',
          padding: '5px 15px',
          border: 0,
          '& svg': {
            fontSize: '17px !important',
          },
          '&:hover': {
            color: '#1899b6',
            border: 0,
            backgroundColor: 'rgba(255, 255, 255, 0)',
          },
          '&:focus': {
            color: '#1899b6',
          },
        },
        root: {
          fontFamily: 'Lexend',
          padding: '11px 22px 11px 18px',
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.57,
          letterSpacing: 'normal',
          textTransform: 'none',
          borderRadius: '2px',
          border: 0,
          height: 40,
        },
        textPrimary: {
          fontFamily: 'Lexend',
          flexDirection: 'inherit',
        },
      },
    },
    MuiChip: {
      variants: [
        {
          props: { variant: 'filled', color: 'orange1' },
          style: {
            background: 'rgba(244, 96, 67, 0.3)',
            border: 'solid 1px rgb(244, 96, 67)',
          },
        },
        {
          props: { variant: 'filled', color: 'green2' },
          style: {
            background: 'rgba(9, 181, 129, 0.3)',
            border: 'solid 1px rgba(9, 181, 129, 1)',
          },
        },
      ],
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          padding: 6,
        },
        colorPrimary: {
          color: '#a7adb5',
          '&:hover': {
            color: '#ffffff',
            backgroundColor: 'rgba(255, 255, 255, 0.07)',
          },
          '&:focus': {
            color: '#ffffff',
            backgroundColor: 'rgba(255, 255, 255, 0.13)',
          },
          '& svg': {
            fontSize: 27,
          },
        },
        colorSecondary: {
          color: '#0a0c10',
          backgroundColor: '#2ecff5',
          padding: '5px 4px 4px 5px',
          borderRadius: '50%',
          '&:hover': {
            backgroundColor: '#1899b6',
          },
          '&:focus': {
            backgroundColor: '#1899b6',
            boxShadow: '0 0 0 3px rgba(33, 190, 227, 0.25)',
          },
          '& svg': {
            fontSize: 14,
          },
        },
        sizeSmall: {
          '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0)',
            color: '#868a91',
          },
          '&:focus': {
            color: '#868a91',
          },
          '& svg': {
            fontSize: 16,
          },
        },
      },
    },
    MuiToggleButtonGroup: {
      styleOverrides: {
        root: {
          height: '31px',
          fontSize: '14px',
          paddingLeft: '8px',
          paddingRight: '8px',
          position: 'relative',
          display: 'inline-block',
          borderRadius: '16px',
          backgroundColor: '#39485a',
        },
        groupedHorizontal: {
          margin: 3,
          border: 'none',
          '&:not(:first-child)': {
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
          },
          '&:not(:last-child)': {
            borderTopLeftRadius: 16,
            borderBottomLeftRadius: 16,
            borderTopRightRadius: 16,
            borderBottomRightRadius: 16,
          },
          '&:first-child': {
            borderRadius: 16,
          },
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          verticalAlign: 'sub',
          display: 'inline-block',
          paddingLeft: '14px',
          paddingRight: '14px',
          paddingTop: '0px',
          paddingBottom: '0px',
          borderRadius: '16px',
          height: '24px',
          lineHeight: '1.6',
          opacity: 0.23,
          cursor: 'pointer',
          border: 0,
          textTransform: 'none',
          fontWeight: 'bold',
          '&.Mui-selected': {
            opacity: 1,
            backgroundColor: '#0a0c10',
          },
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: '#21bee3',
          fontWeight: 'bold',
        },
        colorSecondary: {
          backgroundColor: '#f85c5c',
          fontWeight: 'bold',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          textAlign: 'center',
          fontSize: 12,
          lineHeight: '1.5',
          backgroundColor: '#000000',
          opacity: '0.91',
          borderRadius: 3,
          position: 'relative',
          top: 10,
          padding: 12,
        },
        arrow: {
          '&:before': {
            border: 'solid #000000',
          },
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          '&:last-child td': {
            borderBottom: 0,
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          background: 'rgba(255, 255, 255, 0.07)',
          borderRadius: 6,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 14,
          borderBottom: 'solid 1px rgba(255, 255, 255, 0.12);',
        },
        head: {
          fontFamily: 'Lexend',
          fontSize: 14,
          fontWeight: 'bold',
          fontStretch: 'normal',
          fontStyle: 'normal',
          lineHeight: 1.25,
          letterSpacing: 'normal',
          textAlign: 'left',
          color: '#ffffff',
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:hover:not(.Mui-disabled):before': {
            borderBottom: 0,
          },
          '&:after': {
            borderBottom: 0,
          },
          '&:before': {
            borderBottom: 0,
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(255, 255, 255, 0)',
        },
        input: {
          padding: '11px 17px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        formControl: {
          fontFamily: 'Lexend !important',
        },
      },
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          marginLeft: 12,
          marginRight: 0,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        icon: {
          right: '6px',
        },
        select: {
          border: '1px solid #61788e',
          borderRadius: '3px',
          padding: '11px 17px',
          '&:focus': {
            borderRadius: '3px',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#161926',
          backgroundImage: 'unset',
        },
        elevation1: {
          boxShadow: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 6,
          backgroundColor: '#0b0c12',
          backgroundImage: 'unset',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 16,
          '&:last-child': {
            paddingBottom: 16,
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          color: '#2c324b',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          border: 'solid 1px #5d6e80',
          backgroundColor: '#0b0c12',
        },
      },
    },
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            fontWeight: 'bold',
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: '#4a5169',
          fontSize: '12px',
          lineHeight: 1.5,
          letterSpacing: '0.6px',
          fontWeight: 'bold',
          paddingLeft: '32px',
          paddingRight: '32px',
          paddingTop: '12px',
          paddingBottom: '12px',
          '& .PrivateTabIndicator': {
            display: 'none',
          },
        },
        textColorPrimary: {
          color: '#4a5169',
          '&.Mui-selected': {
            backgroundColor: '#0a0c10',
            color: '#ffffff',
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        img: {
          borderRadius: '50%',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          backgroundColor: 'unset',
          color: '#a7adb5',
          '&:hover': {
            backgroundColor: 'unset',
          },
          '& svg': {
            width: 16.4,
            height: 16.4,
          },
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#1592ff',
          textDecoration: 'underline',
        },
      },
    },
  },
  mixins: {},
  palette: Object.assign({}, defaultPalette, palette, {
    contrastThreshold: 4.5,
    tonalOffset: 0.5,
  }),
});
