import clsx from 'clsx';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { clone } from '@sightgain/core';
import { truncate } from '@sightgain/core/strings';
import { Framework, FrameworkItem } from '../../../../services/interfaces';
import SubTechniqueModal from './SubTechniqueModal';
import TechniqueModal from './TechniqueModal';

const useStyles = makeStyles(theme => ({
  cell: {
    minWidth: 140,
    minHeight: 110,
    marginLeft: -1,
    marginTop: -1,
    borderRadius: 0,
    display: 'flex',
  },
  cellHead: {
    minWidth: 140,
    minHeight: 90,
    marginLeft: -1,
    marginTop: -1,
    borderRadius: 0,
    display: 'flex',
  },
  cellBackground: {
    flex: 1,
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.7)',
    height: '99%',
    minWidth: 119,
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: 4,
  },
  expandButton: {
    borderRadius: '50%',
    minWidth: 20,
    height: 20,
    backgroundColor: '#203045',
  },
  expandCell: {
    height: '100%',
    minWidth: 21,
    display: 'flex',
    alignItems: 'center',
  },
  head: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.text.primary,
    borderBottom: '2px solid #192637',
    marginBottom: 10,
  },
  headerText: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '100%',
    fontWeight: 'bold',
    padding: '0px 20px 0px 0px',
  },
  subtactics: {
    height: 'fit-content',
    minHeight: 89,
    minWidth: 119,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
  subtacticsBackground: {
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
  subtacticContent: {
    padding: 8,
    textAlign: 'center',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgb(255, 255, 255, 0.12)',
    },
    cursor: 'pointer',
  },
  text: {
    width: '100%',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: 10,
  },
  tileContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundColor: '#303F52',
  },
  tileText: {
    position: 'absolute',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    display: 'flex',
    paddingLeft: 1,
    paddingRight: 1,
    flexDirection: 'row',
    height: 'calc(100vh - 220px)',
    width: '100%',
    overflow: 'scroll',
    backgroundColor: '#161926',
  },
}));

const createFriendly = (key: string) =>
  key
    .toLowerCase()
    .replace(/[^a-z0-9\-_]/g, '-')
    .replace(/-+/g, '-');

// Some frameworks we are showing the item id instead of the title in the grid
const getTitle = (fw: Framework | null, item: FrameworkItem) => {
  if (item.identifier && item.name) {
    if (item.identifier === item.name) {
      return truncate(item.name, 50);
    } else {
      return (
        <>
          <div>{item.identifier}</div>
          <div>{truncate(item.name, 50)}</div>
        </>
      );
    }
  }

  return truncate(item.name, 50);
};

interface FrameworkColumn {
  /** @description column name to display in the heading */
  name: string;
  /** @description tactics to be displayed in the column */
  items: FrameworkItem[];
}

interface SelectedSubColumn {
  key: string;
  index: number;
}

interface FrameworkViewProps {
  framework: Framework;
  frameworkDraft: Framework;
  setFrameworkDraft: (draft: Framework) => void;
}

export default function FrameworkView({ framework, frameworkDraft, setFrameworkDraft }: FrameworkViewProps) {
  const classes = useStyles();
  const [subtacticsColumn, setSubtacticsColumn] = useState({} as SelectedSubColumn);
  const [selectedTactic, setSelectedTactic] = useState<string | undefined>(undefined);
  const [selectedTechnique, setSelectedTechnique] = useState<string | undefined>(undefined);
  const [selectedSubTechniqueTactic, setSelectedSubtechniqueTactic] = useState<string | undefined>(undefined);
  const [selectedSubTechnique, setSelectedSubtechnique] = useState<FrameworkItem[] | undefined>(undefined);

  const handleUpdateTechnique = (draft: Framework) => {
    setFrameworkDraft(draft);

    setSelectedTechnique(undefined);
    setSelectedSubtechnique(undefined);
    setSelectedSubtechniqueTactic(undefined);
    setSelectedTactic(undefined);
    setSubtacticsColumn({} as SelectedSubColumn);
  };

  const handleUpdateSubTechnique = (items: FrameworkItem[]) => {
    if (!selectedSubTechniqueTactic || !selectedTechnique) {
      return;
    }
    const tactic = frameworkDraft.groups.find(x => x.name === selectedSubTechniqueTactic);
    const technique = tactic?.items.find(y => y.identifier === selectedTechnique);
    if (technique) {
      technique.sub = items;
    }
    setFrameworkDraft(clone(frameworkDraft));
    setSelectedSubtechnique(undefined);
    setSelectedSubtechniqueTactic(undefined);
    setSelectedTechnique(undefined);

    if (items.length === 0) {
      setSubtacticsColumn({} as SelectedSubColumn);
    }
  };

  const renderTactic = (columnName: string, columnKey: string, tactic: FrameworkItem, index: number) => {
    const isSubtacticSelected = subtacticsColumn.key === columnKey && subtacticsColumn.index === index;
    const subtactics = tactic.sub || [];
    const subLength = framework.groups
      .find(x => x.name === columnName)
      ?.items.find(y => y.identifier === tactic.identifier)?.sub.length;
    return (
      <div className={clsx(classes.cell)} key={`${columnKey}_${tactic.identifier}_${tactic.name}`}>
        <div className={classes.cellBackground} onClick={() => setSelectedTactic(columnName)}>
          <div className={classes.tileContainer}>
            <div className={classes.tileText}>
              <Typography component="div" className={classes.text}>
                {getTitle(frameworkDraft, tactic)}
              </Typography>
            </div>
          </div>
        </div>
        {subtactics.length === 0 ? (
          <></>
        ) : (
          <div className={classes.expandCell}>
            <Button
              component="div"
              className={classes.expandButton}
              onClick={() =>
                isSubtacticSelected
                  ? setSubtacticsColumn({} as SelectedSubColumn)
                  : setSubtacticsColumn({ key: columnKey, index })
              }
            >
              <FontAwesomeIcon icon={['fal', isSubtacticSelected ? 'long-arrow-left' : 'long-arrow-right']} />
            </Button>
          </div>
        )}
        {subtactics.length === 0 && subLength ? (
          <div className={classes.expandCell}>
            <Button
              component="div"
              className={classes.expandButton}
              onClick={() => {
                setSelectedSubtechnique(subtactics);
                setSelectedTechnique(tactic.identifier);
                setSelectedSubtechniqueTactic(columnName);
              }}
            >
              <FontAwesomeIcon icon={['fal', 'plus']} />
            </Button>
          </div>
        ) : (
          <></>
        )}
        {subtactics.length === 0 && subLength === 0 && <div className={classes.expandCell} />}
        {subtacticsColumn.key === columnKey && (
          <div className={clsx([classes.subtactics, { [classes.subtacticsBackground]: isSubtacticSelected }])}>
            {isSubtacticSelected &&
              subtactics.map(sub => {
                return (
                  <div
                    className={classes.subtacticContent}
                    key={sub.identifier}
                    onClick={() => {
                      setSelectedSubtechnique(subtactics);
                      setSelectedTechnique(tactic.identifier);
                      setSelectedSubtechniqueTactic(columnName);
                    }}
                  >
                    {sub.identifier}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  };

  const renderColumn = ({ name, items }: FrameworkColumn) => {
    const columnKey = createFriendly(name);

    return (
      <div className={classes.column} key={columnKey}>
        <div className={clsx(classes.cellHead, classes.head)}>
          <Typography component="div" className={classes.headerText}>
            {truncate(name, 50)}
          </Typography>
        </div>
        {items.map((t, index) => renderTactic(name, columnKey, t, index))}
      </div>
    );
  };

  return (
    <>
      <div className={classes.wrapper}>{frameworkDraft.groups.map(group => renderColumn(group))}</div>
      {!!selectedTactic && (
        <TechniqueModal
          open={!!selectedTactic}
          onClose={() => setSelectedTactic(undefined)}
          onUpdate={handleUpdateTechnique}
          framework={framework}
          frameworkDraft={frameworkDraft}
          tacticName={selectedTactic}
        />
      )}
      {!!selectedSubTechnique && (
        <SubTechniqueModal
          open={!!selectedSubTechnique}
          onClose={() => setSelectedSubtechnique(undefined)}
          onUpdate={handleUpdateSubTechnique}
          items={
            framework.groups
              .find(x => x.name === selectedSubTechniqueTactic)
              ?.items.find(y => y.identifier === selectedTechnique)?.sub || []
          }
          itemsDraft={selectedSubTechnique || []}
        />
      )}
    </>
  );
}
