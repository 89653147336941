import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Modal,
  Typography,
  Button,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SearchBar } from '../../../../reusables/scl';
import ProductItem from './ProductItem';
import { BakeOffProduct } from '../interfaces';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    width: '55%',
    height: '65%',
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    border: 'solid 1px #5d6e80',
    backgroundColor: '#0b0c12',
    paddingTop: 67,
    paddingLeft: 95,
    paddingRight: 95,
    paddingBottom: 67,
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: 1,
  },
  title: {
    marginBottom: 35,
  },
  found: {
    marginTop: 24,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    maxHeight: 350,
    overflowY: 'scroll',
    '& > *': {
      marginRight: 10,
    },
  },
  notShowingCount: {
    marginBottom: 10,
  },
}));

export default function AddProductDialog({
  open = false, products = [], handleClose = () => undefined, onSubmit,
}: AddProductDialogProps) {
  const classes = useStyles();
  const [found, setFound] = useState(products);

  const handleSearch = (value: string) => {
    const lowercaseValue = value.toLowerCase();
    setFound(
      products
        .filter(
          item => item.name.toLowerCase().indexOf(lowercaseValue) !== -1,
        )
        .slice(0, 3),
    );
  };

  return (
    <Modal
      onClose={handleClose}
      open={open}
      className={classes.modal}
    >
      <div className={classes.wrapper}>
        <Typography variant="h2" className={classes.title}>
          Add a Product
        </Typography>
        <div className={classes.content}>
          {products.length > 0 && (
            <>
              <SearchBar
                placeholder="Search Products"
                onChange={handleSearch}
              />
              <div className={classes.found}>
                {found.map(item => (
                  <ProductItem
                    key={item.name}
                    item={item}
                    enableHover
                    onClick={() => onSubmit(item)}
                  />
                ))}
              </div>
            </>
          )}

          {products.length === 0 && (
            <Typography>No products found</Typography>
          )}
        </div>
        <div>
          {found.length < products.length && (
            <Typography className={classes.notShowingCount}>
              {products.length - found.length}
              {' '}
              more products not displayed.
              Search to filter list
            </Typography>
          )}
          <Button
            color="primary"
            onClick={handleClose}
          >
            <FontAwesomeIcon icon={['fal', 'long-arrow-left']} />
            &nbsp;&nbsp;Back To Product Bake-Off
          </Button>
        </div>
      </div>
    </Modal>
  );
}

type AddProductDialogProps = {
  open?: boolean;
  handleClose?: () => unknown;
  products?: BakeOffProduct[];
  onSubmit: (p: BakeOffProduct) => unknown;
};
