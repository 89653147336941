import { AnalystInput } from '../../../../services/interfaces';
import UnmatchedCard from './UnmatchedCard';

function UnmatchedEvents({ analystInput }: UnmatchedEventsProps) {
  const undetectedEvents = analystInput.filter(input => input.matchedEvents.length === 0);

  return (
    <>
      {undetectedEvents.map(input => <UnmatchedCard key={`unmatched-analyst-input-${input.id}`} input={input} />)}
    </>
  );
}

interface UnmatchedEventsProps {
  analystInput: AnalystInput[];
}

export default UnmatchedEvents;
