import { useState } from 'react';
import clsx from 'clsx';
import {
  Typography,
  Divider,
  Button,
} from '@mui/material';
import { Link } from 'react-router-dom';
import Skeleton from '@mui/lab/Skeleton';
import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import appStore from '../../../../AppStore';
import { TierBadge, LargeNumericMetric } from '../../../reusables/scl';
import { useAbort } from '../../../../effects';
import { curriculumService } from '../../../services';
import { StudentActivityGroup } from '../../../services/interfaces';

const useStyles = makeStyles(() => ({
  wrapperEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 250,
  },
  wrapperLoading: {
    display: 'flex',
    flexDirection: 'column',
    height: 250,
  },
  wrapper: {
    padding: 30,
    width: '100%',
    background: 'rgba(255, 255, 255, 0.036)',
    borderRadius: 6,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 14,
  },
  statsWrapper: {
    marginTop: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default function SelectedActivity({ id, groupChildType }: SelectedActivityProps) {
  const classes = useStyles();
  const [activity, setActivity] = useState<StudentActivityGroup>();

  useAbort(async () => {
    if (id !== undefined) {
      return curriculumService.studentActivityGroup(appStore.userId, id);
    }
  }, result => setActivity(result), [id]);

  if (!id) {
    return (
      <div className={clsx(classes.wrapper, classes.wrapperEmpty)}>
        <Typography variant="body1">
          Select a completed item below to view your stats
        </Typography>
      </div>
    );
  }

  if (!activity) {
    return (
      <div className={clsx(classes.wrapper, classes.wrapperLoading)}>
        <Skeleton width="100%" height={40} />
        <Skeleton width="100%" height={22} />
        <Skeleton width="100%" height="100%" />
      </div>
    );
  }

  const groupLabel = groupChildType === 'Classes' ? 'Course' : 'Curriculum';

  return (
    <div className={classes.wrapper}>
      <div className={classes.header}>
        <TierBadge tier={activity.difficulty as 'tier-1' | 'tier-2' | 'tier-3'} />
        <Button
          component={Link}
          to={`/curriculum/learning/activity/course/${id}`}
          color="primary"
          endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
        >
          {groupLabel}
          &nbsp;
          Details
        </Button>
      </div>
      <Typography variant="h2">
        {activity.title}
      </Typography>
      <div className={classes.statsWrapper}>
        <LargeNumericMetric
          value={activity.itemCount}
          label={groupChildType}
        />
        <Divider orientation="vertical" flexItem />
        <LargeNumericMetric
          value={activity.retakeCount}
          label="Retake Count"
        />
        <Divider orientation="vertical" flexItem />
        <LargeNumericMetric
          value={activity.nrOfAchievementsEarned}
          label="Achievements"
        />
        <Divider orientation="vertical" flexItem />
        <LargeNumericMetric
          value={activity.totalTimeSpentMinutes}
          label="Total Time Spent"
          unit="min"
        />
      </div>
    </div>
  );
}

type SelectedActivityProps = {
  id?: string;
  groupChildType: 'Courses' | 'Classes' | 'Exams' | '';
}
