import { escape } from '@sightgain/core/strings';
import { assessmentsService, frameworksService } from '../../../services';
import {
  Assessment,
  FrameworkItemWithScores,
  FrameworkWithScores,
  ScoredFramework,
  ScoredFrameworkItem,
  ScoreMeta,
} from '../../../services/interfaces';

function chunked(arr: string[], prefix: string, chunks: number, maxLength: number = 30000): { [key: string]: string } {
  const result: { [key: string]: string } = {};
  let currentString = '';
  let currentIndex = 1;

  arr.forEach(item => {
    if (currentString.length + item.length + (currentString.length > 0 ? 2 : 0) > maxLength) {
      result[`${prefix}${currentIndex}`] = escape(currentString);
      currentString = '';
      currentIndex++;
    }

    if (currentString.length > 0) {
      currentString += ', ';
    }
    currentString += item;
  });

  if (currentString.length > 0) {
    result[`${prefix}${currentIndex}`] = escape(currentString);
    currentIndex++;
  }

  while (currentIndex <= chunks) {
    result[`${prefix}${currentIndex}`] = '';
    currentIndex++;
  }

  return result;
}

async function fetchSubitem(
  item: ScoredFrameworkItem,
  framework: ScoredFramework,
  assessment: Assessment<FrameworkWithScores>,
) {
  // get the sub-items for the currentItem
  const fwItem = await frameworksService.findItem(item.identifier, framework.name, framework.version);
  // get details for each sub-item
  if (fwItem.sub.length === 0) {
    return [];
  }
  return Promise.all(
    fwItem.sub.map(subItem => {
      return assessmentsService.objective(assessment.id, subItem.id);
    }),
  );
}

const capCsvSubScores = (capItem?: FrameworkItemWithScores) => {
  const scoreMap: { [key: string]: ScoreMeta | undefined } = {};

  capItem?.scores.forEach(score => {
    const key = score.name;
    scoreMap[key] = score;
  });

  return {
    subItemSelfScoreMaturityScore: scoreMap['subItemSelfScoreMaturityScore']?.score ?? '',
    subItemAutomatedMaturityScore: scoreMap['subItemAutomatedMaturityScore']?.score ?? '',
    subItemInferredMaturityScore: scoreMap['subItemInferredMaturityScore']?.score ?? '',
  };
};

const capCsvScores = (capItem?: FrameworkItemWithScores) => {
  const scoreMap: { [key: string]: ScoreMeta | undefined } = {};

  capItem?.scores.forEach(score => {
    const key = score.name + (score.maturityLevel ? `_${score.maturityLevel}` : '');
    scoreMap[key] = score;
  });

  return {
    itemOverallMaturityScore: scoreMap['itemOverallMaturityScore']?.score ?? '',
    itemSelfScoreMaturityScore: scoreMap['itemSelfScoreMaturityScore']?.score ?? '',
    itemSelfScoreMaturityScorePassed: scoreMap['itemSelfScoreMaturityScore']?.passed.toString() ?? '',
    itemAutomatedMaturityScore: scoreMap['itemAutomatedMaturityScore']?.score ?? '',
    itemAutomatedMaturityScorePassed: scoreMap['itemAutomatedMaturityScore']?.passed.toString() ?? '',
  };
};

export const csv = async (fw: ScoredFramework, date: string, assessment: Assessment<FrameworkWithScores>) => {
  const rows: Record<string, string | number | null>[] = [];
  const capGroups = assessment.frameworks[0].groups;

  for (const group of fw.groups) {
    const capGroup = capGroups?.find(g => g.name === group.name);

    for (const item of group.items) {
      const capItem = capGroup?.items.find(i => i.identifier === item.identifier) as FrameworkItemWithScores;
      const testIds = item.testIds.filter(t => t.identifier === item.identifier).map(t => t.test);
      const uniqueTestIds = [...new Set(testIds)];

      const row = {
        groupName: escape(group.name),
        itemName: escape(item.name),
        itemIdentifier: escape(item.identifier),
        itemSubName: escape(item.sub.join(', ')),
        ...chunked(uniqueTestIds, 'itemTestIds', 5),
        itemTestScore: item.testScore.score,
        itemPostureScore: item.postureScore.score,
        itemThreatScore: item.threatScore.score,
        itemAssessorScore: item.assessorScore,
        itemAssessorValue: item.assessorValue,
        ...(capItem && capCsvScores(capItem)),
        ...capCsvSubScores(),
        date,
      };
      rows.push(row);

      const subItems = await fetchSubitem(item, fw, assessment);

      for (const sub of item.sub) {
        const subTestIds = item.testIds.filter(t => t.identifier === sub).map(t => t.test);

        const row = {
          groupName: '',
          itemName: '',
          itemIdentifier: sub,
          itemSubName: '',
          ...chunked(subTestIds, 'itemTestIds', 5),
          itemTestScore: null,
          itemPostureScore: null,
          itemThreatScore: null,
          itemAssessorScore: null,
          itemAssessorValue: null,
          ...capCsvScores(),
          ...capCsvSubScores(subItems.find(x => x.frameworkItem.identifier === sub)?.frameworkItem),
          date: '',
        };
        rows.push(row);
      }
    }
  }

  return rows;
};
