import { FetchPolicy, gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { CreateExamInput, ErrorResponse, Exam, ExamEvent, LiveFireTraining, RunExamInput } from './interfaces';
import { typeLiveFireTraining } from './LiveFireTrainingsService/fields';
import ServiceBase from './ServiceBase';

type UploadResponse = ErrorResponse | Exam;

export const typeExam = `
  {
    id
    name
    description
    vendor
    testIds
    tests {
      id
      vendorId
      description
      type
      name
      createdAt
      tags
      vendor
    }
    trainings {
      id
    }
  }
`;

// creates the generic lists query so it can be used repeatedly
function examsQuery(fields = typeExam) {
  return gql`
    query Exams {
      exams ${fields}
    }
  `;
}

export class ExamsService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['created', 'start', 'end']));
  }

  async list(fields = typeExam, fetchPolicy: FetchPolicy = 'cache-first'): Promise<Exam[]> {
    const query = examsQuery(fields);

    const { exams } = await this.graphql(query, undefined, fetchPolicy);
    return exams;
  }

  async find(id: string, fields = typeExam): Promise<Exam> {
    const query = gql`
      query Exam($id: ID!) {
        exam(id: $id) ${fields}
      }
    `;

    const { exam } = await this.graphql(query, { id });
    return exam;
  }

  async create(newExam: CreateExamInput, fields = typeExam): Promise<Exam> {
    const query = gql`
      mutation CreateExam($input: CreateExamInput!) {
        createExam(input: $input) ${fields}
      }
    `;
    const { createExam } = await this.graphql(query, { input: newExam });
    return createExam;
  }

  async delete(id: string): Promise<string> {
    const query = gql`
      mutation DeleteExam($id: ID!) {
        deleteExam(id: $id)
      }
    `;

    const { deleteExam } = await this.graphql(query, { id });

    this.removeGQLCache(examsQuery(), {}, 'Exam', (e: Exam) => e.id !== id);

    return deleteExam;
  }

  async run(examOptions: RunExamInput): Promise<LiveFireTraining[]> {
    const query = gql`
      mutation RunExam($input: RunExamInput!) {
        runExam(input: $input) ${typeLiveFireTraining}
      }
    `;

    const { runExam } = await this.graphql(query, { input: examOptions });
    return runExam;
  }

  async runScheduled(examEventId: string, attendee: string): Promise<LiveFireTraining> {
    const query = gql`
      mutation RunScheduledExam($examEventId: ID!, $attendee: String!) {
        runScheduledExam(examEventId: $examEventId, attendee: $attendee) ${typeLiveFireTraining}
      }
    `;

    const { runScheduledExam } = await this.graphql(query, { examEventId, attendee });
    return runScheduledExam;
  }

  async schedule(examOptions: RunExamInput): Promise<string> {
    const query = gql`
      mutation ScheduleExam($input: RunExamInput!) {
        scheduleExam(input: $input)
      }
    `;

    const { scheduleExam } = await this.graphql(query, { input: examOptions });
    return scheduleExam;
  }

  async events(attendee: string): Promise<ExamEvent[]> {
    const query = gql`
      query ExamEvents($attendee: String!) {
        examEvents(attendee: $attendee) {
          id
          owner
          attendee
          start
          end
          exam {
            id
            name
            description
          }
          status
        }
      }
    `;

    const { examEvents } = await this.graphql(query, { attendee }, 'network-only');
    return examEvents;
  }

  /**
   * Uploads JSON or Zip files to the server to be processed
   * to create exams
   */
  async upload(files: File[], fields = typeExam): Promise<ErrorResponse[]> {
    const query = gql`mutation UploadExams($files: [Upload!]!) {
        uploadExams(files: $files) {
          __typename
            ... on ErrorResponse { message stack }
            ... on Exam ${fields}
        }
      }`;

    const { uploadExams } = (await this.graphql(query, { files })) as { uploadExams: UploadResponse[] };

    const [exams, errors] = uploadExams.reduce(
      (a, b) => {
        if (b.__typename === 'Exam') {
          a[0].push(b as Exam);
        } else {
          a[1].push(b as ErrorResponse);
        }

        return a;
      },
      [[], []] as [Exam[], ErrorResponse[]],
    );

    // add new exams to the cache
    this.appendGQLCache(examsQuery(), {}, 'Exam', exams);

    return errors;
  }
}

const examsService = new ExamsService();
export default examsService;
