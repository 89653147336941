import { jobsService } from '../services';
import useSocketListener from './useSocketListener';

const statuses = ['queued', 'preparing', 'waiting', 'running'];

/**
 * Effect that returns the number of queued lfts
 * @param watch Enable or disable queue calls
 * @param date Starting date of queue
 * @returns 
 */
export default function useJobQueue({ watch = true, date }: {watch?: boolean, date?: Date }): number | undefined {
  // refresh anytime there are lft updates
  const numTrainings = useSocketListener<number>('livefiretraining', 'update', async () => {
    if (!watch) {
      return undefined;
    }

    const jobs = await jobsService.listJobs(undefined, '{ id status createdAt }', 'no-cache');
  
    const filtered = jobs
      .filter(x => statuses.includes(x.status))
      .filter(x => date ? (x.createdAt < date) : true);
    
    return filtered.length;
  }, undefined);

  return numTrainings;
}
