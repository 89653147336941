import { useCallback, useMemo, useState } from 'react';
import { Company, RoiResult } from '../../../services/interfaces';
import { useAbort } from '../../../../effects';
import { companyService, roiService, settingsService } from '../../../services';
import UpdateCompanyModal from './UpdateCompanyModal';
import appStore from '../../../../AppStore';
import Financial from './Financial';
import { IconButton, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory, useParams } from 'react-router';
import Technical from './Technical';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Title from '../components/Title';

const useStyles = makeStyles({
  iconButton: {
    verticalAlign: 'center',
    marginLeft: 10,
    width: 24,
    height: 24,
  },
});

export default function ReturnOnInvestment() {
  const classes = useStyles();
  const { view = 'technical' } = useParams<{ view: string }>();
  const [roi, setRoi] = useState<RoiResult>();
  const [company, setCompany] = useState<Company>();
  const [isOpen, setIsOpen] = useState(false);
  const [thresholds, setThresholds] = useState<[number, number]>([0, 0]);
  const history = useHistory();

  const roiInput = useMemo(() => {
    const assessmentId = '';
    const versionId = '';
    return { assessmentId, versionId };
  }, []);

  useAbort(
    () => roiService.roi(roiInput),
    result => setRoi(result),
    [roiInput],
  );
  useAbort(
    () => companyService.find(),
    result => setCompany(result),
    [],
  );
  useAbort(
    () => settingsService.find('roiThresholds', '{ name value }'),
    result => setThresholds(JSON.parse(result.value)),
    [],
  );

  const handleRefreshRoi = useCallback(async () => {
    appStore.beginLoading();
    try {
      const result = await roiService.refreshRoi(roiInput);
      setRoi(result);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  }, [roiInput]);

  const handleUpdateCompany = async (data: Company) => {
    appStore.beginLoading();
    try {
      await companyService.save(data);
      await handleRefreshRoi();
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const handleCompanyModalClose = async (data?: Company) => {
    setIsOpen(false);
    if (data) {
      await handleUpdateCompany(data);
    }
  };

  const handleToggleClick = (v: string) => {
    if (v === 'technical') {
      return history.push('/risk/roi');
    }

    return history.push('/risk/roi/financial');
  };

  if (!roi) {
    return <></>;
  }

  let max = 100000000;

  if (company) {
    max = company.revenue + company.ipValue + company.piiRecords;
  }

  return (
    <div data-testid="roi-page">
      <Title
        title="Return on Investments"
        scoredDate={roi?.updatedAt ?? new Date()}
        refresh={handleRefreshRoi}
        titleButton={
          <IconButton color="secondary" className={classes.iconButton} onClick={() => setIsOpen(true)} size="large">
            <FontAwesomeIcon icon={['fal', 'pencil-alt']} />
          </IconButton>
        }
      />
      <ToggleButtonGroup exclusive value={view} onChange={(_, n) => handleToggleClick(n)}>
        <ToggleButton value="technical">Technical</ToggleButton>
        <ToggleButton value="financial">Financial</ToggleButton>
      </ToggleButtonGroup>
      {view === 'financial' && roi && <Financial roi={roi} max={max} thresholds={thresholds} />}
      {view === 'technical' && roi && <Technical roi={roi} thresholds={thresholds} />}
      {company && <UpdateCompanyModal open={isOpen} companyInfo={company} onClose={handleCompanyModalClose} />}
    </div>
  );
}
