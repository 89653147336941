import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  statusBadge: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.5,
    padding: '5px 10px 5px 5px',
    borderRadius: '13px',
    fontFamily: 'Lexend',
    cursor: 'default',
    '& svg': {
      color: '#ffffff',
      marginRight: 5,
      fontSize: 14,
    },
  },
  Active: {
    backgroundColor: '#09b581',
  },
  Disabled: {
    backgroundColor: '#bc4e4e',
  },
}));

export default function AccountStatus({ status }: AccountStatusProps) {
  const classes = useStyles();
  return (
    <span className={`${classes.statusBadge} ${classes[status]}`}>
      <FontAwesomeIcon icon={['fas', status === 'Active' ? 'user' : 'user-times']} />
      {status}
    </span>
  );
}

type AccountStatusProps = {
  status: 'Active' | 'Disabled';
};
