import { Button, Checkbox, Divider, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import { Recommendation, RuleRiskLevel } from '../interfaces';
import RichText from '../../../../reusables/RichText';
import { NumberBadge } from '../../../../reusables/Badges';

const useStyles = makeStyles(() => ({
  card: {
    borderRadius: 6,
    background: '#303F52',
    padding: 24,
    display: 'flex',
  },
  main: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  button: {
    color: '#21bee3',
    all: 'unset',
    cursor: 'pointer',
    fontWeight: 600,
    whiteSpace: 'nowrap'
  },
  footer: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 12 
  },
  content: {
    minHeight: 120 
  },
  header: {
    display: 'flex',
    marginBottom: 12
  },
  selected: {
    border: '1px solid #21BEE3'
  },
  risk: {
    textTransform: 'capitalize',
    width: 95
  },
  recent: {
    '&::before': {
      backgroundColor: '#F46043',
      content: '""',
      width: '15px',
      height: '15px',
      borderRadius: '7.5px',
      alignSelf: 'center',
      marginRight: '10px',
      display: 'inline-block',
      verticalAlign: 'middle'
    }
  },
  click: {
    cursor: 'pointer'
  },
  title: {
    display: 'flex',
    '& *': {
      fontSize: 16,
      fontWeight: 'bold'
    }
  }
}));

function riskColor(level: RuleRiskLevel): string {
  if (level === RuleRiskLevel.HIGH) {
    return 'green';
  }
  if (level === RuleRiskLevel.MEDIUM) {
    return 'orange';
  }
  return 'blue';
}

interface CardProps {
  recommendation: Recommendation;
  selectable?: boolean;
  selected?: boolean;
  onClick?: (id: string) => void;
  onSelect?: (id: string) => void;
}

export default function Card({
  recommendation,
  selectable = true,
  selected = false,
  onClick = () => { },
  onSelect = () => { }
}: CardProps) {
  const classes = useStyles();
  const [showDetails, setShowDetails] = useState(false);
  const { id, title, content, detail, date, riskLevel } = recommendation;
  const isRecent = recommendation.status === 'new';

  return (
    <div className={`${classes.card} ${selected && classes.selected} ${isRecent && classes.click}`} onClick={() => isRecent && onClick(id)}>
      {selectable && (
        <div>
          <Checkbox
            checked={selected}
            onChange={() => onSelect(id)}
            color="primary"
            style={{ marginLeft: -14, marginTop: -11, marginRight: 8 }}
          />
        </div>
      )}

      <div className={classes.main}>
        {/* not footer not hotdog */}
        <div>
          {/* header */}
          <div className={classes.header}>
            <Typography variant="h5" style={{ flex: 1 }} className={isRecent ? `${classes.recent} ${classes.title}` : `${classes.title}`}>
              <RichText text={`${title}`} />
            </Typography>
            <Button
              component={Link}
              style={{ flex: 0 }}
              className={classes.button}
              onClick={() => setShowDetails(!showDetails)}
              disableRipple
            >
              {showDetails ? 'Hide' : 'Show'} Details
            </Button>
          </div>

          {/* content */}
          <div className={classes.content}>
            <RichText text={content} />
            <div style={{ marginTop: 24, display: 'flex', alignItems: 'center', gap: 12 }}>
              <NumberBadge className={classes.risk} tag color={riskColor(riskLevel)}>{riskLevel}</NumberBadge>
              <Typography variant="h6" style={{ color: '#8797AB' }}>Reduction of risk when resolved.</Typography>
            </div>

            {/* details */}
            {showDetails && (
              <div>
                <Divider style={{ marginTop: 24, marginBottom: 24 }} />
                <div>
                  <RichText text={detail}/>
                </div>
              </div>
            )}
          </div>      
        </div>

        {/* footer */}
        <div className={classes.footer}>
          <Typography variant="h6" style={{ color: '#8797AB' }}>Received: {date.toString()}</Typography>
        </div>
      </div>
    </div>
  );
}