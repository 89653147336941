import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import ActivityTable from './ActivityTable';
import useActivityWatcher from '../../../reusables/useActivityWatcher';
import { ActivityLFT } from '../../../reusables/useActivityWatcher/interfaces';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  labelsWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 14,
  },
  title: {
    color: '#ffffff',
    textTransform: 'uppercase',
  },
  chartWrapper: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
    borderRadius: 6,
    height: 200,
  },
}));

export default function AnalystActivity({ userId }: { userId: string }) {
  const classes = useStyles();
  const activityInfo = useActivityWatcher('evaluations', userId);
  const activity = [...activityInfo.completed, ...activityInfo.incomplete] as ActivityLFT[];
  
  return (
    <div className={classes.wrapper}>
      <div className={classes.labelsWrapper}>
        <Typography variant="h6" className={classes.title}>
          Activity
        </Typography>
      </div>
      <div className={classes.chartWrapper}>
        <ActivityTable activity={activity}/>
      </div>
    </div>
  );
}
