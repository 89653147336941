import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useState } from 'react';
import appStore from '../../../../../AppStore';
import { StatusBadge } from '../../../../reusables/Badges';
import { liveFireTrainingService } from '../../../../services';
import { CardUserInfo } from '../../components/cards';
import { LearningTraining } from '../interfaces';
import avatar from '../../../../images/adversaries.png';

const useStyles = makeStyles({
  leftPane: {
    width: 453,
    height: 834,
    padding: '34px 37px',
    borderRadius: 6,
    border: 'solid 1px rgba(255, 255, 255, 0.26)',
  },
  input: {
    marginTop: 12,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.11)',
    borderRadius: 3,
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      border: 0,
    },
    '& > div': {
      '&:before': {
        border: 0,
      },
      '&:after': {
        border: 0,
      },
    },
  },
  eventInput: {
    '& > div': {
      '& textarea': {
        padding: '11px 18px',
        fontSize: 12,
        fontFamily: 'IBMPlexMono, monospace !important',
      },
    },
  },
  noteInput: {
    '& > div': {
      '& textarea': {
        padding: '11px 18px',
        fontSize: 14,
        fontFamily: 'Lexend !important',
      },
    },
  },
  submit: {
    backgroundColor: 'rgba(40, 43, 54, 0.62)',
    borderColor: '#fff',
  },
});

export default function AnalystInputForm({ training, running }: { training: LearningTraining, running: boolean }) {
  const classes = useStyles();
  const [event, setEvent] = useState('');
  const [description, setDescription] = useState('');

  const { id, analyst } = training;

  const submitEvent = async () => {
    const newInput = {
      id,
      event,
      description,
    };

    appStore.beginLoading();
    try {
      await liveFireTrainingService.addAnalystInput(newInput, '{ id }');
      setEvent('');
      setDescription('');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  return (
    <div className={classes.leftPane}>
      <div style={{ marginBottom: 5 }}>
        <StatusBadge status={training.status} />
      </div>
      <Typography variant="h2">
            Live Evaluation
      </Typography>
      <Typography variant="h6" style={{ marginBottom: 10 }}>
        {training.job.name}
      </Typography>
      <div style={{ marginBottom: 12, borderBottom: 'solid 2px #2c3c50', paddingBottom: 12 }}>
        <CardUserInfo image={avatar} bottomRow={analyst.email} topRow="Analyst" />
      </div>
      <Typography variant="h2" style={{ marginBottom: 12 }}>
            New Observed Event
      </Typography>
      <div style={{ marginBottom: 12 }}>
        <Typography variant="h5">
              Event
        </Typography>
        <TextField
          disabled={!running}
          className={`${classes.input} ${classes.eventInput}`}
          multiline
          rows={8}
          onChange={e => setEvent(e.target.value)}
          value={event}
          data-testid="event-input"
        />
      </div>
      <div style={{ marginBottom: 33 }}>
        <Typography variant="h5">
              Event Notes
        </Typography>
        <TextField
          disabled={!running}
          className={`${classes.input} ${classes.noteInput}`}
          multiline
          rows={4}
          onChange={e => setDescription(e.target.value)}
          value={description}
          data-testid="event-note"
        />
      </div>
      <Button
        disabled={!running}
        variant="contained"
        color="primary"
        onClick={() => submitEvent()}
        className={classes.submit}
        data-testid="submit-event"
      >
            Add Observed Event&nbsp; &nbsp; &nbsp;
        <FontAwesomeIcon icon={['fal', 'plus']} />
      </Button>
    </div>
  );
}