import { round } from '@sightgain/core/calculations';
import { chartTitle } from '@sightgain/core/strings';
import { SequentialValuesChartMetric } from '../../../../../reusables/scl';
import { ResultPerformance } from '../../../../../services/interfaces';
import { performanceSeries } from '../utilites';

export default function ChartTechPreventionRates({ performance }: { performance: ResultPerformance[] }) {
  const [labels, prevented] = performanceSeries(performance, 'prevented');

  const series = labels.map((name, i) => {
    const value = round(prevented[i] * 100, 2);
    return {
      name: `${chartTitle(name)} ${value}%`,
      value,
    };
  });

  return <SequentialValuesChartMetric title="Prevention Rates by Technology" series={series} />;
}
