enum StageStatus {
  PREPARING = 0,
  STARTING = 1,
  READY = 3,
}

const currentStage = (statuses: string[]) => {
  const totalCount = statuses.length;
  const waitingCount = statuses.filter(status => status === 'waiting').length;
  const ranCount = statuses.filter(status => status === 'ran').length;

  if (waitingCount === totalCount) {
    return StageStatus.PREPARING;
  }

  if (ranCount >= 1) {
    return StageStatus.READY;
  }

  return StageStatus.STARTING;
};

export { currentStage, StageStatus };
