import { useCallback, useEffect, useState } from 'react';
import { gql } from '@apollo/client';
import { callOnce } from '@sightgain/core';
import appStore from '../../AppStore';
import ServiceBase from './ServiceBase';

export interface UserPreference {
  name: string;
  userId: string;
  value: string;
}

export type SetUserPreference<T> = T | ((setting: T) => T);

const typeUserPreference = `{
  name
  userId
  value
}`;

// TODO: Find a way to aggragate multiple queries to make fetching more efficient
export class UserPreferencesService extends ServiceBase {
  async find(name: string, fields = typeUserPreference): Promise<UserPreference> {
    const input = { name, userId: this._userId };
    const query = gql`
      query GetUserPreference($input: UserPreferenceInput!) {
        getUserPreference (input: $input) ${fields}
      }
    `;

    const { getUserPreference } = await this.graphql(query, { input });
    return getUserPreference;
  }

  async set(name: string, value: string, fields = typeUserPreference): Promise<UserPreference> {
    const input = { name, userId: this._userId, value };
    const query = gql`
      mutation SetUserPreference($input: SetUserPreferenceInput!) {
        setUserPreference(input: $input) ${fields}
      }
    `;

    const { setUserPreference } = await this.graphql(query, { input });
    return setUserPreference;
  }

  get _userId() {
    return appStore.user.id;
  }
}

const userPreferencesService = new UserPreferencesService();

/**
 * Wrapper over *useState* that will persist the data in the user preferences.
 * @example useUserPreference('appCyberNutritionTestSet', undefined)
 * @param name name of user preference to use
 * @param initialValue same as for *useState*
 * @param loadInitialValue=true automatically load initial value from server
 *  This should be left on true unless for some reason, the initial value needs to be loaded a later time
 *  In that case, you should do
 *    const result = UserPreferenceService.find(name);
 *    setInitialState(result);
 * @returns [state, setState, loadInitialValue ? setInitialState : undefined]
 */
export function useUserPreference<T>(
  name: string,
  initialValue: T,
  loadInitialValue = true,
): [T, (value: SetUserPreference<T>) => void, (...args: any[]) => void] {
  const [state, setState] = useState(initialValue);

  const setInitialValue = callOnce((result: UserPreference) => {
    // Update initial value with the one fetched from the server
    //  Does nothing inf the value is null
    if (result.value) {
      setState(JSON.parse(result.value));
    }
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!loadInitialValue) {
        return;
      }
      const result = await userPreferencesService.find(name);
      setInitialValue(result);
    };
    fetchData();
  }, []);

  const setValue = useCallback(
    (value: SetUserPreference<T>) => {
      setState(value);
      userPreferencesService.set(name, JSON.stringify(value));
    },
    [name],
  );

  const returnValue = !loadInitialValue ? setInitialValue : (pref: UserPreference) => undefined;
  return [state, setValue, returnValue];
}

export default userPreferencesService;
