import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from 'react';
import { MgrListItem } from '../Manager/interfaces';
import EvaluationModal from './EvaluationModal';
import ImportDialog from './ImportDialog';

const useStyles = makeStyles({
  buttons: {
    display: 'flex',
    alignItems: 'center',
    gap: 20,
  },
  btnIcons: {
    paddingLeft: 20,
  }
});

function NewEvalautionButtons({ vendor, evaluation, onCloseNewModal }: NewEvaluationButtonsProps) {
  const [openImportModal, setOpenImportModal] = useState(false);
  const [openNewModal, setOpenNewModal] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    if (evaluation) {
      setOpenNewModal(true);
    }
  }, [evaluation]);

  const handleOnClose = () => {
    onCloseNewModal();
    setOpenNewModal(false);
  };

  return (
    <div className={classes.buttons}>
      <Button  variant="outlined" onClick={() => setOpenImportModal(true)}>
          Import Evaluations
        <FontAwesomeIcon className={classes.btnIcons} icon={['fal', 'arrow-to-top']} />
      </Button>
      <Button
        variant="contained"
        style={{ backgroundColor: '#21bee3' }}
        onClick={() => setOpenNewModal(true)}
      >
        New Evaluation
        <FontAwesomeIcon className={classes.btnIcons} icon={['fal', 'plus-circle']} />
      </Button>
      <ImportDialog open={openImportModal} onClose={() => setOpenImportModal(false)} />
      <EvaluationModal
        open={openNewModal}
        evaluation={evaluation}
        vendor={vendor}
        onClose={handleOnClose}
      />
    </div>
  );
}

interface NewEvaluationButtonsProps {
  vendor: string;
  evaluation?: MgrListItem;
  onCloseNewModal: () => unknown;
}

export default React.memo(NewEvalautionButtons);
