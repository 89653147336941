import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import Welcome from './Welcome';
import userPreferencesService from '../../../services/UserPreferencesService';
import FrameworkList from './FrameworkList';
import { useAbort } from '../../../../effects';
import { useState } from 'react';

const useStyles = makeStyles(() => ({
  page: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    gap: 12,
    alignItems: 'center'
  },
  content: {
    height: '100%',
    display: 'flex',
    flex: 1
  }
}));

export default function FrameworkManagement() {
  const classes = useStyles();
  const [showWelcome, setShowWelcome] = useState<undefined | boolean>(undefined);

  useAbort(() => userPreferencesService.find('ShowFrameworkWelcome'), result => {
    if (result.value) {
      setShowWelcome(JSON.parse(result.value));
    } else {
      userPreferencesService.set('ShowFrameworkWelcome', 'true');
      setShowWelcome(true);
    }
  }, []);

  const handleSetFrameworkWelcome = async () => {
    await userPreferencesService.set('ShowFrameworkWelcome', 'false');
    setShowWelcome(false);
  };

  return (
    <div data-testid="management-page" className={classes.page}>
      <div className={classes.header}>
        <Typography variant="h2">Framework Management</Typography>
      </div>
      <div className={classes.content}>
        { /* do not show anything in undefined state */}
        {showWelcome === true && <Welcome onContinue={handleSetFrameworkWelcome} />}
        {showWelcome === false && <FrameworkList />}
      </div>
    </div>
  );
}
