import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { Achievement, ScrollableList } from '../../../../reusables/scl';
import TitleAndViewAll from '../TitleAndViewAll';

const useStyles = makeStyles(() => ({
  title: {
    textTransform: 'uppercase',
    color: '#ffffff',
  },
  content: {
    display: 'flex',
    marginTop: 12,
    minHeight: 110,
  },
  contentEmpty: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 12,
    minHeight: 110,
  },
}));

interface AchievementsContainerProps {
  achievements: string[];
  title: string;
  viewAllLink?: string;
}

export default function AchievementsContainer({ achievements, title, viewAllLink = '' }: AchievementsContainerProps) {
  const classes = useStyles();

  return (
    <div>
      <TitleAndViewAll title={title} to={viewAllLink} />
      {achievements.length === 0 && (
        <div className={classes.contentEmpty}>
          <Typography variant="body1">Complete curriculum to earn achievements</Typography>
        </div>
      )}
      {achievements.length > 0 && (
        <ScrollableList className={classes.content}>
          {achievements.map(a => (
            <Achievement key={a} image={a} />
          ))}
        </ScrollableList>
      )}
    </div>
  );
}
