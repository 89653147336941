import { User } from '../../../../services/interfaces';
import FrameworkItemScores from './frameworkItemScores';

export interface Question {
  id: string;
  type: string;
  question: string;
}

export interface Answer {
  question: Question;
  value: string;
  passed: boolean;
}

export interface Evidence {
  id: string;
  user: User;
  fileName: string;
  createdAt: Date;
}

export interface Objective {
  id: string;
  name: string;
  description: string;
  identifier: string;
  maturityLevel?: string;
  scores: FrameworkItemScores;
  questions: Question[];
  answers: Answer[];
  evidence: Evidence[];
}

export enum MaturityLevel {
  ALL = 'all',
  TARGET = 'target',
  ADVANCED = 'advanced',
}