import { useEffect, useState } from 'react';
import { useAbort } from '../../effects';
import { curriculumService, socket } from '../services';
import { StudentDashboard } from '../services/interfaces';
import { Message } from '../services/Socket/intefaces';

const types = ['progress', 'activity'];
const action = 'update';

export default function useStudentDashboard(userId?: string) {
  const [stats, setStats] = useState<StudentDashboard>({
    totalLifetimeTrainingDurationMinutes: 0,
    totalLifetimeCourses: 0,
    trainingMinutesThisWeek: 0,
    trainingMinutesLastWeek: 0,
  });

  useAbort(async () => userId && curriculumService.studentDashboard(userId), results => results && setStats(results));

  useEffect(() => {
    if (!userId) {
      return;
    }

    let abort = false;
    const handleRefresh = async (msg: Message) => {
      if (msg.body.student !== userId) {
        return;
      }

      const results = await curriculumService.studentDashboard(userId);
      if (abort) {
        return;
      }

      setStats(results);
    };

    const unsubscribes = types.map(type => socket.subscribe(handleRefresh, { type, action }));

    return () => {
      abort = true;
      unsubscribes.forEach(unsub => unsub());
    };

  }, [userId]);

  return stats;

}