import { Grid } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  gridContainer: {
    borderRadius: 6,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
  consoleGrid: {
    paddingLeft: 25,
    paddingRight: 25,
    paddingTop: 20,
    paddingBottom: 16,
    marginTop: 20,
  },
});

export default function ROIGraphsContainer({ children }: { children: JSX.Element[] }) {
  const classes = useStyles();
  return (

    <div className={classes.gridContainer}>
      <Grid container className={classes.consoleGrid} spacing={3}>
        {children}
      </Grid>
    </div>
  );
}