import { truncate } from '@sightgain/core/strings';

const DESCRIPTION_FIELDS = ['Message', 'description', 'message'];

export function yamlField(field: string, value: string): string | undefined {
  const rex = new RegExp(`^\\s*${field}:\\s(\\|\\n\\s*)?(?<value>(.|\n)*)(\\n\\s*\\w+:|$)`, 'm');
  return value.match(rex)?.groups?.value;
}

export function jsonField(field: string, value: string): string | undefined {
  const rex = new RegExp(`"${field}":\\s*"(?<value>.*)"`);
  return value.match(rex)?.groups?.value;
}

export function xmlField(field: string, value: string): string | undefined {
  const rex = new RegExp(`<${field}.*?>(?<value>(.|\n)*)<\\/${field}>`, 'm');
  return value.match(rex)?.groups?.value;
}

export function textField(field: string, value: string): string | undefined {
  const rex = new RegExp(`\\n${field}=(?<value>.*)`);
  return value.match(rex)?.groups?.value;
}

export function fieldParser(raw: string): (field: string, value: string) => string | undefined {
  const lang = eventLanguage(raw);

  switch (lang) {
    case 'yaml':
      return yamlField;
    case 'xml':
      return xmlField;
    case 'json':
      return jsonField;
    default:
      return textField;
  }
}

export function eventDescription(raw: string): string {
  const fn = fieldParser(raw);

  for (const field of DESCRIPTION_FIELDS) {
    const value = fn(field, raw);
    if (value) {
      return truncate(
        value
          // get rid of spaces or line breaks at the beginning
          .replace(/^[\n\s]*/m, '')
          // get rid of everything after the first line break
          .replace(/(\n.*)*$/, ''),
        150,
      );
    }
  }

  return 'System Detected an Unknown Event';
}

export function eventLanguage(raw: string): string | undefined {
  if (raw.match(/^\{|\[/)) {
    return 'json';
  }

  if (raw.match(/^</)) {
    return 'xml';
  }

  if (raw.match(/^\w+:/)) {
    return 'yaml';
  }

  return undefined;
}
