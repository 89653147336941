import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, IconButton, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { format, parseISO } from 'date-fns';
import { TierBadge } from '../../../reusables/Badges';
import { CalendarEvent } from '../../../services/interfaces';
import BeginButton from './BeginButton';

const useStyles = makeStyles(() => ({
  item: {
    padding: '25px 33px 25px 33px',
    '&:not(:last-child)': {
      borderBottom: '1px solid #203045',
    },
  },
  title: {
    textTransform: 'uppercase',
  },
  date: {
    fontSize: 14,
    marginBottom: 32,
  },
  info: {
    flex: 1,
    '& h2': {
      marginBottom: 26,
    },
    '& h6': {
      marginBottom: 12,
      color: '#a7adb5',
    },
  },
}));

export default function CalendarEventItem({ item }: {item: CalendarEvent}) {
  const classes = useStyles();
  return (
    <div className={classes.item} key={item.id}>
      <div className={classes.info}>
        <TierBadge tier={item.trainingGroup.difficulty} />
        <Typography variant="h2">
          {item.trainingGroup.title}
        </Typography>
        <Typography variant="h6" className={classes.title}>Date Schedule</Typography>
        <div className={classes.date}>
          <Typography variant="body1" display="inline">
            {format(parseISO(item.day), 'MM/dd/yyyy')}
            {' '}
          </Typography>
          &nbsp;
          { /* TODO: enable this when we can edit */}
          {false && (
            <IconButton onClick={() => { }} size="small">
              <FontAwesomeIcon icon={['fal', 'pencil-alt']} color="#2ecff5" />
            </IconButton>
          )}
        </div>
        {item.description && (
          <>
            <Typography variant="h6" className={classes.title}>Description</Typography>
            <Typography variant="body1">
              {/* selected.trainingGroup.description */}
              {item.description}
            </Typography>
          </>
        )}
      </div>
      <div>
        <Divider style={{ marginTop: 26, marginBottom: 26 }} />
        <BeginButton type={item.trainingGroup.type} id={item.trainingGroup.id} />
      </div>
    </div>
  );
}