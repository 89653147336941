import { useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { observer } from 'mobx-react';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-bottts-sprites';
import appStore from '../../../AppStore';
import Bar from './Bar';
import Buttons from './Buttons';
import Menu from './Menu';
import Selector from './Selector';
import User from './User';
import { calculateRoles, getFilteredModules, getFilteredPages } from './utils';
import { Role } from '../../services/interfaces';
import { useAbort } from '../../../effects';

function Navigation({ open =  false, setOpen }: NavigationProps) {
  // We need to populate a role below so component can render before login
  const { email, name } = appStore.user || { email: '', name: '', roles: [Role.ADMIN] };
  const { pathname } = useLocation();
  const history = useHistory();

  const avatarSvg = createAvatar(style, {
    seed: email,
    dataUri: true,
    backgroundColor: '#dddbda',
  });

  const [composedRoles, availableRoles] = calculateRoles(appStore.activeModule);

  // set a default role if you have more than one available
  // TODO: persist some how?
  useEffect(() => {
    if (composedRoles.includes(appStore.activeRole)) {
      return;
    }

    appStore.setActiveRole(composedRoles[0]);
  }, [composedRoles]);

  const showLiveFire = !appStore.isLimitedLicense &&
    (appStore.checkAccess([Role.INSTRUCTOR, Role.ADMIN, Role.FRAMEWORK_ANALYST]));
  const showSettings = appStore.activeRole === Role.ADMIN;
  const showNotifications = !appStore.isLimitedLicense
    && !appStore.checkAccess([Role.ORGANIZATIONAL, Role.FRAMEWORK_ANALYST]);

  const user = {
    roles: composedRoles,
    name,
    email,
    image: avatarSvg,
    jobTitle: 'Cyber Defense Analyst, Advanced',
  };

  useEffect(
    () => {
      if (appStore.activeModule && (/\/(select-module|settings|profile|support)/.test(pathname) || pathname === '/')) {
        return;
      }

      const mods = getFilteredModules(availableRoles);
      const newActiveModule = mods.find(m => pathname.startsWith(`/${m.name}`))?.name ?? mods[0].name;
      // TODO: persist some how?
      appStore.setActiveModule(newActiveModule);
    },
    [pathname, availableRoles],
  );

  const filteredPages = useMemo(
    () => {
      if (!appStore.activeModule) {
        return [];
      }
      
      return getFilteredPages(appStore.activeModule);
    },
    // both dependencies are needed despite what eslint believes
    [appStore.activeModule, appStore.activeRole],
  );

  useAbort(() => {
    const tos = filteredPages.flatMap(p => p.to);
    if (!['/preview', '/settings', '/support', '/select-module', '/profile'].find(p => pathname.startsWith(p))
    && !tos.some(to => pathname.startsWith(to))
    ) {
      return tos[0];
    }
  }, to => to && history.push(to), [filteredPages], undefined, false);

  return (
    <>
      <CssBaseline />
      <Bar open={open}>
        <Selector
          open={open}
          activeModule={appStore.activeModule}
          modules={getFilteredModules(availableRoles)}
        />
        <User open={open} user={user} />
        {!appStore.isLimitedLicense && <Menu open={open} pages={filteredPages} />}
        <Buttons
          showLiveFire={showLiveFire}
          showSettings={showSettings}
          showNotifications={showNotifications}
          open={open}
          setOpen={setOpen}
        />
      </Bar>
    </>
  );
}

type NavigationProps = {
  open?: boolean;
  setOpen: (v: boolean) => unknown;
}

export default observer(Navigation);
