import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CsvDownloader from 'react-csv-downloader';

export default function CsvDownload({ datas, filename }: { datas: any; filename: string }) {
  return (
    <CsvDownloader filename={filename} datas={datas}>
      <button
        type="button"
        style={{
          background: 'none',
          color: '#1899b6',
          border: 'none',
          padding: 0,
          marginTop: '2px',
          font: 'inherit',
          cursor: 'pointer',
          outline: 'inherit',
        }}
      >
        <FontAwesomeIcon icon={['fas', 'file-csv']} size="lg" />
      </button>
    </CsvDownloader>
  );
}
