import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconName } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles(() => ({
  trend: {
    border: '1px solid #ffffff',
    borderRadius: '50%',
    padding: '8px 10px',
    width: 25,
    height: 25,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  green: {
    backgroundColor: 'rgba(0, 255, 177, 0.36)',
    '& svg': {
      color: '#00ffb1',
      fontSize: 17,
    },
  },
  grey: {
    backgroundColor: 'rgba(167, 173, 181, 0.37)',
    '& svg': {
      color: '#a7adb5',
      fontSize: 17,
    },
  },
  red: {
    backgroundColor: 'rgba(248, 92, 92, 0.36)',
    '& svg': {
      color: '#f85c5c',
      fontSize: 17,
    },
  },
}));

interface ArrowBadgeProps {
  trend: 'up' | 'middle' | 'down';
  improvement?: 'higher' | 'lower';
}

export default function ArrowBadge({ improvement = 'higher', trend }: ArrowBadgeProps) {
  const classes = useStyles();

  const icon = `long-arrow-${trend}`.replace('middle', 'right');
  const improved = (trend === 'up' && improvement === 'higher') || (trend === 'down' && improvement === 'lower');

  const color = trend === 'middle' ? 'grey' : improved ? 'green' : 'red';

  return (
    <span role="img" className={clsx(classes.trend, classes[color])}>
      <FontAwesomeIcon icon={['fal', icon as IconName]} />
    </span>
  );
}
