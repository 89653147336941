import { makeStyles } from '@mui/styles';
import { Divider } from '@mui/material';
import {
  LargeNumericMetric,
  ProgressMonitoringMetric,
} from '../../../../reusables/scl';
import Progress from './Progress';
import useStudentDashboard from '../../../../reusables/useStudentDashoard';

const useStyles = makeStyles(() => ({
  stats: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: 44,
  },
  wrapper: {
    borderRadius: 6,
    border: 'solid 1px rgba(255, 255, 255, 0.26)',
    padding: '16px 36px 33px 36px',
  }
}));

export default function Stats({ analyst }: StatsProps) {
  const classes = useStyles();
  const {
    totalLifetimeTrainingDurationMinutes,
    totalLifetimeCourses,
    trainingMinutesThisWeek,
    trainingMinutesLastWeek,
  } = useStudentDashboard(analyst);

  return (
    <div className={classes.wrapper}>
      <Progress analyst={analyst} />
      <div className={classes.stats}>
        <LargeNumericMetric
          value={totalLifetimeTrainingDurationMinutes}
          label="Total Lifetime"
          labelSecondRow="Training Duration"
          unit="min"
        />
        <Divider orientation="vertical" flexItem />
        <LargeNumericMetric
          value={totalLifetimeCourses}
          label="Total Lifetime"
          labelSecondRow="Courses"
        />
        <Divider orientation="vertical" flexItem />
        <ProgressMonitoringMetric
          value={trainingMinutesThisWeek}
          valuePrevious={trainingMinutesLastWeek}
          unit="minutes"
          unitShort="min"
        />
      </div>
    </div>
  );
}

type StatsProps = {
  analyst?: string;
};
