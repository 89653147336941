import { useState } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import SearchBar from '../../../reusables/SearchBar';
import AccountStatus from './AccountStatus';
import { renderDate } from '../../../reusables/DataGrid';
import { RoleBadge } from '../../../reusables/scl';
import { Role, User, UserGroup } from '../../../services/interfaces';
import { SelectUserCallback, SelectUsersCallback } from './interfaces';

const useStyles = makeStyles({
  addWrapper: { float: 'right', marginRight: '10px', marginTop: '6px' },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
    marginBottom: 15,
    marginTop: 20,
  },
  toolbarLeft: {
    display: 'flex',
    flex: 1,
    maxWidth: 450,
  },
  toolbarRight: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: 20,
  },
  tableHead: {
    textTransform: 'uppercase',
  },
  tableCell: { color: '#2196F3' },
  searchContainer: {
    display: 'flex',
    overflow: 'hidden',
    width: '15%',
    height: '75%',
    float: 'left',
    zIndex: 5,
    marginTop: '10px',
    marginLeft: '10px',
  },
  tableWrapper: {
    height: 280,
  },
  searchBar: {
    width: '100%',
    zIndex: 5,
  },
  resizeClearIcon: {
    transform: 'scale(.9)',
  },
});

type StatusParams = GridCellParams<any, 'Active' | 'Disabled'>
const renderStatus = ({ value } : StatusParams) => value && <AccountStatus status={value} />;

type RoleParams = GridCellParams<any, Role[]>
const renderRoles = ({ value }: RoleParams) => {
  if (!value || value.length === 0) {
    return '';
  }

  return (
    <>
      {value.map(role => <RoleBadge key={role} role={role} />)}
    </>
  );
};

const columns: GridColDef[] = [{
  field: 'name',
  headerName: 'Name',
  flex: 2,
  editable: false,
}, {
  field: 'email',
  headerName: 'Email',
  flex: 2,
  editable: false,
}, {
  field: 'group',
  headerName: 'Group',
  flex: 1.5,
  editable: false,
}, {
  field: 'roles',
  headerName: 'Role(s)',
  flex: 1.5,
  editable: false,
  align: 'center',
  renderCell: renderRoles,
}, {
  field: 'createdAt',
  headerName: 'Created',
  flex: 2,
  editable: false,
  sortable: false,
  renderCell: renderDate,
}, {
  field: 'updatedAt',
  headerName: 'Last Updated',
  flex: 2,
  editable: false,
  sortable: false,
  renderCell: renderDate,
}, {
  field: 'status',
  headerName: 'Status',
  flex: 1.5,
  editable: false,
  renderCell: renderStatus,
}];

export default function AccountList({ accounts, groups, onClick, onSelect }: AccountListProps) {
  const [searchTerm, setSearchTerm] = useState('');
  const [statusFilter, setStatusFilter] = useState('allStatuses');
  const [rolesFilter, setRolesFilter] = useState(Role.NONE);
  const [groupFilter, setGroupFilter] = useState('allGroups');
  const classes = useStyles();

  // filter to accounts that match the searchTerm
  const filtered = accounts.filter(({
    name, email, roles, group, status,
  }) => {
    // return everything for no search
    if (!searchTerm) {
      return true;
    }

    // create a string of the arrays
    const rolesText = (roles as string[] || []).join('|');

    // merge into one long string
    const hash = [name, email, rolesText, group, status]
      .map(v => (v || '').trim().toLowerCase()).filter(v => v)
      .join('|');

    // check if the search term is included
    return hash.indexOf(searchTerm.trim().toLowerCase()) >= 0;
  }).filter(({ status }) => {
    // filter users by status
    if (statusFilter === 'allStatuses') {
      return true;
    }

    return status === statusFilter;
  }).filter(({ roles }) => {
    // filter users by roles
    if (rolesFilter === Role.NONE) {
      return true;
    }

    return roles.includes(rolesFilter);
  }).filter(({ group }) => {
    if (groupFilter === 'allGroups') {
      return true;
    }

    return group === groupFilter;
  });

  return (
    <>
      <Typography variant="h5">Users</Typography>
      <div className={classes.toolbar}>
        <div className={classes.toolbarLeft}>
          <SearchBar placeholder="Search Accounts" onChange={setSearchTerm} />
        </div>
        <div className={classes.toolbarRight}>
          <Select
            value={groupFilter}
            onChange={e => { setGroupFilter(e.target.value as string); }}
          >
            <MenuItem value="allGroups">All Groups</MenuItem>
            <MenuItem value="Other Analysts">Other Analysts</MenuItem>
            {groups.map(group => (<MenuItem key={group.id} value={group.name}>{group.name}</MenuItem>))}
          </Select>
          <Select
            value={rolesFilter}
            displayEmpty
            onChange={e => { setRolesFilter(e.target.value as Role); }}
          >
            <MenuItem value={Role.NONE}>All Roles</MenuItem>
            <MenuItem value={Role.ADMIN}>Admin</MenuItem>
            <MenuItem value={Role.INSTRUCTOR}>Instructor</MenuItem>
            <MenuItem value={Role.ANALYST}>Analyst</MenuItem>
            <MenuItem value={Role.BUSINESS}>Business Analyst</MenuItem>
            <MenuItem value={Role.FRAMEWORK_ANALYST}>Framework Analyst</MenuItem>
          </Select>
          <Select
            value={statusFilter}
            onChange={e => { setStatusFilter(e.target.value as string); }}
          >
            <MenuItem value="allStatuses">All Statuses</MenuItem>
            <MenuItem value="Active">Active</MenuItem>
            <MenuItem value="Disabled">Disabled</MenuItem>
          </Select>
        </div>
      </div>
      <div data-testid="account-list-table" className={classes.tableWrapper}>
        <DataGrid
          rows={filtered}
          columns={columns}
          density="compact"
          onRowClick={e => onClick(e.row.id)}
          checkboxSelection
          disableRowSelectionOnClick
        />
      </div>
    </>
  );
}

type AccountListProps = {
  accounts: User[];
  groups: UserGroup[];
  onClick: SelectUserCallback;
  onSelect: SelectUsersCallback;
}
