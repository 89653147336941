import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import ProductItem from './ProductItem';
import { BakeOffProduct } from '../interfaces';

const useStyles = makeStyles(() => ({
  container: {
    paddingLeft: 28,
    paddingRight: 28,
    paddingBottom: 35,
    paddingTop: 26,
    height: 292,

    display: 'flex',
    flexDirection: 'column',
    background: '#0b0c12',
    borderRadius: 6,
  },
  header: {
    color: '#ffffff',
    paddingBottom: 30,
  },
  products: {
    display: 'flex',
    flexDirection: 'row',
    '& > *': {
      marginRight: 25,
    },
  },
}));

export default function SwitchProducts({
  products,
  selected = 0,
  onSelect = (n: number) => undefined,
}: SwitchProductsProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.header} variant="h5">Select a Product to review</Typography>
      <div className={classes.products}>
        {products.map((product, id) => (
          <ProductItem
            heights={{
              default: 3,
              hover: 7,
            }}
            key={`product-select-${product.name}`}
            item={product}
            size="medium"
            enableHover
            borderless
            enableSeletion
            onClick={() => onSelect(id)}
            selected={selected === id}
          />
        ))}
      </div>
    </div>
  );
}

type SwitchProductsProps = {
  products: BakeOffProduct[];
  selected?: number;
  onSelect?: (n: number) => unknown;
};