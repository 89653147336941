import { Checkbox, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useContext, useEffect } from 'react';
import Picker from './Picker';
import { addHours } from 'date-fns';
import EvaluationWindowContext from '../../EvaluationWindowContext';
import StepWrapper from '../StepWrapper';

const useStyles = makeStyles(theme => ({
  select: {
    display: 'flex',
    alignItems: 'center',
  }
}));

export default function Schedule() {
  const classes = useStyles();
  const {
    startAfter,
    startBefore,
    handleDisableNextBtn,
    handleUpdateStartAfter,
    handleUpdateStartBefore,
  } = useContext(EvaluationWindowContext);

  useEffect(() => {
    handleDisableNextBtn(false);
  }, []);
  
  const handleStartChange = (e: any) => {
    if (e.target.checked) {
      const now = new Date();
      handleUpdateStartAfter(new Date());
      handleUpdateStartBefore(addHours(now, 8));
    } else {
      handleUpdateStartAfter(undefined);
      handleUpdateStartBefore(undefined);
    }
  };

  const handleEndChange = (e: any) => {
    if (e.target.checked) {
      handleUpdateStartBefore(new Date());
    } else {
      handleUpdateStartBefore(undefined);
    }
  };

  return (
    <StepWrapper>
      <div>
        <div className={classes.select}>
          <Checkbox color="primary" checked={!!startAfter} onChange={handleStartChange} />
          <Typography>Exam must start after:</Typography>
        </div>
        {!!startAfter && <Picker value={startAfter} onChange={handleUpdateStartAfter} minDate={new Date()} />}
      </div>
      <div>
        <div className={classes.select}>
          <Checkbox color="primary" checked={!!startBefore} onChange={handleEndChange} disabled={!startAfter} />
          <Typography>Exam must start before:</Typography>
        </div>
        {!!startBefore && (
          <Picker value={startBefore} onChange={handleUpdateStartBefore} minDate={startAfter ?? new Date()} />
        )}
      </div>
    </StepWrapper>
  );
}

Schedule.label = 'Schedule Exam';