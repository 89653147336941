import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { makeStyles } from '@mui/styles';
import { CSSProperties } from 'react';
import { ModalProps } from './interfaces';

const useStyles = makeStyles({
  title: {
    textTransform: 'capitalize',
    paddingBottom: 0,
  },
  centerActions: {
    justifyContent: 'center',
  }
});

export default function Modal({
  actions = [],
  centerActions = false,
  children,
  open = false,
  title = '',
  onClose,
  onSubmit = () => undefined,
  fullWidth = false,
  maxWidth = 'md',
}: ModalProps) {
  const classes = useStyles();

  const renderContent = () => (
    <>
      { title && <DialogTitle className={classes.title}>{title}</DialogTitle> }
      <DialogContent>{children}</DialogContent>
      { !!actions.length && (
        <DialogActions className={centerActions ? classes.centerActions : undefined}>
          {actions.map(({
            color, text, onClick, submit, variant
          }) => {
            const style: CSSProperties = {};
            const bgColor = color ?? 'primary';

            if (!/^default|primary|secondary|inherit$/.test(bgColor)) {
              style.backgroundColor = bgColor;
            }

            return (
              <Button
                type={submit ? 'submit' : 'button'}
                style={style}
                variant={variant ?? 'contained'}
                key={`action-${text}`}
                onClick={onClick}
                color={color as 'default' | 'primary' | 'secondary' | 'inherit' | undefined as any}
              >
                {text}
              </Button>
            );
          })}
        </DialogActions>
      ) }
    </>
  );

  return (
    <Dialog maxWidth={maxWidth} open={open} onClose={() => onClose()} fullWidth={fullWidth}>
      { /* onSubmit means this is a form */ }
      {!!onSubmit && (
        <form onSubmit={onSubmit}>{renderContent()}</form>
      )}

      { /* no onSubmit means this isn't a form */}
      {!onSubmit && renderContent()}
    </Dialog>
  );
}