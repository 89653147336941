import { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@mui/styles';
import appStore from './AppStore';

type UseEffectFn<T> = () => Promise<T> | T;
type UseAbortCallback<T> = (arg: T) => Promise<unknown> | unknown;

/**
 * Effect that uses the abort and loading pattern
 * @param func async function that returns a result
 * @param call callback function that accepts a result
 * @param deps effect dependencies
 * @param cleanup function executed on cleanup
 */
export function useAbort<T>(
  func: UseEffectFn<T>, call: UseAbortCallback<T>, deps: any[] = [], cleanup = () => { }, loading = true
) {
  useEffect(() => {
    let abort = false;
    const fetchData = async () => {
      if (loading) {
        appStore.beginLoading();
      }
      try {
        const result = await func();
        if (!abort) {
          call(result);
        }
      } catch (err) {
        appStore.error(err);
      }
      if (loading) {
        appStore.endLoading();
      }
    };
    fetchData();
    return () => {
      cleanup();
      abort = true;
    };
  }, deps);
}

/**
 * Similar to useEffect but does nothing on the first run
 * @param func
 * @param deps
 */
export function useEffectSkipInitial<T>(func: UseEffectFn<T>, deps: any[] = []) {
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      func();
    }
  }, deps);
}

const useColorStyles = makeStyles(() => ({
  scoreRed: {
    '&,&.MuiTypography-body1': {
      color: '#f85c5c',
    }
  },
  scoreYellow: {
    '&,&.MuiTypography-body1': {
      color: '#f48e43',
    }
  },
  scoreOrange: {
    '&,&.MuiTypography-body1': {
      color: '#feba06',
    }
  },
  scoreGreen: {
    '&,&.MuiTypography-body1': {
      color: '#06fece',
    }
  },
}));

export const useScoreClass = () => {
  const classes = useColorStyles();

  return (score?: number) => {
    if (score === undefined) {
      return '';
    }

    if (score <= 25) {
      return classes.scoreRed;
    }
    
    if (score <= 50) {
      return classes.scoreYellow;
    }
    
    if (score <= 75) {
      return classes.scoreOrange;
    }
  
    return classes.scoreGreen;
  };
};

export const useScrollPagination = (id: string) => {
  const [page, setPage] = useState(1);

  useEffect(() => {
    // TODO: check if React has figured out that scroll events are not just events yet
    const listenToScroll = (e: any) => {
      const height = e.target.scrollHeight - e.target.clientHeight;
      const diff = height - e.target.scrollTop;
      if (diff < 400) {
        setPage(page + 1);
      }
    };

    const element = document.getElementById(id);
    if (!element) {
      return;
    }

    element.addEventListener('scroll', listenToScroll);
    return () => element.removeEventListener('scroll', listenToScroll);
  }, [page]);

  return page;
};

/**
 * When using a react hook to get data from the backend,
 * useAbort isn't used so a loading indicator needs to be addressed
 * separately. useLoading takes a boolean that can related to the
 * state variable that is waiting for populateion
 * @example useLoading(!dataFromApi)
 * @param isLoading 
 */
export const useLoading = (isLoading = false) => {
  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (isLoading && !showLoading) {
      setShowLoading(true);
      appStore.beginLoading();
      return;
    }

    if (!isLoading && showLoading) {
      setShowLoading(false);
      appStore.endLoading();
      return;
    }

  }, [isLoading, showLoading]);
};