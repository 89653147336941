import { MouseEvent, useState } from 'react';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DataGrid, GridCellParams } from '@mui/x-data-grid';
import { arrayToString } from '@sightgain/core/strings';
import appStore from '../../../../AppStore';
import { useAbort } from '../../../../effects';
import { testingService } from '../../../services';
import { Endpoint } from '../../../services/interfaces';

const useStyles = makeStyles({
  grid: {
    height: 270,
    width: '100%',
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    marginBottom: 20,
  },
});

export default function Endpoints({ className = '' }: { className?: string }) {
  const classes = useStyles();
  const [endpoints, setEndpoints] = useState<EndpointRow[]>([]);

  useAbort(
    () => testingService.endpoints(),
    result => setEndpoints(result.map(r => ({ ...r, products: arrayToString(r.products.map(p => p.name)) }))),
  );

  const handleRemove = async (e: MouseEvent<HTMLButtonElement>, id: string) => {
    e.stopPropagation();
    appStore.beginLoading();
    try {
      await testingService.deleteEndpoint(id);
      setEndpoints(endpoints.filter(t => t.id !== id));
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  const actions = ({ row }: GridCellParams) => {
    const { id } = row as Endpoint;
    return (
      <Button variant="contained" color="primary" onClick={e => handleRemove(e, id)} size="small">
        Remove
      </Button>
    );
  };

  const columns = [
    {
      field: 'hostname',
      headerName: 'Hostname',
      flex: 2,
      editable: false,
    },
    {
      field: 'os',
      headerName: 'Operating System',
      flex: 2,
      editable: false,
    },
    {
      field: 'products',
      headerName: 'Products',
      flex: 2,
      editable: false,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 2,
      renderCell: actions,
    },
  ];

  return (
    <div className={className}>
      <div className={classes.topRow}>
        <Typography variant="h5">Endpoints</Typography>
      </div>
      <div className={classes.grid}>
        <DataGrid rows={endpoints} columns={columns} density="compact" />
      </div>
    </div>
  );
}

type EndpointRow = Pick<Endpoint, 'id' | 'hostname' | 'os'> & {
  products: string;
};
