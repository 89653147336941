import { Divider, Grid } from '@mui/material';
import preview1 from '../preview-1.jpg';
import preview2 from '../../../images/adversaries.png';
import {
  CurriculumHeader,
} from '../';
import { TrainingGroupLevel } from '../../../services/interfaces';
import {
  Card,
  CardBackground,
  CardBadge,
  CardButton,
  CardContent,
  CardDetails,
  CardLink,
  CardProgress,
  CardText,
} from '../../../pages/Curriculum/components/Card';
import { makeStyles } from '@mui/styles';
import { ProgressBadge } from '../../Badges';
import clsx from 'clsx';
import { format } from 'date-fns';

const useStyles = makeStyles({
  cardRowChild: {
    flex: '0 1 50%',
    display: 'flex',
    gap: 24,
  },
  cardRowEnd: {
    justifyContent: 'flex-end',
  },
});

export default function PreviewCurriculums() {
  const classes = useStyles();

  const title = 'Lorem ipsum dolor sit amet, consectetur';
  const category = 'Category Name';
  const courses = '15 Courses';
  const duration = '14 Days';
  const expires = `Exp. ${format(new Date('2022-06-01'), 'MM/dd/yyyy')}`;
  const assigned = `Assigned. ${format(new Date('2022-05-15'), 'MM/dd/yyyy')}`;

  return (
    <>
      <h2> Curriculum Header </h2>
      <CurriculumHeader
        image={preview1}
        onBookmark={() => {}}
        title="Curriculum Name Lorem ipsum dolor sit amet, consectetur "
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip."
        level={TrainingGroupLevel.Tier2}
        courses={15}
        durationDays={5}
      />
      <CurriculumHeader
        image={preview1}
        onBookmark={() => {}}
        title="Curriculum Name Lorem ipsum dolor sit amet, consectetur "
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
          tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris nisi ut aliquip."
        level={TrainingGroupLevel.Tier3}
        courses={15}
        durationDays={5}
        instructor={{
          name: 'FirstName LastName',
          image: preview2,
        }}
      />
      <h2> Curriculum Card </h2>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <CardLink to={window.location.href}>
            <Card>
              <CardBackground image={preview1}>
                <CardContent>
                  <CardDetails large level={TrainingGroupLevel.Tier1} title={title} />
                </CardContent>
              </CardBackground>
              <CardContent variant="dense">
                <CardText>{title}</CardText>
              </CardContent>
              <CardContent variant="dense" row>
                <div className={classes.cardRowChild}>
                  <CardBadge icon={['fal', 'users-class']} text={courses} />
                  <CardBadge icon={['fal', 'clock']} text={duration} />
                </div>
                <div className={clsx(classes.cardRowChild, classes.cardRowEnd)}>
                  <CardText variant='category'>{category}</CardText>
                </div>
              </CardContent>
            </Card>
          </CardLink>
        </Grid>

        <Grid item xs={4}>
          <CardButton onClick={() => alert('button pressed')}>
            <Card>
              <CardBackground image={preview1}>
                <CardContent>
                  <CardDetails large level={TrainingGroupLevel.Tier2} title="Lorem ipsum dolor sit amet, consectetur" />
                </CardContent>
              </CardBackground>
              <CardContent variant="dense">
                <CardText>Lorem ipsum dolor sit amet, consectetur</CardText>
              </CardContent>
              <CardContent variant="dense" row>
                <div className={classes.cardRowChild}>
                  <CardBadge icon={['fal', 'users-class']} text={courses} />
                  <CardBadge icon={['fal', 'clock']} text={duration} />
                </div>
                <div className={clsx(classes.cardRowChild, classes.cardRowEnd)}>
                  <ProgressBadge progress='completed' />
                </div>
              </CardContent>
            </Card>
          </CardButton>
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardBackground image={preview1}>
              <CardContent>
                <CardDetails large level={TrainingGroupLevel.Tier3} title={title} />
              </CardContent>
            </CardBackground>
            <CardContent variant="dense">
              <CardText>{title}</CardText>
            </CardContent>
            <CardContent variant="dense" row>
              <CardProgress progress={30} />
              <CardText variant='category'>{category}</CardText>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardBackground image={preview1}>
              <CardContent>
                <CardDetails large level={TrainingGroupLevel.Tier1} title={title} />
              </CardContent>
            </CardBackground>
            <CardContent variant="dense" row>
              <div className={classes.cardRowChild}>
                <CardBadge icon={['fal', 'users-class']} text={courses} />
                <CardBadge icon={['fal', 'clock']} text={duration} />
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardBackground image={preview1}>
              <CardContent>
                <CardDetails large level={TrainingGroupLevel.Tier2} title={title} />
              </CardContent>
            </CardBackground>
            <CardContent variant="dense" row>
              <CardProgress progress={40} />
              <div className={clsx(classes.cardRowChild, classes.cardRowEnd)}>
                <CardBadge icon={['fal', 'users-class']} text={courses} />
                <CardBadge icon={['fal', 'clock']} text={duration} />
              </div>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardBackground image={preview1}>
              <CardContent>
                <CardDetails large level={TrainingGroupLevel.Tier3} title={title} />
              </CardContent>
            </CardBackground>
            <CardContent variant="dense" row>
              <div className={classes.cardRowChild}>
                <CardBadge icon={['fal', 'users-class']} text={courses} />
                <CardBadge icon={['fal', 'clock']} text={duration} />
              </div>
              <div className={clsx(classes.cardRowChild, classes.cardRowEnd)}>
                <ProgressBadge progress='completed' />
              </div>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <h2> Course Cards </h2>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Card>
            <CardContent variant="medium">
              <CardDetails level={TrainingGroupLevel.Tier1} title={title} />
            </CardContent>
            <Divider />
            <CardContent row>
              <CardBadge icon={['fal', 'clock']} text={duration} />
              <CardText variant='category'>{category}</CardText>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardContent variant="medium">
              <CardDetails level={TrainingGroupLevel.Tier2} title={title} />
            </CardContent>
            <Divider />
            <CardContent row>
              <CardBadge icon={['fal', 'clock']} text={duration} />
              <CardText variant='category'>{category}</CardText>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={4}>
          <Card>
            <CardContent variant="medium">
              <CardDetails level={TrainingGroupLevel.Tier3} title={title} />
            </CardContent>
            <Divider />
            <CardContent row>
              <CardBadge icon={['fal', 'clock']} text={duration} />
              <CardText variant='category'>{category}</CardText>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent row variant="medium">
              <CardDetails level={TrainingGroupLevel.Tier3} title={title} />
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                <CardBadge text={assigned} />
                <CardBadge text={expires} />
              </div>
            </CardContent>
            <Divider />
            <CardContent variant="dense" row>
              <CardBadge icon={['fal', 'clock']} text={duration} />
              <CardText variant='category'>{category}</CardText>
            </CardContent>
          </Card>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent row variant="medium">
              <CardDetails level={TrainingGroupLevel.Tier3} title={title} />
              <div style={{ display: 'flex', flexDirection: 'column', textAlign: 'right' }}>
                <CardBadge text={assigned} />
                <CardBadge text={expires} />
              </div>
            </CardContent>
            <Divider />
            <CardContent variant="dense" row className={classes.cardRowEnd}>
              <CardText variant='category'>{category}</CardText>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardContent variant='dense' row>
              <CardDetails level={TrainingGroupLevel.Tier1} title={title} />
              <CardBadge icon={['fal', 'clock']} text={duration} />
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <Card>
            <CardContent variant='dense'>
              <CardDetails level={TrainingGroupLevel.Tier2} title={title} />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}