import { ChangeEvent, useState } from 'react';
import { useAbort } from '../../../../effects';
import Controls from './Controls';
import { Framework, ImportFrameworkInput } from '../../../services/interfaces';
import FrameworksService from '../../../services/FrameworksService';
import FrameworkCard from './FrameworkCard';
import ImportFrameworkModal from './ImportFrameworkModal';
import CloneModal from './CloneModal';
import appStore from '../../../../AppStore';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router';

const useStyles = makeStyles(() => ({
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    marginTop: 32,
  },
  cardContainer: {
    marginTop: 32,
    flex: 1,
    overflowY: 'auto',
  },
}));

export default function FrameworkList() {
  const classes = useStyles();
  const history = useHistory();
  const [frameworks, setFrameworks] = useState<Framework[]>([]);
  const [filter, setFilter] = useState('all');
  const [openImportModal, setOpenImportModal] = useState(false);
  const [selectedFramework, setSelectedFramework] = useState<undefined | Framework>(undefined);
  const [refresh, setRefresh] = useState(0);

  useAbort(() => {
    return FrameworksService.all();
  }, frameworks => {
    const fws = frameworks.map((fw, idx) => {
      return { ...fw, id: fw._id };
    });
    setFrameworks(fws);
  }, [refresh]);

  const toggleFrameworkEnabled = async (id: string) => {
    let active = false;
    setFrameworks(prev => {
      return prev.map(fw => {
        if (fw._id === id) {
          fw.active = !fw.active;
          active = fw.active;
        }
        return fw;
      });
    });
    await FrameworksService.updateFramework(id, active);
  };

  const handleOpenCloneModal = (f: Framework) => {
    setSelectedFramework(f);
  };

  const handleClone = async (name: string, newTitle: string) => {
    if (appStore.isLoading) {
      return;
    }
    appStore.beginLoading();
    try {
      await FrameworksService.cloneFramework(name, newTitle);
    } catch (err) {
      appStore.error(err);
    }
    setSelectedFramework(undefined);
    appStore.endLoading();
    setRefresh(refresh + 1);
  };

  const handleDelete = async (name: string, version: string) => {
    if (appStore.isLoading) {
      return;
    }
    appStore.beginLoading();
    try {
      await FrameworksService.deleteFramework(name, version);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
    setRefresh(refresh + 1);
  };

  const handleImport = async (input: ImportFrameworkInput) => {
    if (appStore.isLoading) {
      return;
    }
    appStore.beginLoading();
    try {
      await FrameworksService.importFramework(input);
      setOpenImportModal(false);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
    setRefresh(refresh + 1);
  };

  const handleEdit = (name: string) => {
    history.push(`/risk/management/edit/${name}`);
  };

  const handleFilterChange = (e: ChangeEvent<{ value: unknown }>) => {
    setFilter(e.target.value as string);
  };

  const filterFrameworks = (f: Framework) => {
    if (filter === 'all') {
      return true;
    }
    if (filter === 'system' && f.isSystem) {
      return true;
    }
    if (filter === 'cloned' && !f.isSystem) {
      return true;
    }
    return false;
  };

  const sortFrameworks = (a: Framework, b: Framework) => {
    // sort by name, then version
    if (a.name > b.name) {
      return 1;
    } else if (a.name < b.name) {
      return -1;
    } else {
      if (a.version < b.version) {
        return 1;
      } else {
        return -1;
      }
    }
  };

  return (
    <div className={classes.wrapper}>
      <Controls filter={filter} onFilterChange={handleFilterChange} onImport={() => setOpenImportModal(true)} />
      <div className={classes.cardContainer}>
        {frameworks
          .filter(filterFrameworks)
          .sort(sortFrameworks)
          .map(f =>
            <FrameworkCard
              key={f.id}
              onToggle={() => toggleFrameworkEnabled(f.id)}
              onClone={() => handleOpenCloneModal(f)}
              onDelete={() => handleDelete(f.name, f.version)}
              fw={f}
              onEdit={() => handleEdit(f.name)}
            />
          )}
      </div>
      <ImportFrameworkModal
        open={openImportModal}
        onImport={handleImport}
        onClose={() => setOpenImportModal(false)}
      />
      {!!selectedFramework && <CloneModal
        open={!!selectedFramework}
        onClose={() => setSelectedFramework(undefined)}
        framework={selectedFramework}
        onClone={handleClone}
      />}
    </div>
  );
}
