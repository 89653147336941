export const typeLiveFireTraining = `
{
  id
  started
  analyst {
    id
    name
    email
  }
  source
  completed
  createdAt
  ended
  feedback
  job {
    id
    vendorId
    vendor
    isExcluded
    evaluationId
    name
    description
  }
  instructorScore
  isReleased
  isSelfTest
  isExam
  tags
  status
  timeLimit
  updatedAt
  parent { id }
  analystInput {
    id
    createdAt
    description
    event
  }
  timePassed
  eventScore
  precisionScore
  responseScore
  score
}
`;