import { format } from 'date-fns';
import { CSSProperties, useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ProductBakeOffResult } from '../../../../services/interfaces';
import { BakeOffGroup, BakeOffProduct, ProcessedDataGroups, ProcessedDataItem } from '../interfaces';
import ProductItem from './ProductItem';
import ProductTestResultsTable from './ProductTestResultsTable';

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    marginRight: 25,
    '&:last-of-type': {
      marginRight: 0,
    },
    borderRadius: 6,
    overflow: 'hidden',
  },
  header: {
    height: 174,
    minHeight: 174,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#ffffff',
  },
  body: {
    flexGrow: 1,
    minWidth: 280,
    display: 'flex',
    flexDirection: 'column',
    background: 'rgba(255, 255, 255, 0.09)',
  },
  footer: {
    display: 'flex',
    alignItems: 'center',
    height: 78,
    paddingLeft: 25,
    paddingRight: 25,
    background: 'rgba(255, 255, 255, 0.09)',
    boxShadow: '20px 20px 160px 0 rgba(0, 0, 0, 0.51)',
  },
  removeButton: {
    width: '100%',
    backgroundColor: '#f85c5c',
    borderColor: '#f85c5c',
    '& > span': {
      justifyContent: 'space-between',
    },
    '&:hover': {
      backgroundColor: '#c64949',
      borderColor: '#c64949',
    },
  },
  fillVertical: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    padding: 12,
    '& > *': {
      alignSelf: 'center',
      textAlign: 'center',
      width: '100%',
    },
  },
});

export default function ProductTestResults({
  groups,
  bakeOffResults,
  product,
  evaluationName,
  onClose = () => undefined,
  thresholdStyle,
}: ProductTestResultsProps) {
  const classes = useStyles();
  const ALL_JOBS = 'All Jobs';
  const [jobFilter, setJobFilter] = useState<string>(ALL_JOBS);

  // reset the job filter when the results change
  useEffect(() => {
    setJobFilter(ALL_JOBS);
  }, [bakeOffResults]);

  const processedData = useMemo(() => {
    // limit to the selected results only
    const results = jobFilter === ALL_JOBS ? bakeOffResults : bakeOffResults.filter(r => r.vendorJobId === jobFilter);

    // compute the results
    const groupsData = groups.reduce((a: ProcessedDataGroups, b) => {
      const groupResults = results.filter(r => b.testIds.includes(r.vendorTestId));
      const total = groupResults.length;
      const prevented = groupResults.filter(r => r.prevented).length;
      const detected = groupResults.filter(r => r.detected).length;

      return {
        ...a,
        [b.name]: {
          prevented,
          detected,
          total,
        },
      };
    }, {});

    return {
      prevented: results.filter(r => r.prevented).length,
      detected: results.filter(r => r.detected).length,
      total: results.length,
      groups: groupsData,
    };
  }, [groups, bakeOffResults, jobFilter]);

  const jobs = bakeOffResults.reduce((a, b) => {
    if (!a.some(p => p.value === b.vendorJobId)) {
      a.push({ value: b.vendorJobId, text: `${b.vendorJobId} - ${format(b.createdAt, 'yyyy-MM-dd HH:mm:ss')}` });
    }

    return a;
  }, [] as Array<{ text: string; value: string }>);

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <ProductItem item={product} borderless titlePosition="bottom" />
      </div>
      <div style={{ marginTop: 4, marginBottom: 4 }}>
        <Select
          value={jobFilter}
          onChange={({ target: { value } }) => setJobFilter(value)}
          variant="outlined"
          fullWidth
          defaultValue={ALL_JOBS}
        >
          <MenuItem value={ALL_JOBS}>{ALL_JOBS}</MenuItem>
          {jobs.map(j => (
            <MenuItem key={j.value} value={j.value}>
              {j.text}
            </MenuItem>
          ))}
        </Select>
      </div>
      <div className={classes.body}>
        {!evaluationName && (
          <div className={classes.fillVertical}>
            <Typography variant="h3">Please select an evaluation from the left</Typography>
          </div>
        )}
        {evaluationName && (
          <ProductTestResultsTable data={processedData} groups={groups} thresholdStyle={thresholdStyle} />
        )}
      </div>
      <div className={classes.footer}>
        <Button
          className={classes.removeButton}
          color="secondary"
          variant="contained"
          onClick={() => onClose()}
          endIcon={<FontAwesomeIcon icon={['fal', 'trash-alt']} />}
        >
          Remove
        </Button>
      </div>
    </div>
  );
}

type ProductTestResultsProps = {
  product: BakeOffProduct;
  groups: BakeOffGroup[];
  onClose?: () => unknown;
  bakeOffResults: ProductBakeOffResult[];
  thresholdStyle: (value: ProcessedDataItem, prevention?: boolean) => CSSProperties;
  evaluationName: string;
};
