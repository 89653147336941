import { Card as MuiCard } from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';
import { ReactNode } from 'react';

const useStyles = makeStyles({
  card: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '&:hover': {
      filter: 'drop-shadow(2px 2xp 2xp black)',
    }
  },
  cardSelected: {
    border: 'solid 3px rgba(71, 146, 247, 0.51)',
  },
});



export default function Card({ className, children, selected }: CardProps) {
  const classes = useStyles();

  return (
    <MuiCard className={clsx(classes.card, selected && classes.cardSelected, className )}>
      {children}
    </MuiCard>
  );
}

type CardProps = {
  className?: string;
  children: ReactNode | Iterable<ReactNode>;
  selected?: boolean;
}