import { useEffect, useState } from 'react';
import { notificationService } from '../services';
import { socket } from '../services';
import { Notification } from '../services/interfaces';

type MarkReadFn = (id: string) => Promise<unknown>;
type MarkAllReadFn = () => Promise<unknown>;

export default function useNotifications(): [Notification[], number, MarkReadFn, MarkAllReadFn] {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  useEffect(() => {
    const refresh = async () => {
      const result = await notificationService.recentNotifications();
      setNotifications(result);
    };

    refresh();

    const unsubscribe = socket.subscribe(refresh, { type: 'notifications', action: 'update' });

    return () => {
      unsubscribe();
    };
  }, []);

  const unreadNotificationsCount = notifications.filter(n => n.read === false).length;

  const markRead = async (id: string) => {
    await notificationService.markRead(id);
  };

  const markAllRead = async () => {
    await notificationService.markAllRead();
  };

  return [notifications, unreadNotificationsCount, markRead, markAllRead];
}