import { makeStyles } from '@mui/styles';
import { Drawer } from '@mui/material';
import clsx from 'clsx';
import { ReactNode } from 'react';

const drawerWidth = 360;

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    position: 'relative',
    zIndex: 1,
  },
  drawerClosed: {
    width: '84px !important',
    overflowX: 'hidden',
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: '#161926',
    boxShadow: '20px 20px 20px 0 rgba(0, 0, 0, 0.17)',
    paddingTop: 26,
    border: 0,
  },
  drawerContainer: {
    overflowX: 'hidden',
    overflowY: 'hidden',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
}));

interface BarProps {
  open?: boolean;
  children: ReactNode;
}

export default function Bar({ open = true, children }: BarProps) {
  const classes = useStyles();

  return (
    <div>
      <Drawer
        className={clsx({ [classes.drawerClosed]: !open }, classes.drawer)}
        variant="permanent"
        classes={{
          paper: clsx({ [classes.drawerClosed]: !open }, classes.drawerPaper),
        }}
      >
        <div className={classes.drawerContainer}>{children}</div>
      </Drawer>
    </div>
  );
}
