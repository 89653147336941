import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ReactNode } from 'react';

const useStyles = makeStyles(theme => ({
  stepTitle: {
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(0.5),
  },
}));

export default function StepTitle({ children }: { children: ReactNode | ReactNode[] }) {
  const classes = useStyles();
  return (
    <Typography className={classes.stepTitle}>
      {children}
    </Typography>
  );
}