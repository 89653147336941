import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import {
  IconButton,
  Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  PassFail,
  StatusBadge,
  Tag,
} from '../../../../reusables/scl';
import EventItem from './EventItem';
import EventModal from './EventModal';
import matchEvents from './matchEvents';
import { AnalystInput } from '../../../../services/interfaces';
import { ERTestResult } from './interfaces';
import appStore from '../../../../../AppStore';

const useStyles = makeStyles(() => ({
  container: {
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#0b0c12',
    borderRadius: 6,
    overflow: 'hidden',
    width: 692,
    height: 475,
  },
  topRow: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '29px 29px 18px 29px',
  },
  middleRow: {
    flex: 1,
    padding: '34px 29px 29px 29px',
    borderTop: 'solid 2px #1F2336',
  },
  bottomRow: {
    padding: '22px 29px 20px 29px',
    borderTop: 'solid 2px #1F2336',
    // mui makeStyles does not recognize overlay as it is not a standard CSS rule
    overflowY: 'overlay' as any,
    display: 'flex',
    flexDirection: 'column',
  },
  bottomRowHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  bottomRowHeaderLeft: {
    display: 'flex',
    gap: 40,
  },
  bottomRowIndicator: {
    display: 'flex',
    gap: 10,
  },
  events: {
    marginTop: 23,
    overflowY: 'auto',
  },
  status: {
    display: 'flex',
    gap: 20,
    marginTop: 12,
  },
  score: {
    fontSize: 12,
  },
  tagWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: 14,
    marginTop: 29,
    maxHeight: 70,
    overflow: 'auto',
  },
  date: {
    marginTop: 12,
    '& > p': {
      color: '#a7adb5',
    },
  },
  hidden: {
    display: 'none',
  },
  runningIndicator: {
    border: 'solid 3px rgba(0, 255, 177, 0.51)',
  },
}));

export default function MatchedCard(
  { testResult, type, analystInput = [], prevented, detected }: MatchedCardProps) {
  const classes = useStyles();

  const [expanded, setExpanded] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [eventIdx, setEventIdx] = useState(0);
  const eventData = testResult.events[eventIdx] || {};

  const matchedEvents = matchEvents(testResult, analystInput);
  const matchedEventCount = Object.keys(matchedEvents).filter(key => matchedEvents[key].length > 0).length;
  const detectedEventCount = testResult.events.length;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && testResult.status === 'running') {
      containerRef.current.scrollIntoView({
        behavior: 'smooth',
      });
    }
  }, [testResult.status]);

  return (
    <div
      ref={containerRef}
      className={clsx(classes.container, {
        [classes.runningIndicator]: testResult.status === 'running',
      })}
    >
      <div className={classes.topRow}>
        <div style={{ width: '100%' }}>
          <Typography variant="h5">{testResult.name}</Typography>
          <div className={classes.status}>
            <div>
              <span>Blocked: &nbsp;</span>
              <PassFail pass={prevented} />
            </div>
            <div>
              <span>Detected: &nbsp;</span>
              <PassFail pass={detected} />
            </div>
          </div>
          <div className={classes.date}>
            <Typography variant="body2">
              Began at
              {' '}
              {testResult.started?.toString() ?? '(Unknown)'}
            </Typography>
            <Typography variant="body2">
              Ended at
              {' '}
              {testResult.ended?.toString() ?? '(Unknown)'}
            </Typography>
          </div>
        </div>
        <div>
          <StatusBadge status={testResult.status} />
        </div>
      </div>
      <div className={clsx(classes.middleRow, { [classes.hidden]: expanded })}>
        <Typography variant="body1">
          {(testResult.description ?? '').slice(0, 300)}
        </Typography>
        <div className={classes.tagWrapper}>
          {testResult.tags.map(tag => <Tag tag={tag} key={tag} />)}
        </div>
      </div>
      {appStore.SIP !== 'picus' && (
        <div className={classes.bottomRow}>
          <div className={classes.bottomRowHeader}>
            <div className={classes.bottomRowHeaderLeft}>
              <Typography variant="h5">
                { detectedEventCount }
                {' '}
              Events Detected
              </Typography>

              {type === 'lft' && (
                <>
                  <div className={classes.bottomRowIndicator}>
                    <FontAwesomeIcon icon={['fal', 'clone']} size="lg" style={{ color: '#2ecff5' }} />
                    <Typography variant="body1">
                      {matchedEventCount}
                    </Typography>
                  </div>
                  <div className={classes.bottomRowIndicator}>
                    <FontAwesomeIcon icon={['fal', 'question-square']} size="lg" />
                    <Typography variant="body1">
                      {detectedEventCount - matchedEventCount}
                    </Typography>
                  </div> 
                </>
              )}
            </div>
            {testResult.events.length > 0 && (
              <IconButton onClick={() => setExpanded(!expanded)} size="small">
                {!expanded && <FontAwesomeIcon icon={['fal', 'plus']} />}
                {expanded && <FontAwesomeIcon icon={['fal', 'minus']} />}
              </IconButton>
            )}
          </div>
          { expanded && (
            <div className={classes.events}>
              {testResult.events.map((event, index) => (
                <EventItem
                  type={type}
                  key={event.id}
                  data={event}
                  matched={matchedEvents[index].length > 0}
                  onClick={() => {
                    setEventIdx(index);
                    setModalOpen(true);
                  }}
                />
              ))}
            </div>
          )}
          {testResult.events.length > 0 && (
            <EventModal
              title={testResult.name}
              eventIdx={eventIdx}
              setEventIdx={setEventIdx}
              events={testResult.events}
              analystInputs={matchedEvents[eventIdx]}
              data={eventData}
              open={modalOpen}
              setOpen={setModalOpen}
            />
          )}
        </div>
      )}
    </div>
  );
}

interface MatchedCardProps {
  testResult: ERTestResult;
  analystInput?: AnalystInput[];
  type: 'lft' | 'job';
  prevented: boolean;
  detected: boolean;
}
