import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Tokens from './Tokens';
import Endpoints from './Endpoints';
import Libraries from './Libraries';
import Settings from './Settings';

const useStyles = makeStyles({
  tokens: {
    marginTop: 20,
  },
  endpoints: {
    marginTop: 30,
  },
  libraries: {
    marginTop: 30,
  },
  settings: {
    marginTop: 30,
  }
});

export default function ExploitTesting() {
  const classes = useStyles();

  return (
    <div>
      <Typography variant="h3">Atomic Red Team</Typography>
      <Tokens className={classes.tokens} />
      <Endpoints className={classes.endpoints} />
      <Libraries className={classes.libraries} />
      <Settings className={classes.settings} />
    </div>
  );
}
