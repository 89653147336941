import { AnalystInput, JobStage, LiveFireTraining, TestResult } from '../../../services/interfaces';

export type LearningAnalystInput = Pick<AnalystInput, 'id' | 'event' | 'description' | 'createdAt'>

export type LearningTestResult = Pick<TestResult, 'status'>

export type LearningStage = Pick<JobStage, 'id'> & {
  testResults: LearningTestResult[];
}

export type LearningJob = {
  name: string;
  createdAt: Date;
  stages: LearningStage[];
}

export type LearningTraining =
  Pick<LiveFireTraining, 'id' | 'analyst' | 'source' | 'status' | 'timePassed' | 'createdAt' | 'isExam' | 'score'> & {
    analystInput: LearningAnalystInput[];
    job: LearningJob;
  }

export enum TrainingInputSource {
  TICKET = 'ticket',
  SRP = 'srp',
}
