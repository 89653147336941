import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Introduction({ onClick }: { onClick: () => unknown }) {
  return (
    <>
      <Typography variant="h2">Assessment Scoring</Typography>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        minHeight="100%"
        marginTop='-35px'
      >
        <Grid container direction='column' gap='35px' justifyContent="center" alignItems="center" width="450px">
          <Grid item>
            <FontAwesomeIcon icon={['fal', 'signal-alt']} size='3x' color='#f67339' />          
          </Grid>
          <Grid item>
            <Typography align="center" variant="h1">Assessment Scoring</Typography>    
          </Grid>
          <Grid item>
            <Typography align="center" variant="subtitle1">
            Empower your security strategy, leveraging all frameworks
            provided by SightGain for comprehensive testing excellence
            </Typography>
          </Grid>
          <Grid item>
            <Typography align="center" variant="subtitle2" color="grey">
            Manage existing frameworks using the <br />
              <Button component={Link} to={'/risk/management'} color='primary'>Framework Management</Button>
             &nbsp; tool for enhanced cybersecurity resillience.
            </Typography>    
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
              onClick={onClick}
            >
            Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
