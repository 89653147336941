import { endOfDay, startOfDay, subDays } from 'date-fns';
import { ChangeEvent, useContext, useState } from 'react';
import { Divider, FormControl, MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { divideSafely, ratio } from '@sightgain/core/calculations';
import { addCommas } from '@sightgain/core/strings';
import { useAbort } from '../../../../../effects';
import { LargeNumericMetric } from '../../../../reusables/metrics';
import LargeNumericWithLine from '../../../../reusables/metrics/LargeNumericWithLine';
import { DailyExerciseMetric, ResponseResult } from '../../../../services/interfaces';
import socService from '../../../../services/SocService';
import { SocCacheItem } from '../interfaces';
import SocContext from '../SocContext';
import ResponseResults from './ResponseResults';

const useStyles = makeStyles(() => ({
  responseActions: {
    padding: 25,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
    borderRadius: 6,
  },
  headingRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: 0,
  },
  selector: {
    width: 169,
    marginLeft: 14,
  },
  selectors: {
    display: 'flex',
  },
  subTitle: {
    fontWeight: 'bold',
    letterSpacing: 0.6,
    textTransform: 'uppercase',
  },
  actions: {
    display: 'flex',
    padding: 20,
    margin: '15px 0 26px',
    borderRadius: 6,
    justifyContent: 'space-evenly',
    alignItems: 'center',
    border: 'solid 1px rgba(255, 255, 255, 0.26)',
    gap: 10,
  },
  ratio: {
    flex: '0 1 244px',
  },
}));

const calculate = async (dailyExerciseMetrics: DailyExerciseMetric[]) =>
  dailyExerciseMetrics.reduce(
    (acc, day) => {
      const { automatedResponseCount, manualResponseCount, noResponseCount, manualResponseTimeTotal, count } = day;

      acc.automatedResponseCount += automatedResponseCount;
      acc.manualResponseCount += manualResponseCount;
      acc.noResponseCount += noResponseCount;
      acc.manualResponseTimeTotal += manualResponseTimeTotal;
      acc.count += count;
      acc.trends.response.push(Math.round(divideSafely(automatedResponseCount + manualResponseCount, count) * 100));
      acc.trends.missed.push(Math.round(divideSafely(noResponseCount, count) * 100));

      return acc;
    },
    {
      automatedResponseCount: 0,
      manualResponseCount: 0,
      noResponseCount: 0,
      manualResponseTimeTotal: 0,
      count: 0,
      trends: {
        response: [] as number[],
        missed: [] as number[],
      },
    },
  );

export default function ResponseActions() {
  const classes = useStyles();
  const { loadDataForTimeframe, timeSpan, setTimeSpan } = useContext(SocContext);
  const [testFilter, setTestFilter] = useState('all');
  const [data, setData] = useState({
    automatedResponseCount: 0,
    manualResponseCount: 0,
    noResponseCount: 0,
    manualResponseTimeTotal: 0,
    count: 0,
    trends: {
      response: [] as number[],
      missed: [] as number[],
    },
  });
  const [responseResults, setResponseResults] = useState<ResponseResult[]>([]);

  useAbort(
    () => {
      const today = endOfDay(new Date());
      const from = startOfDay(subDays(today, timeSpan));
      return Promise.all([loadDataForTimeframe(from, today), socService.responseResults(from, today)]);
    },
    async ([{ dailyExerciseMetrics }, responses]: [SocCacheItem, ResponseResult[]]) => {
      const total = await calculate(dailyExerciseMetrics);
      setData(total);
      setResponseResults(responses);
    },
    [timeSpan],
  );

  return (
    <div className={classes.responseActions}>
      <div className={classes.headingRow}>
        <Typography variant="h3">Response Actions</Typography>
        <div className={classes.selectors}>
          <FormControl className={classes.selector}>
            <Select value={testFilter} onChange={e => setTestFilter((e as ChangeEvent<HTMLInputElement>).target.value)}>
              <MenuItem value="all">All Tests</MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.selector}>
            <Select value={timeSpan} onChange={e => setTimeSpan(+(e as ChangeEvent<HTMLInputElement>).target.value)}>
              <MenuItem value={30}>Last 30 Days</MenuItem>
              <MenuItem value={60}>Last 60 Days</MenuItem>
              <MenuItem value={90}>Last 90 Days</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <h3 className={classes.subTitle}>Response Metrics</h3>
      <div className={classes.actions}>
        <LargeNumericWithLine
          label="Response Rate"
          trend={data.trends.response}
          subText={addCommas(data.count)}
          metricValue={Math.round(
            divideSafely(data.automatedResponseCount + data.manualResponseCount, data.count) * 100,
          )}
        />
        <Divider orientation="vertical" flexItem />
        <div className={classes.ratio}>
          <LargeNumericMetric
            value={ratio(data.automatedResponseCount, data.manualResponseCount)}
            label="Automated to Manual Response Ratio"
            animate={false}
          />
        </div>
        <Divider orientation="vertical" flexItem />
        <LargeNumericWithLine
          label="Missed"
          trend={data.trends.missed}
          subText={addCommas(data.noResponseCount)}
          metricValue={Math.round(divideSafely(data.noResponseCount, data.count) * 100)}
        />
      </div>
      <h3 className={classes.subTitle}>Results</h3>
      <ResponseResults data={responseResults} />
    </div>
  );
}
