import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import { ChipPropsColorOverrides } from '@mui/material/Chip/Chip';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { formatDateISO } from '@sightgain/core/dates';
import useInput from '../../../../reusables/UseInput';
import assessmentsService from '../../../../services/AssessmentsService';
import { useAssessmentContext } from '../AssessmentContext';
import { AssessmentView, AssessmentViewProps } from './interfaces';

const useStyles = makeStyles(theme => ({
  input: {
    borderRadius: '3px',
    border: 'solid 1px #61788e',
    '& > label': {
      padding: '0px 20px',
    },
  },
  label: {
    paddingBottom: '10px',
  },
  row: {
    padding: '10px',
    position: 'relative',
    background: theme.palette.darkBlue1.main,
    borderRadius: '10px',
  },
  frameworkName: {
    fontWeight: 'bold',
    marginTop: '25px',
  },
  footer: {
    borderTop: `1px solid ${theme.palette.grey1.main}`,
  },
  renewBtn: {
    fontWeight: 'bold',
    textDecoration: 'none',
  },
}));

export default function Details({ onChangeView }: AssessmentViewProps) {
  const classes = useStyles();
  const { editAssessment, isReadonly } = useAssessmentContext();
  const { bind: bindAssessmentName, setValue } = useInput('');

  const licenseStatusColors: Record<string, keyof ChipPropsColorOverrides> = {
    Expired: 'orange1',
    Active: 'green2',
  };

  const statusColors: Record<string, keyof ChipPropsColorOverrides> = {
    renew: 'green1',
    extend: 'blue1',
  };

  useEffect(() => {
    setValue(editAssessment.name || '');
  }, [editAssessment, setValue]);

  const saveAndExit = async () => {
    const name = bindAssessmentName.value;
    await assessmentsService.update({ id: editAssessment.id, name });
    onChangeView(AssessmentView.LIST);
  };

  if (!editAssessment.id) {
    return <></>;
  }

  const framework = {
    name: editAssessment.framework!.title,
    created: editAssessment.framework!.createdAt,
    modified: editAssessment.framework!.updatedAt,
    type: editAssessment.framework!.isSystem ? 'System Provided' : 'Cloned',
    score: editAssessment.frameworks![0].scores['defaultMaturityScore'] || 0,
  };

  const license = {
    type: editAssessment.license!.type,
    status: editAssessment.license!.expires < new Date() ? 'Expired' : 'Active',
    validDate: editAssessment.license!.issued,
    expires: editAssessment.license!.expires,
  };

  return (
    <Grid container flexDirection="column" gap={4}>
      <Grid item>
        <Grid container justifyContent="space-between">
          <Grid item>
            <Typography variant="textStyle9">Assessment Details</Typography>
          </Grid>
          <Grid item>
            <Box bgcolor="darkBlue3.main" border="1px solid #161926" borderRadius={2} padding={1}>
              <Typography color="grey2.main" variant="textStyle2">
                Assessment Score:{' '}
                <Typography variant="textStyle4" component="span">
                  {framework.score}
                </Typography>
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Typography color="grey2.main">Name</Typography>
        <TextField
          autoFocus
          disabled={isReadonly}
          className={classes.input}
          required
          fullWidth
          variant="standard"
          margin="normal"
          type="text"
          placeholder="Assessment Name"
          {...bindAssessmentName}
        />
      </Grid>
      <Grid item>
        <Grid container flexDirection="column" gap={4}>
          <Grid item>
            <Typography variant="textStyle4">License</Typography>
          </Grid>
          <Grid item className={classes.row}>
            <Grid container spacing={5} justifyContent="space-between" alignItems="center" padding={1}>
              <Grid item flex={2}>
                <Typography color="grey2.main" variant="body1" className={classes.label}>
                  Type
                </Typography>
                <Typography fontWeight="bold">{license.type}</Typography>
              </Grid>
              <Grid item flex={1}>
                <Typography color="grey2.main" className={classes.label}>
                  Status
                </Typography>
                <Chip label={license.status} color={licenseStatusColors[license.status]} />
              </Grid>
              <Grid item flex={1}>
                <Typography color="grey2.main" className={classes.label}>
                  Valid from
                </Typography>
                <Typography>{formatDateISO(license.validDate)}</Typography>
              </Grid>
              <Grid item flex={1}>
                <Typography color="grey2.main" className={classes.label}>
                  Valid to
                </Typography>
                <Typography>{formatDateISO(license.expires)}</Typography>
              </Grid>
              <Grid item flex={1}>
                <Typography
                  to="/settings/licensing"
                  component={Link}
                  color={`${statusColors['renew']}.main`}
                  className={classes.renewBtn}
                >
                  Renew
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container flexDirection="column" gap={4}>
          <Grid item>
            <Typography variant="textStyle4">Framework</Typography>
          </Grid>
          <Grid item className={classes.row}>
            <Box
              bgcolor="blue2.main"
              sx={{ position: 'absolute', top: 0, left: 0, padding: '2px 20px', borderRadius: '10px 20px 20px 0px' }}
            >
              <Typography variant="textStyle1">{framework.type}</Typography>
            </Box>
            <Grid container spacing={5} justifyContent="space-between" alignItems="center" padding={1}>
              <Grid item flex={2}>
                <Typography className={classes.frameworkName}>{framework.name}</Typography>
              </Grid>
              <Grid item flex={1}>
                <Typography color="grey2.main" className={classes.label}>
                  Date Created
                </Typography>
                <Typography>{formatDateISO(framework.created)}</Typography>
              </Grid>
              <Grid item flex={1}>
                <Typography color="grey2.main" className={classes.label}>
                  Date Modified
                </Typography>
                <Typography>{formatDateISO(framework.modified)}</Typography>
              </Grid>
              <Grid item flex={1}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item display="flex" justifyContent="end" gap={4}>
        <Button variant="contained" color="primary" disabled={isReadonly} onClick={saveAndExit}>
          Done
        </Button>
        <Button
          color="orange1"
          variant="text"
          onClick={() => onChangeView(AssessmentView.LIST)}
          sx={{
            padding: '5px 15px',
          }}
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
  );
}
