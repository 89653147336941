import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Typography, RadioGroup, Radio, Button } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { RichText } from '../../../reusables/scl';

const useStyles = makeStyles(() => ({
  quiz: {
    display: 'flex',
    flexDirection: 'column',
    height: 'calc(100vh - 222px)',
    position: 'relative',
  },
  main: {
    flex: 1,
    background: 'rgba(255, 255, 255, 0.09)',
    paddingLeft: 36,
    paddingTop: 30,
    paddingRight: 30,
    paddingBottom: 30,
    overflowY: 'scroll',
  },
  number: {
    borderRadius: '50%',
    backgroundColor: '#21bee3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 36,
    height: 36,
    marginRight: 12,
    '& > *': {
      fontWeight: 'bold',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 6,
  },
  body: {
    marginLeft: 48,
  },
  options: {
    marginTop: 48,
    marginBottom: 40,
  },
  icon: {
    marginRight: 12,
    fontSize: 22,
  },
  option: {
    marginBottom: 20,
  },
  footer: {
    paddingTop: 25,
    paddingBottom: 25,
    paddingLeft: 40,
    paddingRight: 40,
    minHeight: 83.33,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    background: 'rgba(255, 255, 255, 0.09)',
    boxShadow: '20px 20px 160px 0 rgba(0, 0, 0, 0.51)',
  },
}));

interface QuizItem {
  title?: string;
  description?: string;
  options?: string[];
}

interface QuizProps {
  quiz: QuizItem[];
  onSubmit: (answers: object | string) => Promise<void>;
}

export default function Quiz({ quiz, onSubmit }: QuizProps) {
  const classes = useStyles();
  const [quizSelections, setQuizSelections] = useState({});

  const onSelect = (title: string, option: string) => {
    setQuizSelections({
      ...quizSelections,
      [title]: option,
    });
  };

  const allQuestionsAnswered = Object.keys(quizSelections).length === quiz.length;

  const onSubmitClick = () => {
    setQuizSelections({});
    onSubmit(quizSelections);
  };

  return (
    <div className={classes.quiz}>
      <div className={classes.main}>
        {quiz.map((question, id) => (
          <div key={question.title}>
            <div className={classes.title}>
              <div className={classes.number}>
                <Typography variant="body1">{id + 1}</Typography>
              </div>
              <Typography variant="h3">{question.title}</Typography>
            </div>
            <div className={classes.body}>
              <Typography variant="body1">{question.description}</Typography>
              <RadioGroup
                className={classes.options}
                value={quizSelections[question.title as keyof typeof quizSelections] || null}
                onChange={(_, v) => onSelect(question.title as string, v)}
              >
                {question?.options?.map(option => (
                  <Typography className={classes.option} component="span" key={option}>
                    <Radio value={option} />
                    <RichText text={option} />
                  </Typography>
                ))}
              </RadioGroup>
            </div>
          </div>
        ))}
      </div>
      <div className={classes.footer}>
        <Button variant="contained" color="primary" disabled={!allQuestionsAnswered} onClick={onSubmitClick}>
          Submit&nbsp; &nbsp; &nbsp;
          <FontAwesomeIcon icon={['fal', 'long-arrow-right']} />
        </Button>
      </div>
    </div>
  );
}
