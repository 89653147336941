import {
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { JobActor, JobActors, RunOption, RunOptionInput } from '../../../../../../../services/interfaces';
import AttackerSelect from './AttackerSelect';
import { EvaluationNodeConfig, EvaluationNodeUpdate, TestOptionUpdate } from '../../../interfaces';
import TargetSelect from './TargetSelect';
import DynamicInput from '../../../../../../../reusables/DynamicInput';
import StepWrapper from '../StepWrapper';

const useStyles = makeStyles(theme => ({
  description: {
    paddingBottom: 20,
  },
}));

export default function Node({
  description, nodeConfig, actorOptions, testOptions, testInputs, updateActor, updateOptions, 
}: EvaluationNodeProps) {
  const classes = useStyles();
  const actors = actorOptions.actors;

  const nodeChange = ({ name, value }: { name: string, value: string }) => {
    if (nodeConfig.type === 'host_cli' && name === 'attacker') {
      return updateActor({ ...nodeConfig, attacker: value, target: value });
    }
    
    updateActor({ ...nodeConfig, [name]: value });
  };

  const getActors = (actionType: string, nodeType: string) => {
    if (actionType === 'host_cli') {
      return actors.filter(actor => actorOptions.sourceActorIds.includes(actor.id));
    }

    if (nodeType === 'attacker') {
      return actors.filter(actor => nodeConfig.target !== actor.id
        && actorOptions.sourceActorIds.includes(actor.id),
      );
    }

    if (nodeType === 'target') {
      return actors.filter(
        actor => nodeConfig.attacker !== actor.id
        && actorOptions.destinationActorIds.includes(actor.id),
      );
    }

    return [] as JobActor[];
  };

  return (
    <StepWrapper>
      <Typography className={classes.description}>{description}</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <AttackerSelect
            actorSetting={nodeConfig.attacker}
            nodes={getActors(nodeConfig.type, 'attacker')}
            selectChange={nodeChange}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          {nodeConfig.type !== 'host_cli' && (
            <TargetSelect
              actorSetting={nodeConfig.target}
              nodes={getActors(nodeConfig.type, 'target')}
              selectChange={nodeChange}
            />
          )}
        </Grid>
        {testOptions.map(option => (
          <Grid item key={option.key} xs={12} sm={6}>
            <DynamicInput
              option={option}
              onUpdate={updateOptions}
              value={testInputs.find(i => i.key === option.key)?.value}
            />
          </Grid>
        ))}
      </Grid>
    </StepWrapper>
  );
}

interface EvaluationNodeProps {
  description: string;
  nodeConfig: EvaluationNodeConfig;
  actorOptions: JobActors;
  testOptions: RunOption[];
  testInputs: RunOptionInput[];
  updateActor: EvaluationNodeUpdate;
  updateOptions: TestOptionUpdate;
}