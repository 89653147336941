import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useHistory } from 'react-router';
import success from '../../images/success.svg';

const useStyles = makeStyles({
  successMessage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  successHeading: {
    marginTop: 26,
    marginBottom: 36,
  },
  successIcon: {
    width: 75,
  },
  successText: {
    marginTop: 19,
    fontWeight: 'bold',
    fontSize: 16,
  },
  button: {
    marginTop: 32,
    fontSize: 14,
    backgroundColor: '#21bee3',
    color: '#fff',
    borderRadius: 2,
  },
});

export default function Success() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.successMessage}>
      <img className={classes.successIcon} src={success} alt="party emoji" />
      <Typography className={classes.successHeading} variant="h1">Success!</Typography>
      <Typography variant="h3">Your account will be enabled soon.</Typography>
      <Typography
        className={classes.successText}
        variant="body1">
          Please wait until an administrator or instructor enables your account.
      </Typography>
      <Button
        className={classes.button}
        variant="contained"
        endIcon={<FontAwesomeIcon size="xs" icon={['fal', 'arrow-right']} />}
        onClick={() => history.push('/')}>
        Click here to return to the login screen.
      </Button>
    </div>
  );
}