import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Line, LineChart, ResponsiveContainer } from 'recharts';
import { LargeNumericMetric } from '.';

const useStyles = makeStyles(() => ({
  trendIndicator: {
    display: 'flex',
    flexDirection: 'column',
  },
  trendSummary: {
    color: '#f85c5c',
    margin: 3,
  },
  trendUp: {
    color: '#00ffb1',
  },
  subText: {
    fontSize: 20,
    alignSelf: 'bottom',
  },
}));

interface LargeNumericWithLineProps {
  metricValue?: string | number;
  label?: string;
  subText?: string;
  trend: number[];
}

export default function LargeNumericWithLine({
  label = '',
  subText = '',
  trend,
  metricValue = '',
}: LargeNumericWithLineProps) {
  const classes = useStyles();

  let icon;
  if (subText) {
    icon = <Typography className={classes.subText}>{`(${subText})`}</Typography>;
  }

  return (
    <div className={classes.trendIndicator}>
      <div style={{ height: 22.4, width: 54.2 }}>
        <ResponsiveContainer>
          <LineChart data={trend.map(value => ({ name: 'series-1', value }))}>
            <Line type="linear" strokeWidth={2} dataKey="value" stroke="#fff" dot={false} />
          </LineChart>
        </ResponsiveContainer>
      </div>
      <LargeNumericMetric value={metricValue} label={label} unit="%" icon={icon} />
    </div>
  );
}
