import { Dispatch, FormEvent, SetStateAction, useMemo, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SearchIcon from '@mui/icons-material/Search';
import { Box, Button, Grid, InputAdornment, MenuItem, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { partition } from '@sightgain/core';
import { useAbort } from '../../../../../../../../effects';
import Modal from '../../../../../../../reusables/Modal';
import useInput from '../../../../../../../reusables/UseInput';
import { frameworksService } from '../../../../../../../services';
import { Framework, FrameworkItem } from '../../../../../../../services/interfaces';

const useStyles = makeStyles(theme => ({
  errorWrapper: {
    marginBottom: '10px',
  },
  input: {
    margin: 0,
    width: '100%',
    borderRadius: '3px',
    border: 'solid 1px #61788e',
    '& > div': {
      '& textarea': {
        fontSize: 14,
        fontFamily: 'Lexend !important',
      },
    },
  },
  listWrapper: {
    height: '300px',
  },
  removeIcon: {
    color: theme.palette.orange1.main,
  },
  testItem: {
    padding: '10px 20px',
    '&:hover': {
      background: theme.palette.darkBlue4.main,
      cursor: 'pointer',
    },
  },
}));

const fields = `{
  _id
  id
  active
  name
  title
  lastUpdated
  createdAt
  updatedAt
  version
  isSystem
  groups {
    name
    items {
      id
      identifier
      name
      sub {
        id
        identifier
        name
      }
    }
  }
}`;

interface ControlModalProps {
  open: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  selectedControls: FrameworkItem[];
  setSelectedControls: Dispatch<SetStateAction<FrameworkItem[]>>;
}

export default function ControlModal({
  open = false,
  onClose,
  onSave,
  selectedControls,
  setSelectedControls,
}: ControlModalProps) {
  const classes = useStyles();
  const { value: filterAvailable, setValue: setFilterAvailable, bind: bindFilterAvailable } = useInput('');
  const { value: filterSelected, setValue: setFilterSelected, bind: bindFilterSelected } = useInput('');
  const [fws, setFws] = useState<Framework[]>([]);
  const [selectedFw, setSelectedFw] = useState<string | undefined>(undefined);
  const [controls, setControls] = useState<FrameworkItem[]>([]);

  // get the frameworks
  useAbort(
    () => frameworksService.list(fields),
    fws => {
      const relevantFws = fws.filter(x => ['zerotrust'].includes(x.name));
      setFws(relevantFws);
      setSelectedFw(relevantFws[0]?.id || '');
      const controls = relevantFws[0]?.groups.flatMap(x => x.items.flatMap(y => y.sub)) ?? [];
      setControls(controls);
    },
    [],
  );

  const handleChangeFw = ({ target: { value } }: { target: { value: string } }) => {
    // we are interested in specific items
    const controls = fws.find(x => x.id === value)?.groups.flatMap(y => y.items.flatMap(z => z.sub)) ?? [];
    setSelectedFw(value);
    setControls(controls);
  };

  const reset = () => {
    setFilterAvailable('');
    setFilterSelected('');
  };

  const handleSave = async (e: FormEvent) => {
    e.preventDefault();
    reset();
    await onSave();
    onClose();
  };

  const selectAll = () => {
    setSelectedControls(controls);
  };

  const deselectAll = () => {
    setSelectedControls([]);
  };

  // move an item from availiable => selected or selected => available
  const toggleItem = (id: string) => {
    setSelectedControls(prev => {
      const isSelected = prev.find(control => control.id === id);
      if (isSelected) {
        return prev.filter(control => control.id !== id);
      } else {
        const control = controls.find(control => control.id === id);
        if (control) {
          return [...prev, control];
        }
      }
      return prev;
    });
  };

  // each render, partition into two buckets (available / selected)
  const [availableControls = [], selectedControlsMemo = []] = useMemo(
    () =>
      partition(controls, control => {
        return selectedControls.find(selected => selected.id === control.id) ? 1 : 0;
      }),
    [controls, selectedControls],
  );

  const searchFilter = (filter: string) => {
    return (x: FrameworkItem) =>
      x.identifier.toLowerCase().includes(filter.toLowerCase()) || x.name.toLowerCase().includes(filter.toLowerCase());
  };

  const availableControlsList = availableControls.filter(searchFilter(filterAvailable));
  const selectedControlsList = selectedControlsMemo.filter(searchFilter(filterSelected));

  // component for each row in the available column
  const AvailableRow = ({ style, index }: { style: object; index: number }) => (
    <Box
      key={availableControlsList[index].id}
      style={style}
      className={classes.testItem}
      onClick={() => toggleItem(availableControlsList[index].id)}
    >
      <Typography>({availableControlsList[index].identifier})</Typography>
      <Typography>{availableControlsList[index].name}</Typography>
    </Box>
  );

  // component for each row in the selected column
  const SelectedRow = ({ style, index }: { style: object; index: number }) => (
    <Box
      key={selectedControlsList[index].id}
      style={style}
      className={classes.testItem}
      onClick={() => toggleItem(selectedControlsList[index].id)}
    >
      <Grid container justifyContent="space-between" gap={5} alignItems="center" height="100%">
        <Grid item flex={1}>
          <Typography>({selectedControlsList[index].identifier})</Typography>
          <Typography>{selectedControlsList[index].name}</Typography>
        </Grid>
        <Grid item>
          <FontAwesomeIcon icon={['far', 'times-circle']} size="2x" className={classes.removeIcon} />
        </Grid>
      </Grid>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={onClose}
      onSubmit={e => handleSave(e)}
      actions={[
        { text: 'Save', submit: true, color: 'primary' },
        { text: 'Cancel', color: 'secondary', variant: 'text', onClick: onClose },
      ]}
      maxWidth="lg"
      fullWidth
    >
      <Grid container spacing={2} justifyContent="space-around" flexDirection="column">
        <Grid item>
          <Typography variant="textStyle7">Select Framework Controls</Typography>
        </Grid>
        <Grid item flexDirection="column" display="flex">
          <Typography variant="textStyle1">Framework</Typography>
          <TextField variant="standard" select value={selectedFw} onChange={handleChangeFw} sx={{ width: 300 }}>
            {fws.map(fw => (
              <MenuItem key={fw.id} value={fw.id}>
                {`${fw.title.replace('_', ' ')} ${fw.version}`}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item>
          <Grid container justifyContent="space-between" gap={4}>
            <Grid item flex={1}>
              <Typography variant="textStyle1">Available Controls</Typography>
              <Box border={1} borderColor="darkBlue4.main">
                <TextField
                  fullWidth
                  margin="normal"
                  variant="standard"
                  id="filter_available"
                  inputProps={{ 'aria-label': 'Find' }}
                  type="text"
                  className={classes.input}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon fontSize="small" />
                      </InputAdornment>
                    ),
                  }}
                  placeholder="Find"
                  {...bindFilterAvailable}
                />
                <Box className={classes.listWrapper}>
                  <FixedSizeList itemCount={availableControlsList.length} itemSize={100} height={300} width="100%">
                    {AvailableRow}
                  </FixedSizeList>
                </Box>
                <Box
                  borderTop={1}
                  borderColor="darkBlue4.main"
                  bgcolor="darkBlue1.main"
                  display="flex"
                  justifyContent="center"
                  padding={2}
                >
                  <Button onClick={selectAll} fullWidth>
                    <Typography variant="textStyle3" color="blue3.main" justifySelf="center">
                      Select All
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item flex={1}>
              <Typography variant="textStyle1">Selected Controls</Typography>
              <TextField
                fullWidth
                margin="normal"
                variant="standard"
                id="filter_selected"
                inputProps={{ 'aria-label': 'Find' }}
                type="text"
                className={classes.input}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon fontSize="small" />
                    </InputAdornment>
                  ),
                }}
                placeholder="Find"
                {...bindFilterSelected}
              />
              <Box className={classes.listWrapper}>
                <FixedSizeList itemCount={selectedControlsList.length} itemSize={100} height={300} width="100%">
                  {SelectedRow}
                </FixedSizeList>
              </Box>
              <Box border={1} borderColor="darkBlue4.main">
                <Box
                  borderTop={1}
                  borderColor="darkBlue4.main"
                  bgcolor="darkBlue1.main"
                  display="flex"
                  justifyContent="center"
                  padding={2}
                >
                  <Button onClick={deselectAll}>
                    <Typography variant="textStyle3" color="orange1.main">
                      Clear All
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Modal>
  );
}
