import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@mui/material';
import { DropzoneArea } from 'mui-file-dropzone';
import { useState } from 'react';
import appStore from '../../../../../AppStore';
import { evaluationsService, examsService } from '../../../../services';

interface ImportDialogProps {
  open?: boolean;
  onClose: () => void;
}

enum ImportType {
  EVALUATION='evalaution',
  EXAM='exam',
}

export function uploadFn(files: File[], type: ImportType) {
  if (type === ImportType.EVALUATION) {
    return evaluationsService.upload(files, '{ id name description }');
  }

  return examsService.upload(files, '{ id name description }');
}

export default function ImportDialog({ open = false, onClose }: ImportDialogProps) {
  const [errors, setErrors] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [importType, setImportType] = useState<ImportType>(ImportType.EVALUATION);

  const handleOnClose = () => {
    setErrors([]);
    setFiles([]);
    onClose();
  };

  const handleOnUpload = async () => {
    appStore.beginLoading();
    try {
      const errors = await uploadFn(files, importType);
      setErrors(errors.map(err => err.message));

      appStore.success('upload complete');
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  };

  return (
    <Dialog open={open} onClose={handleOnClose} maxWidth="md" fullWidth>
      <DialogTitle>Import Evaluations</DialogTitle>
      <DialogContent>
        {!errors.length && (
          <>
            <FormControl>
              <RadioGroup
                name="evaluation-import-type"
                row
                value={importType}
                onChange={e => setImportType(e.target.value as ImportType)}
              >
                <FormControlLabel value={ImportType.EVALUATION} control={<Radio />} label="Evaluations" />
                <FormControlLabel value={ImportType.EXAM} control={<Radio />} label="Exams" />
              </RadioGroup>
            </FormControl>
            <DropzoneArea
              fileObjects={files} 
              onChange={f => setFiles(f)} 
              acceptedFiles={[
                'application/json',
                'application/x-zip',
                'application/x-zip-compressed',
                'application/zip',
              ]} 
            />
          </>
        )}
        {!!errors.length && errors.map((x, idx) => <p key={idx}>{x}</p>)}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleOnClose} color="primary">
          {errors.length ? 'OK' : 'Cancel'}
        </Button>
        <Button onClick={handleOnUpload} disabled={!!errors.length}>
          Import
        </Button>
      </DialogActions>
    </Dialog>
  );
}

