import { useContext, useEffect } from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { EvaluationStepProps } from '../../../interfaces';
import EvaluationWindowContext from '../../EvaluationWindowContext';
import StepTitle from '../StepTitle';
import StepWrapper from '../StepWrapper';
import { AGENT_KEY, getRunOption } from './utilities';

export default function AgentSelector({ runner }: EvaluationStepProps) {
  const { testOptions, testOptionInputs, handleUpdateTestOption, handleDisableNextBtn } =
    useContext(EvaluationWindowContext);

  useEffect(() => {
    handleDisableNextBtn(!testOptionInputs[0] || !testOptionInputs[0].some(opt => opt.key === AGENT_KEY));
  }, [testOptionInputs, handleDisableNextBtn]);

  const agents = getRunOption(testOptions, AGENT_KEY)?.options ?? [];
  const value = getRunOption(testOptionInputs, AGENT_KEY)?.value ?? '';

  return (
    <StepWrapper>
      <StepTitle>Select an agent to run the evaluation</StepTitle>
      <FormControl>
        <InputLabel htmlFor="picus-agent-select">Agent</InputLabel>
        <Select
          id="picus-agent-select"
          value={value}
          onChange={e =>
            handleUpdateTestOption(0, {
              key: AGENT_KEY,
              value: e.target.value as string,
            })
          }
        >
          {agents.map(agent => (
            <MenuItem key={`picus-agent-item-${agent.value}`} value={`${agent.value}`}>
              {agent.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </StepWrapper>
  );
}

AgentSelector.label = 'Select an agent';
