import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { pick } from '@sightgain/core';
import { useAbort } from '../../../../effects';
import Modal from '../../../reusables/Modal';
import TextField from '../../../reusables/TextField';
import { UserGroupInput } from '../../../services/interfaces';
import { EditableGroup } from './interfaces';

const useStyles = makeStyles(() => ({
  btn: {
    display: 'flex',
    width: 115,
    justifyContent: 'space-between',
    margin: 15,
  },
}));

export default function EditGroupModal({ selectedGroup, onSave, onClose, verifyUniqueGroup }: EditGroupModalProps) {
  const [editing, setEditing] = useState<EditableGroup>({ name: '', description: '' });
  const [err, setErr] = useState('');

  const classes = useStyles();

  useAbort(
    () => selectedGroup,
    grp =>
      setEditing({
        ...editing,
        ...(grp && pick(grp, ['id', 'name', 'description'])),
      }),
    [selectedGroup],
  );

  const handleSubmit = () => {
    if (!editing.name) {
      setErr('group name is required');
      return;
    }

    if (!verifyUniqueGroup(editing.id, editing.name)) {
      setErr('group already exists');
      return;
    }

    setErr('');
    onSave(editing);
  };

  return (
    <Modal open={!!selectedGroup} onClose={onClose} onSubmit={handleSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography component="h2">{editing.id ? editing.name : 'New Group'}</Typography>
        </Grid>

        <Grid item xs={6}>
          <TextField
            onChange={e => setEditing({ ...editing, name: e.target.value })}
            value={editing.name}
            error={err}
            label="Name"
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            onChange={e => setEditing({ ...editing, description: e.target.value })}
            value={editing.description}
            error={err}
            label="Description"
          />
        </Grid>

        <Grid container item justifyContent="flex-end" xs={12}>
          <Button
            className={classes.btn}
            variant="contained"
            color="primary"
            disabled={!(!err && editing.name)}
            onClick={handleSubmit}
            data-testid="cancel-save-group"
          >
            Save
            <FontAwesomeIcon icon={['fal', 'arrow-right']} />
          </Button>
          <Button className={classes.btn} variant="outlined" onClick={onClose} data-testid="cancel-save-group">
            Cancel
            <FontAwesomeIcon icon={['fal', 'arrow-right']} />
          </Button>
        </Grid>
      </Grid>
    </Modal>
  );
}

type EditGroupModalProps = {
  selectedGroup?: EditableGroup;
  onSave: (input: UserGroupInput) => Promise<void>;
  onClose: () => void;
  verifyUniqueGroup: (name?: string, gid?: string) => boolean;
};
