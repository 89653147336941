import { useMemo, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
} from '@mui/material';
import { useAbort } from '../../../../../effects';
import { SearchBar } from '../../../../reusables/scl';
import { jobsService } from '../../../../services';
import { JobSummary } from '../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    padding: 25,
  },
  list: {
    maxHeight: 'calc(100% - 65px)',
    display: 'block',
    marginTop: 26,
    overflow: 'auto',
  },
  listItem: {
    borderTop: 'solid 1px #2c324b',
  },
}));

export default function SelectEvaluation({ onSelect }: SelectEvaluationProps) {
  const classes = useStyles();
  const [filter, setFilter] = useState('');
  const [summaries, setSummaries] = useState<JobSummary[]>([]);
  const startDate = new Date(0);
  const endDate = new Date();

  useAbort(
    () => jobsService.summaries(startDate, endDate),
    result => {
      setSummaries(result);
    },
    [],
  );

  const filteredData = useMemo(() => {
    if (!filter) {
      return summaries;
    }

    const lowered = filter.toLowerCase();
    return summaries.filter(e => `${e.name},${e.evaluationId}`.toLocaleLowerCase().includes(lowered));
  }, [filter, summaries]);

  return (
    <div className={classes.wrapper}>
      {summaries.length && (
        <>
          <SearchBar
            placeholder={`Filter Evaluations (${summaries && summaries.length})`}
            onChange={value => setFilter(value ?? '')}
          />
          <List className={classes.list}>
            {filteredData.map(summary => (
              <ListItem
                className={classes.listItem}
                button
                key={summary.evaluationId}
                onClick={() => onSelect(summary)}
              >
                <ListItemText>{summary.name}</ListItemText>
              </ListItem>
            ))}
          </List>
        </>
      )}
      {!summaries.length && <Typography>No evaluations found</Typography>}
    </div>
  );
}

type SelectEvaluationProps = {
  onSelect: (e: JobSummary) => unknown;
};
