import { useContext, useEffect, useMemo } from 'react';
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material';
import EvaluationWindowContext from '../../EvaluationWindowContext';
import StepTitle from '../StepTitle';
import StepWrapper from '../StepWrapper';
import { getAgentProtocols, getSelectedProtocols, PROTOCOLS_KEY } from './utilities';

export default function ProtocolsSelector() {
  const { testOptions, testOptionInputs, handleUpdateTestOption, handleDisableNextBtn } =
    useContext(EvaluationWindowContext);

  const protocols = useMemo(() => getAgentProtocols(testOptions, testOptionInputs), [testOptions, testOptionInputs]);

  const modules = useMemo(
    () => testOptions.flat().filter(opt => protocols.includes(opt.key)),
    [protocols, testOptions],
  );

  // disable the next button when protocols for each module have not been selected
  useEffect(() => {
    const inputs = testOptionInputs.flat().filter(opt => opt.key.includes(PROTOCOLS_KEY)).length;
    handleDisableNextBtn(inputs !== modules.length);
  }, [testOptionInputs, modules, handleDisableNextBtn]);

  const handleOnChange = (key: string, protocol: string, add: boolean) => {
    const previous = getSelectedProtocols(testOptionInputs, key);
    const updated = add ? [...previous, protocol] : previous.filter(p => p !== protocol);
    handleUpdateTestOption(0, {
      key,
      value: JSON.stringify(updated),
    });
  };

  return (
    <StepWrapper>
      <StepTitle>Select protocols for attack modules</StepTitle>
      {modules.map(module => (
        <FormControl key={module.key}>
          <FormLabel>{module.label}</FormLabel>
          <FormGroup row>
            {module.options?.map(opt => (
              <FormControlLabel
                key={`picus-protocol-${module.key}-${opt.value}`}
                label={opt.value}
                control={
                  <Checkbox
                    checked={getSelectedProtocols(testOptionInputs, module.key).includes(opt.value)}
                    onChange={e => handleOnChange(module.key, opt.value, e.target.checked)}
                  />
                }
              />
            ))}
          </FormGroup>
        </FormControl>
      ))}
    </StepWrapper>
  );
}

ProtocolsSelector.label = 'Select attack module protocols';
