import { createRef, ReactNode, useEffect } from 'react';
import { NumberBadge } from './Badges';

function padNumber(val: number) {
  return (val > 9 ? `${val}` : `0${val}`);
}

function timeSpan(passed: number) {

  const hours = padNumber(Math.floor(passed / 3600));
  const minutes = padNumber(Math.floor((passed % 3600) / 60));
  const seconds = padNumber(Math.floor((passed % 3600) % 60));
  return `${hours}:${minutes}:${seconds}`;
}

function Timer({
  elapsed = 0,
  running = false,
  children = '',
}: TimerProps) {
  const tmrRef = createRef<HTMLSpanElement>();

  useEffect(() => {
    let timer: NodeJS.Timer;
    let passed = elapsed;

    if (running) {
      timer = setInterval(() => {
        if (!tmrRef.current) {
          return;
        }

        passed += 1;
        tmrRef.current.innerHTML = timeSpan(passed);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [running, elapsed, tmrRef]);

  return (
    <NumberBadge tag>
      {children}
      {' '}
      <span ref={tmrRef} style={{ fontFamily: 'monospace', fontWeight: 'bolder' }}>
        {timeSpan(elapsed)}
      </span>
    </NumberBadge>
  );
}

interface TimerProps {
  elapsed?: number,
  running?: boolean,
  children?: ReactNode,
}

export default Timer;
