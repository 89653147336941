import { useEffect, useState } from 'react';
import { useAbort } from '../../../../effects';
import { curriculumService, socket } from '../../../services';
import { Student } from '../../../services/interfaces';

const types = ['progress', 'activity', 'event'];
const action = 'update';

export default function useAnalysts() {
  const [analysts, setAnalysts] = useState<Student[]>([]);

  // make the initial call which will showing the loading indicator and from the cache
  useAbort(() => curriculumService.students(undefined, 'cache-first'), results => setAnalysts(results ?? []), []);

  useEffect(() => {
    // flag for exit
    let abort = false;

    // handle the refresh when a change is made
    const handleRefresh = async () => {
      // fetch the student info
      const results = await curriculumService.students(undefined, 'network-only');
  
      // early exit if unmounted
      if (abort) {
        return;
      }

      setAnalysts(results);
    };

    // the initial call will might pull from the cache so we need
    // to ensure we do make an initial network-only call as well
    handleRefresh();

    // subscribe to socket
    const unsubscribes = types.map(type => socket.subscribe(handleRefresh, { type, action }));

    // return clean up
    return () => {
      abort = true;
      unsubscribes.forEach(unsub => unsub());
    };
  }, []);

  return analysts;
}