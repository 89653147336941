import { Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useCountUp } from 'use-count-up';
import { allColors } from './utils';
import ChartWrapper from './ChartWrapper';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  gridItem: {
    marginBottom: 15,
    paddingLeft: 30,
    paddingRight: 30,
  },
  item: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  left: {
    display: 'flex',
    marginRight: 40,
  },
  dot: {
    width: 16,
    height: 16,
    borderRadius: 16,
    marginRight: 9,
  },
}));

const useStylesBar = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    background: 'rgb(255, 255, 255, 0.17)',
    height: 17,
    width: '45%',
  },
  bar: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
  },
}));

interface BarProps {
  color: string;
  value: number;
}

function Bar({ value, color }: BarProps) {
  const classes = useStylesBar();
  const { value: v } = useCountUp({
    isCounting: true,
    end: value,
    duration: 1,
  });

  return (
    <div className={classes.wrapper}>
      <div
        className={classes.bar}
        style={{
          background: color,
          width: `${v}%`,
        }}
      />
    </div>
  );
}

interface SequentialValuesChartMetricProps {
  title: string;
  series: {
    name: string;
    value: number;
  }[];
}

export default function SequentialValuesChartMetric({ title, series }: SequentialValuesChartMetricProps) {
  const classes = useStyles();

  return (
    <ChartWrapper title={title}>
      <div className={classes.wrapper}>
        <Grid container spacing={0}>
          {series.map((item, index) => (
            <Grid className={classes.gridItem} item sm={6}>
              <div className={classes.item}>
                <div className={classes.left}>
                  <div
                    className={classes.dot}
                    style={{
                      backgroundColor: allColors[index],
                    }}
                  />
                  <Typography variant="body2">{item.name}</Typography>
                </div>
                <Bar value={item.value} color={allColors[index]} />
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </ChartWrapper>
  );
}
