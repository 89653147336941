import { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { curriculumService } from '../../../services';
import { useAbort } from '../../../../effects';
import { Breadcrumbs, ProgressBar, VerticalAccordion } from '../../../reusables/scl';
import useActivity from '../../../reusables/useActivity';
import Quiz from './Quiz';
import Lesson from './Lesson';
import appStore from '../../../../AppStore';
import QuizResult from './QuizResult';
import QuizCompleted from './QuizCompleted';
import ReviewAnswers from './ReviewAnswers';
import {
  GuidedTrainingDetails,
  QuestionInput,
  QuizResult as TQuizeResult,
  TrainingGroupContents,
} from '../../../services/interfaces';

const useStyles = makeStyles(() => ({
  subTitle: {
    color: '#a7adb5',
    marginBottom: 6,
    textTransform: 'uppercase',
  },
  progressWrapper: {
    marginTop: 20,
    marginBottom: 28,
    display: 'flex',
    alignItems: 'center',
  },
  progressText: {
    marginRight: 25,
  },
  progressBar: {
    width: '100%',
  },
  contentWrapper: {
    display: 'flex',
  },
  contentLesson: {
    width: 'calc(55% - 300px)',
    background: '#0a0c10',
  },
  contentQuiz: {
    width: '45%',
  },
  verticalAccordion: {
    width: 62,
    height: 'calc(100vh - 222px)',
    background: '#0a0c10',
  },
  contentCompleted: {
    width: 'calc(100% - 362px)',
    height: 'calc(100vh - 222px)',
    background: '#0a0c10',
  },
  expanded: {
    width: 'calc(100% - 62px)',
  },
  contentNav: {
    width: 300,
    height: 'calc(100vh - 222px)',
    overflowY: 'scroll',
    background: '#0a0c10',
  },
  sideBox: {
    paddingTop: 30,
    paddingLeft: 30,
    paddingRight: 30,
    paddingBottom: 36,
    background: '#0a0c10',
    borderTop: 'unset',
    borderLeft: 'unset',
    borderRight: 'solid 1px rgba(94, 111, 130, 0.39)',
    borderBottom: 'solid 1px rgba(94, 111, 130, 0.39)',
    '& > h6': {
      color: '#4a5169',
      marginBottom: 16,
    },
    cursor: 'pointer',
  },
  sideBoxSelected: {
    backgroundColor: '#161926',
    '& > h6': {
      color: '#ffffff',
    },
    cursor: 'default',
  },
  sideBoxComplete: {
    color: '#00ffb1',
  },
  sideBoxIncomplete: {
    color: '#f46043',
  },
}));

const sortedGuidedTraining = (group: TrainingGroupContents) => {
  const incomplete = group.guidedTraining.filter(content => !content.completed);
  const complete = group.guidedTraining.filter(content => content.completed);
  return [...incomplete, ...complete];
};

export default function GuidedTraining() {
  const classes = useStyles();
  const { curriculumId, courseId, classId } = useParams<{
    curriculumId?: string;
    courseId?: string;
    classId?: string;
  }>();
  const [group, setGroup] = useState<TrainingGroupContents | null>(null);
  const [selected, setSelected] = useState('');
  const [displayContent, setDisplayContent] = useState<GuidedTrainingDetails | null>(null);
  const [quizResult, setQuizResult] = useState<TQuizeResult | undefined>(undefined);
  const [courseName, setCourseName] = useState('');
  const [reviewAnswers, setReviewAnswers] = useState(false);

  const guidedTraining = group?.guidedTraining.find(e => e.title === selected);
  const isGuideTraining = guidedTraining && !!Object.keys(guidedTraining).length;

  useEffect(() => {
    if (!quizResult) {
      return;
    }

    const fetchData = async () => {
      try {
        if (isGuideTraining) {
          const { quizId, lessonIds } = guidedTraining;
          const [groups, details] = await Promise.all([
            curriculumService.trainingGroupContentsGuidedTraining(appStore.userId, classId || ''),
            curriculumService.groupedContentDetails(appStore.userId, quizId, lessonIds),
          ]);
          setGroup(groups);
          setDisplayContent(details);
        }
      } catch (err) {
        appStore.error(err);
      }
    };
    fetchData();
  }, [quizResult]);

  useAbort(
    () => curriculumService.trainingGroupContentsGuidedTraining(appStore.userId, classId || ''),
    result => {
      setGroup(result);
      setSelected(sortedGuidedTraining(result)[0].title || '');
    },
  );

  useAbort(
    () => curriculumService.courseName(courseId || ''),
    result => {
      setCourseName(result.title);
    },
  );

  useAbort(
    () => {
      if (!selected) return;

      if (isGuideTraining) {
        const { quizId, lessonIds } = guidedTraining;
        return curriculumService.groupedContentDetails(appStore.userId, quizId, lessonIds);
      }
    },
    result => {
      if (selected) {
        setDisplayContent(result || null);
        setReviewAnswers(false);
      }
    },
    [selected],
  );

  useActivity(appStore.userId, guidedTraining?.quizId || '', [
    curriculumId as string,
    courseId as string,
    classId as string,
  ]);

  const onSubmit = async (answers: string | object) => {
    try {
      appStore.beginLoading();

      if (isGuideTraining) {
        const { quizId, lessonIds } = guidedTraining;
        const result = await curriculumService.submitQuiz(
          appStore.userId,
          quizId,
          lessonIds,
          answers as string,
          displayContent?.quiz.map(q => ({ title: q.title, options: q.options })) as QuestionInput[],
        );
        setQuizResult(result || null);
        setQuizResult(undefined);
      }
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
  };

  if (!group || !displayContent) {
    return <></>;
  }

  const showQuizCompleted = displayContent.completed && !reviewAnswers;
  const showQuiz = !displayContent.completed;
  const showReviewAnswers = displayContent.completed && reviewAnswers;
  const sorted = sortedGuidedTraining(group);
  return (
    <div>
      <QuizResult result={quizResult} />
      <Typography className={classes.subTitle} variant="h6">
        Reference Material
      </Typography>
      <Breadcrumbs
        values={[
          {
            label: courseName,
            url: `/curriculum/learning/${curriculumId}/course/${courseId}`,
          },
          {
            label: group.title,
          },
        ]}
      />
      <div className={classes.progressWrapper}>
        <Typography className={classes.progressText} variant="body1">
          Progress
        </Typography>
        <div className={classes.progressBar}>
          <ProgressBar rainbow size="large" value={group.progressPercent} />
        </div>
      </div>
      <div className={classes.contentWrapper}>
        <div className={classes.contentNav} style={{ visibility: sorted.length > 1 ? 'visible' : 'collapse' }}>
          {sorted.map(content => (
            <div
              key={content.title}
              className={clsx(classes.sideBox, { [classes.sideBoxSelected]: content.title === selected })}
              onClick={() => setSelected(content.title)}
              onKeyPress={() => setSelected(content.title)}
              role="button"
              tabIndex={0}
            >
              <Typography variant="h6">{content.title}</Typography>
              {content.completed && (
                <Typography className={classes.sideBoxComplete} variant="body2">
                  <FontAwesomeIcon icon={['fal', 'clipboard-check']} />
                  &nbsp; Section Complete
                </Typography>
              )}
              {!content.completed && (
                <Typography className={classes.sideBoxIncomplete} variant="body2">
                  <FontAwesomeIcon icon={['fal', 'ballot-check']} />
                  &nbsp; Incomplete
                </Typography>
              )}
            </div>
          ))}
        </div>
        {showQuizCompleted && (
          <>
            <div className={classes.verticalAccordion}>
              <VerticalAccordion title="Supplemental Information" width={500}>
                <Lesson lessons={displayContent.lessons} />
              </VerticalAccordion>
            </div>
            <div className={`${classes.contentCompleted} ${sorted.length <= 1 && classes.expanded}`}>
              <QuizCompleted onReviewAnswers={() => setReviewAnswers(true)} />
            </div>
          </>
        )}
        {showReviewAnswers && (
          <>
            <div className={classes.verticalAccordion}>
              <VerticalAccordion title="Supplemental Information" width={500}>
                <Lesson lessons={displayContent.lessons} />
              </VerticalAccordion>
            </div>
            <div className={classes.contentCompleted}>
              <ReviewAnswers answers={displayContent.answers} />
            </div>
          </>
        )}
        {showQuiz && (
          <>
            <div className={classes.contentLesson}>
              <Lesson lessons={displayContent.lessons} />
            </div>
            <div className={classes.contentQuiz}>
              <Quiz quiz={displayContent.quiz} onSubmit={onSubmit} />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
