import clsx from 'clsx';
import { CSSProperties, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { round } from '@sightgain/core/calculations';
import { contrastTextColor, hexToRGBA } from '@sightgain/core/colors';
import { truncate } from '@sightgain/core/strings';
import {
  AssessorScore,
  ScoredFramework,
  ScoredFrameworkItem,
  TacticScore,
  ThresholdColors,
} from '../../../services/interfaces';
import { ScoreType } from './interfaces';
import Progress from './Progress';

interface SelectedSubColumn {
  key: string;
  index: number;
}

const useStyles = makeStyles(theme => ({
  container: {},
  tileContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  tileText: {
    position: 'absolute',
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
  },
  tileProgress: {
    position: 'absolute',
    padding: 4,
    width: '100%',
    height: '100%',
  },
  testCount: {
    textAlign: 'right',
    fontSize: 10,
  },
  wrapper: {
    display: 'flex',
    paddingLeft: 1,
    paddingRight: 1,
    flexDirection: 'row',
    height: 'calc(100vh - 478px)',
    width: '100%',
    overflow: 'scroll',
  },
  wrapperDateHeader: {
    height: 'calc(100vh - 515px) !important',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  cell: {
    minWidth: 140,
    minHeight: 110,
    marginLeft: -1,
    marginTop: -1,
    borderRadius: 0,
    display: 'flex',
  },
  cellBackground: {
    flex: 1,
    cursor: 'pointer',
    color: 'rgba(0, 0, 0, 0.7)',
    height: '99%',
    minWidth: 119,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: 'rgb(255, 255, 255, 0.12)',
  },
  expandCell: {
    height: '100%',
    minWidth: 21,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
  },
  head: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'transparent',
    border: 'none',
    color: theme.palette.text.primary,
  },
  headerText: {
    margin: 'auto',
    width: '100%',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: '100%',
    fontWeight: 'bold',
    padding: '0px 20px 0px 0px',
  },
  text: {
    width: '100%',
    textAlign: 'center',
    textTransform: 'capitalize',
    fontSize: 10,
  },
  heading2: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: '28px',
    textTransform: 'uppercase',
    margin: '16px 0px 24px 0px',
  },
  subtactics: {
    height: 'fit-content',
    minHeight: 89,
    minWidth: 119,
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    textTransform: 'capitalize',
  },
  subtacticContent: {
    padding: 8,
    textAlign: 'center',
    '&:not(:last-child)': {
      borderBottom: '1px solid rgb(255, 255, 255, 0.12)',
    },
    cursor: 'pointer',
  },
  subtacticsBackground: {
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
    zIndex: 99,
  },
}));

// Some frameworks we are showing the item id instead of the title in the grid
const getTitle = (fw: ScoredFramework | null, item: ScoredFrameworkItem) => {
  if (item.identifier && item.name) {
    if (item.identifier === item.name) {
      return truncate(item.name, 50);
    } else {
      return (
        <>
          <div>{item.identifier}</div>
          <div>{truncate(item.name, 50)}</div>
        </>
      );
    }
  }

  return truncate(item.name, 50);
};

// TODO: Prob make this some global util func that can be used elsewhere, if one doesn't exist
// else use the one that exists instead of this one.
const createFriendly = (key: string) =>
  key
    .toLowerCase()
    .replace(/[^a-z0-9\-_]/g, '-')
    .replace(/-+/g, '-');

const manualScore = (tactic: ScoredFrameworkItem) => {
  let manual = 0;

  switch (tactic.assessorScore) {
    case AssessorScore.VALUE:
      manual = tactic.assessorValue ?? 100;
      break;
    case AssessorScore.FAIL:
      manual = 0;
      break;
    case AssessorScore.PASS:
      manual = 100;
      break;
  }
  return manual;
};

export const compoundScore = (tactic: ScoredFrameworkItem, compoundWeights: number, totalTests: number) => {
  // check for a usable score
  const hasAssessorScore = tactic.assessorScore && tactic.assessorScore !== AssessorScore.NA;

  // Only show a score if at least one type of score exists
  if (!hasAssessorScore && !tactic.testScore?.score) {
    return null;
  }

  // return only automated score if no assessor score
  if (!hasAssessorScore) {
    return round(tactic.testScore.score ?? 0, 0);
  }

  const manual = manualScore(tactic);

  // return only manual score if no test score
  if (totalTests === 0) {
    return round(manual, 0);
  }

  // There is only one weight in compoundWeights at the moment
  const assessor = manual * (compoundWeights / 100);
  const automated = (tactic.testScore?.score ?? 0) * ((100 - compoundWeights) / 100);
  return round(assessor + automated, 0);
};

const calculateScore = (
  showScore: ScoreType,
  tactic: ScoredFrameworkItem,
  compoundWeights: number,
  totalTests: number,
) => {
  switch (showScore) {
    case ScoreType.CAPMIT: // Technology Efficacy
      return tactic.testScore && tactic.testScore.score !== null ? round(tactic.testScore.score, 0) : null;

    case ScoreType.SECMIT: // Risk Posture
      return tactic.postureScore && tactic.postureScore.score !== null ? round(tactic.postureScore.score, 0) : null;

    case ScoreType.HEAT:
      return tactic.threatScore && tactic.threatScore.score !== null ? round(tactic.threatScore.score, 0) : null;

    case ScoreType.ASSESSOR:
      return tactic.assessorScore === AssessorScore.VALUE ? tactic.assessorValue : tactic.assessorScore?.toUpperCase();

    case ScoreType.COMPOUND: {
      return compoundScore(tactic, compoundWeights, totalTests);
    }

    default:
      return null;
  }
};

interface FrameworkProps {
  hideTitle: boolean;
  scoreType: ScoreType;
  onClick: (tactic: ScoredFrameworkItem, columnName?: string, refresh?: boolean) => void;
  scoredFramework: ScoredFramework | null;
  onScroll?: (e: any) => void;
  activeTab: string;
  divRef?: any;
  dateHeader?: boolean;
  backgroundColor: (s?: TacticScore) => string;
  thresholdColors: ThresholdColors;
  assessorThreshold: number;
  compoundWeights: number;
}

export default function Framework({
  hideTitle = false,
  scoreType = ScoreType.NONE,
  onClick = () => {},
  scoredFramework,
  onScroll = () => {},
  divRef = null,
  dateHeader = false,
  backgroundColor,
  thresholdColors,
  assessorThreshold,
  compoundWeights,
}: FrameworkProps) {
  const classes = useStyles();
  const [subtacticsColumn, setSubtacticsColumn] = useState({} as SelectedSubColumn);

  /**
   * Returns a call based on a tactic
   * @param columnName
   * @param columnKey Unique string to use as part of key in interation
   * @param tactic Framework tactic used to create cell
   * @param index
   */
  const renderTactic = (columnName: string, columnKey: string, tactic: ScoredFrameworkItem, index: number) => {
    const textStyle: CSSProperties = {};
    const backgroundStyle: CSSProperties = { backgroundColor: thresholdColors.none };
    const isSubtacticSelected = subtacticsColumn.key === columnKey && subtacticsColumn.index === index;
    const subtactics = tactic.sub || [];

    let showScore = ScoreType.NONE;

    if (tactic.assessorScore && scoreType === ScoreType.ASSESSOR) {
      showScore = ScoreType.ASSESSOR;
      switch (tactic.assessorScore) {
        case AssessorScore.PASS:
          backgroundStyle.backgroundColor = thresholdColors.green;
          break;
        case AssessorScore.FAIL:
          backgroundStyle.backgroundColor = thresholdColors.red;
          break;
        case AssessorScore.NA:
          backgroundStyle.backgroundColor = thresholdColors.disabled;
          textStyle.color = '#808080';
          break;
        case AssessorScore.VALUE:
          backgroundStyle.backgroundColor =
            (tactic.assessorValue ?? 0) > assessorThreshold ? thresholdColors.green : thresholdColors.red;
          break;
      }
    }

    const totalTests = tactic.testIds?.length ?? 0;
    const remainingTests = totalTests - tactic.testsRan;
    const completedTests = tactic.testsRan;

    if (scoreType === ScoreType.COMPOUND) {
      showScore = ScoreType.COMPOUND;

      const score = calculateScore(showScore, tactic, compoundWeights, totalTests);
      if (score || score === 0) {
        backgroundStyle.backgroundColor = backgroundColor({ score: +score, isValid: true });
      }
    }

    if (tactic.postureScore && tactic.postureScore.score !== null && scoreType === ScoreType.SECMIT) {
      showScore = ScoreType.SECMIT;
      backgroundStyle.backgroundColor = backgroundColor(tactic.postureScore);
    }

    if (tactic.testScore && tactic.testScore.score !== null && scoreType === ScoreType.CAPMIT) {
      showScore = ScoreType.CAPMIT;
      backgroundStyle.backgroundColor = backgroundColor(tactic.testScore);
    }

    // to show or not to show red feathered border
    if (
      (tactic.threatScore && tactic.threatScore.score !== null && scoreType === ScoreType.HEAT) ||
      (!(tactic.postureScore && tactic.postureScore.score !== null) && scoreType === ScoreType.SECMIT)
    ) {
      showScore = ScoreType.HEAT;

      const threatLevel = hexToRGBA('#f00', (tactic.threatScore.score ?? 0) / 100);
      if (scoreType === ScoreType.HEAT) {
        backgroundStyle.backgroundColor = threatLevel;
      } else {
        backgroundStyle.boxShadow = `${threatLevel} 2px 1px 10px 10px inset`;
      }
    }

    if (![undefined, thresholdColors.disabled, thresholdColors.none].includes(backgroundStyle.backgroundColor)) {
      textStyle.color = contrastTextColor(backgroundStyle.backgroundColor as string);
    }

    const calculatedScore = calculateScore(showScore, tactic, compoundWeights, totalTests);

    return (
      <div className={clsx(classes.cell)} key={`${columnKey}_${tactic.identifier}_${tactic.name}`}>
        <div className={classes.cellBackground} style={backgroundStyle} onClick={() => onClick(tactic, columnName)}>
          <div className={classes.tileContainer}>
            <div className={classes.tileText}>
              <Typography component="div" className={classes.text} style={textStyle}>
                {getTitle(scoredFramework, tactic)}
              </Typography>
              {showScore !== ScoreType.NONE && (showScore !== ScoreType.HEAT || tactic.threatScore.score !== null) && (
                <Typography component="div" className={classes.text} style={textStyle}>
                  {!!calculatedScore && (typeof calculatedScore === 'number' ? `${calculatedScore}%` : calculatedScore)}
                </Typography>
              )}
            </div>
            {totalTests > 0 && scoreType === ScoreType.CAPMIT && (
              <div className={classes.tileProgress}>
                <Progress
                  completed={completedTests}
                  value={remainingTests}
                  total={totalTests}
                  color={textStyle.color}
                />
                <Typography variant="body2" className={classes.testCount} style={textStyle}>
                  {totalTests} Tests
                </Typography>
              </div>
            )}
          </div>
        </div>
        {subtactics.length ? (
          <div className={classes.expandCell} />
        ) : (
          <Button
            component="div"
            className={classes.expandCell}
            onClick={() =>
              setSubtacticsColumn(isSubtacticSelected ? ({} as SelectedSubColumn) : { key: columnKey, index })
            }
          >
            <FontAwesomeIcon icon={['fal', isSubtacticSelected ? 'long-arrow-left' : 'long-arrow-right']} />
          </Button>
        )}
        {subtacticsColumn.key === columnKey && (
          <div className={clsx([classes.subtactics, { [classes.subtacticsBackground]: isSubtacticSelected }])}>
            {isSubtacticSelected &&
              subtactics.map(sub => {
                const subItem = {
                  assessorScore: null,
                  assessorValue: null,
                  identifier: sub,
                  name: sub,
                  postureScore: { score: null, isValid: null },
                  postureTrend: [],
                  sub: [],
                  testIds: tactic.testIds,
                  testScore: { score: null, isValid: null },
                  testTrend: [],
                  testsRan: 0,
                  threatScore: { score: null, isValid: null },
                  variance: 0,
                } as ScoredFrameworkItem;

                return (
                  <div className={classes.subtacticContent} key={sub} onClick={() => onClick(subItem, columnName)}>
                    {sub}
                  </div>
                );
              })}
          </div>
        )}
      </div>
    );
  };

  /**
   * Returns a framework column
   **/
  const renderColumn = ({ name, items }: FrameworkColumn) => {
    const columnKey = createFriendly(name);

    return (
      <div className={classes.column} key={columnKey}>
        <div className={clsx(classes.cell, classes.head)}>
          <Typography component="div" className={classes.headerText}>
            {truncate(name, 50)}
          </Typography>
        </div>
        {items.map((t, index) => renderTactic(name, columnKey, t, index))}
      </div>
    );
  };

  return (
    <div className={classes.container}>
      <Typography hidden={hideTitle} component="h2" className={classes.heading2}>
        {scoredFramework?.title}
      </Typography>
      <div className={`${classes.wrapper} ${dateHeader && classes.wrapperDateHeader}`} onScroll={onScroll} ref={divRef}>
        {scoredFramework?.groups.map(group => renderColumn(group))}
      </div>
    </div>
  );
}

interface FrameworkColumn {
  /** @description column name to display in the heading */
  name: string;
  /** @description tactics to be displayed in the column */
  items: ScoredFrameworkItem[];
}
