import { useState } from 'react';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useAbort } from '../../../effects';
import { jobsService, testsService } from '../../services';
import { TestAnalysisScore, TestAnalysisTest } from './interfaces';
import TestList from './TestList';
import testScores from './testScores';

const testFields = `{
  vendorId
}`;

const resultFields = `{
  id
  testId
  status
  products {
    prevented
    detected
  }
}`;

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 'calc(100% - 75px)',
  },
  title: {
    marginBottom: '20px',
  },
}));

export default function TestAnalysis() {
  const [tests, setTests] = useState<TestAnalysisScore[]>([]);

  useAbort(async () => {
    const testsData = await testsService.list(undefined, testFields);
    const testVids = testsData.map(({ vendorId }) => vendorId);
    // TODO: This currently only returns at most 10000 results. This is a generic issue
    //  that will be addressed in a uniform way.
    const calculated: TestAnalysisTest[] = await jobsService.list(testVids,  undefined, { limit: 10000 }, resultFields);
    return testScores(calculated);
  }, (scores: TestAnalysisScore[]) => setTests(scores));

  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography variant="h2" className={classes.title}>
        Test Analysis
      </Typography>
      <TestList tests={tests} />
    </div>
  );
}
