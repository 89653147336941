import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  typographyWrapper: {
    maxWidth: 485,
    marginTop: 20,
    marginBottom: 20,
  },
});

export default function PreviewTypography() {
  const classes = useStyles();

  return (
    <>
      <h2> Typography </h2>
      <Typography variant="h1">
        This is an H1 Style
      </Typography>
      <Typography variant="h2">
        This is an H2 Style
      </Typography>
      <Typography variant="h3">
        This is an H3 Style
      </Typography>
      <Typography variant="h4">
        This is an H4 Style
      </Typography>
      <Typography variant="h5">
        This is an H5 Style
      </Typography>
      <Typography variant="h6">
        This is an H6 Style
      </Typography>
      <Typography className={classes.typographyWrapper} variant="body1">
        Body 14.
        {' '}
        <b>Body 14 Bold.</b>
        {' '}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
        nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
        id est laborum.
      </Typography>
      <Typography className={classes.typographyWrapper} variant="body2">
        Body 12.
        {' '}
        <b>Body 12 Bold.</b>
        {' '}
        Lorem ipsum dolor sit amet, consectetur adipiscing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
        quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
        Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat
        nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim
        id est laborum.
      </Typography>
    </>
  );
}
