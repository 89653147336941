import { subHours } from 'date-fns';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { randomString } from '@sightgain/core/strings';
import appStore from '../../../../AppStore';
import { useAbort } from '../../../../effects';
import ButtonLink from '../../../reusables/ButtonLink';
import { settingsService } from '../../../services';
import { Setting } from '../../../services/interfaces';

const SETTING_NAME = 'registrationToken';
const SETTING_FIELDS = '{ name value updatedAt }';

const useStyles = makeStyles({
  selfSignUp: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: 15,
  },
  expire: {
    '&>*': {
      color: 'red',
    },
  },
});

export default function SelfSignupLink() {
  const classes = useStyles();
  const [registrationToken, setRegistrationToken] = useState<Setting>();
  const url = window.location.href.replace(/\/settings\/accounts/, '/signup/');

  useAbort(
    () => settingsService.find(SETTING_NAME, SETTING_FIELDS),
    token => {
      // must exist and can't be older than 72 hours
      if (!token.value || token.updatedAt > subHours(Date.now(), -72)) {
        return;
      }

      setRegistrationToken(token);
    },
    [],
  );

  const saveRegistrationToken = async (value = '') => {
    appStore.beginLoading();
    try {
      const token = await settingsService.save({ name: SETTING_NAME, value }, SETTING_FIELDS);
      setRegistrationToken(token.value ? token : undefined);
    } catch (err) {
      appStore.error(err);
    }

    appStore.endLoading();
  };

  const destination = `${url}${registrationToken?.value}`;

  return (
    <div className={classes.selfSignUp}>
      {!registrationToken?.value && (
        <ButtonLink data-testid="self-signup-create" onClick={() => saveRegistrationToken(randomString(5))}>
          Create Self-Sign-Up Link
        </ButtonLink>
      )}

      {registrationToken?.value && (
        <>
          <ButtonLink
            data-testid="self-signup-link"
            onClick={() => window.open(destination, 'signup', 'noopener,noreferrer')}
          >
            {destination}
          </ButtonLink>
          <ButtonLink
            data-testid="self-signup-expire"
            className={classes.expire}
            onClick={() => saveRegistrationToken()}
          >
            (expire now)
          </ButtonLink>
        </>
      )}
    </div>
  );
}
