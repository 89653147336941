import { useState } from 'react';
import { DropzoneArea } from 'mui-file-dropzone';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import appStore from '../../../../../../AppStore';
import assessmentsService from '../../../../../services/AssessmentsService';
import { useAssessmentContext } from '../../../components/AssessmentContext';
import { Objective } from '../interface';

const useStyles = makeStyles(({
  container: {
    borderRadius: 6,
    backgroundColor: '#0b0c12', 
    padding: '20px',
    width: '50vw',
  },
}));

export default function UploadEvidence({ onClose, objective }: { onClose?: () => void, objective: Objective }) {
  const [files, setFiles] = useState<File[]>([]);
  const classes = useStyles();
  const { assessment } = useAssessmentContext();

  async function handleAddClick() {
    if(files.length === 0) {
      return; 
    }

    appStore.beginLoading();
    try {
      await assessmentsService.addEvidenceArtifact(assessment.id, objective.id, files);
    } catch (err) {
      appStore.error(err);
    }
    appStore.endLoading();
  }

  return (
    <div className={classes.container}>
      <Grid width='md' container direction='column' gap={1}>
        <Grid item>
          <DropzoneArea fileObjects={files} onChange={f => setFiles(f)} acceptedFiles={[
            'image/png',
            'image/jpeg',
            'text/csv',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          ]} />
        </Grid>
        <Grid item>
          <Grid container gap={2} justifyContent='right'>
            <Grid item>
              <Button color='primary' onClick={handleAddClick} variant='contained'>Add</Button>
            </Grid>
            <Grid item>
              <Button color='secondary' onClick={onClose} variant='contained'>Cancel</Button>
            </Grid>  
          </Grid>  
        </Grid>
      </Grid>
    </div>
  );
}