import { Typography } from '@mui/material';
import { useScoreClass } from '../../../effects';
import { ScrollableTable } from '../../reusables/scl';
import { TestAnalysisScore } from './interfaces';

interface TestListProps {
  tests: TestAnalysisScore[];
}

export default function TestList({ tests = [] }: TestListProps) {
  const scoreClassName = useScoreClass();

  return (
    <ScrollableTable
      headers={['Test ID', 'Number Of Runs', 'Prevent/Blocked', 'Detect/Alert', 'Errored']}
      sortableHeaders={{
        'Test ID': {
          field: 'testId',
        },
        'Number Of Runs': {
          field: 'numberOfRuns',
        },
        'Prevent/Blocked': {
          field: 'prevented',
        },
        'Detect/Alert': {
          field: 'detected',
        },
        Errored: {
          field: 'errored',
        },
      }}
      data={tests}
      renderFns={{
        'Test ID': (row: TestAnalysisScore) => <Typography variant="body2">{row.testId}</Typography>,
        'Number Of Runs': (row: TestAnalysisScore) => <Typography variant="body1">{row.numberOfRuns}</Typography>,
        'Prevent/Blocked': (row: TestAnalysisScore) => (
          <Typography variant="body1" className={scoreClassName(row.prevented)}>
            {`${row.prevented}%`}
          </Typography>
        ),
        'Detect/Alert': (row: TestAnalysisScore) => (
          <Typography variant="body1" className={scoreClassName(row.detected)}>
            {`${row.detected}%`}
          </Typography>
        ),
        Errored: (row: TestAnalysisScore) => (
          <Typography variant="body1" className={scoreClassName(100 - row.status)}>
            {`${row.status}%`}
          </Typography>
        ),
      }}
    />
  );
}
