import { makeStyles } from '@mui/styles';
import { Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  socAlerts: {
    flexBasis: '50%',
    display: 'flex',
    justifyContent: 'space-evenly',
    backgroundColor: '#0b0c12',
    borderRadius: 6,
    alignItems: 'center',
    padding: 22,
    textAlign:'center',
  },
}));

function EnableSiem() {
  const classes = useStyles();
  return (
    <div className={classes.socAlerts}>
      
      <FontAwesomeIcon
        icon={['fal', 'signal-alt-slash']}
        size="2x"
      />
      <Typography variant="h5" style={{ marginLeft: 10 }}>
        Signal to Noise Ratio Unavailable
      </Typography>
      
      <Link to="/settings/siem" component={RouterLink}>
        <Typography variant="h5" color="primary">
          Click here to configure your SIEM
        </Typography>
      </Link>
    </div>
  );
}

export default EnableSiem;
