import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const useStyles = makeStyles(() => ({
  progressBadge: {
    fontSize: '12px',
    fontWeight: 'bold',
    lineHeight: 1.5,
    color: '#0b0c12',
    padding: '5px 10px 5px 5px',
    borderRadius: '13px',
    fontFamily: 'Lexend',
    '& svg': {
      color: '#ffffff',
      marginRight: 5,
      fontSize: 14,
    },
  },
  completed: {
    backgroundColor: '#21bee3',
  },
  expired: {
    backgroundColor: '#f85c5c',
  },
  passed: {
    backgroundColor: '#252b36',
    color: '#ffffff',
    '& svg': {
      color: '#feba06',
      fontSize: 13,
    },
  },
  failed: {
    backgroundColor: '#252b36',
    color: '#ffffff',
    '& svg': {
      color: 'red',
      fontSize: 13,
    },
  },
  earned: {
    textTransform: 'uppercase',
    color: '#ffffff',
    fontSize: 10,
    lineHeight: '1.8',
    letterSpacing: '0.5px',
    '& svg': {
      fontSize: 10,
      position: 'relative',
    },
  },
  pending: {
    backgroundColor: '#f48e43',
    '& svg': {
      color: '#ffffff',
    },
  },
}));

type ProgressBadgeStatus = 'completed' | 'expired' | 'passed' | 'earned' | 'pending' | 'failed' | string;

interface ProgressBadgeItemProps {
  title: string;
  status: string;
}

function ProgressBadgeItem({ title, status }: ProgressBadgeItemProps) {
  const classes = useStyles();

  const capitalizedTitle = title.charAt(0).toUpperCase() + title.slice(1);

  return (
    <span className={`${classes.progressBadge} ${status}`}>
      <FontAwesomeIcon icon={['fas', 'check-circle']} />
      {capitalizedTitle}
    </span>
  );
}

interface ProgressBadgeProps {
  progress: ProgressBadgeStatus;
}

export default function ProgressBadge({ progress }: ProgressBadgeProps) {
  const classes = useStyles();

  return (
    {
      completed: <ProgressBadgeItem status={classes.completed} title={progress} />,
      expired: <ProgressBadgeItem status={classes.expired} title={progress} />,
      passed: <ProgressBadgeItem status={classes.passed} title={progress} />,
      earned: <ProgressBadgeItem status={classes.earned} title={progress} />,
      pending: <ProgressBadgeItem status={classes.pending} title={progress} />,
      failed: <ProgressBadgeItem status={classes.failed} title={progress} />,
    }[progress] || null
  );
}
