import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import './scenario_manager.css';
import {  MgrListItem } from './interfaces';
import { Grid } from '@mui/material';
import SearchBar from '../../../../reusables/SearchBar';
import EvaluationList from './EvaluationList';
import EvaluationWindow from './EvaluationWindow';
import { useLocation } from 'react-router';

const useStyles = makeStyles(() => ({
  evaluationManager: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '0px',
    height: '100%',
    overflow: 'hidden',
    borderRadius: 6,
  },
  paper: {
    height: '100%',
    width: '100%',
    padding: 16,
  },
  search: {
    display: 'flex',
    overflow: 'hidden',
    width: '100%',
    marginBottom: '42px',
  },
  searchBar: {
    marginTop: '5px',
    width: '100%',
  },
  list: {
    display: 'flex',
    height: '89%',
    overflow: 'auto',
  },
}));

export default function EvaluationManager(
  { selected, isExams = false, onSelect, onClone }: EvaluationManagerProps) {
  const classes = useStyles();
  const location = useLocation();
  const [searchValue, setSearchValue] = useState((location?.state as { search?: string })?.search ?? '');

  return (
    <div className={classes.evaluationManager}>
      <Grid container style={{ height: '100%' }}>
        <Grid item xs={6} style={{ height: '100%', backgroundColor: '#0a0c10' }}>
          <div className={classes.paper}>
            <div className={classes.search}>
              <SearchBar
                placeholder="Search"
                onChange={setSearchValue}
                value={searchValue}
              />
            </div>
            <div className={classes.list}>
              <EvaluationList
                activeId={selected?.id}
                search={searchValue.toLowerCase()}
                onSelect={onSelect}
                onClone={onClone}
                isExams={isExams}
              />
            </div>
          </div>
        </Grid>
        <Grid item xs={6} style={{ height: '100%', backgroundColor: '#344356' }}>
          <div className={classes.paper} style={{ overflow: 'auto', backgroundColor: '#344356' }}>
            <EvaluationWindow
              activeId={isExams ? selected?.id : selected?.vendorId}
              isExam={isExams}
            />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

interface EvaluationManagerProps {
  isExams?: boolean;
  selected?: MgrListItem;
  onSelect: (item: MgrListItem) => void;
  onClone: (item: MgrListItem) => void;
}
