import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import Tooltip from '@mui/material/Tooltip';
import appStore from '../../../../../../AppStore';
import React from 'react';
import { MgrListItem } from '../interfaces';


function ExportEvaluationButton({ item, isExam = false }: ExportEvaluationButtonProps) {
  const exportEvaluation = async () => {
    const data = new Blob([JSON.stringify(item)], { type: 'application/json' });
    const url = window.URL.createObjectURL(data);
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${item.name}.json`);
    link.click();
  };

  const handleClick = async () => {
    try {
      await exportEvaluation();
    } catch (err) {
      appStore.error(`Error exporting test set: ${err}`);
    }
  };

  const title = `Export ${isExam ? 'Exam' : 'Evaluation'}`;

  return <>
    <ListItemIcon onClick={() => handleClick()}>
      <Tooltip title={title}>
        <IconButton aria-label="export" size="large">
          <SaveAltIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    </ListItemIcon>
  </>;
}

export default React.memo(ExportEvaluationButton);

interface ExportEvaluationButtonProps {
  item: MgrListItem;
  isExam?: boolean;
}
