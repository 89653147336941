import clsx from 'clsx';
import { ChangeEvent } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Slider, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { round } from '@sightgain/core/calculations';
import ButtonLink from '../../../reusables/ButtonLink';
import { CustomValue } from './interfaces';
import ValueField from './ValueField';

const useStyles = makeStyles(() => ({
  customCalc: {
    flex: '0 1 50%',
    padding: '16px 24px 17px',
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
    display: 'flex',
    flexDirection: 'column',
  },
  customCalcFullWidth: {
    flex: 1,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    gap: 20,
  },
  icon: {
    marginLeft: 11,
  },
  strong: {
    fontWeight: 'bold',
  },
  reset: {
    fontWeight: 'bold',
    fontSize: 14,
  },
}));

const labels = (name: string) =>
  ({
    technologyWeight: 'Technology',
    noResponseWeight: 'No Response',
    threatScoreWeight: 'threatScore',
    attackScoreWeight: 'Attack Score Weight',
    passFailScoreThreshold: 'Pass/Fail Score Threshold',
    ipWeight: 'IP Value (supplied via company profile)',
    piiWeight: 'Company Records (supplied via company profile)',
    severityThreshold: 'Severity Threshold',
    eventScoreWeight: 'Event Score',
    noiseScoreWeight: 'Precision Score',
    responseScoreWeight: 'Timeliness Score',
    protectWeight: 'Protect',
    detectWeight: 'Detect',
    respondWeight: 'Response',
    unknownTechniques: '% of unknown Techniques',
    preventionProbability: 'Probability of preventing unknown Techniques',
    detectionProbability: 'Probability of detecting unknown Techniques',
    alertProbability: 'Probability of alerting unknown Techniques',
    responseProbability: 'Probability of Appropriate Response',
  }[name] ?? name);

export default function CustomCalculation({
  fullWidth = false,
  title,
  values,
  infoUrl,
  error = '',
  onChange,
  onReset,
}: CustomCalculationProps) {
  const classes = useStyles();

  const handleOnChange = (name: string) => (value: number) => {
    if (values.length === 1) {
      return onChange(round(value / 100, 4));
    }

    onChange({
      ...values.reduce((a, b) => ({ ...a, [b.name]: round(b.value / 100, 4) }), {} as any),
      [name]: round(value / 100, 4),
    });
  };

  const handleSliderChange = (e: ChangeEvent<unknown>, vals: number[] | number) => {
    if (typeof vals === 'number') {
      return onChange(round(vals / 100, 4));
    }

    const update = values.reduce((a, b, i) => ({ ...a, [b.name]: round(vals[i] / 100, 4) }), {} as any);
    onChange(update);
  };

  return (
    <div className={clsx(classes.customCalc, fullWidth && classes.customCalcFullWidth)}>
      <div className={classes.row}>
        <Typography className={classes.strong} variant="body1">
          {title}
          <Link style={{ color: '#fff' }} target="_documenation" href={infoUrl}>
            <FontAwesomeIcon className={classes.icon} icon={['fal', 'info-circle']} />
          </Link>
        </Typography>
        <ButtonLink onClick={onReset} className={classes.reset}>
          Reset to Default
          <FontAwesomeIcon className={classes.icon} icon={['fal', 'undo']} />
        </ButtonLink>
      </div>

      <div className={classes.row}>
        {values.map(value => (
          <div key={value.name}>
            <ValueField
              label={labels(value.name)}
              value={value.value}
              error={error}
              onChange={handleOnChange(value.name)}
            />
          </div>
        ))}
      </div>

      <Slider value={values.map(v => v.value)} max={100} onChange={handleSliderChange as any} />
    </div>
  );
}

interface CustomCalculationProps {
  title: string;
  values: CustomValue[];
  error?: string;
  infoUrl: string;
  fullWidth?: boolean;
  onChange: (value: any) => unknown;
  onReset: () => unknown;
}
