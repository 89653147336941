import { Button, Chip, Grid, Typography } from '@mui/material';
import { License } from '../../../services/interfaces';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    borderTop: '1px solid #5E6F82',
    height: 72,
  },
}));

interface LicenseCardProps {
  license: License;
  onRenew: () => void;
  onExtend: () => void;
}
export default function LicenseCard({ license, onRenew, onExtend }: LicenseCardProps) {
  const classes = useStyles();
  const { status, name, expires, allocation } = license;

  return (
    <div className={classes.card}>
      <Grid container>
        <Grid item xs={2}>
          <Chip label={status.toUpperCase()} color={status === 'active' ? 'success' : 'error'} sx={{ width: 100 }} />
        </Grid>
        <Grid item xs>
          <Typography>License: {name}</Typography>
        </Grid>
        <Grid item xs>
          <Typography>Allocation: {allocation}</Typography>
        </Grid>
        <Grid item xs>
          <Typography>Expires: {expires.toDateString()}</Typography>
        </Grid>
        <Grid item xs={2} display="flex" justifyContent="right">
          {status !== 'expired' && <Button onClick={onExtend}>Extend</Button>}
          {status === 'expired' && (
            <Button onClick={onRenew} style={{ color: '#00FFB1' }}>
              Renew
            </Button>
          )}
        </Grid>
      </Grid>
    </div>
  );
}
