import { DashboardMixedChartMetric } from '../../../../../reusables/metrics';
import { ResultPerformance } from '../../../../../services/interfaces';
import { performanceSeries, roundArray } from '../utilites';

export default function ChartTechByAdversary({ performance }: { performance: ResultPerformance[] }) {
  const [labels, ...data] = performanceSeries(performance, 'prevented','detected');

  const series = ['% Prevented', '% Detected'].map((name, i) => ({
    name,
    type: 'column',
    data: roundArray(data[i].map(v => v * 100)),
  }));

  return (
    <DashboardMixedChartMetric
      title="Technology performance by adversary"
      series={series}
      labels={labels}
      valueAxis={{
        ticks: 4,
        series: [
          {
            seriesName: '% Prevented',
            show: true,
            min: 0,
            max: 100,
            decimalsInFloat: 0,
          },
        ],
      }}
      labelAxis={{
        formatter: v => v,
      }}
      stroke={{
        width: [0, 4, 2],
        curve: ['smooth', 'smooth', 'smooth'],
      }}
      dataLabels={{
        enabled: true,
        enabledOnSeries: [2],
      }}
    />
  );
}
