import { Checkbox, FormControl, InputLabel, MenuItem, Select, Tooltip, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import TargetAnalysts from './TargetAnalysts';
import { ScenarioTarget } from '../../../interfaces';
import { useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EvaluationWindowContext from '../../EvaluationWindowContext';
import StepWrapper from '../StepWrapper';

const useStyles = makeStyles(theme => ({
  formControl: {
    width: 300,
    marginLeft: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  selectText: {
    fontSize: '14px',
  },
  dryrun: {
    display: 'flex',
    padding: '10px',
    alignItems: 'center',
    justifyContent: 'right',
  },
  infoIcon: {
    padding: '5px',
    marginTop: '-10px',
  },
}));

export default function Target() {
  const classes = useStyles();
  const {
    label,
    analysts,
    dryRun,
    scenarioTarget,
    handleDisableNextBtn,
    handleUpdateDryRun,
    handleUpdateScenarioTarget,
  } = useContext(EvaluationWindowContext);

  useEffect(() => {
    const enabled = scenarioTarget === 'liveTest' || (scenarioTarget === 'analyst' && analysts.some(a => a.isSelected));
    handleDisableNextBtn(!enabled);
  }, [analysts, scenarioTarget, handleDisableNextBtn]);

  useEffect(() => {
    if (label === 'exam' && scenarioTarget !== 'analyst') {
      handleUpdateScenarioTarget('analyst');
    }
  }, [label, scenarioTarget]);

  return (
    <StepWrapper>
      {label === 'evaluation' && (
        <FormControl className={classes.formControl}>
          <InputLabel>Select Live Test or Assign to Analyst</InputLabel>
          <Select
            data-testid="evaluation-target-selector"
            name="EvaluationTarget"
            defaultValue=""
            value={scenarioTarget || ''}
            onChange={({ target: { value } }) => handleUpdateScenarioTarget(value as ScenarioTarget)}
          >
            <MenuItem value="liveTest">
              <Typography className={classes.selectText}>Live Test</Typography>
            </MenuItem>
            <MenuItem value="analyst">
              <Typography className={classes.selectText}>Assign to Analyst</Typography>
            </MenuItem>
          </Select>
        </FormControl>
      )}

      {scenarioTarget === 'analyst' && <TargetAnalysts />}

      {label === 'evaluation' && (
        <div className={classes.dryrun}>
          <Checkbox
            checked={dryRun}
            onChange={({ target: { checked } }) => {
              handleUpdateDryRun(checked);
            }}
            name="checkedB"
            color="primary"
          />
          <Tooltip
            title={'Exclude this training or test from all analyst and system performance scoring'}
            arrow
            placement="top"
          >
            <div style={{ display: 'flex' }}>
              <Typography>Dry Run</Typography>
              <FontAwesomeIcon icon={['fal', 'info-circle']} className={classes.infoIcon} />
            </div>
          </Tooltip>
        </div>
      )}
    </StepWrapper>
  );
}

Target.label = 'Select Evaluation Target';
