import { Typography, Button, Chip } from '@mui/material';
import CompareChip from './CompareChip';
import { useFrameworkContext } from './FrameworkContext';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { DayCompare } from './interfaces';
import CsvDownload from '../../../reusables/CsvDownload';
import { useAssessmentContext } from '../components/AssessmentContext';
import { csv } from './csv';

const compareKey = (day1: string, day2: string) => `${day1}-${day2}`;

interface CompareControlsProps {
  onCompareOpen: () => void;
  onCompare: DayCompare;
}

export default function CompareControls({ onCompareOpen, onCompare }: CompareControlsProps) {
  const {
    scoredFrameworkDays,
    setModalCompareProperties,
    setScoredFrameworkDay1,
    setScoredFrameworkDay2,
    setScoredFrameworkDays,
    scoredFramework,
    scoredFrameworkDay1,
    scoredFrameworkDay2,
  } = useFrameworkContext();

  const { assessment } = useAssessmentContext();

  const handleDelete = (day1: string, day2: string) => {
    // TODO: this an probably be moved somewhere else
    setModalCompareProperties(prevProps => ({ ...prevProps, day1: '', day2: '' }));

    // View 'today' when comparison is removed
    setScoredFrameworkDay1(null);
    setScoredFrameworkDay2(null);

    // Remove by filtering on keys
    const key = compareKey(day1, day2);
    setScoredFrameworkDays([...scoredFrameworkDays.filter(x => compareKey(x.day1, x.day2) !== key)]);
  };

  const handleToday = () => {
    // Just remove day1 & day2 to view 'today'
    setScoredFrameworkDay1(null);
    setScoredFrameworkDay2(null);
  };

  const datas = async () => {
    const data = [];

    if (scoredFrameworkDay1 && scoredFrameworkDay2) {
      data.push(...(await csv(scoredFrameworkDay1, scoredFrameworkDay1?.toDate ?? 'Day1', assessment)));
      data.push(...(await csv(scoredFrameworkDay2, scoredFrameworkDay2?.toDate ?? 'Day2', assessment)));
    } else if (scoredFramework) {
      data.push(...(await csv(scoredFramework, scoredFramework?.toDate ?? 'Today', assessment)));
    }

    return data;
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
      <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
        <Typography variant="h2">Scores</Typography>
        <CsvDownload filename="FrameworkScoring.csv" datas={datas} />
      </div>
      <div style={{ display: 'flex', gap: 12, alignItems: 'center' }}>
        <Chip label="Today" variant="outlined" onClick={handleToday} />
        {scoredFrameworkDays.map(
          (
            x: any, // TODO: add type
          ) => (
            <CompareChip
              key={compareKey(x.day1, x.day2)}
              day1={x.day1}
              day2={x.day2}
              onDelete={handleDelete}
              onClick={onCompare}
            />
          ),
        )}
        <Button color="primary" variant="text" onClick={onCompareOpen} disabled={scoredFrameworkDays.length > 2}>
          <AddCircleOutlineIcon />
          &nbsp; Compare
        </Button>
      </div>
    </div>
  );
}
