import { useEffect, useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Paper, Typography, Radio,
} from '@mui/material';
import ProductIcon from './ProductIcon';
import { BakeOffProduct } from '../interfaces';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',

    backgroundColor: '#FFF',
    padding: theme.spacing(1),
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-between',
  },
  containerMedium: {
    width: 214,
    height: 174,
  },
  containerLarge: {
    width: 250,
    maxWidth: 225,
  },
  selected: {
    border: 'solid 4px #f67339',
  },
  bordered: {
    border: '1px solid rgb(220,220,220)',
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: {
    height: 70,
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  text: {
    color: '#000000',
  },
  titleBottom: {
    marginBottom: 14,
    '& > h6': {
      marginTop: 4,
    },
  },
}));

export default function ProductItem({
  item,
  enableHover = false,
  onClick = () => undefined,
  borderless = false,
  enableSeletion = false,
  selected = false,
  heights = { default: 0, hover: 6 },
  size = 'medium',
  titlePosition = 'top',
}: ProductItemProps) {
  const classes = useStyles();
  const [elevation, setElevation] = useState(heights.default);

  // Selected items should not be hoverable
  const hoverable = () => enableHover && !selected;

  const mouseEnter = () => hoverable() && setElevation(heights.hover);
  const mouseLeave = () => hoverable() && setElevation(heights.default);
  const isMediumSize = size === 'medium';

  const containerSizeClass = isMediumSize ? classes.containerMedium : classes.containerLarge;

  // Needed to update elevation when product is diselected
  //  Otherwise the elevation remains at height.hover
  useEffect(() => {
    if (selected === false) {
      setElevation(heights.default);
    }
  }, [selected]);

  return (
    <Paper
      elevation={elevation}
      onClick={onClick}
      className={`${containerSizeClass} ${classes.container} ${
        hoverable() ? classes.clickable : ''
      } ${borderless ? '' : classes.bordered}
      ${selected ? classes.selected : ''}`}
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      {titlePosition === 'top' && (<Typography variant="body2" className={classes.text}>{item.name}</Typography>)}
      <ProductIcon productName={item.name} className={classes.icon} />
      {titlePosition === 'top' && (<Typography variant="body2" className={classes.text}>{item.type}</Typography>)}
      {titlePosition === 'bottom' && (
        <div className={classes.titleBottom}>
          <Typography variant="h4" className={classes.text}>
            {item.name}
          </Typography>
          <Typography variant="h6" className={classes.text}>
            {item.type}
          </Typography>
        </div>
      )}
      {enableSeletion && !isMediumSize && (
        <Radio
          checked={selected}
          disableRipple
          color="default"
        />
      )}
    </Paper>
  );
}

type ProductItemProps = {
  item: BakeOffProduct;
  enableHover?: boolean;
  onClick?: () => unknown;
  borderless?: boolean;
  enableSeletion?: boolean;
  selected?: boolean;
  heights?: {
    default: number;
    hover: number;
  };
  size?: 'medium' | 'large';
  titlePosition?: 'top' | 'bottom';
};
