import React, { MouseEventHandler, useMemo } from 'react';
import { Slider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useThresholdColors } from './useSetting';

const useStyles = makeStyles({
  root: {
    color: ({ color }: { color: string }) => color,
    height: 4,
  },
  thumb: {
    backgroundColor: '#fff',
    border: '2px solid #fff',
    marginTop: -3,
  },
  valueLabel: {
    left: 'calc(-50% - 8px)',
    top: 20,
    '& *': {
      background: 'transparent',
      color: '#fff',
    },
  },
  track: {
    height: 4,
  },
  rail: {
    height: 4,
    opacity: 1,
  },
});

/**
 * The temperature component is a customzied Material UI slider
 * that have different background colors up to each slider.
 * Since the slider has no option to customize rendering of the slider
 * there was a need to insert 2 new dom elements in the list that will
 */
const TemperatureComponent = React.forwardRef<HTMLDivElement, TemperatureComponentProps>(
  function TemperatureComponent({
    children,
    className,
    onMouseDown,
    colors,
  }: TemperatureComponentProps, ref) {
    const values = children[3]?.map(element => element.props.value) ?? [];

    values.forEach((value, i) => {
      const lastValue = i === 0 ? 0 : values[i - 1];

      children[3]?.unshift(
        <span style={{
          position: 'absolute',
          height: 4,
          display: 'inline-block',
          backgroundColor: colors[i],
          left: `${lastValue}%`, 
          width: `${value - lastValue}%`,
        }} />
      );
    });

    return (
      <div
        ref={ref}
        role="button"
        tabIndex={0}
        className={className}
        onMouseDown={onMouseDown}
      >
        { /* https://reactjs.org/docs/react-api.html#reactchildren */ }
        { React.Children.toArray(children) }
      </div>
    );
  });

interface TemperatureComponentProps {
  children: JSX.Element[][];
  className?: string;
  onMouseDown?: MouseEventHandler;
  colors: string[];
}

function TemperatureSlider({ value: original, reversed, defaultValue = [0,0], ...props }: any) {
  const thresholdColors = useThresholdColors();
  const value = useMemo(
    () => typeof original === 'string' ? JSON.parse(original) : original ?? defaultValue, [original]
  );

  const { colors, rootBg } = useMemo(() => {
    const colors = [
      removeTransparency(thresholdColors?.red),
      removeTransparency(thresholdColors?.orange),
      removeTransparency(thresholdColors?.yellow),
      removeTransparency(thresholdColors?.green),
    ];

    // get rid of orange
    if (value.length === 2) {
      colors.splice(1,1);
    }

    // flip the colors around
    if (reversed) {
      colors.reverse();
    }

    // take off the rail color
    const rootBg = colors.pop();



    return { colors, rootBg, value };
  }, [reversed, thresholdColors, value]);

  const classes = useStyles({ color: rootBg as string });

  return <Slider
    classes={classes}
    component={TemperatureComponent}
    {...props}
    colors={colors}
    value={value}
  />;
}

function removeTransparency(color: string) {
  if (!color.match(/^rgba/)) {
    return color;
  }
  return color.replace(/^rgba/, 'rgb').replace(/,[^,]+\)$/, ')');
}

export { TemperatureSlider, TemperatureComponent };
