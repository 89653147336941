import { format, isAfter, isBefore, parse, parseISO } from 'date-fns';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Button, FormControlLabel, MenuItem, Radio, RadioGroup, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { formatDateISO } from '@sightgain/core/dates';
import { FilterSource } from './FilterButton';

interface FilterControlsProps {
  children: ReactNode;
  onCancel: () => void;
  onApply: () => void;
}

export function FilterControls({ children, onCancel, onApply }: FilterControlsProps) {
  return (
    <div>
      {children}
      <div style={{ display: 'flex', justifyContent: 'space-between', gap: 18, marginTop: 12 }}>
        <Button variant="contained" color="primary" onClick={onApply}>
          Apply Filter
        </Button>
        <Button variant="outlined" color="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </div>
  );
}

interface FilterProps {
  onChange: (filterFunction: any, val: any, column: any, title: any) => void;
  source: FilterSource;
  menuItems?: {
    value: any;
    text: string;
  }[];
}

export function StringFilter({ onChange, source }: FilterProps) {
  const { name, title } = source;
  const [value, setValue] = useState<string>('');
  const [op, setOp] = useState<string>('like');

  const filterFunction = useCallback(
    item => {
      if (!item || item[name] === undefined) {
        return false;
      }
      if (op === 'exact') {
        return value.toLowerCase() === item[name].toLowerCase();
      } else {
        return item[name].toLowerCase().includes(value.toLowerCase());
      }
    },
    [value, op, name],
  );

  useEffect(() => {
    onChange(filterFunction, `${op} ${value}`, name, title);
  }, [filterFunction, onChange, value, op, name, title]);

  return (
    <div>
      <RadioGroup name="string-filter" value={op} onChange={v => setOp(v.target.value)}>
        <FormControlLabel value="like" control={<Radio />} label="like match" />
        <FormControlLabel value="exact" control={<Radio />} label="exact match" />
      </RadioGroup>
      <TextField
        fullWidth
        variant="standard"
        InputProps={{ style: { borderRadius: 3, border: 'solid 1px #61788e' } }}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  );
}

export function NumericFilter({ onChange, source }: FilterProps) {
  const { name, title } = source;
  const [value, setValue] = useState<string>('');
  const [op, setOp] = useState<string>('exact');

  const filterFunction = useCallback(
    item => {
      if (!item || item[name] === undefined) {
        return false;
      }
      if (op === 'equals') {
        return +value === item[name];
      } else if (op === 'less') {
        return item[name] < +value;
      } else {
        return item[name] > +value;
      }
    },
    [value, op, name],
  );

  useEffect(() => {
    onChange(filterFunction, `${op} ${value}`, name, title);
  }, [filterFunction, onChange, value, op, name, title]);

  return (
    <div>
      <RadioGroup defaultValue="equals" name="numeric-filter" value={op} onChange={v => setOp(v.target.value)}>
        <FormControlLabel value="equals" control={<Radio />} label="equals" />
        <FormControlLabel value="less" control={<Radio />} label="less than" />
        <FormControlLabel value="more" control={<Radio />} label="more than" />
      </RadioGroup>
      <TextField
        fullWidth
        variant="standard"
        InputProps={{ style: { borderRadius: 3, border: 'solid 1px #61788e' } }}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  );
}

export function DateFilter({ onChange, source }: FilterProps) {
  const { name, title } = source;
  const [op, setOp] = useState<string>('on');
  const [day, setDay] = useState(format(new Date(), 'yyyy-MM-dd'));
  const [openPicker, setOpenPicker] = useState(false);

  const filterFunction = useCallback(
    item => {
      if (!item || item[name] === undefined) {
        return false;
      }
      if (op === 'on') {
        return format(item[name] as Date, 'yyyy-MM-dd') === day;
      } else if (op === 'before') {
        return isBefore(item[name] as Date, parse(day, 'yyyy-MM-dd', new Date()));
      } else {
        return isAfter(item[name] as Date, parse(day, 'yyyy-MM-dd', new Date()));
      }
    },
    [op, day, name],
  );

  useEffect(() => {
    onChange(filterFunction, `${op} ${day}`, name, title);
  }, [filterFunction, onChange, name, day, op, title]);

  return (
    <div>
      <RadioGroup name="date-filter" value={op} onChange={v => setOp(v.target.value)}>
        <FormControlLabel value="on" control={<Radio />} label="on" />
        <FormControlLabel value="before" control={<Radio />} label="before" />
        <FormControlLabel value="after" control={<Radio />} label="after" />
      </RadioGroup>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div>
          <DatePicker
            format="MM/dd/yyyy"
            value={parseISO(day)}
            onChange={d => {
              setDay(formatDateISO(d as Date));
              setOpenPicker(false);
            }}
            open={openPicker}
            onClose={() => setOpenPicker(false)}
            onOpen={() => setOpenPicker(true)}
          />
        </div>
      </LocalizationProvider>
    </div>
  );
}

export function SelectFilter({ onChange, source, menuItems }: FilterProps) {
  const { name, title } = source;
  const [value, setValue] = useState(menuItems?.length ? menuItems[0].value : undefined);

  const filterFunction = useCallback(
    item => {
      if (!item || item[name] === undefined) {
        return false;
      }
      return value === item[name];
    },
    [value, name],
  );

  useEffect(() => {
    onChange(filterFunction, `${value}`, name, title);
  }, [filterFunction, onChange, name, value, title]);

  return (
    <div>
      <TextField select fullWidth value={value} onChange={e => setValue(e.target.value)}>
        {menuItems?.map(m => (
          <MenuItem value={m.value} key={m.text}>
            {m.text}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
