import { makeStyles } from '@mui/styles';
import { Button, Typography } from '@mui/material';

const useStyles = makeStyles(() => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    backgroundColor: '#0a0c10',
    justifyContent: 'center',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: 44,
  },
  emoji: {
    fontSize: 72,
    marginBottom: 32,
  },
  text: {
    marginBottom: 44,
  },
  description: {
    color: '#ffffff',
  },
  button: {
    marginBottom: 16,
  },
}));

interface QuizCompletedProps {
  onDismiss: () => void;
}

export default function QuizCompleted({ onDismiss }: QuizCompletedProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.content}>
        <div className={classes.emoji}>🎉</div>
        <Typography className={classes.text} variant="h3">
          You’re Done
        </Typography>
        <Typography className={classes.description} variant="h6">
          Hooray! You’ve completed all of the requirements for this course.
        </Typography>
        <Button className={classes.button} onClick={onDismiss}>
          Dismiss
        </Button>
      </div>
    </div>
  );
}
