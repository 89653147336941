import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import { Modal } from '@mui/material';
import clsx from 'clsx';
import SelectItem from './SelectItem';
import ItemDetails from './ItemDetails';
import { TrainingGroupDetails, TrainingGroupPreview } from '../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  wrapper: {
    outline: 'none',
    borderRadius: 6,
    boxShadow: '40px 40px 40px 0 rgba(0, 0, 0, 0.51)',
    border: 'solid 1px #5d6e80',
    backgroundColor: '#0b0c12',
    padding: 50,
    paddingLeft: 95,
    paddingRight: 95,
    width: 1105,
    height: 671,
  },
  hidden: {
    display: 'none',
  },
}));

export default function ContentScheduler({
  date = new Date((new Date()).setHours(0, 0, 0, 0)),
  setOpen,
  onScheduled = () => undefined,
  attendee,
}: ContentSchedulerProps) {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState<TrainingGroupPreview | TrainingGroupDetails>();

  return (
    <Modal
      open
      onClose={() => setOpen(false)}
      className={classes.modal}
    >
      <div className={classes.wrapper}>
        <SelectItem
          setSelectedItem={setSelectedItem}
          className={clsx({ [classes.hidden]: selectedItem })}
        />
        <ItemDetails
          setSelectedItem={setSelectedItem}
          className={clsx({ [classes.hidden]: !selectedItem })}
          setOpen={setOpen}
          date={date}
          item={selectedItem}
          onScheduled={onScheduled}
          attendee={attendee}
        />
      </div>
    </Modal>
  );
}

type ContentSchedulerProps = {
  date?: Date;
  setOpen: (b: boolean) => unknown;
  onScheduled?: () => unknown;
  attendee: string;
};
