import { round } from '@sightgain/core/calculations';
import { addCommas, capitalize } from '@sightgain/core/strings';
import { DashboardMixedChartMetric } from '../../../reusables/metrics';
import { Ratio, RoiInvestmentAction } from '../../../services/interfaces';

// number of actions by analyst per stage
export default function AnalystPerformance({ actioned }: { actioned: RoiInvestmentAction }) {
  const { labels, ratios } = actioned;
  const data = ratios.find(r => r.name === 'analyst')?.data ?? ([] as Ratio[]);
  const values = data.map(d => d.value);
  const totals = data.map(d => d.total);

  const max = Math.max(...totals, ...values);

  return (
    <DashboardMixedChartMetric
      title="Analyst Performance"
      series={[
        { name: 'Actioned', type: 'column', data: values },
        { name: 'Possible', type: 'column', data: totals },
      ]}
      labels={labels.map(label => capitalize(label))}
      valueAxis={{
        ticks: 6,
        series: [
          { decimalsInFloat: 0, max },
          { decimalsInFloat: 0, max, show: false },
        ],
      }}
      tooltip={{
        formatterY: y => addCommas(round(y, 2)),
      }}
      stroke={{ width: [5, 5], curve: ['straight', 'straight'] }}
      fillOpacity={[1, 1]}
      otherOptions={{
        xaxis: {
          labels: {
            trim: true,
            hideOverlappingLabels: false,
            rotateAlways: true,
            style: {
              colors: labels.map(() => '#a7adb5'),
              fontSize: 12,
              fontFamily: 'Lexend',
              fontWeight: 'normal',
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
      }}
    />
  );
}
