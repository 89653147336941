import { Grid } from '@mui/material';
import { round } from '@sightgain/core/calculations';
import Indicator from '../../../reusables/Indicator';
import { RoiResult } from '../../../services/interfaces';
import FiltersAndGraphs from '../components/FiltersAndGraphs';
import AnalystPerformance from './AnalystPerformance';
import ROICharts from './ROICharts';
import ROIGraphsContainer from './ROIGraphsContainer';
import ROIIndicators from './ROIIndicators';
import TechnologyStagePrevention from './TechnologyStagePrevention';
import TestRateChart from './TestRateChart';

export default function Technical({ roi, thresholds }: { roi: RoiResult; thresholds: [number, number] }) {
  if (!roi.detectionRate) {
    return null;
  }
  return (
    <div data-testid="view-roi-investments">
      <FiltersAndGraphs>
        <ROIIndicators>
          <Indicator title="Total Tests Actioned" data={roi.actionedTests} min={0} max={0} improvement="higher" />
          <Indicator title="Total Tests Not Actioned" data={roi.missedTests} min={0} max={0} improvement="lower" />
          <Indicator
            title="Detection Rate"
            data={roi.detectionRate.map(r => round(r * 100))}
            min={0}
            max={Math.max(...roi.detectionRate)}
            percentage
            improvement="higher"
          />
          <Indicator
            title="Prevention Rate"
            data={roi.preventionRate.map(r => round(r * 100))}
            min={0}
            max={100}
            percentage
            improvement="higher"
          />
        </ROIIndicators>
      </FiltersAndGraphs>
      <ROIGraphsContainer>
        <ROICharts>
          <TestRateChart actioned={roi.investmentActions} />
          <AnalystPerformance actioned={roi.investmentActions} />
        </ROICharts>
        <Grid item xs={12}>
          <TechnologyStagePrevention actioned={roi.investmentActions} thresholds={thresholds} />
        </Grid>
      </ROIGraphsContainer>
    </div>
  );
}
