import { makeStyles } from '@mui/styles';
import Chart from 'react-apexcharts';
import ChartWrapper from './ChartWrapper';

const useStyles = makeStyles(() => ({
  chart: {
    marginTop: -35,
    '& .apexcharts-radial-series path': {
      strokeWidth: 10,
    },
    '& path': {
      strokeLinecap: 'round',
    },
  },
}));

interface RadialBarChartMetricProps {
  value: number;
  title: string;
  label: string;
}

export default function RadialBarChartMetric({ value, title, label }: RadialBarChartMetricProps) {
  const classes = useStyles();

  const options = {
    chart: {
      type: 'radialBar',
      foreColor: '#ffffff',
    },
    plotOptions: {
      radialBar: {
        startAngle: -135,
        endAngle: 135,
        track: {
          background: 'rgba(0, 0, 0, 0.18)',
          strokeWidth: '28px',
        },
        dataLabels: {
          name: {
            offsetY: 22,
            fontSize: 12,
            fontFamily: 'Lexend',
            color: '#ffffff',
          },
          value: {
            offsetY: -10,
            fontSize: 32,
            fontFamily: 'Lexend',
            color: '#ffffff',
            formatter(val: number | string) {
              return `${val}%`;
            },
          },
        },
      },
    },
    fill: {
      type: 'solid',
      opacity: 1,
      colors: ['#2cbce9'],
    },
    labels: [label],
  };

  return (
    <ChartWrapper chartClassName={classes.chart} title={title}>
      <Chart type="radialBar" series={[value]} options={options} height="130%" />
    </ChartWrapper>
  );
}
