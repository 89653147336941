import { useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { makeStyles } from '@mui/styles';
import {
  IconButton,
} from '@mui/material';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NotificationButton from './NotificationButton';
import { useKeycloak } from '@react-keycloak/web';
import appStore from '../../../AppStore';
import { Notifications } from '../Notifications';
import useNotifications from '../../reusables/useNotifications';

const useStyles = makeStyles(() => ({
  buttonsNav: {
    display: 'flex',
  },
  buttonsNavClosed: {
    flexDirection: 'column',
    gap: '14px',
  },
  dFlex: {
    display: 'inline-flex',
    gap: '20px',
  },
  container: {
    marginTop: 14,
    marginBottom: 14,
    padding: '0 18px',
  },
  bottomIcons: {
    color: '#a7adb5',
    fontSize: 27,
  },
  bottomIconsSelected: {
    color: '#ffffff !important',
  },
  footer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '12px 20px 12px 20px',
  },
  footerClosed: {
    justifyContent: 'center',
  },
  livefire: {
    color: '#f67339',
  },
}));

export default function Buttons({
  open = false,
  setOpen = a => undefined,
  showSettings = false,
  showLiveFire = false,
  showNotifications = false,
}: ButtonsProps) {
  const classes = useStyles();
  const openIcon = open ? 'chevron-left' : 'chevron-right';
  const { pathname } = useLocation();
  const history = useHistory();
  const { keycloak } = useKeycloak();
  const [notificationsOpen, setNotificationsOpen] = useState(false);
  const [notifications, unread, markRead, markAllRead] = useNotifications();

  const handleLogout = async () => {
    // we want to only clear session storage and not trigger a mobx re-render before redirecting!
    appStore.clearSessionStorage();
    keycloak.logout();
  };

  return <>
    <div className={clsx(classes.buttonsNav, { [classes.buttonsNavClosed]: !open })} style={{ width: '100%' }}>
      <div className={clsx(classes.container, classes.dFlex, { [classes.buttonsNavClosed]: !open })}>
        {showNotifications && (
          <NotificationButton
            open={open}
            notificationCount={unread}
            onClick={() => setNotificationsOpen(true)}
          />
        )}
        {showLiveFire && (
          <IconButton onClick={() => history.push('/curriculum/evaluations')} size="large">
            <FontAwesomeIcon
              icon={['fal', 'bolt']}
              className={clsx(classes.bottomIcons, classes.livefire,
                { [classes.bottomIconsSelected]: pathname.startsWith('/curriculum/evaluations') })}
            />
          </IconButton>
        )}
        {showSettings && (
          <IconButton onClick={() => history.push('/settings')} size="large">
            <FontAwesomeIcon
              icon={['fal', 'cog']}
              className={clsx(classes.bottomIcons,
                { [classes.bottomIconsSelected]: pathname.startsWith('/settings') })}
            />
          </IconButton>
        )}
        {!showSettings && (
          <IconButton onClick={() => history.push('/profile')} size="large">
            <FontAwesomeIcon
              icon={['fal', 'cog']}
              className={clsx(classes.bottomIcons, 
                { [classes.bottomIconsSelected]: pathname.startsWith('/profile') })}
            />
          </IconButton>
        )}
        <IconButton onClick={() => history.push('/support')} size="large">
          <FontAwesomeIcon
            icon={['fal', 'phone']}
            className={clsx(classes.bottomIcons, { [classes.bottomIconsSelected]: pathname.startsWith('/support') })}
          />
        </IconButton>
        <IconButton onClick={handleLogout} size="large">
          <FontAwesomeIcon
            icon={['fal', 'door-open']}
            className={classes.bottomIcons}
          />
        </IconButton>
      </div>
      {!appStore.isLimitedLicense && (
        <div className={clsx(classes.footer, { [classes.footerClosed]: !open })}>
          <IconButton onClick={() => setOpen(!open)} size="large">
            <FontAwesomeIcon icon={['fal', openIcon]} className={classes.bottomIcons} />
          </IconButton>
        </div>
      )}
    </div>
    <Notifications
      open={notificationsOpen}
      notifications={notifications}
      markAllRead={markAllRead}
      markRead={markRead}
      onClose={() => setNotificationsOpen(false)}
    />
  </>;
}

type ButtonsProps = {
  showSettings: boolean;
  showLiveFire: boolean;
  showNotifications: boolean;
  open: boolean;
  setOpen: (a: boolean) => unknown;
};
