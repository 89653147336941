import { makeStyles } from '@mui/styles';
import {
  Typography,
} from '@mui/material';
import EventField from './EventField';
import HighlightedTextField from '../../../../reusables/HighlightedTextField';
import { IntegrationEvent } from '../../../../services/interfaces';
import { eventLanguage } from './parseEvent';

const useStyles = makeStyles(() => ({
  fields: {
    marginTop: 36,
    display: 'flex',
    gap: 20,
    justifyContent: 'space-between',
    '& > div': {
      flex: 1,
    },
  },
}));

export default function SystemIntegrationEvent({ data }: { data: IntegrationEvent }) {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.fields}>
        <EventField label="Event Record ID" value={data.eventId} disp={!!data.eventId} />
        <EventField label="Source IP" value={data.srcIp} />
        <EventField label="Source Port" value={data.srcPort} />
      </div>
      <div className={classes.fields}>
        <EventField label="Destination IP" value={data.destIp} />
        <EventField label="Destination Port" value={data.destPort} />
        <div />
      </div>
      <div className={classes.fields} style={{ flexDirection: 'column' }}>
        <Typography variant="h5" style={{ marginBottom: 15 }}>Event</Typography>
        <HighlightedTextField data={data.raw} language={eventLanguage(data.raw)} />
      </div>
    </div>
  );
}
