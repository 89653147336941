import { DashboardMixedChartMetric } from '../../../../../reusables/metrics';
import { ScorePerformance } from '../../../../../services/interfaces';
import { performanceSeries, roundArray } from '../utilites';

export default function ChartMeanResponse({ performance }: { performance: ScorePerformance[] }) {

  const [labels, data] = performanceSeries(performance, 'responseTime');

  return (
    <DashboardMixedChartMetric
      title="Mean Response Time"
      series={[{ name: 'Mean Response Time', data: roundArray(data) }]}
      labels={labels ?? []}
      valueAxis={{
        ticks: 4,
        series: [
          {
            show: true,
            min: 0,
            max: data ? Math.max(...data) : 0,
            decimalsInFloat: 0,
          },
        ],
      }}
      labelAxis={{
        formatter: v => v,
      }}
      stroke={{
        width: [2],
        curve: ['smooth'],
      }}
      dataLabels={{
        enabled: false,
      }}
    />
  );
}
