import { ReactNode, useState } from 'react';
import { Table, TableContainer, TableHead, TableRow, TableCell, TableBody, TableSortLabel } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
// import { useScrollPagination } from '../../../effects';
import comparator from './comparator';

const useStyles = makeStyles(() => ({
  container: {
    height: '100%',
  },
  row: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#161926',
    },
  },
  header: {
    whiteSpace: 'nowrap',
  },
}));

interface ScrollableTableProps {
  headers: string[];
  sortableHeaders?: { [propName: string]: any };
  renderFns: { [propName: string]: (row: any) => ReactNode };
  data: any[];
  onClick?: (row: any, e?: any) => void;
  children?: ReactNode;
}

export default function ScrollableTable({
  headers,
  sortableHeaders = {},
  renderFns,
  data,
  onClick,
  children,
}: ScrollableTableProps) {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState<string>();
  const [order, setOrder] = useState<'asc' | 'desc'>('asc');

  // TODO: this isn't working anymore
  //const page = useScrollPagination('table');

  const createSortHandler = (property: string) => () => {
    const isAsc = orderBy === property && order === 'asc';
    setOrderBy(property);
    setOrder(isAsc ? 'desc' : 'asc');
  };

  const renderHeader = (header: string) => {
    if (sortableHeaders[header]) {
      return (
        <TableCell key={header}>
          <TableSortLabel
            active={orderBy === header}
            direction={orderBy === header ? order : 'asc'}
            onClick={createSortHandler(header)}
          >
            {header}
          </TableSortLabel>
        </TableCell>
      );
    }

    return (
      <TableCell key={header}>
        <span className={classes.header}>{header}</span>
      </TableCell>
    );
  };

  function RenderRow({ row }: { row: { id?: string; _id?: string } }) {
    const [expanded, setExpaned] = useState(false);
    return (
      <>
        <TableRow onClick={onClick ? e => onClick(row, e) : undefined} className={classes.row}>
          {headers.map(header => (
            <TableCell key={`cell-${header}`}>{renderFns[header] && renderFns[header](row)}</TableCell>
          ))}
          {children && (
            <TableCell
              onClick={e => {
                e.stopPropagation();
                setExpaned(prev => !prev);
              }}
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </TableCell>
          )}
        </TableRow>
        {expanded && (
          <TableRow sx={{ bgcolor: 'grey1.main' }}>
            <TableCell colSpan={headers.length + 1}>
              {typeof children === 'function' ? children(row) : children}
            </TableCell>
          </TableRow>
        )}
      </>
    );
  }

  let sortedData = data;
  if (orderBy) {
    const orderByField = sortableHeaders[orderBy].field;
    sortedData = data.sort(comparator(orderByField, order));
  }
  // TODO: this isn't workign anymore
  // const filteredData = sortedData.slice(0, page * 100);
  const filteredData = sortedData;
  return (
    <TableContainer id="table" className={classes.container}>
      <Table>
        <TableHead>
          <TableRow>{headers.map(header => renderHeader(header))}</TableRow>
        </TableHead>
        <TableBody>
          {filteredData.map((data: any) => (
            <RenderRow key={`${data.id ?? data._id}`} row={data} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
