import { curriculumService, liveFireTrainingService } from '../../services';
import useSocketListener from '../useSocketListener';
import { ActivityInfo, ActivityLFT } from './interfaces';
import { TrainingGroupType } from '../../services/interfaces';
import { useCallback, useMemo } from 'react';
const activityFields = 'id title difficulty progress status';
const curriculumFields = 'curriculums { id title }';
const completedStatuses = ['discarded', 'ended', 'completed'];

export default function useActivityWatcher(tab: string, userId: string) {
  const msgType = tab === 'evaluations' ? 'livefiretraining' : 'progress';

  const callback = useCallback(async (body: { student: string }) => {
    if (tab === 'evaluations' || tab === 'exams') {
      const livefires: ActivityLFT[] = await liveFireTrainingService.list(
        { analyst: userId }, {},
        `{
          id
          status
          completed
          isReleased
          isExam
          job { id isExcluded evaluationId name }
          score
          eventScore
          precisionScore
          responseScore
          instructorScore
        }`
      );

      if (tab === 'exams') {
        return {
          completed: livefires.filter(lf => lf.isExam && completedStatuses.includes(lf.status)),
          incomplete: livefires.filter(lf => lf.isExam && !completedStatuses.includes(lf.status)),
        };
      }

      return {
        completed: livefires.filter(lf => !lf.isExam && completedStatuses.includes(lf.status)),
        incomplete: livefires.filter(lf => !lf.isExam && !completedStatuses.includes(lf.status)),
      };
    }

    if (body.student !== userId) {
      return;
    }

    const parentFields = tab === 'courses' ? curriculumFields : '';
    return await curriculumService.studentActivity(
      userId,
      tab === 'courses' ? TrainingGroupType.course : TrainingGroupType.curriculum,
      `{ incomplete { ${activityFields} ${parentFields} } completed { ${activityFields} ${parentFields} } }`,
      'no-cache',
    );
  }, [tab, userId]);

  const initArgs = useMemo(() => ({ student: userId }), [userId]);
  const value = useMemo(() => ({ completed: [], incomplete: [] } as ActivityInfo), [tab]);

  return useSocketListener(
    msgType,
    'update',
    callback,
    initArgs,
    value,
  ) as ActivityInfo;
}