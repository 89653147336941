import clsx from 'clsx';
import { subDays } from 'date-fns';
import { useContext, useState } from 'react';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { divideSafely } from '@sightgain/core/calculations';
import { addCommas, capitalize } from '@sightgain/core/strings';
import { useAbort } from '../../../../../effects';
import { ArrowBadge } from '../../../../reusables/Badges';
import CircularProgress from '../../../../reusables/metrics/CircularProgress';
import LargeNumericMetric from '../../../../reusables/metrics/LargeNumericMetric';
import Tabs from '../../../../reusables/Tabs';
import { DailyJobMetric } from '../../../../services/interfaces';
import { SocCacheItem } from '../interfaces';
import SocContext from '../SocContext';
import TechnologySummary from './TechnologySummary';

const useStyles = makeStyles(() => ({
  avgTime: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  testsSummary: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
  },
  headingRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 37,
    marginTop: 0,
  },
  chartRow: {
    display: 'flex',
  },
  compText: {
    marginTop: 3,
    marginBottom: 16,
    color: '#00ffb1',
  },
  compDown: {
    color: '#f85c5c',
  },
  techRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  testsContent: {
    padding: 25,
    backgroundColor: 'rgba(255, 255, 255, 0.07)',
    borderRadius: 6,
  },
  testPercentage: {
    position: 'relative',
    display: 'inline-flex',
  },
  testPercentageValue: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  rangeSelector: {
    width: 169,
  },
  summary: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: '6px 0 24px 24px',
  },
  summaryTitle: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 6,
  },
  summaryTitleText: {
    fontSize: 30,
    fontWeight: 'normal',
    lineHeight: 1.13,
    margin: 0,
    marginRight: 12,
  },
  stateText: {
    fontWeight: 'bold',
    textTransform: 'uppercase',
    color: '#a7adb5',
    margin: 0,
  },
}));

type ResultIndex = 'Prevented' | 'Detected' | 'Alerted' | 'Missed';
type ProductIndex = 'Prevented' | 'Detected' | 'Alerted';

interface ProductSummary {
  Prevented: number;
  Detected: number;
  Alerted: number;
}
interface ResultSummary {
  Prevented: number;
  Detected: number;
  Alerted: number;
  Missed: number;
  count: number;
  products: {
    [type: string]: ProductSummary;
  };
}

export default function TestsSummary() {
  const classes = useStyles();
  const { loadDataForTimeframe, timeSpan } = useContext(SocContext);
  const [selected, setSelected] = useState(0);

  const [data, setData] = useState<ResultSummary>({
    Prevented: 0,
    Detected: 0,
    Alerted: 0,
    Missed: 0,
    count: 0,
    products: {},
  });
  const [previousData, setPreviousData] = useState<ResultSummary>({
    Prevented: 0,
    Detected: 0,
    Alerted: 0,
    Missed: 0,
    count: 0,
    products: {},
  });

  const states: ResultIndex[] = ['Prevented', 'Detected', 'Alerted', 'Missed'];

  const calculate = async (dailyJobMetrics: DailyJobMetric[]) =>
    dailyJobMetrics.reduce(
      (acc, day) => {
        const { alertedCount, detectedCount, preventedCount, missedCount, count, products } = day;

        acc.Prevented += preventedCount;
        acc.Detected += detectedCount;
        acc.Alerted += alertedCount;
        acc.Missed += missedCount;
        acc.count += count;
        products.forEach(product => {
          const exists = acc.products[product.type];
          if (!exists) {
            acc.products[product.type] = {
              Prevented: product.preventedCount,
              Detected: product.detectedCount,
              Alerted: product.alertedCount,
            };
            return;
          }
          exists.Prevented += product.preventedCount;
          exists.Detected += product.detectedCount;
          exists.Alerted += product.alertedCount;
        });

        return acc;
      },
      {
        Prevented: 0,
        Detected: 0,
        Alerted: 0,
        Missed: 0,
        count: 0,
        products: {},
      } as ResultSummary,
    );

  useAbort(
    () => {
      const today = new Date();
      const from = subDays(today, timeSpan);
      const prev = subDays(from, timeSpan);
      return Promise.all([loadDataForTimeframe(from, today), loadDataForTimeframe(prev, from)]);
    },
    async ([{ dailyJobMetrics }, { dailyJobMetrics: prevDailyJobMetrics }]: [SocCacheItem, SocCacheItem]) => {
      const cur = await calculate(dailyJobMetrics);
      const prev = await calculate(prevDailyJobMetrics);
      setData(cur);
      setPreviousData(prev);
    },
    [timeSpan],
  );

  const selectedState = states[selected];
  const totalTests = data.count;
  const countedTests = data[selectedState];
  const testPercent = Math.round(divideSafely(countedTests, totalTests) * 100);
  const previous = previousData[selectedState];
  const diff = Math.round(divideSafely(countedTests - previous, countedTests) * 100);
  const trend = diff > 0 ? 'up' : 'down';
  let improvement: 'up' | 'middle' | 'down' = 'middle';
  if (selectedState === 'Missed') {
    improvement = diff > 0 ? 'down' : 'up';
  } else {
    improvement = trend;
  }
  // const avgTime = '01:01:00';

  return (
    <div className={classes.testsSummary}>
      <Tabs
        selected={selected}
        onSelect={n => setSelected(n)}
        labels={states}
        scrollButtons="auto"
        variant="scrollable"
      />
      <div className={classes.testsContent}>
        <div className={classes.headingRow}>
          <Typography variant="h3">{`${selectedState} Tests`}</Typography>
        </div>

        <div className={classes.chartRow}>
          <div className={classes.testPercentage}>
            <CircularProgress percent={testPercent} size={143} strokeWidth={15} />
            <div className={classes.testPercentageValue}>
              <LargeNumericMetric value={testPercent} unit="%" label="" />
            </div>
          </div>

          <div className={classes.summary}>
            <div className={classes.summaryTitle}>
              <h3 className={classes.summaryTitleText}>
                <strong>{addCommas(countedTests)}</strong>
                {` of ${addCommas(totalTests)} Tests`}
              </h3>
              <ArrowBadge trend={improvement} />
            </div>

            <p className={classes.stateText}>{selectedState}</p>

            <Typography
              variant="inherit"
              className={clsx(classes.compText, improvement === 'down' && classes.compDown)}
            >
              {`${capitalize(trend)} ${Math.abs(diff)}% vs. last ${timeSpan} days`}
            </Typography>

            {/* currently disabled - need to identify correct computation
            <Typography variant="inherit">Average prevention time</Typography>
            <Typography className={classes.avgTime} variant="body1">{avgTime}</Typography> */}
          </div>
        </div>

        <div className={classes.techRow}>
          {['Prevented', 'Detected'].includes(selectedState) &&
            Object.keys(data.products).map(type => (
              <TechnologySummary
                key={type}
                title={type}
                percent={Math.round(divideSafely(data.products[type][selectedState as ProductIndex], data.count) * 100)}
                previous={Math.round(
                  divideSafely(
                    (previousData.products[type] && previousData.products[type][selectedState as ProductIndex]) || 0,
                    previousData.count,
                  ) * 100,
                )}
                total={data.products[type][selectedState as ProductIndex]}
              />
            ))}
          {selectedState === 'Alerted' && (
            <TechnologySummary
              key="SIEM"
              title="SIEM"
              percent={Math.round(divideSafely(data.Alerted, data.count) * 100)}
              previous={Math.round(divideSafely(previousData.Alerted || 0, previousData.count) * 100)}
              total={data.Alerted}
            />
          )}
          {selectedState === 'Missed' && <div style={{ height: 88 }} />}
        </div>
      </div>
    </div>
  );
}
