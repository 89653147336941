import { useContext, useEffect, useMemo } from 'react';
import { EvaluationRunner } from '../../interfaces';
import Description from './Description';
import Nodes from './Nodes';
import Stages from './Stages';
import EvaluationWindowContext from '../EvaluationWindowContext';
import Steps from './Steps';
import Target from './Target';
import Collection from './Collection';
import StoppageTime from './StoppageTime';
import Schedule from './Schedule';
import Controls from './Controls';

export default function DefaultStepper({ runner }: { runner: EvaluationRunner }) {
  const { actorOptions, scenarioTarget, label, handleUpdateIsRunnable } = useContext(EvaluationWindowContext);

  useEffect(() => {
    if (!actorOptions || actorOptions.length) {
      handleUpdateIsRunnable(!!actorOptions?.length);
      return;
    }

    handleUpdateIsRunnable('Actors need to be configured first');
  }, [actorOptions, handleUpdateIsRunnable]);

  // all paths have these steps
  const steps = useMemo(() => {
    const stepComponents = [Description, Stages, Nodes, Target, Controls];

    // analyst paths have 2 more steps
    if (scenarioTarget === 'analyst') {
      stepComponents.push(Collection, StoppageTime);
    }

    // exam paths have schedules
    if (label === 'exam') {
      stepComponents.push(Schedule);
    }

    return stepComponents;
  }, [scenarioTarget, label]);

  return <Steps runner={runner} steps={steps} />;
}
