import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function BeginButton({ type, id }: BeginButtonProps) {
  if (type === 'course') {
    return (
      <Button
        component={Link}
        to={`/curriculum/learning/id/course/${id}`}
        variant="contained"
        color="primary"
        endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
      >
        Begin Course
      </Button>
    );
  }

  return (
    <Button
      component={Link}
      to={`/curriculum/learning/${id}`}
      variant="contained"
      color="primary"
      endIcon={<FontAwesomeIcon icon={['fal', 'long-arrow-right']} />}
    >
      Begin Curriculum
    </Button>
  );
}

type BeginButtonProps = {
  type: string,
  id: string,
};
