import { gql } from '@apollo/client';
import { Support } from './interfaces';
import ServiceBase from './ServiceBase';

const typeSupport = `
  {
    phone
    email
    links {
      title
      description
      url
    }
  }
`;

export class SupportService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('before', options => ({
      ...options,
      url: options.url === 'graphql' ? options.url : `jobs/${options.url}`,
    }));
  }

  async support(fields = typeSupport): Promise<Support> {
    const query = gql`
      query Support {
        support ${fields}
      }`;
    const { support } = await this.graphql(query);
    return support;
  }
}

export default new SupportService();
