import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'content',
    background: 'linear-gradient(180deg, #1899B6 0%, rgba(24, 153, 182, 0.00) 100%)',
    padding: 8
  }
}));

export function formatDate(dateString: string): string {
  const [year, month, day] = dateString.split('-');
  
  // Get the month name based on the month number
  const monthNames = [
    'January', 'February', 'March', 'April', 'May', 'June', 
    'July', 'August', 'September', 'October', 'November', 'December'
  ];
  const monthName = monthNames[Number(month) - 1];
  
  // Construct the formatted date string
  const formattedDate = `${monthName} ${Number(day)}, ${year}`;
  
  return formattedDate;
}

export default function DateHeader({ date }: { date: string }){
  const classes = useStyles();

  return (
    <div className={classes.header}>
      <Typography variant="body1" style={{ fontSize: 13 }}>{formatDate(date)}</Typography>
    </div>
  );
}
