import { ClassNameMap, makeStyles } from '@mui/styles';
import { Avatar as Mavatar, IconButton } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

type Size = 'xs' | 's' | 'm' | 'lg';

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'relative',
    display: 'inline-block',
  },
  badge: {
    position: 'absolute',
    bottom: 0,
    right: 3,
    width: 24,
    height: 24,
    padding: 0,
    backgroundColor: '#2ecff5',
    borderRadius: '50%',
    '&:hover': {
      backgroundColor: '#2ecff5',
    },
  },
  icon: {
    width: 14,
    height: 14,
    color: '#0a0c10',
  },
  xs: {
    width: 40,
    height: 40,
    border: '1.5px solid #4792f7',
  },
  s: {
    width: 60,
    height: 60,
    border: '2px solid #4792f7',
  },
  m: {
    width: 90,
    height: 90,
    border: '2.5px solid #4792f7',
  },
  lg: {
    width: 100,
    height: 100,
    border: '2.5px solid #4792f7',
  },
}));

const getSizeClassName = (size: Size, classes: ClassNameMap<Size>) => {
  return (
    {
      xs: classes.xs,
      s: classes.s,
      m: classes.m,
      lg: classes.lg,
    }[size] || ''
  );
};

interface AvatarProps {
  image: string;
  size: Size;
  onEdit?: (() => void) | null;
}

export default function Avatar({ image, size = 'm', onEdit = null }: AvatarProps) {
  const classes = useStyles();

  // TODO: wire this up post MVP
  const isEditable = false && onEdit && ['m', 'lg'].includes(size);

  return (
    <div className={classes.wrapper}>
      <Mavatar src={image} alt="userAvatar" className={getSizeClassName(size, classes)} />
      {isEditable && (
        <IconButton color="secondary" className={classes.badge} size="large">
          <FontAwesomeIcon icon={['fal', 'pencil-alt']} className={classes.icon} />
        </IconButton>
      )}
    </div>
  );
}
