import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  summary: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    marginBottom: 40,
    marginTop: 20,
    gap: 20,
  },
});

export default function ROIIndicators({ children }: { children: JSX.Element[] }) {
  const classes = useStyles();

  return (
    <div className={classes.summary}>
      { children }
    </div>
  );
}