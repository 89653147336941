import React, { createContext, useContext, useMemo, useState } from 'react';

interface CompareState {
  tilesCompareKey: string;
  tileObjsDay1: any[]; // TODO: set a type
  tileObjsDay2: any[]; // TODO: set a type
}

const initialState: CompareState = {
  tilesCompareKey: '',
  tileObjsDay1: [],
  tileObjsDay2: [],
};

const CompareContext = createContext<[CompareState, React.Dispatch<React.SetStateAction<CompareState>>]>([
  initialState,
  () => undefined,
]);

export const CompareProvider: React.FC = ({ children }) => {
  const [state, setState] = useState(initialState);

  return (
    <CompareContext.Provider value={[state, setState]}>
      {children}
    </CompareContext.Provider>
  );
};

export const useCompareStore = () => {
  const [state, setState] = useContext(CompareContext);

  const actions = useMemo(() => ({
    reset: () => setState(initialState),
    setTilesCompareKey: (key: string) => setState(prevState => ({ ...prevState, tilesCompareKey: key })),
    setTileObjsDay1: (tiles: any[]) => setState(prevState => ({ ...prevState, tileObjsDay1: tiles })),
    setTileObjsDay2: (tiles: any[]) => setState(prevState => ({ ...prevState, tileObjsDay2: tiles })),
  }), [setState]); // Here setState is a dependency, it should not change during the component lifecycle

  return { state, ...actions };
};
