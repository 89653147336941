import Chart from 'react-apexcharts';
import { makeStyles } from '@mui/styles';
import ChartWrapper from './ChartWrapper';
import { allColors } from './utils';

const useStyles = makeStyles(() => ({
  chart: {
    '& .apexcharts-legend.position-right': {
      justifyContent: 'center',
      marginBottom: 28,
    },
  },
}));

interface RadarChartMetricProps {
  title: string;
  labels: string[];
  series: {
    name: string;
    data: number[];
  }[];
}

export default function RadarChartMetric({ title, labels, series }: RadarChartMetricProps) {
  const colors = allColors.slice(0, series.length);
  const classes = useStyles();

  const options = {
    height: '100%',
    chart: {
      height: '100%',
      foreColor: '#ffffff',
      type: 'radar',
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radar: {
        size: 65,
        polygons: {
          strokeColors: '#a7adb5',
          connectorColors: '#a7adb5',
          fill: {
            colors: ['rgba(0, 0, 0, 0)'],
          },
        },
      },
    },
    stroke: {
      width: 2,
    },
    colors,
    legend: {
      position: 'top',
      horizontalAlign: 'center',
      fontSize: '12px',
      fontFamily: 'Lexend',
      fontWeight: 'normal',
      labels: {
        colors: ['#fff'],
      },
    },
    xaxis: {
      categories: labels,
      labels: {
        style: {
          fontSize: 11,
          fontFamily: 'Lexend',
          fontWeight: 'normal',
          colors: '#a7adb5',
        },
      },
    },
    yaxis: {
      show: false,
      max: 100,
      min: 0,
      tickAmount: 4,
    },
    fill: {
      opacity: 0.44,
    },
    markers: {
      size: 0,
    },
  };

  return (
    <ChartWrapper chartClassName={classes.chart} title={title}>
      <Chart type="radar" series={series} options={options} height="100%" />
    </ChartWrapper>
  );
}
