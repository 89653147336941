import { useEffect, useMemo, useState } from 'react';
import { AssessmentView } from './interfaces';
import List from './List';
import Create from './Create';
import Details from './Details';
import Licenses from './Licenses';
import { useAssessmentContext } from '../AssessmentContext';

const VIEWS = new Map<AssessmentView, CallableFunction>([
  [AssessmentView.LIST, List],
  [AssessmentView.NEW, Create],
  [AssessmentView.DETAILS, Details],
  [AssessmentView.LICENSES, Licenses],
]);

export default function AssessmentViews({ view, onExit }: AssessmentViewsProps) {
  const { setEditAssessment } = useAssessmentContext();
  const [activeView, setActiveView] = useState<AssessmentView>();

  useEffect(() => {
    setActiveView(view);
  },
  [view]);

  const Component = useMemo(() => 
    activeView !== undefined && (VIEWS.get(activeView) as CallableFunction), [activeView]);

  if (!Component) {
    return <></>;
  }

  const handleExit = () => {
    // reset to the original view
    setActiveView(view);
    onExit();
  };

  const handleViewChange = (update: AssessmentView) => {
    if (update === AssessmentView.NEW) {
      setEditAssessment({});
    }
    setActiveView(update);
  };

  return (
    <Component
      onChangeView={handleViewChange}
      onExit={handleExit}
    />
  );
}

interface AssessmentViewsProps {
  view: AssessmentView;
  onExit: () => unknown;
}