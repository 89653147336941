import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  control: {
    marginTop: 20,
  },
  input: {
    borderRadius: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: '#617884',
    paddingRight: 20,
  },
  label: {
    display: 'block',
    margin: '0 18px 5px 0',
    color: '#fff',
    position: 'relative',
    textTransform: 'capitalize',
    fontSize: 14,

    '&.Mui-focused': {
      color: '#fff',
    },
  },
  red: {
    color: 'red',
    borderColor: 'red',
  }
}));

export default function ValueField({ label, error, value, onChange }: ValueFieldProps) {
  const classes = useStyles();

  const id = `value-field-${label.replace(/[^a-z_-]/gi, '-')}`;

  return (
    <FormControl className={classes.control}>
      <InputLabel
        disableAnimation
        className={clsx(classes.label, error && classes.red)}
        htmlFor={id}
      >
        {label}
      </InputLabel>
      <Input
        id={id}
        className={clsx(classes.input, error && classes.red)}
        type="number"
        value={value}
        inputProps={{ min: 0.5, max: 100, role: 'textbox' }}
        endAdornment={<InputAdornment position="end">
          <FontAwesomeIcon icon={['fal', 'percent']} />
        </InputAdornment>}
        required
        onChange={e => onChange(+e.target.value)}
      />
      {error && (
        <FormHelperText className={classes.red}>{error}</FormHelperText>
      )}
    </FormControl>
  );
}

interface ValueFieldProps {
  label: string,
  value: number,
  error: string,
  onChange: (n: number) => unknown;
}