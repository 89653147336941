import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import {
  Button,
  Typography,
  FormControl,
  MenuItem,
  Select,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  SearchBar,
  LeaderCard,
  IconBadge,
} from '../../../../reusables/scl';
import { AnalystScore } from './interfaces';
import { sortScores } from './score';

const useStyles = makeStyles(() => ({
  header: {
    marginBottom: '39px',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 20,
  },
  headerLeft: {
    display: 'flex',
    gap: 26,
    flex: 1,
    maxWidth: '800px',
  },
  headerRight: {
    display: 'flex',
    gap: 15,
    flex: 1,
  },
  formControl: {
    minWidth: 200,
  },
  leaders: {
    marginTop: 25,
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
  },
}));

export default function LeaderBoard({ scores, byInstructorScore = false, setShowLeaderBoard }: LeaderBoardProps) {
  const classes = useStyles();
  const [searchValue, setSearchValue] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('all');

  const analystFilter = (analyst: string) => analyst.toLowerCase().includes(searchValue.toLowerCase());

  const scoreFilter = (score: number) => {
    switch (selectedCategory) {
      case 'passing':
        return score > 75;
      case 'failing':
        return score < 75;
      default: return true;
    }
  };

  const sorted = sortScores(scores, byInstructorScore);

  const filteredData = sorted.filter(s => analystFilter(`${s.name}--${s.email}`)
    && scoreFilter(byInstructorScore ? s.instructorScore : s.score));

  return (
    <div>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Button color="primary" onClick={() => setShowLeaderBoard(false)}>
            <FontAwesomeIcon icon={['fal', 'long-arrow-left']} size="lg" />
            &nbsp;
            Back to Overview
          </Button>
        </div>
        <div className={classes.headerRight}>
          <SearchBar placeholder="Search Analysts" onChange={setSearchValue} />
          <FormControl className={classes.formControl}>
            <Select
              value={selectedCategory}
              onChange={e => setSelectedCategory(e.target.value as string)}
            >
              <MenuItem value="all">All Scores</MenuItem>
              <MenuItem value="passing">Passing Scores</MenuItem>
              <MenuItem value="failing">Failing Scores</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      <div>
        <Typography variant="h2">Analyst Leaderboard</Typography>
        <div className={classes.leaders}>
          {filteredData.map((data, i) => {
            const { id, name, email, instructorScore, score, achievements, curriculums, courses } = data;
            return (
              <LeaderCard
                rank={`${i + 1}`}
                id={id}
                name={name}
                email={email}
                score={byInstructorScore ? instructorScore : score}
                key={`leader-board-item-${email}`}
              >
                <IconBadge icon={['fal', 'medal']}>{achievements}</IconBadge>
                <IconBadge icon={['fal', 'th-list']}>{curriculums}</IconBadge>
                <IconBadge icon={['fal', 'users-class']}>{courses}</IconBadge>
              </LeaderCard>
            );
          })}
        </div>
      </div>
    </div>
  );
}

interface LeaderBoardProps {
  scores: AnalystScore[];
  setShowLeaderBoard: (v: boolean) => unknown;
  byInstructorScore?: boolean;
}