import { makeStyles } from '@mui/styles';
import { Typography } from '@mui/material';
import { ResultBadge, RichText } from '../../../reusables/scl';

const useStyles = makeStyles(() => ({
  wrapper: {
    paddingTop: 53,
    paddingLeft: 76,
    paddingRight: 76,
    paddingBottom: 53,
    overflow: 'scroll',
    height: '100%',
  },
  text: {
    paddingBottom: 14,
  },
  answer: {
    marginBottom: 68,
  },
  icon: {
    display: 'inline-block',
    marginRight: 13,
  },
  yourAnswerCorrect: {
    display: 'inline-block',
    color: '#00ffb1',
    fontWeight: 'bold',
  },
  yourAnswerIncorrect: {
    display: 'inline-block',
    color: '#f85c5c',
    fontWeight: 'bold',
  },
  correctAnswerWrapper: {
    marginTop: 21,
  },
  correctAnswer: {
    display: 'inline-block',
    marginLeft: 38,
    fontWeight: 'bold',
  },
}));

interface Answers {
  title?: string;
  description?: string;
  answer?: string;
  answerCorrect?: string;
  correct?: boolean;
}

interface ReviewAnswersProps {
  answers?: Answers[];
}

export default function ReviewAnswers({ answers = [] }: ReviewAnswersProps) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {answers.map((answer, index) => (
        <div key={answer.title}>
          <Typography className={classes.text} variant="h3">
            {index + 1}. {answer.title}
          </Typography>
          <Typography className={classes.text} variant="body1">
            {answer.description}
          </Typography>
          <div className={classes.answer}>
            <Typography variant="body1">
              <div className={classes.icon}>
                <ResultBadge result={answer.correct ? 'pass' : 'fail'} />
              </div>
              <div className={answer.correct ? classes.yourAnswerCorrect : classes.yourAnswerIncorrect}>
                Your answer:
              </div>{' '}
              <RichText text={answer.answer} />
            </Typography>
            {!answer.correct && (
              <Typography className={classes.correctAnswerWrapper} variant="body1">
                <div className={classes.correctAnswer}>Correct Answer:</div>{' '}
                <RichText text={answer.answerCorrect || ''} />
              </Typography>
            )}
          </div>
        </div>
      ))}
    </div>
  );
}
