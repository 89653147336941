import { differenceInCalendarDays, sub } from 'date-fns';
import React, { useState } from 'react';
import { MenuItem, Select, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { capitalize } from '@sightgain/core/strings';
import { useAbort } from '../../../../../effects';
import TimeSeriesMetric from '../../../../reusables/metrics/TimeSeriesMetric';
import curriculumService from '../../../../services/CurriculumService';
import { TrainingGroupType } from '../../../../services/interfaces';

const useStyles = makeStyles(() => ({
  titleRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  controls: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
  },
}));

function daySums(dates: Date[], duration: number): { days: number[]; sums: number[] } {
  const now = new Date();
  const days = [...Array(duration).keys()];
  const sums = days.map(x => 0);
  for (const date of dates) {
    const diff = differenceInCalendarDays(now, date);
    if (diff < duration) {
      sums[diff] = sums[diff] + 1;
    }
  }

  return { days, sums };
}

function Progress({ analyst }: { analyst?: string }) {
  const classes = useStyles();
  const [completed, setCompleted] = useState<Date[]>([]);
  const [chartType, setChartType] = useState<string>('achievements');
  const [duration, setDuration] = useState<number>(30);

  useAbort(
    async () => {
      if (!analyst) {
        return;
      }

      if (chartType === 'achievements') {
        const achievements = await curriculumService.achievementsProgress(analyst, '{ completionDate }');
        return achievements.map(a => a.completionDate).filter(a => a) as Date[];
      }

      const progress = await curriculumService.completedProgressByType(
        analyst,
        chartType as TrainingGroupType,
        '{ updatedAt }',
      );
      return progress.map(p => p.updatedAt);
    },
    completionDates => completionDates && setCompleted(completionDates),
    [analyst, chartType],
  );

  const { days, sums } = daySums(completed, duration);
  const now = new Date();
  const labels = days.map(x => sub(now, { days: x })).reverse();
  const reversedSums = sums.reverse();
  const data = labels.map((x, idx) => [x.getTime(), reversedSums[idx]]);

  const series = [{ name: capitalize(chartType), type: 'line', data }];

  return (
    <div>
      <div className={classes.titleRow}>
        <Typography variant="h6" style={{ color: '#ffffff' }}>
          PROGRESS OVER TIME
        </Typography>
        <div className={classes.controls}>
          <Select value={chartType} onChange={e => setChartType(e.target.value as string)}>
            <MenuItem value="achievements">Achievements</MenuItem>
            <MenuItem value="course">Courses</MenuItem>
            <MenuItem value="class">Classes</MenuItem>
            <MenuItem value="curriculum">Curriculum</MenuItem>
          </Select>
          <Select value={duration} onChange={e => setDuration(e.target.value as number)}>
            <MenuItem value={30}>Last 30 Days</MenuItem>
            <MenuItem value={60}>Last 60 Days</MenuItem>
            <MenuItem value={90}>Last 90 Days</MenuItem>
          </Select>
        </div>
      </div>
      <div>
        <TimeSeriesMetric title="" series={series} />
      </div>
    </div>
  );
}

export default React.memo(Progress);
