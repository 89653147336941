import { useState } from 'react';
import { Box, Divider, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import appStore from '../../../../../../AppStore';
import assessmentsService from '../../../../../services/AssessmentsService';
import { useAssessmentContext } from '../../../components/AssessmentContext';
import { Answer, Objective, Question } from '../interface';

function YesNoQuestion({ answer, save, question, isReadonly }: YesNoQuestionProps) {
  const [value, setValue] = useState(answer?.value ?? '');

  async function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const prev = value;
    setValue(event.target.value);

    // attempt to save answer
    const success = await save(question, event.target.value);

    // roll back if not successful
    if (!success) {
      setValue(prev);
      appStore.error('Failed to save response. Please try again');
      return;
    }

    appStore.success('Response Saved Successfully');
  }

  return (
    <Grid item>
      <Grid container direction="column" spacing={3}>
        <Grid item>
          <Typography>{question.question}</Typography>
        </Grid>
        <Grid item>
          <Grid container spacing={3}>
            <Grid item>
              <RadioGroup value={value} name={`radio_${question.id}`} row onChange={handleChange}>
                <FormControlLabel disabled={isReadonly} value="yes" control={<Radio />} label="Yes" />
                <FormControlLabel disabled={isReadonly} value="no" control={<Radio />} label="No" />
              </RadioGroup>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

type YesNoQuestionProps = {
  answer?: Answer;
  save: (question: Question, val: string) => Promise<boolean>;
  question: Question;
  isReadonly?: boolean;
};

export default function ObjectiveQuestions({ objective, isReadonly }: ObjectiveQuestionsProps) {
  const { assessment } = useAssessmentContext();

  async function saveAnswer(question: Question, value: string) {
    try {
      const response = await assessmentsService.addAnswerArtifact(assessment.id, objective.id, question.id, value);
      objective.answers = response.answers;
      return true;
    } catch (err) {
      return false;
    }
  }

  if (objective.questions.length === 0) {
    return <Divider style={{ margin: '30px 0' }} />;
  }

  return (
    <Box>
      <Divider style={{ margin: '30px 0' }} />
      <Grid container direction="column" spacing={3}>
        {objective.questions.map(question => {
          const answer = objective.answers.find(ans => ans.question.id === question.id);
          return (
            <YesNoQuestion
              key={question.id}
              question={question}
              save={saveAnswer}
              answer={answer}
              isReadonly={isReadonly}
            />
          );
        })}
      </Grid>
      <Divider style={{ margin: '30px 0' }} />
    </Box>
  );
}

type ObjectiveQuestionsProps = {
  objective: Objective;
  isReadonly?: boolean;
};
