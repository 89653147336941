import { useEffect, useState } from 'react';
import { socket } from '../services';
import { Message } from '../services/Socket/intefaces';

/**
 * Waits for websocket messages for a specific type and action
 * and then calls a refresh method and returns the results using an
 * update to the state
 * Be sure to useCallback and useMemo to prevent unexpected changes to arguments
 * @param type SRP message type
 * @param action SRP message action
 * @param callback callback back when a message is received
 * @param initArgs initial body value to make the initial call
 * @param value value to set immidiately
 */
export default function useSocketListener<T>(
  type: string,
  action: string,
  callback: (body: any) => Promise<T | undefined>,
  initArgs: any = '',
  value?: T | (() => T),
): T | undefined {
  // save the results in state
  const [results, setResults] = useState<T | undefined>(value);

  useEffect(() => {
    setResults(value);
  }, [value]);

  useEffect(() => {
    let abort = false;
    // handle calls to refresh the data
    const handleRefresh = async ({ body }: Message) => {
      const result = await callback(body);
      if (abort || result === undefined) {
        return;
      }

      setResults(result);
    };

    // get the initial result
    handleRefresh({ body: initArgs, type, action });

    // subscribe to the updates
    const unsubscribe = socket.subscribe(handleRefresh, { type, action });
    // clean up method to return
    return () => {
      abort = true;
      return unsubscribe();
    };
  }, [type, action, callback, initArgs]);

  return results;
}