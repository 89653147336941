import { makeStyles } from '@mui/styles';
import { LeaderCard } from '../../../../reusables/scl';
import { AnalystScore } from './interfaces';
import { sortScores } from './score';

const useStyles = makeStyles(() => ({
  leaders: {
    display: 'flex',
    flexDirection: 'column',
    gap: 7,
  },
}));

export default function LeaderBoardPreview({ scores, byInstructorScore = false }: LeaderBoardPreviewProps) {
  const classes = useStyles();

  const sorted = sortScores(scores, byInstructorScore);

  return (
    <div className={classes.leaders}>
      {sorted.slice(0, 10).map(({ name, id, email, score, instructorScore }, i) => {
        return (
          <LeaderCard
            rank={`${i + 1}`}
            name={name}
            id={id}
            email={email}
            score={byInstructorScore ? instructorScore : score}
            key={`leader-board-item-${email}`}
          />
        );
      })}
    </div>
  );
}

interface LeaderBoardPreviewProps {
  scores: AnalystScore[];
  byInstructorScore?: boolean;
}
