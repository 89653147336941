import { makeStyles } from '@mui/styles';
import {
  Card,
  Divider,
  Typography,
} from '@mui/material';
import FaIconButton from '../../../../reusables/FaIconButton';
import { LargeNumericMetric } from '../../../../reusables/metrics';
// import CardUserInfo from './CardUserInfo';
import { TierBadge } from '../../../../reusables/Badges';
import { TrainingGroupLevel } from '../../../../services/interfaces';
import { CardMethod } from './intefaces';

const useStyles = makeStyles(() => ({
  wrapper: {
    padding: 0,
    display: 'flex',
  },
  content: {
    flex: 4,
    paddingTop: 54,
    paddingBottom: 54,
    paddingLeft: 54,
    paddingRight: 40,
  },
  levelAndIcon: {
    display: 'flex',
    flexDirection: 'row',
    alignItem: 'center',
    '& > *:first-child': {
      marginRight: 60,
    },
    marginBottom: 14,
  },
  footer: {
    display: 'flex',
    flexDirection: 'row',
  },
  image: {
    flex: 6,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
  },
  imageGradient: {
    width: '50%',
    height: '100%',
    backgroundImage: 'linear-gradient(to right, #0b0c12 0%, rgba(11, 12, 18, 0) 99%);',
  },
  title: {
    marginBottom: 25,
  },
  description: {
    marginBottom: 40,
  },
  divider: {
    marginLeft: 38,
    marginRight: 38,
  },
}));

export default function CurriculumHeader({
  image,
  level,
  onBookmark,
  title,
  description,
  courses = 0,
  durationDays = 0,
  trainings = 0,
  instructor,
}: CurriculumHeaderProps) {
  const classes = useStyles({ image });

  return (
    <Card className={classes.wrapper}>
      <div className={classes.content}>
        {level && (
          <div className={classes.levelAndIcon}>
            <TierBadge tier={level} />
            <FaIconButton icon={['fal', 'bookmark']} onClick={onBookmark} />
          </div>
        )}
        <Typography variant="h2" className={classes.title}>
          {title}
        </Typography>
        <Typography variant="body1" className={classes.description}>
          {description}
        </Typography>
        <div className={classes.footer}>
          <LargeNumericMetric animate={false} value={courses} label="Courses" />
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <LargeNumericMetric animate={false} value={durationDays} label={durationDays === 1 ? 'Day' : 'Days'} />
          <Divider className={classes.divider} orientation="vertical" flexItem />
          <LargeNumericMetric animate={false} value={trainings} label="Trainings" />
          { /* TODO: hidden for now
          {instructor && (
            <>
              <Divider className={classes.divider} orientation="vertical" flexItem />
              <CardUserInfo image={instructor.image} bottomRow={instructor.name} topRow="Instructor" />
            </>
          )}
          */ }
        </div>
      </div>
      <div
        className={classes.image}
        style={{
          backgroundImage: `url("${image}")`,
        }}
      >
        <div className={classes.imageGradient} />
      </div>
    </Card>
  );
}

type CurriculumHeaderProps = {
  image: string;
  onBookmark: CardMethod;
  level?: TrainingGroupLevel;
  title: string;
  description: string;
  // Number of courses in this curriculum
  courses?: number;
  durationDays?: number;
  // Instructor information. Will not show if not set
  instructor?: { name: string; image: string; };
  trainings?: number;
};
