import { makeStyles } from '@mui/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactNode } from 'react';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';

const useStyles = makeStyles(() => ({
  tag: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: 1.25,
  },
}));

export default function IconBadge({
  icon,
  children,
  className = '',
}: IconBadgeProps) {
  const classes = useStyles();

  return (
    <span className={`${classes.tag} ${className}`}>
      <FontAwesomeIcon icon={icon} size="lg" color="#117fba" />
      &nbsp;
      {children}
    </span>
  );
}

interface IconBadgeProps {
  children: ReactNode;
  className?: string;
  icon: [IconPrefix, IconName];
}
