import { gql } from '@apollo/client';
import { toDates } from '@sightgain/core/dates';
import { LogResult, PaginationInput } from './interfaces';
import ServiceBase from './ServiceBase';

const typeLogsResult = `
{
  count
  rows {
    id
    createdAt
    message
    level
    meta
  }
}
`;

export class LogsService extends ServiceBase {
  constructor() {
    super();
    this.addMiddleWare('after', data => toDates(data, ['createdAt']));
  }

  /**
   * List logs
   */
  async list(
    to = new Date(),
    pagination: PaginationInput,
    value = '',
    levels: string[] = [],
    fields = typeLogsResult,
  ): Promise<LogResult> {
    const query = gql`
      query Logs($to: Date!, $pagination: PaginationInput, $value: String, $levels: [String!]){
        logs(to: $to, pagination: $pagination, value: $value, levels: $levels) ${fields}
      }
    `;

    const { logs } = await this.graphql(
      query,
      {
        to,
        pagination,
        value,
        levels,
      },
      'network-only',
    );
    return logs;
  }

  async started(): Promise<boolean> {
    const query = gql`
      query LogsStarted {
        logsStarted
      }
    `;

    const { logsStarted } = await this.graphql(query, {}, 'network-only');
    return logsStarted;
  }

  async start(): Promise<string> {
    const query = gql`
      mutation LogStart {
        logsStart
      }
    `;
    const { logsStart } = await this.graphql(query);
    return logsStart;
  }

  async stop(): Promise<string> {
    const query = gql`
      mutation LogStop {
        logsStop
      }
    `;
    const { logsStop } = await this.graphql(query);
    return logsStop;
  }

  async downloadLatest(): Promise<string> {
    const query = gql`
      query DownloadLatest {
        downloadLatest
      }
    `;
    const { downloadLatest } = await this.graphql(query, {}, 'network-only');
    return downloadLatest;
  }

  async downloadAll(): Promise<string> {
    const query = gql`
      query DownloadAll {
        downloadAll
      }
    `;
    const { downloadAll } = await this.graphql(query, {}, 'network-only');
    return downloadAll;
  }
}

const logsService = new LogsService();
export default logsService;
