import { makeStyles } from '@mui/styles';
import {
  Card,
  CardContent,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router';
import { createAvatar } from '@dicebear/avatars';
import * as style from '@dicebear/avatars-bottts-sprites';
import FaIconButton from '../../../../reusables/FaIconButton';
import NumberBadge from '../../../../reusables/Badges/NumberBadge';
import Avatar from '../../../../reusables/Avatar';
import { CardMethod } from './intefaces';
import { MouseEvent, ReactNode } from 'react';

const useStyles = makeStyles(() => ({
  cardContent: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px 10px 20px !important',
    cursor: 'pointer',
  },
  left: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  children: {
    marginRight: 25,
    display: 'flex',
    alignItems: 'center',
    gap: 25,
  },
  right: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
  },
  rank: {
    marginRight: 10,
    color: '#1592ff',
  },
  red: {
    backgroundColor: 'rgba(248, 92, 92, 0.49)',
  },
  orange: {
    backgroundColor: 'rgba(255, 165, 6, 0.49)',
  },
  yellow: {
    backgroundColor: 'rgba(255,255,0, 0.8)',
  },
  green: {
    backgroundColor: 'rgba(0, 255, 177, 0.49)',
  },
}));

export default function LeaderCard({
  rank,
  name,
  children = '',
  score,
  onInfo,
  id,
  email,
}: LeaderCardProps) {
  const classes = useStyles();
  const history = useHistory();

  const classNames = [classes.red, classes.orange, classes.yellow, classes.green];
  // retuns an number between 0 and 3 based on quarters of 100
  const index = Math.min(Math.ceil(((+score + 1) / 25)) - 1, 3);
  const scoreClass = classNames[index];

  const handleClick = (e: MouseEvent, func: CardMethod) => {
    func();
    e.stopPropagation();
  };

  const avatarSvg = createAvatar(style, {
    seed: email,
    dataUri: true,
    backgroundColor: '#dddbda',
  });

  return (
    <Card onClick={() => { history.push(`/curriculum/analysts/${id}`); }}>
      <CardContent className={classes.cardContent}>
        <div className={classes.left}>
          <Typography variant="h3" className={classes.rank}>{rank}</Typography>
          <Avatar size="xs" image={avatarSvg} />
          <Typography variant="h5">{name}</Typography>
        </div>
        <div className={classes.right}>
          <div className={classes.children}>{children}</div>
          <NumberBadge tag className={scoreClass}>{score}</NumberBadge>
          {onInfo && <FaIconButton icon={['fal', 'info-circle']} onClick={e => handleClick(e, onInfo)} />}
        </div>
      </CardContent>
    </Card>
  );
}

type LeaderCardProps = {
  rank: string;
  name: string;
  children?: ReactNode;
  score: number;
  onInfo?: CardMethod;
  id: string;
  email: string;
}
